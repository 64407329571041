import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  Image,
  TextInput,
  I18nManager,
  ImageBackground,
  Platform,
  ScrollView
} from "react-native";
import React, { useState, useRef } from "react";
import Icon from "react-native-vector-icons/Feather";
import Calendar from "../../assets/image/calendar.png";
import Money from "../../assets/image/money.png";
import { Picker } from "@react-native-picker/picker";
import { Checkbox } from "react-native-paper";
import Ellipse1 from "../../assets/image/Ellipse.png";
import Ellipse2 from "../../assets/image/Ellipse.png";
import Cart from "../../assets/image/cart.png";
import { useTranslation } from "react-i18next";
import SideBarConatainer from "../../Components/SideBarConatainer";
import WebDatePicker from "../../Components/DatePickerTest";
import { ApiDateFormate, DateFormate } from "../../Utils/HelperFunctions";
import { useSelector } from "react-redux";

const AddCard = ({ navigation }) => {
  const { t } = useTranslation();
  const [cardNumber, setCardNumber] = useState("");
  const [holderName, setHolderName] = useState("");
  const [cvv, setCvv] = useState("");
  const [amount, setAmount] = useState("");
  const [checked, setChecked] = useState(false);
  const userDetails = useSelector((state) => state.userDetails);
  const [wallet, setWallet] = useState(userDetails?.data.user.accounts[0]);

  const holderNameRef = useRef();
  const cvvRef = useRef();
  const amountRef = useRef();
  const [date, setDate] = useState("");
  const [isDatePickerVisible, setDatePickerVisibility] = useState(false);
  const [show, setShow] = useState(true);
  const showDatePicker = () => {
    setDatePickerVisibility(true);
    if (Platform.OS === "web") {
      setShow(false);
    }
  };
  const dateRef = useRef();

  const OnDateChange = (selectedDate) => {
    const currentDate = selectedDate;
    setDate(currentDate);
    setDatePickerVisibility(false);
    if (Platform.OS === "web") {
      setShow(true);
    }
  };
  const handleClickOutside = (event) => {
    let date = dateRef?.current?.contains(event.target);
    if (date === false && Platform.OS === "web") {
      setDatePickerVisibility(false);
      setShow(true);
    }
  };
  React.useEffect(() => {
    if (Platform.OS === "web") {
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, []);
  return (
    <SideBarConatainer navigation={navigation}>
        <View style={styles.topSection}>
          <Icon onPress={() => navigation.goBack()} name="arrow-left" size={30} />
          <Text style={styles.titleText}> {t("common:Add_Via_Credit_Debit_Card")}</Text>
        </View>
        <View style={styles.docContainer}>
          <ScrollView showsHorizontalScrollIndicator={false}>
            <View style={styles.addCreditContainer}>
              <ImageBackground source={Cart} style={styles.cartContainer}>
                <View style={styles.EllipseView}>
                  <Image source={Ellipse1} style={styles.EllipseImage1} />
                  <Image source={Ellipse2} style={styles.EllipseImage2} />
                </View>
                <View style={styles.accountNumberView}>
                  <Text style={styles.accountNumberText}>6212 2115 5234 4051</Text>
                </View>
                <View style={styles.nameView}>
                  <Text style={styles.nameText}>Samantha kudrow</Text>
                  <Text style={styles.dateText}>02/27</Text>
                </View>
              </ImageBackground>
              <View style={[styles.cardDetailContainer, { zIndex: 2000 }]}>
                <View style={styles.cardDtail}>
                  <View style={styles.cardLabel}>
                    <Text style={styles.cardNumberText}> {t("common:Card_number_st")}</Text>
                    <Text style={styles.star}>*</Text>
                  </View>
                  <TextInput
                    textAlign={I18nManager.isRTL ? "right" : "left"}
                    onSubmitEditing={() => holderNameRef.current.focus()}
                    value={cardNumber}
                    onChangeText={(text) => setCardNumber(text)}
                    keyboardType="number-pad"
                    returnKeyType="done"
                    maxLength={19}
                    style={styles.numberInput}
                  ></TextInput>
                </View>
                <View style={[styles.cardDtail, { marginLeft: 20 }]}>
                  <View style={styles.cardLabel}>
                    <Text style={styles.cardNumberText}>{t("common:ExpireDate")}</Text>
                    <Text style={styles.star}>*</Text>
                  </View>
                  <View style={styles.dateOfBirthView}>
                    <Text style={styles.dateText1}>{date && DateFormate(date)}</Text>
                    <TouchableOpacity
                      onPress={showDatePicker}
                      style={styles.dateButton}
                    >
                      {show && (
                        <Image source={Calendar} style={styles.calendarImage} />
                      )}
                    </TouchableOpacity>
                    <View style={isDatePickerVisible ? { display: "flex" } : { display: "none" }} ref={dateRef}>
                      {Platform.OS === "web" && (
                        <WebDatePicker isDatePickerVisible={isDatePickerVisible} date={date} OnDateChange={OnDateChange} />
                      )}
                    </View>
                  </View>
                </View>
                <View style={[styles.cardDtail, { marginLeft: 20 }]}>
                  <View style={styles.cardLabel}>
                    <Text style={styles.cardNumberText}>{t("common:card_holder_name")}</Text>
                    <Text style={styles.star}>*</Text>
                  </View>
                  <TextInput
                    style={styles.numberInput}
                    textAlign={I18nManager.isRTL ? "right" : "left"}
                    ref={holderNameRef}
                    onSubmitEditing={() => cvvRef.current.focus()}
                    value={holderName}
                    onChangeText={(text) => setHolderName(text)}
                  ></TextInput>
                </View>
              </View>
              <View style={styles.cardDetailContainer}>
                <View style={styles.cardDtail}>
                  <View style={styles.cardLabel}>
                    <Text style={styles.cardNumberText}>{t("common:CVV")}</Text>
                    <Text style={styles.star}>*</Text>
                  </View>
                  <TextInput
                    style={styles.numberInput}
                    textAlign={I18nManager.isRTL ? "right" : "left"}
                    ref={cvvRef}
                    onSubmitEditing={() => amountRef.current.focus()}
                    value={cvv}
                    onChangeText={(text) => setCvv(text)}
                    keyboardType="number-pad"
                    returnKeyType="done"
                    maxLength={19}
                  ></TextInput>
                </View>

                <View style={[styles.cardDtail, { marginLeft: 20 }]}>
                  <Text style={styles.cardNumberText}>
                    {t("common:add_into_wallet")}
                    <Text style={{ color: "#FF4747" }}>*</Text>
                  </Text>
                  <View style={styles.pickerView}>
                    <Image source={Money} style={styles.moneyImage} />
                    <Picker
                      mode="dropdown"
                      selectedValue={wallet}
                      onValueChange={(selectedValue) => setWallet(selectedValue)}
                      style={styles.pickerText}>
                      {userDetails !== undefined &&
                        userDetails.data.user.accounts.map((val, ind) => {
                          return (
                            <Picker.Item
                              key={ind}
                              label={val.name}
                              value={val}
                              style={styles.pickerItemText}
                            />
                          );
                        })}
                    </Picker>
                  </View>
                  <View style={styles.lineView}></View>
                </View>

                <View style={[styles.cardDtail, { marginLeft: 20 }]}>
                  <View style={styles.cardLabel}>
                    <Text style={styles.cardNumberText}> {t("common:currency_")}</Text>
                    <Text style={styles.star}>*</Text>
                  </View>
                  <View style={styles.usdTicket}>
                    <Image source={Money} style={styles.moneyImage} />
                    <Text style={styles.usdText}>{t("common:USD")}</Text>
                  </View>
                </View>
              </View>

              <View style={[styles.cardDetailContainer, { width: 335 }]}>
                <View style={styles.cardDtail}>
                  <View style={styles.cardLabel}>
                    <Text style={styles.cardNumberText}>{t("common:Amount")}</Text>
                    <Text style={styles.star}>*</Text>
                  </View>
                  <TextInput
                    style={styles.numberInput}
                    textAlign={I18nManager.isRTL ? "right" : "left"}
                    ref={amountRef}
                    value={amount}
                    onChangeText={(text) => setAmount(text)}
                    keyboardType="number-pad"
                    returnKeyType="done"
                    maxLength={19}
                  ></TextInput>
                </View>
              </View>
              <View style={styles.checkView}>
                <Checkbox
                  color="#FF4747"
                  status={!checked ? "checked" : "unchecked"}
                  onPress={() => {
                    setChecked(!checked);
                  }}
                />
                <Text style={styles.saveText}>{t("common:SaveMyCard")}</Text>
              </View>
              <View style={styles.btnView}>
                <TouchableOpacity style={styles.btn}>
                  <Text style={styles.continueText}>{t("common:Continue")}</Text>
                </TouchableOpacity>
              </View>
            </View>
          </ScrollView>
        </View>
    </SideBarConatainer>
  );
};

export default AddCard;

const styles = StyleSheet.create({
  topUpContainer: {
    flex: 1,
  },
  topSection: {
    flexDirection: "row",
    alignItems: "center"
  },
  titleText: {
    fontSize: 26,
    fontWeight: "700",
    color: "#000000",
    fontFamily: "Inter",
    marginLeft: 15
  },
  cartContainer: {
    flex:1,
    marginLeft: 20,
    maxWidth: 327,
    height: 180,
    marginBottom: 60,
  },
  EllipseView: {
    flexDirection: "row",
    alignSelf: "flex-end",
    marginRight: 32,
    marginTop: 20
  },
  EllipseImage1: {
    height: 25.18,
    width: 25.7,
    left: 16.29,
  },
  EllipseImage2: {
    height: 25.18,
    width: 25.7,
    left: 8,
  },
  accountNumberView: {
    marginTop: 25,
    marginLeft: 32,
  },
  accountNumberText: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 23,
    color: "#FFFFFF",
  },
  nameView: {
    flexDirection: "row",
    marginTop: 25,
    marginHorizontal: 32,
    justifyContent: "space-between",
  },
  nameText: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 12,
    textTransform: "uppercase",
    color: "#FFFFFF",
  },
  dateText: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 12,
    lineHeight: 16,
    letterSpacing: 0.04,
    textTransform: "uppercase",
    color: "#FFFFFF",
  },
  docContainer: {
    backgroundColor: "#FFF",
    borderRadius: 16,
    marginTop: 27,
    flex: 1,
  },
  addCreditContainer: {
    padding: 20
  },
  cardDetailContainer: {
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "space-between",
    marginTop: 20,
    marginHorizontal: 20,
  },
  cardDtail: {
    flex: 1,
  },
  cardLabel: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  cardNumberText: {
    color: "#A9ABAE",
    fontWeight: "400",
    fontFamily: "Inter",
    fontSize: 14,
    lineHeight: 24,
    fontStyle: "normal",
  },
  star: {
    marginLeft: 3,
    fontSize: 14,
    color: "#FF4747",
  },
  dateOfBirthView: {
    borderBottomWidth: 1,
    borderColor: "#F1F1F1",
    height: 56,
    flexDirection: "row",
    justifyContent: "space-between",
    zIndex: 2000,
    alignItems: "center"
  },
  dateText1: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 14,
    color: "#101820",
  },
  dateButton: {
    marginRight: 15,
  },
  calendarImage: {
    height: 18,
    width: 16.5,
    resizeMode: "contain"
  },
  numberInput: {
    borderBottomWidth: 1,
    borderColor: "#E7E8E9",
    height: 56,
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 14,
    outlineStyle: "none",
    color: "#101820",
  },
  moneyImage: {
    height: 24,
    width: 24,
  },
  usdTicket: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderColor: "#E7E8E9",
    height: 56,
    alignItems: "center"
  },
  pickerView: {
    borderBottomWidth: 1,
    borderColor: "#E7E8E9",
    flexDirection: "row",
    alignItems: "center",
    height: 56,
  },
  pickerText: {
    flex: 1,
    height: 24,
    borderWidth: 1,
    borderColor: "white",
    borderStyle: "solid",
    fontSize: 14,
    fontWeight: "500",
    fontFamily: "Inter",
    color: "#101820",
  },
  usdText: {
    marginTop: 5,
    marginLeft: 5,
    fontSize: 14,
    fontWeight: "500",
    fontFamily: "Inter",
    color: "#101820",
  },
  pickerItemText: {
    fontSize: 14,
    fontWeight: "700",
    fontFamily: "Inter",
  },
  checkView: {
    flexDirection: "row",
    marginTop: 20,
    marginLeft: 10,
    width: "90%",
    alignItems: "center",
  },
  saveText: {
    height: 22,
    marginLeft: 16,
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 14,
    lineHeight: 17,
    letterSpacing: -0.133333,
    color: "#282828",
  },
  btnView: {
    flex: 1,
    alignItems: "flex-end",
    maxHeight: 56,
    marginBottom: 20
  },
  btn: {
    flex: 1,
    width: 327,
    marginHorizontal: 20,
    borderRadius: 70,
    backgroundColor: "#FF4747",
    justifyContent: "center",
    alignItems: "center",
  },
  continueText: {
    color: "#ffffff",
    fontSize: 16,
    fontWeight: "700",
    fontFamily: "Inter",
    fontStyle: "normal",
  },
});
