import {takeEvery, put, call} from 'redux-saga/effects';
import BASEURL from '../URLs'
import {queryApi} from '../QueryApi'
import {
  GET_USER_WALLET_REQUEST,
  GET_USER_WALLET_REQUEST_SUCCESS,
  GET_USER_WALLET_REQUEST_FAILURE,
} from './Actions';
//import {queryApi} from '../query-api';

function* handler() {
  yield takeEvery(GET_USER_WALLET_REQUEST, getUserWallet);
}


function* getUserWallet(action) {
  try {
    // API call
    // console.log(action.parm)
    const apiResponse = yield call(queryApi, {
      endpoint: BASEURL.userWallet + action.params,
      method: "GET",
    });
    console.log("HIT Wallet", apiResponse);
    if (!("error" in apiResponse)) {
      yield put({
        type: GET_USER_WALLET_REQUEST_SUCCESS,
        payload: apiResponse,
      });
    } else {
      yield put({
        type: GET_USER_WALLET_REQUEST_FAILURE,
        payload: apiResponse.error,
      });
    }
  } catch (err) {
    console.log(err);
    // Handle error
    yield put({
      type: GET_USER_WALLET_REQUEST_FAILURE,
      payload: { message: err },
    });
  }
}

export {handler};