import {
  StyleSheet,
  Text,
  View,
  Dimensions,
  ScrollView,
  TextInput,
  I18nManager,
  TouchableOpacity,
  Image,
  KeyboardAvoidingView,
  Platform,
  Keyboard,
  SafeAreaView,
} from "react-native";
import ToastAndroid from "react-native-root-toast";
import React, { useState, useEffect, useRef } from "react";
import Icon from "react-native-vector-icons/Feather";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Picker } from "@react-native-picker/picker";
import Calendar from "../../../assets/image/calendar.png";
import {
  requestUpdate,
  resetRequestUpdate,
} from "../../../Redux/Models/RequestUpdate/Actions";
import { ApiDateFormate } from "../../../Utils/HelperFunctions";
import Loading from "../../Loading";
const height = Dimensions.get("window").height;
import DateTimePickerModal from "react-native-modal-datetime-picker";
import WebDatePicker from "../../../Components/DatePickerTest";
import Alert from "../../../Components/Alert";
import SideBarConatainer from "../../../Components/SideBarConatainer";

const EditPersonalInfo = ({ navigation, route }) => {
  const personalInfo = route.params?.personalInfo;
  const requestId = route.params?.requestId;
  const { t } = useTranslation();
  const kycRequestUpdate = useSelector((state) => state.requestUpdate);
  const [gender, setGender] = useState(personalInfo.gender);
  const [fatherName, setFatherName] = useState(personalInfo.fathers_name);
  const [motherName, setMotherName] = useState(personalInfo.mother_name);
  const [maritalStatus, setMaritalStatus] = useState(
    personalInfo.social_status
  );
  const [nationality, setNationality] = useState(personalInfo.nationality);
  const [email, setEmail] = useState(personalInfo.email);
  const [contactNumber, setContactNumber] = useState(
    personalInfo.secondary_contact_number
  );
  const [grandFatherName, setGrandFatherName] = useState(
    personalInfo.grandfathers_name
  );
  const [disabled, setDisabled] = useState(false);
  const [date, setDate] = useState(new Date(personalInfo.Dob));
  const dispatch = useDispatch();
  const [isDatePickerVisible, setDatePickerVisibility] = useState(false);
  const [show, setShow] = useState(true);
  const showDatePicker = () => {
    setDatePickerVisibility(true);
    if (Platform.OS === "web") {
      setShow(false);
    }
  };
  const hideDatePicker = () => {
    setDatePickerVisibility(false);
  };
  const motherNameRef = useRef();
  const grandFatherNameRef = useRef();
  const nationalityRef = useRef();
  const emailRef = useRef();
  const contactNumberRef = useRef();
  const Validate = () => {
    Keyboard.dismiss();
    if (fatherName === "") {
      ToastAndroid.show("Please enter your father name", ToastAndroid.LONG);
      return false;
    } else if (motherName === "") {
      ToastAndroid.show("Please enter your mother name", ToastAndroid.LONG);
      return false;
    } else if (date === undefined) {
      ToastAndroid.show("Please select DOB", ToastAndroid.LONG);
      return false;
    } else if (nationality === "") {
      ToastAndroid.show("Please select Nationality", ToastAndroid.LONG);
      return false;
    } else if (contactNumber === "") {
      ToastAndroid.show(
        "Please enter the Secondary Contact",
        ToastAndroid.LONG
      );
      return false;
    } else {
      let formData = {
        fathers_name: fatherName,
        mother_name: motherName,
        grandfathers_name:grandFatherName,
        gender: gender,
        date_of_birth: date && Math.ceil(date.getTime() / 1000),
        marital_status: maritalStatus,
        nationality: nationality,
        email: email,
        secondary_contact_number: contactNumber.replace(/-/g, ""),
      };
      let addToForm = new FormData();
      for (let [key, value] of Object.entries(formData)) {
        addToForm.append("user[" + key + "]", value);
      }
      dispatch(requestUpdate(requestId.id, addToForm));
    }
  };
  const dateRef = useRef();
  const handleClickOutside = (event) => {
    let date = dateRef.current.contains(event.target);
    if (date === false && Platform.OS === "web") {
      setDatePickerVisibility(false);
      setShow(true);
    }
  };
  React.useEffect(() => {
    if (Platform.OS === "web") {
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, []);

  const OnDateChange = (selectedDate) => {
    const currentDate = selectedDate;
    setDate(currentDate);
    setDatePickerVisibility(false);
    if (Platform.OS === "web") {
      setShow(true);
    }
  };
  useEffect(() => {
    if (kycRequestUpdate.error === true) {
      return Alert("Error", kycRequestUpdate.message, [
        {
          text: "OK",
          onPress: () => {
            dispatch(resetRequestUpdate());
          },
        },
      ]);
    }
    if (kycRequestUpdate.success === true) {
      return Alert("Congratulation", kycRequestUpdate.message, [
        {
          text: "OK",
          onPress: () => {
            dispatch(resetRequestUpdate());
            navigation.navigate("KycRequestDetail", { request: requestId });
          },
        },
      ]);
    }
  }, [kycRequestUpdate]);
  useEffect(() => {
    if (
      !!fatherName &&
      !!motherName &&
      !!maritalStatus &&
      !!nationality &&
      !!email &&
      !!contactNumber
    ) {
      setDisabled(false);
    } else {
      setDisabled(false);
    }
  }, [
    fatherName,
    motherName,
    maritalStatus,
    nationality,
    email,
    contactNumber,
  ]);
  return (
    <SideBarConatainer navigation={navigation}>
      <View style={styles.headerView}>
        <Icon
          name="arrow-left"
          size={30}
          style={styles.arrowIcon}
          onPress={() => navigation.goBack()}
        />
        <Text style={styles.kycText}>{t("common:kyc")}</Text>
      </View>
      <KeyboardAvoidingView
        style={{ flex: 1 }}
        behavior={Platform.OS === "ios" ? "padding" : "height"}
      >
        <ScrollView showsHorizontalScrollIndicator={false}>
          <View style={styles.innerContainer}>
            <Text style={styles.personalInfoText}>
              {t("common:provide_the_personal_information")}
            </Text>
            <View style={styles.inputContainer}>
              <View style={styles.inputView}>
                <Text style={{ ...styles.nameText }}>
                  {t("common:father_name_st")}
                  <Text style={{ color: "#FF4747" }}>*</Text>
                </Text>
                <TextInput
                  textAlign={I18nManager.isRTL ? "right" : "left"}
                  returnKeyType="next"
                  onSubmitEditing={() => motherNameRef.current.focus()}
                  value={fatherName}
                  onChangeText={(text) => setFatherName(text)}
                  style={styles.input}
                ></TextInput>
              </View>
              <View style={[styles.inputView, { marginLeft: 20 }]}>
                <Text style={styles.nameText}>
                  {t("common:mother_name_st")}
                  <Text style={{ color: "#FF4747" }}>*</Text>
                </Text>
                <TextInput
                  textAlign={I18nManager.isRTL ? "right" : "left"}
                  value={motherName}
                  returnKeyType="next"
                  onSubmitEditing={() => grandFatherNameRef.current.focus()}
                  ref={motherNameRef}
                  onChangeText={(text) => setMotherName(text)}
                  style={styles.input}
                ></TextInput>
              </View>
            </View>
            <View style={styles.inputContainer}>
              <View style={styles.inputView}>
                <Text style={{ ...styles.nameText }}>
                  {t("common:grand_father_name")}
                  <Text style={{ color: "#FF4747" }}>*</Text>
                </Text>
                <TextInput
                  textAlign={I18nManager.isRTL ? "right" : "left"}
                  returnKeyType="next"
                  onSubmitEditing={() => nationalityRef.current.focus()}
                  ref={grandFatherNameRef}
                  defaultValue={grandFatherName}
                  onChangeText={(newText) => setGrandFatherName(newText)}
                  style={styles.input}
                ></TextInput>
              </View>
              <View style={[styles.inputView, { marginLeft: 20 }]}>
                <Text style={styles.nameText}>
                  {t("common:gender_st")}
                  <Text style={{ color: "#FF4747" }}>*</Text>
                </Text>
                <View style={{ ...styles.pickerView, height: 48 }}>
                  <Picker
                    mode="dropdown"
                    selectedValue={gender}
                    onValueChange={(selectedValue) => setGender(selectedValue)}
                    style={styles.pickerItem}
                  >
                    <Picker.Item
                      label="Male"
                      value="Male"
                      style={styles.pickerItemText}
                    />
                    <Picker.Item
                      label="Female"
                      value="Female"
                      style={styles.pickerItemText}
                    />
                  </Picker>
                </View>
              </View>
            </View>
            <View style={[styles.inputContainer,{zIndex:2000}]}>
              <View style={styles.inputView}>
                <Text style={{ ...styles.nameText }}>
                  {t("common:date_of_birth_st")}
                  <Text style={{ color: "#FF4747" }}>*</Text>
                </Text>
                <View style={styles.dateOfBirthView}>
                  <Text style={styles.dateText}>
                    {date && ApiDateFormate(date)}
                  </Text>
                  <TouchableOpacity
                    onPress={showDatePicker}
                    style={styles.dateButton}
                  >
                    {show && (
                      <Image source={Calendar} style={styles.calendarImage} />
                    )}
                  </TouchableOpacity>
                  <View
                    style={
                      isDatePickerVisible
                        ? { display: "flex" }
                        : { display: "none" }
                    }
                    ref={dateRef}
                  >
                    {Platform.OS === "web" && (
                      <WebDatePicker
                        isDatePickerVisible={isDatePickerVisible}
                        date={date}
                        OnDateChange={OnDateChange}
                      />
                    )}
                  </View>
                  <DateTimePickerModal
                    isVisible={isDatePickerVisible}
                    mode="date"
                    onConfirm={OnDateChange}
                    onCancel={hideDatePicker}
                  />
                </View>
              </View>
              <View style={[styles.inputView, { marginLeft: 20 }]}>
                <Text style={styles.nameText}>
                  {t("common:marital_status")}
                  <Text style={{ color: "#FF4747" }}>*</Text>
                </Text>
                <View style={styles.pickerView}>
                  <Picker
                    mode="dropdown"
                    selectedValue={maritalStatus}
                    onValueChange={(selectedValue) =>
                      setMaritalStatus(selectedValue)
                    }
                    style={styles.pickerItem}
                  >
                    <Picker.Item
                      label="Single"
                      value="single"
                      style={styles.pickerItemText}
                    />
                    <Picker.Item
                      label="Married"
                      value="married"
                      style={styles.pickerItemText}
                    />
                    <Picker.Item
                      label="Divorced"
                      value="divorced"
                      style={styles.pickerItemText}
                    />
                    <Picker.Item
                      label="Widow"
                      value="widow"
                      style={styles.pickerItemText}
                    />
                  </Picker>
                </View>
              </View>
            </View>
            <View style={styles.inputContainer}>
              <View style={styles.inputView}>
                <Text style={{ ...styles.nameText }}>
                  {t("common:nationality")}
                  <Text style={{ color: "#FF4747" }}>*</Text>
                </Text>
                <TextInput
                  textAlign={I18nManager.isRTL ? "right" : "left"}
                  returnKeyType="next"
                  onSubmitEditing={() => emailRef.current.focus()}
                  ref={nationalityRef}
                  value={nationality}
                  onChangeText={(text) => setNationality(text)}
                  style={styles.input}
                ></TextInput>
              </View>
              <View style={[styles.inputView, { marginLeft: 20 }]}>
                <Text style={styles.nameText}>
                  {t("common:email")}
                  <Text style={{ color: "#FF4747" }}>*</Text>
                </Text>
                <TextInput
                  textAlign={I18nManager.isRTL ? "right" : "left"}
                  value={email}
                  returnKeyType="next"
                  onSubmitEditing={() => contactNumberRef.current.focus()}
                  ref={emailRef}
                  onChangeText={(text) => setEmail(text)}
                  keyboardType="email-address"
                  style={styles.input}
                ></TextInput>
              </View>
            </View>
            <View style={styles.inputContainer}>
              <View style={styles.inputView}>
                <Text style={{ ...styles.nameText }}>
                  {t("common:secondary_contact_number")}
                  <Text style={{ color: "#FF4747" }}>*</Text>
                </Text>
                <TextInput
                  textAlign={I18nManager.isRTL ? "right" : "left"}
                  maxLength={12}
                  value={contactNumber}
                  ref={contactNumberRef}
                  onChangeText={(text) => {
                    var cleaned = ("" + text).replace(/\D/g, "");
                    let normValue = `${cleaned.substring(0, 3)}${
                      cleaned.length > 3 ? "-" : ""
                    }${cleaned.substring(3, 6)}${
                      cleaned.length > 6 ? "-" : ""
                    }${cleaned.substring(6, 10)}`;
                    setContactNumber(normValue);
                  }}
                  keyboardType="number-pad"
                  returnKeyType="done"
                  style={styles.input}
                ></TextInput>
              </View>
            </View>

            <View style={styles.btnView}>
              <TouchableOpacity onPress={Validate} style={styles.button}>
                <Text style={styles.buttonText}>{t("common:update")}</Text>
              </TouchableOpacity>
            </View>
          </View>
        </ScrollView>
      </KeyboardAvoidingView>
      {kycRequestUpdate.loading && <Loading></Loading>}
    </SideBarConatainer>
  );
};

export default EditPersonalInfo;

const styles = StyleSheet.create({
  topContainer: {
    flex: 1,
    backgroundColor: "#FFFFFF",
  },
  headerView: {
    flexDirection: "row",
  },
  kycText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 26,
    color: "#000000",
    marginLeft: 10,
  },
  innerContainer: {
    marginTop: 20,
    backgroundColor: "#ffffff",
    padding: 20,
    borderRadius: 16,
  },
  personalInfoText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 18,
    color: "#000000",
    marginTop: 20,
    alignSelf: "flex-start",
  },
  inputContainer: {
    flex: 1,
    flexDirection: "row",
  },
  inputView: {
    flex: 1,
    maxWidth: 327,
  },
  nameText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 14,
    alignSelf: "flex-start",
    color: "#A9ABAE",
    marginTop: 10,
  },
  input: {
    height: 56,
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 14,
    borderColor: "#E7E8E9",
    borderBottomWidth: 1,
    outlineStyle: "none",
    color: "#101820",
  },
  pickerView: {
    borderBottomWidth: 1,
    borderColor: "#E7E8E9",
    height: 56,
    justifyContent: "center",
  },
  pickerItem: {
    height: 45,
    borderWidth: 1,
    borderColor: "white",
    borderStyle: "solid",
  },
  dateOfBirthView: {
    borderBottomWidth: 1,
    borderColor: "#F1F1F1",
    height: 56,
    flexDirection: "row",
    justifyContent: "space-between",
    zIndex: 2000,
  },
  dateText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 14,
    color: "#101820",
    marginTop: 20,
  },
  dateButton: {
    marginRight: 15,
    marginTop: 20,
  },
  calendarImage: {
    height: 18,
    width: 16.5,
    resizeMode: "contain"
  },
  pickerItemText: {
    color: "#101820",
    fontSize: 14,
    fontWeight: "500",
    fontFamily: "Inter",
  },
  btnView: {
    flex: 1,
    marginTop: 30,
    alignItems: "flex-end",
  },
  button: {
    padding: 16,
    backgroundColor: "#FF4747",
    borderRadius: 70,
    width: 404,
  },

  buttonText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 16,

    alignItems: "center",
    textAlign: "center",
    color: "#FFFFFF",
  },
});
