import {
  StyleSheet,
  Text,
  View,
  Image,
  SafeAreaView,
  TouchableOpacity,
  ScrollView,
  ActivityIndicator,
} from "react-native";
import React, { useEffect, useState, useCallback } from "react";
import Icon from "react-native-vector-icons/Ionicons";
import { useTranslation } from "react-i18next";
import Profile from "../../assets/image/profile.png";
import Wallets from "./Components/Wallets";
import { DateFormate, formatTimeAMPM } from "../../Utils/HelperFunctions";
import { useFocusEffect } from "@react-navigation/native";
import { useDispatch, useSelector } from "react-redux";
import Entypo from "react-native-vector-icons/Entypo"
import AppHeader from './Components/AppDashboardHeader'
import SideBar from './Components/SideBar'

import {
  getUserDetails,
  getUserTransactions,
} from "../../Redux/Models/User/UserDetails/Actions";
import Loading from "../Loading";
import ToastAndroid from "react-native-root-toast";
import TopUpImage from "../../assets/image/topUpBtn.png"
import { selectedRequests } from '../../Redux/Models/MyRequests/Actions'

const Dashboard = ({ navigation, route }) => {
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state.userDetails);
  const [user, setUser] = useState(null);
  const [wallet, setWallet] = useState(null);
  const { t } = useTranslation();
  console.log("userDetails", userDetails);
  useFocusEffect(useCallback(() => {
    navigation.getParent()?.setOptions({ tabBarStyle: { display: 'none' }, });
  }, [navigation]));

  const switchScreen = (screen) => {
    navigation.getParent()?.setOptions({
      tabBarStyle: {
        borderTopWidth: 0,
        backgroundColor: 'white',
        overflow: 'hidden',
        elevation: 0,
        width: 1400,
        alignSelf: "center",
        display: "flex"
      },
    })
    navigation.navigate(screen)
  }
  const userTransactionsApi = () => {
    dispatch(
      getUserTransactions(new URLSearchParams({
        // to_currency: wallet.currency.currency_iso_code,
        // from_currency: wallet.currency.currency_iso_code,
        limit: 5,
      }))
    );
  }
  useFocusEffect(
    useCallback(() => {
      dispatch(getUserDetails());
      userTransactionsApi()
    }, [dispatch])
  );
  useEffect(() => {
    if (!userDetails.data.user) { return }
    setUser(userDetails.data.user);
    setWallet(userDetails.data.user?.accounts[0]);
  }, [userDetails.data.user]);

  let selectedWallet = (wallet) => {
    userTransactionsApi()
    setWallet({ ...wallet });
  };
  const inProgress = () => {
    ToastAndroid.show(t("common:in_progress"), ToastAndroid.SHORT);
  }
  return (
    <SafeAreaView style={styles.safeAreaContainer}>
      <View style={styles.topContainer}></View>

      <View style={styles.mainContainer}>
        <SideBar route={route} navigation={navigation} />
        <View style={{ flex: 1 }}>
          <AppHeader userDetails={userDetails} navigation={navigation} />
          <View style={styles.innerContainer}>
            <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
              <View style={styles.balanceView}>
                <Text style={styles.balanceText}>{t("common:total_balance")}</Text>
                <Text style={styles.priceText}>
                  {wallet &&
                    wallet?.balance + " " + wallet?.currency.currency_iso_code}
                </Text>
              </View>
              <View style={styles.walletsView}>
                <View style={styles.accountView}>
                  <View><Text style={styles.accountText} >{t("common:my_account")}</Text></View>
                  <View style={{ flexDirection: 'row', gap: 10 }}>
                    <TouchableOpacity><Icon style={styles.arrowIcon} color='#A8A8A8' size={24} name='md-arrow-back'></Icon></TouchableOpacity>
                    <TouchableOpacity><Icon style={styles.arrowIcon} color='#A8A8A8' size={24} name='md-arrow-forward'></Icon></TouchableOpacity>
                  </View>
                </View>
                <View style={{ flexDirection: "row", gap: 20 }}>
                  <Wallets wallets={user?.accounts} onChangeWallet={selectedWallet} />
                </View>
              </View>
              <View style={styles.buttonContainer}>
                <View style={{ ...styles.btnRow, marginLeft: 0 }}>
                  <TouchableOpacity
                    onPress={() => {
                      navigation.getParent()?.setOptions({
                        tabBarStyle: {
                          borderTopWidth: 0,
                          backgroundColor: 'white',
                          overflow: 'hidden',
                          elevation: 0,
                          width: 1400,
                          alignSelf: "center",
                          display: "flex"
                        },
                      })
                      navigation.navigate("RequestTopUp")
                      dispatch(selectedRequests(t("common:new_")))
                    }}
                    style={styles.button}
                  >
                    <View style={styles.btnTextView}>
                      <Image source={TopUpImage} style={styles.image} />
                      <Text style={styles.btnText}>{t("common:top_up")}</Text>
                    </View>
                    <View><Entypo size={24} name='chevron-small-right' /></View>

                  </TouchableOpacity>
                </View>
                <View style={styles.btnRow}>
                  <TouchableOpacity
                    onPress={() => switchScreen("Transfer")}
                    style={styles.button}
                  >

                    <View style={styles.btnTextView}>
                      <Icon name="ios-swap-horizontal" color="#FF4747" size={30} />
                      <Text style={styles.btnText}>{t("common:transfer")}</Text>
                    </View>
                    <View><Entypo size={24} name='chevron-small-right' /></View>


                  </TouchableOpacity>
                </View>
                <View style={styles.btnRow}>
                  <TouchableOpacity onPress={inProgress} style={styles.button}>

                    <View style={styles.btnTextView}>
                      <Icon name="card-outline" color="#FF4747" size={30} />
                      <Text style={styles.btnText}>{t("common:with_draw")}</Text>
                    </View>
                    <View><Entypo size={24} name='chevron-small-right' /></View>


                  </TouchableOpacity>
                </View>
                <View style={styles.btnRow}>
                  <TouchableOpacity
                    onPress={() => switchScreen("MyQrCode")}
                    style={styles.button}
                  >


                    <View style={styles.btnTextView}>
                      <Icon name="ios-scan-sharp" color="#FF4747" size={30} />
                      <Text style={styles.btnText}>{t("common:qr_pay")}</Text>
                    </View>
                    <View><Entypo size={24} name='chevron-small-right' /></View>
                  </TouchableOpacity>
                </View>
              </View>

              <View style={styles.container}>
                <Text style={styles.transactionsText}>{t("common:transactions")}</Text>
                {!userDetails.loading && userDetails.transactionsLoading && (
                  <ActivityIndicator
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    size="large"
                    color="#FF0000"
                  />
                )}
                <ScrollView showsHorizontalScrollIndicator={false}>
                  <View style={styles.transContainer}>
                    {!!userDetails.data.user &&
                      userDetails.userTransactions?.transactions.map((val, ind) => (
                        <TouchableOpacity
                          onPress={() => {
                            navigation.getParent()?.setOptions({
                              tabBarStyle: {
                                borderTopWidth: 0,
                                backgroundColor: 'white',
                                overflow: 'hidden',
                                elevation: 0,
                                width: 1400,
                                alignSelf: "center",
                                display: "flex"
                              },
                            })
                            navigation.navigate("TransactionDetail", { val })
                          }
                          }
                          key={ind}
                          style={
                            userDetails.transactionsLoading
                              ? { display: "none" }
                              : styles.listView
                          }
                        >
                          <View style={styles.arrowView}>
                            <Icon
                              name={
                                val.sender.toLowerCase() !==
                                  userDetails.data.user.name.toLowerCase()
                                  ? "arrow-up"
                                  : "arrow-down"
                              }
                              style={
                                val.sender.toLowerCase() !==
                                  userDetails.data.user.name.toLowerCase()
                                  ? styles.arrowImage
                                  : styles.downImage
                              }
                            />
                          </View>
                          <View style={styles.listContainer}>
                            <Text style={styles.receivedText}>
                              {val.sender.toLowerCase() ===
                                userDetails.data.user.name.toLowerCase()
                                ? val.request_type + " " + val.receiver
                                : val.request_type + " " + val.sender}
                            </Text>
                            <Text style={styles.dateText}>
                              {" "}
                              {DateFormate(
                                new Date(val.Transactions__created.split(" ")[0])
                              ) +
                                " " +
                                formatTimeAMPM(
                                  val.Transactions__created.split(" ")[1].split(":")[0],
                                  val.Transactions__created.split(" ")[1].split(":")[1]
                                )}
                            </Text>
                          </View>
                          <Text style={styles.priceListText}>
                            {val.sender.toLowerCase() !==
                              userDetails.data.user.name.toLowerCase()
                              ? "+" + val.amount + " " + val.receiving_currency
                              : "-" + val.amount + " " + val.sending_currency}
                          </Text>
                        </TouchableOpacity>
                      ))}
                  </View>
                </ScrollView>
              </View>
            </ScrollView>
          </View>

        </View>
        {userDetails.loading && <Loading></Loading>}
      </View>
    </SafeAreaView>
  );
};

export default Dashboard;

const styles = StyleSheet.create({
  safeAreaContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    backgroundColor: '#f8f8f8'
  },
  topContainer: {
    position: 'absolute',
    backgroundColor: 'white',
    height: 80,
    width: '100%'
  },
  mainContainer: {
    flex: 1,
    backgroundColor: '#F8F8F8',
    flexDirection: 'row',
    maxWidth: 1400
  },
  innerContainer: {
    flex: 1,
    marginHorizontal: 25,
  },
  profileView: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginLeft: 24,
    marginTop: 8,
  },
  btnTextView: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  profileContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  walletsView: {
    height: 219,
    backgroundColor: 'white',
    padding: 20,
    borderRadius: 16,
    gap: 16
  },
  accountView: {
    height: 44,
    padding: 10,
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  accountText: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: 18,
    lineHeight: 20,
  },
  profileImage: {
    width: 40,
    height: 40,
    borderRadius: 25,
  },
  nameText: {
    marginLeft: 16,
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 20,
    color: "#000000",
    alignItems: "center"
  },
  notificationView: {
    justifyContent: "center",
    alignItems: "center",
    width: 40,
    height: 40,
    backgroundColor: "#FFFFFF",
    borderRadius: 12,
  },
  balanceView: {
    marginTop: 25,
    marginBottom: 17,
    backgroundColor: 'white',
    height: 134, borderRadius: 16,
    padding: 30
  },
  arrowIcon: {
    height: 25,
    width: 25,
    backgroundColor: '#F8F8F8',
    borderRadius: 50
  },
  balanceText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 14,
    lineHeight: 20,
    fontSize: 12,
    marginBottom: 10,
    letterSpacing: 0.111,
    color: "#101820",
    opacity: 0.5,
    alignSelf: "flex-start"

  },
  priceText: {
    marginTop: 4,
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 36,
    lineHeight: 44,
    color: "#000000",
    alignSelf: "flex-start"
  },
  buttonContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: 'wrap',
    gap: 17,
    marginTop: 12,
  },
  button: {
    backgroundColor: "#FFFFFF",
    borderRadius: 16,
    alignItems: 'center',
    justifyContent: "space-between",
    flexDirection: 'row',
    flex: 1,
    paddingHorizontal: 20
    // minWidth: 72,
  },
  image: {
    width: 24,
    height: 24,
    resizeMode: "contain"
  },
  btnRow: {
    flex: 1,
    minWidth: 150,
    minHeight: 84,
  },
  btnImage: {
    width: 26,
    height: 24,
  },
  btnText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 16,
    lineHeight: 18,
    textAlign: "center",
    color: "#000",
    marginLeft: 13
  },
  container: {
    borderTopLeftRadius: 26,
    borderTopRightRadius: 26,
    backgroundColor: "#FFFFFF",
    flex: 1,
    marginTop: 16,
  },
  transContainer: {
    flex: 1,
    marginLeft: 24,
    marginRight: 24,
    marginBottom: 10,
  },
  transactionsText: {
    marginTop: 32,
    marginBottom: 10,
    marginLeft: 24,
    marginRight: 24,
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 18,
    color: "#000000",
    alignSelf: "flex-start"
  },
  listView: {
    flexDirection: "row",
    marginTop: 10,
    justifyContent: "space-between",
  },
  listContainer: {
    flex: 1,
    justifyContent: "flex-start",
  },
  arrowView: {
    width: 32,
    height: 32,
    backgroundColor: "#F7F7F7",
    borderRadius: 8,
    alignItems: "center",
    justifyContent: "center",
  },
  arrowImage: {
    color: "#209E00",
    fontSize: 15,
  },
  receivedText: {
    // width: 150,
    height: 20,
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 12,
    color: "#000000",
    marginLeft: 16,
    alignSelf: "flex-start"

  },
  dateText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 12,
    color: "#1e064c",
    opacity: 0.3,
    marginLeft: 16,
    alignSelf: "flex-start"
  },
  priceListText: {
    height: 20,
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 14,
    textAlign: "right",
    color: "#000000",

  },
  downImage: {
    color: "red",
    fontSize: 15,
  },
  sendView: {
    marginTop: 15,
  },
});
