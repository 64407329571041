import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  SafeAreaView,
  Switch,
  Pressable
} from "react-native";
import React, { useEffect, useState } from "react";
import Icon from "react-native-vector-icons/Feather";
import { useTranslation } from "react-i18next";
import { resetState } from "../../Redux/Models/User/ChangePinCode/Action";
import ToastAndroid from "react-native-root-toast";
import { useDispatch, useSelector } from "react-redux";
import AsyncStorage from "@react-native-async-storage/async-storage";
import SideBar from "./Components/SideBar";

const AccountSetting = ({ navigation }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [isEnabled, setIsEnabled] = useState(false);
  const toggleSwitch = (value) => {
    console.log("val", value);
    AsyncStorage.setItem("fingerPrint", JSON.stringify({ fingerPrint: value })).catch(err => console.log(err))
    setIsEnabled(previousState => !previousState);
  }
  const changePin = useSelector((state) => state.changePinCode);
  useEffect(() => {
    AsyncStorage.getItem("fingerPrint").then(val => setIsEnabled(JSON.parse(val).fingerPrint)).catch(err => console.log(err))
  }, [])
  useEffect(() => {
    if (changePin.success === true) {
      ToastAndroid.show(t("common:PinChanged"), ToastAndroid.LONG);
      dispatch(resetState());
    }
  }, [changePin]);
  const Validate = () => {
    ToastAndroid.show(t("common:authentication_failure"), ToastAndroid.LONG)
  };
  return (
    <SafeAreaView style={styles.mainContainer}>
      <SideBar/>

    </SafeAreaView>
  );
};

export default AccountSetting;

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
  },
  headerView: {
    flexDirection: "row",
    marginTop: 40,
    alignItems: "center",
    marginHorizontal: 24
  },
  accountText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 22,
    color: "#000000",
    flex: 1,
    textAlign: "center"
  },
  topContainer: {
    flex: 1,
    justifyContent: "center",
    marginHorizontal: 12
  },
  btnContainer: {
    padding: 16,
    borderRadius: 70,
    backgroundColor: "#FF4747",
    margin: 12
  },
  btnText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 16,
    alignItems: "center",
    textAlign: "center",
    color: "#FFFFFF",
    letterSpacing: 1
  },
  figherPrint: {
    marginTop: 15,
    flexDirection: "row",
    marginHorizontal: 24,
    justifyContent: "space-between"
  },
  figherText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 14,
    color: "#101820",
  },
});
