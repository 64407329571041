const POST_USER_REGISTER_REQUEST = "POST_USER_REGISTER_REQUEST";
const POST_USER_REGISTER_REQUEST_SUCCESS = "POST_USER_REGISTER_REQUEST_SUCCESS";
const POST_USER_REGISTER_REQUEST_FAILURE = "POST_USER_REGISTER_REQUEST_FAILURE";
const CHANGE_STATUS = "CHANGE_STATUS";


const userRegister = (formData) => {
  return {
    type: POST_USER_REGISTER_REQUEST,
    formData,
  };
};

export {
  userRegister,
  POST_USER_REGISTER_REQUEST,
  POST_USER_REGISTER_REQUEST_SUCCESS,
  POST_USER_REGISTER_REQUEST_FAILURE,
  CHANGE_STATUS
};
