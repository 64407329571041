import {
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  SafeAreaView
} from "react-native"; import React, { useState, useEffect, useRef } from "react";
import { getBanks } from "../../Redux/Models/TizdanBankAccounts/Actions";
import { RadioButton } from "react-native-paper";
import { useSelector, useDispatch } from "react-redux";
import Loading from "../Loading";
import { useTranslation } from "react-i18next";
import Icon from "react-native-vector-icons/Feather";
import SideBarConatainer from "../../Components/SideBarConatainer";
import ToastAndroid from "react-native-root-toast";
import StepContainer from "../../Components/StepContainer";

const BankTopUp = ({ navigation }) => {
  const { t } = useTranslation();
  const [selectBank, setSelectBank] = useState("");
  const dispatch = useDispatch();

  const tizdanBankAccounts = useSelector((state) => state.TizdanBankAccounts);

  const bankSelect = (bank) => {
    setSelectBank(bank);
  };
  useEffect(() => {
    dispatch(getBanks());
  }, []);
  useEffect(() => {
    tizdanBankAccounts.success &&
      setSelectBank(tizdanBankAccounts?.data.bank_accounts[0]);
  }, [tizdanBankAccounts]);
  const validate = () => {
    if (tizdanBankAccounts?.data.bank_accounts.length === 0) {
      ToastAndroid.show(t("common:please_select_a_bank"), ToastAndroid.LONG);
      return false;
    }
    return true;
  };
  const bankTopUp = () => {
    if (!validate()) {
      return;
    }
    let bankName = {
      bank_name: selectBank.name,
      bank_acc_title: selectBank.name,
      bank_acc_numb: Number(selectBank.account_number),
      tizdan_account_id: Number(selectBank.id),
    };

    navigation.navigate("BankDetail", { bankName })
  };
  return (
    <SideBarConatainer navigation={navigation}>
      <View style={styles.headerView}>
        <Icon
          onPress={() => navigation.navigate("TopUpMoney")}
          name="arrow-left"
          size={30}
        />
        <Text style={styles.bankText}>{t("common:bank_top_up")}</Text>
      </View>
      <View style={styles.addBankContainer}>
        <StepContainer totalStep={1} />
        <Text style={styles.descriptionText}>{t("common:MakeDeposit")}</Text>
        {tizdanBankAccounts.success &&
          tizdanBankAccounts.data.bank_accounts.map((val, ind) => {
            return (
              <TouchableOpacity
                onPress={() => bankSelect(val)}
                key={ind}
                style={selectBank.id === val.id ? styles.selectBank : styles.firstBank}
              >
                <View style={styles.meezanBankView}>
                  <RadioButton
                    value="meezanBank"
                    status={selectBank.id === val.id ? "checked" : "unchecked"}
                    color={selectBank.id === val.id ? "#FF4747":"#F8F8F8"}
                    onPress={() => bankSelect(val)}
                  />
                  <Text style={selectBank.id === val.id ?styles.colorMeezanText :styles.meezanText}>{val.name}</Text>
                </View>
                {selectBank.id === val.id && (
                  <View style={{ width: 327,marginLeft:38 }} >
                    <View style={styles.accountView}>
                      <Text style={styles.accountText}>{t("common:account_name")}</Text>
                      <Text style={styles.meezanBankText}>{val.name}</Text>
                    </View>
                    <View style={styles.numberView}>
                      <Text style={styles.accountText}>
                        {t("common:AccountNumber")}
                      </Text>
                      <Text style={styles.accountNumberText}>
                        {val.account_number}
                      </Text>
                    </View>
                  </View>
                )}
              </TouchableOpacity>
            );
          })}
        <View style={styles.btnView}>
          <TouchableOpacity onPress={bankTopUp} style={styles.submitButton}>
            <Text style={styles.submitText}>
              {t("common:next")}
            </Text>
          </TouchableOpacity>
        </View>
      </View>
      {tizdanBankAccounts.loading && <Loading></Loading>}
    </SideBarConatainer>
  );
};

export default BankTopUp;

const styles = StyleSheet.create({
  headerView: {
    flexDirection: "row",
    alignItems: "center",
  },
  arrowIcon: {
    fontSize: 25,
    color: "#101820",
  },
  bankText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 26,
    color: "#000000",
    marginLeft: 10,
  },
  addBankContainer: {
    backgroundColor: "#ffffff",
    marginTop: 27,
    borderRadius: 16,
    padding: 20,
  },
  descriptionText: {
    marginTop: 16,
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 14,
    color: "#101820",
    alignSelf: "flex-start",
    marginTop: 30
  },
  firstBank: {
    backgroundColor: "#F8F8F8",
    borderRadius: 10,
    marginTop: 20,
    padding: 10,
  },
  selectBank:{
    borderWidth:1,
    borderColor:"#FF4747",
    borderRadius: 10,
    marginTop: 20,
    padding: 10,
  },
  meezanBankView: {
    flexDirection: "row",
    alignItems: "center"
  },
  meezanText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 14,
    color: "#101820",
  },
  colorMeezanText:{
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 14,
    color: "#FF4747",
  },
  accountView: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 10,
    height: 25,
  },
  accountText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 12,
    color: "#000000",
  },
  meezanBankText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 12,
    color: "#959393",
    marginRight: 16,
  },
  numberView: {
    flexDirection: "row",
    height: 25,
    justifyContent: "space-between",
  },
  accountNumberText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 12,
    color: "#959393",
    marginRight: 16,
  },
  btnView: {
    alignItems: "flex-end",
    marginTop: 30,
    marginBottom: 15,
    flex: 1
  },
  submitButton: {
    backgroundColor: "#FF4747",
    borderRadius: 70,
    justifyContent: "center",
    alignItems: "center",
    padding: 16,
    alignSelf: "flex-end",
    width: 327,
  },
  submitText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 16,
    alignItems: "center",
    textAlign: "center",
    color: "#FFFFFF",
  },
});
