
const POST_GIFT_CARD_COMPANY_LIST_REQUEST = 'POST_GIFT_CARD_COMPANY_LIST_REQUEST';
const POST_GIFT_CARD_COMPANY_LIST_REQUEST_SUCCESS =  'POST_GIFT_CARD_COMPANY_LIST_REQUEST_SUCCESS';
const POST_GIFT_CARD_COMPANY_LIST_REQUEST_FAILURE =  'POST_GIFT_CARD_COMPANY_LIST_REQUEST_FAILURE';
const FILTER_GIFT_CARD_COMPANY_LIST =  'FILTER_GIFT_CARD_COMPANY_LIST';

const giftCardCompanyList = (formData)=>{

    return {
        type: POST_GIFT_CARD_COMPANY_LIST_REQUEST,
        formData
    }
}
const filterCardCompanyList = (search)=>{

  return {
      type: FILTER_GIFT_CARD_COMPANY_LIST,
      search
  }
}

export {
  giftCardCompanyList,
  filterCardCompanyList,
  POST_GIFT_CARD_COMPANY_LIST_REQUEST,
  POST_GIFT_CARD_COMPANY_LIST_REQUEST_SUCCESS,
  POST_GIFT_CARD_COMPANY_LIST_REQUEST_FAILURE,
  FILTER_GIFT_CARD_COMPANY_LIST
};