import {takeEvery, put, call} from 'redux-saga/effects';
import BASEURL from '../../URLs'
import {queryApi} from '../../QueryApi'
import {
  POST_DEPOSIT_SLIP_UPLOAD_REQUEST,
  POST_DEPOSIT_SLIP_UPLOAD_REQUEST_SUCCESS,
  POST_DEPOSIT_SLIP_UPLOAD_REQUEST_FAILURE,
} from './Actions';
//import {queryApi} from '../query-api';

function* handler() {
  yield takeEvery(POST_DEPOSIT_SLIP_UPLOAD_REQUEST, fileUpload);
}


function* fileUpload(action) {
  try {
      // API call
      console.log('Data',action.formData)
    const apiResponse = yield call(queryApi, {
      endpoint: BASEURL.documentUpload,
      method: 'POST',
      body:action.formData
    });
    console.log('HIT UPLOAD_FILE',apiResponse)
    if(!("error" in apiResponse)){
      yield put({
        type: POST_DEPOSIT_SLIP_UPLOAD_REQUEST_SUCCESS,
        payload: apiResponse,
       index:action.doc
      });

    }else{
      yield put({
        type: POST_DEPOSIT_SLIP_UPLOAD_REQUEST_FAILURE,
        payload: apiResponse.error
      });
    }

 
  } catch (err) {
    console.log(err);
    // Handle error
    yield put({
      type: POST_DEPOSIT_SLIP_UPLOAD_REQUEST_FAILURE,
      payload: {message:err}
    });
  }
}

export {handler};