import {
  GET_MDP_CREATE_CARD_REQUEST,
  GET_MDP_CREATE_CARD_REQUEST_SUCCESS,
  GET_MDP_CREATE_CARD_REQUEST_FAILURE,
} from "./Actions";

const initialState = {
  loading: null,
  message: null,
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_MDP_CREATE_CARD_REQUEST: {
      console.log(GET_MDP_CREATE_CARD_REQUEST);
      return {
        ...state,
        error: false,
        success:false,
        loading: true,
      };
    }
    case GET_MDP_CREATE_CARD_REQUEST_SUCCESS: {
      const { message } = action.payload;
      console.log(GET_MDP_CREATE_CARD_REQUEST_SUCCESS);
      return {
        ...state,
        loading: false,
        success:true,
        message,
      };
    }
    case GET_MDP_CREATE_CARD_REQUEST_FAILURE: {
      console.log(GET_MDP_CREATE_CARD_REQUEST_FAILURE);

      const { message } = action.payload;
      return {
        ...state,
        loading: false,
        success:false,
        error: true,
        message,
      };
    }
    default:
      return state;
  }
};

export { reducer };
