import {
  StyleSheet, Text, View, Dimensions, TextInput,
  I18nManager, SafeAreaView
} from "react-native";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Icon from "react-native-vector-icons/Feather";
import TopTabService from "./TopTabService";
import Loading from "../Loading";
import { useDispatch, useSelector } from "react-redux";
import { filterVoucherCompanyList } from "../../Redux/Models/ServiceVoucherCompanyList/Actions";
import AppHeader from "../../Components/AppHeader";

const height = Dimensions.get("window").height;

const ServiceVoucher = ({ navigation }) => {
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState("");

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(filterVoucherCompanyList(searchText));
  }, [searchText]);
  return (
    <SafeAreaView style={styles.mainContainer}>
      <AppHeader navigation={navigation} />

      <View style={styles.topContainer}>
        <View style={styles.topHeader}>
          <View style={styles.container}>
            <Icon
              size={30}
              name="arrow-left"
              onPress={() => navigation.goBack()}
            />
            <Text style={styles.serviceText}>{t("common:service_voucher")}</Text>
          </View>
          <View style={styles.searchView}>
            <Icon name="search" size={20} style={styles.searchIcon} />
            <TextInput
              textAlign={I18nManager.isRTL ? "right" : "left"}
              defaultValue={searchText}
              onChangeText={(search) => setSearchText(search)}
              style={styles.searchInput}
              placeholder={t("common:brand")}
            ></TextInput>
          </View>
          <View style={styles.topTabView}>
            <TopTabService />
          </View>
        </View>
      </View>
    </SafeAreaView>
  );
};

export default ServiceVoucher;

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
  },
  topContainer: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "center"
  },
  topHeader: {
    flex: 1,
    maxWidth: 1400
  },
  innerContainer: {
    marginLeft: 24,
    marginRight: 24,
  },
  container: {
    flexDirection: "row",
    marginTop: 25,
    alignItems: "center",
  },
  serviceText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 26,
    color: "#000000",
    marginLeft: 15
  },
  searchView: {
    marginBottom: 10,
    backgroundColor: "#FFFFFF",
    borderRadius: 60,
    flexDirection: "row",
    height: 40,
    alignItems: "center",
    marginTop: 17
  },
  searchIcon: {
    marginLeft: 15,
  },
  searchInput: {
    flex: 1,
    height: 40,
    borderRightRadius: 30,
    marginHorizontal: 7,
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 14,
    color: "#272727",
    outlineStyle: "none"
  },
  topTabView: {
    flex: 1,
  },
});
