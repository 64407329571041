import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  Image,
  TextInput,
  I18nManager,
  Dimensions,
  KeyboardAvoidingView,
  ScrollView,
  SafeAreaView,
  Platform,
} from "react-native";
import React, { useState, useEffect } from "react";
import Icon from "react-native-vector-icons/Feather";
import Money from "../../assets/image/money.png";
import Lyd from "../../assets/image/lyd.png";
import { Picker } from "@react-native-picker/picker";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getCurrencies } from "../../Redux/Models/Currencies/Actions";
import { cblNumber } from "../../Redux/Models/CblNumber/Actions";
import ToastAndroid from "react-native-root-toast";
import { requestExchange } from "../../Redux/Models/ExchangeMoney/Actions";
const height = Dimensions.get("window").height;
import SideBarConatainer from "../../Components/SideBarConatainer";
import Loading from "../Loading";
const WalletToWallet = ({ navigation }) => {
  const userDetails = useSelector((state) => state.userDetails);
  // const getCblNumber = useSelector((state) => state.cblNumber);
  // const requestExchangeMoney = useSelector((state) => state.requestExchange);
  const [wallet, setWallet] = useState(userDetails?.data.user.accounts[0]);
  const [sendCurrency, setSendCurrency] = useState("");
  const [receiveCurrency, setReceiveCurrency] = useState("");
  const [sendAmount, setSendAmount] = useState("");
  const [receiveAmount, setReceiveAmount] = useState("");
  // const [cblNum, setCblNum] = useState("");
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currencies = useSelector((state) => state.currencies);
  useEffect(() => {
    dispatch(getCurrencies());
  }, []);
  // useEffect(() => {
  //   dispatch(cblNumber());
  // }, []);
  const validate = () => {
    if (sendAmount === "") {
      ToastAndroid.show(t("common:Please_enter_amount"), ToastAndroid.LONG);
      return false;
    } else if (receiveAmount === "") {
      ToastAndroid.show(t("common:Please_enter_amount"), ToastAndroid.LONG);
      return false;
    } else if (sendCurrency !== receiveCurrency) {
      ToastAndroid.show(t("common:You_can_only_wallets"), ToastAndroid.LONG);
      return false;
    } else if (sendCurrency === receiveCurrency) {
      ToastAndroid.show(t("common:Currency_is_same"), ToastAndroid.LONG);
      return false;
    }
    return true;
  };

  return (
    <SideBarConatainer navigation={navigation}>
      <View style={styles.topUpContainer}>
        <View style={styles.topSection}>
          <Icon onPress={()=>navigation.goBack()} name="arrow-left" size={30} />
          <Text style={styles.titleText}>{t("common:wallet_to_wallet")}</Text>
        </View>
        <View style={styles.addWalletContainer}>
          <Text style={styles.walletsText}>
            {t("common:available_wallets")}
          </Text>
          <View style={styles.currencyContainer}>
            <Image source={Money} style={styles.moneyImage} />
            <Picker
              mode="dropdown"
              selectedValue={wallet}
              onValueChange={(selectedValue) => setWallet(selectedValue)}
              style={styles.pickerText}
            >
              {userDetails !== undefined &&
                userDetails.data.user.accounts.map((val, ind) => {
                  return (
                    <Picker.Item
                      key={ind}
                      label={val.name}
                      value={val}
                      style={styles.pickerItemText}
                    />
                  );
                })}
            </Picker>
          </View>
          <View style={styles.reciveContainer}>
            <View style={styles.recivedWallet}>
              <Text style={styles.reciveText}>{t("common:you_receive")}</Text>
              <View style={{ flexDirection: "row" }}>
                <TextInput
                  value={receiveAmount}
                  onChangeText={(newText) => setReceiveAmount(newText)}
                  style={styles.reciveMoney}
                  keyboardType="number-pad"
                ></TextInput>
                <View style={styles.pickerView}>
                  <Image source={Money} style={styles.moneyImage} />
                  <Picker
                    mode="dropdown"
                    selectedValue={receiveCurrency}
                    onValueChange={(selectedValue) =>
                      setReceiveCurrency(selectedValue)
                    }
                    style={styles.pickerText}
                  >
                    {currencies.success === true &&
                      currencies.data.currencies?.map((val) => {
                        return (
                          <Picker.Item
                            key={val.id}
                            label={val.currency_iso_code}
                            value={val.id}
                            style={styles.pickerItemText}
                          />
                        );
                      })}
                  </Picker>
                </View>
              </View>
            </View>
            <View style={styles.sendWallet}>
              <Text style={styles.reciveText}>{t("common:you_send")}</Text>
              <View style={{ flexDirection: "row" }}>
                <TextInput
                  value={sendAmount}
                  onChangeText={(newText) => setSendAmount(newText)}
                  style={styles.reciveMoney}
                  keyboardType="number-pad"
                ></TextInput>
                <View style={styles.pickerView}>
                  <Image source={Lyd} style={styles.moneyImage} />
                  <Picker
                    mode="dropdown"
                    selectedValue={sendCurrency}
                    onValueChange={(selectedValue) =>
                      setSendCurrency(selectedValue)
                    }
                    style={styles.pickerText}
                  >
                    {currencies.success === true &&
                      currencies.data.currencies?.map((val) => {
                        return (
                          <Picker.Item
                            key={val.id}
                            label={val.currency_iso_code}
                            value={val.id}
                            style={styles.pickerItemText}
                          />
                        );
                      })}
                  </Picker>
                </View>
              </View>
            </View>
          </View>
          <Text style={styles.moneyResult}>1 LYD = 0.16 USD</Text>
          <View style={styles.btnView}>
            <TouchableOpacity
              onPress={validate}
              style={styles.submitButton}
            >
              <Text style={styles.submitText}>
                {t("common:submit_request")}
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
      {(userDetails.loading || currencies.loading) && <Loading></Loading>}
    </SideBarConatainer>
  );
};

export default WalletToWallet;

const styles = StyleSheet.create({
  topUpContainer: {
    flex: 1,
  },
  topSection: {
    flexDirection: "row",
    alignItems:"center"
  },
  titleText: {
    fontSize: 26,
    marginLeft: 25,
    fontWeight: "700",
    color: "#000000",
    fontFamily: "Inter",
    marginLeft:15
  },
  icon: {
    color: "#000000",
    marginRight: 33,
  },
  addWalletContainer: {
    backgroundColor: "#ffffff",
    borderRadius: 16,
    marginTop:25
  },
  walletsText: {
    margin: 20,
    fontSize: 16,
    fontWeight: "400",
    lineHeight: 20,
    fontFamily: "Inter",
  },
  currencyContainer: {
    marginHorizontal: 20,
    height: 64,
    borderRadius: 10,
    borderWidth: 1,
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: 20,
    borderColor: "#E0E0E0",
    backgroundColor: "#ffffff",
  },
  moneyImage: {
    height: 24,
    width: 24,
  },
  pickerView: {
    flex: 1,
    marginTop: 4,
    flexDirection: "row",
  },
  pickerText: {
    flex: 1,
    height: 24,
    borderWidth: 1,
    borderColor: "white",
    borderStyle: "solid",
    fontSize: 14,
    fontWeight: "500",
    fontFamily: "Inter",
    color: "#101820",
  },
  pickerItemText: {
    color: "#101820",
    fontSize: 14,
    fontWeight: "500",
    fontFamily: "Inter",
  },
  moneyResult: {
    marginTop: 16,
    marginLeft: 20,
    fontSize: 16,
    fontWeight: "500",
    fontStyle: "normal",
    fontFamily: "Inter",
    lineHeight: 19.36,
  },
  btnView: {
    flex: 1,
    // alignItems:"flex-end",
    marginTop: 16,
    marginBottom: 30,
  },
  submitButton: {
    backgroundColor: "#FF4747",
    marginHorizontal: 20,
    borderRadius: 70,
    justifyContent: "center",
    alignItems: "center",
    width: 327,
    padding: 16,
  },
  submitText: {
    color: "#ffffff",
    fontSize: 16,
    fontWeight: "700",
    fontFamily: "Inter",
    fontStyle: "normal",
  },
  reciveContainer: {
    flexDirection: "row",
    marginTop: 16,
  },
  recivedWallet: {
    flex: 1,
    maxWidth: 308,
    maxHeight: 65,
    paddingBottom: 12,
    marginLeft: 20,
    borderBottomColor: "#E0E0E0",
    borderBottomWidth: 1,
  },
  reciveText: {
    fontSize: 14,
    fontWeight: "400",
    lineHeight: 24,
    color: "#A9ABAE",
    fontFamily: "Inter",
  },
  reciveMoney: {
    flex: 1,
    marginRight: 5,
    maxWidth: 154,
    height: 30,
    fontSize: 14,
    fontWeight: "400",
    lineHeight: 24,
    fontFamily: "Inter",
    outlineStyle: "none",
  },
  sendWallet: {
    flex: 1,
    maxWidth: 308,
    maxHeight: 65,
    marginLeft: 154,
    borderBottomColor: "#E0E0E0",
    borderBottomWidth: 1,
  },
});
