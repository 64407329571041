const POST_NEW_PASSWORD_REQUEST = "POST_NEW_PASSWORD_REQUEST";
const POST_NEW_PASSWORD_REQUEST_SUCCESS = "POST_NEW_PASSWORD_REQUEST_SUCCESS";
const POST_NEW_PASSWORD_REQUEST_FAILURE = "POST_NEW_PASSWORD_REQUEST_FAILURE";

const newPassword = (formData) => {
  return {
    type: POST_NEW_PASSWORD_REQUEST,
    formData,
  };
};

export {
  newPassword,
  POST_NEW_PASSWORD_REQUEST,
  POST_NEW_PASSWORD_REQUEST_SUCCESS,
  POST_NEW_PASSWORD_REQUEST_FAILURE,
};
