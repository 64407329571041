import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  Image,
  Dimensions,
  SafeAreaView,
  ScrollView,
} from "react-native";
import React, { useState } from "react";
import Icon from "react-native-vector-icons/Feather";
import { useTranslation } from "react-i18next";
import QrImage from "../../assets/image/qrImage.png";
const height = Dimensions.get("window").height;
import { BarCodeScanner } from "expo-barcode-scanner";
import AppHeader from "../../Components/AppHeader";

const MyQrCode = ({ navigation }) => {
  const { t } = useTranslation();
  const [hasPermission, setHasPermission] = useState(true);

  const askForCameraPermission = () => {
    (async () => {
      const { status } = await BarCodeScanner.requestPermissionsAsync();
      if (status === "granted") {
        navigation.navigate("ScanQrCode")

      }

    })();
  };

  return (
    <SafeAreaView style={styles.mainContainer}>
      <AppHeader navigation={navigation} />

      <View style={styles.topContainer}>
        <View style={styles.innerContainer}>
          <View style={styles.headerView}>
            <Icon
              name="arrow-left"
              size={30}
              onPress={() => navigation.goBack()}
            />
            <Text style={styles.qrCodeText}>{t("common:my_qr_code")}</Text>
          </View>
          <ScrollView showsHorizontalScrollIndicator={false}>
            <View style={styles.qrView}>
              <Image source={QrImage} style={styles.qrImage} />
              <Text style={styles.descriptionText}>
                {t("common:this_is_your_QR_code")}
              </Text>
              <TouchableOpacity
                onPress={askForCameraPermission}
                style={styles.button}
              >
                <Text style={styles.buttonText}>{t("common:scan_qr_code")}</Text>
              </TouchableOpacity>
            </View>
          </ScrollView>
        </View>
      </View>
    </SafeAreaView>
  );
};

export default MyQrCode;

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
  },
  topContainer: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "center",
  },
  innerContainer: {
    flex: 1,
    maxWidth: 1400,
  },
  headerView: {
    flexDirection: "row",
    marginTop: 25,
    alignItems: "center",
  },
  qrCodeText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 26,
    color: "#000000",
    marginLeft: 15,
  },
  descriptionText: {
    marginTop: 90,
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 14,
    textAlign: "center",
    letterSpacing: 0.25,
    color: "#000000",
  },
  qrView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 16,
    marginBottom: 35,
    marginTop: 20,
    backgroundColor: "#FFF",
  },
  qrImage: {
    marginTop:20,
    width:230,
    height:230,
    resizeMode: "contain"
  },
  button: {
    padding: 16,
    backgroundColor: "#FF4747",
    borderRadius: 70,
    width: 327,
    marginTop: 20,
    marginBottom:20
  },
  buttonText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 16,
    alignItems: "center",
    textAlign: "center",
    color: "#FFFFFF",
  },
});
