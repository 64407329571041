import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  Image,
  ImageBackground,
  SafeAreaView,
  TextInput ,
  I18nManager,
  ScrollView,
  useWindowDimensions 
} from "react-native";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Icon from "react-native-vector-icons/Feather";
import Lock from "../../assets/image/lock.png";
import FingerPrint from "../../assets/image/fingerprint.png";
import { useTranslation } from "react-i18next";
import Loading from "../Loading";
import { userRegister } from "../../Redux/Models/User/Register/Action";
import { getUniqueId } from "react-native-device-info";
import Clear from "../../assets/image/clear.png";
import { biometricVerify } from "../../Utils/HelperFunctions";
import AuthHeader from '../../Components/AuthHeader'
import { useRef } from "react";


const AddPinCode = ({ navigation, route }) => {
  const { t } = useTranslation();
  const [pinCode, setPinCode] = useState(["", "", "", ""]);
  const [pinCount, setPinCount] = useState(0);
  const register = useSelector((state) => state.register);
  const refInput = useRef([])
  const dispatch = useDispatch();
  const { formData } = route.params;
  const buttons = [1, 2, 3, 4, 5, 6, 7, 8, 9];
  const {width} = useWindowDimensions();

  const btnPress = (pin) => {
    if (pinCount < 4) {
      let tempArray = pinCode;
      tempArray[pinCount] = pin;
      setPinCode(tempArray);
      setPinCount((prev) =>{
        if(prev!==3){
          refInput.current[prev + 1].focus()
        }
        return prev + 1
      });
    }
  };
  const cancelBtn = () => {
    let tempArray = pinCode;
    tempArray[pinCount - 1] = "";
    setPinCode(tempArray);
    if (pinCount !== 0) {setPinCount((prev) =>{
        refInput.current[prev - 1].focus()
      return prev - 1})}else{
        refInput.current[0].focus()
      };
  };
  
  useEffect(() => {
    if (pinCount === 4) {
      getUniqueId().then((id) => {
        let addToForm = new FormData();
        formData.pass_code = pinCode.join("");
        formData.device_id = id;
        for (let [key, value] of Object.entries(formData)) {
          addToForm.append(key, value);
        }
        dispatch(userRegister(addToForm));
        
      });
    }
  }, [pinCount]);

  useEffect(() => {
    if (register.error === true) {
      setPinCode(["", "", "", ""]);
      setPinCount(0);
      alert(register.message);
    }
  }, [register]);

  useEffect(() => {
    refInput.current[0].focus()
  }, []);

    const touchIdVerify = async () =>{
      if(await biometricVerify()){
        getUniqueId().then((id) => {
          let addToForm = new FormData();
          formData.pass_code = "";
          formData.device_id = id;
          for (let [key, value] of Object.entries(formData)) {
            addToForm.append(key, value);
          }
          dispatch(userRegister(addToForm));
        });
      }
    }

    const addtoRef = (el) => {
      if (el && !refInput.current.includes(el)) {
        refInput.current.push(el)
      }
    }
  return (
    <SafeAreaView style={styles.topContainer}>
           <AuthHeader/>
           <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
        <View style={styles.subContainer}>
        <View style={styles.mainContectContainer}>
      <View style={[styles.container,width<700 && {padding:10}]}>
        <View>
        <Icon
          onPress={() => navigation.goBack()}
          name="arrow-left"
          size={30}
          style={styles.arrowIcon}
        /> 
        </View>

      
      <View style={styles.iconView}>
        <Image source={Lock} style={styles.lockIcon} />
        <Text style={styles.pinText}>{t("common:PinCode")}</Text>
      </View>
      <View style={[styles.baseView,width<700 && {height:60}]}>
                  <View style={styles.codeView}>
                    <TextInput
                      ref={addtoRef}
                      value={pinCode[0]}
                      textAlign={I18nManager.isRTL ? "right" : "left"}
                      maxLength={1}
                      style={styles.codeTextInput}
                      keyboardType="number-pad"
                      returnKeyType="done"
                      onChangeText={btnPress}
                    ></TextInput>
                  </View>
                  <View style={styles.codeView}>
                    <TextInput
                    value={pinCode[1]}
                      textAlign={I18nManager.isRTL ? "right" : "left"}
                      maxLength={1}
                      ref={addtoRef}
                      style={styles.codeTextInput}
                      keyboardType="number-pad"
                      returnKeyType="done"
                      onChangeText={btnPress}
                    ></TextInput>
                  </View>
                  <View style={styles.codeView}>
                    <TextInput
                    value={pinCode[2]}
                      textAlign={I18nManager.isRTL ? "right" : "left"}
                      maxLength={1}
                      ref={addtoRef}
                      style={styles.codeTextInput}
                      keyboardType="number-pad"
                      returnKeyType="done"
                      onChangeText={btnPress}
                    ></TextInput>
                  </View>
                  <View style={styles.codeView}>
                    <TextInput
                    value={pinCode[3]}
                      textAlign={I18nManager.isRTL ? "right" : "left"}
                      maxLength={1}
                      ref={addtoRef}
                      style={styles.codeTextInput}
                      keyboardType="number-pad"
                      returnKeyType="done"
                      onChangeText={btnPress}
                    ></TextInput>
                  </View>
          
                </View>
      
      <View style={[styles.btnContainer,width<700 && {gap:5}]}>
        <View style={styles.btnRow}>
          <TouchableOpacity
            style={styles.numberButton}
            onPress={() => btnPress(1)}
          >
            <Text style={styles.numberText}>1</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={styles.numberButton}
            onPress={() => btnPress(2)}
          >
            <Text style={styles.numberText}>2</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={styles.numberButton}
            onPress={() => btnPress(3)}
          >
            <Text style={styles.numberText}>3</Text>
          </TouchableOpacity>
        </View>
        <View style={styles.btnRow}>
          <TouchableOpacity
            style={styles.numberButton}
            onPress={() => btnPress(4)}
          >
            <Text style={styles.numberText}>4</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={styles.numberButton}
            onPress={() => btnPress(5)}
          >
            <Text style={styles.numberText}>5</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={styles.numberButton}
            onPress={() => btnPress(6)}
          >
            <Text style={styles.numberText}>6</Text>
          </TouchableOpacity>
        </View>
        <View style={styles.btnRow}>
          <TouchableOpacity
            style={styles.numberButton}
            onPress={() => btnPress(7)}
          >
            <Text style={styles.numberText}>7</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={styles.numberButton}
            onPress={() => btnPress(8)}
          >
            <Text style={styles.numberText}>8</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={styles.numberButton}
            onPress={() => btnPress(9)}
          >
            <Text style={styles.numberText}>9</Text>
          </TouchableOpacity>
        </View>
        <View style={styles.btnRow}>
          <TouchableOpacity onPress={touchIdVerify} style={styles.fingerBtn}>
            <ImageBackground
              source={FingerPrint}
              style={styles.fingerImage}
            ></ImageBackground>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => btnPress(0)}
            style={styles.numberButton}
          >
            <Text style={styles.numberText}>0</Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => cancelBtn()}
            style={styles.numberButton}
          >
            <Image source={Clear} style={styles.clearImage} />
          </TouchableOpacity>
        </View>
      </View>
      <View style={styles.btnView}>
       
              <TouchableOpacity onPress={() => nextStep()} style={styles.button}>
            <Text style={styles.buttonText}>{t("common:next")}</Text>
          </TouchableOpacity>
            </View>
      </View>

      {register.loading && <Loading />}
      </View>
        </View>
        
      </ScrollView>
    </SafeAreaView>
  );
};

export default AddPinCode;

const styles = StyleSheet.create({
  topContainer: {
    flex: 1,
    backgroundColor: "#F8F8F8",

  },
  btnView: {
    marginTop:30
    //justifyContent: "flex-end",
  },
  buttonText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 16,
    alignItems: "center",
    textAlign: "center",
    color: "#FFFFFF",
  },
  codeTextInput:{
    width: 40, outlineStyle:"none", fontWeight:'bold',fontSize:16
  },
  button: {
    padding: 16,
    borderRadius: 70,
    backgroundColor: "#FF4747",
  },
  container: {
    flex:1,
    padding:50
  },
  arrowIcon: {
    fontSize: 25,
    color: "#101820",
  },
  iconView:{
    paddingTop:16
  },
  baseView: {
    flexDirection: "row",
    justifyContent:'center',
    gap: 10,
    marginTop: 16,
    marginBottom: 16,
    height:126,

  },
  subContainer: {
    flex: 1,
    flexDirection:'row',
    justifyContent:'center'

  },
  mainContectContainer: {
    flex: 1,
    maxHeight:880,
    maxWidth:640,
    backgroundColor:'#ffffff',
    marginTop: 30,
    borderRadius: 10,
  },
  codeView: {
    flex: 1,
    maxWidth:100,
    borderRadius:10,
    backgroundColor: "#F9F9F9",
    justifyContent: "center",
    alignItems: "center",
    marginRight: 15,
  },
  btnRow: {
    flexDirection: "row",
    justifyContent: "center",
    flex:1,
    gap:32
  },
  lockIcon: {
    height: 25,
    width: 25,
    alignSelf: "center",
  },
  pinText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 18,
    color: "#000000",
    marginTop: 26,
    alignSelf: "center",
  },
  codeContainer: {
    flexDirection: "row",
    marginTop: 30,
    alignSelf: "center",
  },
  code1: {
    width: 16,
    height: 16,
    borderRadius: 15,
    borderWidth: 2,
    backgroundColor: "#FFF",
    borderColor: "#000",
    margin:8
  },
  code2: {
    width: 16,
    height: 16,
    borderRadius: 15,
    backgroundColor: "#000000",
    borderColor: "#000000",
    margin: 8,
  },
  btnContainer: {
    flex:1,
    justifyContent:'center',
    maxHeight:376,
        gap:32
  },
  numberButton: {
    flex: 1,
    padding:10,
    maxWidth:60,
    maxHeight:60,
    borderWidth: 1,
    borderColor: "#E0E0E0",
    borderRadius: 60,
    justifyContent: "center",
    alignItems: "center",
  },
  numberText: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 30,
    textAlign: "center",
    color: "#000000",
  },
  clearImage: {
    height: 32,
    width: 32,
  },
  fingerBtn: {
    maxHeight: 70,
    maxWidth: 70,
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  fingerImage: {
    width: 45,
    height: 45,
  },
});
