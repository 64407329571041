import { StyleSheet, Text, View } from "react-native";
import React from "react";

const StepContainer = ({ currentStep, totalStep }) => {
  return (
    <View style={styles.stepContainer}>
      <View style={styles.stepOne}>
        <Text style={styles.numberOne}>1</Text>
      </View>
      {new Array(totalStep).fill(totalStep).map((_, ind) => (
        <>
          <View style={ [ind+2 <=  currentStep ? {...styles.lineContainer,backgroundColor:"#FF4747"}:styles.lineContainer]}></View>

          <View style={[ ind+2 <= currentStep ? {...styles.stepTwo,backgroundColor:"#FF4747"}:styles.stepTwo]}>
            <Text style={[ind+2 <=  currentStep ? {...styles.numberTwo,color: "#FFFFFF",}:styles.numberTwo]}>{ind+2}</Text>
          </View></>
      ))
      }

    </View>

  );
};

export default StepContainer;

const styles = StyleSheet.create({
  stepContainer: {
    marginTop: 30,
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
  },
  stepOne: {
    height: 24,
    width: 24,
    backgroundColor: "#FF4747",
    borderRadius: 50,
    alignItems: "center",
    justifyContent: "center",
  },
  numberOne: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 14,
    textAlign: "center",
    color: "#FFFFFF",
  },
  lineContainer: {
    flex: 1,
    height: 5,
    maxWidth: 171,
    minWidth: 30,
    backgroundColor: "#F8F8F8",
  },
  stepTwo: {
    height: 24,
    width: 24,
    backgroundColor: "#F8F8F8",
    borderRadius: 50,
    alignItems: "center",
    justifyContent: "center",
  },
  numberTwo: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 14,
    textAlign: "center",
    color: "#A8A8A8",
  },
});
