import {
  StyleSheet,
  Text,
  View,
  Dimensions,
  ScrollView,
  TouchableOpacity,
} from "react-native";
import React, { useState, useEffect, useCallback } from "react";
import Icon from "react-native-vector-icons/Feather";
import { useTranslation } from "react-i18next";
import BeneficiariesList from "./Components/BeneficiariesList";
import { SafeAreaView } from "react-native-safe-area-context";
import { useFocusEffect } from "@react-navigation/native";
import AppHeader from '../../Components/AppHeader';

const height = Dimensions.get("window").height;
import { useDispatch, useSelector } from "react-redux";
import { userBeneficiaries } from "../../Redux/Models/Beneficiaries/Actions";
import Loading from "../Loading";

const Beneficiaries = ({ navigation }) => {
  const { t } = useTranslation();
  const beneficiaries = useSelector((state) => state.beneficiaries);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(userBeneficiaries());
  }, []);
  useFocusEffect(
    useCallback(() => {
      dispatch(userBeneficiaries());
    }, [dispatch])
  );

  return (
    <SafeAreaView style={styles.topContainer}>
      <AppHeader />
      <View style={styles.topHeader}>
        <View style={styles.mainContent}>
          <View style={styles.headerView}>
            <Icon name="arrow-left" size={30} onPress={() => navigation.goBack()} />
            <View style={styles.topSection}>
              <Text style={styles.editText}>{t("common:beneficiaries")}</Text>
              <View style={styles.btnView}>
                <TouchableOpacity
                  onPress={() => navigation.navigate("AddBeneficiaries")}
                  style={styles.button}
                >
                  <Text style={styles.buttonText}>
                    {t("common:add_beneficiaries")}
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
          <ScrollView style={{ flex: 1 }}>
            {beneficiaries.data?.map((val, ind) => {
              return (
                <BeneficiariesList
                  navigation={navigation}
                  key={ind}
                  beneficiaries={val}
                />
              );
            })}
          </ScrollView>
        </View>
      </View>
      {beneficiaries.loading && <Loading></Loading>}
    </SafeAreaView>
  );
};

export default Beneficiaries;

const styles = StyleSheet.create({
  topContainer: {
    flex: 1,
    backgroundColor: "#F8F8F8",
  },
  topHeader: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "center",
  },
  mainContent: {
    width: 1400,
  },
  headerView: {
    marginTop:25,
    flexDirection: "row",
    alignItems: "center"
  },
  topSection: {
    flex: 1, 
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: "center"
  },
  editText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 26,
    color: "#000000",
    marginLeft: 10
  },
  btnView: {
    flex: 1,
    alignItems: "flex-end",
  },
  button: {
    alignItems:"center",
    justifyContent:"center",
    padding: 16,
    backgroundColor: "#FF4747",
    borderRadius: 70,
    width: 327
  },
  buttonText: {
    fontSize: 16,
    fontWeight: '700',
    lineHeight: 26,
    fontFamily: 'Inter',
    fontStyle: 'normal',
    color: '#ffffff'
  }
});
