import {
  StyleSheet,
  Text,
  View,
  TextInput,
  I18nManager,
  Image,
  Dimensions,
  SafeAreaView,
} from "react-native";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Icon from "react-native-vector-icons/Feather";
import Lyd from "../../assets/image/lyd.png";
import TopTabGift from "./TopTabGift";
import { useSelector, useDispatch } from "react-redux";
import { filterCardCompanyList } from '../../Redux/Models/GiftCardCompanyList/Actions'
import { useEffect } from "react";
import AppHeader from "../../Components/AppHeader";

const height = Dimensions.get("window").height;


const GiftCard = ({ navigation }) => {
  const userDetails = useSelector((state) => state.userDetails);
  const dispatch = useDispatch()

  const { t } = useTranslation();
  const [searchText, setSearchText] = useState("")
  useEffect(() => {
    dispatch(filterCardCompanyList(searchText))
  }, [searchText])

  return (
    <SafeAreaView style={styles.mainContainer}>
      <AppHeader navigation={navigation} />

      <View style={styles.topContainer}>
        <View style={styles.topHeader}>
          <View style={styles.container}>
            <Icon
              name="arrow-left"
              size={30}
              onPress={() => navigation.goBack()}
            />
            <Text style={styles.giftText}>{t("common:gift_cards")}</Text>
            <View style={styles.textImageContainer}>
              <Image source={Lyd} style={styles.lydImage} />
              <Text style={styles.numberText}>
                {userDetails.data.user.accounts[0].balance}
              </Text>
            </View>
          </View>
          <View style={styles.searchView}>
            <Icon name="search" size={20} style={styles.searchIcon} />
            <TextInput
              textAlign={I18nManager.isRTL ? "right" : "left"}
              defaultValue={searchText}
              onChangeText={(search) => setSearchText(search)}
              style={styles.searchInput}
              placeholder={t("common:brand")}
              returnKeyType="done"

            ></TextInput>
          </View>
          <View style={styles.topTabView}>
            <TopTabGift />
          </View>
        </View>
      </View>
    </SafeAreaView>
  );
};

export default GiftCard;

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
  },
  topContainer: {
    flex: 1,
    flexDirection:"row",
    justifyContent:"center"
  },
  topHeader:{
    flex:1,
    maxWidth:1400
  },
  container: {
    flexDirection: "row",
    marginTop: 25,
    alignItems: "center",
  },
  giftText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 26,
    color: "#000000",
    marginHorizontal:15,
    flex:1
  },
  textImageContainer: {
    alignItems: "center",
    justifyContent: "flex-end",
    // flex: 1,
    flexDirection: "row",
  },
  lydImage: {
    width: 16,
    height: 16,
    marginRight: 10,
  },
  numberText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 16,
    color: "#FF4747",
  },
  searchView: {
    marginBottom: 10,
    backgroundColor: "#FFFFFF",
    borderRadius: 60,
    flexDirection: "row",
    height: 40,
    alignItems: "center",
    marginTop:17
  },
  searchIcon: {
    marginLeft: 15,
  },
  searchInput: {
    flex: 1,
    height: 40,
    borderRightRadius: 30,
    marginHorizontal: 7,
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 14,
    color: "#272727",
    outlineStyle: "none"
  },
  topTabView: {
    flex: 1,
  },
});
