import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  TextInput,
  I18nManager,
  SafeAreaView
} from "react-native";
import ToastAndroid from "react-native-root-toast";
import React, { useState, useEffect } from "react";
import Icon from "react-native-vector-icons/Feather";
import { useTranslation } from "react-i18next";
import AppHeader from "../../Components/AppHeader";

const PhoneNumberScan = ({ navigation }) => {
  const { t } = useTranslation();
  const [phoneNumber, setPhoneNumber] = useState("");
  const validate = () => {
    if (phoneNumber === "") {
      ToastAndroid.show(t("common:Please_Enter_the_Phone_Number"), ToastAndroid.LONG);
      return false;
    }
    return true;
  };
  const nextStep = () => {
    if (!validate()) {
      return;
    }
    navigation.navigate("QrPay", {
      phoneNumber: phoneNumber.replace(/-/g, ""),
    });
  }
  return (
    <SafeAreaView style={styles.mainContainer}>
      <AppHeader navigation={navigation} />
      <View style={styles.topContainer}>
        <View style={styles.topHeaderContainer}>
          <View style={styles.headerView}>
            <Icon
              name="arrow-left"
              size={30}
              onPress={() => navigation.goBack()}
            />
            <Text style={styles.scanText}>{t("common:scan")}</Text>
          </View>
          <View style={styles.inputView}>
            <Text style={styles.numberText}>{t("common:enter_number_to_pay")}</Text>
            <View style={styles.phoneView}>
              <View style={styles.numberContainer}>
                <Text style={styles.codeText}>+218</Text>
                <TextInput
                  textAlign={I18nManager.isRTL ? "right" : "left"}
                  keyboardType="phone-pad"
                  returnKeyType="done"
                  maxLength={12}
                  value={phoneNumber}
                  onChangeText={(text) => {
                    var cleaned = ("" + text).replace(/\D/g, "");
                    let normValue = `${cleaned.substring(0, 3)}${cleaned.length > 3 ? "-" : ""
                      }${cleaned.substring(3, 6)}${cleaned.length > 6 ? "-" : ""
                      }${cleaned.substring(6, 10)}`;
                    setPhoneNumber(normValue);
                  }}
                  style={styles.phoneInput}
                  placeholder="2568665222"
                ></TextInput>
              </View>
              <TouchableOpacity onPress={nextStep} style={styles.checkBtn}>
                <Icon name="check" color="#FFFFFF" size={18} />
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </View>
    </SafeAreaView>
  );
};

export default PhoneNumberScan;

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
  },
  topContainer: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "center",
  },
  topHeaderContainer: {
    flex: 1,
    maxWidth: 1400,
  },
  headerView: {
    flexDirection: "row",
    marginTop: 20,
    alignItems: "center",
  },
  scanText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 26,
    color: "#101820",
    marginLeft: 15
  },
  qrScan: {
    height: "100%",
    width: "100%",
  },
  btnText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 14,
    textAlign: "center",
    letterSpacing: 0.25,
    color: "#FFFFFF",
  },
  inputView: {
    backgroundColor: "#ffffff",
    marginTop: 27,
    borderRadius: 16,
    padding: 20,
  },
  numberText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 18,
    textAlign: "center",
    letterSpacing: 0.25,
    marginTop: 70,
  },
  phoneInput: {
    marginLeft: 15,
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 16,
    outlineStyle: "none"
  },
  phoneView: {
    flexDirection: "row",
    backgroundColor: "#FFFFFF",
    borderRadius: 48,
    height: 56,
    marginLeft: 24,
    marginRight: 24,
    marginTop: 32,
    alignItems: "center",
    borderWidth: 1,
    borderColor: "#A9ABAE",
  },
  numberContainer: {
    flex: 1,
    justifyContent: "flex-start",
    flexDirection: "row",
    alignItems: "center",
  },
  codeText:{
    color: "#FF4747",
    marginLeft: 15,
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 16,
  },
  checkBtn: {
    height: 32,
    width: 32,
    backgroundColor: "#FF4747",
    borderRadius: 20,
    marginRight: 16,
    alignItems: "center",
    justifyContent: "center"
  },
});
