import {
  StyleSheet,
  Text,
  View,
  Dimensions,
  ScrollView,
  TextInput,
  I18nManager,
  TouchableOpacity,
  Image,
  Platform,
  KeyboardAvoidingView,
  SafeAreaView,
} from "react-native";
import ToastAndroid from "react-native-root-toast";
import React, { useState, useEffect, useRef } from "react";
import Icon from "react-native-vector-icons/Feather";
import { useTranslation } from "react-i18next";
import { Picker } from "@react-native-picker/picker";
import Calendar from "../../assets/image/calendar.png";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../Loading";
import { addFamilyMember, addFamilyMemberStatus } from "../../Redux/Models/User/MyFamily/AddFamilyMember/Actions";
import { changeFamilyMemberPassword } from "../../Redux/Models/User/MyFamily/ChangePassword/Actions";
import DateTimePickerModal from "react-native-modal-datetime-picker";
import WebDatePicker from "../../Components/DatePickerTest";
import AppHeaer from "../../Components/AppHeader"

const height = Dimensions.get("window").height;

const EditFamilyMember = ({ navigation }) => {
  const { t } = useTranslation();
  const [relation, setRelation] = useState("Child");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [id, setId] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [createPassword, setCreatePassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [date, setDate] = useState();
  const [formateDate, setFormateDate] = useState("");
  const [isDatePickerVisible, setDatePickerVisibility] = useState(false);
  const [show, setShow] = useState(true)
  const showDatePicker = () => {
    setDatePickerVisibility(true);
    if (Platform.OS === "web") {
      setShow(false);
    }
  };
  const hideDatePicker = () => {
    setDatePickerVisibility(false);
  };
  const lastNameRef = useRef();
  const nationalIdRef = useRef();
  const phoneNumberRef = useRef();
  const passwordRef = useRef();
  const confirmPasswordRef = useRef();
  const dispatch = useDispatch();
  const userAddFamilyMember = useSelector((state) => state.addFamilyMember);
  const formData = new FormData();
  formData.append("name", firstName + " " + lastName);
  formData.append("national_number", id);
  formData.append("phone_number", phoneNumber.replace(/-/g, ""));
  formData.append("rel_type", relation);

  const Validate = () => {
    if (firstName === "") {
      ToastAndroid.show(
        t("common:Please_enter_your_first_name"),
        ToastAndroid.LONG
      );
      return false;
    } else if (lastName === "") {
      ToastAndroid.show(
        t("common:Please_enter_your_last_name"),
        ToastAndroid.LONG
      );
      return false;
    } else if (id === "") {
      ToastAndroid.show(t("common:Please_enter_id"), ToastAndroid.LONG);
      return false;
    } else if (date === undefined) {
      ToastAndroid.show(t("common:Please_select_DOB"), ToastAndroid.LONG);
      return false;
    } else if (phoneNumber === "") {
      ToastAndroid.show(
        t("common:Please_enter_the_phoneNumber"),
        ToastAndroid.LONG
      );
      return false;
    } else if (createPassword === "") {
      ToastAndroid.show(
        t("common:Please_enter_password"),
        ToastAndroid.LONG
      );
      return false;
    } else if (confirmPassword === "") {
      ToastAndroid.show(
        t("common:Please_enter_confirm_password"),
        ToastAndroid.LONG
      );
      return false;
    } else if (createPassword !== confirmPassword) {
      ToastAndroid.show(
        t("common:Please_enter_match_password"),
        ToastAndroid.LONG
      );
      return false;
    }
    return true;
  };
  const addFamilyMemberApi = () => {
    if (!Validate()) {
      return;
    }
    dispatch(addFamilyMember(formData));
  };
  useEffect(() => {
    if (userAddFamilyMember.error === true) {
      alert(userAddFamilyMember.message);
      dispatch(addFamilyMemberStatus())
    } if (userAddFamilyMember.success === true) {
      dispatch(addFamilyMemberStatus())
      let formData = new FormData()
      formData.append('password', confirmPassword)
      dispatch(changeFamilyMemberPassword({ formData, parms: userAddFamilyMember.data.id }))
      navigation.navigate("FamilyMember");
    }
  }, [userAddFamilyMember]);

  const DateFormate = (date) => {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    setFormateDate(
      date.getDate() +
      " " +
      monthNames[date.getMonth()] +
      " " +
      date.getFullYear()
    );
  };
  const dateRef = useRef();

  const handleClickOutside = (event) => {
    let date = dateRef.current.contains(event.target)
    if (date === false && Platform.OS === "web") {
      setDatePickerVisibility(false);
      setShow(true)
    }
  }
  React.useEffect(() => {
    if (Platform.OS === "web") {
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }
  }, []);
  const OnDateChange = (selectedDate) => {
    const currentDate = selectedDate;
    setDate(currentDate);
    DateFormate(currentDate);
    setDatePickerVisibility(false);
    if (Platform.OS === "web") {
      setShow(true);
    }
  };

  return (
    <SafeAreaView style={styles.mainContainer}>
      <AppHeaer />
      <View style={styles.topContainer}>
        <View style={styles.topHeader}>
          <View style={styles.headerView}>
            <TouchableOpacity onPress={() => navigation.goBack()}>
              <Icon
                name="arrow-left"
                size={30}
              />
            </TouchableOpacity>
            <Text style={styles.editText}>{t("common:add_family_member")}</Text>
          </View>
          <KeyboardAvoidingView
            style={{ flex: 1 }}
            behavior={Platform.OS === "ios" ? "padding" : "height"}
          >
            <ScrollView contentContainerStyle={{ flexGrow: 1 }} showsHorizontalScrollIndicator={false}>
              <View style={styles.innerContainer}>
                <Text style={styles.personalInfoText}>
                  {t("common:provide_basic_information_family")}
                </Text>
                <View style={styles.inputContainer}>
                  <View style={styles.inputView}>
                    <Text style={styles.nameText}>
                      {t("common:first_name_st")}
                      <Text style={{ color: "#FF4747" }}>*</Text>
                    </Text>
                    <TextInput
                      textAlign={I18nManager.isRTL ? "right" : "left"}
                      returnKeyType="next"
                      onSubmitEditing={() => lastNameRef.current.focus()}
                      defaultValue={firstName}
                      onChangeText={(newText) => setFirstName(newText)}
                      style={styles.input}
                    ></TextInput>
                  </View>
                  <View style={{ ...styles.inputView, marginLeft: 20 }}>
                    <Text style={styles.nameText}>
                      {t("common:last_name_st")}
                      <Text style={{ color: "#FF4747" }}>*</Text>
                    </Text>
                    <TextInput
                      textAlign={I18nManager.isRTL ? "right" : "left"}
                      returnKeyType="next"
                      onSubmitEditing={() => nationalIdRef.current.focus()}
                      ref={lastNameRef}
                      defaultValue={lastName}
                      onChangeText={(newText) => setLastName(newText)}
                      style={styles.input}
                    ></TextInput>
                  </View>
                </View>
                <View style={[styles.inputContainer,{zIndex: 2000}]}>
                  <View style={styles.inputView}>
                    <Text style={styles.nameText}>
                      {t("common:national_id_st")}
                      <Text style={{ color: "#FF4747" }}>*</Text>
                    </Text>
                    <TextInput
                      textAlign={I18nManager.isRTL ? "right" : "left"}
                      onSubmitEditing={() => phoneNumberRef.current.focus()}
                      ref={nationalIdRef}
                      keyboardType="number-pad"
                      returnKeyType="done"
                      defaultValue={id}
                      onChangeText={(newText) => setId(newText)}
                      style={styles.input}
                    ></TextInput>
                  </View>
                  <View style={{ ...styles.inputView, marginLeft: 20 }}>

                    <Text style={styles.nameText}>
                      {t("common:date_of_birth_st")}
                      <Text style={{ color: "#FF4747" }}>*</Text>
                    </Text>
                    <View style={styles.dateOfBirthView}>
                      <Text style={styles.dateText}>{formateDate}</Text>
                      <TouchableOpacity
                        onPress={showDatePicker}
                        style={styles.dateButton}
                      >
                        {show && (<Image source={Calendar} style={styles.calendarImage} />)}
                      </TouchableOpacity>
                      <View style={isDatePickerVisible ? { display: "flex" } : { display: "none" }} ref={dateRef}>
                        {Platform.OS === 'web' && (<WebDatePicker isDatePickerVisible={isDatePickerVisible} date={date} OnDateChange={OnDateChange} />)}
                      </View>
                      <DateTimePickerModal
                        isVisible={isDatePickerVisible}
                        mode="date"
                        onConfirm={OnDateChange}
                        onCancel={hideDatePicker}
                      />
                    </View>
                  </View>
                </View>
                <View style={styles.inputContainer}>
                  <View style={styles.inputView}>
                    <Text style={styles.nameText}>
                      {t("common:phone_number_st")}
                      <Text style={{ color: "#FF4747" }}>*</Text>
                    </Text>
                    <TextInput
                      textAlign={I18nManager.isRTL ? "right" : "left"}
                      onSubmitEditing={() => passwordRef.current.focus()}
                      ref={phoneNumberRef}
                      keyboardType="phone-pad"
                      returnKeyType="done"

                      maxLength={12}
                      value={phoneNumber}
                      onChangeText={(text) => {
                        var cleaned = ("" + text).replace(/\D/g, "");
                        let normValue = `${cleaned.substring(0, 3)}${cleaned.length > 3 ? "-" : ""
                          }${cleaned.substring(3, 6)}${cleaned.length > 6 ? "-" : ""
                          }${cleaned.substring(6, 10)}`;
                        setPhoneNumber(normValue);
                      }}
                      style={styles.input}
                    ></TextInput>
                  </View>
                  <View style={{ ...styles.inputView, marginLeft: 20 }}>

                    <Text style={styles.nameText}>
                      {t("common:relation_st")}
                      <Text style={{ color: "#FF4747" }}>*</Text>
                    </Text>
                    <View style={styles.pickerView}>
                      <Picker
                        mode="dropdown"
                        selectedValue={relation}
                        onValueChange={(selectedValue) => setRelation(selectedValue)}
                        style={styles.pickerItem}
                      >
                        <Picker.Item
                          label="Child"
                          value="Child"
                          style={styles.pickerItemText}
                        />
                        <Picker.Item
                          label="Spouse"
                          value="Spouse"
                          style={styles.pickerItemText}
                        />
                        <Picker.Item
                          label="Sibling"
                          value="Sibling"
                          style={styles.pickerItemText}
                        />
                        <Picker.Item
                          label="Other"
                          value="Other"
                          style={styles.pickerItemText}
                        />
                      </Picker>
                    </View>
                  </View>
                </View>
                <View style={styles.inputContainer}>
                  <View style={styles.inputView}>

                    <Text style={styles.nameText}>
                      {t("common:create_password_st")}
                      <Text style={{ color: "#FF4747" }}>*</Text>
                    </Text>
                    <TextInput
                      textAlign={I18nManager.isRTL ? "right" : "left"}
                      returnKeyType="next"
                      onSubmitEditing={() => confirmPasswordRef.current.focus()}
                      ref={passwordRef}
                      defaultValue={createPassword}
                      onChangeText={(newText) => setCreatePassword(newText)}
                      secureTextEntry={true}
                      style={styles.input}
                    ></TextInput>
                  </View>
                  <View style={{ ...styles.inputView, marginLeft: 20 }}>

                    <Text style={styles.nameText}>
                      {t("common:confirm_password_st")}
                      <Text style={{ color: "#FF4747" }}>*</Text>
                    </Text>
                    <TextInput
                      textAlign={I18nManager.isRTL ? "right" : "left"}
                      ref={confirmPasswordRef}
                      defaultValue={confirmPassword}
                      onChangeText={(newText) => setConfirmPassword(newText)}
                      secureTextEntry={true}
                      style={styles.input}
                      returnKeyType="done"
                    ></TextInput>
                  </View>
                </View>
                <View style={styles.btnView}>
                  <TouchableOpacity
                    onPress={addFamilyMemberApi}
                    style={styles.button}
                  >
                    <Text style={styles.buttonText}>{t("common:next")}</Text>
                  </TouchableOpacity>
                </View>
              </View>
            </ScrollView>
          </KeyboardAvoidingView>
        </View>
      </View>

      {userAddFamilyMember.loading && <Loading></Loading>}
    </SafeAreaView>
  );
};

export default EditFamilyMember;

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
  },
  topContainer: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "center"
  },
  topHeader: {
    flex: 1,
    maxWidth: 1400
  },
  headerView: {
    flexDirection: "row",
    marginTop: 25,
  },
  editText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 26,
    color: "#000000",
    marginLeft: 15
  },
  innerContainer: {
    padding: 24,
    backgroundColor: "#FFF",
    borderRadius: 16,
    marginTop:17
  },
  personalInfoText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 14,
    color: "#000000",
    alignSelf: "flex-start"
  },
  inputContainer: {
    flex: 1,
    flexDirection: "row",
  },
  inputView: {
    flex: 1,
  },
  nameText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 14,
    color: "#A9ABAE",
    marginTop: 10,
    alignSelf: "flex-start"
  },
  input: {
    borderBottomWidth: 1,
    borderColor: "#E7E8E9",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 14,
    color: "#101820",
    height: 56,
    outlineStyle: "none",
  },
  dateOfBirthView: {
    borderBottomWidth: 1,
    borderColor: "#F1F1F1",
    height: 49,
    flexDirection: "row",
    justifyContent: "space-between",
    zIndex: 2000
  },
  dateText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 14,
    color: "#101820",
    alignSelf: "flex-start",
    marginTop: 20
  },
  dateButton: {
    marginRight: 15,
    marginTop: 20,
    height: 18,
    width: 16.5,
  },
  calendarImage: {
    height: 18,
    width: 16.5,
    resizeMode: "contain"
  },
  pickerItemText: {
    color: "#101820",
    fontSize: 14,
    fontWeight: "500",
    fontFamily: "Inter",
  },
  pickerView: {
    borderBottomWidth: 1,
    borderColor: "#E7E8E9",
    height: 49,
    justifyContent: "center"
  },
  pickerItem: {
    height: 45,
    borderWidth: 1,
    borderColor: "white",
    borderStyle: "solid",
  },
  btnView: {
    flex: 1,
    marginTop: 32,
    alignItems: "flex-end",
  },
  button: {
    backgroundColor: "#FF4747",
    borderRadius: 70,
    padding: 16,
    width:327
  },
  buttonDisabled: {
    padding: 16,
    borderRadius: 70,
    opacity: 0.5,
    backgroundColor: "#FF4747",
  },
  buttonText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 16,
    alignItems: "center",
    textAlign: "center",
    color: "#FFFFFF",
  },
});
