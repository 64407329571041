import { View, StyleSheet, ActivityIndicator, Modal } from 'react-native'
import React from 'react'

export default function Loading() {
  return (
    <Modal transparent={true} visible={true}>
    <View style={styles.background}>
    
    </View>
    <View style={styles.loadingPostion}>
    <ActivityIndicator style={styles.loading} size="large" color="#FF0000" />    
    </View>

    </Modal>
  )
}

const styles = StyleSheet.create({
    loading: {
    
        width:52,
        height:52,
        borderRadius:50,
        backgroundColor: 'white',
 
         
    },
    loadingPostion: {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      justifyContent: 'center',
      alignItems: 'center'
    },
    background: {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
     
     opacity: 0.5,
      backgroundColor: 'black',
      justifyContent: 'center',
      alignItems: 'center'
  }
  })