const POST_REQUEST_CBL_REQUEST = "POST_REQUEST_CBL_REQUEST"
const POST_REQUEST_CBL_REQUEST_SUCCESS = "POST_REQUEST_CBL_REQUEST_SUCCESS"
const POST_REQUEST_CBL_REQUEST_FAILURE = "POST_REQUEST_CBL_REQUEST_FAILURE"
const RESET_CBL_REQUEST_STATE = "RESET_CBL_REQUEST_STATE"

const requestCbl = (formData) => {
  return {
    type: POST_REQUEST_CBL_REQUEST,
    formData,
  };
};

const resetRequestCbl = ()=>{

  return {
      type: RESET_CBL_REQUEST_STATE,
  }
}
export {
  requestCbl,
  resetRequestCbl,
  POST_REQUEST_CBL_REQUEST,
  POST_REQUEST_CBL_REQUEST_SUCCESS,
  POST_REQUEST_CBL_REQUEST_FAILURE,
  RESET_CBL_REQUEST_STATE
};
