
const GET_REQUEST_COMMENT_LIST_REQUEST = 'GET_REQUEST_COMMENT_LIST_REQUEST';
const GET_REQUEST_COMMENT_LIST_REQUEST_SUCCESS =  'GET_REQUEST_COMMENT_LIST_REQUEST_SUCCESS';
const GET_REQUEST_COMMENT_LIST_REQUEST_FAILURE =  'GET_REQUEST_COMMENT_LIST_REQUEST_FAILURE';

const getRequestCommentList = (params) => {
  return {
    type: GET_REQUEST_COMMENT_LIST_REQUEST,
    params,
  };
};
export {
  getRequestCommentList,
  GET_REQUEST_COMMENT_LIST_REQUEST,
  GET_REQUEST_COMMENT_LIST_REQUEST_SUCCESS,
  GET_REQUEST_COMMENT_LIST_REQUEST_FAILURE,
};