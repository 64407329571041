import {
  StyleSheet,
  Text,
  View,
  Dimensions,
  TextInput,
  I18nManager,
  Image,
  ScrollView,
  TouchableOpacity,
  Platform,
  KeyboardAvoidingView,
  SafeAreaView,
} from "react-native";
import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import Icon from "react-native-vector-icons/Feather";
import Profile from "../../assets/image/profile.png";
import Add from "../../assets/image/plus.png";
import TopTabFamily from "./TopTabFamily";
import { useDispatch, useSelector } from "react-redux";
import { useFocusEffect } from '@react-navigation/native';
import Loading from "../Loading";
import { userFamilyList, selectedFamilyMember } from "../../Redux/Models/User/MyFamily/MyFamilyList/Actions";
import { familyTransactionList } from "../../Redux/Models/User/MyFamily/FamilyMemberTransactions/Actions";
import AppHeaer  from "../../Components/AppHeader"


const FamilyMember = ({ navigation, route }) => {
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState("");
  const dispatch = useDispatch();
  const userMyFamilyList = useSelector((state) => state.myFamilyList);
  const deleteMember = useSelector((state) => state.deleteFamilyMember);
  const familyMemberTransactions = useSelector((state) => state.familyMemberTransactions);
  const [filterMember, setFilterMember] = useState([])
  useEffect(() => {
    if (userMyFamilyList.selectedFamilyMember === undefined) {
      dispatch(selectedFamilyMember(userMyFamilyList.data[0]))
    }
  }, [userMyFamilyList.data]);
  
  useEffect(() => {
    let filterMember = userMyFamilyList.data?.filter(val => val.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1)
    setFilterMember(filterMember)
    console.log(searchText)


  }, [searchText, userMyFamilyList.data])


  useFocusEffect(
    useCallback(() => {
      dispatch(userFamilyList());

    }, [dispatch])
  );


  const selectMember = (value) => {

    // let formData = new FormData()
    // formData.append("phone_number",value.phone_number)
    //     dispatch(familyTransactionList(formData))
    dispatch(selectedFamilyMember(value))
  }
  return (
    <SafeAreaView style={styles.mainContainer}>
      <AppHeaer/>
      <View style={styles.topContainer}>
        <View style={styles.topHeader}>
            <View style={styles.container}>
              <Icon
                name="arrow-left"
                size={30}
                onPress={() => navigation.goBack()}
              />
              <Text style={styles.transferText}>
                {t("common:family_members")}
              </Text>
            </View>
            <View style={styles.searchView}>
              <Icon name="search" style={styles.searchIcon} size={20} />
              <TextInput
                textAlign={I18nManager.isRTL ? "right" : "left"}
                defaultValue={searchText}
                onChangeText={(newText) => setSearchText(newText)}
                style={styles.searchInput}
                returnKeyType="done"
                placeholder={t("common:Find_your_family_member")}
              ></TextInput>
            </View>
            <View style={styles.imageContainer}>
            <ScrollView
              horizontal={true}
              showsHorizontalScrollIndicator={false}
              style={styles.innerContainer}
            >
              <View style={styles.addNewView}>
                <TouchableOpacity
                  onPress={() => navigation.navigate("AddFamilyMember")}
                  style={styles.btnView}
                >
                  <Image source={Add} style={styles.addImage} />
                </TouchableOpacity>
                <Text style={styles.addNewText}>{t("common:add_new")}</Text>
              </View>
              {filterMember.map((value, index) => {
                return (
                  <TouchableOpacity
                    onPress={() => selectMember(value)}
                    key={index}
                    style={styles.imageView}
                  >
                    <Image
                      source={
                        value.profile_image === null || value.profile_image === ""
                          ? Profile
                          : { uri: value.profile_image }
                      }
                      style={
                        value.id === userMyFamilyList.selectedFamilyMember?.id
                          ? styles.image1
                          : styles.image
                      }
                    />

                    <Text style={styles.nameText}>
                      {value.name.split(" ")[0]}
                    </Text>
                  </TouchableOpacity>
                );
              })}
            </ScrollView>
          </View>
          <View style={styles.topTabView}>
            {userMyFamilyList.data.length > 0 && <TopTabFamily />}
          </View>
          {(userMyFamilyList.loading ||
            deleteMember.loading ||
            familyMemberTransactions.loading) && <Loading></Loading>}
        </View>
      </View>
    </SafeAreaView>
  );
};

export default FamilyMember;

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
  },
  topContainer: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "center"
  },
  topHeader: {
    flex: 1,
    maxWidth: 1400
  },
  innerContainer: {
    flexDirection: "row",
    padding:20
  },
  container: {
    flexDirection: "row",
    marginTop: 25,
    alignItems: "center",
  },
  transferText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 26,
    color: "#000000",
    marginLeft:15
  },
  searchView: {
    bottom: 0,
    marginTop:27,
    backgroundColor: "#FFFFFF",
    borderRadius: 60,
    flexDirection: "row",
    height: 40,
    alignItems: "center",
  },
  searchIcon: {
    marginLeft: 15,
  },
  searchInput: {
    flex: 1,
    height: 40,
    borderRightRadius: 30,
    marginHorizontal: 7,
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 14,
    color: "#272727",
    outlineStyle: "none"
  },
  imageContainer: {
    flexDirection: "row",
    marginTop: 32,
    backgroundColor:"#FFFFFF",
    height:190,
    alignItems:"center",
    borderRadius:16,
    marginBottom:20
  },
  addNewView: {},
  btnView: {
    height: 94,
    width: 94,
    borderRadius: 50,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#FF4747",
  },
  addImage: {
    height: 26,
    width: 26,
  },
  addNewText: {
    marginTop: 8,
    textAlign: "center",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 12,
    color: "#10121F",
  },
  imageView: {
    alignItems: "center",
    marginLeft: 8,
  },
  image1: {
    height: 94,
    width: 94,
    borderRadius: 50,
    borderWidth: 2,
    borderColor: "#FF4747",
  },
  image: {
    height: 94,
    width: 94,
    borderRadius: 50,
  },
  nameText: {
    marginTop: 8,
    textAlign: "center",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 12,
    color: "#10121F",
  },
  topTabView: {
    flex: 1,
  },
});
