
const GET_REQUEST_DETAILS_REQUEST = 'GET_REQUEST_DETAILS_REQUEST';
const GET_REQUEST_DETAILS_REQUEST_SUCCESS =  'GET_REQUEST_DETAILS_REQUEST_SUCCESS';
const GET_REQUEST_DETAILS_REQUEST_FAILURE =  'GET_REQUEST_DETAILS_REQUEST_FAILURE';
const RESET_REQUEST_DETAILS = "RESET_REQUEST_DETAILS";
const requestDetails = (params)=>{

    return {
        type: GET_REQUEST_DETAILS_REQUEST,
        params
    }
}
const resetRequestDetails = () => {
  return {
    type: RESET_REQUEST_DETAILS,
  };
};

export {
  requestDetails,
  resetRequestDetails,
  GET_REQUEST_DETAILS_REQUEST,
  GET_REQUEST_DETAILS_REQUEST_SUCCESS,
  GET_REQUEST_DETAILS_REQUEST_FAILURE,
  RESET_REQUEST_DETAILS,
};