import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  ScrollView,
  TextInput,
  I18nManager,
  Dimensions,
  Image
} from "react-native";
import React, { useState, useEffect } from "react";
import Icon from "react-native-vector-icons/Feather";
import BackIcon from "react-native-vector-icons/Feather";
import { useDispatch, useSelector } from "react-redux";
import {
  userLogin,
  RESET_LOGIN_STATE,
} from "../../Redux/Models/User/Login/Actions";
import Loading from "../Loading";
import { SafeAreaView } from "react-native-safe-area-context";
import { useTranslation } from "react-i18next";
import {
  forgetPassword,
  RESET_FORGET_PASSWORD_STATE,
} from "../../Redux/Models/User/ForgetPassword/Action";
import LogoImage from "../../assets/image/logotizdan.png"

const height = Dimensions.get("window").height;

const SignIn = ({ navigation, route }) => {
  const [password, setPassword] = useState();
  const [disabled, setDisabled] = useState(false);
  const { t } = useTranslation();

  const userInfo = useSelector((state) => state.user);
  const userForgetPassword = useSelector((state) => state.forgetPassword);
  const { phoneNumber } = route.params;
  const dispatch = useDispatch();
  const formData = {
    phone_number: phoneNumber,
    password: password,
  };
  const addToForm = new FormData();
  const userLoginApi = () => {
    for (let [key, value] of Object.entries(formData)) {
      addToForm.append(key, value);
    }
    dispatch(userLogin(addToForm));
  };
  const forgetPasswordApi = () => {
    dispatch({ type: RESET_LOGIN_STATE })
    addToForm.append("phone_number", phoneNumber);
    dispatch(forgetPassword(addToForm));
  };
  useEffect(() => {
    if (userInfo.error === true) {
      alert(userInfo.message);
      dispatch({ type: RESET_LOGIN_STATE })

    }

  }, [userInfo])
  useEffect(() => {
    if (userForgetPassword.error === true) {
      alert(userForgetPassword.message);
      dispatch({ type: RESET_FORGET_PASSWORD_STATE })

    } else if (userForgetPassword.success === true && userForgetPassword.loading === false) {

      navigation.navigate("ForgetPassword", { phoneNumber });

    }
  }, [userForgetPassword])

  useEffect(() => {
    if (!!password) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [password]);
  return (
    <SafeAreaView style={styles.mainContainer}>
      <View style={styles.topContainer}>
        <Image source={LogoImage} style={styles.logoImage} />
      </View>
      <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
        <View style={styles.SubContainer}>
          <View style={styles.mainContectContainer}>
            <View style={styles.innerContainer}>
              <View style={styles.namePageContainer}>
                <Icon
                  onPress={() => navigation.goBack()}
                  name="arrow-left"
                  size={30}
                  style={styles.arrowIcon}
                />
                <Text style={styles.numberText}>
                  {t("common:welcome_back")}
                </Text>
              </View>
              <Text style={styles.authText}>
                {t("common:text_you_code")}
              </Text>
              <View style={styles.inputView}>
                <Text style={styles.emailInput}>
                  {phoneNumber.replace("00", "+")}
                </Text>

                <TextInput
                  textAlign={I18nManager.isRTL ? "right" : "left"}
                  secureTextEntry={true}
                  placeholder={t("common:password")}
                  onChangeText={(password) => setPassword(password)}
                  style={styles.passwordInput}
                ></TextInput>
                <TouchableOpacity
                  onPress={() => forgetPasswordApi()}
                  style={styles.forgetButton}
                >
                  <Text style={styles.forgetText}>
                    {t("common:forgot_password")}
                  </Text>
                </TouchableOpacity>
              </View>
              <View style={styles.btnView}>
                <TouchableOpacity
                  onPress={() => userLoginApi()}
                  style={disabled ? styles.buttonDisabled : styles.button}
                  disabled={disabled}
                >
                  <Text style={styles.buttonText}>{t("common:Continue")}</Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </View>
      </ScrollView>
      {(userInfo.loading || userForgetPassword.loading) && <Loading></Loading>}
    </SafeAreaView>
  );
};

export default SignIn;

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    backgroundColor: "#F8F8F8",
  },

  topContainer: {
    backgroundColor: "#ffffff",
    paddingTop: 10,
    paddingBottom: 10,
    width: "100%",
  },
  logoImage: {
    width: 120,
    height: 50,
    resizeMode: "contain",
    marginLeft: 15
  },
  SubContainer: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "center",
  },
  mainContectContainer: {
    flex: 1,
    maxHeight: 580,
    maxWidth: 640,
    marginHorizontal: 15,
    backgroundColor: "#ffffff",
    marginTop: 30,
    borderRadius: 10,
    marginBottom: 51,
  },
  innerContainer: {
    margin: 50,
    flex: 1,
  },
  namePageContainer: {
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  numberText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "700",
    marginLeft: 15,
    fontSize: 26,
    color: "#101820",
    alignSelf: "flex-start",
  },
  authText: {
    marginTop: 16,
    color: "#101820",
    fontSize: 16,
    fontWeight: "400",
    fontStyle: "normal",
    fontFamily: "Inter",
    alignSelf: "flex-start",
  },

  nameText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "700",
    marginLeft: 15,
    fontSize: 26,
    color: "#101820",
    alignSelf: "flex-start",
  },
  inputView: {
    marginTop: 44,
  },
  emailInput: {
    height: 30,
    color: "#101820",
    borderBottomWidth: 1,
    borderColor: "#E7E8E9",
  },
  passwordInput: {
    height: 56,
    color: "#101820",
    borderBottomWidth: 1,
    borderColor: "#E7E8E9",
    marginTop: 25,
    outlineStyle: "none",
  },
  forgetButton: {
    marginTop: 25,
    alignSelf: "flex-end",
    marginRight: 10,
  },
  forgetText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 14,
    textAlign: "right",
    color: "#FF4747",
  },
  btnView: {
    flex: 1,
    justifyContent: "flex-end",
  },
  button: {
    padding: 16,
    borderRadius: 70,
    backgroundColor: "#FF4747",
  },
  buttonDisabled: {
    padding: 16,
    borderRadius: 70,
    opacity: 0.5,
    backgroundColor: "#FF4747",
  },
  buttonText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 16,
    alignItems: "center",
    textAlign: "center",
    color: "#FFFFFF",
  },
});
