
const GET_CURRENCIES_LIST_REQUEST = 'GET_CURRENCIES_LIST_REQUEST';
const GET_CURRENCIES_LIST_REQUEST_SUCCESS =  'GET_CURRENCIES_LIST_REQUEST_SUCCESS';
const GET_CURRENCIES_LIST_REQUEST_FAILURE =  'GET_CURRENCIES_LIST_REQUEST_FAILURE';

const getCurrencies = ()=>{

    return {
        type: GET_CURRENCIES_LIST_REQUEST,
    }
}

export {
    getCurrencies,
    GET_CURRENCIES_LIST_REQUEST,
    GET_CURRENCIES_LIST_REQUEST_SUCCESS,
    GET_CURRENCIES_LIST_REQUEST_FAILURE,
};