import {
  StyleSheet,
  Text,
  View,
  Image,
  Dimensions,
  TouchableOpacity,
  SafeAreaView,
  ScrollView
} from "react-native";
import React, { useEffect } from "react";
import Icon from "react-native-vector-icons/Feather";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { changeServiceVoucherStatus } from "../../Redux/Models/BuyServiceVoucher/Actions";
import Loading from "../Loading";
import Alert from "../../Components/Alert";
import AppHeader from "../../Components/AppHeader";

const height = Dimensions.get("window").height;

const CompanyVoucherDetail = ({ navigation, route }) => {
  const { t } = useTranslation();
  const itemDetail = route.params?.item;
  const giftCardDetail = route.params?.giftCard;

  const dispatch = useDispatch();
  const buyVoucher = useSelector((state) => state.buyServiceVoucher);
  const userDetails = useSelector((state) => state.userDetails);

  const formData = new FormData();
  formData.append("amount", route.params?.giftCard.amount);
  formData.append("company_id", route.params?.item.id);
  formData.append("package_name", route.params?.giftCard.package_name);
  formData.append("lyd_price", route.params?.giftCard.lyd_price);
  const buyCard = () => {
    if (
      userDetails.data.user?.accounts[0].balance < route.params?.giftCard.amount
    ) {
      Alert(
        "",
        t("common:Total_amount_of_selected") +
        userDetails.data.user?.accounts[0].currency.currency_iso_code +
        " " +
        route.params?.giftCard.amount +
        t("common:But_add_balance_in_your_wallet"),
        [{ text: t("common:Ok"), onPress: () => "" }]
      );
      return;
    }
    navigation.navigate("BuyVoucherVerifyPin", { formData, giftCardDetail, itemDetail });
  };
  useEffect(() => {
    if (buyVoucher.error === true) {
      return Alert("Error", buyVoucher.message, [
        {
          text: "OK",
          onPress: () => {
            dispatch(changeServiceVoucherStatus());
            navigation.navigate("ServiceVoucher");
          },
        },
      ]);
    }
    if (buyVoucher.success === true) {
      return Alert("Congratulation", "Voucher purchased successfully", [
        {
          text: "OK",
          onPress: () => {
            dispatch(changeServiceVoucherStatus());
            navigation.navigate("ServiceVoucher");
          },
        },
      ]);
    }
  }, [buyVoucher]);

  return (
    <SafeAreaView style={styles.mainContainer}>
      <AppHeader navigation={navigation} />

      <View style={styles.topHeader}>
        <View style={styles.headerContainer}>
          <View style={styles.headerView}>
            <Icon
              name="arrow-left"
              size={30}
              onPress={() => navigation.goBack()}
            />
            <Text style={styles.cardText}>{t("common:voucher")}</Text>
          </View>
          <View style={styles.topContainer}>
            <View style={styles.cardContainer}>
              <Image
                source={{ uri: route.params?.item.logo_color }}
                style={styles.amazonImage}
              />
            </View>
            <View style={styles.innerContainer}>
              <View style={styles.container}>
                <Text style={styles.nameText}>{t("common:company_name")}</Text>
                <Text style={styles.summaryText}> {route.params?.item.title}</Text>
              </View>

              <View style={styles.container}>
                <Text style={styles.nameText}>{t("common:amount")}</Text>
                <Text style={styles.summaryText}>
                  ${route.params?.giftCard.amount}
                </Text>
              </View>

              <View style={styles.container}>
                <Text style={styles.nameText}>{t("common:instructions")}</Text>
                <Text style={{ ...styles.summaryText, flex: 1 }}>
                  {route.params?.item.instructions}
                </Text>
              </View>
              <View style={styles.btnView}>
                <TouchableOpacity onPress={buyCard} style={styles.button}>
                  <Text style={styles.btnText}>{t("common:buy")}</Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </View>
      </View>

      {buyVoucher.loading && <Loading></Loading>}
    </SafeAreaView>
  );
};

export default CompanyVoucherDetail;

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
  },
  topHeader: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "center",
  },
  headerContainer: {
    flex: 1,
    maxWidth: 1400,
  },
  headerView: {
    flexDirection: "row",
    marginTop: 40,
    marginBottom: 20,
    alignItems: "center",
  },
  arrowIcon: {
    marginLeft: 24,
  },
  cardText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 22,
    color: "#000000",
    marginLeft: 15
  },
  topContainer: {
    marginTop: 25,
    padding: 20,
    borderRadius: 16,
    backgroundColor: "#FFF",
    flexDirection: "row",
  },
  innerContainer: {
    flex: 1,
    marginLeft: 80,
  },
  cardContainer: {
    alignItems: "center",
    justifyContent: "center",
  },
  amazonImage: {
    width: 390,
    height: 212,
    resizeMode: "contain"
  },
  container: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 24,
  },
  nameText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 14,
    color: "#101820",
  },
  summaryText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 14,
    textAlign: "right",
    color: "#FF4747",
  },
  btnView: {
    lex: 1,
    alignItems: "flex-end",
    marginTop: 30,
    marginBottom: 10,
  },
  button: {
    backgroundColor: "#FF4747",
    borderRadius: 70,
    justifyContent: "center",
    alignItems: "center",
    width: 327,
    padding: 16,
  },
  btnText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 16,
    alignItems: "center",
    textAlign: "center",
    color: "#FFFFFF",
  },
});
