import {
  StyleSheet,
  Text,
  View,
  Dimensions,
  ScrollView,
  TouchableOpacity,
  SafeAreaView,
} from "react-native";
import React, { useState } from "react";
import Icon from "react-native-vector-icons/Feather";
import { useTranslation } from "react-i18next";
import ExistingCblList from "./ExistingCblList";
const height = Dimensions.get("window").height;

const ExistingCbl = ({ navigation }) => {
  const { t } = useTranslation();

  return (
    <SafeAreaView style={styles.topContainer}>
      <View style={styles.headerView}>
        <Icon
          name="arrow-left"
          size={24}
          style={styles.arrowIcon}
          onPress={() => navigation.goBack()}
        />
        <Text style={styles.cblText}>{t("common:cbl")}</Text>
      </View>
      <ScrollView contentContainerStyle={styles.scrollView}>
        <View style={styles.innerContainer}>
          <ExistingCblList />
        </View>
      </ScrollView>
      <View style={styles.addView}>
        <TouchableOpacity
          onPress={() => navigation.navigate("AddExistingCbl")}
          style={styles.button}
        >
          <Icon name="plus" style={{ color: "#FFFFFF" }} />
        </TouchableOpacity>
      </View>
    </SafeAreaView>
  );
};

export default ExistingCbl;

const styles = StyleSheet.create({
  topContainer: {
    height: height,
    backgroundColor: "#FFFFFF",
  },
  headerView: {
    flexDirection: "row",
    marginTop: 44,
    marginBottom: 40,
    alignItems: "center",
    marginLeft:24,
  },
  cblText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 22,
    color: "#000000",
    marginLeft:110,
    textAlign:"center"
  },
  scrollView: {
    height:height,
  },
  innerContainer:{
    flex:1,
    marginRight:24,
    marginLeft:24
  },
  button: {
    justifyContent: "center",
    alignItems: "center",
    width: 56,
    height: 56,
    right: 30,
    backgroundColor: "#FF4747",
    borderRadius: 70,
  },
  addView: {
    flex:1,
    marginBottom:51,
    justifyContent: "flex-end",
    alignItems: "flex-end",
  },
});
