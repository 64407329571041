
const POST_BUY_SERVICE_VOUCHER_REQUEST = 'POST_BUY_SERVICE_VOUCHER_REQUEST';
const POST_BUY_SERVICE_VOUCHER_REQUEST_SUCCESS =  'POST_BUY_SERVICE_VOUCHER_REQUEST_SUCCESS';
const POST_BUY_SERVICE_VOUCHER_REQUEST_FAILURE =  'POST_BUY_SERVICE_VOUCHER_REQUEST_FAILURE';
const CHANGE_BUY_SERVICE_VOUCHER_STATUS = "CHANGE_BUY_SERVICE_VOUCHER_STATUS";

const buyServiceVoucher = (formData) => {
  return {
    type: POST_BUY_SERVICE_VOUCHER_REQUEST,
    formData,
  };
};
const changeServiceVoucherStatus = () => {
  return {
    type: CHANGE_BUY_SERVICE_VOUCHER_STATUS,
  };
};
export {
  buyServiceVoucher,
  changeServiceVoucherStatus,
  POST_BUY_SERVICE_VOUCHER_REQUEST,
  POST_BUY_SERVICE_VOUCHER_REQUEST_SUCCESS,
  POST_BUY_SERVICE_VOUCHER_REQUEST_FAILURE,
  CHANGE_BUY_SERVICE_VOUCHER_STATUS,
};