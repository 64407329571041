
const POST_DEPOSIT_SLIP_UPLOAD_REQUEST = 'POST_DEPOSIT_SLIP_UPLOAD_REQUEST';
const POST_DEPOSIT_SLIP_UPLOAD_REQUEST_SUCCESS =  'POST_DEPOSIT_SLIP_UPLOAD_REQUEST_SUCCESS';
const POST_DEPOSIT_SLIP_UPLOAD_REQUEST_FAILURE =  'POST_DEPOSIT_SLIP_UPLOAD_REQUEST_FAILURE';
const RESET_DEPOSIT_SLIP_UPLOAD_STATE = 'RESET_DEPOSIT_SLIP_UPLOAD_STATE';
const CLEAR_FILES_ON_DEPOSIT_SLIP = "CLEAR_FILES_ON_DEPOSIT_SLIP";

const uploadDepositSlip = (formData)=>{
    return {
        type: POST_DEPOSIT_SLIP_UPLOAD_REQUEST,
        formData,
    }
}

const resetUploadDepositSlip = ()=>{

    return {
        type: RESET_DEPOSIT_SLIP_UPLOAD_STATE,
        
    }
}
const ClearFilesOnDepositSlip = () => {
  return {
    type: CLEAR_FILES_ON_DEPOSIT_SLIP,
  };
};
export {
  uploadDepositSlip,
  resetUploadDepositSlip,
  ClearFilesOnDepositSlip,
  POST_DEPOSIT_SLIP_UPLOAD_REQUEST,
  POST_DEPOSIT_SLIP_UPLOAD_REQUEST_SUCCESS,
  POST_DEPOSIT_SLIP_UPLOAD_REQUEST_FAILURE,
  RESET_DEPOSIT_SLIP_UPLOAD_STATE,
  CLEAR_FILES_ON_DEPOSIT_SLIP,
};