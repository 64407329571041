import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  TextInput,
  I18nManager,
  ScrollView,
  useWindowDimensions,
} from "react-native";
import React, { useState, useEffect } from "react";
import Icon from "react-native-vector-icons/Feather";
import { useTranslation } from "react-i18next";
import { SafeAreaView } from "react-native-safe-area-context";
import AuthHeader from '../../Components/AuthHeader'


const VerificationCode = ({ navigation }) => {
  const [time, setTime] = useState(59);
  const {width} = useWindowDimensions();

  useEffect(() => {
    if (!time) return;
    const res = setInterval(() => {
      setTime(time - 1);
    }, 1000);

    return () => {
      clearInterval(res);
    };
  }, [time]);

  const { t } = useTranslation();
  return (
    <SafeAreaView style={styles.mainContainer}>
      <View style={styles.container}>
       <AuthHeader></AuthHeader>
        <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
          <View style={styles.SubContainer}>
            <View style={styles.mainContectContainer}>
              <View style={styles.innerContainer}>
                <View style={styles.pageTopContainer}>
                  <Icon
                    onPress={() => navigation.goBack()}
                    name="arrow-left"
                    size={30}
                  />
                  <Text style={styles.verificationText}>
                    {t("common:PleaseEnterCode")}
                  </Text>
                </View>

                <View style={styles.smsVerifyCodeText}>
                  <Text style={styles.smsText}>{t("common:WeSentSMS")}</Text>

                  <Text style={styles.phoneNumberText}>
                    {" "}
                    +218 (91) 837 6323
                  </Text>
                </View>
                <Text style={styles.codeText}>{t("common:EnterCode")}</Text>
                <View style={[styles.baseView,width<700 && {height:60}]}>
                  <View style={styles.codeView}>
                    <TextInput
                      textAlign={I18nManager.isRTL ? "right" : "left"}
                      maxLength={1}
                      style={{ width: 40 }}
                      keyboardType="number-pad"
                      returnKeyType="done"
                    ></TextInput>
                  </View>
                  <View style={styles.codeView}>
                    <TextInput
                      textAlign={I18nManager.isRTL ? "right" : "left"}
                      maxLength={1}
                      style={{ width: 40 }}
                      keyboardType="number-pad"
                      returnKeyType="done"
                    ></TextInput>
                  </View>
                  <View style={styles.codeView}>
                    <TextInput
                      textAlign={I18nManager.isRTL ? "right" : "left"}
                      maxLength={1}
                      style={{ width: 40 }}
                      keyboardType="number-pad"
                      returnKeyType="done"
                    ></TextInput>
                  </View>
                  <View style={styles.codeView}>
                    <TextInput
                      textAlign={I18nManager.isRTL ? "right" : "left"}
                      maxLength={1}
                      style={{ width: 40 }}
                      keyboardType="number-pad"
                      returnKeyType="done"
                    ></TextInput>
                  </View>
                  <View style={styles.codeView}>
                    <TextInput
                      textAlign={I18nManager.isRTL ? "right" : "left"}
                      maxLength={1}
                      style={{ width: 40 }}
                      keyboardType="number-pad"
                      returnKeyType="done"
                    ></TextInput>
                  </View>
                </View>
                <View style={styles.resendView}>
                  <Text style={styles.resendText}>
                    {t("common:ResendCode")}
                  </Text>
                  <Text style={styles.timeText}>00:{time}</Text>
                </View>
                <View style={styles.btnView}>
                  <TouchableOpacity
                    onPress={() => navigation.navigate("Name")}
                    style={styles.button}
                  >
                    <Text style={styles.buttonText}>
                      {t("common:Continue")}
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          </View>
        </ScrollView>
      </View>
    </SafeAreaView>
  );
};

export default VerificationCode;

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    backgroundColor: "#FFFFFF",
  },
  container: {
    flex: 1,
    flexGrow: 1,
  },
  topContainer: {
    backgroundColor: "#ffffff",
    paddingTop: 10,
    paddingBottom: 10,
  },
  logoView: {
    color: "#FF4747",
    fontSize: 23,
    marginLeft: 25,
    fontWeight: "700",
  },
  SubContainer: {
    flex: 1,
    backgroundColor: "#F8F8F8",
    alignItems: "center",
  },
  mainContectContainer: {
    flex: 1,
    backgroundColor: "#ffffff",
    maxHeight:580,
    maxWidth:640,
    marginHorizontal: 15,
    marginTop: 30,
    borderRadius: 10,
    marginBottom: 51,
   
  },
  innerContainer: {
    margin: 50,
    flex: 1,
  },
  pageTopContainer: {
    flexDirection: "row",
    alignItems: "flex-start",
  },
  arrowIcon: {
    fontSize: 25,
    color: "#101820",
  },
  verificationText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "700",
    marginLeft: 15,
    fontSize: 26,
    color: "#101820",
    alignSelf: "flex-start",
  },
  smsVerifyCodeText: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
  phoneNumberText: {
    marginTop: 16,
    color: "#101820",
    fontSize: 16,
    fontWeight: "600",
    fontStyle: "normal",
    alignSelf: "flex-start",
  },
  smsText: {
    marginTop: 16,
    color: "#101820",
    fontSize: 16,
    fontWeight: "400",
    fontStyle: "normal",
    alignSelf: "flex-start",
  },
  codeText: {
    fontSize: 16,
    fontWeight: "500",
    color: "#A9ABAE",
    marginTop: 16,
    alignSelf: "flex-start",
  },
  baseView: {
    flexDirection: "row",
    gap: 10,
    marginTop: 16,
    marginBottom: 30,
    height:126,

  },
  codeView: {
    flex: 1,
    borderRadius: 4,
    backgroundColor: "#F9F9F9",
    borderRadius: 4,
    justifyContent: "center",
    alignItems: "center",
    marginRight: 15,
  },
  resendView: {
    flexDirection: "row",
    marginBottom:78
  },
  resendText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 16,
    color: "#FF4747",
    alignSelf: "flex-start",
  },
  timeText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 16,
    color: "#101820",
    marginLeft: 6,
  },
  btnView: {
    flex: 1,
    justifyContent: "flex-end",
  },
  button: {
    padding: 16,
    borderRadius: 70,
    backgroundColor: "#FF4747",
  },
  buttonText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 16,
    alignItems: "center",
    textAlign: "center",
    color: "#FFFFFF",
  },
});
