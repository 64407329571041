const POST_USER_FAMILY_LIST_REQUEST = "POST_USER_FAMILY_LIST_REQUEST";
const POST_USER_FAMILY_LIST_REQUEST_SUCCESS = "POST_USER_FAMILY_LIST_REQUEST_SUCCESS";
const POST_USER_FAMILY_LIST_REQUEST_FAILURE = "POST_USER_FAMILY_LIST_REQUEST_FAILURE";
const SELECTED_FAMILY_MEMBER = "SELECTED_FAMILY_MEMBER";
const userFamilyList = (formData) => {
  return {
    type: POST_USER_FAMILY_LIST_REQUEST,
    formData,
  };
};
const selectedFamilyMember = (data) => {
  return {
    type: SELECTED_FAMILY_MEMBER,
    data,
  };
};
const resetFamilyMember = (data) => {
  return {
    type: SELECTED_FAMILY_MEMBER,
    data,
  };
};
export {
  userFamilyList,
  selectedFamilyMember,
  resetFamilyMember,
  POST_USER_FAMILY_LIST_REQUEST,
  POST_USER_FAMILY_LIST_REQUEST_SUCCESS,
  POST_USER_FAMILY_LIST_REQUEST_FAILURE,
  SELECTED_FAMILY_MEMBER,
};
