
const POST_BANK_TOP_UP_REQUEST = 'POST_BANK_TOP_UP_REQUEST';
const POST_BANK_TOP_UP_REQUEST_SUCCESS =  'POST_BANK_TOP_UP_REQUEST_SUCCESS';
const POST_BANK_TOP_UP_REQUEST_FAILURE =  'POST_BANK_TOP_UP_REQUEST_FAILURE';
const CHANGE_TOP_UP_STATUS ='CHANGE_TOP_UP_STATUS'
const requestBankTopUp = (formData)=>{

    return {
        type: POST_BANK_TOP_UP_REQUEST,
        formData
    }
}
const changeTopUpstatus = ()=>{

    return {
        type: CHANGE_TOP_UP_STATUS,
    }
}

export {
  requestBankTopUp,
  changeTopUpstatus,
  POST_BANK_TOP_UP_REQUEST,
  POST_BANK_TOP_UP_REQUEST_SUCCESS,
  POST_BANK_TOP_UP_REQUEST_FAILURE,
  CHANGE_TOP_UP_STATUS,
};