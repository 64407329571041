const POST_FORGET_PASSWORD_REQUEST = "POST_FORGET_PASSWORD_REQUEST";
const POST_FORGET_PASSWORD_REQUEST_SUCCESS =  "POST_FORGET_PASSWORD_REQUEST_SUCCESS";
const POST_FORGET_PASSWORD_REQUEST_FAILURE =  "POST_FORGET_PASSWORD_REQUEST_FAILURE";
const RESET_FORGET_PASSWORD_STATE = 'RESET_FORGET_PASSWORD_STATE'

const forgetPassword = (formData) => {
  return {
    type: POST_FORGET_PASSWORD_REQUEST,
    formData,
  };
};

export {
  forgetPassword,
  RESET_FORGET_PASSWORD_STATE,
  POST_FORGET_PASSWORD_REQUEST,
  POST_FORGET_PASSWORD_REQUEST_SUCCESS,
  POST_FORGET_PASSWORD_REQUEST_FAILURE,
};
