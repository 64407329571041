import {
  StyleSheet,
  Text,
  View,
  TextInput,
  I18nManager,
  Image,
  TouchableOpacity,
  Dimensions,
  SafeAreaView,
  ScrollView,
} from "react-native";
import { useTranslation } from "react-i18next";
import Icon from "react-native-vector-icons/Feather";
import FamilyIcon from "react-native-vector-icons/MaterialIcons";
import Beneficiaries from "../../assets/image/Beneficiaries.png";
import GiftCard from "../../assets/image/GiftCard.png";
import ServiceVoucher from "../../assets/image/Voucher.png";
import { useState } from "react";
import AppHeader from "./Components/AppDashboardHeader";
import SideBar from "./Components/SideBar";
import { useSelector } from "react-redux";
import { useFocusEffect } from "@react-navigation/native";
import { useCallback } from "react";
const height = Dimensions.get("window").height;

const Services = ({ navigation, route }) => {
  const { t } = useTranslation();
  const userDetails = useSelector((state) => state.userDetails);
  // console.log("ServiceRouteName",route.name);
  useFocusEffect(useCallback(() => {
    console.warn('sdfsdfsdfs')
    navigation.getParent()?.setOptions({ tabBarStyle: { display: 'none' }, });
  }, [navigation]));
  const switchScreen = (screen) => {
    navigation.getParent()?.setOptions({
      tabBarStyle: {
        borderTopWidth: 0,
        backgroundColor: 'white',
        overflow: 'hidden',
        elevation: 0,
        width: 1400,
        alignSelf: "center",
        display: "flex"
      },
    })
    navigation.navigate(screen)
  }
  return (
    <SafeAreaView style={styles.mainContainer}>

      <View style={styles.topContainer}></View>
      <View style={styles.container}>
        <SideBar route={route} navigation={navigation} />
        <View style={{ flex: 1 }}>
          <AppHeader userDetails={userDetails} navigation={navigation} />

          <View style={{ flex: 1, marginTop: 25, gap: 20, marginLeft: 20 }}>
            <View style={styles.innerContainer}>

              <TouchableOpacity
                onPress={() => switchScreen("Beneficiaries")}
                style={styles.btnContainer}
              >
                <View style={styles.imageContainer}>
                  <Image source={Beneficiaries} style={styles.image} />
                  <Text style={styles.btnText}>{t("common:beneficiaries")}</Text>
                </View>
                <Icon name="chevron-right" size={24} style={styles.arrowImage} />
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => switchScreen("FamilyMember")}
                style={styles.btnContainer}
              >
                <View style={styles.imageContainer}>
                  <FamilyIcon
                    name="family-restroom"
                    size={24}
                    color="#FF4747"
                  />
                  <Text style={styles.btnText}>{t("common:Family_Mem")}</Text>
                </View>
                <Icon name="chevron-right" size={24} style={styles.arrowImage} />
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => switchScreen("GiftCard")}
                style={styles.btnContainer}
              >
                <View style={styles.imageContainer}>
                  <Image source={GiftCard} style={styles.image} />
                  <Text style={styles.btnText}>{t("common:gift_cards")}</Text>
                </View>
                <Icon name="chevron-right" size={24} style={styles.arrowImage} />
              </TouchableOpacity>


            </View>
            <View style={styles.innerContainer}>
              <TouchableOpacity
                onPress={() => switchScreen("ServiceVoucher")}
                style={styles.btnContainer}
              >
                <View style={styles.imageContainer}>
                  <Image source={ServiceVoucher} style={styles.image} />
                  <Text style={styles.btnText}>{t("common:service_vouchers")}</Text>
                </View>
                <Icon name="chevron-right" size={24} style={styles.arrowImage} />
              </TouchableOpacity>
            </View>
          </View>
        </View>

      </View>
    </SafeAreaView>
  );
};

export default Services;

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "center",
    backgroundColor: "#f8f8f8",
  },
  topContainer: {
    position: "absolute",
    backgroundColor: "white",
    height: 80,
    width: "100%",
  },
  container: {
    flex: 1,
    backgroundColor: "#F8F8F8",
    flexDirection: "row",
    maxWidth: 1400,
  },
  innerContainer: {
    flexDirection: "row",
    gap: 20,
  },
  btnContainer: {
    flexDirection: "row",
    backgroundColor: "#fff",
    height: 110,
    borderRadius: 16,
    maxWidth: 350,
    minWidth: 150,
    flex: 1,
    padding: 20,
    alignItems: "center",
  },
  imageContainer: {
    flex: 1,
    justifyContent: "flex-start",
  },
  image: {
    width: 40,
    height: 40,
  },
  btnText: {
    marginTop: 20,
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 18,
    color: "#000000",
    alignSelf: "flex-start",
  },
  arrowImage: {
    textAlign: "right",
  },
});
