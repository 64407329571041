import {
  POST_CHANGE_PIN_CODE_REQUEST,
  POST_CHANGE_PIN_CODE_REQUEST_SUCCESS,
  POST_CHANGE_PIN_CODE_REQUEST_FAILURE,
  RESET_CHANGE_PIN_CODE_STATE
} from "./Action"

const initialState = {
  message: null,
  data: {
    otp: null, 
    sms: null,
    email:null,
  },
}; 

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case POST_CHANGE_PIN_CODE_REQUEST: {
      console.log(POST_CHANGE_PIN_CODE_REQUEST);

      return {
        ...state,
        error: false,
        success: false,
        loading: true,
      };
    }
    case POST_CHANGE_PIN_CODE_REQUEST_SUCCESS: {
      const { message, data } = action.payload;
      console.log(POST_CHANGE_PIN_CODE_REQUEST_SUCCESS);
      return {
        ...state,
        loading: false,
        success: true,
        message,
        data,
      };
    }
    case POST_CHANGE_PIN_CODE_REQUEST_FAILURE: {
      console.log(POST_CHANGE_PIN_CODE_REQUEST_FAILURE);

      const { message } = action.payload;
      return {
        ...state,
        loading: false,
        success: false,
        error: true,
        message,
      };
    }
    case RESET_CHANGE_PIN_CODE_STATE: {
      console.log(RESET_CHANGE_PIN_CODE_STATE);
      return {
        ...initialState        
      };
    }
    default:
      return state;
  }
};

export { reducer };
