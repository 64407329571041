import {
  StyleSheet,
  Text,
  View,
  Dimensions,
  TouchableOpacity,
  Image,
  ScrollView,
  SafeAreaView,
} from "react-native";
import React, { useState } from "react";
import Icon from "react-native-vector-icons/Feather";
import { useTranslation } from "react-i18next";
import Edit from "../../assets/image/edit.png";
import Add from "../../assets/image/add.png";
import { ApiDateFormate } from '../../Utils/HelperFunctions'
import SideBarConatainer from "../../Components/SideBarConatainer"
const height = Dimensions.get("window").height;

const CblDocument = ({ navigation,route }) => {
  const { t } = useTranslation();
  const requestDocs = route.params?.requestDocs
  console.log("requestDocs",requestDocs)
  return (
    <SideBarConatainer navigation={navigation}>
      <View style={styles.headerView}>
        <Icon
          name="arrow-left"
          size={30}
          onPress={() => navigation.goBack()}
        />
        <Text style={styles.requestsText}>{t("common:requests")}</Text>
      </View>
      <ScrollView showsHorizontalScrollIndicator={false}>

        <View style={styles.mainContainer}>
        <View style={styles.addressView}>
          <Text style={styles.addressText}>{t("common:documents")}</Text>
          {
              requestDocs?.verify_request_status.toLowerCase() === "pending" && (
                <TouchableOpacity onPress={() => navigation.navigate('EditCblDocuments', { requestDocs: requestDocs })} style={styles.editBtn}>
              <Image source={Edit} style={styles.editImage} />
            </TouchableOpacity>
            )

          }
         
      </View>
          {
            requestDocs?.docs.map((val, ind) => (
              <View style={{ marginHorizontal: 24, flex: 1, }} key={ind}>
              <Text style={styles.nationalText}>{val.name}
              </Text>
              <View style={styles.container}>
                <Text style={styles.nameText}>{t("common:id_number")}</Text>
                <Text style={styles.cblRequestText}>{val.id_number}</Text>
              </View>
      
              <View style={styles.container}>
                <Text style={styles.nameText}>{t("common:place_of_issue")}</Text>
                <Text style={styles.cblRequestText}>{val.place_issue}</Text>
              </View>
      
              <View style={styles.container}>
                <Text style={styles.nameText}>{t("common:date_of_issue")}</Text>
                  <Text style={styles.cblRequestText}>{ApiDateFormate(new Date(val.date_issue))}</Text>
              </View>
      
              <View style={styles.container}>
                <Text style={styles.nameText}>{t("common:expiry_date")}</Text>
                  <Text style={styles.cblRequestText}>{ApiDateFormate(new Date(val.expiry_date))}</Text>
              </View>
              <View style={styles.logoView}>
                  {
                    val.doc_files.map((file, ind) => (
                      <TouchableOpacity key={ind}>
                        <Image source={{ uri: file.storage_path }} style={styles.btnImage} />
                      </TouchableOpacity>
                    ))
                  }
              </View>
              </View>
            ))
          }
        </View>
        </ScrollView>
    </SideBarConatainer>
  );
};

export default CblDocument;

const styles = StyleSheet.create({
  topContainer: {
    flex: 1,
    backgroundColor: "#FFFFFF",
  },
  headerView: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 27
  },
  requestsText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 26,
    color: "#000000",
    marginLeft: 10
  },
  mainContainer: {
    backgroundColor: "#ffffff",
    borderRadius: 16,
    padding: 20,
  },
  addressView: {
    marginBottom: 14,
    marginHorizontal: 24,
    marginBottom: 20,
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  addressText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 18,
    color: "#000000",
    alignSelf: "flex-start"
  },
  editBtn: {},
  editImage: {
    height: 17,
    width: 51,
  },
  container: {
    marginTop: 24,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  nationalText: {
    marginTop: 24,
    marginBottom: 8,
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 16,
    color: "#10121F",
  },
  nameText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 14,
    color: "#BDBDBD",
  },
  cblRequestText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 14,
    textAlign: "right",
  },
  logoView: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 24,
  },
  addBtn: {
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 26.88,
    borderWidth: 1,
    borderRadius: 18,
    borderColor: "#DDDDDE",
    height: 157,
    width: 170,
    marginRight: 10,
  },
  btnImage: {
    borderWidth: 1,
    borderRadius: 18,
    borderColor: "#DDDDDE",
    height: 157,
    width: 170,
    marginRight:10,
  },
});
