import {
  StyleSheet,
  Text,
  View,
  Dimensions,
  ScrollView,
  TouchableOpacity,
  Image,
  TextInput,
  I18nManager,
  SafeAreaView
} from "react-native";
import React, { useState, useEffect, useCallback } from "react";
import Icon from "react-native-vector-icons/Feather";
import { useTranslation } from "react-i18next";
import Add from "../../assets/image/add.png";
import SendChat from "../../assets/image/sendMasg.png";
import Dialog from "react-native-dialog";
import { launchCamera, launchImageLibrary } from "react-native-image-picker";
import CrossIcon from "react-native-vector-icons/Entypo";
import MessageIcon from "react-native-vector-icons/Entypo";
import { getRequestCommentList } from "../../Redux/Models/Comments/RequestListComment/Actions";
import { createCommentsRequest, resetAddCommentsRequest } from "../../Redux/Models/Comments/RequestAddComment/Actions";
import Loading from "../Loading";
import { useDispatch, useSelector } from "react-redux";
import { requestDetails, resetRequestDetails } from "../../Redux/Models/RequestDetails/Actions";
import { ApiDateFormate, DateFormate, formatAMPM, formatTimeAMPM } from "../../Utils/HelperFunctions";
import { useFocusEffect } from '@react-navigation/native';
import Alert from "../../Components/Alert";
import SideBarConatainer from "../../Components/SideBarConatainer"
const height = Dimensions.get("window").height;

const CblRequestDetail = ({ navigation, route }) => {
  const { t } = useTranslation();
  const [commentText, setCommentText] = useState("");
  const [visible, setVisible] = useState(false);
  const createRequestComment = useSelector((state) => state.createCommentsRequest);
  const requestCommentList = useSelector((state) => state.getRequestCommentList);
  const requestDetail = useSelector((state) => state.requestDetails);
  console.log("DFGDGDFGDGDDFG", requestCommentList.data.comments)
  const dispatch = useDispatch();
  const selectedRequestDetail = route.params?.request
  useEffect(() => {
    dispatch(getRequestCommentList(route.params?.request.id));
  }, []);
  const createComment = () => {
    if (commentText !== "") {
      let formData = new FormData();
      formData.append("message", commentText);
      dispatch(createCommentsRequest(route.params?.request.id, formData));
      setCommentText("");
    }
  };
  useEffect(() => {
    if (createRequestComment.success === true) {
      dispatch(getRequestCommentList(route.params?.request.id))
      dispatch(resetAddCommentsRequest())
    }

  }, [createRequestComment])


  useFocusEffect(
    useCallback(() => {
      dispatch(requestDetails(route.params?.request.id))
      dispatch(getRequestCommentList(route.params?.request.id));
    }, [dispatch])
  );
  useEffect(() => {
    if (requestDetail.error === true) {
      return Alert("Error", requestDetail.message.toString(), [
        {
          text: "OK",
          onPress: () => {
            dispatch(resetRequestDetails());
            dispatch(requestDetails(route.params?.request.id));
          },
        },
      ]);
    }
  }, [requestDetail]);
  return (
    <SideBarConatainer navigation={navigation}>
      <View style={styles.headerView}>
        <Icon
          name="arrow-left"
          size={30}
          style={styles.arrowIcon}
          onPress={() => navigation.goBack()}
        />
        <Text style={styles.requestsText}>{t("common:requests")}</Text>
      </View>
      <ScrollView showsHorizontalScrollIndicator={false}>
        <View style={styles.innerContainer}>
          <View style={styles.headerContainer}>

        <View style={styles.innerContainer}>
          <View style={styles.container}>
            <Text style={styles.nameText}>{t("common:request_category")}</Text>
            <Text style={styles.cblRequestText}>
              {selectedRequestDetail.verify_process_type.toUpperCase()}
            </Text>
          </View>

          <View style={styles.container}>
            <Text style={styles.nameText}>{t("common:request_id")}</Text>
            <Text style={styles.cblRequestText}>
              {selectedRequestDetail.request_number}
            </Text>
          </View>

          <View style={styles.container}>
            <Text style={styles.nameText}>{t("common:date")}</Text>
            <Text style={styles.cblRequestText}>
              {ApiDateFormate(new Date(selectedRequestDetail.created)) +
                " " +
                formatAMPM(new Date(selectedRequestDetail.created))}
            </Text>
          </View>
            </View>

            <View style={styles.innerContainer}>
          <View style={styles.container}>
            <Text style={styles.nameText}>{t("common:status")}</Text>
            <Text style={styles.pendingText}>
              {t("common:" + selectedRequestDetail.verify_request_status.toLowerCase())}
            </Text>
          </View>

          <View style={styles.container}>
            <Text style={styles.nameText}>{t("common:status_reason_col")}</Text>
            <Text style={styles.pendingText}>
              {selectedRequestDetail.request_status_reason}
            </Text>
          </View>
          <View style={[styles.descriptionView]}>
            <Text style={styles.nameText}>{t("common:description")}</Text>
            <Text style={styles.descriptionText}>
              {selectedRequestDetail.description}
            </Text>
          </View>
            </View>

          </View>
            <TouchableOpacity
              onPress={() =>
                navigation.navigate("CblDocument", {
                  requestDocs: requestDetail.data.request,
                })
              }
              style={styles.personalButton}
            >
              <Text style={styles.personalText}>{t("common:documents")}</Text>
              <Icon name="chevron-right" size={24} style={styles.arrowImage} />
            </TouchableOpacity>
          {/* <View style={styles.commentView}>
            <Text style={styles.commentText}>{t("common:comments")}</Text>
            {requestCommentList.data.comments?.map((val, ind) => (
              <View key={ind} style={styles.messageView}>
                <MessageIcon name="message" color="#FF4747" size={24} />
                <View style={{ marginLeft: 16 }}>
                  <Text style={styles.messageText}>{val.message}</Text>
                  <Text style={styles.timeText}>
                    {DateFormate(new Date(val.created)) +
                      " " +
                      formatTimeAMPM(new Date(val.created))}
                  </Text>
                </View>
              </View>
            ))}
          </View> */}
        </View>
      </ScrollView>
      {/* <View style={styles.chatContainer}>
        <View style={styles.chatView}>
          <TextInput
            textAlign={I18nManager.isRTL ? "right" : "left"}
            defaultValue={commentText}
            onChangeText={(newText) => setCommentText(newText)}
            multiline={true}
            placeholder={t("common:Type_comment")}
            style={styles.chatInput}
          ></TextInput>
        </View>
        <TouchableOpacity onPress={createComment} style={styles.chatButton}>
          <Image source={SendChat} style={styles.chatImage} />
        </TouchableOpacity>
      </View> */}
      {(createRequestComment.loading || requestCommentList.loading) && (
        <Loading></Loading>
      )}
    </SideBarConatainer>
  );
};

export default CblRequestDetail;

const styles = StyleSheet.create({
  headerView: {
    flexDirection: "row",
    alignItems: "center",
  },
  requestsText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 26,
    color: "#000000",
    marginLeft: 10
  },
  innerContainer: {
    flex: 1,
  },
  headerContainer: {
    backgroundColor: "#FFF",
    borderRadius: 16,
    padding: 20,
    marginTop: 17,
    flexDirection:"row"
  },
  container: {
    flexDirection: "row",
    alignItems: "center",
    height:30,
    justifyContent: "space-between",
    marginHorizontal: 10,
    marginTop: 10,
  },

  nameText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 14,
    color: "#BDBDBD",
  },
  cblRequestText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 14,
    textAlign: "right",
  },
  descriptionView: {
    marginTop: 10,
    marginHorizontal:10,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  descriptionText: {
    marginTop: 10,
    width: 210,
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 14,
    textAlign: "right",
    color: "#000000",
  },
  pendingText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 14,
    color: "#FF7C22",
  },
  personalButton: {
    height: 75,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#FFF",
    borderRadius: 16,
    marginTop: 17
  },
  personalText: {
    marginLeft: 24,
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 18,
    color: "#000000",
  },
  arrowImage: {
    marginRight: 24,
  },
  commentView: {
    flex: 1,
    marginTop: 24,
    padding: 20,
    backgroundColor: "#FFF",
    borderRadius: 16
  },
  commentText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 18,
    color: "#000000",
  },
  messageView: {
    flexDirection: "row",
    marginTop: 24,
    marginRight: 24,
    height:56,
    borderBottomWidth:1,
    borderBottomColor:"#E2E2E3"
  },
  messageText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 14,
    color: "#000000",
  },
  timeText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 12,
    color: "#BABABA",
    marginTop: 8,
  },
  chatContainer: {
    position:"absolute",
    borderTopLeftRadius: 15,
    borderTopRightRadius: 15,
    flexDirection: "row",
    alignItems: "center",
    height: 56,
    flex: 1,
    width:"100%",
    bottom: 0,
    marginLeft: 0,
    marginRight: 0,
    maxHeight: 100,
    borderTopWidth: 0.1,
    borderLeftWidth: 0.1,
    borderRightWidth: 0.1,
  },
  chatView: {
    width: "80%",
    height: 56,
    marginLeft: 24,
    marginRight: 10,
  },
  chatInput: {
    marginRight: 10,
    height: "100%",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 14,
    color: "#101820",
    outlineStyle: "none"
  },
  chatButton: {
    width: 28,
    height: 28,
    marginRight: 25.5,
  },
  chatImage: {
    width: 28,
    height: 28,
  },
});
