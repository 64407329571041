
const GET_MDP_CARD_LOOKUP_LANGUAGES_REQUEST = 'GET_MDP_CARD_LOOKUP_LANGUAGES_REQUEST';
const GET_MDP_CARD_LOOKUP_LANGUAGES_REQUEST_SUCCESS = 'GET_MDP_CARD_LOOKUP_LANGUAGES_REQUEST_SUCCESS';
const GET_MDP_CARD_LOOKUP_LANGUAGES_REQUEST_FAILURE = 'GET_MDP_CARD_LOOKUP_LANGUAGES_REQUEST_FAILURE';

const mdpCardsLookupLanguages = () => {

  return {
    type: GET_MDP_CARD_LOOKUP_LANGUAGES_REQUEST,
  }
}

export {
  mdpCardsLookupLanguages,
  GET_MDP_CARD_LOOKUP_LANGUAGES_REQUEST,
  GET_MDP_CARD_LOOKUP_LANGUAGES_REQUEST_SUCCESS,
  GET_MDP_CARD_LOOKUP_LANGUAGES_REQUEST_FAILURE,
};