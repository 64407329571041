
const GET_MDP_CARD_LOOKUP_ID_TYPES_REQUEST = 'GET_MDP_CARD_LOOKUP_ID_TYPES_REQUEST';
const GET_MDP_CARD_LOOKUP_ID_TYPES_REQUEST_SUCCESS = 'GET_MDP_CARD_LOOKUP_ID_TYPES_REQUEST_SUCCESS';
const GET_MDP_CARD_LOOKUP_ID_TYPES_REQUEST_FAILURE = 'GET_MDP_CARD_LOOKUP_ID_TYPES_REQUEST_FAILURE';

const mdpCardsLookupIdTypes = () => {

  return {
    type: GET_MDP_CARD_LOOKUP_ID_TYPES_REQUEST,
  }
}

export {
  mdpCardsLookupIdTypes,
  GET_MDP_CARD_LOOKUP_ID_TYPES_REQUEST,
  GET_MDP_CARD_LOOKUP_ID_TYPES_REQUEST_SUCCESS,
  GET_MDP_CARD_LOOKUP_ID_TYPES_REQUEST_FAILURE,
};