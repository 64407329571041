
const POST_REQUEST_EXCHANGE_MONEY_REQUEST = 'POST_REQUEST_EXCHANGE_MONEY_REQUEST';
const POST_REQUEST_EXCHANGE_MONEY_REQUEST_SUCCESS =  'POST_REQUEST_EXCHANGE_MONEY_REQUEST_SUCCESS';
const POST_REQUEST_EXCHANGE_MONEY_REQUEST_FAILURE =  'POST_REQUEST_EXCHANGE_MONEY_REQUEST_FAILURE';

const requestExchange = (formData) => {
  return {
    type: POST_REQUEST_EXCHANGE_MONEY_REQUEST,
    formData,
  };
};

export {
  requestExchange,
  POST_REQUEST_EXCHANGE_MONEY_REQUEST,
  POST_REQUEST_EXCHANGE_MONEY_REQUEST_SUCCESS,
  POST_REQUEST_EXCHANGE_MONEY_REQUEST_FAILURE,
};