import {
  StyleSheet,
  Text,
  View,
  SafeAreaView,
  TouchableOpacity,
  Image,
  ScrollView,
  Dimensions,
} from "react-native";
import React, { useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import Profile from "../../assets/image/profile.png";
import { useDispatch, useSelector } from "react-redux";
import AccountIcon from "react-native-vector-icons/MaterialCommunityIcons";
import PersonalIcon from "react-native-vector-icons/Ionicons";
import ToastAndroid from "react-native-root-toast";
import AppHeader from "./Components/AppDashboardHeader";
import SideBar from "./Components/SideBar";
import Icon from "react-native-vector-icons/Feather";
import AccountImage from "../../assets/image/user.png";
import CoinImage from "../../assets/image/coin_outline.png";
import InfoImage from "../../assets/image/info.png";
import StarImage from "../../assets/image/star.png";
import ShieldImage from "../../assets/image/shield.png";
import NotificationImage from "../../assets/image/Notifications.png";
import MessageImage from "../../assets/image/message.png";
import PhoneImage from "../../assets/image/phone.png";
import NotepadImage from "../../assets/image/notepad.png";
import BoltImage from "../../assets/image/subscribe.png";
import { resetState } from "../../Redux/Models/User/ChangePinCode/Action";
import Loading from '../Loading';
import { useFocusEffect } from "@react-navigation/native";
import { selectedRequests } from '../../Redux/Models/MyRequests/Actions'


const Account = ({ navigation, route }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const userDetails = useSelector((state) => state.userDetails);
  const changePin = useSelector((state) => state.changePinCode);
  useEffect(() => {
    if (changePin.success === true) {
      ToastAndroid.show(t("common:PinChanged"), ToastAndroid.LONG);
      dispatch(resetState());
    }
  }, [changePin]);

  const inProgress = () => {
    ToastAndroid.show(t("common:in_progress"), ToastAndroid.SHORT);
  };
  useFocusEffect(useCallback(() => {
    navigation.getParent()?.setOptions({ tabBarStyle: { display: 'none' }, });
  }, [navigation]));

  return (
    <SafeAreaView style={styles.mainContainer}>
      <View style={styles.topContainer}></View>
      <View style={styles.container}>
        <SideBar route={route} navigation={navigation} />
        <View style={{ flex: 1 }}>
          <AppHeader userDetails={userDetails} navigation={navigation} />
          <ScrollView showsHorizontalScrollIndicator={false} >

            <View style={styles.profileContainer}>
              <View style={styles.profileInner}>
                <View style={styles.profileView}>
                  <Image
                    source={
                      userDetails.data.user?.profile_image === null ||
                        userDetails.data.user?.profile_image === ""
                        ? Profile
                        : { uri: userDetails.data.user?.profile_image }
                    }
                    style={styles.profileImage}
                  />
                  <View style={{ marginLeft: 40 }}>
                    <Text style={styles.nameText}>
                      {userDetails.data.user?.name}
                    </Text>
                    <Text style={styles.emailText}>
                      {userDetails.data.user?.email}
                    </Text>
                  </View>
                </View>
                <TouchableOpacity style={styles.editBtn} onPress={inProgress}>
                  <Text style={styles.editText}>{t("common:edit")}</Text>
                </TouchableOpacity>
              </View>
            </View>
            <View style={styles.accountBtn}>
              <View style={styles.innerContainer}>
                <TouchableOpacity onPress={inProgress} style={styles.userBtn}>
                  <View style={styles.imageContainer}>
                    <Image source={AccountImage} style={styles.image} />
                    <Text style={styles.userText}>{t("common:account")}</Text>
                  </View>
                  <Icon
                    name="chevron-right"
                    size={24}
                    style={styles.arrowImage}
                  />
                </TouchableOpacity>
                <TouchableOpacity
                  onPress={() => {
                    navigation.getParent()?.setOptions({
                      tabBarStyle: {
                        borderTopWidth: 0,
                        backgroundColor: 'white',
                        overflow: 'hidden',
                        elevation: 0,
                        width: 1400,
                        alignSelf: "center",
                        display: "flex"
                      },
                    })
                    navigation.navigate("SelectLanguage")
                    dispatch(selectedRequests(t("common:select_language")))
                  }}
                  style={styles.userBtn}
                >
                  <View style={styles.imageContainer}>
                    <Image source={AccountImage} style={styles.image} />
                    <Text style={styles.userText}>
                      {t("common:account_settings")}
                    </Text>
                  </View>
                  <Icon
                    name="chevron-right"
                    size={24}
                    style={styles.arrowImage}
                  />
                </TouchableOpacity>
                <TouchableOpacity onPress={inProgress} style={styles.userBtn}>
                  <View style={styles.imageContainer}>
                    <Image source={InfoImage} style={styles.image} />
                    <Text style={styles.userText}>
                      {t("common:personal_info")}
                    </Text>
                  </View>
                  <Icon
                    name="chevron-right"
                    size={24}
                    style={styles.arrowImage}
                  />
                </TouchableOpacity>
              </View>

              <View style={styles.innerContainer}>
                <TouchableOpacity onPress={inProgress} style={styles.userBtn}>
                  <View style={styles.imageContainer}>
                    <Image source={NotepadImage} style={styles.image} />
                    <Text style={styles.userText}>
                      {t("common:documents_and_reports")}
                    </Text>
                  </View>
                  <Icon
                    name="chevron-right"
                    size={24}
                    style={styles.arrowImage}
                  />
                </TouchableOpacity>

                <TouchableOpacity onPress={inProgress} style={styles.userBtn}>
                  <View style={styles.imageContainer}>
                    <Image source={NotificationImage} style={styles.image} />
                    <Text style={styles.userText}>
                      {t("common:manage_notifications")}
                    </Text>
                  </View>
                  <Icon
                    name="chevron-right"
                    size={24}
                    style={styles.arrowImage}
                  />
                </TouchableOpacity>
                <TouchableOpacity onPress={inProgress} style={styles.userBtn}>
                  <View style={styles.imageContainer}>
                    <Image source={CoinImage} style={styles.image} />
                    <Text style={styles.userText}>
                      {t("common:invite_friends")}
                    </Text>
                  </View>
                  <Icon
                    name="chevron-right"
                    size={24}
                    style={styles.arrowImage}
                  />
                </TouchableOpacity>
              </View>
              <View style={styles.innerContainer}>
                <TouchableOpacity onPress={inProgress} style={styles.userBtn}>
                  <View style={styles.imageContainer}>
                    <Image source={ShieldImage} style={styles.image} />
                    <Text style={styles.userText}>
                      {t("common:security_and_privacy")}
                    </Text>
                  </View>
                  <Icon
                    name="chevron-right"
                    size={24}
                    style={styles.arrowImage}
                  />
                </TouchableOpacity>
                <TouchableOpacity onPress={inProgress} style={styles.userBtn}>
                  <View style={styles.imageContainer}>
                    <Image source={BoltImage} style={styles.image} />
                    <Text style={styles.userText}>
                      {t("common:subscription")}
                    </Text>
                  </View>
                  <Icon
                    name="chevron-right"
                    size={24}
                    style={styles.arrowImage}
                  />
                </TouchableOpacity>

                <TouchableOpacity onPress={inProgress} style={styles.userBtn}>
                  <View style={styles.imageContainer}>
                    <Image source={PhoneImage} style={styles.image} />
                    <Text style={styles.userText}>
                      {t("common:contact_to_support")}
                    </Text>
                  </View>
                  <Icon
                    name="chevron-right"
                    size={24}
                    style={styles.arrowImage}
                  />
                </TouchableOpacity>
              </View>
              <View style={styles.innerContainer}>
                <TouchableOpacity onPress={inProgress} style={styles.userBtn}>
                  <View style={styles.imageContainer}>
                    <Image source={StarImage} style={styles.image} />
                    <Text style={styles.userText}>{t("common:rate_us")}</Text>
                  </View>
                  <Icon
                    name="chevron-right"
                    size={24}
                    style={styles.arrowImage}
                  />
                </TouchableOpacity>
                <TouchableOpacity onPress={inProgress} style={styles.userBtn}>
                  <View style={styles.imageContainer}>
                    <Image source={MessageImage} style={styles.image} />
                    <Text style={styles.userText}>{t("common:faqs")}</Text>
                  </View>
                  <Icon
                    name="chevron-right"
                    size={24}
                    style={styles.arrowImage}
                  />
                </TouchableOpacity>
              </View>
            </View>
          </ScrollView>

        </View>
      </View>
      {changePin.loading && <Loading></Loading>}
    </SafeAreaView>
  );
};

export default Account;

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "center",
    backgroundColor: "#f8f8f8",
  },
  topContainer: {
    position: "absolute",
    backgroundColor: "white",
    height: 80,
    width: "100%",
  },
  container: {
    flex: 1,
    backgroundColor: "#F8F8F8",
    flexDirection: "row",
    maxWidth: 1400,
  },
  profileContainer: {
    maxWidth: 1081,
    minWidth: 350,
    maxHeight: 120,
    flex: 1,
    padding: 20,
    backgroundColor: "#FFFFFF",
    borderRadius: 16,
    marginTop: 35,
    marginLeft: 10,
    justifyContent: "center",
  },
  profileInner: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  },
  editBtn: {
    marginRight: 10,
    alignItems: "center",
    justifyContent: "center",
  },
  editText: {
    textAlign: "right",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 18,
    color: "#FF4747",
  },
  profileView: {
    flexDirection: "row",
  },
  profileImage: {
    width: 80,
    height: 80,
    borderRadius: 50,
    marginLeft: 10,
  },
  nameText: {
    marginTop: 16,
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 20,
    color: "#000000",
  },
  emailText: {
    marginTop: 8,
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 14,
    color: "#828282",
  },
  accountBtn: {
    flex: 1,
    marginTop: 25,
    gap: 14,
    marginLeft: 10,
    flexWrap: "wrap",
  },
  innerContainer: {
    flexDirection: "row",
    gap: 14,
    flexWrap: "wrap",
  },
  userBtn: {
    flexDirection: "row",
    backgroundColor: "#fff",
    height: 110,
    borderRadius: 16,
    maxWidth: 350,
    minWidth: 150,
    flex: 1,
    padding: 20,
    alignItems: "center",
  },
  imageContainer: {
    flex: 1,
    justifyContent: "flex-start",
  },
  image: {
    width: 40,
    height: 40,
    resizeMode: "contain"
  },
  userImage: {
    width: 24,
    height: 24,
  },
  userText: {
    marginTop: 20,
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 16,
    color: "#101820",
  },
  subImage: {
    height: 19,
    width: 14,
  },
});
