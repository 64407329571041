import { takeEvery, put, call } from "redux-saga/effects";
import BASEURL from "../../URLs";
import { queryApi } from "../../QueryApi";
import {
  POST_USER_REGISTER_REQUEST,
  POST_USER_REGISTER_REQUEST_SUCCESS,
  POST_USER_REGISTER_REQUEST_FAILURE,
} from "../Register/Action";
import {SET_LOGIN_USER} from '../Login/Actions'

function* handler() {
  yield takeEvery(POST_USER_REGISTER_REQUEST, userRegister);
}

function* userRegister(action) {
  try {
    // API call
    console.log(action.formData);
    const apiResponse = yield call(queryApi, {
      endpoint: BASEURL.userRegister,
      method: "POST",
      body: action.formData,
    });
    if (!("error" in apiResponse)) {
      yield put({
        type: POST_USER_REGISTER_REQUEST_SUCCESS,
        payload: apiResponse,
      });
      yield put({
        type: SET_LOGIN_USER,
        user: apiResponse.data,
      });

    } else {
      yield put({
        type: POST_USER_REGISTER_REQUEST_FAILURE,
        payload: apiResponse.error,
      });
    }
  } catch (err) {
    console.log(err);
    // Handle error
    yield put({
      type: POST_USER_REGISTER_REQUEST_FAILURE,
      payload: { message: err },
    });
  }
}

export { handler };
