const POST_USER_LOGIN_REQUEST = 'POST_USER_LOGIN_REQUEST';
const POST_USER_LOGIN_REQUEST_SUCCESS =  'POST_USER_LOGIN_REQUEST_SUCCESS';
const POST_USER_LOGIN_REQUEST_FAILURE =  'POST_USER_LOGIN_REQUEST_FAILURE';
const SET_LOGIN_USER =  'SET_LOGIN_USER';
const RESET_LOGIN_STATE ='RESET_LOGIN_STATE'

const userLogin = (formData)=>{

    return {
        type: POST_USER_LOGIN_REQUEST,
        formData
    }
}
const setUser =(user)=>{
    return {
        type: SET_LOGIN_USER,
        user
    }
}

export {
    userLogin,
    setUser,
    SET_LOGIN_USER,
    RESET_LOGIN_STATE,
    POST_USER_LOGIN_REQUEST,
    POST_USER_LOGIN_REQUEST_SUCCESS,
    POST_USER_LOGIN_REQUEST_FAILURE,
};