
const GET_COUNTRY_LIST_REQUEST = 'GET_COUNTRY_LIST_REQUEST';
const GET_COUNTRY_LIST_REQUEST_SUCCESS =  'GET_COUNTRY_LIST_REQUEST_SUCCESS';
const GET_COUNTRY_LIST_REQUEST_FAILURE =  'GET_COUNTRY_LIST_REQUEST_FAILURE';

const countryList = ()=>{

    return {
        type: GET_COUNTRY_LIST_REQUEST,
        
    }
} 

export {
  countryList,
  GET_COUNTRY_LIST_REQUEST,
  GET_COUNTRY_LIST_REQUEST_SUCCESS,
  GET_COUNTRY_LIST_REQUEST_FAILURE,
};