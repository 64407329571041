import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  Image,
  Dimensions,
} from "react-native";
import React from "react";
import Icon from "react-native-vector-icons/Feather";
import Card from "../../assets/image/card.png";
import BankTopUp from "../../assets/image/bank.png";
import Wallet from "../../assets/image/Wallet.png";
import Arrow from "../../assets/image/Arrow.png";
import { useTranslation } from "react-i18next";
import { SafeAreaView } from "react-native-safe-area-context";
import ToastAndroid from "react-native-root-toast";
import SideBarConatainer from "../../Components/SideBarConatainer";
const height = Dimensions.get("window").height;

const TopUpMoney = ({ navigation }) => {
  const { t } = useTranslation();
  const AddCard = () => {
    ToastAndroid.show(t("common:in_progress"), ToastAndroid.SHORT);
  };
  const Wallets = () => {
    ToastAndroid.show(t("common:in_progress"), ToastAndroid.SHORT);
  };
  return (
    <SideBarConatainer navigation={navigation}> 
      <View style={styles.topContainer}>
        <View style={styles.headerView}>
          <Icon
            name="arrow-left"
            size={30}
            onPress={() => navigation.goBack()}
          />
          <Text style={styles.moneyText}>{t("common:top_up_money")}</Text>
        </View>
        <View style={styles.container}>
          <TouchableOpacity onPress={()=>navigation.navigate('AddCard')} style={styles.buttonContainer}>
            <View style={styles.imageContainer}>
              <Image source={Card} style={styles.topImage} />
              <Text style={styles.topUpText}>
                {t("common:Add_Via_Credit_Debit_Card")}
              </Text>
            </View>
            <Icon name="chevron-right" size={24} />
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => navigation.navigate("BankTopUp")}
            style={styles.buttonContainer}
          >
            <View style={styles.imageContainer}>
              <Image source={BankTopUp} style={styles.topImage} />
              <Text style={styles.topUpText}>{t("common:bank_top_up")}</Text>
            </View>
            <Icon name="chevron-right" size={24} />
          </TouchableOpacity>
          <TouchableOpacity onPress={()=>navigation.navigate("WalletToWallet")} style={styles.buttonContainer}>
            <View style={styles.imageContainer}>
              <Image source={Wallet} style={styles.topImage} />
              <Text style={styles.topUpText}>
                {t("common:wallet_to_wallet")}
              </Text>
            </View>
            <Icon name="chevron-right" size={24} />
          </TouchableOpacity>
        </View>
      </View>
      </SideBarConatainer>
  );
};

export default TopUpMoney;

const styles = StyleSheet.create({
  topContainer: {
    flex: 1,
  },
  headerView: {
    flexDirection: "row",
    alignItems: "center",
  },
  moneyText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 26,
    color: "#000000",
    marginLeft:15
  },
  container: {
  },
  buttonContainer: {
    flexDirection: "row",
    marginTop: 15,
    height: 100,
    backgroundColor:"#FFFFFF",
    borderRadius:16,
    alignItems:"center",
    justifyContent:"center",
    paddingHorizontal:20
  },
  imageContainer: {
    flex: 1,
    justifyContent: "flex-start",
    flexDirection: "row",
  },
  lineView: {
    marginTop: 13,
    borderBottomWidth: 1,
    borderColor: "#E7E8E9",
    borderRadius: 99,
  },
  topUpText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 18,
    color: "#000000",
    marginTop:10,
    marginLeft: 15,
  },
  topImage: {
    width: 40,
    height: 40,
    resizeMode:"contain"
  },
  bankImage: {
    width: 20,
    height: 20,
  },
  arrowImage: {
    alignSelf: "flex-end",
  },
});
