import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  TouchableOpacity,
  TextInput,
  I18nManager,
  Dimensions,
} from "react-native";
import React, { useEffect } from "react";
import Icon from "react-native-vector-icons/Feather";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { SafeAreaView } from "react-native-safe-area-context";
import ToastAndroid from "react-native-root-toast";
import AuthHeader from '../../Components/AuthHeader'

const height = Dimensions.get("window").height;

const Email = ({ navigation, route }) => {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const { formData } = route.params;
  const [disabled, setDisabled] = useState(true);

  const nextStep = () => {
    if (!ValidateEmail()) {
      return;
    }
    formData.email = email;
    navigation.navigate("Password", { formData });
  };
  const ValidateEmail = () => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      return true;
    }
    ToastAndroid.show(t("common:please_enter_valid_emil"), ToastAndroid.SHORT);
    return false;
  };
  useEffect(() => {
    if (!!email) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [email]);
  return (
    <SafeAreaView style={styles.mainContainer}>
      <AuthHeader/>
      <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
     <View style={styles.subContainer}>
     <View style={styles.mainContectContainer}>
          <View style={styles.innerContainer}>
            <View style={styles.pageTopContainer}>
              <View style={styles.namePageContainer}>
                <Icon
                  onPress={() => navigation.goBack()}
                  name="arrow-left"
                  size={30}
                  style={styles.arrowIcon}
                />
                <Text style={styles.nameText}>{t("common:EnterEmail")}</Text>
              </View>
              <Text style={styles.counterText}>2/6</Text>
            </View>
              <View style={styles.nameView}>
                <Text style={styles.emailText}>
                  {t("common:EmailAddress")}
                </Text>
                <TextInput
                  textAlign={I18nManager.isRTL ? "right" : "left"}
                  defaultValue={email}
                  onChangeText={(newText) => setEmail(newText)}
                  keyboardType="email-address"
                  style={styles.emailInput}
                ></TextInput>
              </View>
            <View style={styles.btnView}>
            <TouchableOpacity
             onPress={() => nextStep()}
             style={ styles.skipButton}>

            <Text style={styles.SkipButtonText}>Skip</Text>
          </TouchableOpacity>
          <TouchableOpacity
             onPress={() => nextStep()}
            style={disabled ? styles.buttonDisabled : styles.button}
            disabled={disabled}
          >
            <Text style={styles.buttonText}>{t("common:next")}</Text>
          </TouchableOpacity>
        </View>
          </View>
        </View>
     </View>
      </ScrollView>
    </SafeAreaView>
  );
};

export default Email;

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    backgroundColor: "#F8F8F8",
  },
  topContainer: {
    backgroundColor: "#ffffff",
    paddingTop: 10,
    paddingBottom: 10,
  },
  logoView: {
    color: "#FF4747",
    fontSize: 23,
    marginLeft: 25,
    fontWeight: "700",
  },
  subContainer:{
    flex: 1,
    flexDirection:'row',
    justifyContent:'center'
  },
  mainContectContainer: {
    flex: 1,
    maxHeight:580,
    maxWidth:640,
    marginHorizontal: 15,
    backgroundColor:'#ffffff',
    marginTop: 30,
    borderRadius: 10,
    marginBottom:51,
  },
  namePageContainer: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  innerContainer: {
    margin: 50,
    flex: 1,
  },
  pageTopContainer: {
    flexDirection: "row",
  },
  nameText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "700",
    marginLeft: 15,
    fontSize: 26,
    color: "#101820",
    alignSelf: "flex-start",
  },
  counterText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 22,
    marginLeft: 20,
    color: "#000000",
    alignSelf: "center",
  },
  nameView: {
    flex: 1,
    marginTop: 26,
  },
  emailText:{
    fontSize: 14,
    fontWeight: "400",
    color: "#A9ABAE",
    alignSelf: "flex-start",
  },
  emailInput:{
      height: 50,
      borderBottomWidth: 1,
      borderBottomColor: "#E7E8E9",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: 16,
      outlineStyle: "none",
  },
  btnView: {
    flex: 1,
    justifyContent: "flex-end",
  },
  button: {
    padding: 16,
    borderRadius: 70,
    backgroundColor: "#FF4747",
  },
  skipButton: {
    justifyContent:"center",
    alignSelf:'center',
    padding: 5,
    borderRadius: 20,
    marginBottom:20,
  },
  SkipButtonText:{
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 16,
    alignItems: "center",
    textAlign: "center",
    color: "#FF4747",
    borderBottomColor:"#FF4747",
    borderBottomWidth:1,
    
  },
  buttonDisabled: {
    padding: 16,
    borderRadius: 70,
    opacity: 0.5,
    backgroundColor: "#FF4747",
  },
  buttonText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 16,
    alignItems: "center",
    textAlign: "center",
    color: "#FFFFFF",
  },
});
