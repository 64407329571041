
const POST_MDP_CARD_LIST_REQUEST = 'POST_MDP_CARD_LIST_REQUEST';
const POST_MDP_CARD_LIST_REQUEST_SUCCESS = 'POST_MDP_CARD_LIST_REQUEST_SUCCESS';
const POST_MDP_CARD_LIST_REQUEST_FAILURE = 'POST_MDP_CARD_LIST_REQUEST_FAILURE';

const mdpCardsList = () => {

  return {
    type: POST_MDP_CARD_LIST_REQUEST,
  }
}

export {
  mdpCardsList,
  POST_MDP_CARD_LIST_REQUEST,
  POST_MDP_CARD_LIST_REQUEST_SUCCESS,
  POST_MDP_CARD_LIST_REQUEST_FAILURE,
};