const POST_ADD_BENEFICIARIES_REQUEST = "POST_ADD_BENEFICIARIES_REQUEST";
const POST_ADD_BENEFICIARIES_REQUEST_SUCCESS = "POST_ADD_BENEFICIARIES_REQUEST_SUCCESS";
const POST_ADD_BENEFICIARIES_REQUEST_FAILURE = "POST_ADD_BENEFICIARIES_REQUEST_FAILURE";

const POST_USER_BENEFICIARIES_REQUEST = "POST_USER_BENEFICIARIES_REQUEST";
const POST_USER_BENEFICIARIES_REQUEST_SUCCESS = "POST_USER_BENEFICIARIES_REQUEST_SUCCESS";
const POST_USER_BENEFICIARIES_REQUEST_FAILURE = "POST_USER_BENEFICIARIES_REQUEST_FAILURE";

const CHANGE_BENEFICIARIES_STATUS = "CHANGE_BENEFICIARIES_STATUS"
const RESET_USER_BENEFICIARIES_STATE = "RESET_USER_BENEFICIARIES_STATE";

const userBeneficiaries = () => {
  return {
    type: POST_USER_BENEFICIARIES_REQUEST,
    
  };
};
const addBeneficiary = (formData) => {
  return {
    type: POST_ADD_BENEFICIARIES_REQUEST,
    formData,
    
  };
};
const changeBeneficiaryStatus = () => {
  return {
    type: CHANGE_BENEFICIARIES_STATUS,
    
  };
};

const resetBeneficiariesState = () => {
  return {
    type: RESET_USER_BENEFICIARIES_STATE,
  };
};
export {
  userBeneficiaries,
  addBeneficiary,
  changeBeneficiaryStatus,
  resetBeneficiariesState,
  POST_USER_BENEFICIARIES_REQUEST,
  POST_USER_BENEFICIARIES_REQUEST_SUCCESS,
  POST_USER_BENEFICIARIES_REQUEST_FAILURE,
  POST_ADD_BENEFICIARIES_REQUEST,
  POST_ADD_BENEFICIARIES_REQUEST_SUCCESS,
  POST_ADD_BENEFICIARIES_REQUEST_FAILURE,
  CHANGE_BENEFICIARIES_STATUS,
  RESET_USER_BENEFICIARIES_STATE

};
