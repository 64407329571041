import { takeEvery, put, call } from "redux-saga/effects";
import BASEURL from "../../../URLs";
import { queryApi } from "../../../QueryApi";
import {
  POST_USER_FAMILY_TRANSACTIONS_LIST_REQUEST,
  POST_USER_FAMILY_TRANSACTIONS_LIST_REQUEST_SUCCESS,
  POST_USER_FAMILY_TRANSACTIONS_LIST_REQUEST_FAILURE,
} from "./Actions"

function* handler() {
  yield takeEvery(POST_USER_FAMILY_TRANSACTIONS_LIST_REQUEST, myFamilyList);
}

function* myFamilyList(action) {
  try {
    // API call
    // console.log(action.formData);
    const apiResponse = yield call(queryApi,{
      endpoint: BASEURL.familyMemberTransactions,
      method: "POST",
      body: action.formData,
      
    });
    console.log("Family TRANSACTIONs Api",apiResponse);
    if (!("error" in apiResponse)) {
      yield put({
        type: POST_USER_FAMILY_TRANSACTIONS_LIST_REQUEST_SUCCESS,
        payload: apiResponse,
      });
    } else {
      yield put({
        type: POST_USER_FAMILY_TRANSACTIONS_LIST_REQUEST_FAILURE,
        payload: apiResponse.error,
      });
    }
  } catch (err) {
    console.log(err);
    // Handle error
    yield put({
      type: POST_USER_FAMILY_TRANSACTIONS_LIST_REQUEST_FAILURE,
      payload: { message: err },
    });
  }
}

export { handler };
