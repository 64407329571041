
const GET_BANK_LIST_REQUEST = 'GET_BANK_LIST_REQUEST';
const GET_BANK_LIST_REQUEST_SUCCESS =  'GET_BANK_LIST_REQUEST_SUCCESS';
const GET_BANK_LIST_REQUEST_FAILURE =  'GET_BANK_LIST_REQUEST_FAILURE';

const getBanks = ()=>{

    return {
        type: GET_BANK_LIST_REQUEST,
    }
}

export {
    getBanks,
    GET_BANK_LIST_REQUEST,
    GET_BANK_LIST_REQUEST_SUCCESS,
    GET_BANK_LIST_REQUEST_FAILURE,
};