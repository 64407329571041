
const GET_MDP_CARDS_SET_PIN_REQUEST = 'GET_MDP_CARDS_SET_PIN_REQUEST';
const GET_MDP_CARDS_SET_PIN_REQUEST_SUCCESS = 'GET_MDP_CARDS_SET_PIN_REQUEST_SUCCESS';
const GET_MDP_CARDS_SET_PIN_REQUEST_FAILURE = 'GET_MDP_CARDS_SET_PIN_REQUEST_FAILURE';

const mdpCardsSetPin = () => {

  return {
    type: GET_MDP_CARDS_SET_PIN_REQUEST,
  }
}

export {
  mdpCardsSetPin,
  GET_MDP_CARDS_SET_PIN_REQUEST,
  GET_MDP_CARDS_SET_PIN_REQUEST_SUCCESS,
  GET_MDP_CARDS_SET_PIN_REQUEST_FAILURE,
};