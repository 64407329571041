import {call, put, select} from 'redux-saga/effects';
import AsyncStorage from "@react-native-async-storage/async-storage";
import ToastAndroid  from "react-native-root-toast";
import { CHANGE_STATUS } from "../../Redux/Models/User/Register/Action";
import { setUser } from "../../Redux/Models/User/Login/Actions";

function* queryApi({endpoint, method, body = null}) {
  //const state = yield select();
  const user = yield AsyncStorage.getItem('user');
  const userJson = JSON.parse(user)
  userJson !==null?console.log("TOKEN", userJson.token):""
  const res = yield call(makeRequest, {
    endpoint,
    method,
    headers: {
      Authorization: userJson !==null
        ? `Bearer ${userJson.token}`
        : null,
     // 'Content-Type': 'application/json',
      'Accept': 'application/json'
    },
    body: body
  });

  if (res.status === 401) {
     AsyncStorage.removeItem("user").then((val=>console.log(val))).catch((err)=>console.log(err));;
    const user = { token: null };
    yield put(setUser(user));
    yield put({ type: CHANGE_STATUS });
    ToastAndroid.show(
      "Session Expired Logout",
      ToastAndroid.LONG
    );
  }

  const parsedResponse = yield call(parseResponse, res);
  if (!res.ok) {
   return {error: parsedResponse}
  }

  return parsedResponse;
}

const makeRequest = async ({endpoint, method, headers, body = null}) => {
  return fetch(endpoint, {
    method,
    headers,
    body: body === '{}' ? undefined : body,
  });
};

const parseResponse = async response => {
  let parsedResponse;
  try {
    parsedResponse = await response.clone().json();
  } catch {
    parsedResponse = await response.text();
  }

  return parsedResponse;
};

export {queryApi};
