import {
  StyleSheet,
  Text,
  View,
  Dimensions,
  ScrollView,
  TouchableOpacity,
  Image,
  SafeAreaView,
} from "react-native";
import React, { useState } from "react";
import Icon from "react-native-vector-icons/Feather";
import { useTranslation } from "react-i18next";
import Calendar from "../../assets/image/calendar.png";
import { Picker } from "@react-native-picker/picker";
const height = Dimensions.get("window").height;

const LetterCreditStep4 = ({ navigation }) => {
  const { t } = useTranslation();
  const [ship, setShip] = useState("");
  const [transshipment, setTransshipment] = useState("");
  const [partialShipment, setPartialShipment] = useState("");
  const [collectDocuments, setCollectDocuments] = useState("");
  const [authorized, setAuthorized] = useState("");
  const [date, setDate] = useState(new Date());
  const [deliveryDate, setDeliveryDate] = useState("");

  const DateFormate = (date) => {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    setDeliveryDate(
      date.getDate() +
        " " +
        monthNames[date.getMonth()] +
        " " +
        date.getFullYear()
    );
  };
  const OnDateChange = (event, selectedDate) => {
    const currentDate = selectedDate;
    setDate(currentDate);
    DateFormate(currentDate);
  };
  useEffect(() => {
    DateFormate(date);
  }, []);
  return (
    <SafeAreaView style={styles.topContainer}>
      <View style={styles.headerView}>
        <Icon
          name="arrow-left"
          style={styles.arrowIcon}
          onPress={() => navigation.goBack()}
        />
        <Text style={styles.cardText}>{t("common:letter_of_credit")}</Text>
      </View>
      <View style={styles.stepView}>
        <View style={styles.step1}></View>
        <View style={styles.step2}></View>
        <View style={styles.step3}></View>
        <View style={styles.step4}></View>
        <View style={styles.steps}></View>
      </View>
      <ScrollView contentContainerStyle={styles.scrollView}>
        <View style={styles.innerContainer}>
          <Text style={styles.shipmentText}>
            {t("common:shipment_details")}
          </Text>
          <Text style={styles.provideShipmentText}>
            {t("common:kindly_provide_shipment_details")}
          </Text>
          <Text style={[styles.deliveryDateText, styles.nameText]}>
            {t("common:delivery_date")}
          </Text>
          <View style={styles.dateView}>
            <Text style={styles.dateText}>{deliveryDate}</Text>
            <TouchableOpacity
              onPress={() => {
                DateTimePickerAndroid.open({
                  value: date,
                  onChange: OnDateChange,
                  mode: "date",
                  is24Hour: true,
                });
              }}
              style={styles.dateButton}
            >
              <Image source={Calendar} style={styles.calendarImage} />
            </TouchableOpacity>
          </View>
          <Text style={[styles.shipText, styles.nameText]}>
            {t("common:ship_by")}
          </Text>
          <View style={styles.pickerView}>
            <Picker
              mode="dropdown"
              selectedValue={ship}
              onValueChange={(selectedValue) => setShip(selectedValue)}
            >
              <Picker.Item
                label="Sea"
                value="Sea"
                style={styles.pickerItemText}
              />
              <Picker.Item
                label="Air"
                value="Air"
                style={styles.pickerItemText}
              />
              <Picker.Item
                label="Road"
                value="Road"
                style={styles.pickerItemText}
              />
            </Picker>
          </View>
          <Text style={[styles.partialShipmentText, styles.nameText]}>
            {t("common:partial_shipment")}
          </Text>
          <View style={styles.partialPickerView}>
            <Picker
              mode="dropdown"
              selectedValue={partialShipment}
              onValueChange={(selectedValue) =>
                setPartialShipment(selectedValue)
              }
            >
              <Picker.Item
                label="Allowed"
                value="Allowed"
                style={styles.pickerItemText}
              />
              <Picker.Item
                label="Not Allowed"
                value="Not Allowed"
                style={styles.pickerItemText}
              />
            </Picker>
          </View>
          <Text style={[styles.transshipmentText, styles.nameText]}>
            {t("common:transshipment")}
          </Text>
          <View style={styles.transshipmentPickerView}>
            <Picker
              mode="dropdown"
              selectedValue={transshipment}
              onValueChange={(selectedValue) => setTransshipment(selectedValue)}
            >
              <Picker.Item
                label="Allowed"
                value="Allowed"
                style={styles.pickerItemText}
              />
              <Picker.Item
                label="Not Allowed"
                value="Not Allowed"
                style={styles.pickerItemText}
              />
            </Picker>
          </View>
          <Text style={[styles.dateOfShipmentText, , styles.nameText]}>
            {t("common:date_of_shipment")}
          </Text>
          <View style={styles.dateOfShipmentView}>
            <Text style={styles.dateText}>1 Nov 2021</Text>
            <TouchableOpacity
              onPress={() => {
                DateTimePickerAndroid.open({
                  value: date,
                  onChange: OnDateChange,
                  mode: "date",
                  is24Hour: true,
                });
              }}
              style={styles.dateButton}
            >
              <Image source={Calendar} style={styles.calendarImage} />
            </TouchableOpacity>
          </View>

          <Text style={[styles.presentationText, styles.nameText]}>
            {t("common:latest_date_of_presentation")}
          </Text>
          <View style={styles.presentationView}>
            <Text style={styles.dateText}>1 Nov 2021</Text>
            <TouchableOpacity
              onPress={() => {
                DateTimePickerAndroid.open({
                  value: date,
                  onChange: OnDateChange,
                  mode: "date",
                  is24Hour: true,
                });
              }}
              style={styles.dateButton}
            >
              <Image source={Calendar} style={styles.calendarImage} />
            </TouchableOpacity>
          </View>
          <Text style={[styles.collectDocumentsText, styles.nameText]}>
            {t("common:collect_your_documents")}
          </Text>
          <View style={styles.collectDocumentsPickerView}>
            <Picker
              mode="dropdown"
              selectedValue={collectDocuments}
              onValueChange={(selectedValue) =>
                setCollectDocuments(selectedValue)
              }
            >
              <Picker.Item
                label="Pickup"
                value="Pickup"
                style={styles.pickerItemText}
              />
            </Picker>
          </View>
          <Text style={[styles.authorizedText, styles.nameText]}>
            {t("common:authorized_to_pick_or_deliver")}
          </Text>
          <View style={styles.authorizedPickerView}>
            <Picker
              mode="dropdown"
              selectedValue={authorized}
              onValueChange={(selectedValue) => setAuthorized(selectedValue)}
            >
              <Picker.Item
                label="Account signee"
                value="Account signee"
                style={styles.pickerItemText}
              />
            </Picker>
          </View>
          <View style={styles.btnView}>
            <TouchableOpacity
              onPress={() => navigation.navigate("LetterCreditStep5")}
              style={styles.button}
            >
              <Text style={styles.buttonText}>
                {t("common:save_and_continue")}
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

export default LetterCreditStep4;

const styles = StyleSheet.create({
  topContainer: {
    height: height,
    backgroundColor: "#FFFFFF",
  },
  headerView: {
    flexDirection: "row",
    marginTop: 44,
    alignItems: "center",
    backgroundColor: "#FFFFFF",
  },
  arrowIcon: {
    fontSize: 25,
    color: "#101820",
    marginLeft: 24,
  },
  cardText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 22,
    color: "#000000",
    left: 90,
  },
  stepView: {
    flexDirection: "row",
    height: 6,
    marginTop: 40,
    marginBottom: 25,
    marginLeft: 24,
    marginRight: 24,
    justifyContent: "space-between",
  },
  step1: {
    width: 59,
    height: 6,
    backgroundColor: "#FF4747",
    borderRadius: 17,
  },
  step2: {
    width: 59,
    height: 6,
    backgroundColor: "#FF4747",
    borderRadius: 17,
  },
  step3: {
    width: 59,
    height: 6,
    backgroundColor: "#FF4747",
    borderRadius: 17,
  },
  step4: {
    width: 59,
    height: 6,
    backgroundColor: "#FF4747",
    borderRadius: 17,
  },
  steps: {
    width: 59,
    height: 6,
    backgroundColor: "#000000",
    opacity: 0.1,
    borderRadius: 17,
  },
  scrollView: {
    height: 1130,
  },
  innerContainer: {
    flex: 1,
    marginLeft: 24,
    marginRight: 24,
  },
  shipmentText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 18,
    color: "#000000",
    marginTop: 20,
  },
  provideShipmentText: {
    marginTop: 16,
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 14,
    color: "#101820",
  },
  nameText: {
    height: 28,
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 14,
    color: "#A9ABAE",
  },
  deliveryDateText: {
    marginTop: 24,
  },
  dateView: {
    borderBottomWidth: 1,
    borderColor: "#F1F1F1",
    height: 56,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  dateButton: {
    top: 20,
  },
  calendarImage: {
    height: 18,
    width: 16.5,
    right: 15,
    resizeMode: "contain"
  },
  dateText: {
    height: 24,
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 14,
    color: "#101820",
    top: 15,
  },
  shipText: {
    top: 24,
  },
  pickerView: {
    height: 60,
    top: 10,
    borderBottomWidth: 1,
    borderColor: "#E7E8E9",
  },
  pickerItemText: {
    color: "#101820",
    fontSize: 14,
    fontWeight: "500",
    fontFamily: "Inter",
    fontStyle: "normal",
  },
  partialPickerView: {
    height: 60,
    top: 24,
    borderBottomWidth: 1,
    borderColor: "#E7E8E9",
  },
  partialShipmentText: {
    top: 30,
  },
  transshipmentPickerView: {
    height: 60,
    top: 40,
    borderBottomWidth: 1,
    borderColor: "#E7E8E9",
  },
  transshipmentText: {
    top: 40,
  },
  dateOfShipmentText: {
    top: 60,
  },
  dateOfShipmentView: {
    top: 60,
    borderBottomWidth: 1,
    borderColor: "#F1F1F1",
    height: 56,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  presentationText: {
    top: 80,
  },
  presentationView: {
    top: 80,
    borderBottomWidth: 1,
    borderColor: "#F1F1F1",
    height: 56,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  collectDocumentsPickerView: {
    height: 60,
    top: 100,
    borderBottomWidth: 1,
    borderColor: "#E7E8E9",
  },
  collectDocumentsText: {
    top: 100,
  },
  authorizedText: {
    top: 120,
  },
  authorizedPickerView: {
    height: 60,
    width: 327,
    top: 120,
    borderBottomWidth: 1,
    borderColor: "#E7E8E9",
  },
  btnView: {
    flex: 1,
    marginBottom: 51,
    justifyContent: "flex-end",
  },
  button: {
    padding: 16,
    backgroundColor: "#FF4747",
    borderRadius: 70,
  },
  buttonText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 16,
    alignItems: "center",
    textAlign: "center",
    color: "#FFFFFF",
  },
});
