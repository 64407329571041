import React, { useState, useEffect } from "react";
import { NavigationContainer } from "@react-navigation/native";
import AppStack from "./AppStack";
import AuthStack from "./AuthStack";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../Redux/Models/User/Login/Actions";

const RootStack = ({ user }) => {
  const dispatch = useDispatch();
  const getUser = useSelector((state) => state.user.data);

  useEffect(() => {
    user && dispatch(setUser(user));
  }, []);
console.log('renderROOT')
  return (
    <NavigationContainer>
      {getUser.token !== null ? <AppStack /> : <AuthStack />}
    </NavigationContainer>
  );
};
export default RootStack;
