import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  TouchableOpacity,
  SafeAreaView,
} from "react-native";
import React, { useState, useEffect } from "react";
import Icon from "react-native-vector-icons/Feather";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import DocumentForm from "./Components/DocumentForm";
import { cblVerifyDocument } from "../../Redux/Models/CblVerifyDocuments/Actions";
import Loading from "../Loading";
import ToastAndroid from "react-native-root-toast";
import {
  resetUploadCBLFilestate,
  ClearCBLFiles,
} from "../../Redux/Models/DocumentUpload/CBLDocuments/Actions";
import {
  requestCbl,
  resetRequestCbl,
} from "../../Redux/Models/RequestCbl/Actions";
import { BarCodeScanner } from "expo-barcode-scanner";
import Alert from "../../Components/Alert";
import SideBarConatainer from "../../Components/SideBarConatainer"
const CblRequest = ({ navigation }) => {
  const { t } = useTranslation();

  const cblDocumentVerify = useSelector((state) => state.cblVerifyDocument);
  const documentUpload = useSelector((state) => state.cblDocuments);
  const deleteFile = useSelector((state) => state.deleteFile);
  const cblRequest = useSelector((state) => state.requestCbl);
  const [hasPermission, setHasPermission] = useState(null);

  const [allDocs, setAllDocs] = useState({});
  const dispatch = useDispatch();
  const getBarCodeScannerPermissions = async () => {
    const { status } = await BarCodeScanner.requestPermissionsAsync();
    setHasPermission(status === "granted");
  };
  useEffect(() => {
    dispatch(cblVerifyDocument());
    getBarCodeScannerPermissions();
    dispatch(ClearCBLFiles());
  }, []);

  useEffect(() => {
    if (documentUpload.error === true) {
      dispatch(resetUploadCBLFilestate());
      alert(documentUpload.message);
    }
  }, [documentUpload]);

  const validate = () => {
    for (let [ind, val] of cblDocumentVerify.data.verify_doc_types.entries()) {
      if (allDocs[ind].id_number === "") {
        ToastAndroid.show(t("common:Please_enter_ID"), ToastAndroid.LONG);
        return false;
      } else if (allDocs[ind].date_issue === undefined) {
        ToastAndroid.show(
          t("common:Please_select_date_of_issue"),
          ToastAndroid.LONG
        );
        return false;
      } else if (allDocs[ind].expiry_date === undefined) {
        ToastAndroid.show(
          t("common:Please_select_expiration_date"),
          ToastAndroid.LONG
        );
        return false;
      } else if (allDocs[ind].doc_files === undefined) {
        ToastAndroid.show(
          t("common:Please_select_identification_document_type"),
          ToastAndroid.LONG
        );
        return false;
      }
    }
    return true;
  };

  const cblRequestApi = () => {
    if (!validate()) {
      return;
    }

    let addToForm = new FormData();

    for (let [mainkey, values] of Object.entries(allDocs)) {
      for (let [key, value] of Object.entries(values)) {
        if (key !== "doc_files") {
          addToForm.append("docs[" + mainkey + "][" + key + "]", value);
        } else {
          value?.length > 0 &&
            value.map((val, ind) => {
              for (let [key, value] of Object.entries(val)) {
                addToForm.append(
                  "docs[" + mainkey + "][doc_files][" + ind + "][" + key + "]",
                  value
                );
              }
            });
        }
      }
    }
    let tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    addToForm.append(
      "target_completion_date",
      Math.ceil(tomorrow.getTime() / 1000)
    );
    addToForm.append("request_date", Math.ceil(new Date().getTime() / 1000));
    addToForm.append("priority", "High");
    addToForm.append("description", "");
    dispatch(requestCbl(addToForm));
  };

  useEffect(() => {
    if (cblRequest.error === true) {
      return Alert("Error", cblRequest.message, [
        {
          text: "OK",
          onPress: () => {
            dispatch(resetRequestCbl());
          },
        },
      ]);
    }
    if (cblRequest.success === true) {
      return Alert("Congratulation", cblRequest.message, [
        {
          text: "OK",
          onPress: () => {
            dispatch(resetRequestCbl());
            navigation.navigate("RequestTopUp");
          },
        },
      ]);
    }
  }, [cblRequest]);

  return (
    <SideBarConatainer navigation={navigation}>
      <View style={styles.headerView}>
        <Icon
          name="arrow-left"
          size={30}
          style={styles.arrowIcon}
          onPress={() => navigation.goBack()}
        />
        <Text style={styles.cblText}>{t("common:cbl_request")}</Text>
      </View>
      <View style={styles.docContainer}>
        <ScrollView contentContainerStyle={{ flexGrow: 1 }} showsHorizontalScrollIndicator={false}>
          <View style={styles.mainContainer}>
            <View style={{ flex: 1 }}>
              <View style={styles.innerContainer}>
                <View style={styles.textConatiner}>
                  <Text style={styles.documentsText}>{t("common:documents")}</Text>

                  <Text style={styles.supportingText}>
                    {t("common:kindly_attach_supporting")}
                  </Text>
                </View>
                {cblDocumentVerify.data?.verify_doc_types.map((val, ind) => (
                  <DocumentForm
                    setAllDocs={setAllDocs}
                    key={ind}
                    doc={ind}
                    title={val}
                  />
                ))}
                <View style={styles.btnView}>
                  <TouchableOpacity onPress={cblRequestApi} style={styles.button}>
                    <Text style={styles.buttonText}>{t("common:SUBMIT")}</Text>
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          </View>
        </ScrollView>
      </View>

      {(cblDocumentVerify.loading ||
        documentUpload.loading ||
        deleteFile.loading ||
        cblRequest.loading) && <Loading></Loading>}
    </SideBarConatainer>
  );
};

export default CblRequest;

const styles = StyleSheet.create({
  topContainer: {
    flex: 1,
    backgroundColor: "#FFFFFF",
  },
  headerView: {
    flexDirection: "row",
    alignItems: "center",
  },
  arrowIcon: {
  },
  cblText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 26,
    color: "#000000",
    marginLeft: 10
  },
  docContainer: {
    backgroundColor: "#FFF",
    borderRadius: 16,
    marginTop:27,
    flex: 1,
  },
  mainContainer: {
    padding: 20,
  },
  innerContainer: {
    flex: 1,
  },
  textConatiner: {
    marginTop: 30,
    marginLeft: 20
  },
  documentsText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 18,
    color: "#000000",
    alignSelf: "flex-start",
  },
  supportingText: {
    marginTop: 10,
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 16,
    alignSelf: "flex-start",
    color: "#101820",
  },
  btnView: {
    flex: 1,
    marginTop: 30,
    alignItems: "flex-end",
    justifyContent: 'flex-end'
  },
  button: {
    padding: 16,
    backgroundColor: "#FF4747",
    borderRadius: 70,
    width: 404
  },
  buttonDisabled: {
    padding: 16,
    borderRadius: 70,
    opacity: 0.5,
    backgroundColor: "#FF4747",
  },
  buttonText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 16,
    alignItems: "center",
    textAlign: "center",
    color: "#FFFFFF",
  },
});
