import {
  POST_FAMILY_MEMBER_TOP_UP_REQUEST,
  POST_FAMILY_MEMBER_TOP_UP_REQUEST_SUCCESS,
  POST_FAMILY_MEMBER_TOP_UP_REQUEST_FAILURE,
  CHANGE_REQUEST_FAMILY_STATUS,
} from "./Actions";

const initialState = {
  message: null,
  data: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case POST_FAMILY_MEMBER_TOP_UP_REQUEST: {
      console.log(POST_FAMILY_MEMBER_TOP_UP_REQUEST);

      return {
        ...state,
        error: false,
        success: false,
        loading: true,
      };
    }
    case POST_FAMILY_MEMBER_TOP_UP_REQUEST_SUCCESS: {
      const { message, data } = action.payload;
      console.log(POST_FAMILY_MEMBER_TOP_UP_REQUEST_SUCCESS);
      return {
        ...state,
        loading: false,
        success: true,
        data,
        message,
      };
    }
    case POST_FAMILY_MEMBER_TOP_UP_REQUEST_FAILURE: {
      console.log(POST_FAMILY_MEMBER_TOP_UP_REQUEST_FAILURE);

      const { message } = action.payload;
      return {
        ...state,
        loading: false,
        success: false,
        data: [],
        error: true,
        message,
      };
    }
    case CHANGE_REQUEST_FAMILY_STATUS: {
      console.log(CHANGE_REQUEST_FAMILY_STATUS);
      return {
        ...state,
        loading: false,
        success: false,
        error: false,
      };
    }

    default:
      return state;
  }
};

export { reducer };
