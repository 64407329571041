import {
  GET_USER_DETAILS_REQUEST,
  GET_USER_DETAILS_REQUEST_SUCCESS,
  GET_USER_DETAILS_REQUEST_FAILURE,
  GET_USER_TRANSACTIONS_REQUEST,
  GET_USER_TRANSACTIONS_REQUEST_SUCCESS,
  GET_USER_TRANSACTIONS_REQUEST_FAILURE,
} from "./Actions";
const initialState = {
  message: null,
  data: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_DETAILS_REQUEST: {
      console.log(GET_USER_DETAILS_REQUEST);

      return {
        ...state,
        error: false,
        loading: true,
      };
    }
    case GET_USER_DETAILS_REQUEST_SUCCESS: {
      const { message, data } = action.payload;
      console.log(GET_USER_DETAILS_REQUEST_SUCCESS);
      return {
        ...state,
        data,
        loading: false,
        success: true,
        message,
      };
    }
    case GET_USER_DETAILS_REQUEST_FAILURE: {
      console.log(GET_USER_DETAILS_REQUEST_FAILURE);

      const { message } = action.payload;
      return {
        ...state,
        loading: false,
        error: true,
        message,
      };
    }

    case GET_USER_TRANSACTIONS_REQUEST: {
      console.log(GET_USER_TRANSACTIONS_REQUEST);
      return {
        ...state,
        error: false,
        transactionsLoading:true
      };
    }
    case GET_USER_TRANSACTIONS_REQUEST_SUCCESS: {
      const { message, data } = action.payload;
      console.log(GET_USER_TRANSACTIONS_REQUEST_SUCCESS);
      return {
        ...state,
        userTransactions:data,
        transactionsLoading: false,
        success: true,
        message,
      };
    }
    case GET_USER_TRANSACTIONS_REQUEST_FAILURE: {
      console.log(GET_USER_TRANSACTIONS_REQUEST_FAILURE);

      const { message } = action.payload;
      return {
        ...state,
        transactionsLoading: false,
        error: true,
        message,
      };
    }
    default:
      return state;
  }
};

export { reducer };
