import { takeEvery, put, call } from "redux-saga/effects";
import BASEURL from "../URLs";
import { queryApi } from "../QueryApi";
import {
  GET_CBL_VERIFY_DOCUMENT_REQUEST,
  GET_CBL_VERIFY_DOCUMENT_REQUEST_SUCCESS,
  GET_CBL_VERIFY_DOCUMENT_REQUEST_FAILURE,
} from "./Actions";

function* handler() {
  yield takeEvery(GET_CBL_VERIFY_DOCUMENT_REQUEST, cblVerifyDocument);
}

function* cblVerifyDocument(action) {
  try {
    // API call
    console.log(action.formData);
    const apiResponse = yield call(queryApi, {
      endpoint: BASEURL.cblVerifyDocument,
      method: "GET",
      body: action.formData,
    });
    console.log("HIT VERIFY DOCUMENT", apiResponse);
    if (!("error" in apiResponse)) {
      yield put({
        type: GET_CBL_VERIFY_DOCUMENT_REQUEST_SUCCESS,
        payload: apiResponse,
      });
    } else {
      yield put({
        type: GET_CBL_VERIFY_DOCUMENT_REQUEST_FAILURE,
        payload: apiResponse.error,
      });
    }
  } catch (err) {
    console.log(err);
    // Handle error
    yield put({
      type: GET_CBL_VERIFY_DOCUMENT_REQUEST_FAILURE,
      payload: { message: err },
    });
  }
}

export { handler };
