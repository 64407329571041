import {
  StyleSheet,
  Text,
  View,
  ImageBackground,
  ScrollView,
  TouchableOpacity,
  
} from "react-native";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Background from "../../../assets/image/background.png";

const Wallets = ({ wallets ,onChangeWallet}) => {
  const [activeView, setActiveView] = useState(0);
  const [activeWallet, setActiveWallet] = useState(0);

  const { t } = useTranslation();

  const onChange = (slideEvent) => {
    if (slideEvent) {
      const slide = Math.ceil(
        slideEvent.contentOffset.x / slideEvent.layoutMeasurement.width
      );
      if (slide != activeView) {
        setActiveView(slide);
      }
    }
  };
  const selectWallet =( walletIndex,walletValues) =>{
    setActiveWallet(walletIndex)
    onChangeWallet(walletValues)
  }

  // let wallets = [
  //   {
  //     balance: 10,
  //     currency: { currency_iso_code: "LYD" },
  //   },
  //   {
  //     balance: 40,
  //     currency: { currency_iso_code: "USD" },
  //   },
    
  // ];

  return (
    <>
      <View style={styles.container}>
        <ScrollView
          // onScroll={({ slideEvent }) => onChange(slideEvent)}
          horizontal={true}
          showsHorizontalScrollIndicator={false}
        >
          {wallets &&
            wallets.map((val, ind) => {
              return (
                <TouchableOpacity
                  key={ind}
                  onPress={() => selectWallet(ind, val)}
                >
                  <ImageBackground
                    source={activeWallet == ind && Background}
                    style={styles.Background}
                  >
                    <View
                        style={
                          activeWallet == ind
                            ? styles.iconContainer
                            : styles.iconContainerBlue
                        }
                      >
                        <Text
                          style={
                            activeWallet == ind
                              ? styles.usdImage
                              : styles.usdImageBlack
                          }
                        >
                          {val.currency.currency_iso_code}
                        </Text>
                      </View>
                      <View
                        style={
                          activeWallet == ind
                            ? styles.textContainer
                            : styles.textContainerBlue
                        }
                      >
                        <Text
                          style={
                            activeWallet == ind
                              ? styles.usdText
                              : styles.eurText
                          }
                        >
                          {val.currency.currency_iso_code}
                        </Text>
                      </View>
                      <View
                        style={
                          activeWallet == ind
                            ? styles.textContainer
                            : styles.textContainerBlue
                        }
                      >
                        <Text
                          style={
                            activeWallet == ind
                              ? styles.priceText
                              : styles.priceEurText
                          }
                        >
                          {val.balance}
                        </Text>
                      </View>
                  </ImageBackground>
                </TouchableOpacity>
              );
            })}
        </ScrollView>
      </View>
      {/* <View style={styles.dotView}>
        {wallets &&
          wallets.map((val, ind) => {
            return (
              <View
                key={ind}
                style={
                  activeWallet == ind ? styles.dotActive : styles.dotInactive
                }
              ></View>
            );
          })}
      </View> */}
    </>
  );
};

export default Wallets;

const styles = StyleSheet.create({
  container: {
    height: 120,
    flexDirection: "row",
  },
  Background: {
    height: null,
    minWidth: 135,
    backgroundColor: "#FFFFFF",
    borderRadius: 16,
    resizeMode: "contain",
    flex: 1,
    borderWidth:1,
    borderColor:"#F8F8F8",
    marginHorizontal:10
  },
  iconContainer: {
    height: 30,
    width: 30,
    marginLeft: 16,
    marginTop: 20,
    borderRadius: 25,
    backgroundColor: "white",
    justifyContent: "center",
    alignItems: "center",
  },
  iconContainerBlue: {
    height: 30,
    width: 30,
    marginLeft: 16,
    marginTop: 20,
    borderRadius: 25,
    backgroundColor: "#5162FD",
    justifyContent: "center",
    alignItems: "center",
  },
  usdImage: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "700",
    color: "#F86261",
  },
  usdImageBlack: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "700",
    color: "#FFFFFF",
  },
  usdText: {
    marginLeft: 16,
    marginTop: 6,
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 14,
    color: "#FFFFFF",
    opacity: 0.6,
  },
  textContainer: {
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  textContainerBlue: {
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  priceText: {
    marginLeft: 16,
    marginTop:5,
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 18,
    color: "#FFFFFF",
  },
  eurView: {
    marginLeft: 24,
    height: 120,
    width: 135,
    backgroundColor: "#FFFFFF",
    borderRadius: 16,
  },
  eurText: {
    marginTop: 6,
    marginLeft: 16,
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 14,
    color: "#000000",
    opacity: 0.6,
  },
  priceEurText: {
    marginLeft: 16,
    marginTop:5,
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 18,
    color: "#000000",
  },
  gbrView: {
    marginLeft: 24,
    marginRight: 24,
    height: 120,
    width: 135,
    backgroundColor: "#FFFFFF",
    borderRadius: 16,
  },
  dotView: {
    marginTop: 24,
    flexDirection: "row",
    width: 72,
    alignSelf: "center",
    justifyContent: "space-evenly",
  },
  dotActive: {
    width: 24,
    height: 6,
    backgroundColor: "#FF4747",
    borderRadius: 20,
  },
  dotInactive: {
    width: 6,
    height: 6,
    borderRadius: 20,
    backgroundColor: "#BDBDBD",
  },
});
