import {
  StyleSheet,
  Text,
  View,
  Dimensions,
  TextInput, I18nManager,
  TouchableOpacity,
} from "react-native";
import React, { useState, useEffect, useRef } from "react";
import Icon from "react-native-vector-icons/Feather";
import { useTranslation } from "react-i18next";
import { SafeAreaView } from "react-native-safe-area-context";
const height = Dimensions.get("window").height;
import { useDispatch, useSelector } from "react-redux";
import {
  addBeneficiary,
  changeBeneficiaryStatus, resetBeneficiariesState
} from "../../Redux/Models/Beneficiaries/Actions";
import Loading from "../Loading";
import Alert from "../../Components/Alert";
import HeaderContainer from '../../Components/AppHeader';


const AddBeneficiaries = ({ navigation }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [accountHolder, setAccountHolder] = useState("");
  const beneficiaries = useSelector((state) => state.beneficiaries);

  const accountHolderRef = useRef();

  const handleForm = () => {
    let formData = new FormData();
    formData.append("phone_number", phoneNumber.replace(/-/g, ""));
    formData.append("beneficiary_name", accountHolder);

    dispatch(addBeneficiary(formData));
  };

  useEffect(() => {
    beneficiaries.addBeneficiary === 1 &&
      Alert("Congratulation", beneficiaries.message, [
        {
          text: "OK",
          onPress: () => {
            dispatch(changeBeneficiaryStatus());
            navigation.navigate("Beneficiaries");
          },
        },
      ]);

    beneficiaries.addBeneficiary !== 1 &&
      beneficiaries.beneficiarySuccess &&
      Alert("Error", beneficiaries.message, [
        {
          text: "OK",
          onPress: () => {
            dispatch(changeBeneficiaryStatus());
          },
        },
      ]);
    if (beneficiaries.error === true) {
      Alert("Error", beneficiaries.message.toString(), [
        {
          text: "OK",
          onPress: () => {
            dispatch(resetBeneficiariesState());
          },
        },
      ]);
    }
  }, [beneficiaries]);

  return (
    <SafeAreaView style={styles.topContainer}>
      <HeaderContainer />
      <View style={styles.topHeader}>
        <View style={styles.innerContainer}>
          <View style={styles.headerView}>
            <Icon
              name="arrow-left"
              size={30}
              onPress={() => navigation.goBack()}
            />
            <Text style={styles.editText}>{t("common:add_beneficiaries")}</Text>
          </View>
          <View style={styles.container}>
            <Text style={[styles.phoneText, styles.nameText]}>
              {t("common:phone_number_st")}
              <Text style={{ color: "#FF4747" }}>*</Text>
            </Text>
            <TextInput
              textAlign={I18nManager.isRTL ? "right" : "left"}
              returnKeyType="next"
              onSubmitEditing={() => accountHolderRef.current.focus()}
              keyboardType="phone-pad"
              maxLength={12}
              value={phoneNumber}
              onChangeText={(text) => {
                var cleaned = ("" + text).replace(/\D/g, "");
                let normValue = `${cleaned.substring(0, 3)}${cleaned.length > 3 ? "-" : ""
                  }${cleaned.substring(3, 6)}${cleaned.length > 6 ? "-" : ""
                  }${cleaned.substring(6, 10)}`;
                setPhoneNumber(normValue);
              }}
              style={[styles.phoneInput, styles.input]}
            ></TextInput>

            <Text style={[styles.accountText, styles.nameText]}>
              {t("common:account_holder_name_st")}
              <Text style={{ color: "#FF4747" }}>*</Text>
            </Text>
            <TextInput
              textAlign={I18nManager.isRTL ? "right" : "left"}
              defaultValue={accountHolder}
              ref={accountHolderRef}
              onChangeText={(newText) => setAccountHolder(newText)}
              style={[styles.accountInput, styles.input]}
            ></TextInput>
            <View style={styles.btnView}>
              <TouchableOpacity onPress={handleForm} style={styles.button}>
                <Text style={styles.buttonText}>
                  {t("common:add_beneficiaries")}
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </View>
      {beneficiaries.loading && <Loading></Loading>}
    </SafeAreaView>
  );
};

export default AddBeneficiaries;

const styles = StyleSheet.create({
  topContainer: {
    flex: 1,
    backgroundColor: "#f8f8f8",
  },
  topHeader:{
    flex:1,
    justifyContent:"center",
    flexDirection:"row"
  },
  innerContainer: {
    width:1400
  },
  headerView: {
    flexDirection: "row",
    marginTop: 40,
  },
  editText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 26,
    color: "#000000",
    marginLeft: 10
  },
  container: {
    backgroundColor: "#ffffff",
    borderRadius: 16,
    padding: 20,
    marginTop: 25
  },
  nameText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 14,
    color: "#A9ABAE",
    alignSelf: "flex-start",
  },
  input: {
    borderBottomWidth: 1,
    borderColor: "#E7E8E9",
    height: 50,
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 14,
    color: "#101820",
    outlineStyle: "none"

  },
  phoneText: {
    marginTop: 10,
  },
  phoneInput: {},

  accountText: {
    marginTop: 20,
  },
  accountInput: {
    marginTop: 4,
  },
  btnView: {
    flex: 1,
    marginTop: 46,
    // marginBottom: 20,
    alignItems: "flex-end"
  },
  button: {
    padding: 16,
    backgroundColor: "#FF4747",
    borderRadius: 70,
    width: 327
  },
  buttonText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 16,
    alignItems: "center",
    textAlign: "center",
    color: "#FFFFFF",
  },
});
