import {
  POST_USER_VERIFY_PASS_REQUEST,
  POST_USER_VERIFY_PASS_SUCCESS,
  POST_USER_VERIFY_PASS_FAILURE,
  RESET_USER_VERIFY_PASS_STATE
} from "./Actions";

const initialState = {
  message: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case POST_USER_VERIFY_PASS_REQUEST: {
      console.log(POST_USER_VERIFY_PASS_REQUEST)

      return {
        ...state,
        error:false,
        loading: true,
      };
    }
    case POST_USER_VERIFY_PASS_SUCCESS: {
      const { message } = action.payload;
      console.log(POST_USER_VERIFY_PASS_SUCCESS)
      return {
        ...state,
        loading: false,
        success:true,
        message,
      };
    }
    case POST_USER_VERIFY_PASS_FAILURE: {
      console.log(POST_USER_VERIFY_PASS_FAILURE)

      const { message } = action.payload;
      return {
        ...state,
        loading: false,        
        error:true,
        message,
        
        
      };
     
    }
    case RESET_USER_VERIFY_PASS_STATE: {
      return{
        ...initialState
      };
    }
    default:
      return state;
  }
};

export { reducer };
