
const GET_CBL_NUMBER_REQUEST = 'GET_CBL_NUMBER_REQUEST';
const GET_CBL_NUMBER_REQUEST_SUCCESS =  'GET_CBL_NUMBER_REQUEST_SUCCESS';
const GET_CBL_NUMBER_REQUEST_FAILURE =  'GET_CBL_NUMBER_REQUEST_FAILURE';

const cblNumber = ()=>{

    return {
        type: GET_CBL_NUMBER_REQUEST,
    }
}

export {
  cblNumber,
  GET_CBL_NUMBER_REQUEST,
  GET_CBL_NUMBER_REQUEST_SUCCESS,
  GET_CBL_NUMBER_REQUEST_FAILURE,
};