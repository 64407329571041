
const POST_MDP_CARDS_KYC_UPDATE_REQUEST = 'POST_MDP_CARDS_KYC_UPDATE_REQUEST';
const POST_MDP_CARDS_KYC_UPDATE_REQUEST_SUCCESS = 'POST_MDP_CARDS_KYC_UPDATE_REQUEST_SUCCESS';
const POST_MDP_CARDS_KYC_UPDATE_REQUEST_FAILURE = 'POST_MDP_CARDS_KYC_UPDATE_REQUEST_FAILURE';
const RESET_MDP_CARDS_UPDATE_REQUEST_STATE = 'RESET_MDP_CARDS_UPDATE_REQUEST_STATE'
const mdpCardsKycUpdate = (formData) => {

  return {
    type: POST_MDP_CARDS_KYC_UPDATE_REQUEST,
    formData
  }
}
const resetMdpCardsKycUpdate = () => {

  return {
    type: RESET_MDP_CARDS_UPDATE_REQUEST_STATE,
  }
}
export {
  mdpCardsKycUpdate,
  resetMdpCardsKycUpdate,
  POST_MDP_CARDS_KYC_UPDATE_REQUEST,
  POST_MDP_CARDS_KYC_UPDATE_REQUEST_SUCCESS,
  POST_MDP_CARDS_KYC_UPDATE_REQUEST_FAILURE,
  RESET_MDP_CARDS_UPDATE_REQUEST_STATE
};