const POST_CREATE_FAMILY_MEMBER_PASSWORD_REQUEST = "POST_CREATE_FAMILY_MEMBER_PASSWORD_REQUEST";
const POST_CREATE_FAMILY_MEMBER_PASSWORD_REQUEST_SUCCESS = "POST_CREATE_FAMILY_MEMBER_PASSWORD_REQUEST_SUCCESS";
const POST_CREATE_FAMILY_MEMBER_PASSWORD_REQUEST_FAILURE = "POST_CREATE_FAMILY_MEMBER_PASSWORD_REQUEST_FAILURE";
const FAMILY_MEMBER_PASSWORD_STATUS_REST = "FAMILY_MEMBER_PASSWORD_STATUS_REST";


const changeFamilyMemberPassword = ({formData, parms} ) => {
  return {
    type: POST_CREATE_FAMILY_MEMBER_PASSWORD_REQUEST,
    formData,
    parms
  };
}; 
const changeFamilyMemberPasswordStatus = () => {
  return {
    type: FAMILY_MEMBER_PASSWORD_STATUS_REST,
  };
};

export {
  changeFamilyMemberPassword,
  changeFamilyMemberPasswordStatus,
  POST_CREATE_FAMILY_MEMBER_PASSWORD_REQUEST,
  POST_CREATE_FAMILY_MEMBER_PASSWORD_REQUEST_SUCCESS,
  POST_CREATE_FAMILY_MEMBER_PASSWORD_REQUEST_FAILURE,
  FAMILY_MEMBER_PASSWORD_STATUS_REST
};
