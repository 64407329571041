const POST_USER_VERIFY_PASS_REQUEST = "POST_USER_VERIFY_PASS_REQUEST";
const POST_USER_VERIFY_PASS_SUCCESS = "POST_USER_VERIFY_PASS_SUCCESS";
const POST_USER_VERIFY_PASS_FAILURE = "POST_USER_VERIFY_PASS_FAILURE";
const RESET_USER_VERIFY_PASS_STATE = "RESET_USER_VERIFY_PASS_STATE";

const userPinVerify = (formData) => {
  return {
    type: POST_USER_VERIFY_PASS_REQUEST,
    formData,
  }
}
const resetPassCodeSate = () => {
  return {
    type: RESET_USER_VERIFY_PASS_STATE
  }
}
export {
  userPinVerify,
  resetPassCodeSate,
  POST_USER_VERIFY_PASS_REQUEST,
  POST_USER_VERIFY_PASS_SUCCESS,
  POST_USER_VERIFY_PASS_FAILURE,
  RESET_USER_VERIFY_PASS_STATE
};
