import {takeEvery, put, call} from 'redux-saga/effects';
import BASEURL from '../../URLs'
import {queryApi} from '../../QueryApi'
import {
  POST_MDP_CARDS_BALANCE_REQUEST,
  POST_MDP_CARDS_BALANCE_REQUEST_SUCCESS,
  POST_MDP_CARDS_BALANCE_REQUEST_FAILURE,
} from './Actions'; 
//import {queryApi} from '../query-api';

function* handler() {
  yield takeEvery(POST_MDP_CARDS_BALANCE_REQUEST, mdpCardsBalance);
}


function* mdpCardsBalance(action) {
  try {
    // API call
    console.log("MDP_CARDS_BALANCE", action.formData);
    const apiResponse = yield call(queryApi, {
      endpoint: BASEURL.mdpCardsBalance,
      method: "POST",
      body:action.formData
    });
    console.log("MDP_CARDS_BALANCE", apiResponse);
    if (!("error" in apiResponse)) {
      yield put({
        type: POST_MDP_CARDS_BALANCE_REQUEST_SUCCESS,
        payload: apiResponse,
      });
    } else {
      yield put({
        type: POST_MDP_CARDS_BALANCE_REQUEST_FAILURE,
        payload: apiResponse.error,
      });
    }
  } catch (err) {
    console.log(err);
    // Handle error
    yield put({
      type: POST_MDP_CARDS_BALANCE_REQUEST_FAILURE,
      payload: { message: err },
    });
  }
}

export {handler};