
const POST_FILE_UPLOAD_REQUEST = 'POST_FILE_UPLOAD_REQUEST';
const POST_FILE_UPLOAD_REQUEST_SUCCESS =  'POST_FILE_UPLOAD_REQUEST_SUCCESS';
const POST_FILE_UPLOAD_REQUEST_FAILURE =  'POST_FILE_UPLOAD_REQUEST_FAILURE';
const RESET_FILE_UPLOAD_STATE = 'RESET_FILE_UPLOAD_STATE';
const DETELE_FROM_FILE_UPLOADED = 'DETELE_FROM_FILE_UPLOADED'
const SET_EDIT_FROM_FILES = 'SET_EDIT_FROM_FILES'
const CLEAR_FILES_ON_KYC_REQUEST_SUCCESS = 'CLEAR_FILES_ON_KYC_REQUEST_SUCCESS'

const uploadFile = (formData,doc)=>{
console.log('acountion ',doc)
    return {
        type: POST_FILE_UPLOAD_REQUEST,
        formData,
        doc
    }
}

const resetUploadFilestate = ()=>{

    return {
        type: RESET_FILE_UPLOAD_STATE,
        
    }
}
const deleteUploadedFile = (doc,index)=>{

    return {
        type: DETELE_FROM_FILE_UPLOADED,
        doc,
        index        
    }
}
const setUploadFiles = (data, doc)=>{
        return {
            type: SET_EDIT_FROM_FILES,
            data,
            doc
        }
    }
    const ClearFilesKycRequestSuccess = ()=>{
        return {
            type: CLEAR_FILES_ON_KYC_REQUEST_SUCCESS,
        }
    }
export {
    uploadFile,
    resetUploadFilestate,
    deleteUploadedFile,
    setUploadFiles,
    ClearFilesKycRequestSuccess,
    POST_FILE_UPLOAD_REQUEST,
    POST_FILE_UPLOAD_REQUEST_SUCCESS,
    POST_FILE_UPLOAD_REQUEST_FAILURE,
    RESET_FILE_UPLOAD_STATE,
    SET_EDIT_FROM_FILES,
    DETELE_FROM_FILE_UPLOADED,
    CLEAR_FILES_ON_KYC_REQUEST_SUCCESS
};