import {
  StyleSheet,
  Text,
  View,
  Image,
  TouchableOpacity,
  TextInput,
I18nManager,
  SafeAreaView,
} from "react-native";
import React, { useState } from "react";
import UpArrow from "../../../assets/image/UpArrow.png";
import { Picker } from "@react-native-picker/picker";
import { useTranslation } from "react-i18next";
import Calendar from "../../../assets/image/calendar.png";
import Add from "../../../assets/image/add.png";

const CertificateOfOrigin = () => {
  const [show, setShow] = useState(true);
  const { t } = useTranslation();
  const [date, setDate] = useState(new Date());
  const [deliveryDate, setDeliveryDate] = useState("");

  const DateFormate = (date) => {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    setDeliveryDate(
      date.getDate() +
        " " +
        monthNames[date.getMonth()] +
        " " +
        date.getFullYear()
    );
  };
  const OnDateChange = (event, selectedDate) => {
    const currentDate = selectedDate;
    setDate(currentDate);
    DateFormate(currentDate);
  };
  useEffect(() => {
    DateFormate(date);
  }, []);
  return (
    <SafeAreaView style={styles.mainContainer}>
      <View style={styles.topContainer}>
        <TouchableOpacity style={styles.btn} onPress={() => setShow(!show)}>
          <Text style={styles.certificateText}>
            {t("common:certificate_of_origin")}
          </Text>
          <Image source={UpArrow} style={styles.upArrowImage} />
        </TouchableOpacity>

        {show ? (
          <View style={styles.infoView}>
            <Text style={[styles.idNumberText, styles.nameText]}>
              {t("common:reference_number")}
            </Text>
            <TextInput 
 textAlign={I18nManager.isRTL ? "right" :"left"} 
              style={[styles.input, styles.id_numberInput]}
            ></TextInput>

            <Text style={[styles.nameText, styles.dateIssueText]}>
              {t("common:date_of_issue")}
            </Text>
            <View style={[styles.dateIssueView, styles.nameInput]}>
              <Text style={styles.dateText}>1 Jun 2010</Text>
              <View style={styles.datePickerView}>
                <TouchableOpacity
                  onPress={() => {
                    DateTimePickerAndroid.open({
                      value: date,
                      onChange: OnDateChange,
                      mode: "date",
                      is24Hour: true,
                    });
                  }}
                  style={styles.dateButton}
                >
                  <Image source={Calendar} style={styles.imageCalendar} />
                </TouchableOpacity>
              </View>
            </View>
            <Text style={[styles.nameText, styles.expiryDateText]}>
              {t("common:expiry_date")}
            </Text>
            <View style={[styles.expiryDateView, styles.nameInput]}>
              <Text style={styles.dateText}>1 Jun 2010</Text>
              <View style={styles.datePickerView}>
                <TouchableOpacity
                  onPress={() => {
                    DateTimePickerAndroid.open({
                      value: date,
                      onChange: OnDateChange,
                      mode: "date",
                      is24Hour: true,
                    });
                  }}
                  style={styles.dateButton}
                >
                  <Image source={Calendar} style={styles.imageCalendar} />
                </TouchableOpacity>
              </View>
            </View>

            <TextInput 
 textAlign={I18nManager.isRTL ? "right" :"left"} 
              placeholder="Description"
              style={[styles.input, styles.descriptionInput]}
            ></TextInput>

            <View style={styles.logoView}>
              <TouchableOpacity style={styles.addBtn}>
                <Image source={Add} style={styles.btnImage} />
              </TouchableOpacity>
            </View>
          </View>
        ) : null}
      </View>
    </SafeAreaView>
  );
};

export default CertificateOfOrigin;

const styles = StyleSheet.create({
  mainContainer: {
    backgroundColor: "#FFFFFF",
    marginTop: 42,
  },
  btn: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  certificateText: {
    width: 250,
    height: 26,
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 16,
    color: "#000000",
  },
  upArrowImage: {
    width: 24,
    height: 24,
  },
  infoView: {},
  nameText: {
    height: 28,
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 14,
    color: "#A9ABAE",
  },
  nameInput: {
    height: 40,
    borderBottomWidth: 1,
    borderColor: "#E7E8E9",
    color: "#101820",
  },
  idNumberText: {
    top: 20,
  },
  input: {
    borderBottomWidth: 1,
    borderColor: "#E7E8E9",
    height: 60,
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 14,
    color: "#101820",
  },
  id_numberInput: {
    top: 20,
  },
  datePickerView: {
    marginRight: 10,
  },
  dateIssueText: {
    top: 40,
  },
  dateButton: {
    top: 20,
  },
  dateIssueView: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    top: 50,
  },
  expiryDateText: {
    top: 70,
  },
  expiryDateView: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    top: 80,
  },
  imageCalendar: {
    width: 16.5,
    height: 18,
    resizeMode: "contain"
  },
  dateText: {
    left: 8,
  },
  descriptionInput: {
    top: 110,
  },
  logoView: {
    flexDirection: "row",
    flexWrap: "wrap",
    top: 130,
  },
  addBtn: {
    justifyContent: "center",
    alignItems: "center",
    bottom: 26.88,
    borderWidth: 1,
    borderRadius: 16,
    borderColor: "#DDDDDE",
    height: 90,
    width: 98,
  },
  btnImage: {
    width: 20,
    height: 20,
  },
});
