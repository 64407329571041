import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  Image,
  Dimensions,
  ScrollView,
  SafeAreaView,
  Modal
} from "react-native";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Edit from "../../assets/image/edit.png";
import Loading from "../Loading";
import { deleteFamilyMember } from "../../Redux/Models/User/MyFamily/DeleteMember/Actions";
import { useSelector, useDispatch } from "react-redux";
import {
  userFamilyList
} from "../../Redux/Models/User/MyFamily/MyFamilyList/Actions";
import { resetFamilyMember } from "../../Redux/Models/User/MyFamily/MyFamilyList/Actions";
import {
  changeFamilyMemberStatus
} from "../../Redux/Models/User/MyFamily/DeleteMember/Actions";
import Dialog from "react-native-dialog";
import CrossIcon from "react-native-vector-icons/AntDesign";
import { useFocusEffect } from "@react-navigation/native";
import { useCallback } from "react";
import { editFamilyMemberStatus } from "../../Redux/Models/User/MyFamily/EditFamilyMember/Actions";

const height = Dimensions.get("window").height;

const FamilyDetail = ({ navigation, route }) => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);

  const selectedFamilyMem = useSelector(
    (state) => state.myFamilyList.selectedFamilyMember
  );
console.log("selectedFamilyMem",selectedFamilyMem);
  const deleteMember = useSelector((state) => state.deleteFamilyMember);
  const userEditFamilyMember = useSelector((state) => state.editFamilyMember);
  const userMyFamilyList = useSelector((state) => state.myFamilyList);

  const dispatch = useDispatch();

  useFocusEffect(
    useCallback(() => {
      if (deleteMember.error) {
        alert(deleteMember.message)
        dispatch(changeFamilyMemberStatus())
      }
      if (deleteMember.success === true || userEditFamilyMember.success ===true) {
        dispatch(changeFamilyMemberStatus())
        dispatch(editFamilyMemberStatus())
        if(deleteMember.success === true ){
        dispatch(resetFamilyMember(undefined))
        }
        dispatch(userFamilyList());
      }
    }, [deleteMember,userEditFamilyMember]));

    useEffect(() => {
      if (userMyFamilyList) {
        console.log("adsasdasdasd");
        // dispatch(selectedFamilyMember(userMyFamilyList.data[0]))
      }
    }, [userMyFamilyList.data]);

console.log("userEditFamilyMember",userEditFamilyMember);

  const editFamily = () => {
    navigation.navigate("EditFamilyMember");
  };
  const deleteFamily = () => {
    let formData = new FormData()
    formData.append('phone_number', selectedFamilyMem.phone_number)
    dispatch(deleteFamilyMember(formData));
    setVisible(false)
  };

  return (
    <SafeAreaView style={styles.topContainer}>
      <Modal transparent={true} visible={visible}>
        <View style={styles.innerModal}>
          <View style={styles.modalContainer}>
            <View style={styles.crossContainer}>
              <TouchableOpacity
                style={styles.crossBtn}
                onPress={() => setVisible(false)}
              >
                <CrossIcon name="close" size={24} />
              </TouchableOpacity>
            </View>
            <Text style={styles.profileText}>{t("common:delete_account")}</Text>
            <Text style={styles.accountText}>
              {t("common:are_delete_this_account")}
            </Text>
            <View style={styles.btnContainer}>
              <TouchableOpacity
                onPress={() => setVisible(false)}
                style={styles.btnNo}
              >
                <Text style={styles.btnNoText}>{t("common:No")}</Text>
              </TouchableOpacity>
              <TouchableOpacity onPress={deleteFamily} style={styles.btnYes}>
                <Text style={styles.btnYesText}>{t("common:Yes")}</Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Modal>
      <View style={styles.personalView}>
        <Text style={styles.personalText}>
          {t("common:family_member_details")}
        </Text>
        <TouchableOpacity onPress={editFamily} style={styles.editBtn}>
          <Image source={Edit} style={styles.editImage} />
        </TouchableOpacity>
      </View>

      <View style={styles.listContainer}>
        <ScrollView style={{ flex: 1 }} showsHorizontalScrollIndicator={false}>
          <View style={styles.listView}>
            <View style={styles.container}>
              <Text style={styles.nameText}>{t("common:name")}</Text>
              <Text style={styles.cblRequestText}>
                {selectedFamilyMem?.name}
              </Text>
            </View>

            <View style={styles.container}>
              <Text style={styles.nameText}>{t("common:national_id")}</Text>
              <Text style={styles.cblRequestText}>
                {selectedFamilyMem?.national_number}
              </Text>
            </View>

            <View style={styles.container}>
              <Text style={styles.nameText}>{t("common:phone")}</Text>
              <Text style={styles.cblRequestText}>
                {selectedFamilyMem?.phone_number}
              </Text>
            </View>

            <View style={styles.container}>
              <Text style={styles.nameText}>{t("common:date_of_birth")}</Text>
              <Text style={styles.cblRequestText}>10 Feb 1998</Text>
            </View>

            <View style={styles.container}>
              <Text style={styles.nameText}>{t("common:relation")}</Text>
              <Text style={styles.cblRequestText}>
                {selectedFamilyMem?.rel_type}
              </Text>
            </View>

            <View style={styles.container}>
              <Text style={styles.nameText}>{t("common:email")}</Text>
              <Text style={styles.cblRequestText}>info@gmail.com</Text>
            </View>

            <View style={styles.deleteView}>
              <TouchableOpacity style={styles.deleteBtn} onPress={() => setVisible(true)}>
                <Text style={styles.deleteText}>
                  {t("common:delete_member")}
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </ScrollView>
      </View>
    </SafeAreaView>
  );
};

export default FamilyDetail;

const styles = StyleSheet.create({
  topContainer: {
    flex: 1,
    marginTop: 20,
    backgroundColor: "#FFFFFF",
    borderRadius: 16,
    padding: 20,
    marginBottom: 25,
  },
  innerModal: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#000000aa",
  },
  modalContainer: {
    backgroundColor: "#FFFFFF",
    borderRadius: 20,
    marginHorizontal: 24,
    justifyContent: 'center',
    alignSelf: "center",
  },
  crossContainer: {
    alignSelf: "flex-end",
  },
  crossBtn: {
    marginRight: 10,
    marginTop: 10
  },
  accountText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 14,
    color: "#000000",
    marginTop: 16,
    textAlign: "center",
    marginHorizontal: 10
  },
  profileText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 20,
    textAlign: "center",
    color: "#101820",
    marginHorizontal: 10
  },
  btnContainer: {
    marginTop: 20,
    marginBottom: 10,
    flexDirection: "row",
    justifyContent: "space-around",
  },
  btnNo: {
    borderRadius: 70,
    borderColor: "#FF4747",
    borderWidth: 1,
    height: 56,
    width: 130,
    justifyContent: "center",
  },
  btnNoText: {
    fontFamily: "Inter",
    fontSize: 15,
    fontWeight: "500",
    color: "#FF4747",
    textAlign: "center",
    alignItems: "center",
  },
  btnYes: {
    borderRadius: 70,
    backgroundColor: "#FF4747",
    height: 56,
    width: 130,
    justifyContent: "center",
  },
  btnYesText: {
    fontFamily: "Inter",
    fontSize: 15,
    fontWeight: "500",
    color: "#FFFFFF",
    textAlign: "center",
    alignItems: "center",
  },
  personalView: {
    marginTop: 32,
    marginHorizontal: 24,
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  personalText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 18,
    color: "#000000",
  },
  editImage: {
    height: 17,
    width: 51,
  },
  container: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 20,
    marginHorizontal: 24,
    justifyContent: "space-between",
  },
  listContainer: {
    marginTop: 24,
    flex: 1,
  },
  listView: {
    flex: 1,
  },
  nameText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 14,
    color: "#BDBDBD",
  },
  cblRequestText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 14,
    textAlign: "right",
  },
  deleteView: {
    alignItems: "flex-end",
    marginTop: 20,
    marginHorizontal: 24,

  },
  deleteBtn: {
    width: 195,
    borderWidth: 1,
    borderColor: "#FF4747",
    borderRadius: 50,
    padding: 14,
    alignItems: "center",
    justifyContent: "center"
  },
  deleteText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 18,
    textAlign: "right",
    color: "#FF4747",
  },
});
