import {
  POST_FILE_UPLOAD_REQUEST,
  POST_FILE_UPLOAD_REQUEST_SUCCESS,
  POST_FILE_UPLOAD_REQUEST_FAILURE,
  RESET_FILE_UPLOAD_STATE,
  SET_EDIT_FROM_FILES,
  DETELE_FROM_FILE_UPLOADED,
  CLEAR_FILES_ON_KYC_REQUEST_SUCCESS
} from "./Actions";

const initialState = {
  loading: null,
  message: null,
  error: null,
  data: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case POST_FILE_UPLOAD_REQUEST: {
      console.log(POST_FILE_UPLOAD_REQUEST);
      return {
        ...state,
        error: false,
        success: false,
        loading: true,
      };
    }
    case POST_FILE_UPLOAD_REQUEST_SUCCESS: {
      const { message, data } = action.payload;
      state.data[action.index] === undefined
        ? (state.data[action.index] = [data])
        : (state.data[action.index] = [...state.data[action.index], data]);
      console.log(POST_FILE_UPLOAD_REQUEST_SUCCESS);
      return {
        ...state,
        loading: false,
        success: true,
        data: state.data,
        message,
      };
    }
    case POST_FILE_UPLOAD_REQUEST_FAILURE: {
      console.log(POST_FILE_UPLOAD_REQUEST_FAILURE);
      const { message } = action.payload;
      return {
        ...state,
        loading: false,
        success: false,
        error: true,
        message,
      };
    }
    case RESET_FILE_UPLOAD_STATE: {
      console.log(RESET_FILE_UPLOAD_STATE);
      return {
        ...state,
        loading: false,
        success: false,
        error: false,
      };
    }
    case DETELE_FROM_FILE_UPLOADED: {
      console.log(DETELE_FROM_FILE_UPLOADED);
       state.data[action.doc].splice(action.index,1)
      return {
        ...state,
        loading: false,
        success: false,
        data:state.data,
        error: false,
      };
    }
    case SET_EDIT_FROM_FILES: {
      console.log(SET_EDIT_FROM_FILES);
       state.data[action.doc] = action.data
      return {
        ...state,
        loading: false,
        success: false,
        data:state.data,
        error: false,
      };
    }
    case CLEAR_FILES_ON_KYC_REQUEST_SUCCESS: {
      console.log(CLEAR_FILES_ON_KYC_REQUEST_SUCCESS);
      return {
        ...state,
        data:{}
      }
    }
    default:
      return state;
  }
};

export { reducer };
