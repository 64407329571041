import {
  StyleSheet,
  Text,
  View,
  Dimensions,
  ScrollView,
  TouchableOpacity,
  SafeAreaView,
} from "react-native";
import React from "react";
import Icon from "react-native-vector-icons/Feather";
import { useTranslation } from "react-i18next";
import CertificateOfOrigin from "./Components/CertificateOfOrigin";
const height = Dimensions.get("window").height;

const LetterCreditStep5 = ({ navigation }) => {
  const { t } = useTranslation();

  return (
    <SafeAreaView style={styles.topContainer}>
      <View style={styles.headerView}>
        <Icon
          name="arrow-left"
          style={styles.arrowIcon}
          onPress={() => navigation.goBack()}
        />
        <Text style={styles.cardText}>{t("common:letter_of_credit")}</Text>
      </View>
      <View style={styles.stepView}>
        <View style={styles.step1}></View>
        <View style={styles.step2}></View>
        <View style={styles.step3}></View>
        <View style={styles.step4}></View>
        <View style={styles.step5}></View>
      </View>
      <ScrollView contentContainerStyle={styles.scrollView}>
        <View style={styles.innerContainer}>
          <Text style={styles.supportingText}>
            {t("common:supporting_documents")}
          </Text>

          <CertificateOfOrigin />
          <View style={styles.btnView}>
            <TouchableOpacity
              onPress={() => navigation.navigate("RequestTopUp")}
              style={styles.button}
            >
              <Text style={styles.buttonText}>{t("common:SUBMIT")}</Text>
            </TouchableOpacity>
          </View>
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

export default LetterCreditStep5;

const styles = StyleSheet.create({
  topContainer: {
    height: height,
    backgroundColor: "#FFFFFF",
  },
  headerView: {
    flexDirection: "row",
    marginTop: 44,
    alignItems: "center",
    backgroundColor: "#FFFFFF",
  },
  arrowIcon: {
    fontSize: 25,
    color: "#101820",
    marginLeft: 24,
  },
  cardText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 22,
    color: "#000000",
    left: 90,
  },
  stepView: {
    flexDirection: "row",
    height: 6,
    marginTop: 40,
    marginBottom: 25,
    marginLeft: 24,
    marginRight: 24,
    justifyContent: "space-between",
  },
  step1: {
    width: 59,
    height: 6,
    backgroundColor: "#FF4747",
    borderRadius: 17,
  },
  step2: {
    width: 59,
    height: 6,
    backgroundColor: "#FF4747",
    borderRadius: 17,
  },
  step3: {
    width: 59,
    height: 6,
    backgroundColor: "#FF4747",
    borderRadius: 17,
  },
  step4: {
    width: 59,
    height: 6,
    backgroundColor: "#FF4747",
    borderRadius: 17,
  },
  step5: {
    width: 59,
    height: 6,
    backgroundColor: "#FF4747",
    borderRadius: 17,
  },
  scrollView: {
    height:height
  },
  innerContainer: {
    flex: 1,
    marginLeft: 24,
    marginRight: 24,
  },
  supportingText: {
    top: 20,
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 18,
    color: "#000000",
  },
  btnView: {
    flex: 1,
    marginBottom: 51,
    justifyContent: "flex-end",
  },
  button: {
    padding: 16,
    backgroundColor: "#FF4747",
    borderRadius: 70,
  },
  buttonText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 16,
    alignItems: "center",
    textAlign: "center",
    color: "#FFFFFF",
  },
});
