import { StyleSheet, Text, View, SafeAreaView, TextInput, I18nManager, ScrollView, TouchableOpacity, useWindowDimensions, Platform } from 'react-native'
import Icon from "react-native-vector-icons/Feather";
import { useTranslation } from "react-i18next";
import { useState, useRef } from 'react';
import { changePinCode, resetState } from "../../Redux/Models/User/ChangePinCode/Action";
import { useDispatch, useSelector } from "react-redux";
import ToastAndroid from "react-native-root-toast";
import Loading from '../Loading';
import SideBar from "./Components/SideBar";

const ChangePinCode = ({ navigation, route }) => {
    const { t } = useTranslation();
    const { width } = useWindowDimensions();
    const dispatch = useDispatch();
    const changePin = useSelector((state) => state.changePinCode);
    const [newSecurityPin, setNewSecurityPin] = useState("");
    const [confirmSecurityPin, setConfirmSecurityPin] = useState("");
    const [otp, setOtp] = useState("");
    const addToForm = new FormData();

    const confirmRef = useRef();
    const otpRef = useRef();
    const Validate = () => {
        if (newSecurityPin.length < 4) {
            ToastAndroid.show(
                t("common:please_enter_four_digit"),
                ToastAndroid.LONG
            );
            return false;
        } else if (confirmSecurityPin.length < 4) {
            ToastAndroid.show(
                t("common:please_enter_four_digit"),
                ToastAndroid.LONG
            );
            return false;
        } else if (newSecurityPin !== confirmSecurityPin) {
            ToastAndroid.show(
                t("common:Please_enter_match_password"),
                ToastAndroid.LONG
            );
            return false;
        } else if (otp !== "1234") {
            ToastAndroid.show(
                t("common:Please_enter_otp"),
                ToastAndroid.LONG
            );
            return false;
        }
        return true;
    };
    const changePinApi = () => {
        if (!Validate()) {
            return;
        }
        addToForm.append("pass_code", newSecurityPin);
        dispatch(changePinCode(addToForm));
        navigation.navigate("Account");
    };

    return (
        <SideBar route={route} navigation={navigation}>
            <ScrollView contentContainerStyle={{ flexGrow: 1 }} showsHorizontalScrollIndicator={false}>

                <View style={styles.mainContainer}>
                    <View style={styles.headerView}>
                        <Text style={styles.updateText}>{t("common:update_security_pin")}</Text>
                        <View style={styles.inputContainer}>

                            <View style={styles.innerContainer}>
                                <View style={styles.inputs}>
                                    <TextInput
                                        placeholder={t("common:new_security_pin")}
                                        textAlign={I18nManager.isRTL ? "right" : "left"}
                                        style={styles.input}
                                        onSubmitEditing={() => confirmRef.current.focus()}
                                        keyboardType="number-pad"
                                        returnKeyType="done"
                                        maxLength={4}
                                        value={newSecurityPin}
                                        onChangeText={(value) => setNewSecurityPin(value)}
                                    ></TextInput>
                                </View>
                                <View style={[styles.inputs, width > 560 ? { marginLeft: 20 }:{marginLeft:0}]}>
                                    <TextInput
                                        placeholder={t("common:confirm_your_pin")}
                                        textAlign={I18nManager.isRTL ? "right" : "left"}
                                        style={styles.input}
                                        ref={confirmRef}
                                        onSubmitEditing={() => otpRef.current.focus()}
                                        keyboardType="number-pad"
                                        returnKeyType="done"
                                        maxLength={4}
                                        value={confirmSecurityPin}
                                        onChangeText={(value) => setConfirmSecurityPin(value)}
                                    ></TextInput>
                                </View>
                            </View>

                            <View style={{ width: 495 }}>
                                <TextInput
                                    placeholder={t("common:OTP")}
                                    textAlign={I18nManager.isRTL ? "right" : "left"}
                                    style={styles.input}
                                    keyboardType="number-pad"
                                    returnKeyType="done"
                                    value={otp}
                                    ref={otpRef}
                                    onChangeText={(value) => setOtp(value)}
                                ></TextInput>
                                <Text style={styles.checkText}>{t("common:CheckOTP")}</Text>
                            </View>
                        </View>
                        <View style={styles.btnView}>
                            <TouchableOpacity onPress={changePinApi} style={styles.btn}>
                                <Text style={styles.btnText}>{t("common:Continue")}</Text>
                            </TouchableOpacity>
                        </View>
                    </View>
                </View>
            </ScrollView>

            {changePin.loading && <Loading></Loading>}

        </SideBar>
    )
}

export default ChangePinCode

const styles = StyleSheet.create({
    mainContainer: {
        backgroundColor: "#FFF",
        paddingVertical: 30,
        paddingHorizontal: 20,
        borderRadius: 16,
    },
    headerView: {
        flex: 1,
        marginHorizontal: 24
    },
    arrowIcon: {
        marginTop: 40,
        alignSelf: "flex-start"
    },
    updateText: {
        fontFamily: "Inter",
        fofontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: 18,
        color: "#000000",
    },
    inputContainer: {
    },
    innerContainer: {
        flexDirection: "row",
        flex: 1,
        flexWrap:"wrap"
    },
    inputs: {
        flex: 1,
        maxWidth:327,
        minWidth:120
    },
    input: {
        height: 46,
        color: "#101820",
        fontWeight: "500",
        marginTop: 55,
        borderBottomWidth: 1,
        borderBottomWidth: 1,
        borderColor: "#E7E8E9",
        outlineStyle: "none"
    },
    checkText: {
        marginTop: 5,
        fontFamily: "Inter",
        fontSize: 14,
        fontWeight: "400",
        fontStyle: "normal",
        color: "#FF4747",
        alignSelf: "flex-start"
    },
    btnView: {
        flex: 1,
        flexDirection:"row",
        marginTop: 120,
        alignItems: "flex-end",
        justifyContent: "flex-end"
    },
    btn: {
        padding: 16,
        borderRadius: 50,
        backgroundColor: "#FF4747",
        maxWidth: 327,
        flex:1,
        minWidth:120,
    },
    btnText: {
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: "700",
        fontSize: 16,
        alignItems: "center",
        textAlign: "center",
        color: "#FFFFFF",
    },
})