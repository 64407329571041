const GET_USER_DETAILS_REQUEST = "GET_USER_DETAILS_REQUEST";
const GET_USER_DETAILS_REQUEST_SUCCESS = "GET_USER_DETAILS_REQUEST_SUCCESS";
const GET_USER_DETAILS_REQUEST_FAILURE = "GET_USER_DETAILS_REQUEST_FAILURE";

const GET_USER_TRANSACTIONS_REQUEST = "GET_USER_TRANSACTIONS_REQUEST";
const GET_USER_TRANSACTIONS_REQUEST_SUCCESS = "GET_USER_TRANSACTIONS_REQUEST_SUCCESS";
const GET_USER_TRANSACTIONS_REQUEST_FAILURE = "GET_USER_TRANSACTIONS_REQUEST_FAILURE";


const getUserDetails = () => {
  return {
    type: GET_USER_DETAILS_REQUEST,
  }
}

const getUserTransactions = (params) => {
  return {
    type: GET_USER_TRANSACTIONS_REQUEST,
    params
  }
}
export {
  getUserTransactions,
  getUserDetails,
  GET_USER_DETAILS_REQUEST,
  GET_USER_DETAILS_REQUEST_SUCCESS,
  GET_USER_DETAILS_REQUEST_FAILURE,
  GET_USER_TRANSACTIONS_REQUEST,
  GET_USER_TRANSACTIONS_REQUEST_SUCCESS,
  GET_USER_TRANSACTIONS_REQUEST_FAILURE


};
