import React from "react";
import { createMaterialTopTabNavigator } from "@react-navigation/material-top-tabs";
import Vouchers from "./Vouchers";
import PurchasedVouchers from "./PurchasedVouchers";
import { useTranslation } from "react-i18next";
import { Platform } from "react-native";
const Tab = createMaterialTopTabNavigator();

const TopTabService = () => {
  const { t } = useTranslation();

  return (
    <Tab.Navigator
    screenOptions={{
      tabBarLabelStyle: {
        textTransform: "none",
        fontFamily: 'Inter',
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: 14,
      },
      tabBarActiveTintColor: 
      Platform.OS === "web" ?
          '#FF4747' : "#000000",
      tabBarInactiveTintColor:"#000000",
      tabBarStyle: 
      Platform.OS === "web" ? 
      {
        backgroundColor: "#F2F2F2",
        elevation: 0,
        shadowOpacity: 0, 
        width:350
      }: {
        backgroundColor: "#F2F2F2",
        elevation: 0,
        shadowOpacity: 0,
      },
      swipeEnabled: false,
    }}
    >
      <Tab.Screen
        options={{
          tabBarIndicatorStyle:
            Platform.OS === "web" ? {
              backgroundColor: "#FF4747",
              height: 2
            } : {
              backgroundColor: "#FF4747",
              height: 2,
              width: "18%",
              left: "16%"
            },
        }}
        name={t("common:Vouchers")}
        component={Vouchers}
      />
      <Tab.Screen
        options={{
          tabBarIndicatorStyle:
            Platform.OS === "web" ? {
              backgroundColor: "#FF4747",
              height: 2
            } : {
              backgroundColor: "#FF4747",
              height: 2,
              width: "40%",
              left: "5%"
            },
        }}
        name={t("common:purchased_vouchers")}
        component={PurchasedVouchers}
      />
    </Tab.Navigator>
  );
};

export default TopTabService;
