const GET_CBL_VERIFY_DOCUMENT_REQUEST = "GET_CBL_VERIFY_DOCUMENT_REQUEST";
const GET_CBL_VERIFY_DOCUMENT_REQUEST_SUCCESS = "GET_CBL_VERIFY_DOCUMENT_REQUEST_SUCCESS";
const GET_CBL_VERIFY_DOCUMENT_REQUEST_FAILURE = "GET_CBL_VERIFY_DOCUMENT_REQUEST_FAILURE";

const cblVerifyDocument = (formData) => {
  return {
    type: GET_CBL_VERIFY_DOCUMENT_REQUEST,
    formData,
  };
};

export {
  cblVerifyDocument,
  GET_CBL_VERIFY_DOCUMENT_REQUEST,
  GET_CBL_VERIFY_DOCUMENT_REQUEST_SUCCESS,
  GET_CBL_VERIFY_DOCUMENT_REQUEST_FAILURE,
};
