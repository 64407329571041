import { takeEvery, put, call } from "redux-saga/effects";
import BASEURL from "../../URLs";
import { queryApi } from "../../QueryApi";
import {
  POST_USER_VERIFY_PASS_REQUEST,
  POST_USER_VERIFY_PASS_SUCCESS,
  POST_USER_VERIFY_PASS_FAILURE,
} from "./Actions";
//import {queryApi} from '../query-api';

function* handler() {
  yield takeEvery(POST_USER_VERIFY_PASS_REQUEST, verifyUserPassCode);
}

function* verifyUserPassCode(action) {
  try {
    // API call
    console.log(action.formData);
    const apiResponse = yield call(queryApi, {
      endpoint: BASEURL.verifyUserPassCode,
      method: "POST",
      body: action.formData,
    });
    if (!("error" in apiResponse)) {
      yield put({
        type: POST_USER_VERIFY_PASS_SUCCESS,
        payload: apiResponse,
      });
    } else {
      yield put({
        type: POST_USER_VERIFY_PASS_FAILURE,
        payload: apiResponse.error,
      });
    }
  } catch (err) {
    console.log(err);
    // Handle error
    yield put({
      type: POST_USER_VERIFY_PASS_FAILURE,
      payload: { message: err },
    });
  }
}

export { handler };
