import {
  StyleSheet,
  Text,
  View,
  Image,
  Dimensions,
  ScrollView,
  FlatList,
  TouchableOpacity,

} from "react-native";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import noPhoto from "../../assets/image/no-photo.jpg";
import Amazon from "../../assets/image/amazon.png";
import { useDispatch, useSelector } from "react-redux";
import { giftCardCompanyList } from "../../Redux/Models/GiftCardCompanyList/Actions";
const height = Dimensions.get("window").height;
import Loading from "../Loading";

const GiftCards = ({ navigation }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const giftCardsList = useSelector((state) => state.giftCardCompanyList);
  const [filterData, setFilterData] = useState([])

  useEffect(() => {
    dispatch(giftCardCompanyList());
  }, []);
  useEffect(() => {
    let data = giftCardsList.data.filter(val => val.title.toLowerCase().indexOf(giftCardsList.search?.toLowerCase()) > -1)
    let temArray = []
    data.map((giftCard, index) => {
      giftCard.gift_cards.map((giftCardChiled, ind) => {
        let value = {}
        value.title = giftCard.title
        value.logo_color = giftCard.logo_color
        value.visible = giftCard.visible
        value.amount = giftCardChiled.amount
        value.package_name = giftCardChiled.package_name
        value.lyd_price = giftCardChiled.lyd_price
        value.giftCardChiled = giftCardChiled
        value.id = giftCard.id.toString() + ind.toString()
        temArray = [...temArray, value]

      })
    })
    setFilterData(temArray)
  }, [giftCardsList.search]);

  useEffect(() => {
    let temArray = []
    giftCardsList.data.map((giftCard, index) => {
      giftCard.gift_cards.map((giftCardChiled, ind) => {
        let value = {}
        value.title = giftCard.title
        value.logo_color = giftCard.logo_color
        value.visible = giftCard.visible
        value.amount = giftCardChiled.amount
        value.package_name = giftCardChiled.package_name
        value.lyd_price = giftCardChiled.lyd_price
        value.giftCardChiled = giftCardChiled
        value.id = giftCard.id.toString() + ind.toString()
        temArray = [...temArray, value]

      })
    })
    setFilterData(temArray);
  }, [giftCardsList.data]);
  return (
    <View style={styles.innerContainer}>
      <FlatList
        data={filterData}
        renderItem={({ item }) =>
          item.visible === 1 &&
          <TouchableOpacity
            onPress={() =>
              navigation.navigate("CompanyGiftCardDetail", {
                item, giftCard: item.giftCardChiled 
              })
            }
            style={styles.topContainer1}
          >
            <View style={styles.container}>
              <Image
                source={item.logo_color === "" ? noPhoto : { uri: item.logo_color }}
                style={styles.vodafoneImage}
              />
              <View style={styles.giftView}>
                <Text style={styles.giftCardText}>
                  {t("common:gift_card")}
                </Text>
                <Text style={styles.vodafoneText}>{item.title}</Text>
                <Text style={styles.vodafoneText}>
                  {item.package_name !== "" && item.package_name}
                </Text>

                {/*  <Text style={styles.dateText}>Till 23 Jun 2021</Text>*/}
              </View>
              <View
                style={{justifyContent: "center",alignItems: "flex-end",}}>
                <Text style={styles.priceText}>{item.amount} $</Text>
                <Text style={styles.priceText2}>
                  {"("} {item.lyd_price} LYD {")"}
                </Text>
              </View>
            </View>
          </TouchableOpacity>

        }
        keyExtractor={(item, ind) => item.id + ind}
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={{ flexDirection: "row", flexWrap: "wrap" }}
      />
      {giftCardsList.loading && <Loading></Loading>}
    </View>
  );
};

export default GiftCards;

const styles = StyleSheet.create({

  innerContainer: {
    flex: 1,
    marginTop: 20,
    backgroundColor: "#FFFFFF",
    borderRadius: 16,
    padding: 20,
    marginBottom: 25,
  },
  topContainer1: {
    width: 430,
    height:100,
    backgroundColor: "#F9F9F9",
    borderRadius: 10,
    margin: 10,
    paddingVertical: 14,
    paddingHorizontal:16,
    justifyContent:"center"
  },
  container: {
    flexDirection: "row",
    alignItems: "center",
  },
  vodafoneImage: {
    borderRadius: 56,
    width: 56,
    height: 56,
    resizeMode:"contain"
  },
  giftView: {
    marginHorizontal: 12,
    flex:1,
    justifyContent:"center",
  },
  giftCardText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 12,
    alignSelf: "flex-start",
    letterSpacing: 0.111,
    color: "#828282",
  },
  vodafoneText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 16,
    alignSelf: "flex-start",
    letterSpacing: 0.111,
    color: "#282828",
  },
  dateText: {
    height: 20,
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 12,

    letterSpacing: 0.111,
    color: "#BDBDBD",
  },
  priceText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 14,
    textAlign: "right",
    letterSpacing: 0.111,
    color: "#FF4747",
    alignItems: "center",
  },
  priceText2: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 12,
    textAlign: "right",
    letterSpacing: 0.111,
    color: "#FF4747",
    alignItems: "center",
  },
});
