import {
  StyleSheet,
  Text,
  View,
  Image,
  TouchableOpacity,
  TextInput,
  I18nManager,
  Dimensions,
  SafeAreaView,
  Modal,
  Pressable,
  Platform,
} from "react-native";
import React, { useState, useEffect, useRef } from "react";
import UpArrow from "../../../assets/image/UpArrow.png";
import { Picker } from "@react-native-picker/picker";
import { useTranslation } from "react-i18next";
import Calendar from "../../../assets/image/calendar.png";
import Add from "../../../assets/image/add.png";
import { useDispatch, useSelector } from "react-redux";
import {
  uploadFile,
  resetUploadFilestate,
  deleteUploadedFile,
} from "../../../Redux/Models/DocumentUpload/Actions";
import { deleteFile } from "../../../Redux/Models/DeleteDocument/Actions";
import Dialog from "react-native-dialog";
import { launchCamera, launchImageLibrary } from "react-native-image-picker";
import Icon from "react-native-vector-icons/Entypo";
import DownArrow from "../../../assets/image/downArrow.png";
import CrossIcon from "react-native-vector-icons/Entypo";
import { DateFormate } from "../../../Utils/HelperFunctions";
const height = Dimensions.get("window").height;
import DateTimePickerModal from "react-native-modal-datetime-picker";
import WebDatePicker from "../../../Components/DatePickerTest";

const DocumentForm = ({ title, doc, setAllDocs }) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(true);
  const [placeOfIssue, setPlaceOfIssue] = useState("");
  const [idNumber, setIdNumber] = useState("");
  const [issueDate, setIssueDate] = useState();
  const [expiryDate, setExpiryDate] = useState();
  const [visible, setVisible] = useState(false);
  const [uploadedFile, setUploadedFile] = useState([]);
  const documentUpload = useSelector((state) => state.DocumentUpload);
  const dispatch = useDispatch();
  const [isDatePickerVisible, setDatePickerVisibility] = useState(false);
  const [isExpiryDatePickerVisible, setExpiryDatePickerVisibility] =
    useState(false);
  const [showImage, setShowImage] = useState(true);
  const [showExpiry, setShowExpiry] = useState(true);

  const showDatePicker = () => {
    setDatePickerVisibility(true);
    if (Platform.OS === "web") {
      setShowImage(false);
    }
  };
  const showExpiryDatePicker = () => {
    setExpiryDatePickerVisibility(true);
    if (Platform.OS === "web") {
      setShowExpiry(false);
    }
  };
  const dateIssuRef = useRef();
  const dateExpiryRef = useRef();

  const handleClickOutside = (event) => {
    let date = dateIssuRef?.current?.contains(event.target);
    if (date === false && Platform.OS === "web") {
      setDatePickerVisibility(false);
      setShowImage(true);
    }
  };
  const handleExpiryClickOutside = (event) => {
    let dateExpiry = dateExpiryRef?.current?.contains(event.target);
    if (dateExpiry === false && Platform.OS === "web") {
      setExpiryDatePickerVisibility(false);
      setShowExpiry(true);
    }
  };
  React.useEffect(() => {
    if (Platform.OS === "web") {
      document.addEventListener("mousedown", handleClickOutside);
      document.addEventListener("mousedown", handleExpiryClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
        document.removeEventListener("mousedown", handleExpiryClickOutside);
      };
    }
  }, []);
  const hideDatePicker = () => {
    setDatePickerVisibility(false);
    setExpiryDatePickerVisibility(false);
  };

  const OnDateChange = (selectedDate) => {
    const currentDate = selectedDate;
    setIssueDate(currentDate);
    setDatePickerVisibility(false);
    if (Platform.OS === "web") {
      setShowImage(true);
    }
  };
  const OnExpiryDateChange = (selectedDate) => {
    const currentDate = selectedDate;
    setExpiryDate(currentDate);
    setExpiryDatePickerVisibility(false);
    if (Platform.OS === "web") {
      setShowExpiry(true);
    }
  };

  const takePhoto = () => {
    let formData = new FormData();
    setVisible(false);

    launchCamera({
      cameraType: "back",
      mediaType: "photo",
    })
      .then((val) => {
        formData.append("file", {
          type: val.assets[0].type,
          uri: val.assets[0].uri,
          name: val.assets[0].fileName,
        });
        dispatch(uploadFile(formData, doc));
      })
      .catch((err) => console.log(err));
  };
  const choosePhoto = () => {
    setVisible(false);
    launchImageLibrary({ mediaType: "photo" })
      .then(async (val) => {
        let formData = new FormData();
        let res = await fetch(val.assets[0].uri)
        let buffar = await res.arrayBuffer()
        let file = new File([buffar], val.assets[0].fileName, { type: val.assets[0].type })
        formData.append("file", file);
        dispatch(uploadFile(formData, doc));
      })
      .catch((err) => console.log(err));
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const uploadDoc = () => {
    setVisible(true);
  };
  const deleteFileApi = (delDoc, ind) => {
    let params = new URLSearchParams({
      system_name: delDoc,
    });
    dispatch(deleteFile(params));
    dispatch(deleteUploadedFile(doc, ind));
  };

  useEffect(() => {
    setAllDocs((prev) => {
      prev[doc] = {
        verify_doc_type_id: title.id,
        doc_reference: title.id,
        id_number: idNumber,
        place_issue: placeOfIssue,
        date_issue: issueDate && Math.floor(issueDate.getTime()),
        expiry_date: expiryDate && Math.floor(expiryDate.getTime()),
        doc_files: documentUpload.data[doc],
      };
      return { ...prev };
    });
  }, [idNumber, placeOfIssue, issueDate, expiryDate, documentUpload.data[doc]]);
  return (
    <SafeAreaView style={styles.container}>
      <Modal transparent={true} visible={visible}>
        <Pressable onPress={handleCancel} style={styles.innerDialog}>
          <View style={styles.dialogContainer}>
            <View style={styles.textContainer}>
              <Text style={styles.source}>{t("common:select_source")}</Text>
              {/* <TouchableOpacity onPress={takePhoto}>
                <Text style={styles.dialogText}>
                  {t("common:take_a_photo")}
                </Text>
              </TouchableOpacity> */}
              <TouchableOpacity onPress={choosePhoto}>
                <Text style={styles.dialogText}>
                  {t("common:choose_a_photo_from_the_gallery")}
                </Text>
              </TouchableOpacity>
              <TouchableOpacity onPress={handleCancel}>
                <Text style={styles.cancelText}>{t("common:cancel")}</Text>
              </TouchableOpacity>
            </View>
          </View>
        </Pressable>
      </Modal>
      <View style={styles.btnView}>
        <TouchableOpacity style={styles.btn} onPress={() => setShow(!show)}>
          <Text style={styles.nationalText}>{title.type}</Text>
          <Image
            source={show ? UpArrow : DownArrow}
            style={styles.upArrowImage}
          />
        </TouchableOpacity>
      </View>
      {show && (
        <View>
          <View style={styles.inputContainer}>
            <View style={styles.inputView}>
              <Text style={styles.nameText}>
                {t("common:id_number_st")}
                <Text style={{ color: "#FF4747" }}>*</Text>
              </Text>
              <TextInput
                textAlign={I18nManager.isRTL ? "right" : "left"}
                value={idNumber}
                keyboardType="number-pad"
                returnKeyType="done"
                onChangeText={(number) => setIdNumber(number)}
                style={styles.input}
              ></TextInput>
            </View>
            <View style={[styles.inputView, { marginLeft: 20 }]}>
              <Text style={[styles.issueText, styles.nameText]}>
                {t("common:place_of_issue")}
              </Text>
              <TextInput
                textAlign={I18nManager.isRTL ? "right" : "left"}
                value={placeOfIssue}
                onChangeText={(text) => setPlaceOfIssue(text)}
                style={styles.input}
                returnKeyType="done"
              ></TextInput>
            </View>
          </View>

          <View style={[styles.inputContainer, { zIndex: 2000 }]}>
            <View style={styles.inputView}>
              <Text style={styles.nameText}>
                {t("common:date_of_issue_st")}
                <Text style={{ color: "#FF4747" }}>*</Text>
              </Text>
              <View style={styles.datePickerView}>
                <Text style={styles.dateText}>
                  {issueDate && DateFormate(issueDate)}
                </Text>
                <TouchableOpacity onPress={showDatePicker}>
                  {showImage && (
                    <Image source={Calendar} style={styles.imageCalendar} />
                  )}
                </TouchableOpacity>
                <View
                  style={
                    isDatePickerVisible
                      ? { display: "flex" }
                      : { display: "none" }
                  }
                  ref={dateIssuRef}
                >
                  {Platform.OS === "web" && (
                    <WebDatePicker
                      isDatePickerVisible={isDatePickerVisible}
                      date={issueDate}
                      OnDateChange={OnDateChange}
                    />
                  )}
                </View>
              </View>
            </View>
            <View style={[styles.inputView, { marginLeft: 20 }]}>
              <Text style={[styles.issueText, styles.nameText]}>
                {t("common:expiry_date_st")}
              </Text>
              <View style={styles.datePickerView}>
                <Text style={styles.dateText}>
                  {expiryDate && DateFormate(expiryDate)}
                </Text>
                <TouchableOpacity onPress={showExpiryDatePicker}>
                  {showExpiry && (
                    <Image source={Calendar} style={styles.imageCalendar} />
                  )}
                </TouchableOpacity>
                <View
                  style={
                    isExpiryDatePickerVisible
                      ? { display: "flex" }
                      : { display: "none" }
                  }
                  ref={dateExpiryRef}
                >
                  {Platform.OS === "web" && (
                    <WebDatePicker
                      isDatePickerVisible={isExpiryDatePickerVisible}
                      date={expiryDate}
                      OnDateChange={OnExpiryDateChange}
                    />
                  )}
                </View>
              </View>
            </View>
          </View>
          <View style={styles.logoView}>
            {documentUpload.data[doc] !== undefined &&
              documentUpload.data[doc].map((val, ind) => {
                return (
                  <View key={ind} style={styles.addView}>
                    <Image
                      source={{ uri: val.storage_path }}
                      style={styles.image}
                    />
                    <View style={styles.crossView}>
                      <TouchableOpacity
                        onPress={() => deleteFileApi(val.system_name, ind)}
                        style={styles.crossBtn}
                      >
                        <CrossIcon name="cross" color="white" />
                      </TouchableOpacity>
                    </View>
                  </View>
                );
              })}
            <TouchableOpacity onPress={uploadDoc} style={styles.addBtn}>
              <Image source={Add} style={styles.btnImage} />
            </TouchableOpacity>
          </View>
        </View>
      )}
    </SafeAreaView>
  );
};

export default DocumentForm;

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#FFFFFF",
    marginTop: 32,
  },
  innerDialog: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#000000aa",
  },
  dialogContainer: {
    backgroundColor: "#FFFFFF",
    borderRadius: 5,
    marginHorizontal: 24,
    justifyContent: "center",
    alignSelf: "center",
  },
  textContainer: {
    marginHorizontal: 12,
    padding: 10,
    margin: 10,
  },
  source: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 18,
    color: "#101820",
    alignSelf: "flex-start",
  },
  dialogText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 16,
    color: "#101820",
    marginTop: 6,
    alignSelf: "flex-start",
  },
  cancelText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 16,
    color: "#101820",
    marginTop: 20,
    textAlign: "right",
    alignSelf: "flex-end",
  },
  btn: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  nationalText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 16,
    color: "#000000",
  },
  upArrowImage: {
    width: 24,
    height: 24,
  },
  inputContainer: {
    flex: 1,
    flexDirection: "row",
  },
  inputView: {
    flex: 1,
    maxWidth: 327,
  },
  nameText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 14,
    color: "#A9ABAE",
    alignSelf: "flex-start",
    marginTop: 10,
  },
  input: {
    borderBottomWidth: 1,
    borderColor: "#E7E8E9",
    height: 56,
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 14,
    color: "#101820",
    outlineStyle: "none",
  },
  datePickerView: {
    borderBottomWidth: 1,
    borderColor: "#F1F1F1",
    height: 56,
    flexDirection: "row",
    justifyContent: "space-between",
    zIndex: 2000,
  },
  imageCalendar: {
    width: 16.5,
    height: 18,
    marginRight: 15,
    marginTop: 20,
    resizeMode: "contain"
  },
  dateText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 14,
    marginTop: 20,
    color: "#101820",
  },
  logoView: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 10,
  },
  addBtn: {
    justifyContent: "center",
    alignItems: "center",
    borderWidth: 1,
    borderRadius: 18,
    borderColor: "#DDDDDE",
    height: 157,
    width: 170,
    marginRight: 14,
    marginBottom: 10,
  },
  addView: {
    borderWidth: 1,
    borderRadius: 18,
    borderColor: "#DDDDDE",
    height: 157,
    width: 170,
    marginRight: 14,
    marginBottom: 10,
    flexDirection: "row",
  },
  crossView: {
    flex: 1,
    alignItems: "flex-end",
  },
  crossBtn: {
    zIndex: 1,
    width: 25,
    height: 25,
    marginTop: 5,
    marginRight: 5,
    borderRadius: 20,
    backgroundColor: "#FF4747",
    alignItems: "center",
    justifyContent: "center",
  },
  image: {
    borderWidth: 1,
    borderRadius: 18,
    borderColor: "#DDDDDE",
    height: 157,
    width: 170,
    zIndex: 0,
  },
  btnImage: {
    width: 41,
    height: 41,
  },
});
