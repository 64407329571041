const POST_EDIT_FAMILY_MEMBER_REQUEST = "POST_EDIT_FAMILY_MEMBER_REQUEST";
const POST_EDIT_FAMILY_MEMBER_REQUEST_SUCCESS = "POST_EDIT_FAMILY_MEMBER_REQUEST_SUCCESS";
const POST_EDIT_FAMILY_MEMBER_REQUEST_FAILURE = "POST_EDIT_FAMILY_MEMBER_REQUEST_FAILURE";
const EDIT_FAMILY_MEMBER_STATUS_REST = "EDIT_FAMILY_MEMBER_STATUS_REST";


const editFamilyMember = (formData) => {
  return {
    type: POST_EDIT_FAMILY_MEMBER_REQUEST,
    formData,
  };
};
const editFamilyMemberStatus = () => {
  return {
    type: EDIT_FAMILY_MEMBER_STATUS_REST,
  };
};

export {
  editFamilyMember,
  editFamilyMemberStatus,
  POST_EDIT_FAMILY_MEMBER_REQUEST,
  POST_EDIT_FAMILY_MEMBER_REQUEST_SUCCESS,
  POST_EDIT_FAMILY_MEMBER_REQUEST_FAILURE,
  EDIT_FAMILY_MEMBER_STATUS_REST
};
