import {
  StyleSheet,
  View,
} from "react-native";
import React from "react";
import TopTabMoney from "./TopTabMoney";
import SideBarConatainer from "../../Components/SideBarConatainer.web";

const RequestTopUp = ({ navigation }) => {

  return (
    <SideBarConatainer navigation={navigation}>
      <View style={styles.mainContainer}>
        <TopTabMoney />
      </View>
    </SideBarConatainer>
  );
};

export default RequestTopUp;

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
  },
});
