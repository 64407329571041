import {
  StyleSheet,
  Text,
  View,
  Dimensions,
  ScrollView,
  TouchableOpacity,
} from "react-native";
import React, { useState } from "react";
import Icon from "react-native-vector-icons/Feather";
import { useTranslation } from "react-i18next";
import { SafeAreaView } from "react-native-safe-area-context";
import HeaderContainer from '../../Components/AppHeader';

const height = Dimensions.get("window").height;


const BeneficiariesDetail = ({ navigation, route }) => {
  const { t } = useTranslation();
  const { beneficiaries } = route.params


  return (
    <SafeAreaView style={styles.topContainer}>
      <HeaderContainer />
      <View style={styles.topHeader}>
        <View style={styles.innerContainer}>
          <View style={styles.headerView}>
            <Icon
              name="arrow-left"
              size={30}
              onPress={() => navigation.goBack()}
            />
            <Text style={styles.editText}>
              {t("common:beneficiaries_details")}
            </Text>
          </View>

         <View style={styles.mainContainer}>
         <View style={styles.container}>
            <Text style={styles.nameText}>{t("common:phone_number")}</Text>
            <Text style={styles.summaryText}>{beneficiaries.beneficiary_user.phone_number}</Text>
          </View>

          <View style={styles.container}>
            <Text style={styles.nameText}>{t("common:account_holder_name")}</Text>
            <Text style={styles.summaryText}>{beneficiaries.beneficiary_user.name}</Text>
          </View>

          <View style={styles.container}>
            <Text style={styles.nameText}>{t("common:account_type")}</Text>
            <Text style={styles.summaryText}></Text>
          </View>

          <View style={styles.btnView}>
            <TouchableOpacity
              onPress={() => navigation.navigate('Transfer', { phoneNumber: beneficiaries.beneficiary_user.phone_number })}
              style={styles.button}>
              <Text style={styles.buttonText}>{t("common:transfer_money")}</Text>
            </TouchableOpacity>
          </View>
         </View>

        </View>
      </View>
    </SafeAreaView>
  );
};

export default BeneficiariesDetail;

const styles = StyleSheet.create({
  topContainer: {
    flex: 1,
    backgroundColor: "#F8F8F8",
  },
  topHeader:{
    flex:1,
    justifyContent:"center",
    flexDirection:"row"
  },
  innerContainer: {
    width:1400
  },
  headerView: {
    flexDirection: "row",
    marginTop:25,
    alignItems:"center"
  },
  editText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 26,
    color: "#000000",
    marginLeft: 10
  },
  mainContainer:{
    backgroundColor:"#FFFFFF",
    borderRadius:16,
    gap:20,
    padding:20,
    marginTop:25
  },
  container: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  nameText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 14,
    color: "#101820",
  },
  summaryText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 14,
    color: "#FF4747",
  },
  accountNumberText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 14,
    color: "#101820",
  },
  btnView: {
    flex: 1,
    alignItems:"flex-end",
    marginBottom:20
  },
  button: {
    backgroundColor: "#FF4747",
    borderRadius: 70,
    padding: 16,
    width:327
  },
  buttonText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 16,
    alignItems: "center",
    textAlign: "center",
    color: "#FFFFFF",
  },
});
