import {
    StyleSheet,
    Text,
    View,
    TouchableOpacity,
    Image,
    I18nManager,
    SafeAreaView,
    Modal,
    Pressable
} from "react-native";
import React, { useState, useEffect } from "react";
import Icon from "react-native-vector-icons/Feather";
import { useTranslation } from "react-i18next";
import MdpCustomer from "../../../assets/image/mdpCustomer.png"
import MdpCards from "../../../assets/image/mdpCards.png"
import { useDispatch, useSelector } from "react-redux";
import { mdpCreateCard } from "../../../Redux/Models/MdpCards/MdpCreateCardRequest/Actions";
import { mdpCardsKycStatus } from "../../../Redux/Models/MdpCards/MdpCardsKycStatus/Actions";
import SideBarConatainer from "../../../Components/SideBarConatainer";
import ToastAndroid from "react-native-root-toast";
const RTL = I18nManager.isRTL;

const MdpCard = ({ navigation }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const userDetails = useSelector((state) => state.userDetails);
    const mdpCreate = useSelector((state) => state.mdpCreateCard)
    const mdpKycStatus = useSelector((state) => state.mdpCardsKycStatus)

    useEffect(() => {
        dispatch(mdpCreateCard());
        dispatch(mdpCardsKycStatus());
    }, [])

    const verificationMdpCheck = () => {
        if (userDetails.data.user.verification_level === "0") {
            alert(mdpCreate.message)
            return
        }
        navigation.navigate("MdpCustomer");
    }
    const mdpCheckStatus = () => {
        if (mdpKycStatus.success === true) {
            alert(mdpCreate.message)
            return
        }
        navigation.navigate("ChangeBalance");
    }

    return (
        <SideBarConatainer navigation={navigation}>

            <View style={styles.topContainer}>
                <View style={styles.headerView}>
                    <Icon
                        name="arrow-left"
                        size={30}
                        onPress={() => navigation.goBack()}
                        style={styles.arrowIcon}
                    />
                    <Text style={styles.moneyText}>{t("common:mdp_cards")}</Text>
                </View>
                <View style={styles.container}>
                    <TouchableOpacity onPress={verificationMdpCheck} style={styles.buttonContainer}>
                        <View style={styles.imageContainer}>
                            <Image source={MdpCustomer} style={styles.cardImage} />
                            <Text style={styles.topUpText}>
                                {t("common:mdp_customer")}
                            </Text>
                        </View>
                        <Icon name="chevron-right" size={24} style={styles.arrowIcon} />
                    </TouchableOpacity>
                    <TouchableOpacity  onPress={mdpCheckStatus}
                        style={{ ...styles.buttonContainer, borderBottomWidth: 0 }}
                    >
                        <View style={styles.imageContainer}>
                            <Image source={MdpCards} style={styles.cardImage} />
                            <Text style={styles.topUpText}>{t("common:mdp_cards")}</Text>
                        </View>
                        <Icon name="chevron-right" size={24} style={styles.arrowIcon} />
                    </TouchableOpacity>
                </View>
            </View>
        </SideBarConatainer >
    );
};

export default MdpCard;

const styles = StyleSheet.create({
    topContainer: {
        flex: 1,
    },
    headerView: {
        flexDirection: "row",
        alignItems: "center",
    },
    container: {
        paddingHorizontal: 5,
      },
    moneyText: {
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: "700",
        fontSize: 26,
        color: "#000000",
        marginLeft: 10
    },
    buttonContainer: {
        flexDirection: "row",
        marginTop: 15,
        height: 100,
        backgroundColor: "#FFFFFF",
        borderRadius: 16,
        alignItems: "center",
        justifyContent: "center",
        paddingHorizontal: 20
    },
    imageContainer: {
        flex: 1,
        justifyContent: "flex-start",
        flexDirection: "row",
    },
    topUpText: {
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: 16,
        color: "#000000",
        marginLeft: 15
    },
    cardImage: {
        width: 24,
        height: 24,
        resizeMode: "contain"
    },
    arrowIcon: {
        transform: [{ scaleX: RTL ? -1 : 1 }]
    }
});
