import {
  StyleSheet,
  Text,
  View,
  Image,
  Dimensions,
  SafeAreaView,
  ScrollView
} from "react-native";
import React from "react";
import Icon from "react-native-vector-icons/Feather";
import { useTranslation } from "react-i18next";
import AmazonGift from "../../assets/image/amazonService.png";
const height = Dimensions.get("window").height;
import AppHeader from "../../Components/AppHeader";

const GiftCardDetail = ({ navigation, route }) => {
  const { t } = useTranslation();
  const { item } = route.params;
  return (
    <SafeAreaView style={styles.mainContainer}>
      <AppHeader navigation={navigation} />
      <View style={styles.topHeader}>
        <View style={styles.inSideContainer}>

          <View style={styles.headerView}>
            <Icon
              name="arrow-left"
              size={24}
              style={styles.arrowIcon}
              onPress={() => navigation.goBack()}
            />
            <Text style={styles.cardText}>{t("common:gift_card")}</Text>
          </View>
          <View style={styles.topContainer}>
            <View style={styles.cardContainer}>
              <Image source={{ uri: item.company.logo }} style={styles.amazonImage} />
            </View>
            <View style={styles.innerContainer}>
              <View style={styles.container}>
                <Text style={styles.nameText}>{t("common:company_name")}</Text>
                <Text style={styles.summaryText}> {item.company.title}</Text>
              </View>

              <View style={styles.container}>
                <Text style={styles.nameText}>{t("common:transaction_id")}</Text>
                <Text style={styles.summaryText}>{item.transaction_no}</Text>
              </View>

              <View style={styles.container}>
                <Text style={styles.nameText}>{t("common:serial_number")}</Text>
                <Text style={styles.summaryText}>{item.code}</Text>
              </View>

              <View style={styles.container}>
                <Text style={styles.nameText}>{t("common:amount")}</Text>
                <Text style={styles.summaryText}>${item.amount}</Text>
              </View>

              <View style={styles.container}>
                <Text style={styles.nameText}>{t("common:voucher_code")}</Text>
                <Text style={styles.summaryText}>{item.voucher_id}</Text>
              </View>

              <View style={styles.container}>
                <Text style={styles.nameText}>{t("common:instructions")}</Text>
                <Text style={{ ...styles.summaryText, flex: 1 }}>
                  {item.company.instructions}
                </Text>
              </View>
            </View>
          </View>
        </View>
      </View>
    </SafeAreaView>
  );
};

export default GiftCardDetail;

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
  },
  topHeader: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "center",
  },
  inSideContainer: {
    flex: 1,
    maxWidth: 1400,
  },
  headerView: {
    flexDirection: "row",
    marginTop: 25,
    alignItems: "center",
  },
  cardText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 26,
    color: "#000000",
    marginLeft: 15
  },
  topContainer: {
    marginTop: 25,
    padding: 20,
    borderRadius: 16,
    backgroundColor: "#FFF",
    flexDirection: "row",
  },
  innerContainer: {
    flex: 1,
    marginLeft: 80,
  },
  cardContainer: {
    alignItems: "center",
    justifyContent: "center",
  },
  amazonImage: {
    width: 390,
    height: 212,
    resizeMode: "contain"
  },
  container: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: 24,
  },
  nameText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 14,
    color: "#101820",
  },
  summaryText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 14,
    textAlign: "right",
    color: "#FF4747",
  },
});
