import {
  StyleSheet,
  Text,
  View,
  Dimensions,
  ScrollView,
  TextInput,
  I18nManager,
  TouchableOpacity,
  Image,
  Platform,
  KeyboardAvoidingView,
  SafeAreaView,
} from "react-native";
import ToastAndroid from "react-native-root-toast";
import React, { useState, useEffect, useRef } from "react";
import Icon from "react-native-vector-icons/Feather";
import { useTranslation } from "react-i18next";
import { Picker } from "@react-native-picker/picker";
import Calendar from "../../assets/image/calendar.png";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../Loading";
import { editFamilyMember, editFamilyMemberStatus } from "../../Redux/Models/User/MyFamily/EditFamilyMember/Actions";
import { changeFamilyMemberPassword } from "../../Redux/Models/User/MyFamily/ChangePassword/Actions";
import { resetFamilyMember } from "../../Redux/Models/User/MyFamily/MyFamilyList/Actions";
import DateTimePickerModal from "react-native-modal-datetime-picker";
import WebDatePicker from "../../Components/DatePickerTest";
import { userFamilyList } from "../../Redux/Models/User/MyFamily/MyFamilyList/Actions";

const height = Dimensions.get("window").height;

const EditFamilyMember = ({ navigation }) => {
  const { t } = useTranslation();
  const [createPassword, setCreatePassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [date, setDate] = useState(new Date());
  const [formateDate, setFormateDate] = useState("");
  const [isDatePickerVisible, setDatePickerVisibility] = useState(false);
  const [show, setShow] = useState(true)
  const showDatePicker = () => {
    setDatePickerVisibility(true);
    if (Platform.OS === "web") {
      setShow(false);
    }
  };
  const hideDatePicker = () => {
    setDatePickerVisibility(false);
  };
  const dispatch = useDispatch();
  const Validate = () => {
    if (firstName === "") {
      ToastAndroid.show(
        t("common:Please_enter_your_first_name"),
        ToastAndroid.LONG
      );
      return false;
    } else if (lastName === "") {
      ToastAndroid.show(
        t("common:Please_enter_your_last_name"),
        ToastAndroid.LONG
      );
      return false;
    } else if (id === "") {
      ToastAndroid.show(t("common:Please_enter_id"), ToastAndroid.LONG);
      return false;
    } else if (date === undefined) {
      ToastAndroid.show(t("common:Please_select_DOB"), ToastAndroid.LONG);
      return false;
    } else if (phoneNumber === "") {
      ToastAndroid.show(
        t("common:Please_enter_the_phoneNumber"),
        ToastAndroid.LONG
      );
      return false;
    } else if (createPassword === "") {
      ToastAndroid.show(t("common:Please_enter_password"), ToastAndroid.LONG);
      return false;
    } else if (confirmPassword === "") {
      ToastAndroid.show(
        t("common:Please_enter_confirm_password"),
        ToastAndroid.LONG
      );
      return false;
    } else if (createPassword !== confirmPassword) {
      ToastAndroid.show(
        t("common:Please_enter_match_password"),
        ToastAndroid.LONG
      );
      return false;
    }
    return true;
  };
  const userEditFamilyMember = useSelector((state) => state.editFamilyMember);
  const selectedFamilyMember = useSelector(
    (state) => state.myFamilyList.selectedFamilyMember
  );
  console.log(selectedFamilyMember);
  const [firstName, setFirstName] = useState(
    selectedFamilyMember.name.split(" ")[0]
  );
  const [lastName, setLastName] = useState(
    selectedFamilyMember.name.split(" ")[1]
  );
  const [id, setId] = useState(selectedFamilyMember.national_number);
  const [phoneNumber, setPhoneNumber] = useState(
    selectedFamilyMember.phone_number
  );
  const [relation, setRelation] = useState(selectedFamilyMember.rel_type);

  const editFamilyMemberApi = () => {
    if (!Validate()) {
      return;
    }
    let formData = new FormData()
    formData.append('user_no', selectedFamilyMember.user_no)
    formData.append('name', firstName + ' ' + lastName)
    phoneNumber == selectedFamilyMember.phone_number ? '' : formData.append('phone_number', phoneNumber.replace(/-/g, ""))
    formData.append('national_number', id)
    formData.append('rel_type', relation)
    dispatch(editFamilyMember(formData));

  }
  useEffect(() => {
    if (userEditFamilyMember.error === true) {
      alert(userEditFamilyMember.message)
      dispatch(editFamilyMemberStatus())
    }
    if (userEditFamilyMember.success === true) {
      console.log("sfsdfsdfdsfdsf", userEditFamilyMember)
      let formData = new FormData()
      formData.append('password', confirmPassword)
      dispatch(changeFamilyMemberPassword({ formData, parms: selectedFamilyMember.id }))
      // dispatch(resetFamilyMember(undefined));
      // dispatch(userFamilyList());
      navigation.navigate('FamilyDetail')
    }
  }, [userEditFamilyMember]);
  const DateFormate = (date) => {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    setFormateDate(
      date.getDate() +
      " " +
      monthNames[date.getMonth()] +
      " " +
      date.getFullYear()
    );
  };
  const dateRef = useRef();

  const handleClickOutside = (event) => {
    let date = dateRef.current.contains(event.target)
    if (date === false && Platform.OS === "web") {
      setDatePickerVisibility(false);
      setShow(true)
    }
  }
  React.useEffect(() => {
    if (Platform.OS === "web") {
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }
  }, []);
  const OnDateChange = (selectedDate) => {
    const currentDate = selectedDate;
    setDate(currentDate);
    DateFormate(currentDate);
    setDatePickerVisibility(false);
    if (Platform.OS === "web") {
      setShow(true);
    }
  };
  useEffect(() => {
    DateFormate(date);
  }, []);
  return (
    <SafeAreaView style={styles.topContainer}>
      <View style={styles.headerView}>
        <Icon
          name="arrow-left"
          size={30}
          style={styles.arrowIcon}
          onPress={() => navigation.goBack()}
        />
        <Text style={styles.editText}>{t("common:edit_family_member")}</Text>
      </View>
      <KeyboardAvoidingView
        style={{ flex: 1 }}
        behavior={Platform.OS === "ios" ? "padding" : "height"}
      >
        <ScrollView contentContainerStyle={{ flexGrow: 1 }} showsHorizontalScrollIndicator={false}>
          <View style={styles.innerContainer}>
            <Text style={styles.personalInfoText}>
              {t("common:provide_basic_information_family")}
            </Text>
            <View style={styles.inputContainer}>
              <View style={styles.inputView}>
                <Text style={styles.nameText}>
                  {t("common:first_name_st")}
                  <Text style={{ color: "#FF4747" }}>*</Text>
                </Text>
                <TextInput
                  textAlign={I18nManager.isRTL ? "right" : "left"}
                  defaultValue={firstName}
                  onChangeText={(newText) => setFirstName(newText)}
                  style={styles.input}
                ></TextInput>
              </View>
              <View style={{ ...styles.inputView, marginLeft: 20 }}>

                <Text style={styles.nameText}>
                  {t("common:last_name_st")}
                  <Text style={{ color: "#FF4747" }}>*</Text>
                </Text>
                <TextInput
                  textAlign={I18nManager.isRTL ? "right" : "left"}
                  defaultValue={lastName}
                  onChangeText={(newText) => setLastName(newText)}
                  style={styles.input}
                ></TextInput>
              </View>
            </View>
            <View style={[styles.inputContainer, { zIndex: 2000 }]}>
              <View style={styles.inputView}>
                <Text style={styles.nameText}>
                  {t("common:national_id_st")}
                  <Text style={{ color: "#FF4747" }}>*</Text>
                </Text>
                <TextInput
                  textAlign={I18nManager.isRTL ? "right" : "left"}
                  keyboardType="number-pad"
                  returnKeyType="done"
                  defaultValue={id}
                  onChangeText={(newText) => setId(newText)}
                  style={styles.input}
                ></TextInput>
              </View>
              <View style={{ ...styles.inputView, marginLeft: 20 }}>

                <Text style={styles.nameText}>
                  {t("common:date_of_birth")}
                  <Text style={{ color: "#FF4747" }}> *</Text>
                </Text>
                <View style={styles.dateOfBirthView}>
                  <Text style={styles.dateText}>{formateDate}</Text>
                  <TouchableOpacity
                    onPress={showDatePicker}
                    style={styles.dateButton}
                  >
                    {show && (<Image source={Calendar} style={styles.calendarImage} />)}
                  </TouchableOpacity>
                  <View style={isDatePickerVisible ? { display: "flex" } : { display: "none" }} ref={dateRef}>
                    {Platform.OS === 'web' && (<WebDatePicker isDatePickerVisible={isDatePickerVisible} date={date} OnDateChange={OnDateChange} />)}
                  </View>
                  <DateTimePickerModal
                    isVisible={isDatePickerVisible}
                    mode="date"
                    onConfirm={OnDateChange}
                    onCancel={hideDatePicker}
                  />
                </View>
              </View>
            </View>
            <View style={styles.inputContainer}>
              <View style={styles.inputView}>
                <Text style={styles.nameText}>
                  {t("common:phone_number_st")}
                  <Text style={{ color: "#FF4747" }}>*</Text>
                </Text>
                <TextInput
                  textAlign={I18nManager.isRTL ? "right" : "left"}
                  keyboardType="phone-pad"
                  returnKeyType="done"
                  maxLength={12}
                  value={phoneNumber}
                  onChangeText={(text) => {
                    var cleaned = ("" + text).replace(/\D/g, "");
                    let normValue = `${cleaned.substring(0, 3)}${cleaned.length > 3 ? "-" : ""
                      }${cleaned.substring(3, 6)}${cleaned.length > 6 ? "-" : ""
                      }${cleaned.substring(6, 10)}`;
                    setPhoneNumber(normValue);
                  }}
                  style={styles.input}
                ></TextInput>
              </View>
              <View style={{ ...styles.inputView, marginLeft: 20 }}>

                <Text style={styles.nameText}>
                  {t("common:relation_st")}
                  <Text style={{ color: "#FF4747" }}>*</Text>
                </Text>
                <View style={styles.pickerView}>
                  <Picker
                    mode="dropdown"
                    selectedValue={relation}
                    onValueChange={(selectedValue) => setRelation(selectedValue)}
                    style={styles.pickerItem}
                  >
                    <Picker.Item
                      label="Child"
                      value="Child"
                      style={styles.pickerItemText}
                    />
                    <Picker.Item
                      label="Spouse"
                      value="Spouse"
                      style={styles.pickerItemText}
                    />
                    <Picker.Item
                      label="Sibling"
                      value="Sibling"
                      style={styles.pickerItemText}
                    />
                    <Picker.Item
                      label="Other"
                      value="Other"
                      style={styles.pickerItemText}
                    />
                  </Picker>
                </View>
              </View>
            </View>
            <View style={styles.inputContainer}>
              <View style={styles.inputView}>

                <Text style={styles.nameText}>
                  {t("common:create_password_st")}
                  <Text style={{ color: "#FF4747" }}>*</Text>
                </Text>
                <TextInput
                  textAlign={I18nManager.isRTL ? "right" : "left"}
                  defaultValue={createPassword}
                  onChangeText={(newText) => setCreatePassword(newText)}
                  secureTextEntry={true}
                  style={styles.input}
                  returnKeyType="done"
                ></TextInput>
              </View>
              <View style={{ ...styles.inputView, marginLeft: 20 }}>

                <Text style={styles.nameText}>
                  {t("common:confirm_password_st")}
                  <Text style={{ color: "#FF4747" }}>*</Text>
                </Text>
                <TextInput
                  textAlign={I18nManager.isRTL ? "right" : "left"}
                  defaultValue={confirmPassword}
                  onChangeText={(newText) => setConfirmPassword(newText)}
                  secureTextEntry={true}
                  style={styles.input}
                  returnKeyType="done"
                ></TextInput>
              </View>
            </View>
            <View style={styles.btnView}>
              <TouchableOpacity
                onPress={editFamilyMemberApi}
                style={styles.button}
              >
                <Text style={styles.buttonText}>{t("common:save")}</Text>
              </TouchableOpacity>
            </View>
          </View>
        </ScrollView>
      </KeyboardAvoidingView>
      {userEditFamilyMember.loading && <Loading></Loading>}
    </SafeAreaView>
  );
};

export default EditFamilyMember;

const styles = StyleSheet.create({
  topContainer: {
    flex: 1,
    marginTop: 20,
    backgroundColor: "#FFFFFF",
    borderRadius: 16,
    padding: 20,
    marginBottom: 25,
  },
  headerView: {
    flexDirection: "row",
    marginTop: 30,
    alignItems: "center",
    backgroundColor: "#FFFFFF",
  },
  arrowIcon: {
    marginLeft: 24,
  },
  editText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 22,
    color: "#000000",
    marginLeft:15
  },
  innerContainer: {
    flex: 1,
    marginHorizontal: 24,
  },
  inputContainer: {
    flex: 1,
    flexDirection: "row",
  },
  inputView: {
    flex: 1,
  },
  personalInfoText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 14,
    color: "#000000",
    alignSelf: "flex-start"
  },
  nameText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 14,
    color: "#A9ABAE",
    marginTop: 10,
    alignSelf: "flex-start"
  },
  input: {
    borderBottomWidth: 1,
    borderColor: "#E7E8E9",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 14,
    color: "#101820",
    height: 56,
    outlineStyle: "none",
  },
  dateOfBirthView: {
    borderBottomWidth: 1,
    borderColor: "#F1F1F1",
    height: 49,
    flexDirection: "row",
    justifyContent: "space-between",
    zIndex: 2000
  },
  dateText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 14,
    color: "#101820",
    alignSelf: "flex-start",
    marginTop: 20
  },
  dateButton: {
    marginRight: 15,
    marginTop: 20
  },
  calendarImage: {
    height: 18,
    width: 16.5,
    resizeMode: "contain"
  },
  pickerItemText: {
    color: "#101820",
    fontSize: 14,
    fontWeight: "500",
    fontFamily: "Inter",
  },
  pickerView: {
    borderBottomWidth: 1,
    borderColor: "#E7E8E9",
    height: 49,
    justifyContent: "center"
  },
  pickerItem: {
    height: 45,
    borderWidth: 1,
    borderColor: "white",
    borderStyle: "solid",
  },
  btnView: {
    flex: 1,
    marginTop: 32,
    alignItems: "flex-end",
  },
  button: {
    backgroundColor: "#FF4747",
    borderRadius: 70,
    padding: 16,
    width:327
  },
  buttonText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 16,
    alignItems: "center",
    textAlign: "center",
    color: "#FFFFFF",
  },
});
