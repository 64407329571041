import {takeEvery, put, call} from 'redux-saga/effects';
import BASEURL from '../URLs'
import {queryApi} from '../QueryApi'
import {
  GET_REQUEST_DETAILS_REQUEST,
  GET_REQUEST_DETAILS_REQUEST_SUCCESS,
  GET_REQUEST_DETAILS_REQUEST_FAILURE,
} from "./Actions"; 
//import {queryApi} from '../query-api';

function* handler() {
  yield takeEvery(GET_REQUEST_DETAILS_REQUEST, requestDetails);
}


function* requestDetails(action) {
  try {
    // API call
    console.log("REQUEST_DETAILS", action.params);
    const apiResponse = yield call(queryApi, {
      endpoint: BASEURL.requestDetails + action.params,
      method: "GET",
    });
    console.log("REQUEST_DETAILS", apiResponse);
    if (!("error" in apiResponse)) {
      yield put({
        type: GET_REQUEST_DETAILS_REQUEST_SUCCESS,
        payload: apiResponse,
      });
    } else {
      yield put({
        type: GET_REQUEST_DETAILS_REQUEST_FAILURE,
        payload: apiResponse.error,
      });
    }
  } catch (err) {
    console.log(err);
    // Handle error
    yield put({
      type: GET_REQUEST_DETAILS_REQUEST_FAILURE,
      payload: { message: err },
    });
  }
}

export {handler};