import {
  StyleSheet,
  Text,
  View,
  Dimensions,
  TouchableOpacity,
  Image,
  SafeAreaView,
} from "react-native";
import React, { useState } from "react";
import Icon from "react-native-vector-icons/Feather";
import { useTranslation } from "react-i18next";
import Edit from "../../assets/image/edit.png";
import { DateFormate } from "../../Utils/HelperFunctions";
const height = Dimensions.get("window").height;
import SideBarConatainer from "../../Components/SideBarConatainer";

const KycPersonalInfo = ({ navigation, route }) => {
  const personalInfo = route.params?.personalInfo.user;
  const requestId = route.params?.personalInfo;
  const { t } = useTranslation();

  return (
    <SideBarConatainer navigation={navigation}>
      <View style={styles.headerView}>
        <Icon
          name="arrow-left"
          size={30}
          style={styles.arrowIcon}
          onPress={() => navigation.goBack()}
        />
        <Text style={styles.requestsText}>{t("common:requests")}</Text>
      </View>
      <View style={styles.innerContainer}>
        <View style={styles.personalView}>
          <Text style={styles.personalText}>
            {t("common:personal_information")}
          </Text>
          {requestId.verify_request_status.toLowerCase() === "pending" && (
            <TouchableOpacity
              onPress={() =>
                navigation.navigate("EditPersonalInfo", {
                  personalInfo,
                  requestId,
                })
              }
              style={styles.editBtn}
            >
              <Image source={Edit} style={styles.editImage} />
            </TouchableOpacity>
          )}
        </View>
        <View style={styles.container}>
          <Text style={styles.nameText}>{t("common:father_name")}</Text>
          <Text style={styles.cblRequestText}>{personalInfo.fathers_name}</Text>
        </View>

        <View style={styles.container}>
          <Text style={styles.nameText}>{t("common:grand_father_name")}</Text>
          <Text style={styles.cblRequestText}>
            {personalInfo.grandfathers_name}
          </Text>
        </View>

        <View style={styles.container}>
          <Text style={styles.nameText}>{t("common:mother_name_st")}</Text>
          <Text style={styles.cblRequestText}>{personalInfo.mother_name}</Text>
        </View>

        <View style={styles.container}>
          <Text style={styles.nameText}>{t("common:gender_st")}</Text>
          <Text style={styles.cblRequestText}>{personalInfo.gender}</Text>
        </View>

        <View style={styles.container}>
          <Text style={styles.nameText}>{t("common:date_of_birth_st")}</Text>
          <Text style={styles.cblRequestText}>
            {DateFormate(new Date(personalInfo.Dob))}
          </Text>
        </View>

        <View style={styles.container}>
          <Text style={styles.nameText}>{t("common:marital_status")}</Text>
          <Text style={styles.cblRequestText}>
            {personalInfo.social_status}
          </Text>
        </View>
        <View style={styles.container}>
          <Text style={styles.nameText}>{t("common:nationality")}</Text>
          <Text style={styles.cblRequestText}>{personalInfo.nationality}</Text>
        </View>
        <View style={styles.container}>
          <Text style={styles.nameText}>{t("common:email")}</Text>
          <Text style={styles.cblRequestText}>{personalInfo.email}</Text>
        </View>
        <View style={styles.container}>
          <Text style={styles.nameText}>{t("common:secondary_number")}</Text>
          <Text style={styles.cblRequestText}>
            {personalInfo.secondary_contact_number}
          </Text>
        </View>
      </View>
    </SideBarConatainer>
  );
};

export default KycPersonalInfo;

const styles = StyleSheet.create({
  topContainer: {
    flex: 1,
    backgroundColor: "#FFFFFF",
  },
  headerView: {
    flexDirection: "row",
  },
  requestsText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 26,
    color: "#000000",
    marginLeft: 10,
  },
  innerContainer: {
    marginTop: 20,
    backgroundColor: "#ffffff",
    padding: 20,
    borderRadius: 16,
  },
  personalView: {
    marginBottom: 14,
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  personalText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 18,
    color: "#000000",
    alignSelf: "flex-start",
  },
  editBtn: {},
  editImage: {
    height: 17,
    width: 51,
  },
  container: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: 20,
  },
  nameText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 14,
    color: "#BDBDBD",
  },
  cblRequestText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 14,
    textAlign: "right",
  },
});
