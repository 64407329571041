
const GET_MY_REQUESTS_REQUEST = 'GET_MY_REQUESTS_REQUEST';
const GET_MY_REQUESTS_REQUEST_SUCCESS =  'GET_MY_REQUESTS_REQUEST_SUCCESS';
const GET_MY_REQUESTS_REQUEST_FAILURE =  'GET_MY_REQUESTS_REQUEST_FAILURE';
const SET_REQUESTS_TAB = 'SET_REQUESTS_TAB'
const getMyRequests = (parm)=>{

    return {
        type: GET_MY_REQUESTS_REQUEST,
        parm
    }
}
const selectedRequests = (tab)=>{

    return {
        type: SET_REQUESTS_TAB,
        tab
    }
}
export {
    getMyRequests,
    selectedRequests,
    GET_MY_REQUESTS_REQUEST,
    GET_MY_REQUESTS_REQUEST_SUCCESS,
    GET_MY_REQUESTS_REQUEST_FAILURE,
    SET_REQUESTS_TAB
};