import * as LocalAuthentication from "expo-local-authentication";
import ToastAndroid from "react-native-root-toast";
const DateFormate = (date) => {
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  return (
    date.getDate() +
    " " +
    monthNames[date.getMonth()] +
    " " +
    date.getFullYear()
  );
};
function formatAMPM(currDate) {
  let date = new Date(Math.floor(new Date(currDate) * 1000));
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
}

function formatTimeAMPM(hours, minutes) {
  var ampm = Number(hours) >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12;
  minutes = Number(minutes) < 10 ? "0" + Number(minutes) : Number(minutes);
  var strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
}
const ApiDateFormate = (currDate) => {
  let dateLength = Math.floor(new Date(currDate)).toString().length;
  let date = new Date(currDate);
  if (dateLength === 10) {
    date = new Date(Math.floor(new Date(currDate) * 1000));
  }
  if (dateLength === 13) {
    date = new Date(Math.floor(new Date(currDate / 1000) * 1000));
  }
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  return (
    date.getDate() +
    " " +
    monthNames[date.getMonth()] +
    " " +
    date.getFullYear()
  );
};

const biometricVerify = async () => {
  try {
    if (
      (await LocalAuthentication.hasHardwareAsync()) === true &&
      (await LocalAuthentication.isEnrolledAsync()) === true
    ) {
      const result = await LocalAuthentication.authenticateAsync({
        promptMessage: "Biometric login for Tizdan app",
        cancelLabel: "Use PIN",
        disableDeviceFallback: true,
      });
      return result.success;
    } else {
      ToastAndroid.show("Touch ID not found", ToastAndroid.LONG);
      return false;
    }
  } catch (err) {
    console.log(err);
    return false;
  }
};

export {
  DateFormate,
  formatAMPM,
  formatTimeAMPM,
  biometricVerify,
  ApiDateFormate,
};
