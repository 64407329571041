import {takeEvery, put, call} from 'redux-saga/effects';
import BASEURL from '../../URLs'
import {queryApi} from '../../QueryApi'
import {
  GET_MDP_CREATE_CARD_REQUEST,
  GET_MDP_CREATE_CARD_REQUEST_SUCCESS,
  GET_MDP_CREATE_CARD_REQUEST_FAILURE,
} from "./Actions"; 
//import {queryApi} from '../query-api';

function* handler() {
  yield takeEvery(GET_MDP_CREATE_CARD_REQUEST, mdpCreateCard);
}


function* mdpCreateCard() {
  try {
    // API call
    // console.log("MDP_CREATE_CARD", action.formData);
    const apiResponse = yield call(queryApi, {
      endpoint: BASEURL.mdpCreateCard,
      method: "GET",
    });
    console.log("MDP_CREATE_CARD", apiResponse);
    if (!("error" in apiResponse)) {
      yield put({
        type: GET_MDP_CREATE_CARD_REQUEST_SUCCESS,
        payload: apiResponse,
      });
    } else {
      yield put({
        type: GET_MDP_CREATE_CARD_REQUEST_FAILURE,
        payload: apiResponse.error,
      });
    }
  } catch (err) {
    console.log(err);
    // Handle error
    yield put({
      type: GET_MDP_CREATE_CARD_REQUEST_FAILURE,
      payload: { message: err },
    });
  }
}

export {handler};