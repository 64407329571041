export default {
  //--------common-----------//

  Continue: "Continue",
  next: "Next",
  skip: "Skip",
  SUBMIT: "Submit",
  save_and_continue: "Save and Continue",
  Confirm: "Confirm",
  transfer_money: "Transfer Money",
  save: "Save",
  scan_qr_code: "Scan QR code",
  select_source: "Select Source",
  Cancel: "Cancel",
  take_a_photo: "Take a photo",
  choose_a_photo_from_the_gallery: "Choose a photo from the gallery",
  Select_Printer: "Select Printer",
  Sunmi_Printer: "Sunmi Printer",
  Android_Printer: "Android Printer",
  //--------Dashboard-------//
  qr_pay: "QR Pay",
  transactions: "Transactions",
  received_from: "Received from",
  sended_to: "Sended To",
  transaction_detail: "Transaction Detail",
  transaction_amount: "Transaction Amount",
  transaction_from: "Transaction From",
  transaction_to: "Transaction To",
  transaction_number: "Transaction Number",
  transactions_status: "Transactions Status",
  transactions_created: "Transactions Created",
  Home: "Home",
  category: "Category",
  document: "Document",
  Activity: "Activity",
  Profile: "Profile",
  my_account: "My Account",
  //--------Services-------//
  services: "Services",
  essentials: "Essentials",
  service_vouchers: "Service Vouchers",
  //--------Account-------//
  account: "Accounts",
  documents_and_reports: "Documents and reports",
  manage_notifications: "Manage notifications",
  invite_friends: "Invite friends",
  personal_info: "Personal Information",
  security_and_privacy: "Security and privacy",
  subscription: "Subscription",
  contact_to_support: "Contact to support",
  rate_us: "Rate us",
  faqs: "FAQS",
  Logout: "Log out",
  tizdan_customer: "Tizdan Customer",
  are_you_sure_you_want_to_logout: "Are you sure you want to logout?",
  transactions_history: "Transactions History",

  //--------HomeScreen-------//

  GetStarted: "Get Started",
  signin: "Sign in",

  //--------PhoneNumber-------//

  EnterPhoneNumber: "Enter your phone number",
  text_you_code: "So we can text you an authentication code",
  enter_phone_number: "Enter your phone number",
  _9xx_xxx_xxxx_phone_number: "9XX-XXX-XXXX",

  //--------VerificationCode-------//

  ResendCode: "Resend code",
  EnterCode: "Enter code",
  WeSentSMS: "We’ve sent you an SMS with the verification code to",
  PleaseEnterCode: "Please enter your verification code",

  //--------Name-------//

  enter_name: "Enter your name",
  FirstName: "First Name",
  LastName: "Last Name",

  //--------Email-------//

  EnterEmail: "Enter Email address",
  EmailAddress: "Email Address",
  please_enter_valid_emil: "Please enter valid emil",

  //--------Password-------//
  enter_password: "Enter your password",
  password: "Password",
  //--------Gender-------//

  ChooseGender: "Choose Gender",
  Male: "Male",
  Female: "Female",
  Other: "Other",

  //--------Nationality-------//

  YourNationality: "Whats your nationality",
  Nationality: "Nationality",

  //--------Residence-------//

  YourResidence: "Choose country of residence",
  CountryOfResidence: "Country of residence",

  //--------PinCode-------//
  verify_pinCode: "Verify PIN - code",
  PinCode: "Create PIN - code",
  UseFingerPrint: "Use FingerPrint",
  update_security_pin: "UPDATE SECURITY PIN",
  new_security_pin_cap: "NEW SECURITY PIN",
  new_security_pin: "New security pin",
  confirm_your_pin: "Confirm your security pin",
  OTP: "OTP",
  New_Password: "New Password",
  Confirm_Password: "Confirm Password",
  CheckOTP: "Check OTP on your registered mobile number or regestered email.",
  PinChanged: "Security pin updated successfully",
  Please_enter_otp: "Please enter a OTP",
  authentication_failure: "Authentication Failure",
  please_enter_four_digit: "Please Enter Four Digit Code",
  Please_enter_match_password: "Please enter Match Password",
  //--------Signin-------//
  welcome_back: "Welcome back",
  forgot_password: "Forgot Password",

  //--------Forget Password-------//
  reset_password: "Reset Password",

  //--------Request-------//

  requests: "Requests",

  //--------AddCard-------//

  add_card: "Add Card",
  Card_number_st: "Card number ",
  ExpireDate: "Expire date ",
  card_holder_name: "Card holder name ",
  CVV: "CVV ",
  add_into_wallet: "Add into wallet ",
  currency_: "Currency ",
  USD: "USD",
  Amount: "Amount ",
  SaveMyCard: "Save my card for future use",

  //--------Bank Top Up-------//
  bank_top_up: "Bank Top Up",
  step_1: "Step 1",
  MakeDeposit: "Make deposit to our bank below to one of your preferred bank",
  Meezan_Bank_Limited: "Meezan Bank Limited",
  account_name: "Account Name",
  Meezan_Bank: "Meezan Bank",
  AccountNumber: "Account Number",
  Dubai_Islamic_Bank: "Dubai Islamic Bank",
  Tizdan_Bank: "Tizdan Bank",
  step_2: "Step 2",
  Add_InTo_Wallet: "Add into wallet",
  amount: "Amount",
  method: "Method",
  deposite_date: "Deposit date",
  upload: "Upload",
  deposit_slip_no: "Slip No",
  //--------Top Up Money-------//
  top_up_money: "Top Up money",
  Add_Via_Credit_Debit_Card: "Add Via Credit / Debit card",
  wallet_to_wallet: "Wallet to Wallet",

  //--------MDP Cards-------//
  mdp_cards: "MDP Cards",
  mdp_issuance_text:
    "MDP Cards requires you to complete your KYC profile first than you can Request a MDP Cards",
  mdp_customer: "MDP Customer",
  second_name: "Second Name",
  place_of_birth: "Place of Birth",
  title: "Title",
  preferred_language: "Preferred Language",
  house: "House",
  change_balance: "Change Balance",
  credit_debit_card: "Credit/Debit Card",
  account_id: "Account Id",
  Please_enter_your_second_name: "Pleasee enter your second name",
  Please_enter_place_of_birth: "Please enter place of birth",
  Please_enter_your_house: "Please enter your house",
  Please_enter_your_id_number: "Please enter your Id number",
  //--------Wallet To Wallet-------//
  available_wallets: "Available wallets",
  you_send: "You Send",
  you_receive: "You Receive",
  //--------New-------//
  exchange_money: "Exchange money",
  kyc: "KYC",
  cbl: "CBL",
  letter_of_credit: "Letter of credit",

  //--------Letter of credit Step1-------//
  company_details: "Company details",
  c_name: "Company name ",
  company_address: "Company address ",
  country_st: "Country ",
  city: "City ",
  bank_details: "Bank details",

  //--------Letter of credit Step2-------//
  financial_information: "Financial Information",
  currency_to: "Currency",
  lC_type: "LC Type",
  confirmed: "Confirmed",
  performa_invoice_date: "Performa invoice date",
  performa_invoice_number: "Performa invoice number",

  //--------Letter of credit Step3-------//
  products_services_details: "Products / Services details",
  fill_in_the_details:
    "Fill in the details of Products/Services that you are buying",

  //--------Letter of credit Step4-------//
  shipment_details: "Shipment details",
  kindly_provide_shipment_details:
    "Kindly provide with following shipment and delivery details",
  delivery_date: "Delivery Date",
  ship_by: "Ship by",
  partial_shipment: "Partial shipment",
  transshipment: "Transshipment",
  date_of_shipment: "Date of shipment",
  latest_date_of_presentation: "Latest date of presentation",
  collect_your_documents: "Collect your documents",
  authorized_to_pick_or_deliver: "Authorized to pick or deliver",

  //--------Letter of credit Step5-------//
  supporting_documents: "Supporting documents",
  certificate_of_origin: "Certificate of origin",
  reference_number: "Reference number ",
  date_of_issue_st: "Date of issue ",
  expiry_date_st: "Expiry date ",

  perfoma_invoice: "Perfoma invoice",
  commercial_invoice: "Commercial invoice",
  packing_list: "Packing list",
  authorized_person_ID: "Authorized person ID",

  //--------KYC Step1-------//
  provide_the_personal_information: "Provide the Personal information",
  father_name_st: "Father name ",
  mother_name_st: "Mother name ",
  gender_st: "Gender ",
  date_of_birth_st: "Date of birth ",
  marital_status: "Marital status",
  nationality: "Nationality",
  email: "Email",
  secondary_contact_number: "Secondary contact number",

  //--------KYC Step2-------//
  provide_address_detail: "Provide Address detail",
  address_st: "Address ",
  street_st: "Street ",
  district_st: "District ",

  //--------KYC Step3-------//
  provide_financial_info: "Provide the Financial information",
  source_of_income_st: "Source of income ",
  business_Emp_name: "Business / Employer name ",
  role_st: "Role ",
  contact_no_st: "Contact No ",
  average_monthly_income_st: "Average monthly income ",
  years_of_experience_st: "Years of experience with current business? ",

  //--------KYC Step4-------//
  attach_the_documents: "Attach the following documents",
  national_id_passport: "National ID / Passport",
  id_number_st: "ID Number ",
  place_of_issue: "Place of issue",
  national_number: "National Number ",
  proof_of_funds: "Proof of funds ",
  Would_apply_for_CBL_Number: "Would you like to apply for CBL Number?",
  no_later: "No, later",
  //--------CBL Request-------//
  cbl_request: "CBL request",
  documents: "Documents",
  kindly_attach_supporting: "Kindly attach any supporting documents (if any)",

  //--------My Request-------//
  completed: "Completed",
  cencelled: "Cencelled",
  pending: "Pending",
  kyc_verification: "KYC Verification",
  //--------CBL Request Details-------//
  request_category: "Request Category",
  cbl_request_: "CBL Request",
  request_id: "Request ID",
  date: "Date",
  status: "Status",
  status_reason_col: "Status Reason",
  documents_in_review: "Documents in Review",
  description: "Description ",
  comments: "Comments",
  cbl_issuance_text:
    "CBL Issuance requires you to complete your profile first than you can Request a CBL",
  description1:
    "CBL Issuance requires you to complete your profile first than you can Request a CBL",
  description2: "Would you like to complete your profile?",
  would_complete_your_profile: "Would you like to complete your profile?",
  Yes: "Yes",
  No: "No",

  //--------KYC Request Details-------//
  personal_information: "Personal information",
  address_details: "Address details",

  //--------KYC Personal Details-------//
  grand_father_name: "Grand Father Name ",
  secondary_number: "Secondary number",
  date_of_birth: "Date of birth",

  //--------KYC Address Details-------//
  address: "Address",
  street: "Street",

  //--------KYC Financial Details-------//
  Financial_information: "Financial information",
  source_of_income: "Source of income",
  buisness_employer_name: "Buisness/Employer name",
  role: "Role",
  official_contact_number: "Official Contact No",
  average_monthly_income: "Average monthly income",
  years_of_experience: "Years of experience",

  //--------KYC Documents Details-------//
  id_number: "ID Number",
  date_of_issue: "Date of issue",
  expiry_date: "Expiry date",
  detail: "Details",
  account_number: "Account Number",
  deposite_currency: "Deposit Currency",
  add_to_wallet: "Add into wallet",
  //--------Transfer-------//
  transfer: "Transfer",
  find_your_recipient: "Find your recipient",
  //--------User Account-------//
  account_number_st: "Account number ",
  transfer_from_st: "Transfer from ",
  amount_st: "Amount ",

  //--------Bank Transfer-------//
  bank_name_st: "Bank name ",
  branch_st: "Branch ",

  //--------Transaction Summary-------//
  transaction_summary: "Transaction Summary",
  sender_name: "Sender name",
  receivers_name: "Receivers name",
  receivers_account_number: "Receivers account number",
  TransactionID: "Transaction ID",
  fee: "Fee",
  Tax: "Tax",

  //--------Family Members-------//
  add_new: "Add New",
  family_members: "Family members",
  Family_Mem: "Family Members",
  total_balance: "Total balance",
  wallets: "Wallets",
  top_up: "Top Up",
  topup: "Top Up",

  with_draw: "Withdraw",
  family_member_details: "Family member details",
  phone: "Phone",
  relation: "Relation",
  delete_member: "Delete member",
  national_id: "National ID",
  delete_account: "Delete Account",
  are_delete_this_account: "Are you sure you want to delete account?",
  //--------Edit Family Members-------//
  edit_family_member: "Edit Family Member",
  provide_basic_information_family: "Provide basic information family members",
  first_name_st: "First Name ",
  last_name_st: "Last Name ",
  national_id_st: "National ID ",
  phone_number_st: "Phone Number ",
  relation_st: "Relation ",
  create_password_st: "Create Password ",
  confirm_password_st: "Confirm Password ",

  //--------Add Family Members-------//
  add_family_member: "Add Family Member",

  //--------Beneficiaries-------//
  beneficiaries: "Beneficiaries",

  //--------Add Beneficiaries-------//
  add_beneficiaries: "Add beneficiaries",
  account_holder_name_st: "Account holder name ",

  //--------Beneficiaries Detail-------//
  beneficiaries_details: "Beneficiaries details",
  phone_number: "Phone Number",
  account_holder_name: "Account holder name",
  account_type: "Account Type",

  //--------Gift Card-------//
  gift_cards: "Gift Cards",
  Purchased_Cards: "Purchased Cards",
  gift_cards_details: "Gift cards - Details",

  //--------Gift Cards-------//
  gift_card: "Gift card",

  //--------Gift Detail-------//
  company_name: "Company Name",
  transaction_id: "Transaction ID",
  serial_number: "Serial number",
  voucher_code: "Voucher code",
  instructions: "Instructions",
  buy: "Buy",

  //--------Purchased Card-------//
  day: "Day",
  week: "Week",
  month: "Month",
  year: "Year",
  active: "Active",
  finished: "Finished",
  buy_date: "Buy Date",

  //--------Service Voucher-------//
  service_voucher: "Service voucher",
  voucher: "Voucher",
  Vouchers: "Vouchers",
  brand: "Brand",
  Total_amount_of_selected: "Total amount of selected voucher is ",
  But_add_balance_in_your_wallet: ". But you don't have enough credit please add balance in your wallet.",
  //--------Add Existing CBL-------//
  add_existing_cbl: "Add Existing CBL",
  provide_existing_CBL_details: "Provide the existing CBL details",
  cbl_number: "CBL Number",
  existing_CBL: "Existing CBL",
  bank_name: "Bank name",
  branch: "Branch",
  //--------My QR Code-------//
  my_qr_code: "MY QR code",
  this_is_your_QR_code:
    "This is your QR code.Present it to others for connect to you",
  add_phone_number: "Add Phone Number",
  enter_number_to_pay: "Enter mobile number to pay",
  //--------QR Pay-------//
  please_provide_the_detail_below: "Please provide the details below",
  pay: "Pay",
  account_no_st: "Account No ",

  appname: "TizdanCustomer",
  offer: "Offer",
  SELECTALANGUAGE: "Select a language",
  Earned: "Earned",
  Select: "Select",
  VIEWALLCARDS: "VIEW ALL CARDS",
  On: "On",

  //--------Validation-------//
  You_can_only_wallets: "You can only  exchange between existing wallets",
  Currency_is_same: "Currency is same",
  Please_enter_ID: "Please enter ID",
  Please_select_date_of_issue: "Please select date of issue",
  Please_select_expiration_date: "Please select expiration date",
  Please_select_identification_document_type:
    "Please select identification document type",
  Please_enter_your_father_name: "Please enter your father name",
  Please_enter_your_mother_name: "Please enter your mother name",
  Please_enter_your_grand_father_name: "Please enter your grand father name",
  Please_select_DOB: "Please select DOB",
  Please_select_Nationality: "Please select Nationality",
  Please_enter_the_Secondary_Contact: "Please enter the Secondary Contact",
  Please_enter_address: "Please enter address",
  Please_Enter_the_Street_Number: "Please Enter the Street Number",
  Please_select_the_District: "Please select the District",
  Please_Enter_Zip_Code: "Please Enter Zip Code",
  Please_enter_Business_Employee_Name: "Please enter Business/ Employee Name",
  Please_select_the_Role: "Please select the Role",
  Please_enter_official_no: "Please enter official no",
  Please_enter_the_Average_Monthly_Income:
    "Please enter the Average Monthly Income",
  Please_Enter_your_current_Experience: "Please Enter your current Experience",
  Please_enter_amount: "Please enter amount",
  Please_enter_the_description: "Please enter the description",
  Please_Enter_the_Phone_Number: "Please Enter the Phone Number",
  Please_attach_Deposit_Slip: "Please attach Deposit Slip",
  Type_comment: "Type comment",
  top_up_wallet: "Wallet",
  details: "Details",
  Find_your_family_member: "Find your family member",
  Insufficient_balance_in_the_selected_wallet: "Insufficient balance in the selected wallet",
  Please_enter_your_first_name: "Please enter First Name",
  Please_enter_your_last_name: "Please enter your Last Name",
  Please_enter_id: "Please enter National ID",
  Please_enter_the_phoneNumber: "Please enter the Phone Number",
  Please_enter_password: "Please enter Password",
  Please_enter_confirm_password: "Please enter Confirm Password",
  PhonNumber_does_not_match_any_account:
    "PhonNumber does not match any account",
  //SPLASH SCREEN-->
  Off: "Off",
  currency: " LYD ",
  DigitalPaymentSolution: "Digital Payment Solution",
  //INTRO SCREEN-->
  NEWUPDATE: "NEW UPDATE",
  NOTNOW: "NOT NOW",
  string_first: "Send and Receive Payments",
  string_second: "Scan and Pay Merchants",
  string_third: "Book Events Tickets",
  string_forth: "Instant Recharge Anytime",
  content_first:
    "Use  Pay to send, receive payments for your online transactions",
  content_second: "Pay using “scan and pay” feature within fraction of seconds",
  content_third: "You can book tickets for the events posted by merchants",
  content_forth:
    "Instant transaction, bill payment is possible at anytime &amp; anywhere",

  // GetStarted Screen-->
  LOGIN: "LOGIN",
  REGISTER: "Register",
  MobileNumber: "Mobile Number",

  // Signup Screen-->
  LoginDes:
    "Login into  to enjoy a seamless payment \n experience with awesome offers!",
  EnterValidNumber: "Please enter a valid number",

  Email: "Email id",
  SelectYourCountry: "Select your country",
  ReferCode: "Refer Code",
  Password: "Password",
  EnterFirstName: "Please enter a first name",
  EnterLastName: "Please enter a last name",
  EnterValidEmail: "Please enter a valid email",
  EnterNumber: "Please enter a number",
  EnterCountry: "Please select a country",
  EnterPassword: "Please enter a password",
  ResetPasswordText: "Password should have a minimum of 6 characters.",
  UpdatePlayService: "Please update your google play service!!!",
  Ok: "Ok",
  Cancel: "Cancel",
  ForgotPassword: "Forgot Password?",
  EnterMobileNumber: "Please enter a mobile number",
  OTPsent: "OTP sent successfully in your registered number and email",
  LogoutForOtherDevice: "Logout for other device",
  AlreadyYouLoggedIn:
    "Already you logged-in another device, so please click ok to continue this device.",
  AreYouSureWantToExit: "Are you sure want to exit",
  SCANQRCODE: "SCAN QR CODE",
  FINDNEARBY: "FIND NEAR BY",
  PAYNOW: "PAY NOW",
  RECEIVE: "RECEIVE",
  Yourbeneficiarylistisempty: "Your beneficiary list is empty",
  SendMoney: "Send Money",
  PleaseEnterNewPin: "Please enter a new pin",
  PleaseEnterOTP: "Please enter a OTP",
  PinChanged: "Security pin changed successfully",
  CheckregesteredEmail:
    "Check OTP on your registered mobile number or regestered email.",
  Youget: "You get",
  RewardPointsAfterPaymentSuccess: "reward points after payment success",
  ResetPassword: "RESET PASSWORD",
  ResetSecurityPin: "RESET SECURITY PIN",
  NewPassword: "New Password",
  NewSecurityPin: "New security pin",
  OldPassword: "Old Password",
  OTP: "OTP",
  CheckOTP: "Check OTP on your registered mobile number or regestered email.",
  ENTERNEWPASSWORD: "Please enter a new password",
  ENTEROTP: "Please enter a OTP",
  ENTERVALIDOTP: "Please enter a valid OTP",
  TouchIDPasscode: "Touch ID or Enter security pin",
  EnterPin: "Please enter your security pin",
  CreatePin: "Create your Security Pin",
  SecureAccess: "This code will secure access to your",
  PersonalData: "personal data",
  ForgotPin: "FORGOT PIN?",
  Reenter: "Please re-enter a correct security pin",
  ConfirmSecurityPin: "Confirm your security pin",
  NewPin: "Create your new security pin",
  OldPin: "Please enter your old security pin",
  OTPsentsuccessfully: "OTP sent successfully",
  Delete: "Delete",
  OTPVerification: "OTP VERIFICATION",
  OTPBanner: "Sit back &amp; Relax! while we verify your \n mobile number",
  OtpToast:
    "( Enter the OTP below in case if we fail to detect the \n SMS automatically )",
  Home: "Home",
  Nearby: "Stores",
  Feeds: "Feeds",
  Events: "Events",
  More: "More",
  RPay: "RPay",
  BalanceDetails: "R Points>",
  ADDMONEY: "ADD MONEY",
  RecentTransaction: "Recent Transaction",
  Send: "Send",
  Offers: "Offers",
  BILLPAYMENT: "BILL PAYMENTS",
  TRANSACTIONS: "TRANSACTIONS",
  REFERRAL: "REFERRAL",
  REWARDS: "REWARDS",
  BillPayment: "Bill Payments",
  ComingSoon: "Coming Soon...",
  Recharge: "Recharge",
  YouRecentRewardsWillAppearHere: "You recent rewards \n will appear here",
  RechargeBillPaymentsampmore: "Recharge, Bill Payments &amp; more",
  Earn200morepointstoredeemalenovaLaptop:
    "Earn 200 more points to redeem a lenova Laptop",
  pleaseenteraamount: "Please enter a amount",
  pleaseenteravalidamount: "Please enter a valid amount",
  Thisdeviceisnotsupported: "This device is not supported.",
  Sessionexpiredpleaselogintocontinue:
    "Session expired, please login to continue",
  DealsNearbyyou: "Deals Nearby you",
  Mywallet: "My Wallet",
  MyAccount: "My Profile",
  MyAccountDes: "Manage your profile information here.",
  MyWalletDes: "TopUp your wallet from your credit card.",
  Transaction: "Transaction History",
  MyVoucherCode: "My Coupon Code",
  TransactionDes: "Your past transactions on  app and other merchants.",
  MoneyRequest: "Money Request",
  MoneyRequestDes: "You can request money from other customer.",
  MyVoucherCodeDes: "Your voucher code information here.",
  EventBooking: "Event Booking History",
  EventBookingDes: "Your recent booking history on  app and other merchants.",
  Popcoins: "Rewards",
  PopcoinsDes: "Earn coins from merchant, Get benefits from .",
  MyContacts: "My Beneficiaries",
  AddBeneficiary: "Add Beneficiary",
  MyContactsDes: "Add all your beneficiary information here.",
  ReferAndEarn: "Refer &amp; Earn",
  ReferAndEarnDes: "Refer your friends and earn.",
  Bussinesaccount: "Register as business account",
  BussinesaccountDes: "Your past transactions on  app and other merchants.",
  Rfid: "Smart Card",
  RfidDes: "Your own Smart Card.",
  Settings: "Settings",
  SettingsDes: "Make all your settings.",
  LogoutDes: "Logout your account safely.",
  Remove: "Remove",
  Alert: "Alert!!!",
  AreYouSureWantToDelete: "Are you sure want to delete?",
  BookingDetails: "Booking Details",
  BookingSuccess: "Booking Success",
  YourSavedBankAccountsAppearHere:
    "Your saved bank accounts \n will appear here",
  SearchCountryName: "Search country by Name",
  NoCountryFound: "No country found",
  YourSavedCardsAppearHere: "Your saved cards \n will appear here",
  AccountHolderName: "Account Holder Name",
  BankName: "Bank Name",
  Add: "Add",
  UseFingerPrint: "Use FingerPrint",
  Shops: "Shops",
  Seller: "Agents",
  Search: "Search by store name",
  SearchFeed: "Search by store name or offer name",
  Navigate: "Navigate",
  Reward: "Rewards",
  OFFER: "Offer",
  ShopOffer: "Shop Offer",
  Nostoresfound: "No stores found",
  Nosellerssfound: "No sellers found",
  Searchbyagentsname: "Search by agents name",
  Actions: "Actions",
  LanguageSelection: "Language",
  NotificationBlock: "Notification Block",
  ChangePin: "Change Security Pin",
  Changepassword: "Change password",
  English: "English",
  Arabic: "Arabic",
  Portuguese: "Portuguese",
  Hindi: "Hindi",
  Profile: "Profile",
  MyProfile: "My Profile",
  ShowQR: "Show this QR Code to accept payments",
  QRcodeAbove: "QR code above is your personal  ID",
  EditProfile: "Edit Profile",
  UpdateKYC: "Update KYC",
  SavedCards: "Saved Cards",
  BankAccounts: "Bank Accounts",
  Update: "Update",
  ShareReferral: "Share Referral",
  ViewReferral: "View Referral",
  YourReferralCode: "Your referral code",
  ShareLink: "Share via web Link",
  Copy: "Copy",
  FirstText: "Invite your friend to , you get",
  SecondText: "after your friend registered with us.",
  ThirdText: "Your friend will also get",
  ReferFriend: "Refer a Friend",
  BeneficiaryEmpty: "Your beneficiary list is empty",
  TransactionHistory: "Transaction History",
  All: "All",
  Paid: "Paid",
  Received: "Received",
  Topup: "Topup",
  Refund: "Refund",
  BonusCredited: "Bonus Credited",
  Creditwallet: "Credit to wallet",
  AmountTransferredTo: "Amount Transferred To",
  Failed: "Failed",
  Success: "Success",
  RecentTransactionAppearHere: "Your recent transaction \n will appear here",
  WithdrawnTransactions: "Withdrawn Transactions",
  WithdrawnFee: "Withdrawn Fee",
  CreditedToBank: "Credited to Bank",
  Transactedon: "Transacted on",
  OrderId: "Txn ID",
  MyTransactions: "My Transactions",
  YouHaveEarned: "You have earned",
  PointsReduced: "Points reduced",
  Offer: "Offer",
  EarnedPoints: "Earned Points",
  Congratulation: "Congratulation",
  Need: "Need",
  MorePointsFor: "more points for",
  off: "off",
  YouEligibleFor: "You're eligible for",
  OffOnYouNextPurchase: "off on you next purchase",
  Points: "Points",
  Entermobilenumbertopay: "Enter mobile number to pay",
  OR: "OR",
  Entermobilenumbertorequest: "Enter mobile number to request",
  ConfirmDetails: "Confirm your details to Proceed",
  Description: "Description : ",
  Requestnow: "Request Now",
  ServiceFee: "Service Fee",
  PopPayFee: " Fee",
  TotalPayable: "Total Payable",
  Tip: "Tips",
  RewardOffer: "Reward Offer",
  Total: "Total",
  TransactionDetail: "Transaction Detail",
  RefundAmount: "Refund Amount",
  RefundType: "Refund Type",
  RefundDate: "Refund Date",
  Name: "Name",
  PayNow: "Pay Now",
  Submit: "Submit",
  Family: "Family",
  Business: "Business",
  Totalavailablecoins: "Total available rewards",
  PopCoinHistory: "Rewards History",
  SENDREWARDS: "SEND REWARDS",
  add_via_credit_debit: "Add Via Credit/Debit card",
  PaidSuccess: "Paid Successfully",
  For: "FOR",
  MyWallet: "My Wallet",
  Withdrawn: "Withdrawn",
  Availablebalance: "Available Balance",
  AddMoney: "AddMoney",
  Withdraw: "Withdraw",
  EnterAmount: "Enter Amount",
  Creditdebit: "CREDIT/DEBIT",
  SaveCards: "SAVE CARDS",
  mmyy: "MM/YY",
  EnteredAmount: "Entered Amount",
  CreditedtoBank: "Credited to Bank",
  Withdrawfee: "Withdrawn Fee",
  SelectBankAccount: "Select Bank Account",
  AddBankAccount: "ADD A NEW BANK ACCOUNT",
  ProceedToPay: "PROCEED TO PAY",
  Tappay: "Tap to select card for pay",
  Westoreyourcard: "We store your card information in payment gateway server",
  Change: "CHANGE",
  Notallowed: "Not allowed",
  PleaseEnterAmount: "Please enter a amount",
  PleaseEnterValidAmount: "Please enter a valid amount",
  PleaseAddBankAccount: "Please add a new bank account",
  PleaseEnterMinimumAmount: "Please enter a minimum amount",

  PaidSuccessfully: "Paid Successfully",
  UnauthorizedAccess: "Unauthorized access",
  PleaseEnterValidCardDetails: "Please enter a valid card details",
  AboutEvent: "About Event",
  Organizers: "Organizers",
  TermsCond: "Terms &amp; Conditions",
  BOOK: "BOOK",
  YourRecentEventsAppearHere: "Your recent events list\n will appear here",
  CurrentlyEventList: "Currently no events in list",
  EventDescription: "Event Description",
  BusinessName: "Business Name",
  ReviewBooking: "Review Booking",
  TicketDetails: "Ticket Details",
  NoTickets: "No.Tickets",
  DateTime: "Date &amp; Time",
  Subtotal: "Subtotal",
  Servicetax: "Service tax",
  ConfirmationMessage: "Confirmation message will send to",
  YourRecentTransactionWillAppearHere:
    "Your recent transaction \n will appear here",
  RequestReceivedFrom: "Request received from",
  RequestSentTo: "Request sent to",
  RejectRequest: "Reject Request",
  SENDMONEY: "SEND MONEY",
  FROM: "FROM",
  EventName: "Event Name",
  TicketBookedSuccessfully: "Ticket Booked Successfully",
  Venu: "Venu",
  Date: "Date",
  TicketType: "Ticket Type",
  Time: "Time",
  Price: "Price",
  BookingID: "Booking ID",
  VERIFIED: "Verified",
  SelectDate: "Select Date",
  SelectTime: "Select Time",
  EnterOldPassword: "Please enter a old password",
  EnterNewPassword: "Please enter a new password",
  NoEventfound: "No Event found",
  ok: "OK",
  no_camera_permission:
    "This application cannot run because it does not have the camera permission.  The application will now exit.",
  low_storage_error:
    "Face detector dependencies cannot be downloaded due to low device storage",
  BookingHistory: "Booking History",
  UpComingEvents: "UpComing Events",
  CompletedEvents: "Completed Events",
  Onwards: "Onwards",
  PleaseEnterCardNumber: "Please enter card number",
  PleaseEnterexpiryMonth: "Please enter expiry month",
  PleaseEnterCvvnumber: "Please enter CVV number",
  PleaseEnterValidCvvnumber: "Please enter valid CVV number",
  Appblock: "App Status",
  Apply: "Apply",
  Share: "Share",
  SharePost: "Share this post in social media",
  HidePost: "Hide this post",
  IDontWantPost: "I don't want to see this post in my feed",
  Unfollowtheshop: "Unfollow the shop",
  StopSeeingFeedsShop: "Stop seeing feeds from this shop",
  History: "History",
  RECENTPAYMENT: "RECENT PAYMENT",
  NoInternetConnection: "No Internet Connection!!!",
  Locationisdisabled: "Location is disabled",
  LocationisdisabledDEC: "Location service is disabled please enable location",
  Permissionnecessary: "Permission necessary",
  ExternalStoragePermissionNecessary:
    "External storage permission is necessary",
  CameraPermissionNecessary: "Camera permission is necessary",
  Pleaseselectyouroperator: "Please select your operator",
  pleasechooseanothernumber: "please choose another number",
  Prepaid: "Prepaid",
  Postpaid: "Postpaid",
  SelectOperator: "Select Operator",
  Insufficientwalletbalance: "Insufficient wallet balance",
  pleaseselectadate: "please select a date",
  pleaseselectatime: "please select a time",
  InsufficientRewards: "Insufficient Rewards",
  Noeventsfound: "No events found",
  MoneyloadtowalletSuccessfully: "Money load to wallet Successfully",
  Version: "Version-",
  voucher_purchased: "Voucher Purchased",

  buynow: "Buy Now",
  add_friend_via_google_facebook: "Add Friend Via Google/Facebook",
  EnterFatherName: "Please enter father's name",
  EnterGFatherName: "Please enter grand father's name",
  less_balance: "You don't have sufficient balance!!!",
  add_new_account: "Add New Account",
  national_number: "National Number",
  type_of_relationship: "Type of relationship",
  enter_nationality: "Please enter nationality",
  enter_national_number: "Please enter national number",
  my_family: "My Family",
  nearby: "Nearby",
  my_cards: "My Cards",
  request_money: "Request Money",
  taxi: "Taxi",
  shop: "Shop",
  my_friends: "My Friends",
  offers: "Offers",
  location_is_disable: "Location is disabled",
  Location_service_is_disabled_please_enable_location:
    "Location service is disabled please enable location",
  sure_you_want_to_delete_account: "Are you sure want to delete card?",
  recommended_items: "Recommended Items",
  cs_cart: "Cs Cart",
  add_to_wishlist: "Add To Wishlist",

  price_details: "Price Details",

  continue_shopping: "<u>Continue Shopping</u>",
  check_out: "Check Out",
  user_account: "User Account",
  add_new_beneficiary: "Add New Beneficiary",
  or_via_other_services: "Or Via Other Services",
  recents_transtion: "Recents Transtion",
  see_all: "See all",
  see_all_line: "<u>See all</u>",
  no_recent_transtions: "No Recent Transactions",
  family_users: "Family Users",
  saved_cards: "SAVED CARDS",
  linked_bank_accounts: "Linked Bank Accounts",
  change_mobile_number: "Change Mobile Number",
  change_pin_code: "Change Pin Code",

  no_record_found: "No records found",
  rating_reviews: "<![CDATA[Rating & Reviews]]>",

  permission_msg:
    "Without this permission the app is unable to works. Please go to settings page and give the permission to access this application.",
  google_mapkey: "AIzaSyA-NYDFiyT529mQXofJhGnQGmm5XTNunTs",
  more: "More",
  currencySymbol: "LYD",
  purchase_online_vouchers: "Purchase online vouchers",

  hidden_companies: "Hidden Vouchers Companies",
  hide_seller_message: "Would you like to unhide this Voucher seller?",
  hidesellermessage: "Would you like to hide this Voucher seller?",
  hidegiftsellermessage: "Would you like to hide this Gifts Cards seller?",
  unhide_seller_message: "Would you like to unhide this Gifts Cards seller?",
  unhide: "Unhide",

  cancel: "Cancel",

  transids: "Transaction ID :",
  currencySymbolDollar: "$",

  search_here: "Search here",

  purchased_vouchers: "Purchased Vouchers",

  p_gift_cards_history: "Purchased Gift Cards",

  close: "Close",
  apply: "Apply",
  service_providers: "Service providers",
  start_date: "Start date",
  end_date: "End date",
  price: "Price",
  topup_received: "Topup Received",
  topup_sent: "Topup Sent",

  hidden_gift_cards_companies: "Hidden Gift Cards Companies",
  hidden_vouchers_companies: "Hidden Vouchers Companies",
  mother_name: "Mother Name",
  sur_name: "Surname",
  father_middle_name: "Father/Middle Name",
  full_name: "Full Name",
  sex: "Sex",
  social_status: "Social status",
  number_of_family_members: "Number of family members",
  place_of_issue: "Place of issue",
  date_of_iisue: "Date of issue",
  expiration_date: "Expiration Date",
  address_amp_contact_info: "<![CDATA[Address & Contact Info]]>",
  save_amp_next: "<![CDATA[Save & Next]]>",

  an_identification_document: "An Identification Document",
  id_card: "ID Card",
  passport: "Passport",
  registration_no: "Registration No.",
  window: "Widow",
  single: "Single",
  divorced: "Divorced",
  married: "Married",
  region: "Region",
  phone_no: "Phone No.",
  current_employer_detail: "Current Employer Detail",
  employer_name: "Employer Name",
  employer_phone_no: "Employer Phone No",
  job_title: "Job Title",
  employer_address: "Employer Address",
  working_years: "Working Years",
  give_us_your_identification_document_detail:
    "Give us your Identification Document Detail",
  review_comment: "Review Comment",
  place_order: "Place Order",
  linked_accounts: "Linked Accounts",
  add_new_link_account: "Add New Link Account",
  clear: "Clear",
  account_balance: "Account balance",
  bank_account_number: "Bank Account Number",
  reset: "RESET",
  share_code: "SHARE CODE",
  whatsapp_not_installed: "Whatsapp not Installed!",
  add_money: "Add Money",
  saving_account: "Saving Account",
  proceed: "Proceed",
  enter_mobile_number_to_receive_payment:
    "Enter mobile number to receive payment",
  direct_transfer: "Direct Transfer",
  current_balance: "Current balance",
  see_all_transaction: "See All Transaction",
  withdraw_money: "Withdraw Money",
  load_money: "Load money",
  payment: "Payment",
  enter_description: "Enter Description",
  sent: "Sent",
  make_new_request: "Make New Request",
  approve: "Approve",
  decline: "Decline",
  ask_date_of_return: "Ask Date Of Return",
  your_recent_coupon_code_n_will_appear_here:
    "Your recent coupon code \n will appear here",
  enter_account_number: "Enter account number",
  account: "Account",
  enter_nick_name: "Enter nick name",
  nick_name: "Nick name",
  create_account: "Create Account",

  add_linked_account: "Add Linked Account",
  privacy_policies: "<![CDATA[Privacy & Policies]]>",
  account_settings: "Account Settings",
  contact_us: "Contact Us",
  agree_terms_and_conditions: "Agree Terms and Conditions",
  father_s_name: "Father's Name",
  mother_s_name: "Mother's Name",
  financial: "Financial",
  document: "Document",
  district: "District",
  secondary_contact_number: "Secondary Contact Number",

  job: "Job",
  please_attach_following_documents: "Please attach following documents",
  edit: "Edit",
  user_no: "User No",
  user_detail: "User Detail",
  update_contact_number: "Update Contact Number",
  preferred_language: "Preferred Language",
  change_pin: "Change PIN",
  kyc_information: "KYC Information",
  cbl_information: "CBL Information",
  my_documents: "My Documents",
  preferred_currency: "Preferred Currency",
  omar: "OMAR",
  faq: "FAQ",
  complete_profile: "Complete Profile",
  filter_by: "Filter by",
  received: "Received",
  proceed_to_pay: "Proceed to pay",
  pay_to: "Pay to",
  selected_address: "Selected Address",
  mark_as_delivery_address: "Mark as delivery address",
  save_address: "Save Address",
  name: "Name",
  bank_account: "Bank Account",
  credit_card: "Credit Card",
  qr_code: "QR Code",
  nfc: "NFC",
  add_new_address: "Add New Address",
  sort_by: "Sort by",
  type: "Type",
  expenses: "Expenses",
  by_day: "By Day",
  last_3_month: "last 3 month",
  under_development_nchart: "Under Development\nChart",
  total_expenses: "Total Expenses",
  food_amp_dinning: "<![CDATA[Food&Dinning]]>",
  rent_household_bills: "Rent/Household bills",
  shopping: "Shopping",
  travel: "Travel",
  add_using_qr_code: "Add Using QR Code",
  add_using_information: "Add Using Information",
  select_address: "Select Address",
  enter_your_number_to_update: "Enter your number to update.",
  choose_payment_method: "Choose payment method",
  sub_total_col: "Sub Total:",
  lyd_13: "LYD 13",
  taxes_col: "Taxes:",
  total: "Total",
  payment_through_wallet: "Payment through wallet",
  cash_on_delivery: "Cash on delivery",
  change_mode_to_online: "Change mode to online",
  change_mode_to_offline: "Change mode to offline",
  events_by_tizdan_customer: "Events By Tizdan Customer",
  name_surname: "Name Surname",
  block_report: "Block/Report",
  gift_card_type: "Gift card Type",
  amazon_pay_email_gift_card: "Amazon pay Email Gift Card",
  message: "message",
  now: "Now",
  price_of_product_lyd_10: "Price of Product : LYD 10",
  xs: "xs",
  xl: "xl",
  product_quantity: "Product Quantity",
  product_description: "Product Description",
  add_to_cart: "Add to cart",
  title_of_item: "Title of item",
  size: "Size",
  color: "Color",
  varients: "Varients",
  product_detail: "Product Detail",
  rate_product: "Rate Product",
  q: "Q",
  are_you_the_registered_head_of_family:
    "Are you the registered head of family?",
  edit_answer: "Edit Answer",
  would_you_like_to_your_family_grant_to_be_processed_through_tizdan:
    "Would you like to your Family Grant to be processed through Tizdan?",
  would_you_like_to_apply_for_a_individual_cbl:
    "Would you like to apply for a Individual CBL?",
  expired: "Expired",
  beam_data: "Beam Data",
  received_data: "Received Data",
  current_balance_lyd_0: "Current balance: LYD 0",
  view_purchased_voucher_codes: "View Purchased Voucher Codes",
  view_purchased_gift_cards: "View Purchased Gift Cards",
  view_my_offline_vouchers: "View my offline vouchers",
  request_to_st: "Request To",
  date_of_return: "Date of Return",
  member_s_of_android: "Member's of Android",
  send_money: "Send Money",
  pay_bill: "Pay Bill",
  split_bill: "Split bill",
  sms: "SMS",
  total_payable: "Total Payable",
  all_payment_options: "All Payment Options",
  tizdan_wallet: "Tizdan Wallet",
  debit_card: "Debit Card",
  net_banking: "Net Banking",
  contiune: "Contiune",
  cascade_store: "cascade store",
  cart: "Cart",
  wishlist: "Wishlist",
  tizdan: "TIZDAN",
  ticket_name: "Ticket Name",
  science_olympiad_events_are_balanced_among_the_three_broad_areas_of_the_science_standards:
    "Science Olympiad Events are balanced among the three broad areas of the Science Standards.",
  no_image: "NO IMAGE",
  place_name: "Place Name",
  back_to_home: "Back to Home",
  enter_your_smart_card_number_to_activate:
    "Enter your Smart Card number to Activate",
  why_i_need_s_smart_card: "Why i need s Smart Card?",
  enter_card_number: "Enter card Number",
  activate_now: "Activate Now",
  your_card_number: "Your Card Number",
  activated_on: "Activated on",
  expires_on: "Expires on",
  top_offers: "Top Offers",
  gender: "Gender",
  location: "Location",
  add_location: "Add Location",
  shop_name: "Shop Name",
  events: "Events",
  roadster_women_sneakers: "Roadster Women Sneakers",
  status_col: "STATUS :",
  it_s_important_that_a_:
    "It’s important that a product description writer is honest and transparent when describing the product. Saying a product is free when it really isn’t is deceptive. Saying your product is of the highest quality when you have countless complaints about the product quality isn’t going to trick people into liking your product.",
  delivered: "Delivered",
  order_id: "Order Id",
  invoice_no_dot: "Invoice no.",
  order_date: "Order Date",
  delivered_date: "Delivered Date",
  shipping_address: "Shipping Address",
  contact_to_our_support_dot: "Contact to our Support.",
  need_help: "Need Help?",
  give_a_review: "Give a Review",
  delivery_fee: "Delivery Fee",
  tax_amp_charges: "<![CDATA[Tax & Charges]]>",
  discount: "Discount",
  sub_total: "Sub Total",
  view: "View",
  rate: "Rate",
  save_rating: "Save Rating",
  select_city: "Select city",
  same_as_shipping_info: "Same as Shipping Info",
  add_shipping_info: "Add Shipping Info",
  add_notes: "Add Notes",
  add_change_address: "Add / Change Address",
  add_billing_info: "Add Billing Info",
  coupon_code: "Coupon Code",
  print_voucher: "Print Voucher",
  thank_you_for_your_purchase: "Thank you for your Purchase",
  thank_you: "Thank you",
  categories: "Categories",
  featured_products: "Featured Products",
  deals_for_you: "Deals for You",
  add_fund: "Add Fund",
  balance: "Balance",
  receiver_number: "Receiver number",
  number: "Number",
  transaction_fee: "Transaction Fee",
  money_sent_successfully: "Money sent successfully",
  lyd: "LYD",
  complete: "Complete",
  have_an_issue: "Have an Issue?",
  contact_to_support_dot: "Contact to Support.",
  contact_to_support_on_phone_call_n_218915004222:
    "Contact to Support on phone call\n+218915004222",
  tizdan_customer_users: "Tizdan Customer Users",
  no_tizdan_customer_user_found: "No Tizdan Customer User Found",
  no_family_user_found: "No Family User Found",
  new_trip: "New Trip",
  my_bookings: "My Bookings",
  flight: "Flight",
  bus: "Bus",
  car: "Car",
  one_way: "One Way",
  round_trip: "Round Trip",
  from: "FROM",
  to: "To",
  date: "Date",
  when: "When",
  passengers: "Passengers",
  economy: "Economy",
  _1_adult: "1 Adult",
  customer_s_personal_data: "Customer's personal data",
  grandfather_name: "Grandfather Name",
  full_name_in_english_will_be_written_on_visa_card:
    "Full Name in English - Will Be Written on Visa Card",
  back: "Back",
  current_employer: "Current Employer",
  employer_info: "Employer Info",
  for_your_purchase_n_nbelow_is_the_voucher_code_for_your_future_reference:
    "For your purchase\n\nBelow is the voucher code for your future reference:",
  done: "Done",
  coming_soon: "Coming Soon...",
  ttd: "TTD $",
  per_month_you_can_add_upto_n_ttd_20000_in_your_wallet_why:
    "Per month you can add upto \n TTD $20000 in your wallet why?",
  visa: "Visa",
  add_beneficiary_using_information: "Add beneficiary using information",
  eb: "EB",
  dth: "DTH",
  service: "SERVICE",
  service_number: "Service Number",
  oyopay_fee: "OYOPAY Fee",
  ticket: "Ticket",
  step_1_question_1: "Step 1 - Question 1",
  step_1_question_2: "Step 1 - Question 2",
  step_1_question_3: "Step 1 - Question 3",
  top: "Top",
  full_sleeve_top: "Full sleeve top",
  merchant_name: "Merchant Name",
  selected_customer_didn_t_have_rpay_account:
    "Selected customer didn't have RPay account",
  payment_details: "Payment Details",
  actual_cost: "Actual Cost",
  choose_for_a_contact: "Choose for a contact",
  drawing_competition: "Drawing Competition",
  inter_city: "Inter-City #",
  _80_people_intrested: "80+ people intrested",
  complex: "Complex",
  seller_name: "Seller Name",
  book_festival: "Book Festival",
  are_you_looking_for_a_children_s_book_that_is_highly_entertaining:
    "Are you looking for a children's book that is highly entertaining",
  paid_for_shop: "Paid for Shop",
  earned: "Earned",
  cancel_request: "Cancel request",
  are_you_sure_to_cancel_the_request: "Are you sure to cancel the request?",
  take_a_photo: "Take a photo",
  choose_a_photo_from_the_gallery: "Choose a photo from the gallery",
  confirm_transfer: "Confirm Transfer",
  please_confirm_transfer_details_and_amount:
    "Please confirm transfer details and amount?",
  receiver_phone_number: "Receiver Phone Number",
  new_update_available: "New update available!!!",
  update_now: "Update Now",
  scratch_your_card: "Scratch your card",
  are_you_sure_want_to_remove_sub_user: "Are you sure want to remove sub user?",
  paid_to_ola: "Paid to ola",
  user_name: "User name",
  store_name: "Store Name",
  it_s_important_that_a_product_description_:
    "It’s important that a product description writer is honest and transparent when describing the product. Saying a product is free when it really isn’t is deceptive. Saying your product is of the highest quality when you have countless complaints about the product quality isn’t going to trick people into liking your product.",
  manikandan_mn: "Manikandan.MN",
  persad_food_stores_ltd: "Persad Food Stores Ltd",
  your_feed_list_is_empty: "Your Feed list is empty",
  verifying_your_identity: "Verifying your identity",
  using_fingerprint: "Using Fingerprint?",
  redeem_now: "Redeem now",
  my_beneficiary: "My Beneficiary",
  logout: "Logout",
  customer_comments: "Customer comments",
  success: "SUCCESS",
  refund_details: "Refund Details :",
  refund_status: "Refund Status",
  analyzer_report: "Analyzer Report",
  orders: "Orders",
  scan: "Scan",
  support: "Support",
  tizdan_customer_gift_and_user: "Tizdan Customer gift and user",
  on_map: "On Map",
  dob_col: "DOB:",
  phone_col: "Phone:",
  email_col: "Email:",
  history: "History",
  extra_fee: "Extra Fee",
  your_saved_card_will_appear_here: "Your saved card will appear here",
  reedem_points: "Reedem Points",
  coupon: "Coupon",
  foloosi_fee: "Foloosi Fee",
  tip: "Tip",
  add_tips: "(+Add Tips)",
  you_get_50_reward_points_after_payment_success:
    "You get 50 reward points after payment success",
  pay_for: "Pay for",
  aed: "AED",
  you_recent_transaction_n_will_appere_here:
    "You recent transaction \n will appere here",
  category_name: "Category Name",
  cart_total: "Cart Total",
  cart_discount: "Cart Discount",
  order_total: "Order Total",
  delivery_charges: "Delivery Charges",
  get_upto_200_cashback: "Get upto 200% Cashback",
  there_is_a_little_bit_of_twist_:
    "There is a little bit of twist in this offer. They offer 100% cashback but in a instalment",
  recharge: "Recharge",
  super_recharge: "Super Recharge",
  flash_sale_save_50_on_recharges_amp_bill_payments:
    "<![CDATA[Flash Sale : Save 50% on Recharges & Bill payments]]>",
  get_code_to_scratch_this: "Get Code to Scratch this!!",
  code: "Code",
  flash: "FLASH",
  promotion_details: "Promotion Details",
  get_code: "GET CODE",
  points_available: "Points Available",
  inter_city_startup: "Inter-City # Startup",
  port_of_trinidad: "Port-of-Trinidad",
  need_50_more_points_for_25_off: "Need 50 more points for 25% off",
  events_for_gather_for_marchants: "Events for Gather for Marchants",
  at_square_ground_california: "At Square Ground, California",
  event_cinema: "Event Cinema",
  participants: "Participants",
  yesterday: "Yesterday",
  all_categories: "All Categories",
  change_amount: "Change Amount",
  get_directions: "Get Directions",
  class_: "Class",
  recharge_processing: "Recharge Processing",
  card: "Card",
  relation_to_account_holder: "Relation To Account Holder",
  from_date: "From date",
  to_date: "To date",
  search: "Search",
  type_phone_number: "Type phone number",
  address_1: "Address 1",
  address_2: "Address 2",
  state: "State",
  postal_code: "Postal Code",
  country: "Country",
  enter_voucher_code: "Enter voucher code",
  enter_address: "Enter address",
  enter_your_first_name: "Enter your First Name",
  enter_father_middle_name: "Enter Father / Middle Name",
  enter_grandfather_name: "Enter Grandfather Name",
  enter_surname: "Enter Surname",
  enter_mother_name: "Enter Mother Name",
  enter_full_name_in_english: "Enter Full Name in English",
  current_employer_name: "Current Employer Name",
  the_number_of_years_of_work_in_your_current_emplyoment:
    "The number of years of work in your current Emplyoment",
  the_number_of_years_of_work_in_your_current_emplyoment_business:
    "The number of years of work in your current Employer/Business ",
  current_employer_address: "Current Employer Address",
  employer_phone_number: "Employer Phone Number",
  search_event_by_name_place: "Search Event by name, place",
  what_this_for: "What this for",
  search_by_name: "Search by name",
  business_employer_name: "Business/Employer Name",
  average_monthly_income: "Average Monthly Income",
  total_experience: "Total Experience",
  reason_for_cancel: "Reason for cancel",
  phone_no_dot: "Phone No.",
  search_by_country_name_code: "Search by country name, code",
  enter_4_digit_pin: "Enter 4-digit PIN",
  re_enter_4_digit_pin: "Re-enter 4-digit PIN",
  please_enter_your_bank_account: "Please enter a bank name",
  please_enter_name: "Please enter your name",
  please_enter_a_account_holder_name: "Please enter a account holder name",
  please_enter_a_account_number: "Please enter a account number",
  please_enter_first_name: "Please enter first name",
  password_should_be_at_least: "Password should be at least of 6 characters",
  please_enter_valid_password: "Please enter valid password",
  pin_code_mismatch: "Pin code mismatch",
  password_mismatch_reenter_password: "Password  mismatch Re-enter password",
  please_enter_valid_referral_code: "Please enter valid referral code",
  please_enter_father_middle_name: "Please enter father/middle name",
  please_enter_grandfather_name: "Please enter grandfather name",
  please_enter_surname: "Please enter surname",
  please_enter_mother_name: "Please enter mother name",
  please_enter_full_name: "Please enter full name",
  please_select_gender: "Please select gender",
  please_enter_id: "Please enter ID",
  please_enter_place_name_of_issuing_document:
    "Please enter place name of Issuing document",
  please_enter_issue_date: "Please enter issue date",
  please_expire_date: "Please enter expire date",
  please_select_martial_status: "Please select martial status",
  please_enter_family_members_count: "Please enter family members count",
  please_select_identificaion_document_type:
    "Please select identification document type",
  please_select_dob: "Please select DOB",
  enter_relationship_type: "Enter relationship type",
  please_enter_a_valid_number: "Please enter a valid number",
  please_select_date_of_return: "Please select date of return",
  please_enter_your_account_name: "Please enter your account name",
  please_enter_mobile_number: "Please enter your mobile number",
  you_cannot_add_yourself: "You cannot add yourself.",
  please_enter_beneficiary_name: "Please enter beneficiary name",
  please_enter_your_relation_with_benificiary:
    "Please enter your relation with beneficiary",
  please_enter_valid_4_digit_pin: "Please enter valid 4 digit pin",
  pin_mismatch: "Pin Mismatch",
  please_enter_your_name: "Please enter your name",
  you_cannot_add_from_yourself: "You cannot add from yourself",
  old_password_is_wrong: "Old password is wrong",
  please_make_any_changes: "Please make any changes",
  permission_caneled_now_your_application:
    "Permission canceled, now your application cannot access CAMERA.",
  failed_to_read_picture_data: "Failed to read picture data!",
  profile_uploaded_successfully: "Profile uploaded successfully!",
  please_enter_a_valid_card_details: "Please enter a valid card details",
  invalid_referral: '"Invalid referral "',
  please_enter_working_years: "Please enter working years",
  please_enter_job_title: "Please enter job title",
  please_enter_address: "Please enter address",
  please_select_the_question_1: "Please select the question 1",
  please_select_the_question_2: "Please select the question 2",
  please_select_the_question_3: "Please select the question 3",
  please_enter_the_first_name: "Please enter the first name",
  please_enter_the_father_middle_name: "Please enter the father/middle name",
  please_enter_the_grandfather_name: "Please enter the grandfather name",
  please_enter_the_surname: "Please enter the surname",
  please_enter_the_mother_name: "Please enter the mother name",
  please_enter_the_full_name: "Please enter the full name",
  please_select_the_gender: "Please select the gender",
  please_select_the_social_status: "Please select the socialStatus",
  please_enter_the_family_members: "Please enter the family members",
  please_enter_the_identification_number:
    "Please enter the identification number",
  please_select_expiration_date: "Please select expiration date",
  please_enter_the_passport_number: "Please enter the passport number",
  please_enter_the_national_number: "Please enter the national number",
  please_enter_the_registration_number: "Please enter the registration number",
  please_enter_the_place_of_issue: "Please enter the place of issue",
  please_select_date_of_issue: "Please select date of issue",
  please_enter_the_email_address: "Please enter the email address",
  please_enter_the_valid_email_address: "Please enter the valid email address",
  please_enter_the_phone_number: "Please enter the phone number",
  please_enter_valid_number: "Please enter valid phone number",
  please_enter_the_address: "Please enter the address",
  please_enter_the_employer_name: '"Please enter the employer name "',
  please_enter_the_employer_phone_number:
    "Please enter the employer phone number",
  please_enter_valid_phone_number: "Please enter valid phone number",
  please_enter_the_employer_address: "Please enter the employer address",
  please_enter_the_job_title: "Please enter the job title",
  please_enter_the_employer_working_year:
    "Please enter the employer working year",
  please_select_the_date_of_issue: "Please select the date of issue",
  please_select_the_expiry_date: "Please select the expiry date",
  expiry_date_must_be_after_the_issue:
    "Expiry date must be after the issue date",
  expiry_date_must_be_after: "Expiry date must be after the issue date",
  permission_canceled_now_your:
    "Permission canceled, now your application cannot access CAMERA.",
  item_added_to_wishlist: "Item added to wishlist",
  added_to_wishlist: "Added to Wishlist",
  item_removed_from_wishlist: "Item removed from wishlist",
  remove_from_wishlist: "Remove from Wishlist",
  proof_of_funds: "Proof of Funds",
  would_you_like_to_apply_for_cbl_number:
    "Would you like to apply \n for CBL Number",
  please_pick_the_date_of_birth: "Please Pick the Date of Birth",
  please_select_the_nationality: "Please Select the Nationality",
  file_should_not_greater_than_5_mb: "File should not greater than 5 mb",
  please_enter_the_street_number: "Please Enter the Street Number",
  please_select_the_country: "Please select the Country",
  please_select_the_city: "Please select the City",
  please_select_the_district: "Please select the District",
  please_enter_the_secondary_contact: "Please enter the Secondary Contact",
  please_enter_the_region: "Please enter the Region",
  please_select_the_source_of_icome: "Please select the Source of Income",
  please_select_the_role: "Please select the Role",
  please_enter_the_average_monthly_income:
    "Please enter the Average Monthly Income",
  please_select_the_currency: "Please select the Currency",
  please_enter_the_number_of_years_of_work:
    "Please enter the number of years of work in your current Employer",
  file_added_successfully: "File Added Successfully",
  years_of_experience_with_current_employer_business:
    "Years of Experience with Current Employer/Business?",
  please_enter_your_father_name: "Please enter your father name",
  please_enter_your_grand_father_name: "Please enter your grand father name",
  please_enter_your_mother_name: "Please enter your mother name",
  please_enter_business_name: "Please enter Business/Employee Name",
  please_enter_official_contact_no: "Please enter official contact no",
  please_enter_your_current_employee:
    "Please enter your Years of Experience with Current Employer/Business?",
  apply_cbl: "Apply CBL",
  go_to_cbl_requests: "Go to CBL Requests",
  exiting_cbl: "Existing  CBL",
  no_cbl_found: "No CBL found",
  cbl_number_col: "CBL Number:",
  bank_name_col: "Bank Name:",
  new_: "New",
  my_requests: "My Request",
  my_request: "My Request",
  request_new_cbl: "Request New CBL",
  kindly_attach_any_supporting_documents_if_any:
    "Kindly attach any supporting documents (If any)",
  applied_on_col: "Applied on:",
  cbl_request_no_col: "CBL Request No:",
  n_a: "N/A",
  add_document: "Add Document",
  insufficient_wallet_balance: "Insufficient wallet balance",
  please_enter_a_amount: "Please enter a amount",
  invalid_receiver_selected: "Invalid receiver selected",
  please_select_social_status: "Please select social status",
  please_give_the_rate_to_product: "Please give the rate to product",
  no_linked_account_available: "No linked account available",
  please_enter_account_number: "Please enter account number / phone number",
  please_enter_nick_name: "Please enter nick name",
  please_enter_discription: "Please enter description",
  please_select_your_address: "Please select your address",
  please_allow_the_location_permissions_from_setting:
    "please allow the location permissions from setting",
  please_enter_last_name: "Please enter last name",
  please_enter_city: "Please enter city",
  please_enter_state: "Please enter state",
  please_enter_postal_code: "Please enter postal code",
  please_enter_country: "Please enter country",
  please_select_end_date_greater_then_start_date:
    "Please select end date greater then start date",
  sub_user_does_not: "Sub user doesn't have any amount.",
  enter_withdraw_amount_is_greater_then:
    "Entered withdraw amount is greater then the balance this sub user.",
  please_select_amount: "Please select amount",
  this_company_does_not_any_gift: "This company does not have any gift cards",
  please_select_the_payment_method: "Please select the payment method",
  requested_updated_successfully: "Request updated successfully",
  status_updated: "Status updated",
  under_development: "Under development",
  please_phone_number: "Enter phone number",
  entered_amount_is_greater_then_the:
    "Entered amount is greater then the balance you have.",
  here_are_no_fingure_prints:
    "Here are no finger prints registered on this device",
  please_enter_valid_opt: "Please enter valid OTP",
  please_enter_a_correct_opt: "Please enter a correct otp",
  invalid_recipient_to_request: "Invalid recipient to request",
  invalid_ticket_to_verify: "Invalid ticket to verify",
  select_customer_did_not_have_account:
    "Selected customer didn't have RPay account",
  you_can_not_send_money: "You cannot send/request money from yourself",
  please_check_country_code: "Please check country code",
  please_choose_a_valid_number: "Please choose a valid number",
  sorry_you_cannot_follow_the_rpay:
    "Sorry, you can't un-follow the RPay admin feeds",
  no_feed_found: "No feed found",
  permission_canceled_now_your_application:
    "Permission canceled, now your application cannot access CONTACTS.",
  no_contact_found: "No contact found",
  please_choose_another_number: "Please choose another number",
  voucher_provider_set_hidden: "Voucher porvider set hidden successfully",
  card_deleted_successfully: "Card deleted successfully.",
  please_enter_smart_card_number: "Please enter smart card number",
  please_enter_id_number: "Please enter ID number",
  please_enter_place_of_issue: "Please enter place of issue",
  please_add_your_review: "Please add your review for the product",
  request_category_: "Request Category :",
  request_id_: "Request ID:",
  date_: "Date:",
  description_: "Description:",
  admin_comments: "Admin Comments:",
  my_wallet: "My Wallet",
  business_information: "Business Information",
  father_name: "Father Name",
  document_completed: "Document Completed",
  lyd_wallet: "LYD Wallet",
  available_balance_col: "Available Balance:",
  current_balance_col: "Current balance:",
  wallet_to_wallet_exchange: "Wallet to Wallet Exchange",
  create_new_wallet: "Create New Wallet",
  transaction: "Transaction",
  martial_status: "Marital Status",
  provide_basic_information_about_your_business:
    "Provide basic information about yourself",

  transaction_limits_info:
    "To set transaction limits provide the following info.",
  please_provide_address_detail: "Please provide address detail",
  zip_code: "Zip Code",
  contact_no: "Contact No",
  under_review: "Under Review",
  available_wallet: "Available Wallet",
  usd_wallet: "USD Wallet",
  exchange_from: "Exchange From",
  exchange_to: "Exchange To",
  submit_request: "Submit Request",
  your_request_successfully_submit: "Your Request Successfully Submitted",
  view_detail: "<u>View Detail</u>",
  slip_no: "Slip No",
  deposit_slip: "Deposit Slip",
  step_3: "Step 3",
  hide_detail: "<u>Hide Detail</u>",
  expiry_date: "Expiry Date",
  hello_blank_fragment: "Hello blank fragment",
  add_new_bank: "Add new Bank",
  bank_transfer: "Bank Transfer",
  please_provide_the_user_bank_account_details:
    "Please provide the user bank account details.",
  account_no: "Account No",
  transfer_from: "Transfer From",
  transfer_to_beneficiaries: "Transfer to Beneficiaries",
  receiver_name: "Receivers Name",
  receivers_account_no: "Receivers Account No",
  please_enter_your_4_digits_pin_code: "Please enter your 4 Digits PIN Code",
  forgot_pin: "<u>Forgot PIN?</u>",
  delete_underline: "<u>Delete</u>",
  my_cbl: "My CBL",

  please_provide_the_existing_cbl_details:
    "Please provide the Existing CBL details",
  please_enter_the_cbl_number: "Please Enter the CBL number",
  select_language: "Select Language",
  account_setting: "ACCOUNT SETTINGS",

  transation_history: "TRANSACTION HISTORY",
  analyzer_report_cap: "ANALYZER REPORT",
  orders_cap: "ORDERS",
  scan_cap: "SCAN",
  please_enter_the_account_number: "Please enter the account number",
  please_select_deposite_date: "Please select deposit Date",
  please_select_a_bank: "Please Select a Bank",
  please_upload_deposite_slip: "Please Upload deposit slip",
  wallet_is_not_available: "Wallet not available",
  congratulation_you_have_made_transaction_successfully:
    "Congratulations you have made transaction successfully",
  currency_is_the_same: "Currency is the same.",
  issue_date_should_be_smaller_than_expire_date:
    "Issue Date should be smaller than Expire Date",
  expire_date_should_greater_than_issue_date:
    "Expire Date should be greater than Issue Date",
  please_select_the_issue_date_first: "Please Select the Issue Date First",
  please_enter_zip_code: "Please Enter Zip Code",
  please_enter_your_current_experience: "Please Enter your current Experience",
  my_cbl_numbe: "My CBL Number",
  please_select_the_bank_that_you_have_deposited_the_top_up_amount:
    "Please select the bank that you have deposited the top up amount",
  selected_custome_didnt_have_tizdan_account:
    "Selected Customer didn't have Tizdan account",
  you_cannot_send: "You cannot send/request money from yourself",
  qr_pay_transaction: "QR Pay Transaction",
  fill_required_fields: "Fill all the required fields",
  family_members_detail: "Family Member Details",
  family_members_list: "Family Member List",
  view_transaction_limits: "View Transaction Limits",
  view_transaction_history: "View Transaction History",
  set_your_password: "Set your Password",
  please_enter_the_password: "Please Enter the Password",
  please_confirm_your_password: "Please Confirm your Password",
  top_up_money_limit: "Top Up Money Limit",
  withdraw_limit: "Withdraw Limit",
  customer_to_customer: "Customer to Customer",
  transfer_limit: "Transfer Limit",
  customer_to_merchant: "Customer to Merchant",
  limit_definition: "These limits are to be defined Monthly",
  note: "Note :",
  set_transaction_limits: "Set Transaction Limits",
  family_member_added_successfully: "Family member added successfully",
  alert: "Alert",
  are_you_sure_you_want_to_delete_this_member:
    "Are you sure you want to delete this Member",
  signup: "Signup",

  monthly_limits: "Monthly Limits",
  customer: "Customer",
  transfer_to_family: "Transfer to Family Members",
  enabled: "Enabled",
  disable: "Disable",
  no_wallets_available: "No Wallets Available!",
  family_member_wallet_view: "Family Member Wallet View",
  use_pay_to_send_receive_paymnets_for_your_online_transactions:
    "Use Pay to send, receive paymnets for your online transactions",
  resend: "Resend",
  create_pin: "Create PIN",
  to_increase_your_verification_level_complete_100_profile_now:
    "To increase your verification level complete 100% profile now.",
  no_beneficiaries_yet: "No Beneficiaries yet!",
  no_family_member_yet: "No Family Member Yet!",
  reason: "Reason :",
  update: "Update",
  transaction_not_availble: "Transaction is not available",
  enable_fingerprint: "Enable Fingerprint",
  failed: "Failed",
  no_kyc: "Please create a KYC request first",
  please_confirm_your_security_pin: "Confirm Your security Pin",
  events_and_offer_screen: "<![CDATA[Events & Offers screen]]>",
  manager: "Manager",
  item_detail: "Item Detail",
  view_code: "View Code",
  redeemed: "Redeemed",
  code_collin: '"Code : "',
  analyzer: "Analyzer",
  all_user: "All Users",
  omlah_users: "Omlah Users",
  all_transactions: "All Transactions",
  paid_to: "Paid to",
  select_a_Amount: "Select a Amount",
  pay_amount: "Pay Amount :",
  recharger_failed: "Recharge failed",
  card_code_colln: "Card code:",
  to_high: "to-high",
  nfc_payment: "Nfc Payment",
  to_low: "to-low",
  refunded_amount: "Refunded Amount",
  refund_amount: "Refund Amount",
  refund_type: "Refund Type",
  refund_date: "Refund Date",
  more_: "<u>More</u>",
  verification_level: '"Verification Level "',
  in_progress: "In Progress",
  select_file: "Select File",
  no_recorde_found: "No Recorde Found !",
  enter_valid_amount: "Enter a valid amount!",
  bank_name_cant_be_empty: "Bank name can't be empty!",
  account_number_cant_be_empty: "Account number can't be empty!",
  today_s_exchange_rate: "Today's Exchange rate:",
  you_will_receive: "You will receive:",
  please_provide_the_bank_transfer_details:
    "Please provide the bank transfer details.",
  purchase_offline_voucher_codes: "Purchase Offline Voucher Codes",
  your_default_wallet_is: "Your default wallet is",
  top_up_: "TOP UP",
  transfer_cap: "TRANSFER",
  relationship_col: "Relationship :",
  please_enter_national_id: "Please Enter National ID",
  select_relation_from_given: "Select relation from the given",
  search_by_shop_name: "Search by shops name",
  near_by: "Near By",
  load_more: "Load More",
  are_you_sure_you_want_to_delete_cbl_num:
    "Are you sure you want to delete your CBL number?",
  please_enter_your_comments: "Please enter your comments",
  to_pay: '"To Pay "',
  accepted_here: "Accepted Here",
  scan_this_qr_code: "Scan this QR Code",
  removed: "Removed",
  mixed: "Mixed",
  higher_to_lower: "Higher to lower",
  lower_to_high: "Lower to higher",
  received_small: "received",
  balance_is_not_available: "Balance is not Available",
  parent_info: "Parent Info",
  forgot_pin_: "Forgot Pin",
  request_id_col: "Request ID:",
  deactivated_message:
    "Your account has been Deactivated. Please Contact Customer Support",
  exchange_btw_existing: "You can only exchange between existing wallets",
  enter_valid_national_number: "Please enter valid national number",
  zero_lyd: " 0 LYD ",
  please_apply_for_cbl_first: "Please apply for CBL first",
  wallet_and_currency_should_be_same:
    "Selected wallet and currency should be same.",
  cant_transfer_more_than_wallet_balance:
    "You can't transfer amount more than your account balance.",
  cheque: "Cheque",
};
