import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  Image,
  TextInput,
I18nManager,
  TouchableOpacity,
  Dimensions,
  SafeAreaView,
} from "react-native";
import React, { useState ,useEffect} from "react";
import Icon from "react-native-vector-icons/Feather";
import { useTranslation } from "react-i18next";
import Money from "../../assets/image/money.png";
import { Picker } from "@react-native-picker/picker";
import Calendar from "../../assets/image/calendar.png";
import DateTimePickerModal from "react-native-modal-datetime-picker";

const height = Dimensions.get("window").height;

const LetterCreditStep2 = ({ navigation }) => {
  const { t } = useTranslation();
  const [usd, setUsd] = useState("");
  const [lcType, setLcType] = useState("");
  const [confirm, setConfirm] = useState("");
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [text, setText] = useState("");
  const [date, setDate] = useState(new Date());
  const [formateDate, setFormateDate] = useState("");
  const [isDatePickerVisible, setDatePickerVisibility] = useState(false);
  const showDatePicker = () => {
    setDatePickerVisibility(true);
  };
  const hideDatePicker = () => {
    setDatePickerVisibility(false);
  };
    const DateFormate = (date) => {
      const monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      setFormateDate(
        date.getDate() +
          " " +
          monthNames[date.getMonth()] +
          " " +
          date.getFullYear()
      );
    };
    const OnDateChange = (selectedDate) => {
      const currentDate = selectedDate;
      setDate(currentDate);
      DateFormate(currentDate);
      setDatePickerVisibility(false);
    };
    useEffect(() => {
      DateFormate(date);
    }, []);
  return (
    <SafeAreaView style={styles.topContainer}>
      <View style={styles.headerView}>
        <Icon
          name="arrow-left"
          style={styles.arrowIcon}
          onPress={() => navigation.goBack()}
        />
        <Text style={styles.cardText}>{t("common:letter_of_credit")}</Text>
      </View>
      <View style={styles.stepView}>
        <View style={styles.step1}></View>
        <View style={styles.step2}></View>
        <View style={styles.steps}></View>
        <View style={styles.steps}></View>
        <View style={styles.steps}></View>
      </View>
      <ScrollView contentContainerStyle={styles.scrollView}>
        <View style={styles.innerContainer}>
          <Text style={styles.financialText}>
            {t("common:financial_information")}
          </Text>
          <Text style={styles.currencyText}>{t("common:currency_to")}</Text>
          <View style={styles.currencyView}>
            <Image source={Money} style={styles.moneyImage} />
            <Picker
              mode="dropdown"
              selectedValue={usd}
              onValueChange={(selectedValue) => setUsd(selectedValue)}
              style={styles.pickerText}
            >
              <Picker.Item
                label="USD"
                value="USD"
                style={styles.pickerItemText}
              />
            </Picker>
          </View>
          <TextInput 
 textAlign={I18nManager.isRTL ? "right" :"left"} 
            defaultValue={text}
            onChangeText={(newText) => setText(newText)}
            placeholder="LC Value"
            style={styles.lcInput}
          ></TextInput>
          <Text style={styles.lcTypeText}>{t("common:lC_type")}</Text>
          <View style={styles.pickerView}>
            <Picker
              mode="dropdown"
              selectedValue={lcType}
              onValueChange={(selectedValue) => setLcType(selectedValue)}
            >
              <Picker.Item
                label="Irrevocable"
                value="Irrevocable"
                style={styles.pickerItemText}
              />
              <Picker.Item
                label="Revocable"
                value="Revocable"
                style={styles.pickerItemText}
              />
            </Picker>
          </View>
          <Text style={styles.confirmedText}>{t("common:confirmed")}</Text>
          <View style={styles.confirmedPickerView}>
            <Picker
              mode="dropdown"
              selectedValue={confirm}
              onValueChange={(selectedValue) => setConfirm(selectedValue)}
            >
              <Picker.Item
                label="Yes"
                value="Yes"
                style={styles.pickerItemText}
              />
              <Picker.Item
                label="No"
                value="No"
                style={styles.pickerItemText}
              />
            </Picker>
          </View>

          <Text style={styles.performaDateText}>
            {t("common:performa_invoice_date")}
          </Text>
          <View style={styles.dateView}>
            <Text style={styles.dateText}>{formateDate}</Text>
            <TouchableOpacity
              onPress={showDatePicker}
              style={styles.dateButton}
            >
              <Image source={Calendar} style={styles.calendarImage} />
            </TouchableOpacity>
            <DateTimePickerModal
              isVisible={isDatePickerVisible}
              mode="date"
              onConfirm={OnDateChange}
              onCancel={hideDatePicker}
            />
          </View>

          <Text style={styles.performaNumberText}>
            {t("common:performa_invoice_number")}
          </Text>
          <View style={styles.performaPickerView}>
            <Picker
              mode="dropdown"
              selectedValue={invoiceNumber}
              onValueChange={(selectedValue) => setInvoiceNumber(selectedValue)}
            >
              <Picker.Item
                label="56897"
                value="56897"
                style={styles.pickerItemText}
              />
            </Picker>
          </View>
          <View style={styles.btnView}>
            <TouchableOpacity
              onPress={() => navigation.navigate("LetterCreditStep3")}
              style={styles.button}
            >
              <Text style={styles.buttonText}>
                {t("common:save_and_continue")}
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

export default LetterCreditStep2;

const styles = StyleSheet.create({
  topContainer: {
    height: height,
    backgroundColor: "#FFFFFF",
  },
  headerView: {
    flexDirection: "row",
    marginTop: 44,
    alignItems: "center",
    backgroundColor: "#FFFFFF",
  },
  arrowIcon: {
    fontSize: 25,
    color: "#101820",
    marginLeft:24
  },
  cardText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 22,
    color: "#000000",
    left: 90,
  },
  stepView: {
    flexDirection: "row",
    height: 6,
    marginTop: 40,
    marginBottom: 25,
    marginLeft: 24,
    marginRight: 24,
    justifyContent: "space-between",
  },
  step1: {
    width: 59,
    height: 6,
    backgroundColor: "#FF4747",
    borderRadius: 17,
  },
  step2: {
    width: 59,
    height: 6,
    backgroundColor: "#FF4747",
    borderRadius: 17,
  },
  steps: {
    width: 59,
    height: 6,
    backgroundColor: "#000000",
    opacity: 0.1,
    borderRadius: 17,
  },
  scrollView: {
    height: 830,
  },
  innerContainer: {
    flex: 1,
    marginLeft: 24,
    marginRight: 24,
  },
  financialText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 18,
    color: "#000000",
    top: 20,
  },
  currencyText: {
    height: 28,
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 14,
    color: "#A9ABAE",
    top: 40,
  },
  currencyView: {
    flexDirection: "row",
    width: 327,
    height: 56,
    top: 40,
    borderBottomWidth: 1,
    borderColor: "#E7E8E9",
    alignItems: "center",
  },
  moneyImage: {
    height: 24,
    width: 24,
  },
  pickerText: {
    width: 310,
  },
  pickerItemText: {
    color: "#101820",
    fontSize: 14,
    fontWeight: "500",
    fontFamily: "Inter",
    fontStyle: "normal",
  },
  lcInput: {
    height: 65,
    top: 60,
    borderBottomWidth: 1,
    borderColor: "#E7E8E9",
    color: "#101820",
  },
  lcTypeText: {
    top: 80,
    height: 28,
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 14,
    color: "#A9ABAE",
  },
  pickerView: {
    height: 60,
    top: 80,
    borderBottomWidth: 1,
    borderColor: "#E7E8E9",
  },
  confirmedText: {
    top: 100,
    height: 28,
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 14,
    color: "#A9ABAE",
  },
  confirmedPickerView: {
    height: 60,
    width: 327,
    top: 100,
    borderBottomWidth: 1,
    borderColor: "#E7E8E9",
  },
  performaDateText: {
    top: 120,
    height: 28,
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 14,
    color: "#A9ABAE",
  },
  dateView: {
    top: 120,
    borderBottomWidth: 1,
    borderColor: "#F1F1F1",
    height: 56,
    flexDirection: "row",
  },
  dateButton: {
    right: 28,
    top: 20,
  },
  calendarImage: {
    height: 18,
    width: 16.5,
    left: 230,
    resizeMode: "contain"
  },
  dateText: {
    height: 24,
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 14,
    color: "#101820",
    top: 15,
  },
  performaNumberText: {
    top: 140,
    height: 28,
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 14,
    color: "#A9ABAE",
    width: 327,
  },
  performaPickerView: {
    height: 60,
    top: 140,
    borderBottomWidth: 1,
    borderColor: "#E7E8E9",
  },
  btnView: {
    flex: 1,
    marginBottom: 51,
    justifyContent: "flex-end",
  },
  button: {
    padding: 16,
    backgroundColor: "#FF4747",
    borderRadius: 70,
  },
  buttonText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 16,
    alignItems: "center",
    textAlign: "center",
    color: "#FFFFFF",
  },
});
