import {
    StyleSheet,
    Text,
    View,
    TouchableOpacity,
    Image,
    TextInput,
    useWindowDimensions
  } from "react-native";
  import { useState } from "react";
  import notifyIcon from "../../../assets/image/Notification.png";
  import profile from "../../../assets/image/profile.png";
  import Feather from 'react-native-vector-icons/Feather'
  import LogoImage from "../../../assets/image/logotizdan.png"

  const AppHeader = ({userDetails}) => {
    const [search, setSearch] = useState("");
    const {width} = useWindowDimensions();

    return (
      <View style={styles.headerContainer}>
        
       {width>560 ? <View style={styles.headerSearchContainer}>
          <View style={styles.searchContainer}>
            <View style={styles.searchInnerContainer}>
            <Feather size={20} name='search' style={styles.searchIconImage}/>
              <TextInput
                style={styles.searchInput}
                value={search}
                placeholder="Search"
                placeholderTextColor={"#AEAEAE"}
                onChangeText={(newText) => setSearch(newText)}
              />
            </View>
          </View>
        </View>:<View style={styles.mainHeaderSection}>
          <Image source={LogoImage} style={{ width: 120, height: 50, resizeMode: "contain" }} />
        </View>}
        <View style={[styles.headerProfileContainer,width<560&& {marginRight:25}]}>
          <TouchableOpacity style={styles.profileDetail}>
             <Image
             source={notifyIcon}
             style={styles.notifyImage}
             />
          </TouchableOpacity>
          <Image
              source={
                userDetails.data.user?.profile_image === null ||
                userDetails.data.user?.profile_image === ""
                  ? profile
                  : { uri: userDetails.data.user?.profile_image }
              }
              style={styles.profileImage}
            />
 
          <Text style={styles.userName}>
          {userDetails.data.user?.name}
          </Text>
        </View>
      </View>
    );
  };
  
  export default AppHeader;
  
  const styles = StyleSheet.create({
    headerContainer: {
      backgroundColor: "white",
      flexDirection: "row",
      alignItems: "center",
      height: 80,
    },
    mainHeaderSection: {
      flex: 1,
      justifyContent: "center",
      maxHeight: 51,
      maxWidth: 173,
    },
    logoView: {
      color: "#FF4747",
      fontSize: 30,
      fontWeight: "700",
    },
    headerSearchContainer: {
      flex: 1,
      justifyContent: "flex-start",
      marginTop: 20,
      height: 55,
      marginLeft: 16,
    },
  
    searchContainer: {
      flex: 1,
      backgroundColor: "#F8F8F8",
      borderRadius: 60,
      maxHeight: 40,
      maxWidth: 547.7,
    },
    searchInnerContainer: {
      flexDirection: "row",
      alignItems: "center",
    },
    searchIconImage: {
      marginTop: 10,
      marginLeft: 13,

    },
    searchInput: {
      flex: 1,
      marginTop: 8,
      maxWidth:547,
      marginHorizontal: 9,
      height: 24,
      outlineStyle: "none",
    },
    headerProfileContainer: {
      flex: 1,
      backgroundColor:'#ffffff',
      marginTop: 20,
      flexDirection: "row",
      justifyContent: "flex-end",
      height: 55,
      marginLeft: 16,
       marginRight: 70,
    },
    profileDetail: {
      backgroundColor: "#ffffff",
      justifyContent:'center',    
      shadowColor: '#f8f8f8',
      shadowOffset: {width: -2, height: 2},
      shadowRadius: 3,
      alignItems:'center',
      marginRight:16,
      borderRadius:12,
      marginLeft:20,
      height: 40,
      width: 40,
    },
    notifyImage:{
      height:30,
      width:30
    },
    profileImage:{
      height:40,
      width:40,
      borderRadius:20,
      marginRight:16,
    },
    userName:{
      marginTop:8,
      color:'#000000',
      fontSize:20,
      fontWeight:'500',
      fontFamily:'Inter',
      fontStyle:'normal'
    }
  });
  