import {takeEvery, put, call} from 'redux-saga/effects';
import BASEURL from '../URLs'
import {queryApi} from '../QueryApi'
import {
  POST_REQUEST_TRANSFER_REQUEST,
  POST_REQUEST_TRANSFER_REQUEST_SUCCESS,
  POST_REQUEST_TRANSFER_REQUEST_FAILURE,
} from './Actions'; 
//import {queryApi} from '../query-api';

function* handler() {
  yield takeEvery(POST_REQUEST_TRANSFER_REQUEST, requestTransfer);
}


function* requestTransfer(action) {
  try {
    // API call
    console.log("REQUEST_TRANSFER", action.formData);
    const apiResponse = yield call(queryApi, {
      endpoint: BASEURL.requestTransfer,
      method: "POST",
      body: action.formData,
    });
    console.log("RequestTransfer", apiResponse);
    if (!("error" in apiResponse)) {
      yield put({
        type: POST_REQUEST_TRANSFER_REQUEST_SUCCESS,
        payload: apiResponse,
      });
    } else {
      yield put({
        type: POST_REQUEST_TRANSFER_REQUEST_FAILURE,
        payload: apiResponse.error,
      });
    }
  } catch (err) {
    console.log(err);
    // Handle error
    yield put({
      type: POST_REQUEST_TRANSFER_REQUEST_FAILURE,
      payload: { message: err },
    });
  }
}

export {handler};