import {
  StyleSheet,
  Text,
  View,
  Dimensions,
  ScrollView,
  TextInput,
  I18nManager,
  TouchableOpacity,
  Platform,
  KeyboardAvoidingView,
  Keyboard,
  SafeAreaView,
} from "react-native";
import ToastAndroid from "react-native-root-toast";
import React, { useState, useEffect, useRef } from "react";
import Icon from "react-native-vector-icons/Feather";
import { useTranslation } from "react-i18next";
import { Picker } from "@react-native-picker/picker";
import { useSelector, useDispatch } from "react-redux";
import { countryList } from "../../../Redux/Models/Countries/Actions";
import { cityList } from "../../../Redux/Models/Cities/Actions";
import SideBarConatainer from "../../../Components/SideBarConatainer";

import {
  requestUpdate,
  resetRequestUpdate,
} from "../../../Redux/Models/RequestUpdate/Actions";

import Loading from "../../Loading";
import Alert from "../../../Components/Alert";

const height = Dimensions.get("window").height;

const EditAddressDetail = ({ navigation, route }) => {
  const { t } = useTranslation();
  const countryLists = useSelector((state) => state.countryList);
  const citiesLists = useSelector((state) => state.cityList);
  const [country, setCountry] = useState(
    countryLists.data.cities !== undefined &&
      countryLists.data.countryList[0].id
  );
  const personalInfo = route.params?.personalInfo;
  const requestId = route.params?.requestId;
  const kycRequestUpdate = useSelector((state) => state.requestUpdate);
  const [district, setDistrict] = useState(personalInfo.district);
  const [address, setAddress] = useState(personalInfo.address);
  const [street, setStreet] = useState(personalInfo.street);
  const [zipCode, setZipCode] = useState(personalInfo.zip_code);

  const [city, setCity] = useState(
    citiesLists.data.cities !== undefined && citiesLists.data.cities[0].id
  );
  const [disabled, setDisabled] = useState(true);

  const { formData } = route.params;
  console.log(formData);
  const streetRef = useRef();
  const districtRef = useRef();
  const zipCodeRef = useRef();
  const dispatch = useDispatch();
  const validate = () => {
    Keyboard.dismiss();
    if (address === "") {
      ToastAndroid.show("Please enter address", ToastAndroid.LONG);
      return false;
    } else if (street === "") {
      ToastAndroid.show("Please Enter the Street Number", ToastAndroid.LONG);
      return false;
    } else if (district === "") {
      ToastAndroid.show("Please select the District", ToastAndroid.LONG);
      return false;
    } else if (zipCode === "") {
      ToastAndroid.show("Please Enter Zip Code", ToastAndroid.LONG);
      return false;
    }
    return true;
  };

  const nextStep = () => {
    if (!validate()) {
      return;
    }
    let formData = {};
    formData.address = address;
    formData.street = street;
    formData.city = city;
    formData.district = district;
    formData.zip_code = zipCode;
    formData.country_id = country;

    let addToForm = new FormData();
    for (let [key, value] of Object.entries(formData)) {
      addToForm.append("user[" + key + "]", value);
    }
    dispatch(requestUpdate(requestId.id, addToForm));
  };
  useEffect(() => {
    if (!!address && !!street && !!zipCode) {
      setDisabled(false);
    } else {
      setDisabled(false);
    }
  }, [address, street, zipCode]);
  useEffect(() => {
    dispatch(countryList());
    dispatch(cityList());
  }, []);
  useEffect(() => {
    if (kycRequestUpdate.error === true) {
      return Alert("Error", kycRequestUpdate.message, [
        {
          text: "OK",
          onPress: () => {
            dispatch(resetRequestUpdate());
          },
        },
      ]);
    }
    if (kycRequestUpdate.success === true) {
      return Alert("Congratulation", kycRequestUpdate.message, [
        {
          text: "OK",
          onPress: () => {
            dispatch(resetRequestUpdate());
            navigation.navigate("KycRequestDetail", { request: requestId });
          },
        },
      ]);
    }
  }, [kycRequestUpdate]);
  return (
    <SideBarConatainer navigation={navigation}>
      <View style={styles.headerView}>
        <Icon
          name="arrow-left"
          size={30}
          style={styles.arrowIcon}
          onPress={() => navigation.goBack()}
        />
        <Text style={styles.kycText}>{t("common:kyc")}</Text>
      </View>
      <KeyboardAvoidingView
        style={{ flex: 1 }}
        behavior={Platform.OS === "ios" ? "padding" : "height"}
      >
        <ScrollView showsHorizontalScrollIndicator={false}>
          <View style={styles.innerContainer}>
            <Text style={styles.personalInfoText}>
              {t("common:provide_address_detail")}
            </Text>
            <View style={styles.inputContainer}>
              <View style={styles.inputView}>
                <Text style={{ ...styles.nameText }}>
                  {t("common:address_st")}
                  <Text style={{ color: "#FF4747" }}>*</Text>
                </Text>
                <TextInput
                  textAlign={I18nManager.isRTL ? "right" : "left"}
                  value={address}
                  returnKeyType="next"
                  onSubmitEditing={() => streetRef.current.focus()}
                  onChangeText={(text) => setAddress(text)}
                  style={styles.input}
                ></TextInput>
              </View>
              <View style={[styles.inputView, { marginLeft: 20 }]}>
                <Text style={styles.nameText}>
                  {t("common:street_st")}
                  <Text style={{ color: "#FF4747" }}>*</Text>
                </Text>
                <TextInput
                  textAlign={I18nManager.isRTL ? "right" : "left"}
                  value={street}
                  returnKeyType="next"
                  onSubmitEditing={() => districtRef.current.focus()}
                  ref={streetRef}
                  onChangeText={(text) => setStreet(text)}
                  style={styles.input}
                ></TextInput>
              </View>
            </View>
            <View style={styles.inputContainer}>
              <View style={styles.inputView}>
                <Text style={{ ...styles.nameText }}>
                  {t("common:country_st")}
                  <Text style={{ color: "#FF4747" }}>*</Text>
                </Text>
                <View style={styles.pickerView}>
                  <Picker
                    mode="dropdown"
                    selectedValue={country}
                    onValueChange={(selectedValue) => setCountry(selectedValue)}
                    style={styles.pickerItem}
                  >
                    {countryLists.data.countryList?.map((val, ind) => (
                      <Picker.Item
                        key={ind}
                        label={val.country_name}
                        value={val.id}
                        style={styles.pickerItemText}
                      />
                    ))}
                  </Picker>
                </View>
              </View>
              <View style={[styles.inputView, { marginLeft: 20 }]}>
                <Text style={styles.nameText}>
                  {t("common:city")}
                  <Text style={{ color: "#FF4747" }}>*</Text>
                </Text>
                <View style={styles.pickerView}>
                  <Picker
                    mode="dropdown"
                    selectedValue={city}
                    onValueChange={(selectedValue) => setCity(selectedValue)}
                    style={styles.pickerItem}
                  >
                    {citiesLists.data.cities?.map((val, ind) => (
                      <Picker.Item
                        key={ind}
                        label={val.city_name}
                        value={val.id}
                        style={styles.pickerItemText}
                      />
                    ))}
                  </Picker>
                </View>
              </View>
            </View>
            <View style={styles.inputContainer}>
              <View style={styles.inputView}>
                <Text style={{ ...styles.nameText }}>
                  {t("common:district_st")}
                  <Text style={{ color: "#FF4747" }}>*</Text>
                </Text>
                <TextInput
                  textAlign={I18nManager.isRTL ? "right" : "left"}
                  value={district}
                  returnKeyType="next"
                  onSubmitEditing={() => zipCodeRef.current.focus()}
                  ref={districtRef}
                  onChangeText={(text) => setDistrict(text)}
                  style={styles.input}
                ></TextInput>
              </View>
              <View style={[styles.inputView, { marginLeft: 20 }]}>
                <TextInput
                  textAlign={I18nManager.isRTL ? "right" : "left"}
                  value={zipCode}
                  ref={zipCodeRef}
                  onChangeText={(text) => setZipCode(text)}
                  keyboardType="number-pad"
                  returnKeyType="done"
                  placeholder={t("common:zip_code")}
                  style={{ ...styles.input, marginTop: 30 }}
                ></TextInput>
              </View>
            </View>
            <View style={styles.btnView}>
              <TouchableOpacity
                onPress={nextStep}
                style={disabled ? styles.buttonDisabled : styles.button}
              >
                <Text style={styles.buttonText}>{t("common:update")}</Text>
              </TouchableOpacity>
            </View>
          </View>
        </ScrollView>
      </KeyboardAvoidingView>
      {(countryLists.loading ||
        citiesLists.loading ||
        kycRequestUpdate.loading) && <Loading></Loading>}
    </SideBarConatainer>
  );
};

export default EditAddressDetail;

const styles = StyleSheet.create({
  topContainer: {
    flex: 1,
    backgroundColor: "#FFFFFF",
  },
  headerView: {
    flexDirection: "row",
  },
  kycText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 26,
    color: "#000000",
    marginLeft: 10,
  },
  innerContainer: {
    marginTop: 20,
    backgroundColor: "#ffffff",
    padding: 20,
    borderRadius: 16,
  },
  personalInfoText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 18,
    color: "#000000",
    marginTop: 20,
    alignSelf: "flex-start",
  },
  inputContainer: {
    flex: 1,
    flexDirection: "row",
  },
  inputView: {
    flex: 1,
    maxWidth: 327,
  },
  nameText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 14,
    alignSelf: "flex-start",
    color: "#A9ABAE",
    marginTop: 10,
  },
  input: {
    height: 56,
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 14,
    borderColor: "#E7E8E9",
    borderBottomWidth: 1,
    outlineStyle: "none",
    color: "#101820",
  },
  pickerView: {
    borderBottomWidth: 1,
    borderColor: "#E7E8E9",
    height: 56,
    justifyContent: "center",
  },
  pickerItem: {
    height: 45,
    borderWidth: 1,
    borderColor: "white",
    borderStyle: "solid",
  },
  pickerItemText: {
    color: "#101820",
    fontSize: 14,
    fontWeight: "500",
    fontFamily: "Inter",
  },
  btnView: {
    flex: 1,
    marginTop: 30,
    alignItems: "flex-end",
  },
  button: {
    padding: 16,
    backgroundColor: "#FF4747",
    borderRadius: 70,
    width: 404,
  },

  buttonText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 16,
    alignItems: "center",
    textAlign: "center",
    color: "#FFFFFF",
  },
});
