import {
  StyleSheet,
  Text,
  View,
  Dimensions,
  Image,
  ScrollView,
  SafeAreaView,
} from "react-native";
import React from "react";
import { useTranslation } from "react-i18next";
const height = Dimensions.get("window").height;

const ExistingCblList = ({ navigation }) => {
  const { t } = useTranslation();

  return (
    <SafeAreaView style={styles.mainContainer}>
        <Text style={styles.existingText}>{t("common:existing_CBL")}</Text>
        <View style={styles.container}>
          <View style={[styles.numberView, styles.cblView]}>
            <Text style={styles.nameText}>{t("common:cbl_number")}</Text>
            <Text style={styles.numberText}>54555511221</Text>
          </View>
          <View style={[styles.numberView, styles.bankView]}>
            <Text style={styles.nameText}>{t("common:bank_name")}</Text>
            <Text style={styles.numberText}>Central Bank of Libya</Text>
          </View>
          <View style={[styles.numberView, styles.branchView]}>
            <Text style={styles.nameText}>{t("common:branch")}</Text>
            <Text style={styles.numberText}>Lybia Main Branch</Text>
          </View>
        </View>
        <View>
        </View>
    </SafeAreaView>
  );
};

export default ExistingCblList;

const styles = StyleSheet.create({
  mainContainer: {
    flex:1,
  },
  existingText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 18,
    color: "#000000",
  },
  container: {
    zIndex: 1,
    marginTop: 24,
    height: 117,
    backgroundColor: "#F9F9F9",
    borderRadius: 10,
  },
  numberView: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  nameText: {
    left:16,
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 14,
    color: "#828282",
  },
  numberText: {
    right:16,
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 14,
    textAlign: "right",
    color: "#000000",
  },
  cblView: {
    top: 15,
  },
  bankView: {
    top: 25,
  },
  branchView: {
    top: 35,
  },
  deleteImage: {
    textAlign: "right",
    top: 70,
    width: 24,
    height: 24,
  },
});
