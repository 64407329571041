import {
  StyleSheet,
  Text,
  View,
  Image,
  Dimensions,
  ScrollView,
  FlatList,
  TouchableOpacity,
  SafeAreaView
} from "react-native";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import noPhoto from "../../assets/image/no-photo.jpg";
import Amazon from "../../assets/image/amazon.png";
import { useDispatch, useSelector } from "react-redux";
const height = Dimensions.get("window").height;
import Loading from "../../Screens/Loading";
import { serviceVoucherCompanyList } from "../../Redux/Models/ServiceVoucherCompanyList/Actions";


const Vouchers = ({ navigation }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const serviceVouchersList = useSelector(
    (state) => state.serviceVoucherCompanyList
  );
  const [filterData, setFilterData] = useState([]);

  useEffect(() => {
    dispatch(serviceVoucherCompanyList());
  }, []);
  useEffect(() => {
    let data = serviceVouchersList.data.filter(
      (val) =>
        val.title
          .toLowerCase()
          .indexOf(serviceVouchersList.search?.toLowerCase()) > -1
    );
    let temArray = []
    data.map((giftCard, index) => {
      giftCard.service_vouchers.map((giftCardChiled, ind) => {
        let value = {}
        value.title = giftCard.title
        value.logo_color = giftCard.logo_color
        value.visible = giftCard.visible
        value.amount = giftCardChiled.amount
        value.package_name = giftCardChiled.package_name
        value.lyd_price = giftCardChiled.lyd_price
        value.giftCardChiled = giftCardChiled
        value.id = giftCard.id.toString() + ind.toString()
        temArray = [...temArray, value]

      })
    })
    setFilterData(temArray);
  }, [serviceVouchersList.search]);
  useEffect(() => {
    let temArray = []
    serviceVouchersList.data.map((giftCard, index) => {
      giftCard.service_vouchers.map((giftCardChiled, ind) => {
        let value = {}
        value.title = giftCard.title
        value.logo_color = giftCard.logo_color
        value.visible = giftCard.visible
        value.amount = giftCardChiled.amount
        value.package_name = giftCardChiled.package_name
        value.lyd_price = giftCardChiled.lyd_price
        value.giftCardChiled = giftCardChiled
        value.id = giftCard.id.toString() + ind.toString()
        temArray = [...temArray, value]

      })
    })
    // console.log("temArray",serviceVouchersList.data);

    setFilterData(temArray);
  }, [serviceVouchersList.data]);
  return (
    <SafeAreaView style={styles.mainContainer}>
      <View style={styles.innerContainer}>
        <FlatList
          data={filterData}
          renderItem={({ item }) =>
            item.visible === 1 &&
            <TouchableOpacity
              onPress={() =>
                navigation.navigate("CompanyVoucherDetail", {
                  item,
                  giftCard: item.giftCardChiled,
                })
              }
              style={styles.topContainer1}
            >
              <View style={styles.container}>
                <Image
                  source={item.logo_color === "" ? noPhoto : { uri: item.logo_color }}
                  style={styles.vodafoneImage}
                />
                <View style={styles.giftView}>
                  <Text style={styles.giftCardText}>
                    {t("common:voucher")}
                  </Text>
                  <Text style={styles.vodafoneText}>{item.title}</Text>
                  <Text style={styles.vodafoneText}>
                    {item.package_name !== "" && item.package_name}
                  </Text>

                  {/*  <Text style={styles.dateText}>Till 23 Jun 2021</Text>*/}
                </View>
                <View
                  style={{
                    flex: 1,
                    justifyContent: "center",
                    marginRight: 15,
                    alignItems: "flex-end",
                  }}
                >
                  <Text style={styles.priceText}>{item.amount} $</Text>
                  <Text style={styles.priceText2}>
                    {"("} {item.lyd_price} LYD {")"}
                  </Text>
                </View>
              </View>
            </TouchableOpacity>
          }
          keyExtractor={(item, ind) => item.id + ind}
          showsHorizontalScrollIndicator={false}
          contentContainerStyle={{ flexDirection: "row", flexWrap: "wrap" }}
        />
      </View>
      {serviceVouchersList.loading && <Loading></Loading>}
    </SafeAreaView>
  );
};

export default Vouchers;

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    marginTop: 20,
    backgroundColor: "#FFFFFF",
    borderRadius: 16,
    padding: 20,
    marginBottom: 25,
  },
  innerContainer: {
    flex: 1,
  },
  topContainer1: {
    backgroundColor: "#F9F9F9",
    borderRadius: 10,
    width: 430,
    height: 100,
    margin: 10,
    padding: 10,
    justifyContent: "center"
  },
  container: {
    flexDirection: "row",
    alignItems: "center",
  },
  vodafoneImage: {
    borderRadius: 56,
    width: 56,
    height: 56,
  },
  giftView: {
    marginLeft: 30,
  },
  giftCardText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 12,
    alignSelf: "flex-start",
    letterSpacing: 0.111,
    color: "#828282",
  },
  vodafoneText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 16,
    alignSelf: "flex-start",
    letterSpacing: 0.111,
    color: "#282828",
  },
  dateText: {
    height: 20,
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 12,

    letterSpacing: 0.111,
    color: "#BDBDBD",
  },
  priceText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 14,

    textAlign: "right",
    letterSpacing: 0.111,
    color: "#FF4747",
    alignItems: "center",
  },
  priceText2: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 12,

    textAlign: "right",
    letterSpacing: 0.111,
    color: "#FF4747",
    alignItems: "center",
  },
});
