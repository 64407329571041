
const POST_REQUEST_TRANSFER_REQUEST = 'POST_REQUEST_TRANSFER_REQUEST';
const POST_REQUEST_TRANSFER_REQUEST_SUCCESS =  'POST_REQUEST_TRANSFER_REQUEST_SUCCESS';
const POST_REQUEST_TRANSFER_REQUEST_FAILURE =  'POST_REQUEST_TRANSFER_REQUEST_FAILURE';
const CHANGE_REQUEST_TRANSFER_STATUS = 'CHANGE_REQUEST_TRANSFER_STATUS'
const requestTransfer = (formData)=>{

    return {
        type: POST_REQUEST_TRANSFER_REQUEST,
        formData
    }
}

const changeRequestTransferstatus = ()=>{

  return {
      type: CHANGE_REQUEST_TRANSFER_STATUS,
  }
}
export {
  requestTransfer,
  changeRequestTransferstatus,
  POST_REQUEST_TRANSFER_REQUEST,
  POST_REQUEST_TRANSFER_REQUEST_SUCCESS,
  POST_REQUEST_TRANSFER_REQUEST_FAILURE,
  CHANGE_REQUEST_TRANSFER_STATUS
};