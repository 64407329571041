const POST_FAMILY_MEMBER_TOP_UP_REQUEST = "POST_FAMILY_MEMBER_TOP_UP_REQUEST";
const POST_FAMILY_MEMBER_TOP_UP_REQUEST_SUCCESS = "POST_FAMILY_MEMBER_TOP_UP_REQUEST_SUCCESS";
const POST_FAMILY_MEMBER_TOP_UP_REQUEST_FAILURE = "POST_FAMILY_MEMBER_TOP_UP_REQUEST_FAILURE";
const CHANGE_REQUEST_FAMILY_STATUS = "CHANGE_REQUEST_TRANSFER_STATUS";

const familyMemberTopUp = (formData) => {
  return {
    type: POST_FAMILY_MEMBER_TOP_UP_REQUEST,
    formData,
  }; 
};
 const changeRequestFamilyStatus = () => {
   return {
     type: CHANGE_REQUEST_FAMILY_STATUS,
   };
 };
export { 
  familyMemberTopUp,
  changeRequestFamilyStatus,
  POST_FAMILY_MEMBER_TOP_UP_REQUEST,
  POST_FAMILY_MEMBER_TOP_UP_REQUEST_SUCCESS,
  POST_FAMILY_MEMBER_TOP_UP_REQUEST_FAILURE,
  CHANGE_REQUEST_FAMILY_STATUS,
};
