
const GET_MDP_CARD_LOOKUP_PRODUCTS_REQUEST = 'GET_MDP_CARD_LOOKUP_PRODUCTS_REQUEST';
const GET_MDP_CARD_LOOKUP_PRODUCTS_REQUEST_SUCCESS = 'GET_MDP_CARD_LOOKUP_PRODUCTS_REQUEST_SUCCESS';
const GET_MDP_CARD_LOOKUP_PRODUCTS_REQUEST_FAILURE = 'GET_MDP_CARD_LOOKUP_PRODUCTS_REQUEST_FAILURE';

const mdpCardsLookupProducts = () => {

  return {
    type: GET_MDP_CARD_LOOKUP_PRODUCTS_REQUEST,
  }
}

export {
  mdpCardsLookupProducts,
  GET_MDP_CARD_LOOKUP_PRODUCTS_REQUEST,
  GET_MDP_CARD_LOOKUP_PRODUCTS_REQUEST_SUCCESS,
  GET_MDP_CARD_LOOKUP_PRODUCTS_REQUEST_FAILURE,
};