import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
export default function MyApp({isDatePickerVisible,date,OnDateChange,}) {
  const datePickerRef = React.useRef(null); 
   const [showDatePicker,setShowDatePicker]=React.useState(isDatePickerVisible);

  const handleClickOutside = (event) => {
    if (datePickerRef.current) {
      
      // the click was outside the date picker, so we want to close it
      // datePickerRef.current.close();
      // console.log("asjkfasdifsDFK",datePickerRef.current);
      setShowDatePicker(false)
    }
  };

  return (
    <>

      {isDatePickerVisible && (
        <DatePicker
        portalId="root-portal"
          inline
          ref={datePickerRef}
          selected={date}
          onChange={date => OnDateChange(date)}
          popperProps={{
            positionFixed: true
        }}
        />
      )}
    </>
  );
}
