import {takeEvery, put, call} from 'redux-saga/effects';
import BASEURL from '../URLs'
import {queryApi} from '../QueryApi'
import {
  GET_BANK_LIST_REQUEST,
    GET_BANK_LIST_REQUEST_SUCCESS,
    GET_BANK_LIST_REQUEST_FAILURE,
} from './Actions';
//import {queryApi} from '../query-api';

function* handler() {
  yield takeEvery(GET_BANK_LIST_REQUEST, getBanks);
}


function* getBanks(action) {
  try {
      // API call
     // console.log(action.parm)
    const apiResponse = yield call(queryApi, {
      endpoint: BASEURL.tizdanBankAccounts,
      method: 'GET',
    });
    console.log('HIT Banks',apiResponse)
    if(!("error" in apiResponse)){
      yield put({
        type: GET_BANK_LIST_REQUEST_SUCCESS,
        payload: apiResponse
      });

    }else{
      yield put({
        type: GET_BANK_LIST_REQUEST_FAILURE,
        payload: apiResponse.error
      });
    }

 
  } catch (err) {
    console.log(err);
    // Handle error
    yield put({
      type: GET_BANK_LIST_REQUEST_FAILURE,
      payload: {message:err}
    });
  }
}

export {handler};