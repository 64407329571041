import { StyleSheet, Text, View, TouchableOpacity, Image, useWindowDimensions, SafeAreaView, ScrollView } from "react-native";
import React, { useEffect } from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { setUser } from "../../../Redux/Models/User/Login/Actions";
import { CHANGE_STATUS } from "../../../Redux/Models/User/Register/Action";
import Alert from "../../../Components/Alert";
import AppHeader from "../../../Components/AppHeader"
import profile from "../../../assets/image/profile.png";
import { useTranslation } from "react-i18next";
import { selectedRequests } from '../../../Redux/Models/MyRequests/Actions'
import { useSelector, useDispatch } from "react-redux";

const SideBar = ({ children, navigation, route }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch()
  const userDetails = useSelector((state) => state.userDetails);

  const switchTab = (tab) => {
    navigation.navigate(tab)
  }
  const { width } = useWindowDimensions();
  const logout = () => {
    Alert(
      t("common:tizdan_customer"),
      t("common:are_you_sure_you_want_to_logout"),
      [
        {
          text: t("common:No"),
          style: "cancel",
        },
        {
          text: t("common:yes"),
          onPress: () => {
            AsyncStorage.removeItem("user").then((val) => console.log(val)).catch((err) => console.log(err));
            const user = { token: null };
            dispatch(setUser(user));
            dispatch({ type: CHANGE_STATUS });
          },
        },
      ],
      {
        cancelable: true,
      }
    );
  };
  const formatePhoneNumber = () => {

    let phoneNumber = userDetails.data.user?.phone_number;
    const countryCodeLength = phoneNumber.length - 10;

    const countryCode = phoneNumber.substr(0, countryCodeLength)

    phoneNumber = phoneNumber.substr(countryCodeLength, phoneNumber.length)

    var cleaned = ("" + phoneNumber).replace(/\D/g, "");
    let normValue = `${cleaned.substring(0, 3)}${cleaned.length > 3 ? "-" : ""
      }${cleaned.substring(3, 6)}${cleaned.length > 6 ? "-" : ""
      }${cleaned.substring(6)}`;
    return countryCode.replace("00", "+") + " " + normValue;
  }
  return (
    <SafeAreaView style={styles.headerContainer}>
      <AppHeader />
      <ScrollView contentContainerStyle={{ flexGrow: 1 }} showsHorizontalScrollIndicator={false}>
        <View style={styles.mainContainer}>
          <View style={styles.topHeader}>
            <View style={styles.sidebar}>
              <View style={styles.imageContainer}>
                <Image
                  source={
                    userDetails.data.user?.profile_image === null ||
                      userDetails.data.user?.profile_image === ""
                      ? profile
                      : { uri: userDetails.data.user?.profile_image }
                  }
                  style={styles.profileImage}
                />
                <Text style={styles.userName}>{userDetails.data.user?.name}</Text>
              </View>
              <View style={styles.btnContainer}>
                <TouchableOpacity style={styles.inActiveBtn}>
                  <Text style={styles.btnText}>{t("common:account_settings")}</Text>
                </TouchableOpacity>
                <TouchableOpacity style={styles.inActiveBtn}>
                  <Text style={styles.btnText}>{t("common:transactions_history")}</Text>
                </TouchableOpacity>
                <TouchableOpacity onPress={() => switchTab("SelectLanguage")} style={[styles.activeBtn, route.name !== "SelectLanguage" && { border: 'none' }]}>
                  <Text style={[styles.activeBtnText, route.name !== "SelectLanguage" && { color: '#000', fontWeight: "500" }]}>{t("common:select_language")}</Text>
                </TouchableOpacity>
                <TouchableOpacity
                  onPress={() => switchTab("ChangePinCode")}
                  style={[styles.activeBtn, route.name !== "ChangePinCode" && { border: 'none' }]}
                >
                  <Text style={[styles.activeBtnText, route.name !== "ChangePinCode" && { color: '#000', fontWeight: "500" }]}>{t("common:change_pin_code")}</Text>
                </TouchableOpacity>
                <TouchableOpacity style={styles.inActiveBtn}>
                  <Text style={styles.btnText}>{t("common:analyzer_report")}</Text>
                </TouchableOpacity>
                <TouchableOpacity style={styles.inActiveBtn}>
                  <Text style={styles.btnText}>{t("common:orders")}</Text>
                </TouchableOpacity>
                <TouchableOpacity style={styles.inActiveBtn}>
                  <Text style={styles.btnText}>{t("common:scan")}</Text>
                </TouchableOpacity>
                <TouchableOpacity style={styles.inActiveBtn}>
                  <Text style={styles.btnText}>{t("common:invite_friends")}</Text>
                </TouchableOpacity>
                <TouchableOpacity style={styles.inActiveBtn}>
                  <Text style={styles.btnText}>{t("common:faqs")}</Text>
                </TouchableOpacity>
                <TouchableOpacity style={styles.inActiveBtn}>
                  <Text style={styles.btnText}>{t("common:support")}</Text>
                </TouchableOpacity>
                <TouchableOpacity onPress={logout} style={[styles.inActiveBtn, { marginRight: 20 }]}>
                  <Text style={styles.logoutBtnText}>{t("common:Logout")}</Text>
                </TouchableOpacity>
                <Text style={styles.phoneText}>{formatePhoneNumber()}</Text>
              </View>
            </View>
            <View style={{ flex: 1 }}>
              {children}
            </View>
          </View>
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

export default SideBar;

const styles = StyleSheet.create({
  headerContainer: {
    flex: 1,
  },
  mainContainer: {
    flex: 1,
    marginVertical: 25,
    flexDirection: "row",
    justifyContent: "center",
  },
  topHeader: {
    flex: 1,
    flexDirection: "row",
    maxWidth: 1400,
    gap: 30
  },
  sidebar: {
    flex: 1,
    backgroundColor: "#FFFFFF",
    borderRadius: 16,
    maxWidth: 279,
    minWidth: 150,
    paddingLeft: 20,
    paddingVertical: 30,
  },
  imageContainer:{
    justifyContent:"center",
    alignItems:"center"
  },
  profileImage: {
    height: 92,
    width: 92,
    borderRadius: 100,
  },
  userName: {
    marginTop: 8,
    color: "#000000",
    fontSize: 22,
    fontWeight: "700",
    fontFamily: "Inter",
    fontStyle: "normal",
  },
  btnContainer: {
    marginTop: 30,
  },
  btnText: {
    fontFamily: 'Inter',
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 17,
    lineHeight: 21,
    letterSpacing: -0.220936,
    textTransform: "uppercase",
    color: "#000000",
    alignSelf: "flex-start"
  },
  activeBtn: {
    height: 40,
    justifyContent: "center",
    borderRightWidth: 2,
    borderRightColor: '#FF4747'
  },
  inActiveBtn: {
    height: 40,
    justifyContent: "center",
  },
  activeBtnText: {
    fontFamily: 'Inter',
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 17,
    lineHeight: 21,
    letterSpacing: -0.220936,
    textTransform: "uppercase",
    color: "#FF4747",
    alignSelf: "flex-start"
  },
  logoutBtnText: {
    fontFamily: 'Inter',
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 17,
    lineHeight: 21,
    letterSpacing: -0.220936,
    textTransform: "uppercase",
    color: "#FF4747",
  },
  phoneText: {
    marginTop: 30,
    fontFamily: 'Inter',
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 16,
    lineHeight: 17,
    letterSpacing: -0.017241,
    color: "#000000",
  }
});
