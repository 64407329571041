import {takeEvery, put, call} from 'redux-saga/effects';
import BASEURL from '../../URLs'
import {queryApi} from '../../QueryApi'
import {
  POST_USER_LOGIN_REQUEST,
  POST_USER_LOGIN_REQUEST_SUCCESS,
  POST_USER_LOGIN_REQUEST_FAILURE,
} from './Actions';
//import {queryApi} from '../query-api';

function* handler() {
  yield takeEvery(POST_USER_LOGIN_REQUEST, getUserInfo);
}


function* getUserInfo(action) {
  try {
      // API call
      console.log(action.formData)
    const apiResponse = yield call(queryApi, {
      endpoint: BASEURL.userLogin,
      method: 'POST',
      body: action.formData
    });
    console.log('LOGIN USER Tokon',apiResponse)
    if(!("error" in apiResponse)){
      yield put({
        type: POST_USER_LOGIN_REQUEST_SUCCESS,
        payload: apiResponse
      });

    }else{
      yield put({
        type: POST_USER_LOGIN_REQUEST_FAILURE,
        payload: apiResponse.error
      });
    }

 
  } catch (err) {
    console.log(err);
    // Handle error
    yield put({
      type: POST_USER_LOGIN_REQUEST_FAILURE,
      payload: {message:err}
    });
  }
}

export {handler};