
const DELETE_FILE_REQUEST = 'DELETE_FILE_REQUEST';
const DELETE_FILE_REQUEST_SUCCESS =  'DELETE_FILE_REQUEST_SUCCESS';
const DELETE_FILE_REQUEST_FAILURE =  'DELETE_FILE_REQUEST_FAILURE';
const RESET_DETETE_FILE_STATE = 'RESET_DETETE_FILE_UPLOAD_STATE';

const deleteFile = (params)=>{
    return {
        type: DELETE_FILE_REQUEST,
        params,
    }
}

const resetDeleteFilestate = ()=>{

    return {
        type: RESET_DETETE_FILE_STATE,
        
    }
}
export {
  deleteFile,
  resetDeleteFilestate,
  DELETE_FILE_REQUEST,
  DELETE_FILE_REQUEST_SUCCESS,
  DELETE_FILE_REQUEST_FAILURE,
  RESET_DETETE_FILE_STATE
};