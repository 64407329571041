import React, { useEffect } from "react";
import {
  View,
  Text,
  StyleSheet,
  Pressable,
  I18nManager,
  SafeAreaView,
  TouchableOpacity,
  Platform
} from "react-native";
import { useTranslation } from "react-i18next";
import RNRestart from "react-native-restart";
import AsyncStorage from "@react-native-async-storage/async-storage";
import Icon from "react-native-vector-icons/Feather";
import { useState } from "react";
import SideBar from "./Components/SideBar";

const LANGUAGES = [
  { code: "en", label: "English" },
  { code: "ar", label: "Arabic" },
];

const SelectLanguage = ({ navigation, route }) => {

  const [lang, setLang] = useState(null);
  const { t, i18n } = useTranslation();

  const selectedLanguageCode = i18n.language;
  const setLanguage = (code) => {
    I18nManager.forceRTL(code === "ar");
    let currlang = i18n.language;
    i18n.changeLanguage(code);
    if (currlang === code) {
      navigation.navigate("Dashboard");
    } else {
      AsyncStorage.setItem(
        "langApp",
        JSON.stringify({ lang: code, restarted: true })
      ).then(val => {
        if (Platform.OS === "web") {
          window.location.reload();
        } else {
          RNRestart.Restart()
        }
      }

      );
    }
  };

  return (
    <SideBar route={route} navigation={navigation}>

      <View style={styles.langBtnContainer}>
        <Text style={styles.accountText}>{t("common:select_language")}</Text>
        <View style={{ flex: 1, flexDirection: "row", marginTop: 30, flexWrap: "wrap" }}>
          {LANGUAGES.map((language) => {
            const selectedLanguage = language.code === selectedLanguageCode;

            return (
              <TouchableOpacity
                key={language.code}
                style={lang === null ? selectedLanguage ? styles.buttonContainer : styles.btnCont : lang === language.code ? styles.buttonContainer : styles.btnCont}
                onPress={() => setLang(language.code)}
              >
                <Text
                  style={lang === null ? selectedLanguage ? styles.selectedText : styles.text : lang === language.code ? styles.selectedText : styles.text}
                >
                  {language.label}
                </Text>
              </TouchableOpacity>
            );
          })}
        </View>
        <View style={styles.btnView}>
          <TouchableOpacity onPress={() => setLanguage(lang)} style={styles.updateBtn}>
            <Text style={styles.updateText}>{t("common:update")}</Text>
          </TouchableOpacity>
        </View>
      </View>
    </SideBar>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  headerView: {
    flexDirection: "row",
    marginTop: 40,
    alignItems: "center",
    marginHorizontal: 24
  },
  accountText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 18,
    color: "#000000",
  },
  langBtnContainer: {
    backgroundColor: "#FFF",
    paddingVertical: 30,
    paddingHorizontal: 20,
    borderRadius: 16,
  },
  buttonContainer: {
    padding: 16,
    borderRadius: 50,
    borderWidth: 1,
    borderColor: "#FF4747",
    margin: 15,
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
    maxWidth: 327,
    minWidth: 120
  },
  btnCont: {
    padding: 16,
    margin: 15,
    borderRadius: 50,
    backgroundColor: "#FFFFFF",
    flex: 1,
    maxWidth: 327,
    minWidth: 120,
    alignItems: "center",
    justifyContent: "center",
    borderWidth: 1,
    borderColor: "#000",
  },
  text: {
    fontSize: 18,
    fontWeight: "600",
    fontFamily: "Inter",
    color: "#000",
    textAlign: "center",
  },
  selectedText: {
    fontSize: 18,
    fontWeight: "600",
    fontFamily: "Inter",
    color: "#FF4747",
    textAlign: "center",
  },
  btnView: {
    alignItems: "flex-end",
    justifyContent: "flex-end",
    flexDirection:"row"
  },
  updateBtn: {
    marginTop: 30,
    padding: 16,
    margin: 15,
    borderRadius: 50,
    maxWidth: 327,
    flex:1,
    minWidth:120,
    backgroundColor: "#FF4747"
  },
  updateText: {
    fontSize: 18,
    fontWeight: "600",
    fontFamily: "Inter",
    color: "#FFFFFF",
    textAlign: "center",
  },
});

export default SelectLanguage;
