import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  TouchableOpacity,
  Image,
  TextInput,
  I18nManager,
  SafeAreaView,
} from "react-native";
import React, { useState, useEffect, useCallback } from "react";
import Icon from "react-native-vector-icons/Feather";
import { useTranslation } from "react-i18next";
import SendChat from "../../assets/image/sendMasg.png";
import MessageIcon from "react-native-vector-icons/Entypo";
import { useDispatch, useSelector } from "react-redux";
import { DateFormate, formatAMPM } from "../../Utils/HelperFunctions";
import { requestDetails } from "../../Redux/Models/RequestDetails/Actions";
import { getRequestCommentList } from "../../Redux/Models/Comments/RequestListComment/Actions";
import {
  createCommentsRequest,
  resetAddCommentsRequest,
} from "../../Redux/Models/Comments/RequestAddComment/Actions";
import { useFocusEffect } from "@react-navigation/native";
import SideBarConatainer from "../../Components/SideBarConatainer";

import Loading from "../Loading";
const KycRequestDetail = ({ navigation, route }) => {
  const { t } = useTranslation();
  const [commentText, setCommentText] = useState("");
  const dispatch = useDispatch();

  const requestDetail = useSelector((state) => state.requestDetails);
  const createRequestComment = useSelector(
    (state) => state.createCommentsRequest
  );
  const requestCommentList = useSelector(
    (state) => state.getRequestCommentList
  );
  const selectedRequestdetail = route.params?.request;

  useEffect(() => {
    if (createRequestComment.success === true) {
      dispatch(getRequestCommentList(route.params?.request.id));
      dispatch(resetAddCommentsRequest());
    }
  }, [createRequestComment]);

  useFocusEffect(
    useCallback(() => {
      dispatch(requestDetails(route.params?.request.id));
      dispatch(getRequestCommentList(route.params?.request.id));
    }, [dispatch])
  );

  const createComment = () => {
    if (commentText !== "") {
      let formData = new FormData();
      formData.append("message", commentText);
      dispatch(createCommentsRequest(route.params?.request.id, formData));
      setCommentText("");
    }
  };
  return (
    <SideBarConatainer navigation={navigation}>
      <View style={styles.headerView}>
        <Icon
          name="arrow-left"
          size={30}
          style={styles.arrowIcon}
          onPress={() => navigation.goBack()}
        />
        <Text style={styles.requestsText}>{t("common:requests")}</Text>
      </View>
      <ScrollView showsHorizontalScrollIndicator={false}>
        <View style={styles.infoContainer}>
          <View style={styles.headerContainer}>
            <View style={styles.infoContainer}>
              <View style={styles.container}>
                <Text style={styles.nameText}>
                  {t("common:request_category")}
                </Text>
                <Text style={styles.cblRequestText}>
                  {selectedRequestdetail.verify_process_type.toUpperCase()}
                </Text>
              </View>

              <View style={styles.container}>
                <Text style={styles.nameText}>{t("common:request_id")}</Text>
                <Text style={styles.cblRequestText}>
                  {selectedRequestdetail.request_number}
                </Text>
              </View>

              <View style={styles.container}>
                <Text style={styles.nameText}>{t("common:date")}</Text>
                <Text style={styles.cblRequestText}>
                  {" "}
                  {DateFormate(
                    new Date(
                      !!selectedRequestdetail.created
                        ? selectedRequestdetail.created
                        : selectedRequestdetail.request_date
                    )
                  ) +
                    " " +
                    formatAMPM(
                      new Date(
                        !!selectedRequestdetail.created
                          ? selectedRequestdetail.created
                          : selectedRequestdetail.request_date
                      )
                    )}
                </Text>
              </View>
            </View>
            <View style={styles.infoContainer}>
              <View style={styles.container}>
                <Text style={styles.nameText}>{t("common:status")}</Text>
                <Text style={styles.pendingText}>
                  {t(
                    "common:" +
                      selectedRequestdetail.verify_request_status.toLowerCase()
                  )}
                </Text>
              </View>

              <View style={styles.container}>
                <Text style={styles.nameText}>
                  {t("common:status_reason_col")}
                </Text>
                <Text style={styles.pendingText}>
                  {selectedRequestdetail.request_status_reason}
                </Text>
              </View>

              <View style={styles.container}>
                <Text style={styles.nameText}>{t("common:description")}</Text>
                <Text style={styles.descriptionText}>
                  {selectedRequestdetail.description}
                </Text>
              </View>
            </View>
          </View>
        </View>

        <View>
          <TouchableOpacity
            onPress={() =>
              navigation.navigate("KycPersonalInfo", {
                personalInfo: requestDetail.data.request,
              })
            }
            style={styles.personalButton}
          >
            <Text style={styles.personalText}>
              {t("common:personal_information")}
            </Text>
            <Icon name="chevron-right" size={24} style={styles.arrowImage} />
          </TouchableOpacity>

          <TouchableOpacity
            onPress={() =>
              navigation.navigate("KycAddressDetail", {
                personalInfo: requestDetail.data.request,
              })
            }
            style={styles.personalButton}
          >
            <Text style={styles.personalText}>
              {t("common:address_details")}
            </Text>
            <Icon name="chevron-right" size={24} style={styles.arrowImage} />
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() =>
              navigation.navigate("KycFinancialInfo", {
                personalInfo: requestDetail.data.request,
              })
            }
            style={styles.personalButton}
          >
            <Text style={styles.personalText}>
              {t("common:financial_information")}
            </Text>
            <Icon name="chevron-right" size={24} style={styles.arrowImage} />
          </TouchableOpacity>

          <TouchableOpacity
            onPress={() =>
              navigation.navigate("KycDocuments", {
                requestDocs: requestDetail.data.request,
              })
            }
            style={styles.personalButton}
          >
            <Text style={styles.personalText}>{t("common:documents")}</Text>
            <Icon name="chevron-right" size={24} style={styles.arrowImage} />
          </TouchableOpacity>
          {/* <View style={styles.commentView}>
            <Text style={styles.commentText}>{t("common:comments")}</Text>

            {requestCommentList.data.comments?.map((val, ind) => (
              <View key={ind} style={styles.messageView}>
                <MessageIcon name="message" color="#FF4747" size={24} />
                <View style={{ marginLeft: 16 }}>
                  <Text style={styles.messageText}>{val.message}</Text>
                  <Text style={styles.timeText}>
                    {DateFormate(new Date(val.created)) +
                      " " +
                      formatAMPM(new Date(val.created))}
                  </Text>
                </View>
              </View>
            ))}
          </View> */}
        </View>
      </ScrollView>
      {/* <View style={styles.chatContainer}>
        <View style={styles.chatView}>
          <TextInput
            textAlign={I18nManager.isRTL ? "right" : "left"}
            defaultValue={commentText}
            onChangeText={(newText) => setCommentText(newText)}
            multiline={true}
            placeholder={t("common:Type_comment")}
            style={styles.chatInput}
          ></TextInput>
        </View>
        <TouchableOpacity onPress={createComment} style={styles.chatButton}>
          <Image source={SendChat} style={styles.chatImage} />
        </TouchableOpacity>
      </View> */}
    </SideBarConatainer>
  );
};

export default KycRequestDetail;

const styles = StyleSheet.create({
  topContainer: {
    flex: 1,
    backgroundColor: "#FFFFFF",
  },
  headerView: {
    flexDirection: "row",
    alignItems: "center",
  },
  requestsText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 26,
    color: "#000000",
    marginLeft: 10,
  },
  infoContainer: {
    flex: 1,
  },
  headerContainer: {
    backgroundColor: "#FFF",
    borderRadius: 16,
    padding: 20,
    marginTop: 17,
    flexDirection: "row",
  },
  container: {
    flexDirection: "row",
    alignItems: "center",
    height: 30,
    justifyContent: "space-between",
    marginHorizontal: 10,
    marginTop: 10,
  },
  nameText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 14,
    color: "#BDBDBD",
  },
  cblRequestText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 14,
    textAlign: "right",
  },
  descriptionText: {
    width: 210,
    marginTop: 5,
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 14,
    textAlign: "right",
    color: "#000000",
  },
  pendingText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 14,
    color: "#FF7C22",
  },

  personalButton: {
    height: 75,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#FFF",
    borderRadius: 16,
    marginTop: 17,
  },
  personalText: {
    marginLeft: 24,
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 18,
    color: "#000000",
  },
  arrowImage: {
    marginRight: 24,
  },
  commentView: {
    flex: 1,
    marginTop: 24,
    padding: 20,
    backgroundColor: "#FFF",
    borderRadius: 16,
  },
  commentText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 18,
    color: "#000000",
  },
  messageView: {
    flexDirection: "row",
    marginTop: 24,
    marginRight: 24,
    height: 56,
    borderBottomWidth: 1,
    borderBottomColor: "#E2E2E3",
  },
  messageText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 14,
    color: "#000000",
  },
  timeText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 12,
    color: "#BABABA",
    marginTop: 8,
  },
  chatContainer: {
    position: "absolute",
    borderTopLeftRadius: 15,
    borderTopRightRadius: 15,
    flexDirection: "row",
    alignItems: "center",
    height: 56,
    flex: 1,
    width: "100%",
    bottom: 0,
    marginLeft: 0,
    marginRight: 0,
    maxHeight: 100,
    borderTopWidth: 0.1,
    borderLeftWidth: 0.1,
    borderRightWidth: 0.1,
  },
  chatView: {
    width: "80%",
    height: 56,
    marginLeft: 24,
    marginRight: 10,
  },
  chatInput: {
    marginRight: 10,
    height: "100%",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 14,
    color: "#101820",
    outlineStyle: "none",
  },
  chatButton: {
    width: 28,
    height: 28,
    marginRight: 25.5,
  },
  chatImage: {
    width: 28,
    height: 28,
  },
});
