import {takeEvery, put, call} from 'redux-saga/effects';
import BASEURL from '../../URLs'
import {queryApi} from '../../QueryApi'
import {
  POST_ADD_COMMENT_REQUEST,
  POST_ADD_COMMENT_REQUEST_SUCCESS,
  POST_ADD_COMMENT_REQUEST_FAILURE,
} from './Actions';
//import {queryApi} from '../query-api';

function* handler() {
  yield takeEvery(POST_ADD_COMMENT_REQUEST, createCommentsRequest);
}


function* createCommentsRequest(action) {
  try {
    // API call
    // console.log(action.parm)
    const apiResponse = yield call(queryApi, {
      endpoint: BASEURL.createCommentsRequest + action.params + "/comment",
      method: "POST",
      body:action.body,
    });
    console.log("HIT REQUESTS_COMMENT", apiResponse);
    if (!("error" in apiResponse)) {
      yield put({
        type: POST_ADD_COMMENT_REQUEST_SUCCESS,
        payload: apiResponse,
      });
    } else {
      yield put({
        type: POST_ADD_COMMENT_REQUEST_FAILURE,
        payload: apiResponse.error,
      });
    }
  } catch (err) {
    console.log(err);
    // Handle error
    yield put({
      type: POST_ADD_COMMENT_REQUEST_FAILURE,
      payload: { message: err },
    });
  }
}

export {handler};