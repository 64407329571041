import {
  StyleSheet,
  Text,
  View,
  Dimensions,
  TouchableOpacity,
} from "react-native";
import React from "react";
import Icon from "react-native-vector-icons/Feather";
import { SafeAreaView } from "react-native-safe-area-context";
const height = Dimensions.get("window").height;

const BeneficiariesList = ({ beneficiaries, navigation }) => {
  return (
    <View style={styles.innerContainer}>
      <TouchableOpacity
        onPress={() =>
          navigation.navigate("BeneficiariesDetail", { beneficiaries })
        }
        style={styles.container}
      >
        <Text style={styles.nameText}>
          {beneficiaries.beneficiary_user.name}
        </Text>
        <View style={styles.numberContainer}>
          <Text style={styles.numberText}>
            {beneficiaries.beneficiary_user.phone_number}
          </Text>
          <Icon name="chevron-right" size={24} />
        </View>
      </TouchableOpacity>
      
    </View>
  );
};

export default BeneficiariesList;

const styles = StyleSheet.create({
  innerContainer: {
    marginTop:17,
  },
  container: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor:'#ffffff',
    height:84,
    borderRadius:16,
    paddingHorizontal:30,
  },
  nameText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 18,
    lineHeight:18,
    color: "#10121F",
    justifyContent: "flex-start",
  },
  numberContainer: {
    flex: 1,
    justifyContent: "flex-end",
    flexDirection: "row",
    alignItems: "center",
  },
  numberText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 18,
    color: "#FF4747",
    lineHeight:18,
    marginRight: 70,
  },
});
