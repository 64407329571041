import * as React from "react";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import AsyncStorage from "@react-native-async-storage/async-storage";

import Gender from "../Screens/Signup/Gender";
import Email from "../Screens/Signup/Email";
import HomeScreen from "../Screens/Signup/HomeScreen";
import Name from "../Screens/Signup/Name";
import PhoneNumber from "../Screens/Signup/PhoneNumber";
import VerificationCode from "../Screens/Signup/VerificationCode";
import LanguageSelector from "../Components/LanguageSelector";
import Nationality from "../Screens/Signup/Nationality";
import Residence from "../Screens/Signup/Residence";
import AddPinCode from "../Screens/PinCode/AddPinCode";
import SignIn from "../Screens/Login/SignIn";
import Password from "../Screens/Signup/Password";
import AppStack from "./AppStack";
import DashboardTabs from "./DashboardTabs";
import ForgetPassword from "../Screens/Login/ForgetPassword";
const Stack = createNativeStackNavigator();
var initialRouteName;
const routeName = () => {
  AsyncStorage.getItem("lang")
    .then((val) => {
      let jsonVal = JSON.parse(val);
      if (!!jsonVal.lang && jsonVal.restarted === true) {
        AsyncStorage.removeItem("lang").then((val => console.log(val))).catch((err) => console.log(err));
        initialRouteName = "HomeScreen";
      } else {
        initialRouteName = "LanguageSelector";
      }
    })
    .catch((err) => console.log(err));
};
routeName();
const AuthStack = () => {
  return (
    <Stack.Navigator
      screenOptions={{ animation: "slide_from_right", orientation: "portrait" }}
      initialRouteName={initialRouteName}>
      <Stack.Screen
        options={{ headerShown: false }}
        name="LanguageSelector"
        component={LanguageSelector}
      />

      {/* //.... Login Screen ...//   */}
      <Stack.Screen
        options={{ headerShown: false }}
        name="SignIn"
        component={SignIn}
      />
      <Stack.Screen
        options={{ headerShown: false }}
        name="ForgetPassword"
        component={ForgetPassword}
      />
      {/* //.... Signup Screen ...//   */}
      <Stack.Screen
        options={{ headerShown: false }}
        name="HomeScreen"
        component={HomeScreen}
      />
      <Stack.Screen
        options={{ headerShown: false }}
        name="PhoneNumber"
        component={PhoneNumber}
      />
      <Stack.Screen
        options={{ headerShown: false }}
        name="VerificationCode"
        component={VerificationCode}
      />
      <Stack.Screen
        options={{ headerShown: false }}
        name="Name"
        component={Name}
      />
      <Stack.Screen
        options={{ headerShown: false }}
        name="Email"
        component={Email}
      />
      <Stack.Screen
        options={{ headerShown: false }}
        name="Password"
        component={Password}
      />
      <Stack.Screen
        options={{ headerShown: false }}
        name="Gender"
        component={Gender}
      />
      <Stack.Screen
        options={{ headerShown: false }}
        name="Nationality"
        component={Nationality}
      />
      <Stack.Screen
        options={{ headerShown: false }}
        name="Residence"
        component={Residence}
      />
      <Stack.Screen
        options={{ headerShown: false }}
        name="AddPinCode"
        component={AddPinCode}
      />
    </Stack.Navigator>
  );
};
export default AuthStack;
