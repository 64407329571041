import { takeEvery, put, call } from "redux-saga/effects";
import BASEURL from "../../URLs";
import { queryApi } from "../../QueryApi";
import {
  GET_USER_DETAILS_REQUEST,
  GET_USER_DETAILS_REQUEST_SUCCESS,
  GET_USER_DETAILS_REQUEST_FAILURE,
  GET_USER_TRANSACTIONS_REQUEST,
  GET_USER_TRANSACTIONS_REQUEST_SUCCESS,
  GET_USER_TRANSACTIONS_REQUEST_FAILURE
} from "./Actions";

function* handler() {
  yield takeEvery(GET_USER_DETAILS_REQUEST, userDetails);
  yield takeEvery(GET_USER_TRANSACTIONS_REQUEST, userTransactions);

}

function* userDetails(action) {
  try {
    // API call
    const apiResponse = yield call(queryApi, {
      endpoint: BASEURL.userDetails,
      method: "GET",
    });
   // console.log('HIT',apiResponse)
    if (!("error" in apiResponse)) {
      yield put({
        type: GET_USER_DETAILS_REQUEST_SUCCESS,
        payload: apiResponse,
      });

    } else {
      yield put({
        type: GET_USER_DETAILS_REQUEST_FAILURE,
        payload: apiResponse.error,
      });
    }
  } catch (err) {
    console.log(err);
    // Handle error
    yield put({
      type: GET_USER_DETAILS_REQUEST_FAILURE,
      payload: { message: err },
    });
  }
}


function* userTransactions(action) {
  try {
    console.log('PARMS',action.params)
   
    //API call
    const apiResponse = yield call(queryApi, {
      endpoint: BASEURL.userTransaction + action.params,
      method: "GET",
    });
    console.log('HIT TRANSACTIONS',apiResponse)
    if (!("error" in apiResponse)) {
      yield put({
        type: GET_USER_TRANSACTIONS_REQUEST_SUCCESS,
        payload: apiResponse,
      });
    } else {
      yield put({
        type: GET_USER_TRANSACTIONS_REQUEST_FAILURE,
        payload: apiResponse.error,
      });
    }
  } catch (err) {
    console.log(err);
    // Handle error
    yield put({
      type: GET_USER_TRANSACTIONS_REQUEST_FAILURE,
      payload: { message: err },
    });
  }
}

export { handler };
