import {
  StyleSheet,
  Text,
  View,
  Dimensions,
  ScrollView,
  TextInput,
  I18nManager,
  TouchableOpacity,
  Platform,
  KeyboardAvoidingView,
  Keyboard,
  SafeAreaView,
} from "react-native";
import ToastAndroid from "react-native-root-toast";
import React, { useState, useEffect ,useRef} from "react";
import Icon from "react-native-vector-icons/Feather";
import { useTranslation } from "react-i18next";
import { Picker } from "@react-native-picker/picker";
import { useSelector, useDispatch } from "react-redux";
import { getCurrencies } from "../../Redux/Models/Currencies/Actions";
import { getUserWallet } from "../../Redux/Models/UserWallet/Actions";
import { changeRequestFamilyStatus } from "../../Redux/Models/User/MyFamily/FamilyMemberTopUp/Actions";
import Loading from "../Loading";
import Alert from "../../Components/Alert";
import AppHeader from "../../Components/AppHeader";

const height = Dimensions.get("window").height;

const QrPay = ({ navigation, route }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state.userDetails);
  const userWallets = useSelector((state) => state.userWallet);
  const currencies = useSelector((state) => state.currencies);
  const familyTopUp = useSelector((state) => state.familyMemberTopUp);
  const [wallet, setWallet] = useState(userDetails.data.user?.accounts[0]);
  const [accountNumber, setAccountNumber] = useState(route.params?.phoneNumber);
  const [amount, setAmount] = useState("");
  const [currency, setCurrency] = useState("");
  const [description, setDescription] = useState("");
  const descriptionRef = useRef();
  const validate = () => {
    Keyboard.dismiss();
    if (amount === "") {
      ToastAndroid.show(t("common:Please_enter_amount"), ToastAndroid.LONG);
      return false;
    } else if (description === "") {
      ToastAndroid.show(
        t("common:Please_enter_the_description"),
        ToastAndroid.LONG
      );
      return false;
    } else if (userDetails.data.user.accounts[0].balance < amount) {
      ToastAndroid.show(
        t("common:Insufficient_balance_in_the_selected_wallet"),
        ToastAndroid.LONG
      );
      return false;
    }
    return true;
  };
  const QrPayApi = () => {
    if (!validate()) {
      return;
    }
    let formData = new FormData();

    formData.append(
      "financial_request[receiver_account_id]",
      userWallets.data.wallets?.length > 0 ?
        userWallets.data.wallets[0]?.id : ""
    );
    formData.append("financial_request[amount]", amount);
    formData.append("financial_request[sender_account_id]", wallet.id);
    formData.append("request_date", Math.floor(new Date().getTime() / 1000));
    formData.append("description", description);
    if (userWallets.data.wallets?.length > 0) {
      navigation.navigate("QrPayVerifyPin", { formData });
    } else {
      return Alert("Error", "PhonNumber does not match any account", [
        {
          text: "OK",
          onPress: () => { navigation.navigate("PhoneNumberScan") },
        },
      ]);
    }
  }
  useEffect(() => {
    if (familyTopUp.error === true) {
      return Alert("Error", familyTopUp.message, [
        {
          text: "OK",
          onPress: () => {
            dispatch(changeRequestFamilyStatus());
          },
        },
      ]);
    }
    if (familyTopUp.success === true) {
      return Alert("Congratulation", familyTopUp.message, [
        {
          text: "OK",
          onPress: () => {
            dispatch(changeRequestFamilyStatus());
            navigation.navigate("DashboardTabs");
          },
        },
      ]);
    }
  }, [familyTopUp]);
  useEffect(() => {
    let parms = new URLSearchParams({
      phone_number: accountNumber,
    });
    dispatch(getUserWallet(parms));
    dispatch(getCurrencies());
  }, []);
  useEffect(() => {
    currencies.success && setCurrency(currencies.data.currencies[0].id);
  }, [currencies]);
  return (
    <SafeAreaView style={styles.mainContainer}>
      <AppHeader navigation={navigation} />
      <View style={styles.topContainer}>
        <View style={styles.topHeaderContainer}>
          <View style={styles.headerView}>
            <Icon
              name="arrow-left"
              size={30}
              onPress={() => navigation.goBack()}
            />
            <Text style={styles.qrText}>{t("common:qr_pay")}</Text>
          </View>
          <KeyboardAvoidingView
            style={{ flex: 1 }}
            behavior={Platform.OS === "ios" ? "padding" : "height"}
          >
            <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
              <View style={styles.innerContainer}>
                <Text style={styles.addressDetailText}>
                  {t("common:please_provide_the_detail_below")}
                </Text>
                <View style={styles.inputContainer}>
                  <View style={styles.inputView}>
                  <Text style={styles.nameText}>
                    {t("common:account_no_st")}
                    <Text style={{ color: "#FF4747" }}>*</Text>
                  </Text>
                  <TextInput
                    textAlign={I18nManager.isRTL ? "right" : "left"}
                    editable={false}
                    value={accountNumber}
                    onChangeText={(text) => setAccountNumber(text)}
                    style={styles.input}
                  ></TextInput>
                </View>
                <View style={[styles.inputView, { marginLeft: 20 }]}>
                  <Text style={styles.nameText}>
                    {t("common:transfer_from_st")}
                    <Text style={{ color: "#FF4747" }}>*</Text>
                  </Text>
                  <View style={styles.pickerView}>
                    <Picker
                      mode="dropdown"
                      selectedValue={wallet}
                      onValueChange={(selectedValue) => setWallet(selectedValue)}
                      style={styles.pickerItem}
                    >
                      {userDetails !== undefined &&
                        userDetails.data.user.accounts.map((val, ind) => {
                          return (
                            <Picker.Item
                              key={ind}
                              label={val.name}
                              value={val}
                              style={styles.pickerItemText}
                            />
                          );
                        })}
                    </Picker>
                  </View>
                </View>
              </View>
              <View style={styles.inputContainer}>
                <View style={styles.inputView}>
                  <Text style={styles.nameText}>
                    {t("common:currency_")}
                    <Text style={{ color: "#FF4747" }}>*</Text>
                  </Text>
                  <View style={styles.pickerView}>
                    <Picker
                      mode="dropdown"
                      selectedValue={currency}
                      onValueChange={(selectedValue) => setCurrency(selectedValue)}
                      style={styles.pickerItem}
                    >
                      {currencies.success === true &&
                        currencies.data.currencies?.map((val) => {
                          return (
                            <Picker.Item
                              key={val.id}
                              label={val.currency_iso_code}
                              value={val.id}
                              style={styles.pickerItemText}
                            />
                          );
                        })}
                    </Picker>
                  </View>
                </View>
                <View style={[styles.inputView, { marginLeft: 20 }]}>
                  <Text style={styles.nameText}>
                    {t("common:amount_st")}
                    <Text style={{ color: "#FF4747" }}>*</Text>
                  </Text>
                  <TextInput
                    textAlign={I18nManager.isRTL ? "right" : "left"}
                    value={amount}
                    keyboardType="number-pad"
                    returnKeyType="done"
                    onSubmitEditing={() => descriptionRef.current.focus()}
                    onChangeText={(text) => setAmount(text)}
                    style={styles.input}
                  ></TextInput>
                </View>
              </View>
              <View style={{ width: 674, marginTop: 20 }}>
                <Text style={styles.nameText}>
                  {t("common:description")}
                </Text>
                <TextInput
                  textAlign={I18nManager.isRTL ? "right" : "left"}
                  value={description}
                  ref={descriptionRef}
                  onChangeText={(text) => setDescription(text)}
                  style={styles.input}
                  returnKeyType="done"
                ></TextInput>
              </View>
              <View style={styles.btnView}>
                <TouchableOpacity onPress={QrPayApi} style={styles.button}>
                  <Text style={styles.buttonText}>{t("common:pay")}</Text>
                </TouchableOpacity>
              </View>
            </View>
          </ScrollView>
        </KeyboardAvoidingView>
      </View>
    </View>
      { familyTopUp.loading && <Loading></Loading> }
    </SafeAreaView >
  );
};

export default QrPay;

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
  },
  topContainer: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "center",
  },
  topHeaderContainer: {
    flex: 1,
    maxWidth: 1400,
  },
  headerView: {
    flexDirection: "row",
    marginTop: 20,
    alignItems: "center",
  },
  qrText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 26,
    color: "#101820",
    marginLeft: 15
  },
  innerContainer: {
    backgroundColor: "#ffffff",
    marginTop: 27,
    borderRadius: 16,
    padding: 20,
  },
  addressDetailText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 18,
    color: "#000000",
    marginTop: 20,
    alignSelf: "flex-start"
  },
  inputContainer: {
    flex: 1,
    flexDirection: "row",
    marginTop: 20
  },
  inputView: {
    flex: 1,
    maxWidth: 327,
  },
  nameText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 14,
    color: "#A9ABAE",
    alignSelf: "flex-start",
  },
  input: {
    borderBottomWidth: 1,
    borderColor: "#E7E8E9",
    height: 56,
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 14,
    color: "#101820",
    outlineStyle: "none"
  },
  pickerView: {
    height: 50,
    justifyContent: "center",
    borderBottomWidth: 1,
    borderColor: "#E7E8E9",
  },
  pickerItem: {
    height: 50,
    borderWidth: 1,
    borderColor: "white",
    borderStyle: "solid",
  },
  pickerItemText: {
    color: "#101820",
    fontSize: 14,
    fontWeight: "500",
    fontFamily: "Inter",
  },
  btnView: {
    flex: 1,
    justifyContent: "flex-end",
    marginTop: 30,
    alignItems: "flex-end"
  },
  button: {
    padding: 16,
    backgroundColor: "#FF4747",
    borderRadius: 70,
    width: 327,
  },
  buttonDisabled: {
    padding: 16,
    borderRadius: 70,
    opacity: 0.5,
    backgroundColor: "#FF4747",
  },
  buttonText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 16,
    alignItems: "center",
    textAlign: "center",
    color: "#FFFFFF",
  },
});
