import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  Image,
  ScrollView,
  TextInput,
  I18nManager,
} from "react-native";
import React, { useState, useRef, useEffect } from "react";
import Icon from "react-native-vector-icons/Feather";
import { useTranslation } from "react-i18next";
import { SafeAreaView } from "react-native-safe-area-context";
import { useDispatch, useSelector } from "react-redux";
import { mdpCardsChangeBalance } from "../../../Redux/Models/MdpCards/MdpCardChangeBalance/Actions";
import SideBarConatainer from "../../../Components/SideBarConatainer";
import Loading from "../../Loading";

const RTL = I18nManager.isRTL;
const ChangeBalance = ({ navigation }) => {
  const { t } = useTranslation();
  const accountIdRef = useRef("");
  const amountRef = useRef("");
  const dispatch = useDispatch();
  const mdpChangeBalance = useSelector((state) => state.mdpCardsChangeBalance);

  const [credit, setCredit] = useState();
  const [accountId, setAccountId] = useState();
  const [amount, setAmount] = useState();
  const changeBalance = () => {
    const formData = new FormData();
    formData.append("debitcredit", credit),
      formData.append("account_id", accountId),
      formData.append("amount", amount);
    console.log("Data:", formData);
    // dispatch(mdpCardsChangeBalance(formData))
  };
  return (
    <SideBarConatainer navigation={navigation}>
      <View style={styles.headerView}>
        <Icon
          name="arrow-left"
          size={30}
          onPress={() => navigation.goBack()}
          style={{ transform: [{ scaleX: RTL ? -1 : 1 }] }}
        />
        <Text style={styles.cardText}>{t("common:change_balance")}</Text>
      </View>
      <ScrollView showsHorizontalScrollIndicator={false}>
        <View style={styles.topContainer}>
          <View style={styles.inputContainer}>
            <View style={styles.inputView}>
              <Text style={styles.inputText}>
                {t("common:credit_debit_card")}
                <Text style={{ color: "#FF4747" }}> *</Text>
              </Text>
              <TextInput
                textAlign={I18nManager.isRTL ? "right" : "left"}
                onSubmitEditing={() => accountIdRef.current.focus()}
                value={credit}
                onChangeText={(text) => setCredit(text)}
                returnKeyType="next"
                style={styles.cardInput}
              ></TextInput>
            </View>
            <View style={[styles.inputView, { marginLeft: 10 }]}>
              <Text style={styles.inputText}>
                {t("common:account_id")}
                <Text style={{ color: "#FF4747" }}> *</Text>
              </Text>
              <TextInput
                textAlign={I18nManager.isRTL ? "right" : "left"}
                onSubmitEditing={() => amountRef.current.focus()}
                ref={accountIdRef}
                value={accountId}
                onChangeText={(text) => setAccountId(text)}
                returnKeyType="next"
                style={styles.cardInput}
              ></TextInput>
            </View>
          </View>
          <View style={styles.inputContainer}>
            <View style={styles.inputView}>
              <Text style={styles.inputText}>
                {t("common:amount")}
                <Text style={{ color: "#FF4747" }}> *</Text>
              </Text>
              <TextInput
                textAlign={I18nManager.isRTL ? "right" : "left"}
                ref={amountRef}
                value={amount}
                onChangeText={(text) => setAmount(text)}
                returnKeyType="done"
                keyboardType="number-pad"
                style={styles.cardInput}
              ></TextInput>
            </View>
          </View>
          <View style={styles.btnView}>
            <TouchableOpacity
              onPress={changeBalance}
              style={styles.continueButton}
            >
              <Text style={styles.continueText}>
                {t("common:change_balance")}
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      </ScrollView>
      {mdpChangeBalance.loading && <Loading></Loading>}
    </SideBarConatainer>
  );
};

export default ChangeBalance;

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 5,
  },
  mainContainer: {
    flex: 1,
    backgroundColor: "#FFFFFF",
  },
  topContainer: {
    flex: 1,
    backgroundColor: "#FFFFFF",
    borderRadius: 10,
    padding: 20,
    marginTop:27
  },
  inputContainer: {
    // flex: 1,
    flexDirection: "row",
  },
  inputView: {
    flex: 1,
    maxWidth: 327,
  },
  headerView: {
    flexDirection: "row",
    alignItems: "center",
  },
  dateOfBirthView: {
    borderBottomWidth: 1,
    borderColor: "#F1F1F1",
    height: 48,
    flexDirection: "row",
    justifyContent: "space-between",
    zIndex: 2000,
  },
  cardText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 26,
    color: "#000000",
    marginLeft: 10,
  },
  inputText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 14,
    color: "#A9ABAE",
    height: 25,
    marginTop: 10,
  },
  cardInput: {
    borderBottomWidth: 1,
    borderColor: "#E7E8E9",
    height: 56,
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 14,
    outlineStyle: "none",
    color: "#101820",
  },
  btnView: {
    flex: 1,
    marginTop: 30,
    alignItems: "flex-end",
  },
  continueButton: {
    padding: 16,
    backgroundColor: "#FF4747",
    borderRadius: 70,
    width: 404,
  },
  continueText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 16,
    alignItems: "center",
    textAlign: "center",
    color: "#FFFFFF",
  },
});
