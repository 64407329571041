import { takeEvery, put, call } from "redux-saga/effects";
import BASEURL from "../../../URLs";
import { queryApi } from "../../../QueryApi";
import {
  POST_FAMILY_MEMBER_TOP_UP_REQUEST,
  POST_FAMILY_MEMBER_TOP_UP_REQUEST_SUCCESS,
  POST_FAMILY_MEMBER_TOP_UP_REQUEST_FAILURE,
} from "./Actions"

function* handler() {
  yield takeEvery(POST_FAMILY_MEMBER_TOP_UP_REQUEST, familyMemberTopUp);
}

function* familyMemberTopUp(action) {
  try {
    // API call
    // console.log(action.formData);
    const apiResponse = yield call(queryApi, {
      endpoint: BASEURL.familyMemberTopUp,
      method: "POST",
      body: action.formData,
    });
    console.log("FamilyApi", apiResponse);
    if (!("error" in apiResponse)) {
      yield put({
        type: POST_FAMILY_MEMBER_TOP_UP_REQUEST_SUCCESS,
        payload: apiResponse,
      });
    } else {
      yield put({
        type: POST_FAMILY_MEMBER_TOP_UP_REQUEST_FAILURE,
        payload: apiResponse.error,
      });
    }
  } catch (err) {
    console.log(err);
    // Handle error
    yield put({
      type: POST_FAMILY_MEMBER_TOP_UP_REQUEST_FAILURE,
      payload: { message: err },
    });
  }
}

export { handler };
