
const POST_SERVICE_VOUCHER_BUY_LIST_REQUEST = 'POST_SERVICE_VOUCHER_BUY_LIST_REQUEST';
const POST_SERVICE_VOUCHER_BUY_LIST_REQUEST_SUCCESS =  'POST_SERVICE_VOUCHER_BUY_LIST_REQUEST_SUCCESS';
const POST_SERVICE_VOUCHER_BUY_LIST_REQUEST_FAILURE =  'POST_SERVICE_VOUCHER_BUY_LIST_REQUEST_FAILURE';

const serviceVoucherBuyList = (formData)=>{

    return {
        type: POST_SERVICE_VOUCHER_BUY_LIST_REQUEST,
        formData
    }
}

export {
  serviceVoucherBuyList,
  POST_SERVICE_VOUCHER_BUY_LIST_REQUEST,
  POST_SERVICE_VOUCHER_BUY_LIST_REQUEST_SUCCESS,
  POST_SERVICE_VOUCHER_BUY_LIST_REQUEST_FAILURE,
};