
const POST_BANK_TOP_UP_UPDATE_REQUEST = 'POST_BANK_TOP_UP_UPDATE_REQUEST';
const POST_BANK_TOP_UP_UPDATE_REQUEST_SUCCESS =  'POST_BANK_TOP_UP_UPDATE_REQUEST_SUCCESS';
const POST_BANK_TOP_UP_UPDATE_REQUEST_FAILURE =  'POST_BANK_TOP_UP_UPDATE_REQUEST_FAILURE';
const RESET_TOPUP_BANK_UPDATE_STATE = 'RESET_TOPUP_BANK_UPDATE_STATE';

const bankTopUpUpdate = (requestId,formData)=>{

    return {
        type: POST_BANK_TOP_UP_UPDATE_REQUEST,
        formData,
        requestId
    }
}
const resetBankTopUpUpdate = ()=>{

  return {
      type: RESET_TOPUP_BANK_UPDATE_STATE,
  }
}

export {
  bankTopUpUpdate,
  resetBankTopUpUpdate,
  POST_BANK_TOP_UP_UPDATE_REQUEST,
  POST_BANK_TOP_UP_UPDATE_REQUEST_SUCCESS,
  POST_BANK_TOP_UP_UPDATE_REQUEST_FAILURE,
  RESET_TOPUP_BANK_UPDATE_STATE
};