import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  ScrollView,
  TextInput,
  I18nManager,
  Dimensions,
} from "react-native";
import React, { useState, useEffect } from "react";
import Icon from "react-native-vector-icons/Feather";
import { useTranslation } from "react-i18next";
import { SafeAreaView } from "react-native-safe-area-context";
import AuthHeader from '../../Components/AuthHeader'

const height = Dimensions.get("window").height;

const Password = ({ navigation, route }) => {
  const [password, setPassword] = useState("");
  const { t } = useTranslation();
  const { formData } = route.params;
  const [disabled, setDisabled] = useState(true);

  const nextStep = () => {
    formData.password = password;
    navigation.navigate("Gender", { formData });
  };
  useEffect(() => {
    if (!!password) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [password]);
  return (
    <SafeAreaView style={styles.mainContainer}>
<AuthHeader/>

      <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
      <View style={styles.subContainer}>
      <View style={styles.mainContectContainer}>
          <View style={styles.innerContainer}>
            <View style={styles.pageTopContainer}>
              <View style={styles.namePageContainer}>
                <Icon
                  onPress={() => navigation.goBack()}
                  name="arrow-left"
                  size={30}
                />
                <Text style={styles.nameText}>
                  {t("common:enter_password")}
                </Text>
              </View>
              <Text style={styles.counterText}>3/6</Text>
            </View>
            <ScrollView>
              <View style={styles.nameView}>
                <Text style={styles.emailText}>{t("common:password")}</Text>
                <TextInput
                  textAlign={I18nManager.isRTL ? "right" : "left"}
                  defaultValue={password}
                  secureTextEntry={true}
                  onChangeText={(newText) => setPassword(newText)}
                  style={styles.emailInput}
                ></TextInput>
              </View>
            </ScrollView>
            <View style={styles.btnView}>
              <TouchableOpacity
                onPress={() => nextStep()}
                style={styles.skipButton}
              >
                <Text style={styles.SkipButtonText}>Skip</Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => nextStep()}
                style={disabled ? styles.buttonDisabled : styles.button}
                disabled={disabled}
              >
                <Text style={styles.buttonText}>{t("common:next")}</Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </View>
      </ScrollView>
    </SafeAreaView>
  );
};

export default Password;

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    backgroundColor: "#F8F8F8",
  },
  topContainer: {
    backgroundColor: "#ffffff",
    paddingTop: 10,
    paddingBottom: 10,
  },
  logoView: {
    color: "#FF4747",
    fontSize: 23,
    marginLeft: 25,
    fontWeight: "700",
  },
  subContainer:{
    flex: 1,
    flexDirection:'row',
    justifyContent:'center'
  },
  mainContectContainer: {
    flex: 1,
    maxHeight:580,
    maxWidth:640,
    marginHorizontal: 15,
    backgroundColor:'#ffffff',
    marginTop: 30,
    borderRadius: 10,
    marginBottom:51,
  },
  namePageContainer: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  innerContainer: {
    margin: 50,
    flex: 1,
  },
  pageTopContainer: {
    flexDirection: "row",
  },
  arrowIcon: {
    fontSize: 25,
    color: "#101820",
  },
  nameText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "700",
    marginLeft: 15,
    fontSize: 26,
    color: "#101820",
    alignSelf: "flex-start",
  },
  counterText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 22,
    marginLeft: 20,
    color: "#000000",
    alignSelf: "center",
  },
  nameView: {
    flex: 1,
    marginTop: 26,
  },
  emailText: {
    fontSize: 14,
    fontWeight: "400",
    color: "#A9ABAE",
    alignSelf: "flex-start",
  },
  emailInput: {
    height: 56,
    borderBottomWidth: 1,
    borderBottomColor: "#E7E8E9",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 14,
    outlineStyle: "none",
  },
  btnView: {
    flex: 1,
    justifyContent: "flex-end",
  },
  button: {
    padding: 16,
    borderRadius: 70,
    backgroundColor: "#FF4747",
  },
  skipButton: {
    justifyContent: "center",
    alignSelf: "center",
    padding: 5,
    borderRadius: 20,
    marginBottom: 20,
  },
  SkipButtonText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 16,
    alignItems: "center",
    textAlign: "center",
    color: "#FF4747",
    borderBottomColor: "#FF4747",
    borderBottomWidth: 1,
  },
  buttonDisabled: {
    padding: 16,
    borderRadius: 70,
    opacity: 0.5,
    backgroundColor: "#FF4747",
  },
  buttonText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 16,
    alignItems: "center",
    textAlign: "center",
    color: "#FFFFFF",
  },
});
