
const POST_MDP_CARDS_CHANGE_BALANCE_REQUEST = 'POST_MDP_CARDS_CHANGE_BALANCE_REQUEST';
const POST_MDP_CARDS_CHANGE_BALANCE_REQUEST_SUCCESS = 'POST_MDP_CARDS_CHANGE_BALANCE_REQUEST_SUCCESS';
const POST_MDP_CARDS_CHANGE_BALANCE_REQUEST_FAILURE = 'POST_MDP_CARDS_CHANGE_BALANCE_REQUEST_FAILURE';

const mdpCardsChangeBalance = (formData) => {

  return {
    type: POST_MDP_CARDS_CHANGE_BALANCE_REQUEST,
    formData
  }
}

export {
  mdpCardsChangeBalance,
  POST_MDP_CARDS_CHANGE_BALANCE_REQUEST,
  POST_MDP_CARDS_CHANGE_BALANCE_REQUEST_SUCCESS,
  POST_MDP_CARDS_CHANGE_BALANCE_REQUEST_FAILURE,
};