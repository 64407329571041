import {
  POST_ADD_FAMILY_MEMBER_REQUEST,
  POST_ADD_FAMILY_MEMBER_REQUEST_SUCCESS,
  POST_ADD_FAMILY_MEMBER_REQUEST_FAILURE,
  ADD_FAMILY_MEMBER_STATUS_RESET
} from "../AddFamilyMember/Actions";

const initialState = {
  message: null,
  data: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case POST_ADD_FAMILY_MEMBER_REQUEST: {
      console.log(POST_ADD_FAMILY_MEMBER_REQUEST);

      return {
        ...state,
        error: false,
        success: false,
        loading: true,
      };
    }
    case POST_ADD_FAMILY_MEMBER_REQUEST_SUCCESS: {
      const { message, data } = action.payload;
      console.log(POST_ADD_FAMILY_MEMBER_REQUEST_SUCCESS);
      return {
        ...state,
        loading: false,
        success: true,
        data,
        message,
      };
    }
    case POST_ADD_FAMILY_MEMBER_REQUEST_FAILURE: {
      console.log(POST_ADD_FAMILY_MEMBER_REQUEST_FAILURE);

      const { message } = action.payload;
      return {
        ...state,
        loading: false,
        success: false,
        error: true,
        message,
      };
    }
    case ADD_FAMILY_MEMBER_STATUS_RESET: {
      console.log(ADD_FAMILY_MEMBER_STATUS_RESET);

      return {
        ...state,
        success: false,
        error: false,
      };
    }
    default:
      return state;
  }
};

export { reducer };
