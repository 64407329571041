import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  ScrollView,
  TextInput,
  I18nManager,
  Dimensions,
} from "react-native";
import React, { useState, useEffect } from "react";
import Icon from "react-native-vector-icons/Feather";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../Loading";
import { SafeAreaView } from "react-native-safe-area-context";
import { useTranslation } from "react-i18next";
import { newPassword } from "../../Redux/Models/User/NewPassword/Action";
import { RESET_FORGET_PASSWORD_STATE } from "../../Redux/Models/User/ForgetPassword/Action";
import ToastAndroid from "react-native-root-toast";

const height = Dimensions.get("window").height;

const ForgetPassword = ({ navigation, route }) => {
  const [newUerPassword, setNewUserPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [otp, setOtp] = useState("");
  const [disabled, setDisabled] = useState(true);
  const { t } = useTranslation();
  const Validate = () => {
    if (newUerPassword !== confirmPassword) {
      ToastAndroid.show(
        t("common:Please_enter_match_password"),
        ToastAndroid.LONG
      );
      return false;
    }
    return true;
  };
  const { phoneNumber } = route.params;
  const userPassword = useSelector((state) => state.newPassword);
  const dispatch = useDispatch();

  const addToForm = new FormData();

  const newPasswordApi = () => {
    if (!Validate()) {
      return;
    }
    addToForm.append("phone_number", phoneNumber);
    addToForm.append("password", newUerPassword);
    addToForm.append("otp", otp);
    console.log(addToForm);
    dispatch(newPassword(addToForm));
    navigation.navigate("SignIn", { phoneNumber });
  };
  useEffect(() => {
    dispatch({ type: RESET_FORGET_PASSWORD_STATE });
  }, []);

  useEffect(() => {
    if (!!newUerPassword && !!confirmPassword && !!otp) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [newUerPassword, confirmPassword, otp]);
  return (
    <SafeAreaView style={styles.mainContainer}>
      <View style={styles.topContainer}>
        <Text style={styles.logoView}>Logo</Text>
      </View>
      <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
        <View style={styles.SubContainer}>
          <View style={styles.mainContectContainer}>
            <View style={styles.innerContainer}>
              <View style={styles.pageTopContainer}>
                <View style={styles.namePageContainer}>
                  <Icon
                    onPress={() => navigation.goBack()}
                    name="arrow-left"
                    size={30}
                    style={styles.arrowIcon}
                  />
                  <Text style={styles.nameText}>
                    {t("common:reset_password")}
                  </Text>
                </View>
              </View>
              <View style={styles.nameView}>
                <TextInput
                  textAlign={I18nManager.isRTL ? "right" : "left"}
                  secureTextEntry={true}
                  placeholder={t("common:New_Password")}
                  value={newUerPassword}
                  onChangeText={(value) => setNewUserPassword(value)}
                  style={styles.passwordInput}
                ></TextInput>
                <TextInput
                  textAlign={I18nManager.isRTL ? "right" : "left"}
                  secureTextEntry={true}
                  placeholder={t("common:Confirm_Password")}
                  value={confirmPassword}
                  onChangeText={(value) => setConfirmPassword(value)}
                  style={styles.passwordInput}
                ></TextInput>
                <TextInput
                  textAlign={I18nManager.isRTL ? "right" : "left"}
                  placeholder={t("common:OTP")}
                  value={otp}
                  onChangeText={(value) => setOtp(value)}
                  style={styles.passwordInput}
                ></TextInput>
              </View>
              <View style={styles.btnView}>
                <TouchableOpacity
                  onPress={() => newPasswordApi()}
                  style={disabled ? styles.buttonDisabled : styles.button}
                  disabled={disabled}
                >
                  <Text style={styles.buttonText}>{t("common:Continue")}</Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

export default ForgetPassword;

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    backgroundColor: "#F8F8F8",
  },

  topContainer: {
    backgroundColor: "#ffffff",
    paddingTop: 10,
    paddingBottom: 10,
    width: "100%",
  },
  logoView: {
    color: "#FF4747",
    fontSize: 23,
    marginLeft: 25,
    fontWeight: "700",
  },
  SubContainer: {
    flex: 1,
    flexDirection:'row',
    justifyContent:'center'

  },
  mainContectContainer: {
    flex: 1,
    maxHeight:580,
    maxWidth:640,
    marginHorizontal: 15,
    backgroundColor:'#ffffff',
    marginTop: 30,
    borderRadius: 10,
    marginBottom:51,
  },
  namePageContainer: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  innerContainer: {
    margin: 50,
    flex: 1,
  },
  pageTopContainer: {
    flexDirection: "row",
  },

  arrowIcon: {
    fontSize: 25,
    color: "#101820",
  },
  nameText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "700",
    marginLeft: 15,
    fontSize: 26,
    color: "#101820",
    alignSelf: "flex-start",
  },
  passwordInput: {
    height: 56,
    color: "#101820",
    borderBottomWidth: 1,
    borderColor: "#E7E8E9",
    marginTop: 25,
    outlineStyle: "none",
  },
  btnView: {
    flex: 1,
    justifyContent: "flex-end",
  },
  button: {
    padding: 16,
    borderRadius: 70,
    backgroundColor: "#FF4747",
  },
  buttonDisabled: {
    padding: 16,
    borderRadius: 70,
    opacity: 0.5,
    backgroundColor: "#FF4747",
  },
  buttonText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 16,
    alignItems: "center",
    textAlign: "center",
    color: "#FFFFFF",
  },
});
