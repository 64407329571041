import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  Dimensions,
  SafeAreaView,
} from "react-native";
import React, { useState, useEffect } from "react";
import Icon from "react-native-vector-icons/Feather";
import { useTranslation } from "react-i18next";
import QrImage from "../../assets/image/qrImage.png";
import { BarCodeScanner } from "expo-barcode-scanner";
const height = Dimensions.get("window").height;
const width = Dimensions.get("window").width;
import AppHeader from "../../Components/AppHeader";
import { Camera } from "expo-camera";

const ScanQrCode = ({ navigation }) => {
  const { t } = useTranslation();
  const [scanned, setScanned] = useState(false);
  const [text, setText] = useState("Not yet scanned");

  // What happens when we scan the bar code
  const handleBarCodeScanned = ({ type, data }) => {
    setScanned(true);
    setText(data);
    navigation.navigate("QrPay", { phoneNumber: data });
    console.log("Scan Type: " + type + "\nScan Data: " + data);
  };

  return (
    <SafeAreaView style={styles.mainContainer}>
      <AppHeader navigation={navigation} />
      <View style={styles.topContainer}>
        <View style={styles.innerContainer}>
          <View style={styles.headerView}>
            <Icon name="arrow-left" size={30} onPress={() => navigation.goBack()} />
            <Text style={styles.scanText}>{t("common:scan")}</Text>
          </View>

          <View style={{ flex: 1 }}>
            <Camera
              barCodeScannerSettings={{
                barCodeTypes: [BarCodeScanner.Constants.BarCodeType.qr],
              }}
              onBarCodeScanned={scanned === "" ? undefined : handleBarCodeScanned}
              style={{
                ...StyleSheet.absoluteFillObject,
                // flex:1,
                marginBottom: 16,
                marginTop: 17,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <View style={styles.barCodeView}></View>
              <View style={styles.phoneBtn}>
                <TouchableOpacity
                  onPress={() => navigation.navigate("PhoneNumberScan")}
                >
                  <Text style={styles.btnText}>{t("common:add_phone_number")}</Text>
                </TouchableOpacity>
              </View>
            </Camera>

          </View>
        </View>
      </View>
    </SafeAreaView>
  );
};

export default ScanQrCode;

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
  },
  topContainer: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "center",
  },
  innerContainer: {
    flex: 1,
    maxWidth: 1400,
  },
  headerView: {
    flexDirection: "row",
    marginTop: 25,
    alignItems: "center",
  },
  barCodeView: {
    borderWidth: 1,
    borderColor: "#FF4747",
    maxHeight: 275,
    minWidth: 275,
    borderRadius: 30,
    flex: 1,
  },
  phoneBtn: {
    marginTop: 80,
    marginBottom: 30,
  },
  scanText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 26,
    color: "#000000",
    marginLeft: 15
  },
  qrScan: {
    height: "100%",
    width: "100%",
  },
  btnText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 18,
    textAlign: "center",
    letterSpacing: 0.25,
    color: "#FFFFFF",
  },
});
