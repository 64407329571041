import {
  StyleSheet,
  Text,
  View,
  Dimensions,
  ScrollView,
  TextInput,
  I18nManager,
  TouchableOpacity,
  Platform,
  KeyboardAvoidingView,
  Keyboard,
  SafeAreaView,
} from "react-native";
import ToastAndroid from "react-native-root-toast";
import React, { useState, useEffect, useRef } from "react";
import Icon from "react-native-vector-icons/Feather";
import { useTranslation } from "react-i18next";
import { Picker } from "@react-native-picker/picker";
import { useSelector, useDispatch } from "react-redux";
import { countryList } from "../../Redux/Models/Countries/Actions";
import { cityList } from "../../Redux/Models/Cities/Actions";
import SideBarConatainer from "../../Components/SideBarConatainer";
import StepContainer from "../../Components/StepContainer";

import Loading from "../Loading";
const height = Dimensions.get("window").height;

const AddressDetail = ({ navigation, route }) => {
  const { t } = useTranslation();
  const countryLists = useSelector((state) => state.countryList);
  const citiesLists = useSelector((state) => state.cityList);
  const [country, setCountry] = useState(
    countryLists.data.countryList !== undefined &&
    countryLists.data.countryList[0].id
  );
  const [district, setDistrict] = useState("");
  const [address, setAddress] = useState("");
  const [street, setStreet] = useState("");
  const [city, setCity] = useState(
    citiesLists.data.cities !== undefined && citiesLists.data.cities[0].id
  );
  const [zipCode, setZipCode] = useState("");
  const [disabled, setDisabled] = useState(true);

  const { formData } = route.params;
  // console.log(formData);
  const streetRef = useRef();
  const districtRef = useRef();
  const zipCodeRef = useRef();

  const dispatch = useDispatch();
  const validate = () => {
    Keyboard.dismiss();
    if (address === "") {
      ToastAndroid.show(t("common:Please_enter_address"), ToastAndroid.LONG);
      return false;
    } else if (street === "") {
      ToastAndroid.show(
        t("common:Please_Enter_the_Street_Number"),
        ToastAndroid.LONG
      );
      return false;
    } else if (district === "") {
      ToastAndroid.show(
        t("common:Please_select_the_District"),
        ToastAndroid.LONG
      );
      return false;
    } else if (zipCode === "") {
      ToastAndroid.show(t("common:Please_Enter_Zip_Code"), ToastAndroid.LONG);
      return false;
    }
    return true;
  };
  const nextStep = () => {
    if (!validate()) {
      return;
    }
    formData.address = address;
    formData.street = street;
    formData.city = city;
    formData.district = district;
    formData.zip_code = zipCode;
    formData.country_id = country;
    navigation.navigate("FinancialInfo", { formData });
  };
  useEffect(() => {
    if (!!address && !!street && !!zipCode) {
      setDisabled(false);
    } else {
      setDisabled(false);
    }
  }, [address, street, zipCode]);
  useEffect(() => {
    dispatch(countryList());
    dispatch(cityList());
  }, []);
  return (
    <SideBarConatainer navigation={navigation}>
      <View style={styles.headerView}>
        <Icon
          name="arrow-left"
          size={30}
          style={styles.arrowIcon}
          onPress={() => navigation.goBack()}
        />
        <Text style={styles.kycText}>{t("common:kyc")}</Text>
      </View>
      <View style={styles.docContainer}>
        <ScrollView showsHorizontalScrollIndicator={false}>
          <View style={styles.mainContainer}>
            <StepContainer totalStep={3} currentStep={2} />
            <KeyboardAvoidingView
              style={{ flex: 1 }}
              behavior={Platform.OS === "ios" ? "padding" : "height"}
            >
              <View style={styles.innerContainer}>
                <Text style={styles.personalInfoText}>
                  {t("common:provide_address_detail")}
                </Text>
                <View style={styles.inputContainer}>
                  <View style={styles.inputView}>
                    <Text style={styles.nameText}>
                      {t("common:address_st")}
                      <Text style={{ color: "#FF4747" }}>*</Text>
                    </Text>
                    <TextInput
                      textAlign={I18nManager.isRTL ? "right" : "left"}
                      value={address}
                      returnKeyType="next"
                      onSubmitEditing={() => streetRef.current.focus()}
                      onChangeText={(text) => setAddress(text)}
                      style={styles.input}
                    ></TextInput>
                  </View>
                  <View style={{ ...styles.inputView, marginLeft: 20 }}>
                    <Text style={styles.nameText}>
                      {t("common:street_st")}
                      <Text style={{ color: "#FF4747" }}>*</Text>
                    </Text>
                    <TextInput
                      textAlign={I18nManager.isRTL ? "right" : "left"}
                      value={street}
                      returnKeyType="next"
                      onSubmitEditing={() => districtRef.current.focus()}
                      ref={streetRef}
                      onChangeText={(text) => setStreet(text)}
                      style={styles.input}
                    ></TextInput>
                  </View>
                </View>

                <View style={styles.inputContainer}>
                  <View style={styles.inputView}>
                    <Text style={styles.nameText}>
                      {t("common:country_st")}
                      <Text style={{ color: "#FF4747" }}>*</Text>
                    </Text>
                    <View style={styles.pickerView}>
                      <Picker
                        mode="dropdown"
                        selectedValue={country}
                        onValueChange={(selectedValue) =>
                          setCountry(selectedValue)
                        }
                        style={styles.pickerItem}
                      >
                        {countryLists.data.countryList?.map((val, ind) => (
                          <Picker.Item
                            key={ind}
                            label={val.country_name}
                            value={val.id}
                            style={styles.pickerItemText}
                          />
                        ))}
                      </Picker>
                    </View>
                  </View>
                  <View style={{ ...styles.inputView, marginLeft: 20 }}>
                    <Text style={styles.nameText}>
                      {t("common:city")}
                      <Text style={{ color: "#FF4747" }}>*</Text>
                    </Text>
                    <View style={styles.pickerView}>
                      <Picker
                        mode="dropdown"
                        selectedValue={city}
                        onValueChange={(selectedValue) => setCity(selectedValue)}
                        style={styles.pickerItem}
                      >
                        {citiesLists.data.cities?.map((val, ind) => (
                          <Picker.Item
                            key={ind}
                            label={val.city_name}
                            value={val.id}
                            style={styles.pickerItemText}
                          />
                        ))}
                      </Picker>
                    </View>
                  </View>
                </View>
                <View style={styles.inputContainer}>
                  <View style={styles.inputView}>
                    <Text style={styles.nameText}>
                      {t("common:district_st")}
                      <Text style={{ color: "#FF4747" }}>*</Text>
                    </Text>
                    <TextInput
                      textAlign={I18nManager.isRTL ? "right" : "left"}
                      value={district}
                      returnKeyType="next"
                      onSubmitEditing={() => zipCodeRef.current.focus()}
                      ref={districtRef}
                      onChangeText={(text) => setDistrict(text)}
                      style={styles.input}
                    ></TextInput>
                  </View>
                  <View style={{ ...styles.inputView, marginLeft: 20 }}>
                    <TextInput
                      textAlign={I18nManager.isRTL ? "right" : "left"}
                      value={zipCode}
                      ref={zipCodeRef}
                      onChangeText={(text) => setZipCode(text)}
                      keyboardType="number-pad"
                      returnKeyType="done"
                      placeholder={t("common:zip_code")}
                      style={{ ...styles.input, marginTop: 30 }}
                    ></TextInput>
                  </View>
                </View>
                <View style={styles.btnView}>
                  <TouchableOpacity onPress={nextStep} style={styles.button}>
                    <Text style={styles.buttonText}>
                      {t("common:save_and_continue")}
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>
            </KeyboardAvoidingView>
          </View>
        </ScrollView>
      </View>
      {(countryLists.loading || citiesLists.loading) && <Loading></Loading>}
    </SideBarConatainer>
  );
};

export default AddressDetail;

const styles = StyleSheet.create({
  headerView: {
    flexDirection: "row",
  },
  kycText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 26,
    color: "#000000",
    marginLeft: 10,
  },
  docContainer: {
    backgroundColor: "#FFF",
    borderRadius: 16,
    marginTop: 27,
    flex: 1,
  },
  mainContainer: {
    padding: 20,
  },
  innerContainer: {
    flex: 1,
    marginLeft: 24,
    marginRight: 24,
  },
  personalInfoText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 18,
    color: "#000000",
    marginTop: 20,
    alignSelf: "flex-start",
  },
  inputContainer: {
    flex: 1,
    flexDirection: "row",
  },
  inputView: {
    flex: 1,
    maxWidth: 327,
  },
  nameText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 14,
    marginTop: 10,
    color: "#A9ABAE",
    alignSelf: "flex-start",
  },
  input: {
    height: 56,
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 14,
    borderColor: "#E7E8E9",
    borderBottomWidth: 1,
    outlineStyle: "none",
    color: "#101820",
  },
  pickerView: {
    borderBottomWidth: 1,
    borderColor: "#E7E8E9",
    height: 56,
    justifyContent: "center",
  },
  pickerItem: {
    height: 45,
    borderWidth: 1,
    borderColor: "white",
    borderStyle: "solid",
  },
  pickerItemText: {
    color: "#101820",
    fontSize: 14,
    fontWeight: "500",
    fontFamily: "Inter",
  },
  btnView: {
    flex: 1,
    marginTop: 30,
    alignItems: "flex-end",
  },
  button: {
    padding: 16,
    backgroundColor: "#FF4747",
    borderRadius: 70,
    width: 404,
  },
  buttonText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 16,
    alignItems: "center",
    textAlign: "center",
    color: "#FFFFFF",
  },
});
