import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  TouchableOpacity,
  Dimensions,
  SafeAreaView,
  I18nManager
} from "react-native";
import React, { useState } from "react";
import Icon from "react-native-vector-icons/Feather";
import { useTranslation } from "react-i18next";
const RTL = I18nManager.isRTL;

const TransactionSummary = ({ navigation }) => {
  const { t } = useTranslation();

  return (
    <SafeAreaView style={styles.mainContainer}>
      <View style={styles.headerView}>
        <Icon
          name="arrow-left"
          size={24}
          style={styles.arrowIcon}
          onPress={() => navigation.goBack()}
        />
        <Text style={styles.transactionText}>
          {t("common:transaction_summary")}
        </Text>
      </View>
      <View style={styles.topContainer}>
        <View style={styles.innerContainer}>
          <View style={styles.container}>
            <Text style={styles.nameText}>{t("common:sender_name")}</Text>
            <Text style={styles.summaryText}>ABC</Text>
          </View>

          <View style={styles.container}>
            <Text style={styles.nameText}>{t("common:receivers_name")}</Text>
            <Text style={styles.summaryText}>XYZ</Text>
          </View>

          <View style={styles.container}>
            <Text style={styles.accountNumberText}>
              {t("common:receivers_account_number")}
            </Text>
            <Text style={styles.summaryText}>122365476</Text>
          </View>

          <View style={styles.container}>
            <Text style={styles.nameText}>{t("common:TransactionID")}</Text>
            <Text style={styles.summaryText}>23233544111</Text>
          </View>

          <View style={styles.container}>
            <Text style={styles.nameText}>{t("common:amount")}</Text>
            <Text style={styles.summaryText}>LYD 120</Text>
          </View>

          <View style={[styles.feeView, styles.container]}>
            <Text style={styles.nameText}>{t("common:fee")}</Text>
            <Text style={styles.summaryText}>LYD 0</Text>
          </View>
          <View style={styles.container}>
            <Text style={styles.nameText}>{t("common:Tax")}</Text>
            <Text style={styles.summaryText}>LYD 0</Text>
          </View>
          <View style={styles.btnView}>
            <TouchableOpacity
              onPress={() => navigation.navigate("Transfer")}
              style={styles.button}
            >
              <Text style={styles.buttonText}>{t("common:Confirm")}</Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </SafeAreaView>
  );
};

export default TransactionSummary;

const styles = StyleSheet.create({
  mainContainer: {
    flex:1,
    backgroundColor: "#FFFFFF",
  },
  headerView: {
    flexDirection: "row",
    marginTop: 40,
    marginBottom: 40,
    alignItems: "center",
  },
  arrowIcon: {
    marginLeft: 24,
    transform: [{ scaleX: RTL ? -1 : 1 }]
  },
  transactionText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 22,
    color: "#000000",
    marginLeft: 40,
  },
  topContainer: {
    flex: 1,
  },
  innerContainer: {
    flex: 1,
    marginLeft: 24,
    marginRight: 24,
  },
  container: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop:24
  },
  nameText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 14,
    color: "#101820",
  },
  summaryText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 14,
    textAlign: "right",
    color: "#FF4747",
  },
  accountNumberText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 14,
    color: "#101820",
  },
  btnView: {
    flex: 1,
    justifyContent: "flex-end",
    marginBottom: 51,
  },
  button: {
    backgroundColor: "#FF4747",
    borderRadius: 70,
    padding: 16,
  },
  buttonText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 16,
    alignItems: "center",
    textAlign: "center",
    color: "#FFFFFF",
  },
});
