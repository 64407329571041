import {
  StyleSheet,
  Text,
  View,
  Dimensions,
  ScrollView,
  TextInput,
I18nManager,
  TouchableOpacity,
  Image,
  SafeAreaView,
} from "react-native";
import React, { useState } from "react";
import Icon from "react-native-vector-icons/Feather";
import { useTranslation } from "react-i18next";
import { Picker } from "@react-native-picker/picker";
import Add from "../../assets/image/add.png";
const height = Dimensions.get("window").height;

const AddExistingCbl = ({ navigation }) => {
  const { t } = useTranslation();
  const [branch, setBranch] = useState("");
  const [bankName,setBankName]=useState("");
  const [cblNumber, setCblNumber] = useState("");
  return (
    <SafeAreaView style={styles.topContainer}>
      <View style={styles.headerView}>
        <Icon name="arrow-left" size={24} onPress={() => navigation.goBack()} />
        <Text style={styles.editText}>{t("common:add_existing_cbl")}</Text>
      </View>
      <ScrollView contentContainerStyle={styles.scrollView}>
        <View style={styles.innerContainer}>
          <Text style={styles.provideText}>
            {t("common:provide_existing_CBL_details")}
          </Text>
          <Text style={[styles.nameText, styles.bankText]}>
            {t("common:bank_name_st")}
            <Text style={{ color: "#FF4747" }}>*</Text>
          </Text>
          <TextInput 
 textAlign={I18nManager.isRTL ? "right" :"left"} 
            defaultValue={bankName}
            onChangeText={(newText) => setBankName(newText)}
            style={[styles.input, styles.bankInput]}
          ></TextInput>

          <Text style={[styles.nameText, styles.branchText]}>
            {t("common:branch_st")}
            <Text style={{ color: "#FF4747" }}>*</Text>
          </Text>
          <View style={styles.pickerView}>
            <Picker
              mode="dropdown"
              selectedValue={branch}
              onValueChange={(selectedValue) => setBranch(selectedValue)}
              style={styles.relationPickerText}
            >
              <Picker.Item
                label="Libyan"
                value="Libyan"
                style={styles.pickerItemText}
              />
            </Picker>
          </View>
          <Text style={[styles.nameText, styles.cblText]}>
            {t("common:cbl_number")}
          </Text>
          <TextInput 
 textAlign={I18nManager.isRTL ? "right" :"left"} 
            keyboardType="number-pad"
returnKeyType="done"
            defaultValue={cblNumber}
            onChangeText={(newText) => setCblNumber(newText)}
            style={[styles.input, styles.cblInput]}
          ></TextInput>
          <Text style={styles.documentText}>{t("common:documents")}</Text>
          <Text style={styles.supportingText}>
            {t("common:kindly_attach_supporting")}
          </Text>
          <View style={styles.logoView}>
            <TouchableOpacity style={styles.addBtn}>
              <Image source={Add} style={styles.addImage} />
            </TouchableOpacity>
          </View>
          <View style={styles.btnView}>
            <TouchableOpacity
              onPress={() => navigation.navigate("BeneficiariesDetail")}
              style={styles.button}
            >
              <Text style={styles.buttonText}>{t("common:save")}</Text>
            </TouchableOpacity>
          </View>
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

export default AddExistingCbl;

const styles = StyleSheet.create({
  topContainer: {
    flex:1,
    backgroundColor: "#FFFFFF",
  },
  headerView: {
    flexDirection: "row",
    marginTop: 44,
    marginBottom: 40,
    alignItems: "center",
    marginLeft: 24,
  },
  editText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 22,
    color: "#000000",
    marginLeft: 56,
    textAlign:"center"
  },
  scrollView: {
    height: height,
  },
  innerContainer:{
    flex:1,
    marginLeft:24,
    marginRight:24
  },
  provideText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 18,
    color: "#000000",
  },
  nameText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 14,
    color: "#A9ABAE",
  },
  input: {
    borderBottomWidth: 1,
    borderColor: "#E7E8E9",
    height: 60,
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 14,
    color: "#101820",
  },
  bankText: {
    top: 30,
  },
  bankInput: {
    top: 20,
  },
  branchText: {
    top: 50,
  },
  pickerView: {
    top: 40,
    borderBottomWidth: 1,
    borderColor: "#E7E8E9",
  },
  branchPickerText: {
  },
  pickerItemText: {
    color: "#101820",
    fontSize: 14,
    fontWeight: "500",
    fontFamily: "Inter",
  },
  cblText: {
    top: 70,
  },
  cblInput: {
    top: 60,
  },
  documentText: {
    top: 90,
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 18,
    color: "#000000",
  },
  supportingText: {
    top: 100,
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 14,
    color: "#101820",
  },
  logoView: {
    flexDirection: "row",
    flexWrap: "wrap",
    top: 130,
  },
  addBtn: {
    justifyContent: "center",
    alignItems: "center",
    bottom: 26.88,
    borderWidth: 1,
    borderRadius: 16,
    borderColor: "#DDDDDE",
    height: 90,
    width: 98,
  },
  addImage: {
    width: 20,
    height: 20,
  },
  btnView:{
    flex:1,
    justifyContent:"flex-end",
    marginBottom:51
  },
  button: {
    backgroundColor: "#FF4747",
    borderRadius: 70,
    padding:16
  },
  buttonText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 16,
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    color: "#FFFFFF",
  },
});
