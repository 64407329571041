import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  Image,
  TextInput,
  I18nManager,
  ScrollView,
  SafeAreaView,
} from "react-native";
import ToastAndroid from "react-native-root-toast";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Money from "../../assets/image/money.png";
import Lyd from "../../assets/image/lyd.png";
import Profile from "../../assets/image/profile.png";
import { Picker } from "@react-native-picker/picker";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../Loading";
import { changeRequestFamilyStatus } from "../../Redux/Models/User/MyFamily/FamilyMemberTopUp/Actions";
import { getUserWallet } from "../../Redux/Models/UserWallet/Actions";
import { getCurrencies } from "../../Redux/Models/Currencies/Actions";
import Alert from "../../Components/Alert";


const FamilyTopUp = ({ navigation, route }) => {
  const { t } = useTranslation();
  const [currency, setCurrency] = useState("");
  const [amount, setAmount] = useState("");
  const [description, setDescription] = useState("");
  const [disabled, setDisabled] = useState(true);

  const dispatch = useDispatch();
  const familyTopUp = useSelector((state) => state.familyMemberTopUp);
  const selectedFamilyMember = useSelector(
    (state) => state.myFamilyList.selectedFamilyMember
  );
  const userDetails = useSelector((state) => state.userDetails);
  const [wallet, setWallet] = useState(userDetails?.data.user.accounts[0]);
  const currencies = useSelector((state) => state.currencies);
  const FamilyTopUpApi = () => {
    if (userDetails.data.user.accounts[0].balance < amount) {
      ToastAndroid.show(
        t("common:Insufficient_balance_in_the_selected_wallet"),
        ToastAndroid.LONG
      );
      return;
    }
    let formData = new FormData();
    formData.append(
      "financial_request[receiver_account_id]",
      route.params?.wallet.id
    );
    formData.append("financial_request[amount]", amount);
    formData.append("financial_request[sender_account_id]", wallet.id);
    formData.append("request_date", Math.ceil(new Date().getTime() / 1000));
    formData.append("description", description);
    navigation.navigate("FamilyTopUpVerifyPin", { formData });
  };
  useEffect(() => {
    dispatch(getCurrencies());
  }, []);

  useEffect(() => {
    if (familyTopUp.error === true) {
      return Alert("Error", familyTopUp.message, [
        {
          text: "OK",
          onPress: () => {
            dispatch(changeRequestFamilyStatus());
            navigation.navigate("FamilyMember");
          },
        },
      ]);
    }
    if (familyTopUp.success === true) {
      return Alert("Congratulation", familyTopUp.message, [
        {
          text: "OK",
          onPress: () => {
            dispatch(changeRequestFamilyStatus());
            navigation.navigate("FamilyMember");
          },
        },
      ]);
    }
  }, [familyTopUp]);
  useEffect(() => {
    if (!!amount && !!description) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [amount, description]);
  return (
    <SafeAreaView style={styles.topContainer}>
      <ScrollView contentContainerStyle={{flexGrow:1}} showsHorizontalScrollIndicator={false}>
        <View style={styles.innerContainer}>
          <View style={styles.container}>
            <Image
              source={
                selectedFamilyMember.profile_image === null
                  ? Profile
                  : { uri: selectedFamilyMember.profile_image }
              }
              style={styles.image1}
            />
            <Text style={styles.name}>
              {selectedFamilyMember.name.split(" ")[0]}
            </Text>
          </View>
          <Text style={styles.nameText}>
            {t("common:transfer_from_st")}
            <Text style={{ color: "#FF4747" }}>*</Text>
          </Text>
          <View style={styles.transferFromView}>
            <Image source={Lyd} style={styles.lydImage} />
            <Picker
              mode="dropdown"
              selectedValue={wallet}
              onValueChange={(selectedValue) => setWallet(selectedValue)}
              style={styles.pickerItem}
            >
              {userDetails !== undefined &&
                userDetails.data.user.accounts.map((val, ind) => {
                  return (
                    <Picker.Item
                      key={ind}
                      label={val.name}
                      value={val}
                      style={styles.pickerItemText}
                    />
                  );
                })}
            </Picker>
          </View>
          <Text style={styles.nameText}>
            {t("common:amount_st")}
            <Text style={{ color: "#FF4747" }}>*</Text>
          </Text>
          <View style={styles.sendView}>
            <TextInput
              textAlign={I18nManager.isRTL ? "right" : "left"}
              returnKeyType="next"
              autoFocus={true}
              defaultValue={amount}
              onChangeText={(newText) => setAmount(newText)}
              keyboardType="number-pad"
              style={styles.sendInput}
            ></TextInput>
            <View style={styles.pickerView}>
              <Image source={Money} style={styles.lydImage} />
              <Picker
                mode="dropdown"
                selectedValue={currency}
                onValueChange={(selectedValue) => setCurrency(selectedValue)}
                style={styles.pickerText}
              >
                {currencies.success === true &&
                  currencies.data.currencies?.map((val) => {
                    return (
                      <Picker.Item
                        key={val.id}
                        label={val.currency_iso_code}
                        value={val.id}
                        style={styles.pickerItemText}
                      />
                    );
                  })}
              </Picker>
            </View>
          </View>
          <TextInput
            textAlign={I18nManager.isRTL ? "right" : "left"}
            defaultValue={description}
            onChangeText={(newText) => setDescription(newText)}
            placeholder={t("common:description")}
            style={styles.accountInput}
            returnKeyType="done"
          ></TextInput>
          <View style={styles.btnView}>
          <TouchableOpacity
            onPress={FamilyTopUpApi}
            style={disabled ? styles.buttonDisabled : styles.button}
            disabled={disabled}
          >
            <Text style={styles.buttonText}>{t("common:top_up")}</Text>
          </TouchableOpacity>
          </View>
        </View>
      </ScrollView>
      {familyTopUp.loading && <Loading></Loading>}
    </SafeAreaView>
  );
};

export default FamilyTopUp;

const styles = StyleSheet.create({
  topContainer: {
    flex: 1,
    marginTop: 20,
    backgroundColor: "#FFFFFF",
    borderRadius: 16,
    padding: 20,
    marginBottom: 25,
  },
  innerContainer: {
   flex:1,
   padding:20
  },
  container: {
    marginTop: 30,
    alignItems: "center",
    flexDirection: "row",
  },
  image1: {
    height: 48,
    width: 48,
    borderRadius: 25,
  },
  name: {
    marginLeft: 16,
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 14,
    color: "#10121F",
  },
  nameText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 14,
    color: "#A9ABAE",
    marginTop: 10,
    alignSelf: "flex-start"
  },
  accountInput: {
    height: 56,
    marginTop: 30,
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 14,
    borderColor: "#E7E8E9",
    borderBottomWidth: 1,
    outlineStyle:"none",
    color:"#101820",
  },
  transferFromView: {
    flexDirection: "row",
    borderColor: "#E7E8E9",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 56,
    marginTop:10
  },
  lydImage: {
    width: 24,
    height: 24,
  },
  pickerItem: {
    flex:1,
    height:45,
    borderWidth:1,
    borderColor:"white",
    borderStyle:"solid",
  },
  pickerItemText: {
    color: "#101820",
    fontSize: 14,
    fontWeight: "500",
    fontFamily: "Inter",
  },
  sendView: {
    flexDirection: "row",
    marginTop:10
  },
  sendInput: {
    flex:1,
    borderBottomWidth: 1,
    borderColor: "#F1F1F1",
    fontFamily: "Inter",
    fontSize: 14,
    fontWeight: "500",
    outlineStyle:"none",
    height:56,
    color:"#101820",
  },
  pickerView: {
    flex:1,
    borderBottomWidth: 1,
    borderColor: "#F1F1F1",
    marginLeft: 20,
    flexDirection: "row",
    alignItems: "center",
    height:56,
    justifyContent:"center"
  },
  pickerText: {
    flex:1,
    height:45,
    borderWidth:1,
    borderColor:"white",
    borderStyle:"solid",
  },
  btnView:{
    flex:1,
    alignItems:"flex-end",
  },
  button: {
    backgroundColor: "#FF4747",
    borderRadius: 70,
    padding: 16,
    width:327,
    marginTop:20,
  },
  buttonDisabled: {
    padding: 16,
    borderRadius: 70,
    opacity: 0.5,
    width:327,
    marginTop:20,
    backgroundColor: "#FF4747",
  },
  buttonText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 16,
    alignItems: "center",
    textAlign: "center",
    color: "#FFFFFF",
  },
});
