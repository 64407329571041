import { combineReducers } from "redux";
import { reducer as checkPhoneNumberReducer } from "./CheckPhoneNumber/Reducer";
import { reducer as userReducer } from "./User/Login/Reducer";
import { reducer as userPassCodeReducer } from "./User/VerifyPassCode/Reducer";
import { reducer as userRegisterReducer } from "./User/Register/Reducer";
import { reducer as userDetailsReducer } from "./User/UserDetails/Reducer";
import { reducer as forgetPasswordReducer } from "./User/ForgetPassword/Reducer";
import { reducer as newPasswordReducer } from "./User/NewPassword/Reducer";
import { reducer as currenciesReducer } from "./Currencies/Reducer";
import { reducer as tizdanBankAccountsReducer } from "./TizdanBankAccounts/Reducer";
import { reducer as documentUploadReducer } from "./DocumentUpload/Reducer";
import { reducer as requestBankTopUpReducer } from "./RequestBankTopUp/Reducer";
import { reducer as myFamilyListReducer } from "./User/MyFamily/MyFamilyList/Reducer";
import { reducer as addFamilyMemberReducer } from "./User/MyFamily/AddFamilyMember/Reducer";
import { reducer as editFamilyMemberReducer } from "./User/MyFamily/EditFamilyMember/Reducer";
import { reducer as deleteFamilyMemberReducer } from "./User/MyFamily/DeleteMember/Reducer";
import { reducer as changeMyFamilyPasswordReducer } from "./User/MyFamily/ChangePassword/Reducer";
import { reducer as myRequestReducer } from "./MyRequests/Reducer";
import { reducer as beneficiariesReducer } from "./Beneficiaries/Reducer";
import { reducer as requestKycReducer } from "./RequestKyc/Reducer";
import { reducer as countryListReducer } from "./Countries/Reducer";
import { reducer as requestTransferReducer } from "./RequestTransfer/Reducer";
import { reducer as giftCardCompanyListReducer } from "./GiftCardCompanyList/Reducer";
import { reducer as giftCardBuyListReducer } from "./GiftCardBuyList/Reducer";
import { reducer as serviceVoucherCompanyListReducer } from "./ServiceVoucherCompanyList/Reducer";
import { reducer as serviceVoucherBuyListReducer } from "./ServiceVoucherBuyList/Reducer";
import { reducer as requestCblReducer } from "./RequestCbl/Reducer";
import { reducer as cityListReducer } from "./Cities/Reducer";
import { reducer as buyGiftCardReducer } from "./BuyGiftCard/Reducer";
import { reducer as buyServiceVoucherReducer } from "./BuyServiceVoucher/Reducer";
import { reducer as verifyDocumentReducer } from "./VerifyDocuments/Reducer";
import { reducer as familyMemberTopUpReducer } from "./User/MyFamily/FamilyMemberTopUp/Reducer";
import { reducer as cblVerifyDocumentReducer } from "./CblVerifyDocuments/Reducer";
import { reducer as userWalletReducer } from "./UserWallet/Reducer";
import { reducer as deleteFileReducer } from "./DeleteDocument/Reducer";
import { reducer as bankDepositSlipReducer } from "./DocumentUpload/BankDepositSlip/Reducer";
import { reducer as cblDocumentsReducer } from "./DocumentUpload/CBLDocuments/Reducer";
import { reducer as familyMemberTransactionsReducer } from "./User/MyFamily/FamilyMemberTransactions/Reducer";
import { reducer as requestDetailsReducer } from "./RequestDetails/Reducer";
import { reducer as createCommentsRequestReducer } from "./Comments/RequestAddComment/Reducer";
import { reducer as getRequestCommentListReducer } from "./Comments/RequestListComment/Reducer";
import { reducer as requestUpdateReducer } from "./RequestUpdate/Reducer";
import { reducer as topBankUpdateReducer } from "./TopBankUpdate/Reducer";
import { reducer as cblRequestUpdateReducer } from "./CblRequestUpdate/Reducer";
import { reducer as cblNumberReducer } from "./CblNumber/Reducer";
import { reducer as requestExchangeReducer } from "./ExchangeMoney/Reducer";
import { reducer as changePinCodeReducer } from "./User/ChangePinCode/Reducer";
import { reducer as mdpCreateCardReducer } from "./MdpCards/MdpCreateCardRequest/Reducer";
import { reducer as mdpCardsListReducer } from "./MdpCards/MdpCardsList/Reducer";
import { reducer as mdpCardsKycStatusReducer } from "./MdpCards/MdpCardsKycStatus/Reducer";
import { reducer as mdpCardsCustomerStatusReducer } from "./MdpCards/MdpCardsCustomerStatus/Reducer";
import { reducer as mdpCardsKycUpdateReducer } from "./MdpCards/MdpCardsKycUpdate/Reducer";
import { reducer as mdpCardsSetPinReducer } from "./MdpCards/MdpCardsSetPin/Reducer";
import { reducer as mdpCardsChangeBalanceReducer } from "./MdpCards/MdpCardChangeBalance/Reducer";
import { reducer as mdpCardsBalanceReducer } from "./MdpCards/MdpCardCurrentBalance/Reducer";
import { reducer as mdpCardsLookupProductsReducer } from "./MdpCards/MdpCardsLookupProducts/Reducer";
import { reducer as mdpCardsLookupIdTypesReducer } from "./MdpCards/MdpCardsLookupIdTypes/Reducer";
import { reducer as mdpCardsLookupLanguagesReducer } from "./MdpCards/MdpCardsLookupLanguages/Reducer";
import { reducer as mdpCardsLookupCountriesReducer } from "./MdpCards/MdpCardsLookupCountries/Reducer";
import { reducer as mdpCardsLookupNationalitiesReducer } from "./MdpCards/MdpCardsLookupNationalities/Reducer";

const reducer = combineReducers({
  checkPhoneNumber: checkPhoneNumberReducer,
  user: userReducer,
  VerifyPassCode: userPassCodeReducer,
  register: userRegisterReducer,
  userDetails: userDetailsReducer,
  forgetPassword: forgetPasswordReducer,
  newPassword: newPasswordReducer,
  currencies: currenciesReducer,
  TizdanBankAccounts: tizdanBankAccountsReducer,
  DocumentUpload: documentUploadReducer,
  requestBankTopUp: requestBankTopUpReducer,
  myFamilyList: myFamilyListReducer,
  addFamilyMember: addFamilyMemberReducer,
  editFamilyMember: editFamilyMemberReducer,
  deleteFamilyMember: deleteFamilyMemberReducer,
  myRequests: myRequestReducer,
  beneficiaries: beneficiariesReducer,
  requestKyc: requestKycReducer,
  countryList: countryListReducer,
  requestTransfer: requestTransferReducer,
  giftCardCompanyList: giftCardCompanyListReducer,
  giftCardBuyList: giftCardBuyListReducer,
  serviceVoucherCompanyList: serviceVoucherCompanyListReducer,
  serviceVoucherBuyList: serviceVoucherBuyListReducer,
  changeMyFamilyPassword: changeMyFamilyPasswordReducer,
  requestCbl: requestCblReducer,
  cityList: cityListReducer,
  buyGiftCard: buyGiftCardReducer,
  buyServiceVoucher: buyServiceVoucherReducer,
  verifyDocument: verifyDocumentReducer,
  familyMemberTopUp: familyMemberTopUpReducer,
  cblVerifyDocument: cblVerifyDocumentReducer,
  userWallet: userWalletReducer,
  deleteFile: deleteFileReducer,
  bankDepositSlip: bankDepositSlipReducer,
  cblDocuments: cblDocumentsReducer,
  familyMemberTransactions: familyMemberTransactionsReducer,
  requestDetails: requestDetailsReducer,
  createCommentsRequest: createCommentsRequestReducer,
  getRequestCommentList: getRequestCommentListReducer,
  requestUpdate: requestUpdateReducer,
  topBankUpdate: topBankUpdateReducer,
  cblRequestUpdate: cblRequestUpdateReducer,
  cblNumber: cblNumberReducer,
  requestExchange: requestExchangeReducer,
  changePinCode: changePinCodeReducer,
  mdpCreateCard: mdpCreateCardReducer,
  mdpCardsList: mdpCardsListReducer,
  mdpCardsKycStatus: mdpCardsKycStatusReducer,
  mdpCardsCustomerStatus: mdpCardsCustomerStatusReducer,
  mdpCardsKycUpdate: mdpCardsKycUpdateReducer,
  mdpCardsSetPin: mdpCardsSetPinReducer,
  mdpCardsChangeBalance: mdpCardsChangeBalanceReducer,
  mdpCardsBalance:mdpCardsBalanceReducer,
  mdpCardsLookupProducts:mdpCardsLookupProductsReducer,
  mdpCardsLookupIdTypes:mdpCardsLookupIdTypesReducer,
  mdpCardsLookupLanguages:mdpCardsLookupLanguagesReducer,
  mdpCardsLookupCountries:mdpCardsLookupCountriesReducer,
  mdpCardsLookupNationalities:mdpCardsLookupNationalitiesReducer,
});

export { reducer };
