import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  Image,
  Dimensions,
  ScrollView,
  SafeAreaView,
} from "react-native";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Cbl from "../../assets/image/CBL.png";
import Kyc from "../../assets/image/KYC.png";
import TimeImage from "../../assets/image/Time.png";
import { getMyRequests } from "../../Redux/Models/MyRequests/Actions";
import { useDispatch, useSelector } from "react-redux";
import {
  DateFormate,
  formatAMPM,
  ApiDateFormate,
} from "../../Utils/HelperFunctions";
import Loading from "../Loading";
import SideBarConatainer from "../../Components/SideBarConatainer";
const height = Dimensions.get("window").height;

const MyRequest = ({ navigation }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [status, setStatus] = useState(["completed", "cencelled", "pending"]);
  const [selected, setselected] = useState("completed");
  const myRequests = useSelector((state) => state.myRequests);
  // console.log("myRequestsList", myRequests);
  useEffect(() => {
    const unsubscribe = navigation.addListener("tabPress", (e) => {
      let parms = new URLSearchParams({
        sort: "request_number",
        direction: "desc",
        //page: 1,
        //limit: 10,
        status: selected,
      });
      dispatch(getMyRequests(parms));
    });

    return unsubscribe;
  }, [navigation]);
  const onStatusChange = (val) => {
    setselected(val);

    let parms = new URLSearchParams({
      sort: "request_number",
      direction: "desc",
      //page: 1,
      //limit: 10,
      status: val,
    });
    dispatch(getMyRequests(parms));
  };
  const requestDetail = (request) => {
    if (request.verify_process_type.toUpperCase() === "KYC") {
      navigation.navigate("KycRequestDetail", { request });
    }
    if (request.verify_process_type.toUpperCase() === "CBL") {
      navigation.navigate("CblRequestDetail", { request });
    }
    if (request.verify_process_type.toUpperCase() === "TOP-UP") {
      navigation.navigate("TopUpRequestDetail", { request });
    }
  };
  return (
    <SafeAreaView style={{ flex: 1 }}>
      <Text style={styles.myRequestText}>{t("common:my_request")}</Text>
      <View style={styles.buttonView}>
        {status.map((val, ind) => {
          return (
            <TouchableOpacity
              onPress={() => onStatusChange(val)}
              key={ind}
              style={
                selected === val ? styles.completedBtn : styles.cencelledBtn
              }
            >
              <Text
                style={
                  selected === val
                    ? styles.completedText
                    : styles.cencelledText
                }
              >
                {t("common:" + val)}
              </Text>
            </TouchableOpacity>
          );
        })}
      </View>
      <View style={styles.mainContainer}>
      <ScrollView showsHorizontalScrollIndicator={false}>
          {myRequests.data.requests?.map((val, ind) => {
            return (
              <TouchableOpacity
                onPress={() => requestDetail(val)}
                key={ind}
                style={styles.cardView}
              >
                <View style={styles.cblView}>
                  <Image
                    source={
                      val.verify_process_type.toUpperCase() === "KYC"
                        ? Kyc
                        : Cbl
                    }
                    style={styles.cblImage}
                  />
                  <View style={styles.requestView}>
                    <Text style={styles.requestText}>
                      {t(
                        "common:" +
                        val.verify_process_type.toLowerCase().replace("-", "")
                      )}
                    </Text>
                    <Text style={styles.requestNumText}>
                      {val.request_number}
                    </Text>

                    <View style={styles.timeView}>
                      <Image source={TimeImage} style={styles.timeImage} />

                      <Text style={styles.timeText}>
                        {ApiDateFormate(new Date(val.created)) +
                          " " +
                          formatAMPM(new Date(val.created))}
                      </Text>
                    </View>
                  </View>
                  <View style={styles.textView}>
                    <Text style={styles.status}>{t("common:status")}</Text>
                    <Text style={styles.complete}>
                      {t("common:" + val.verify_request_status.toLowerCase())}
                    </Text>
                    <Text style={styles.status_reason}>
                      {t("common:status_reason_col")}
                    </Text>
                    <Text style={styles.document}>
                      {val.request_status_reason?.length > 30
                        ? val.request_status_reason.substring(0, 30) + "..."
                        : val.request_status_reason}
                    </Text>
                  </View>
                </View>
              </TouchableOpacity>
            );
          })}
      </ScrollView>
      </View>
      {myRequests.loading && <Loading></Loading>}
    </SafeAreaView>
  );
};

export default MyRequest;

const styles = StyleSheet.create({
  mainContainer: {
    backgroundColor: "#ffffff",
    borderRadius: 16,
    padding: 20,
    flex:1,
    marginTop:20
  },
  container: {
    marginTop: 20,
    alignItems: "center",
    justifyContent: "space-between",
  },
  myRequestText:{
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 26,
  },
  buttonView: {
    flexDirection: "row",
    marginTop: 24,
    marginHorizontal: 24,
    justifyContent: "space-between",
    maxWidth: 400,
    minWidth: 200,
  },
  completedBtn: {
    maxWidth: 130,
    height: 40,
    flex: 1,
    borderBottomWidth: 1,
    borderBottomColor: "#FF4747",
    alignItems: "center",
    justifyContent: "center",
  },
  completedText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 16,
    color: "#FF4747",
  },
  cencelledBtn: {
    height: 40,
    maxWidth: 130,
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  cencelledText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 16,
    color: "#000000",
  },
  cardView: {
    marginBottom: 16,
    borderWidth: 1,
    borderColor: "#E0E0E0",
    borderRadius: 10,
    padding: 20,
  },
  cblView: {
    flexDirection: "row",
    marginTop: 7,
    marginHorizontal: 15,
  },
  cblImage: {
    height: 24,
    width: 24,
  },
  requestView: {},
  requestText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 14,
    color: "#000000",
    alignSelf: "flex-start",
    marginLeft: 10,
  },
  requestNumText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 10,
    color: "#BDBDBD",
    marginTop: 3,
    marginLeft: 10,
    alignSelf: "flex-start",
  },
  textView: {
    alignItems: "flex-end",
    flex: 1,
  },
  timeView: {
    width: 140,
    flexDirection: "row",
    alignItems: "center",
    marginTop: 10,
    marginBottom: 10,
  },
  timeImage: {
    height: 12,
    width: 12,
    resizeMode:"contain"
  },
  timeText: {
    marginLeft: 8,
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 10,
    color: "#BABABA",
  },
  status: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 10,
    color: "#BDBDBD",
  },
  complete: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 10,
    textAlign: "right",
    color: "#27AE60",
  },
  completeText: {
    marginTop: 4,
  },
  status_reason: {
    marginTop: 4,
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 10,
    color: "#BDBDBD",
  },
  document: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 10,
    textAlign: "right",
    color: "#27AE60",
    marginBottom: 5,
  },
});
