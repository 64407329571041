
const GET_CHECK_USER_PHONE_REQUEST = 'GET_CHECK_USER_PHONE_REQUEST';
const GET_CHECK_USER_PHONE_REQUEST_SUCCESS =  'GET_CHECK_USER_PHONE_REQUEST_SUCCESS';
const GET_CHECK_USER_PHONE_REQUEST_FAILURE =  'GET_CHECK_USER_PHONE_REQUEST_FAILURE';
const RESET_CHECK_USER_PHONE_STATE = 'RESET_CHECK_USER_PHONE_STATE';

const checkPhone = (parm)=>{

    return {
        type: GET_CHECK_USER_PHONE_REQUEST,
        parm
    }
}
const resetCheckPhoneState = ()=>{

    return {
        type: RESET_CHECK_USER_PHONE_STATE,
    }
}
export {
    checkPhone,
    resetCheckPhoneState,
    GET_CHECK_USER_PHONE_REQUEST,
    GET_CHECK_USER_PHONE_REQUEST_SUCCESS,
    GET_CHECK_USER_PHONE_REQUEST_FAILURE,
    RESET_CHECK_USER_PHONE_STATE
};