import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  TextInput,
  I18nManager,
  Dimensions,
  KeyboardAvoidingView,
  ScrollView,
  Platform,
} from "react-native";
import React, { useState, useEffect, useRef } from "react";
import Icon from "react-native-vector-icons/Feather";
import { useTranslation } from "react-i18next";
import { SafeAreaView } from "react-native-safe-area-context";
import { useSelector } from "react-redux";
import AuthHeader from '../../Components/AuthHeader'

const height = Dimensions.get("window").height;

const Name = ({ navigation }) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [disabled, setDisabled] = useState(true);
  const { t } = useTranslation();

  const lastNameRef = useRef();

  const phoneNumber = useSelector(
    (state) => state.checkPhoneNumber.phoneNumber
  );
  const formData = {
    phone_number: phoneNumber,
    name: firstName,
    last_name: lastName,
  };

  useEffect(() => {
    if (!!firstName && !!lastName) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [firstName, lastName]);

  return (
    <SafeAreaView style={styles.mainContainer}>
      <AuthHeader></AuthHeader>
      <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
        <View style={styles.SubContainer}>
        <View style={styles.mainContectContainer}>
          <View style={styles.innerContainer}>
            <View style={styles.pageTopContainer}>
              <View style={styles.namePageContainer}>
                <Icon
                  onPress={() => navigation.goBack()}
                  name="arrow-left"
                  size={30}
                  style={styles.arrowIcon}
                />
                <Text style={styles.nameText}>{t("common:enter_name")}</Text>
              </View>
              <Text style={styles.counterText}>1/6</Text>
            </View>
            <KeyboardAvoidingView
              style={{ flex: 1 }}
              behavior={Platform.OS === "ios" ? "padding" : "height"}
            >
                <View style={styles.nameView}>
                  <Text style={styles.firstNameText}>
                    {t("common:FirstName")}
                  </Text>
                  <TextInput
                    textAlign={I18nManager.isRTL ? "right" : "left"}
                    returnKeyType="next"
                    onSubmitEditing={() => lastNameRef.current.focus()}
                    defaultValue={firstName}
                    onChangeText={(newText) => setFirstName(newText)}
                    style={styles.input}
                  ></TextInput>
                  <Text style={styles.lastNameText}>
                    {t("common:LastName")}
                  </Text>
                  <TextInput
                    textAlign={I18nManager.isRTL ? "right" : "left"}
                    ref={lastNameRef}
                    defaultValue={lastName}
                    onChangeText={(newText) => setLastName(newText)}
                    style={styles.input}
                  ></TextInput>
                </View>
            </KeyboardAvoidingView>
            <View style={styles.btnView}>
              <TouchableOpacity
                onPress={() => navigation.navigate("Email", { formData })}
                style={styles.skipButton}
              >
                <Text style={styles.SkipButtonText}>Skip</Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => navigation.navigate("Email", { formData })}
                style={disabled ? styles.buttonDisabled : styles.button}
                disabled={disabled}
              >
                <Text style={styles.buttonText}>{t("common:next")}</Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

export default Name;

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    backgroundColor: "#F8F8F8",
  },

  topContainer: {
    backgroundColor: "#ffffff",
    paddingTop: 10,
    paddingBottom: 10,
  },
  logoView: {
    color: "#FF4747",
    fontSize: 23,
    marginLeft: 25,
    fontWeight: "700",
  },
  SubContainer: {
    flex: 1,
    flexDirection:'row',
    justifyContent:'center'

  },
  mainContectContainer: {
    flex: 1,
    maxHeight:580,
    maxWidth:640,
    marginHorizontal: 15,
    backgroundColor:'#ffffff',
    marginTop: 30,
    borderRadius: 10,
    marginBottom:51,
  },
  namePageContainer: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  innerContainer: {
    margin: 50,
    flex: 1,
  },
  pageTopContainer: {
    flexDirection: "row",
  },
  arrowIcon: {
    fontSize: 25,
    color: "#101820",
  },
  counterText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 22,
    marginLeft: 20,
    color: "#000000",
    alignSelf: "center",
  },

  nameText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "700",
    marginLeft: 15,
    fontSize: 26,
    color: "#101820",
    alignSelf: "flex-start",
  },
  nameView: {
    flex: 1,
    marginTop: 26,
  },
  firstNameText: {
    fontSize: 14,
    fontWeight: "400",
    color: "#A9ABAE",
    alignSelf: "flex-start",
  },
  input: {
    height: 50,
    borderBottomWidth: 1,
    borderBottomColor: "#E7E8E9",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 14,
    outlineStyle: "none",
  },
  lastNameText: {
    fontSize: 14,
    fontWeight: "400",
    color: "#A9ABAE",
    marginTop: 25,
    alignSelf: "flex-start",
  },
  btnView: {
    flex: 1,
    justifyContent: "flex-end",
  },
  button: {
    padding: 16,
    borderRadius: 70,
    backgroundColor: "#FF4747",
  },
  skipButton: {
    justifyContent: "center",
    alignSelf: "center",
    padding: 5,
    borderRadius: 20,
    marginBottom: 20,
  },
  SkipButtonText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 16,
    alignItems: "center",
    textAlign: "center",
    color: "#FF4747",
    borderBottomColor: "#FF4747",
    borderBottomWidth: 1,
  },
  buttonDisabled: {
    padding: 16,
    borderRadius: 70,
    opacity: 0.5,
    backgroundColor: "#FF4747",
  },
  buttonText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 16,
    alignItems: "center",
    textAlign: "center",
    color: "#FFFFFF",
  },
});
