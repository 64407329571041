const BASEURL = 'https://dev-user.tizdan.net/v1/'
const BASEURLv2 = 'https://dev-user.tizdan.net/api/v2/'
const TRANSACTION_URL = 'https://dev-trans.tizdan.net/v1/'

// const BASEURL = 'https://staging-user.tizdan.net/v1/'
// const BASEURLv2  = 'https://staging-user.tizdan.net/api/v2/'
// const TRANSACTION_URL  = 'https://staging-trans.tizdan.net/v1/'

export default {
  checkPhoneExist: BASEURL + "check-phone-exist/",
  userLogin: BASEURLv2 + "login",
  verifyUserPassCode: BASEURL + "customer/verify-pass-code",
  userRegister: BASEURLv2 + "register",
  userDetails: BASEURL + "customer/user",
  userTransaction: BASEURLv2 + "transactions?",
  forgetPassword: BASEURL + "forget-password",
  newPassword: BASEURL + "new-password",
  changePinCode: BASEURL + "customer/add-pass-code",
  currencies: BASEURLv2 + "currencies",
  tizdanBankAccounts: BASEURLv2 + "tizdan-bank-accounts",
  documentUpload: BASEURLv2 + "s3-file?role=customer&request_type=kyc",
  tizdanBankAccounts: BASEURLv2 + "tizdan-bank-accounts",
  requestBankTopUp: BASEURLv2 + "request/top-up",
  myFamilyList: BASEURL + "my-family/list",
  addFamilyMember: BASEURL + "my-family/add-member",
  editFamilyMember: BASEURL + "my-family/edit-member",
  deleteFamilyMember: BASEURL + "my-family/remove-sub-acc",
  familyMemberTransactions: BASEURL + "my-family/transactions-list",
  myRequests: BASEURLv2 + "requests?",
  beneficiaryList: BASEURL + "beneficiary-list",
  addBeneficiary: BASEURL + "add-beneficiary",
  requestKyc: BASEURLv2 + "customer/request/kyc",
  countryList: BASEURL + "country/list",
  requestTransfer: BASEURLv2 + "request/transfer",
  giftCardCompanyList: BASEURL + "gift-card-companies/list",
  giftCardBuyList: TRANSACTION_URL + "buy/gift-cards/list",
  serviceVoucherCompanyList: BASEURL + "companies/list",
  serviceVoucherBuyList: TRANSACTION_URL + "buy/vouchers/list",
  familyMemberPasswordChange: BASEURL + "my-family/change-password/",
  requestCbl: BASEURLv2 + "request/cbl",
  cityList: BASEURLv2 + "cities",
  buyGiftCard: TRANSACTION_URL + "gift-cards/buy",
  buyServiceVoucher: TRANSACTION_URL + "vouchers/buy",
  verifyDocument: BASEURLv2 + "verify-doc-types?role=customer&request_type=kyc",
  familyMemberTopUp: BASEURLv2 + "request/transfer",
  cblVerifyDocument:
    BASEURLv2 + "verify-doc-types?role=customer&request_type=cbl",
  userWallet: BASEURLv2 + "wallets/user?",
  deleteFile: BASEURLv2 + "s3-file?",
  requestDetails: BASEURLv2 + "request/",
  createCommentsRequest: BASEURLv2 + "request/",
  getRequestCommentList: BASEURLv2 + "request/",
  cblNumber: BASEURLv2 + "cbl-numbers",
  requestExchange: BASEURLv2 + "request/exchange",
  mdpCreateCard: TRANSACTION_URL + "mdp-cards/kyc-create",
  mdpCardsList: TRANSACTION_URL + "mdp-cards/get_available_card",
  mdpCardsKycStatus: TRANSACTION_URL + "mdp-cards/kyc-status",
  mdpCardsCustomerStatus: TRANSACTION_URL + "mdp-cards/mdpcustomer-status",
  mdpCardsKycUpdate: TRANSACTION_URL + "mdp-cards/kyc-update",
  mdpCardsSetPin: TRANSACTION_URL + "mdp-cards/set-pin",
  mdpCardsChangeBalance: TRANSACTION_URL + "mdp-cards/change-balance",
  mdpCardsBalance: TRANSACTION_URL + "mdp-cards/balance",
  mdpCardsLookupProducts: TRANSACTION_URL + "mdp-cards/lookup/products",
  mdpCardsLookupIdTypes: TRANSACTION_URL + "mdp-cards/lookup/idTypes",
  mdpCardsLookupLanguages: TRANSACTION_URL + "mdp-cards/lookup/languages",
  mdpCardsLookupCountries: TRANSACTION_URL + "mdp-cards/lookup/countries",
  mdpCardsLookupNationalities: TRANSACTION_URL + "mdp-cards/lookup/nationalities"
}; 