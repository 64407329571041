const POST_ADD_FAMILY_MEMBER_REQUEST = "POST_ADD_FAMILY_MEMBER_REQUEST";
const POST_ADD_FAMILY_MEMBER_REQUEST_SUCCESS = "POST_ADD_FAMILY_MEMBER_REQUEST_SUCCESS";
const POST_ADD_FAMILY_MEMBER_REQUEST_FAILURE = "POST_ADD_FAMILY_MEMBER_REQUEST_FAILURE";
const ADD_FAMILY_MEMBER_STATUS_RESET = "ADD_FAMILY_MEMBER_STATUS_RESET";

const addFamilyMember = (formData) => {
  return {
    type: POST_ADD_FAMILY_MEMBER_REQUEST,
    formData,
  };
};
const addFamilyMemberStatus = () => {
  return {
    type: ADD_FAMILY_MEMBER_STATUS_RESET,
  };
};

export {
  addFamilyMember,
  addFamilyMemberStatus,
  POST_ADD_FAMILY_MEMBER_REQUEST,
  POST_ADD_FAMILY_MEMBER_REQUEST_SUCCESS,
  POST_ADD_FAMILY_MEMBER_REQUEST_FAILURE,
  ADD_FAMILY_MEMBER_STATUS_RESET
};
