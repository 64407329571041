import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  Dimensions,
  ScrollView,
  Image,
  FlatList,
  SafeAreaView
} from "react-native";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Amazon from "../../assets/image/amazon.png";
import { useDispatch, useSelector } from "react-redux";
import { giftCardBuyList } from "../../Redux/Models/GiftCardBuyList/Actions";
const height = Dimensions.get("window").height;
import Loading from "../Loading";
import noPhoto from "../../assets/image/no-photo.jpg";

const PurchasedCard = ({ navigation }) => {
  const giftCardBuy = useSelector((state) => state.giftCardBuyList);
  console.log("giftCardBuy.data",giftCardBuy.data);
  const [timePeriod, setTimePeriod] = useState([
    "day",
    "week",
    "month",
    "year",
  ]);
  const [selectedTime, setSelectedTime] = useState(0);
  const [filterData, setFilterData] = useState([]);
  useEffect(() => {
    let data = giftCardBuy.data.filter(
      (val) =>
        val.company.title
          .toLowerCase()
          .indexOf(giftCardBuy.search?.toLowerCase()) > -1
    );
    setFilterData(data);
  }, [giftCardBuy.search]);

  useEffect(() => {
    setFilterData(giftCardBuy.data);
  }, [giftCardBuy.data]);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const DateFormate = (date) => {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    return date.getDate() + " " + monthNames[date.getMonth()] + " " + date.getFullYear()

  };
  function formatAMPM(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }

  useEffect(() => {
    const unsubscribe = navigation.addListener('tabPress', (e) => {


      const formData = new FormData();
      formData.append("date_range", selectedTime + 1);
      dispatch(giftCardBuyList(formData));
    });

    return unsubscribe;
  }, [navigation]);


  const onChangeList = (Selected) => {
    setSelectedTime(Selected);
    const formData = new FormData();
    formData.append("date_range", Selected + 1);
    dispatch(giftCardBuyList(formData));
  };

  return (
    <SafeAreaView style={styles.mainContainer}>
      <View style={styles.buttonContainer}>
        {timePeriod.map((val, ind) => (
          <TouchableOpacity
            key={ind}
            onPress={() => onChangeList(ind)}
            style={selectedTime === ind ? styles.button : styles.button1}
          >
            <Text
              style={selectedTime === ind ? styles.buttonText : styles.btnText}
            >
              {t("common:" + val)}
            </Text>
          </TouchableOpacity>
        ))}
      </View>
      <FlatList
        data={filterData}
        renderItem={({ item }) => (
          <TouchableOpacity
            onPress={() => navigation.navigate("GiftCardDetail", { item })}
            style={styles.topContainer1}
          >
            <View style={styles.container}>
              <Image source={item.logo_color === "" ? noPhoto : { uri: item.company.logo_color }} style={styles.vodafoneImage} />
              <View style={styles.giftView}>
                <Text style={styles.giftCardText}>{t("common:gift_card")}</Text>
                <Text style={styles.amazonText}>{item.company.title}</Text>
                <Text style={styles.dateText}>{t("common:buy_date")} {DateFormate(new Date(item.buy_date)) + ' ' + formatAMPM(new Date(item.buy_date))}</Text>
              </View>
            </View>
            {/*    
                  <Text style={styles.activeText}>{t("common:active")}</Text>
           */}
          </TouchableOpacity>
        )}
        keyExtractor={(item) => item.id}
        showsHorizontalScrollIndicator={false}
        numColumns={3}
        style={{ gap: 10 }}
      />
      {giftCardBuy.loading && <Loading></Loading>}
    </SafeAreaView>
  );
};

export default PurchasedCard;

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    marginTop: 20,
    backgroundColor: "#FFFFFF",
    borderRadius: 16,
    padding: 20,
    marginBottom: 25,
  },
  buttonContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: 400,
    marginTop: 10,
    marginBottom: 20,
    padding:20
  },
  button: {
    maxWidth: 90,
    height: 40,
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#FF4747",
    borderRadius: 25,
  },
  buttonText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 14,
    color: "#FFFFFF",
  },
  button1: {
    maxWidth: 90,
    height: 40,
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 25,
  },
  btnText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 14,
    color: "#000000",
  },
  topContainer1: {
    backgroundColor: "#F9F9F9",
    borderRadius: 10,
    flex: 1,
    maxWidth: 430,
    minWidth: 150,
    margin: 10,
    padding: 10
  },
  container: {
    flexDirection: "row",
    alignItems: "center",
  },
  vodafoneImage: {
    borderRadius: 56,
    width: 56,
    height: 56,
    marginLeft: 15,
  },
  giftView: {
    marginLeft: 30,
    marginBottom: 15,
    marginTop: 5
  },
  giftCardText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 12,
    letterSpacing: 0.111,
    color: "#828282",
    alignSelf: "flex-start",
    marginTop: 5
  },
  amazonText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 16,
    letterSpacing: 0.111,
    color: "#282828",
    alignSelf: "flex-start",
    marginTop: 5
  },
  dateText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 12,
    letterSpacing: 0.111,
    color: "#BDBDBD",
    alignSelf: "flex-start",
    marginTop: 5

  },
  activeText: {
    height: 20,
    marginBottom: 35,
    marginRight: 16,
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 14,
    textAlign: "right",
    letterSpacing: 0.111,
    color: "#27AE60",
    alignItems: "center",
  },
  finishedText: {
    height: 20,
    marginBottom: 35,
    marginRight: 16,
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 14,
    textAlign: "right",
    letterSpacing: 0.111,
    color: "#A9ABAE",
    alignItems: "center",
  },
});
