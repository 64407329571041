const POST_USER_FAMILY_TRANSACTIONS_LIST_REQUEST = "POST_USER_FAMILY_TRANSACTIONS_LIST_REQUEST";
const POST_USER_FAMILY_TRANSACTIONS_LIST_REQUEST_SUCCESS = "POST_USER_FAMILY_TRANSACTIONS_LIST_REQUEST_SUCCESS";
const POST_USER_FAMILY_TRANSACTIONS_LIST_REQUEST_FAILURE = "POST_USER_FAMILY_TRANSACTIONS_LIST_REQUEST_FAILURE";

const familyTransactionList = (formData) => {
  return {
    type: POST_USER_FAMILY_TRANSACTIONS_LIST_REQUEST,
    formData,
  };
};


export {
  familyTransactionList,
  POST_USER_FAMILY_TRANSACTIONS_LIST_REQUEST,
  POST_USER_FAMILY_TRANSACTIONS_LIST_REQUEST_SUCCESS,
  POST_USER_FAMILY_TRANSACTIONS_LIST_REQUEST_FAILURE,
};
