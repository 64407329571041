
const POST_CBL_FILE_UPLOAD_REQUEST = 'POST_CBL_FILE_UPLOAD_REQUEST';
const POST_CBL_FILE_UPLOAD_REQUEST_SUCCESS =  'POST_CBL_FILE_UPLOAD_REQUEST_SUCCESS';
const POST_CBL_FILE_UPLOAD_REQUEST_FAILURE =  'POST_CBL_FILE_UPLOAD_REQUEST_FAILURE';
const RESET_CBL_FILE_UPLOAD_STATE = 'RESET_CBL_FILE_UPLOAD_STATE';
const DETELE_FROM_CBL_FILE_UPLOADED = 'DETELE_FROM_CBL_FILE_UPLOADED'
const SET_CBL_EDIT_FROM_FILES = 'SET_CBL_EDIT_FROM_FILES'
const CLEAR_CBL_FILES = 'CLEAR_CBL_FILES'

const uploadCBLFile = (formData,doc)=>{
console.log('acountion ',doc)
    return {
        type: POST_CBL_FILE_UPLOAD_REQUEST,
        formData,
        doc
    }
}

const resetUploadCBLFilestate = ()=>{

    return {
        type: RESET_CBL_FILE_UPLOAD_STATE,
        
    }
}
const deleteUploadedCBLFile = (doc,index)=>{

    return {
        type: DETELE_FROM_CBL_FILE_UPLOADED,
        doc,
        index        
    }
}
const setUploadFiles = (data, doc)=>{
    return {
        type: SET_CBL_EDIT_FROM_FILES,
        data,
        doc
    }
}
const ClearCBLFiles = ()=>{
    return {
        type: CLEAR_CBL_FILES,
    }
}
export {
    uploadCBLFile,
    resetUploadCBLFilestate,
    deleteUploadedCBLFile,
    setUploadFiles,
    ClearCBLFiles,
    POST_CBL_FILE_UPLOAD_REQUEST,
    POST_CBL_FILE_UPLOAD_REQUEST_SUCCESS,
    POST_CBL_FILE_UPLOAD_REQUEST_FAILURE,
    RESET_CBL_FILE_UPLOAD_STATE,
    DETELE_FROM_CBL_FILE_UPLOADED,
    SET_CBL_EDIT_FROM_FILES,
    CLEAR_CBL_FILES
};