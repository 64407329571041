import {
  StyleSheet,
  Text,
  View,
  Dimensions,
  TouchableOpacity,
  Image,
  TextInput,
  I18nManager,
  SafeAreaView,
  KeyboardAvoidingView,
  ScrollView,
  Platform,
} from "react-native";
import React, { useState, useRef } from "react";
import Icon from "react-native-vector-icons/Feather";
import { useTranslation } from "react-i18next";
import { Picker } from "@react-native-picker/picker";
import Money from "../../assets/image/money.png";
const height = Dimensions.get("window").height;
import AppHeader from "../../Components/AppHeader";
import { useSelector } from "react-redux";

const BankTransfer = ({ navigation }) => {
  const userDetails = useSelector((state) => state.userDetails);
  const { t } = useTranslation();
  const [branch, setBranch] = useState("");
  const [usd, setUsd] = useState("");
  const [baknName, setBankName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [amount, setAmount] = useState("");
  const [description, setDescription] = useState("");

  const accountNumberRef = useRef();
  const amountRef = useRef();
  const descriptionRef = useRef();
  return (
    <SafeAreaView style={styles.topContainer}>
      <AppHeader navigation={navigation} />

      <View style={styles.topHeader}>
        <View style={styles.topHeaderContainer}>
          <View style={styles.headerView}>
            <Icon
              name="arrow-left"
              size={30}
              onPress={() => navigation.goBack()}
            />
            <Text style={styles.transferText}>{t("common:transfer")}</Text>
          </View>
          <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
            <View style={styles.container}>
              <View style={styles.innerContainer}>
                <View style={styles.insideContainer}>
                  <View style={styles.nameContainer}>
                    <Text style={styles.nameText}>
                      {t("common:bank_name_st")}
                      <Text style={{ color: "#FF4747" }}>*</Text>
                    </Text>
                    <TextInput
                      textAlign={I18nManager.isRTL ? "right" : "left"}
                      style={styles.input}
                      value={baknName}
                      onChangeText={(text) => setBankName(text)}
                      onSubmitEditing={() => accountNumberRef.current.focus()}
                      returnKeyType="next"
                    ></TextInput>
                  </View>
                  <View style={[styles.nameContainer, { marginLeft: 20 }]}>
                    <Text style={styles.nameText}>
                      {t("common:branch_st")}
                      <Text style={{ color: "#FF4747" }}>*</Text>
                    </Text>
                    <View style={styles.transferFromView}>
                      <Picker
                        mode="dropdown"
                        selectedValue={branch}
                        onValueChange={(selectedValue) =>
                          setBranch(selectedValue)
                        }
                        style={styles.pickerItem}
                      >
                        <Picker.Item
                          label="Branch"
                          value="Branch"
                          style={styles.pickerItemText}
                        />
                      </Picker>
                    </View>
                  </View>
                </View>
                <View style={styles.insideContainer}>
                  <View style={styles.nameContainer}>
                    <Text style={styles.nameText}>
                      {t("common:account_number_st")}
                      <Text style={{ color: "#FF4747" }}>*</Text>
                    </Text>
                    <TextInput
                      textAlign={I18nManager.isRTL ? "right" : "left"}
                      keyboardType="number-pad"
                      returnKeyType="done"
                      value={accountNumber}
                      onChangeText={(text) => setAccountNumber(text)}
                      onSubmitEditing={() => amountRef.current.focus()}
                      ref={accountNumberRef}
                      style={styles.input}
                    ></TextInput>
                  </View>
                  <View style={[styles.nameContainer, { marginLeft: 20 }]}>
                    <TextInput
                      textAlign={I18nManager.isRTL ? "right" : "left"}
                      placeholder={t("common:description")}
                      value={description}
                      onChangeText={(text) => setDescription(text)}
                      ref={descriptionRef}
                      returnKeyType="done"
                      style={[styles.input, styles.descriptionInput]}
                    ></TextInput>
                  </View>
                </View>
                <Text style={styles.nameText}>
                  {t("common:amount_st")}
                  <Text style={{ color: "#FF4747" }}>*</Text>
                </Text>
                <View style={styles.sendView}>
                  <TextInput
                    textAlign={I18nManager.isRTL ? "right" : "left"}
                    keyboardType="number-pad"
                    returnKeyType="done"
                    value={amount}
                    onChangeText={(text) => setAmount(text)}
                    onSubmitEditing={() => descriptionRef.current.focus()}
                    ref={amountRef}
                    style={styles.sendInput}
                  ></TextInput>
                  <View style={styles.pickerView}>
                    <Image source={Money} style={styles.lydImage} />
                    <Picker
                      mode="dropdown"
                      selectedValue={usd}
                      onValueChange={(selectedValue) => setUsd(selectedValue)}
                      style={styles.pickerItem}
                    >
                      <Picker.Item
                        label="USD"
                        value="USD"
                        style={styles.pickerItemText}
                      />
                      <Picker.Item
                        label="LYD"
                        value="LYD"
                        style={styles.pickerItemText}
                      />
                      <Picker.Item
                        label="EUR"
                        value="EUR"
                        style={styles.pickerItemText}
                      />
                    </Picker>
                  </View>
                </View>

                <View style={styles.btnView}>
                  <TouchableOpacity
                    onPress={() => navigation.navigate("Dashboard")}
                    style={styles.button}
                  >
                    <Text style={styles.buttonText}>{t("common:transfer")}</Text>
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          </ScrollView>
        </View>
      </View>
    </SafeAreaView>
  );
};

export default BankTransfer;

const styles = StyleSheet.create({
  topContainer: {
    flex: 1,
    backgroundColor: "#F8F8F8",
  },
  topHeader: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "center",
  },
  topHeaderContainer: {
    flex: 1,
    maxWidth: 1400
  },
  headerView: {
    flexDirection: "row",
    marginTop: 25,
    alignItems: "center",
  },
  transferText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 26,
    color: "#000000",
    marginLeft: 15,
  },
  container: {
    marginTop: 25,
    backgroundColor: "#FFFFFF",
    borderRadius: 16,
  },
  innerContainer: {
    flex: 1,
    marginHorizontal: 24,
  },
  insideContainer: {
    flexDirection: "row",
    marginTop: 20,
  },
  nameContainer: {
    flex: 1,
  },
  nameText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 14,
    color: "#A9ABAE",
    alignSelf: "flex-start",
    marginTop: 15,
  },
  input: {
    height: 56,
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 14,
    borderColor: "#E7E8E9",
    borderBottomWidth: 1,
    outlineStyle: "none",
  },
  transferFromView: {
    borderColor: "#E7E8E9",
    borderBottomWidth: 1,
    height: 56,
    justifyContent: "center",
  },
  lydImage: {
    width: 24,
    height: 24,
  },
  pickerItem: {
    flex: 1,
    height: 45,
    borderWidth: 1,
    borderColor: "white",
    borderStyle: "solid",
  },
  pickerItemText: {
    color: "#101820",
    fontSize: 14,
    fontWeight: "500",
    fontFamily: "Inter",
  },
  sendView: {
    flexDirection: "row",
    marginTop: 10,
  },
  sendInput: {
    flex: 1,
    borderBottomWidth: 1,
    borderColor: "#F1F1F1",
    fontFamily: "Inter",
    fontSize: 14,
    fontWeight: "500",
    outlineStyle: "none",
  },
  pickerView: {
    flex: 1,
    borderBottomWidth: 1,
    borderColor: "#F1F1F1",
    marginLeft: 20,
    flexDirection: "row",
    alignItems: "center",
  },
  descriptionInput: {
    marginTop: 30,
  },
  btnView: {
    flex: 1,
    marginTop: 30,
    marginBottom: 20,
    alignItems: "flex-end"
  },
  button: {
    padding: 16,
    backgroundColor: "#FF4747",
    borderRadius: 70,
    width: 404
  },
  buttonText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 16,
    alignItems: "center",
    textAlign: "center",
    color: "#FFFFFF",
  },
});
