import {
  StyleSheet,
  Text,
  View,
  Dimensions,
  ScrollView,
  TouchableOpacity,
  SafeAreaView,
} from "react-native";
import React,{useEffect} from 'react'
import Icon from "react-native-vector-icons/Feather"
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {DateFormate, formatAMPM} from "../../Utils/HelperFunctions"
import { familyTransactionList } from "../../Redux/Models/User/MyFamily/FamilyMemberTransactions/Actions";

const height = Dimensions.get("window").height;

const FamilyTransactions = ({navigation}) => {
const { t } = useTranslation();
const dispatch = useDispatch()
const familyMemberTransactions = useSelector((state) => state.familyMemberTransactions)
const userDetails = useSelector((state) => state.userDetails.data.user);
const selectedFamilyMember = useSelector(
  (state) => state.myFamilyList.selectedFamilyMember
);
  useEffect(() => {
    const unsubscribe = navigation.addListener('tabPress', (e) => {
     
  
      let formData = new FormData()
      formData.append("phone_number",selectedFamilyMember.phone_number)
    dispatch(familyTransactionList(formData))
  
    });
  
    return unsubscribe;
  }, [navigation]);
  return (
  <SafeAreaView style={styles.mainContainer}>
    <ScrollView>
      <View style={styles.container}>
        {familyMemberTransactions?.data?.transactions?.map((val, ind) => (
          <TouchableOpacity
            onPress={() => navigation.navigate("FamilyTransactionDetail",{val})}
            key={ind}
            style={styles.listContainer}
          >
            <View style={styles.arrowView}>
              <Icon
                name={
                  val.sender_id === userDetails.id ? "arrow-up" : "arrow-down"
                }
                size={15}
                style={
                  val.sender_id === userDetails.id
                    ? styles.arrowImage
                    : styles.downImage
                }
              />
            </View>
            <View style={[styles.listView, styles.resView]}>
              <View style={styles.receivedView}>
                <Text style={styles.receivedText}>
                  {val.sender_id === userDetails.id
                    ? t("common:received_from") + " " + val.receiver.name
                    : "Paid to" + " " + val.sender.name}
                </Text>
                <Text style={styles.dateText}>
                  {DateFormate(new Date(val.created)) +
                    " " +
                    formatAMPM(new Date(val.created))}
                </Text>
              </View>
              <Text style={styles.priceListText}>
                {val.sender_id === userDetails.id
                  ? "+" + val.receive_amount + " " + val.receive_currency
                  : "-" + val.send_amount + " " + val.send_currency}
              </Text>
            </View>
          </TouchableOpacity>
        ))}
      </View>
    </ScrollView>
  </SafeAreaView>
);
} 

export default FamilyTransactions

const styles = StyleSheet.create({
mainContainer: {
  flex: 1,
    marginTop: 20,
    backgroundColor: "#FFFFFF",
    borderRadius: 16,
    padding: 20,
    marginBottom: 25,
},
container: {
  flex: 1,
  padding:24,
},
listContainer: {
  marginTop: 10,
  flexDirection: "row",
},
arrowView: {
  width: 32,
  height: 32,
  backgroundColor: "#F7F7F7",
  borderRadius: 8,
  alignItems: "center",
  justifyContent: "center",
},
listView: {
  flex: 1,
  flexDirection: "row",
  justifyContent: "space-between",
},
receivedView: {
  marginLeft: 16,
},
arrowImage: {
  color: "#209E00",
},
receivedText: {
  fontFamily: "Inter",
  fontStyle: "normal",
  fontWeight: "500",
  fontSize: 12,
  color: "#000000",
},
dateText: {
  fontFamily: "Inter",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: 12,
  color: "#1e064c",
  opacity: 0.3,
  marginTop:3
},
priceListText: {
  height: 20,
  fontFamily: "Inter",
  fontStyle: "normal",
  fontWeight: "600",
  fontSize: 14,
  textAlign: "right",
  color: "#000000",
},
listContainer1: {
  marginTop: 10,
  flexDirection: "row",
},
downView: {
  width: 32,
  height: 32,
  backgroundColor: "#F7F7F7",
  borderRadius: 8,
  alignItems: "center",
  justifyContent: "center",
},
downImage: {
  color: "red",
},
sendView: {},
});