
const POST_REQUEST_KYC_REQUEST = 'POST_REQUEST_KYC_REQUEST';
const POST_REQUEST_KYC_REQUEST_SUCCESS =  'POST_REQUEST_KYC_REQUEST_SUCCESS';
const POST_REQUEST_KYC_REQUEST_FAILURE =  'POST_REQUEST_KYC_REQUEST_FAILURE';
const RESET_KYC_REQUEST_STATE ='RESET_KYC_REQUEST_STATE'
const requestKyc = (formData)=>{

    return {
        type: POST_REQUEST_KYC_REQUEST,
        formData
    }
}
const resetRequestKyc = ()=>{

  return {
      type: RESET_KYC_REQUEST_STATE,
  }
}

export {
  requestKyc,
  resetRequestKyc,
  POST_REQUEST_KYC_REQUEST,
  POST_REQUEST_KYC_REQUEST_SUCCESS,
  POST_REQUEST_KYC_REQUEST_FAILURE,
  RESET_KYC_REQUEST_STATE
};