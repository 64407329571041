
const GET_CITY_LIST_REQUEST = 'GET_CITY_LIST_REQUEST';
const GET_CITY_LIST_REQUEST_SUCCESS =  'GET_CITY_LIST_REQUEST_SUCCESS';
const GET_CITY_LIST_REQUEST_FAILURE =  'GET_CITY_LIST_REQUEST_FAILURE';

const cityList = ()=>{

    return {
        type: GET_CITY_LIST_REQUEST,
    }
} 

export {
  cityList,
  GET_CITY_LIST_REQUEST,
  GET_CITY_LIST_REQUEST_SUCCESS,
  GET_CITY_LIST_REQUEST_FAILURE,
};