
const POST_CBL_REQUEST_UPDATE_REQUEST = 'POST_CBL_REQUEST_UPDATE_REQUEST';
const POST_CBL_REQUEST_UPDATE_REQUEST_SUCCESS =  'POST_CBL_REQUEST_UPDATE_REQUEST_SUCCESS';
const POST_CBL_REQUEST_UPDATE_REQUEST_FAILURE =  'POST_CBL_REQUEST_UPDATE_REQUEST_FAILURE';
const RESET_CBL_UPDATE_STATE = 'RESET_CBL_UPDATE_STATE';

const cblUpdateRequest = (requestId,formData)=>{

    return {
        type: POST_CBL_REQUEST_UPDATE_REQUEST,
        formData,
        requestId
    }
}
const resetCblRequestUpdate = ()=>{

  return {
      type: RESET_CBL_UPDATE_STATE,
  }
}

export {
  cblUpdateRequest,
  resetCblRequestUpdate,
  POST_CBL_REQUEST_UPDATE_REQUEST,
  POST_CBL_REQUEST_UPDATE_REQUEST_SUCCESS,
  POST_CBL_REQUEST_UPDATE_REQUEST_FAILURE,
  RESET_CBL_UPDATE_STATE
};