import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  Dimensions,
  Image,
} from "react-native";
import React from "react";
import { useTranslation } from "react-i18next";
import image from "../../assets/image/splashscreen_image.png";
import { SafeAreaView } from "react-native-safe-area-context";

const height = Dimensions.get("window").height;

const HomeScreen = ({ navigation }) => {
  const { t } = useTranslation();
  return (
    <SafeAreaView style={styles.container}>
      <View style={styles.innerContainer}>
        <View style={styles.logoView}>
           <Image source={image} style={{width:350,height:200,resizeMode:"contain"}}/>
        </View>
      </View>
      <View style={styles.btnContainer}>
        <TouchableOpacity
            onPress={() => navigation.navigate("PhoneNumber")}
            style={styles.signInButton}
          >
            <Text style={styles.signInText}>{t("common:signin")}</Text>
          </TouchableOpacity>
        <TouchableOpacity
            onPress={() => navigation.navigate("PhoneNumber")}
            style={styles.button}
          >
            <Text style={styles.buttonText}>{t("common:GetStarted")}</Text>
          </TouchableOpacity>
         
         </View>
    </SafeAreaView>
  );
};

export default HomeScreen;

const styles = StyleSheet.create({
  container: {
    flex:1,
    backgroundColor: "#FF4747",
  },
  innerContainer:{ 
    marginHorizontal:24,
    flex: 1 
  },
  logoView: {
    flex:1,
    alignSelf:"center",
    justifyContent:"center"
  },
  logoText:{
    color:'#ffffff',
    fontSize:40,
    fontWeight:'700'
  },
  btnContainer:{
    flexDirection:'row',
    marginBottom:16,
    justifyContent:'center',
    gap:16,
    alignItems:'center',
    flexWrap:"wrap",

  },
  button: {
    maxWidth:327,
    minWidth:127,
    flex:1,
    paddingTop:16,
    paddingBottom:16,
    alignItems: "center",
    justifyContent:'center',
    borderRadius: 70,
    borderWidth:1,
    borderColor:'#ffffff',
    backgroundColor: "#ffffff",
   
  },
  buttonText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 16,
    alignItems: "center",
    textAlign: "center",
    color: "#000000",
  },
  signInButton: {
    maxWidth:327,
    minWidth:127,
    flex:1,
   paddingTop:16,
    paddingBottom:16,
    alignItems: "center",
    justifyContent:'center',
    borderRadius: 70,
    borderWidth:1,
    borderColor:'#ffffff',
    backgroundColor: "#FF4747",
  },
  signInText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 16,
    alignItems: "center",
    textAlign: "center",
    color: "#ffffff",
    
  },
});
