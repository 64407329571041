import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  Image,
  Dimensions,
  SafeAreaView,
} from "react-native";
import React, { useState } from "react";
import Icon from "react-native-vector-icons/Feather";
import { useTranslation } from "react-i18next";
import Edit from "../../assets/image/edit.png";
const height = Dimensions.get("window").height;
import SideBarConatainer from "../../Components/SideBarConatainer";


const KycFinancialInfo = ({ navigation,route }) => {
  const { t } = useTranslation();
  const personalInfo = route.params?.personalInfo.user;
  const requestId = route.params?.personalInfo;
  return (

    <SideBarConatainer navigation={navigation}>
          <View style={styles.headerView}>
            <Icon
              name="arrow-left"
              size={30}
              style={styles.arrowIcon}
              onPress={() => navigation.goBack()}
            />
            <Text style={styles.requestsText}>{t("common:requests")}</Text>
          </View>
          <View style={styles.innerContainer}>
        <View style={styles.personalView}>
          <Text style={styles.personalText}>
          {t("common:Financial_information")}
          </Text>
          {
            requestId.verify_request_status.toLowerCase() === "pending" && (
              <TouchableOpacity
            onPress={() =>
              navigation.navigate("EditFinancialInfo", {
                personalInfo,
                requestId,
              })
            }
            style={styles.editBtn}
          >
            <Image source={Edit} style={styles.editImage} />
          </TouchableOpacity>
            )

          }
        </View>
        <View style={styles.container}>
           <Text style={styles.nameText}>
             {t("common:buisness_employer_name")}
           </Text>
           <Text style={styles.cblRequestText}>
             {personalInfo.business_name}
           </Text>
         </View>

         <View style={styles.container}>
           <Text style={styles.nameText}>{t("common:role")}</Text>
           <Text style={styles.cblRequestText}>
             {personalInfo.position_held}
           </Text>
         </View>

         <View style={styles.container}>
           <Text style={styles.nameText}>
            {t("common:official_contact_number")}
           </Text>
           <Text style={styles.cblRequestText}>
             {personalInfo.official_contact_no}
           </Text>
        </View>

         <View style={styles.container}>
           <Text style={styles.nameText}>
             {t("common:average_monthly_income")}
           </Text>
           <Text style={styles.cblRequestText}>
             {personalInfo.monthly_income}
           </Text>
         </View>

         <View style={styles.container}>
           <Text style={styles.nameText}>{t("common:years_of_experience")}</Text>
           <Text style={styles.cblRequestText}>
             {personalInfo.current_experience}
           </Text>
         </View>
      </View>
    </SideBarConatainer>
  );
};

export default KycFinancialInfo;

const styles = StyleSheet.create({
  topContainer: {
    flex:1,
    backgroundColor: "#FFFFFF",
  },
  headerView: {
    flexDirection: "row",
  },
  requestsText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 26,
    color: "#000000",
    marginLeft: 10,
  },
  innerContainer: {
    marginTop: 20,
    backgroundColor: "#ffffff",
    padding: 20,
    borderRadius: 16,
  },
  personalView: {
    marginBottom: 14,
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  personalText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 18,
    color: "#000000",
    alignSelf: "flex-start",
  },
  editImage: {
    height: 17,
    width: 51,
  },
  container: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: 20,
  },
  nameText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 14,
    color: "#BDBDBD",
  },
  cblRequestText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 14,
    textAlign: "right",
  },
});
