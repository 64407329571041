import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  TouchableOpacity,
  Dimensions,
} from "react-native";
import React, { useState, useEffect } from "react";
import Icon from "react-native-vector-icons/Feather";
import { Picker } from "@react-native-picker/picker";
import { useTranslation } from "react-i18next";
import { SafeAreaView } from "react-native-safe-area-context";
import { countryList } from "../../Redux/Models/Countries/Actions";
import { useSelector, useDispatch } from "react-redux";
import Loading from "../Loading";
import AuthHeader from '../../Components/AuthHeader'

const height = Dimensions.get("window").height;

const Nationality = ({ navigation, route }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const countryLists = useSelector((state) => state.countryList);

  const [nationality, setNationality] = useState(
    countryLists.data.countryList !== undefined &&
      countryLists.data.countryList[0].id
  );
   const { formData } = route.params;
  const nextStep = () => {
    formData.nationality = nationality;
    navigation.navigate("Residence", { formData });
  };

  useEffect(() => {
    dispatch(countryList());
  }, []);

  return (
    <SafeAreaView style={styles.mainContainer}>
    <AuthHeader/>
      <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
       <View style={styles.subContainer}>
       <View style={styles.mainContectContainer}>
          <View style={styles.innerContainer}>
            <View style={styles.pageTopContainer}>
              <View style={styles.namePageContainer}>
                <Icon
                  onPress={() => navigation.goBack()}
                  name="arrow-left"
                  size={30}
                />
                <Text style={styles.nameText}>
                  {" "}
                  {t("common:YourNationality")}
                </Text>
              </View>
              <Text style={styles.counterText}>5/6</Text>
            </View>
            <View style={styles.nationalityView}>
              <Text style={styles.textNationality}>
                {t("common:Nationality")}
              </Text>
              <View style={styles.pickerView}>
                <Picker
                  mode="dropdown"
                  selectedValue={nationality}
                  onValueChange={(selectedValue) =>
                    setNationality(selectedValue)
                  }
                  style={styles.pickerItem}
                >
                  {countryLists.data.countryList?.map((val, ind) => (
                    <Picker.Item
                      key={ind}
                      label={val.country_name}
                      value={val.id}
                      style={styles.pickerItemText}
                    />
                  ))}
                </Picker>
              </View>
            </View>
            <View style={styles.btnView}>
              <TouchableOpacity
                style={styles.skipButton}
              >
                <Text style={styles.SkipButtonText}>Skip</Text>
              </TouchableOpacity>
              <TouchableOpacity onPress={() => nextStep()} style={styles.button}>
            <Text style={styles.buttonText}>{t("common:next")}</Text>
          </TouchableOpacity>
            </View>
          </View>
        </View>
       </View>
      </ScrollView>
      {countryLists.loading && <Loading></Loading>}
    </SafeAreaView>
  );
};

export default Nationality;

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    backgroundColor: "#F8F8F8",
  },
  topContainer: {
    backgroundColor: "#ffffff",
    paddingTop: 10,
    paddingBottom: 10,
  },
  logoView: {
    color: "#FF4747",
    fontSize: 23,
    marginLeft: 25,
    fontWeight: "700",
  },
  subContainer:{
    flex: 1,
    flexDirection:'row',
    justifyContent:'center'
  },
  mainContectContainer: {
    flex: 1,
    maxHeight:580,
    maxWidth:640,
    marginHorizontal: 15,
    backgroundColor:'#ffffff',
    marginTop: 30,
    borderRadius: 10,
    marginBottom:51,
  },
  namePageContainer: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  innerContainer: {
    margin: 50,
    flex: 1,
  },
  pageTopContainer: {
    flexDirection: "row",
  },
  arrowIcon: {
    fontSize: 25,
    color: "#101820",
  },
  nameText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "700",
    marginLeft: 15,
    fontSize: 26,
    color: "#101820",
    alignSelf: "flex-start",
  },
  counterText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 22,
    marginLeft: 20,
    color: "#000000",
    alignSelf: "center",
  },
  nameView: {
    flex: 1,
    marginTop: 26,
  },

  btnView: {
    flex: 1,
    justifyContent: "flex-end",
  },
  nationalityView: {
    marginTop: 26,
  },
  textNationality: {
    height: 25,
    fontSize: 14,
    fontWeight: "400",
    color: "#A9ABAE",
    alignSelf:"flex-start"
  },
  pickerView: {
    height:56,
    justifyContent:"center",
    borderBottomWidth: 1,
    borderColor: "#E7E8E9",
  },
  pickerItem:{
    height: 45,
    borderWidth: 1,
    borderColor: "white",
    borderStyle: "solid",
  },
  pickerItemText: {
    color: "#101820",
    fontSize: 16,
    fontWeight: "500",
    fontFamily: "Inter",
  },
  button: {
    padding: 16,
    borderRadius: 70,
    backgroundColor: "#FF4747",
  },
  skipButton: {
    justifyContent: "center",
    alignSelf: "center",
    padding: 5,
    borderRadius: 20,
    marginBottom: 20,
  },
  SkipButtonText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 16,
    alignItems: "center",
    textAlign: "center",
    color: "#FF4747",
    borderBottomColor: "#FF4747",
    borderBottomWidth: 1,
  },
  buttonDisabled: {
    padding: 16,
    borderRadius: 70,
    opacity: 0.5,
    backgroundColor: "#FF4747",
  },
  buttonText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 16,
    alignItems: "center",
    textAlign: "center",
    color: "#FFFFFF",
  },
});
