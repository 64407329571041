import AsyncStorage from "@react-native-async-storage/async-storage";
import {
  POST_USER_REGISTER_REQUEST,
  POST_USER_REGISTER_REQUEST_SUCCESS,
  POST_USER_REGISTER_REQUEST_FAILURE,
  CHANGE_STATUS,
} from "./Action";

const initialState = {
  message: null,
  data: {
    token: null,
    customer_id: null,
    uid: null,
    role_id: null,
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case POST_USER_REGISTER_REQUEST: {
      console.log(POST_USER_REGISTER_REQUEST);

      return {
        ...state,
        error: false,
        success: false,
        loading: true,
      };
    }
    case POST_USER_REGISTER_REQUEST_SUCCESS: {
      const { message, data } = action.payload;
      console.log(POST_USER_REGISTER_REQUEST_SUCCESS);
      AsyncStorage.setItem("user", JSON.stringify(data)).then((val=>console.log(val))).catch((err)=>console.log(err));;
      return {
        ...state,
        loading: false,
        success: true,
        registered: true,
        data,
        message,
      };
    }
    case POST_USER_REGISTER_REQUEST_FAILURE: {
      console.log(POST_USER_REGISTER_REQUEST_FAILURE);

      const { message } = action.payload;
      return {
        ...state,
        loading: false,
        success: false,
        error: true,
        message,
      };
    }
    case CHANGE_STATUS: {
      console.log(CHANGE_STATUS)
      return {
        ...state,
        registered: false,
      };
    }
    default:
      return state;
  }
};

export { reducer };
