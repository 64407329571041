const POST_DELETE_FAMILY_MEMBER_REQUEST = "POST_DELETE_FAMILY_MEMBER_REQUEST";
const POST_DELETE_FAMILY_MEMBER_REQUEST_SUCCESS = "POST_DELETE_FAMILY_MEMBER_REQUEST_SUCCESS";
const POST_DELETE_FAMILY_MEMBER_REQUEST_FAILURE = "POST_DELETE_FAMILY_MEMBER_REQUEST_FAILURE";
const FAMILY_MEMBER_STATUS_REST = "FAMILY_MEMBER_STATUS_REST";


const deleteFamilyMember = (formData) => {
  return {
    type: POST_DELETE_FAMILY_MEMBER_REQUEST,
    formData,
  };
}; 
const changeFamilyMemberStatus = () => {
  return {
    type: FAMILY_MEMBER_STATUS_REST,
  };
};

export {
  deleteFamilyMember,
  changeFamilyMemberStatus,
  POST_DELETE_FAMILY_MEMBER_REQUEST,
  POST_DELETE_FAMILY_MEMBER_REQUEST_SUCCESS,
  POST_DELETE_FAMILY_MEMBER_REQUEST_FAILURE,
  FAMILY_MEMBER_STATUS_REST
};
