import React, { useCallback, useEffect, useState } from "react";
import * as SplashScreen from "expo-splash-screen";
import * as Font from "expo-font";
import "./Constants/IMLocalize";
import { Provider } from "react-redux";
import { store } from "./Redux/Store";
import AsyncStorage from "@react-native-async-storage/async-storage";
import RootStack from "./Navigation/RootStack";

// Keep the splash screen visible while we fetch resources
SplashScreen.preventAutoHideAsync();

export default function App() {
  const [appIsReady, setAppIsReady] = useState(false);
  const [getUser, setUser] = useState(null);


  useEffect(() => {
    async function prepare() {
      try {
        // Pre-load fonts
        await Font.loadAsync({
          Inter: require("./assets/fonts/Inter-Regular.ttf"),
          Roboto: require("./assets/fonts/Roboto-Regular.ttf"),
          "Open Sans": require("./assets/fonts/OpenSans-Regular.ttf"),
          Gilroy: require("./assets/fonts/Gilroy-Light.otf"),
        });
      let user = await AsyncStorage.getItem("user");
      setUser(user);
      } catch (e) {
        console.warn(e);
      } finally {
        // Tell the application to render
        setAppIsReady(true);
        await SplashScreen.hideAsync();
      }
    }

    prepare();
  }, []);

  if (!appIsReady) {
    return null;
  }

  return (
    <Provider store={store}>
     <RootStack user={getUser}/>
    </Provider>
  );
}
// function Chk() {
//   const users = useSelector(state => state)
//   const dispatch = useDispatch()
//   useEffect(() => {
//     dispatch(checkPhone('gvhjfgjfgjhfgg'));
//   }, [dispatch])
//   return(
//     <View>
//     <Text>{JSON.stringify(users)}</Text>
//     </View>
//    //<Loading></Loading>
//   )

// }
