
const GET_MDP_CARDS_KYC_STATUS_REQUEST = 'GET_MDP_CARDS_KYC_STATUS_REQUEST';
const GET_MDP_CARDS_KYC_STATUS_REQUEST_SUCCESS = 'GET_MDP_CARDS_KYC_STATUS_REQUEST_SUCCESS';
const GET_MDP_CARDS_KYC_STATUS_REQUEST_FAILURE = 'GET_MDP_CARDS_KYC_STATUS_REQUEST_FAILURE';

const mdpCardsKycStatus = () => {

  return {
    type: GET_MDP_CARDS_KYC_STATUS_REQUEST,
  }
}

export {
  mdpCardsKycStatus,
  GET_MDP_CARDS_KYC_STATUS_REQUEST,
  GET_MDP_CARDS_KYC_STATUS_REQUEST_SUCCESS,
  GET_MDP_CARDS_KYC_STATUS_REQUEST_FAILURE,
};