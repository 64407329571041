import {
  GET_CHECK_USER_PHONE_REQUEST,
  GET_CHECK_USER_PHONE_REQUEST_SUCCESS,
  GET_CHECK_USER_PHONE_REQUEST_FAILURE,
  RESET_CHECK_USER_PHONE_STATE,
} from "./Actions";

const initialState = {
  loading: null,
  message: null,
  error: null,
  data: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CHECK_USER_PHONE_REQUEST: {
      console.log(GET_CHECK_USER_PHONE_REQUEST);
      return {
        ...state,
        error: false,
        loading: true,
        phoneNumber: action.parm,
      };
    }
    case GET_CHECK_USER_PHONE_REQUEST_SUCCESS: {
      const { message, data } = action.payload;
      console.log(GET_CHECK_USER_PHONE_REQUEST_SUCCESS);
      return {
        ...state,
        loading: false,
        data,
        message,
      };
    }
    case GET_CHECK_USER_PHONE_REQUEST_FAILURE: {
      console.log(GET_CHECK_USER_PHONE_REQUEST_FAILURE);

      const { message } = action.payload;
      return {
        ...state,
        loading: false,
        error: true,
        message,
      };
    }
    case RESET_CHECK_USER_PHONE_STATE: {
      return {
        ...initialState,
        phoneNumber:state.phoneNumber
      };
    }
    default:
      return state;
  }
};

export { reducer };
