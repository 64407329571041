
const POST_ADD_COMMENT_REQUEST = 'POST_ADD_COMMENT_REQUEST';
const POST_ADD_COMMENT_REQUEST_SUCCESS =  'POST_ADD_COMMENT_REQUEST_SUCCESS';
const POST_ADD_COMMENT_REQUEST_FAILURE =  'POST_ADD_COMMENT_REQUEST_FAILURE';
const RESET_ADD_COMMENT_STATE = 'RESET_ADD_COMMENT_STATE';

const createCommentsRequest = (params,body) => {
  return {
    type: POST_ADD_COMMENT_REQUEST,
    params,
    body,
  };
};
const resetAddCommentsRequest = () => {
  return {
    type: RESET_ADD_COMMENT_STATE,
  };
};
export {
  createCommentsRequest,
  resetAddCommentsRequest,
  POST_ADD_COMMENT_REQUEST,
  POST_ADD_COMMENT_REQUEST_SUCCESS,
  POST_ADD_COMMENT_REQUEST_FAILURE,
  RESET_ADD_COMMENT_STATE
};