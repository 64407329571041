
const GET_MDP_CARDS_CUSTOMER_STATUS_REQUEST = 'GET_MDP_CARDS_CUSTOMER_STATUS_REQUEST';
const GET_MDP_CARDS_CUSTOMER_STATUS_REQUEST_SUCCESS = 'GET_MDP_CARDS_CUSTOMER_STATUS_REQUEST_SUCCESS';
const GET_MDP_CARDS_CUSTOMER_STATUS_REQUEST_FAILURE = 'GET_MDP_CARDS_CUSTOMER_STATUS_REQUEST_FAILURE';

const mdpCardsCustomerStatus = () => {

  return {
    type: GET_MDP_CARDS_CUSTOMER_STATUS_REQUEST,
  }
}

export {
  mdpCardsCustomerStatus,
  GET_MDP_CARDS_CUSTOMER_STATUS_REQUEST,
  GET_MDP_CARDS_CUSTOMER_STATUS_REQUEST_SUCCESS,
  GET_MDP_CARDS_CUSTOMER_STATUS_REQUEST_FAILURE,
};