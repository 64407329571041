import { takeEvery, put, call } from "redux-saga/effects";
import BASEURL from "../URLs";
import { queryApi } from "../QueryApi";
import {
  POST_USER_BENEFICIARIES_REQUEST,
  POST_USER_BENEFICIARIES_REQUEST_SUCCESS,
  POST_USER_BENEFICIARIES_REQUEST_FAILURE,
  POST_ADD_BENEFICIARIES_REQUEST,
  POST_ADD_BENEFICIARIES_REQUEST_SUCCESS,
  POST_ADD_BENEFICIARIES_REQUEST_FAILURE
} from "./Actions";

function* handler() {
  yield takeEvery(POST_USER_BENEFICIARIES_REQUEST, userBeneficiaries);
  yield takeEvery(POST_ADD_BENEFICIARIES_REQUEST, addBeneficiary);

}

function* userBeneficiaries(action) {
  try {
    // API call
    console.log(action.formData);
    const apiResponse = yield call(queryApi, {
      endpoint: BASEURL.beneficiaryList,
      method: "POST",
    });
    if (!("error" in apiResponse)) {
      yield put({
        type: POST_USER_BENEFICIARIES_REQUEST_SUCCESS,
        payload: apiResponse,
      });

    } else {
      yield put({
        type: POST_USER_BENEFICIARIES_REQUEST_FAILURE,
        payload: apiResponse.error,
      });
    }
  } catch (err) {
    console.log(err);
    // Handle error
    yield put({
      type: POST_USER_BENEFICIARIES_REQUEST_FAILURE,
      payload: { message: err },
    });
  }
}

function* addBeneficiary(action) {
  try {
    // API call
    console.log(action.formData);
    const apiResponse = yield call(queryApi, {
      endpoint: BASEURL.addBeneficiary,
      method: "POST",
      body:action.formData
    });
    console.log('ADD BENEFICIARIES', apiResponse)
    if (!("error" in apiResponse)) {
      yield put({
        type: POST_ADD_BENEFICIARIES_REQUEST_SUCCESS,
        payload: apiResponse,
      });

    } else {
      yield put({
        type: POST_ADD_BENEFICIARIES_REQUEST_FAILURE,
        payload: apiResponse.error,
      });
    }
  } catch (err) {
    console.log(err);
    // Handle error
    yield put({
      type: POST_ADD_BENEFICIARIES_REQUEST_FAILURE,
      payload: { message: err },
    });
  }
}

export { handler };
