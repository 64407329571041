import {takeEvery, put, call} from 'redux-saga/effects';
import BASEURL from '../URLs'
import {queryApi} from '../QueryApi'
import {
  DELETE_FILE_REQUEST,
  DELETE_FILE_REQUEST_SUCCESS,
  DELETE_FILE_REQUEST_FAILURE,
} from "./Actions";
//import {queryApi} from '../query-api';

function* handler() {
  yield takeEvery(DELETE_FILE_REQUEST, deleteFile);
}


function* deleteFile(action) {
  try {
    // API call
    console.log("Data", action.formData);
    const apiResponse = yield call(queryApi, {
      endpoint: BASEURL.deleteFile + action.params,
      method: "DELETE",
    });
    console.log("HIT DELETE_FILE", apiResponse);
    if (!("error" in apiResponse)) {
      yield put({
        type: DELETE_FILE_REQUEST_SUCCESS,
        payload: apiResponse,
        index: action.doc,
      });
    } else {
      yield put({
        type: DELETE_FILE_REQUEST_FAILURE,
        payload: apiResponse.error,
      });
    }
  } catch (err) {
    console.log(err);
    // Handle error
    yield put({
      type: DELETE_FILE_REQUEST_FAILURE,
      payload: { message: err },
    });
  }
}

export {handler};