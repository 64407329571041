import { StyleSheet, Text, View, Platform,TouchableOpacity } from "react-native";
import React from "react";
import { createMaterialTopTabNavigator } from "@react-navigation/material-top-tabs";
import New from "./New";
import MyRequest from "./MyRequest";
import { useTranslation } from "react-i18next";

const Tab = createMaterialTopTabNavigator();
const TabBar = ({ state, descriptors, navigation, position }) =>{
  const { t } = useTranslation();
  const [selected,setSelected] = React.useState(t("common:new_"))
  return <View style={styles.reqSideBar}>
    <Text style={styles.requestsText}>{t("common:requests")}</Text>
    <TouchableOpacity onPress={() => setSelected(t("common:new_"))} style={[styles.sideBtn, selected !== t("common:new_") && { border: 'none' }]}><Text style={styles.sideBtnText}>{t("common:new_")}</Text></TouchableOpacity>
    <TouchableOpacity onPress={() => setSelected(t("common:my_requests"))} style={[styles.sideBtn, selected !== t("common:my_requests") && { border: 'none' }]}><Text style={styles.sideBtnText}>{t("common:my_requests")}</Text></TouchableOpacity>
  </View>;
}
const TopTabMoney = () => {
  const { t } = useTranslation();
  return (
    <Tab.Navigator
   // sceneContainerStyle={{backgroundColor:'red', flexDirection:'column'}}
    //tabBar={(props)=><TabBar {...props}/>}
    //style={{flexDirection:'row',gap:30}}
      screenOptions={{
        tabBarGap: 10,
        tabBarLabelStyle: {
          textTransform: "none",
          fontFamily: 'Inter',
          fontStyle: "normal",
          fontWeight: "600",
          fontSize: 14,
        },
        tabBarActiveTintColor: 
        Platform.OS === "web" ?
            '#FF4747' : "#000000",
        tabBarInactiveTintColor:"#000000",
        tabBarIndicatorStyle: {},
        tabBarStyle: {
          display:'none',
          backgroundColor: "#F2F2F2",
          elevation: 0,
          shadowOpacity: 0,
        },
        swipeEnabled: false,
      }}
    >
      <Tab.Screen
        options={{
          tabBarIndicatorStyle:
            Platform.OS === "web" ? {
              backgroundColor: "#FF4747",
              height: 2,
            } : {
              backgroundColor: "#FF4747",
              height: 2,
              width: "10%",
              left: "20%"
            },
        }}
        name={t("common:new_")}
        component={New}
      />
      <Tab.Screen
        options={{
          tabBarIndicatorStyle:
            Platform.OS === "web" ? { 
              backgroundColor: "#FF4747", 
              height:2            
            } : {
              backgroundColor: "#FF4747",
              height:2,
              width: "25%",
              left: "12.5%"
            },
        }}
        name={t("common:my_requests")}
        component={MyRequest}
      />
    </Tab.Navigator>
  );
};

export default TopTabMoney;

const styles = StyleSheet.create({

  reqSideBar:{
    flex:1,
    backgroundColor:'white',
    borderRadius:16,
    paddingLeft:20,
    paddingVertical:30,
    maxWidth:279
  },  
  sideBtn:{
    paddingVertical:20,
    borderRightWidth:2,
    borderRightColor:'#FF4747'

  },
  sideBtnText:{
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: 18,
    lineHeight: 18
  },
  requestsText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 22,
    color: "#101820",
    alignSelf: "flex-start",
    marginBottom:20
  },
});
