
const GET_MDP_CREATE_CARD_REQUEST = 'GET_MDP_CREATE_CARD_REQUEST';
const GET_MDP_CREATE_CARD_REQUEST_SUCCESS =  'GET_MDP_CREATE_CARD_REQUEST_SUCCESS';
const GET_MDP_CREATE_CARD_REQUEST_FAILURE =  'GET_MDP_CREATE_CARD_REQUEST_FAILURE';

const mdpCreateCard = ()=>{

    return {
        type: GET_MDP_CREATE_CARD_REQUEST,
    }
}

export {
  mdpCreateCard,
  GET_MDP_CREATE_CARD_REQUEST,
  GET_MDP_CREATE_CARD_REQUEST_SUCCESS,
  GET_MDP_CREATE_CARD_REQUEST_FAILURE,
};