import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  Image,
  Dimensions,
  ScrollView,
  SafeAreaView,
} from "react-native";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Money from "../../assets/image/money.png";
import TopUpBtn from "../../assets/image/topUpBtn.png";
import WithDraw from "../../assets/image/withDraw.png";
import { Picker } from "@react-native-picker/picker";
const height = Dimensions.get("window").height;
import { useSelector, useDispatch } from "react-redux";

const FamilyWallet = ({ navigation }) => {
  const selectedFamilyMember = useSelector(
    (state) => state.myFamilyList.selectedFamilyMember
  );
  const { t } = useTranslation();
  const [wallet, setWallet] = useState();
  useEffect(() => {
    if (selectedFamilyMember?.accounts[0])
      setWallet(selectedFamilyMember?.accounts[0])
  }, [selectedFamilyMember?.accounts[0]])
  return (
    <SafeAreaView style={styles.topContainer}>
      <ScrollView showsHorizontalScrollIndicator={false} contentContainerStyle={{ flexGrow: 1 }}>
        <View style={styles.innerContainer}>
          <Text style={styles.nameText}>
            {t("common:total_balance")}
          </Text>
          <Text style={styles.priceText}>{wallet?.balance+ " " + wallet?.currency.currency_iso_code}</Text>
          <Text style={styles.nameText}>
            {t("common:wallets")}
          </Text>
          <View style={styles.usdView}>
            <Image source={Money} style={styles.moneyImage} />
            <Picker
              mode="dropdown"
              selectedValue={wallet}
              onValueChange={(wallet) => setWallet(wallet)}
              style={styles.pickerText}
            >
              {selectedFamilyMember !== undefined &&
                selectedFamilyMember.accounts.map((val, ind) => {
                  return (
                    <Picker.Item
                      key={ind}
                      label={val.name}
                      value={val}
                      style={styles.pickerItemText}
                    />
                  );
                })}
            </Picker>
          </View>
          <View style={styles.buttonView}>
            <TouchableOpacity
              onPress={() => navigation.navigate("FamilyTopUp", { wallet })}
              style={{ ...styles.topUpBtn, marginLeft: 0 }}
            >
              <Image source={TopUpBtn} style={styles.TopUpBtnImage} />
              <Text style={styles.btnText}>{t("common:top_up")}</Text>
            </TouchableOpacity>
            <TouchableOpacity style={styles.topUpBtn}>
              <Image source={WithDraw} style={styles.TopUpBtnImage} />
              <Text style={styles.btnText}>{t("common:with_draw")}</Text>
            </TouchableOpacity>
          </View>

        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

export default FamilyWallet;

const styles = StyleSheet.create({
  topContainer: {
    flex: 1,
    marginTop: 20,
    backgroundColor: "#FFFFFF",
    borderRadius: 16,
    padding: 20,
    marginBottom: 25,
  },
  innerContainer: {
    flex: 1,
    padding: 20
  },
  nameText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 14,
    color: "#A9ABAE",
    alignSelf: "flex-start",
    marginTop: 20,
  },
  priceText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 18,
    color: "#000000",
    marginTop: 8,
    alignSelf: "flex-start",
  },
  usdView: {
    height: 56,
    borderRadius: 10,
    borderWidth: 1,
    borderColor: "#E0E0E0",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: 8,
  },
  moneyImage: {
    height: 24,
    width: 24,
    marginLeft: 16,
  },
  pickerText: {
    flex: 1,
    height: 45,
    borderWidth: 1,
    borderColor: "white",
    borderStyle: "solid",
  },
  pickerItemText: {
    color: "#101820",
    fontSize: 14,
    fontWeight: "500",
    fontFamily: "Inter",
  },
  buttonView: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 24,
    minHeight: 56
  },
  topUpBtn: {
    marginLeft: 15,
    maxWidth: 156,
    flex: 1,
    flexDirection: "row",
    backgroundColor: "#F8F8F8",
    borderRadius: 16,
    alignItems: "center",
    justifyContent: "space-evenly",
  },
  TopUpBtnImage: {
    height: 18,
    width: 20,
  },
  btnText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 14,
    textAlign: "center",
    color: "#000000",
  },
});
