import { StyleSheet, Text, View, SafeAreaView } from "react-native";
import React from "react";
import Icon from "react-native-vector-icons/Feather";
import { useTranslation } from "react-i18next";
import { DateFormate, formatAMPM } from "../../Utils/HelperFunctions";
import AppHeaer  from "../../Components/AppHeader"

const FamilyTransactionDetail = ({ navigation, route }) => {
  const { t } = useTranslation();
  const { val } = route.params;
  console.log("Val", val);
  return (
    <SafeAreaView style={styles.mainContainer}>
      <AppHeaer/>
      <View style={styles.topContainer}>
        <View style={styles.topHeader}>
          <View style={styles.headerView}>
            <Icon
              name="arrow-left"
              size={30}
              onPress={() => navigation.goBack()}
            />
            <Text style={styles.cardText}>{t("common:transaction_detail")}</Text>
          </View>
          <View style={styles.innerContainer}>
            <View style={styles.container}>
              <Text style={styles.nameText}>{t("common:transaction_amount")}</Text>
              <Text style={styles.summaryText}>LYD {val.send_amount}</Text>
            </View>

            <View style={styles.container}>
              <Text style={styles.nameText}>{t("common:transaction_id")}</Text>
              <Text style={styles.summaryText}>{val.id}</Text>
            </View>

            <View style={styles.container}>
              <Text style={styles.nameText}>{t("common:transaction_from")}</Text>
              <Text style={styles.summaryText}> {val.sender.name}</Text>
            </View>

            <View style={styles.container}>
              <Text style={styles.nameText}>{t("common:transaction_to")}</Text>
              <Text style={styles.summaryText}>{val.receiver.name}</Text>
            </View>
            <View style={styles.container}>
              <Text style={styles.nameText}>{t("common:transaction_number")}</Text>
              <Text style={styles.summaryText}>{val.transaction_no}</Text>
            </View>
            <View style={styles.container}>
              <Text style={styles.nameText}>
                {t("common:transactions_status")}
              </Text>
              <Text style={styles.summaryText}>{val.status}</Text>
            </View>
            <View style={styles.container}>
              <Text style={styles.nameText}>
                {t("common:transactions_created")}
              </Text>
              <Text style={styles.summaryText}>
                {DateFormate(new Date(val.created)) +
                  " " +
                  formatAMPM(new Date(val.created))}
              </Text>
            </View>
          </View>
        </View>
      </View>
    </SafeAreaView>
  );
};

export default FamilyTransactionDetail;

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
  },
  topContainer: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "center"
  },
  topHeader: {
    flex: 1,
    maxWidth: 1400
  },
  headerView: {
    flexDirection: "row",
    marginTop: 25,
    alignItems: "center",
  },
  arrowIcon: {
    marginLeft: 24,
  },
  cardText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 26,
    color: "#000000",
   marginLeft:15
  },
  innerContainer: {
    backgroundColor:"#FFF",
    borderRadius:16,
    marginTop:25,
    padding:20,
    gap:20
  },
  container: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  nameText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 14,
    color: "#101820",
  },
  summaryText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 14,
    textAlign: "right",
    color: "#FF4747",
  },
});
