import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  Image,
  SafeAreaView,
  Dimensions,
  ScrollView,
  Platform,
  Modal,
  Pressable
} from "react-native";
import React, { useState } from "react";
import TopUp from "../../assets/image/TopUp.png";
import Exchange from "../../assets/image/Exchange.png";
import Kyc from "../../assets/image/KYC.png";
import Cbl from "../../assets/image/CBL.png";
import LetterOfCredit from "../../assets/image/LetterOfCredit.png";
import Icon from "react-native-vector-icons/Feather";
import { useTranslation } from "react-i18next";
const height = Dimensions.get("window").height;
import { useDispatch, useSelector } from "react-redux";
import ToastAndroid from "react-native-root-toast";
import MdpCards from "../../assets/image/mdpCards.png"

const New = ({ navigation }) => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const userDetails = useSelector((state) => state.userDetails);
  const verificationCheck = () => {
    if (userDetails.data.user.verification_level === "0") {
      setVisible(true)
      return
    }
    navigation.navigate("CblRequest");
  }
  const kycScreen = () => {
    navigation.navigate("PersonalInfo");
    setVisible(false)
  }
  const InProgress = () => {
    ToastAndroid.show(t("common:in_progress"), ToastAndroid.SHORT);
  };
  return (
    <SafeAreaView style={styles.topContainer}>
      <Modal
        transparent={true}
        visible={visible}>
        <Pressable onPress={() => setVisible(false)} style={styles.innerDialog}>
          <View style={styles.dialogContainer}>
            <View style={styles.textContainer}>
              <Text style={styles.profileText}>
                {t("common:would_complete_your_profile")}
              </Text>
              <Text style={styles.cblText}>{t("common:cbl_issuance_text")}</Text>
            </View>
            <View style={styles.btnContainer}>
              <TouchableOpacity
                onPress={() => setVisible(false)}
                style={styles.btnNo}
              >
                <Text style={styles.btnNoText}>{t("common:No")}</Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={kycScreen}
                style={styles.btnYes}
              >
                <Text style={styles.btnYesText}>{t("common:Yes")}</Text>
              </TouchableOpacity>
            </View>
          </View>
        </Pressable>

      </Modal>
      <View style={styles.container}>
        <ScrollView showsHorizontalScrollIndicator={false}>
          <View style={{}}>
            <TouchableOpacity
              onPress={() => navigation.navigate("TopUpMoney")}
              style={styles.buttonContainer}
            >
              <View style={styles.imageContainer}>
                <Image source={TopUp} style={styles.topImage} />
                <Text style={styles.topUpText}>{t("common:top_up_money")}</Text>
              </View>
              <Icon name="chevron-right" size={24} />
            </TouchableOpacity>

            <TouchableOpacity
              onPress={() => navigation.navigate("ExchangeWalletToWallet")}
              style={styles.buttonContainer}
            >
              <View style={styles.imageContainer}>
                <Image source={Exchange} style={styles.topImage} />
                <Text style={styles.topUpText}>{t("common:exchange_money")}</Text>
              </View>
              <Icon name="chevron-right" size={24} />
            </TouchableOpacity>

            <TouchableOpacity
              onPress={() => navigation.navigate("PersonalInfo")}
              style={styles.buttonContainer}
            >
              <View style={styles.imageContainer}>
                <Image source={Kyc} style={styles.topImage} />
                <Text style={styles.topUpText}>{t("common:kyc")}</Text>
              </View>
              <Icon name="chevron-right" size={24} />
            </TouchableOpacity>

            <TouchableOpacity
              onPress={verificationCheck}
              style={styles.buttonContainer}
            >
              <View style={styles.imageContainer}>
                <Image source={Cbl} style={styles.topImage} />
                <Text style={styles.topUpText}>{t("common:cbl")}</Text>
              </View>
              <Icon name="chevron-right" size={24} />
            </TouchableOpacity>

            <TouchableOpacity
              onPress={InProgress}
              style={styles.buttonContainer}
            >
              <View style={styles.imageContainer}>
                <Image source={LetterOfCredit} style={styles.topImage} />
                <Text style={styles.topUpText}>{t("common:letter_of_credit")}</Text>
              </View>
              <Icon name="chevron-right" size={24} />
            </TouchableOpacity>
            <TouchableOpacity onPress={() => navigation.navigate("MdpCard")} style={{ ...styles.buttonContainer, borderBottomWidth: 0 }}>
              <View style={styles.imageContainer}>
                <Image source={MdpCards} style={styles.topImage} />
                <Text style={styles.topUpText}>
                  {t("common:mdp_cards")}
                </Text>
              </View>
              <Icon name="chevron-right" size={24} />
            </TouchableOpacity>
          </View>
        </ScrollView>
      </View>
    </SafeAreaView>
  );
};

export default New;

const styles = StyleSheet.create({
  topContainer: {
    flex: 1,
  },
  dialogContainer: {
    backgroundColor: "#FFFFFF",
    borderRadius: 20,
    marginHorizontal: 24,
    justifyContent: 'center',
    alignSelf: "center",
    padding: 20
  },
  innerDialog: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#000000aa",
  },
  textContainer: {
    marginHorizontal: 12,
    marginVertical: 5
  },
  cblText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontSize: 15,
    fontWeight: "500",
    textAlign: "center",
    marginTop: 20,
  },
  profileText: {
    textAlign: "center",
    fontFamily: "Inter",
    fontSize: 15,
    fontWeight: "bold",
  },
  btnContainer: {
    marginTop: 20,
    marginBottom: 10,
    flexDirection: "row",
    justifyContent: "space-around",
  },
  btnNo: {
    borderRadius: 25,
    borderColor: "#FF4747",
    borderWidth: 1,
    height: 50,
    width: 130,
    justifyContent: "center",
  },
  btnNoText: {
    fontFamily: "Inter",
    fontSize: 15,
    fontWeight: "500",
    color: "#FF4747",
    textAlign: "center",
    alignItems: "center",
  },
  btnYes: {
    borderRadius: 25,
    backgroundColor: "#FF4747",
    height: 50,
    width: 130,
    justifyContent: "center",
  },
  btnYesText: {
    fontFamily: "Inter",
    fontSize: 15,
    fontWeight: "500",
    color: "#FFFFFF",
    textAlign: "center",
    alignItems: "center",
  },
  container: {
    flex: 1,
  },
  buttonContainer: {
    flexDirection: "row",
    // borderBottomWidth: 1,
    // borderColor: "#F1F1F1",
    marginTop: 15,
    height: 100,
    backgroundColor: "#FFFFFF",
    borderRadius: 16,
    alignItems: "center",
    justifyContent: "center",
    paddingHorizontal: 20
  },
  imageContainer: {
    flex: 1,
    justifyContent: "flex-start",
    flexDirection: "row",
  },
  topUpText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 18,
    color: "#000000",
    marginLeft: 15,
    marginTop: 10
  },
  topImage: {
    width: 40,
    height: 40,
    resizeMode: "contain"
  },
});
