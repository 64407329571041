
const POST_REQUEST_UPDATE_REQUEST = 'POST_REQUEST_UPDATE_REQUEST';
const POST_REQUEST_UPDATE_REQUEST_SUCCESS =  'POST_REQUEST_UPDATE_REQUEST_SUCCESS';
const POST_REQUEST_UPDATE_REQUEST_FAILURE =  'POST_REQUEST_UPDATE_REQUEST_FAILURE';
const RESET_KYC_UPDATE_STATE = 'RESET_KYC_UPDATE_STATE';

const requestUpdate = (requestId,formData)=>{

    return {
        type: POST_REQUEST_UPDATE_REQUEST,
        formData,
        requestId
    }
}
const resetRequestUpdate = ()=>{

  return {
      type: RESET_KYC_UPDATE_STATE,
  }
}

export {
  requestUpdate,
  resetRequestUpdate,
  POST_REQUEST_UPDATE_REQUEST,
  POST_REQUEST_UPDATE_REQUEST_SUCCESS,
  POST_REQUEST_UPDATE_REQUEST_FAILURE,
  RESET_KYC_UPDATE_STATE
};