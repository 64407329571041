import {
  StyleSheet,
  Text,
  View,
  Dimensions,
  ScrollView,
  TouchableOpacity,
} from "react-native";
import React from "react";
import { SafeAreaView } from "react-native-safe-area-context";
import Icon from "react-native-vector-icons/Feather";
const height = Dimensions.get("window").height;

const BankTransferList = ({ navigation }) => {
  return (
    <SafeAreaView style={styles.mainContainer}>
      <ScrollView style={{ flex: 1, marginTop: 20 }}>
        <View style={{flex:1,gap:15}}>
          <View style={styles.container}>
            <Text style={styles.nameText}>Kashif Ali</Text>
            <View style={styles.numberContainer}>
              <Text style={styles.numberText}>Lybian Bank</Text>
              <Icon name="chevron-right" size={24} />
            </View>
          </View>
          <View style={styles.container}>
            <Text style={styles.nameText}>Kashif Ali</Text>
            <View style={styles.numberContainer}>
              <Text style={styles.numberText}>Lybian Bank</Text>
              <Icon name="chevron-right" size={24} />
            </View>
          </View>
          <View style={styles.container}>
            <Text style={styles.nameText}>Kashif Ali</Text>
            <View style={styles.numberContainer}>
              <Text style={styles.numberText}>Lybian Bank</Text>
              <Icon name="chevron-right" size={24} />
            </View>
          </View>
          <View style={styles.container}>
            <Text style={styles.nameText}>Kashif Ali</Text>
            <View style={styles.numberContainer}>
              <Text style={styles.numberText}>Lybian Bank</Text>
              <Icon name="chevron-right" size={24} />
            </View>
          </View>
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

export default BankTransferList;

const styles = StyleSheet.create({
  mainContainer: {
    backgroundColor: "#FFFFFF",
    marginTop: 20,
    padding: 30,
  },
  container: {
    flexDirection: "row",
    alignItems: "center",
    borderWidth: 1,
    borderColor: "#F1F1F1",
    height: 56,
    borderRadius: 16,
    paddingHorizontal: 15,
  },
  nameText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 14,
    color: "#10121F",
    justifyContent: "flex-start",
  },
  numberContainer: {
    flex: 1,
    justifyContent: "flex-end",
    flexDirection: "row",
    alignItems: "center",
  },
  numberText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 14,
    color: "#FF4747",
    marginRight: 10,
  },
  btnView: {
    marginBottom: 50,
    alignItems: "flex-end",
  },
  button: {
    backgroundColor: "#FF4747",
    padding: 16,
    borderRadius: 100,
    marginRight: 24,
  },
});
