import React, { useEffect } from "react";
import {
  View,
  Text,
  StyleSheet,
  Pressable,
  I18nManager,
  TouchableOpacity,
  Platform
} from "react-native";
import { useTranslation } from "react-i18next";
import RNRestart from "react-native-restart";
import AsyncStorage from "@react-native-async-storage/async-storage";

const LANGUAGES = [
  { code: "en", label: "English" },
  { code: "ar", label: "Arabic" },
];

const Selector = ({ navigation }) => {
  const { t, i18n } = useTranslation();
  const selectedLanguageCode = i18n.language;
  const setLanguage = (code) => {
    I18nManager.forceRTL(code === "ar");
    let currlang = i18n.language;

    i18n.changeLanguage(code);
    if (currlang === code) {
      navigation.navigate("HomeScreen");
    } else {
      AsyncStorage.setItem(
        "lang",
        JSON.stringify({ lang: code, restarted: true })
      ).then(val => {
        if (Platform.OS === "web") {
          window.location.reload();
        } else {
          RNRestart.Restart()
        }
      }

      );

    }
  };


  return (
    <View style={styles.container}>
      <View style={styles.row}>
        <Text style={styles.title}>{t("navigate:home")}</Text>
        {/* <Ionicons color='#444' size={28} name='ios-language-outline' /> */}
      </View>

      {LANGUAGES.map((language) => {
        const selectedLanguage = language.code === selectedLanguageCode;

        return (
          <Pressable
            key={language.code}
            style={styles.buttonContainer}
            onPress={() => setLanguage(language.code)}
          >
            <Text
              style={[selectedLanguage ? styles.selectedText : styles.text]}
            >
              {language.label}
            </Text>
          </Pressable>
        );
      })}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingTop: 60,
    paddingHorizontal: 16,
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  title: {
    color: "#444",
    fontSize: 28,
    fontWeight: "600",
  },
  buttonContainer: {
    marginTop: 10,
  },
  text: {
    fontSize: 18,
    color: "#000",
    paddingVertical: 4,
  },
  selectedText: {
    fontSize: 18,
    fontWeight: "600",
    color: "tomato",
    paddingVertical: 4,
  },
});

export default Selector;
