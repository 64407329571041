import React from "react";
import { NavigationContainer } from "@react-navigation/native";
import { createMaterialTopTabNavigator } from "@react-navigation/material-top-tabs";
import GiftCards from "./GiftCards";
import PurchasedCard from "./PurchasedCard";
import { useTranslation } from "react-i18next";
import { Platform } from "react-native";
const Tab = createMaterialTopTabNavigator();
 
const TopTabGift = () => {
  const { t } = useTranslation();

  return (
    <Tab.Navigator
    screenOptions={{
      tabBarLabelStyle: {
        textTransform: "none",
        fontFamily: 'Inter',
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: 14,
      },
      tabBarActiveTintColor: 
      Platform.OS === "web" ?
          '#FF4747' : "#000000",
      tabBarInactiveTintColor:"#000000",
      tabBarStyle: 
      Platform.OS === "web" ? 
      {
        backgroundColor: "#F2F2F2",
        elevation: 0,
        shadowOpacity: 0, 
        width:350
      }: {
        backgroundColor: "#F2F2F2",
        elevation: 0,
        shadowOpacity: 0,
      },
      swipeEnabled: false,
    }}
    >
      <Tab.Screen
        options={{
          tabBarIndicatorStyle:  
          Platform.OS === "web" ? {
            backgroundColor: "#FF4747",
            height: 2
          } : {
            backgroundColor: "#FF4747",
            height: 2,
            width: "19%",
            left: "16%",
            alignSelf: "center"
          },
        }}
        name={t("common:gift_cards")}
        component={GiftCards}
      />
      <Tab.Screen
        options={{
          tabBarIndicatorStyle:
          Platform.OS === "web" ? {
            backgroundColor: "#FF4747", 
            height: 2
          } : {
            backgroundColor: "#FF4747",
            height: 2,
            width: "33%",
            left: "9%"
          },
        }}
        name={t("common:Purchased_Cards")}
        component={PurchasedCard}
      />
    </Tab.Navigator>
  );
};

export default TopTabGift;

