import {takeEvery, put, call} from 'redux-saga/effects';
import BASEURL from '../URLs'
import {queryApi} from '../QueryApi'
import {
  POST_BUY_SERVICE_VOUCHER_REQUEST,
  POST_BUY_SERVICE_VOUCHER_REQUEST_SUCCESS,
  POST_BUY_SERVICE_VOUCHER_REQUEST_FAILURE,
} from './Actions'; 
//import {queryApi} from '../query-api';

function* handler() {
  yield takeEvery(POST_BUY_SERVICE_VOUCHER_REQUEST, buyServiceVoucher);
}


function* buyServiceVoucher(action) {
  try {
    // API call
    console.log("BUY_SERVICE_VOUCHER", action.formData);
    const apiResponse = yield call(queryApi, {
      endpoint: BASEURL.buyServiceVoucher,
      method: "POST",
      body: action.formData,
    });
    console.log("BUY_SERVICE_VOUCHER", apiResponse);
    if (!("error" in apiResponse)) {
      yield put({
        type: POST_BUY_SERVICE_VOUCHER_REQUEST_SUCCESS,
        payload: apiResponse,
      });
    } else {
      yield put({
        type: POST_BUY_SERVICE_VOUCHER_REQUEST_FAILURE,
        payload: apiResponse.error,
      });
    }
  } catch (err) {
    console.log(err);
    // Handle error
    yield put({
      type: POST_BUY_SERVICE_VOUCHER_REQUEST_FAILURE,
      payload: { message: err },
    });
  }
}

export {handler};