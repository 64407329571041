import {
  StyleSheet,
  Text,
  View,
  Dimensions,
  ScrollView,
  TextInput,
  I18nManager,
  TouchableOpacity,
  Platform,
  KeyboardAvoidingView,
  Keyboard,
  SafeAreaView,
} from "react-native";
import ToastAndroid from "react-native-root-toast";
import React, { useState, useEffect, useRef } from "react";
import Icon from "react-native-vector-icons/Feather";
import { useTranslation } from "react-i18next";
import { Picker } from "@react-native-picker/picker";
const height = Dimensions.get("window").height;
import SideBarConatainer from "../../Components/SideBarConatainer";
import StepContainer from "../../Components/StepContainer";

const FinancialInfo = ({ navigation, route }) => {
  const { t } = useTranslation();
  const [source, setSource] = useState("business");
  const [lyd, setLyd] = useState("");
  const [experience, setExperience] = useState("");
  const [employName, setEmployName] = useState("");
  const [role, setRole] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [income, setIncome] = useState("");
  const [disabled, setDisabled] = useState(true);

  const roleRef = useRef();
  const contactNumberRef = useRef();
  const incomeRef = useRef();
  const experienceRef = useRef();

  const { formData } = route.params;
  // console.log(formData);
  const validate = () => {
    Keyboard.dismiss();
    if (employName === "") {
      ToastAndroid.show(
        t("common:Please_enter_Business_Employee_Name"),
        ToastAndroid.LONG
      );
      return false;
    } else if (role === "") {
      ToastAndroid.show(t("common:Please_select_the_Role"), ToastAndroid.LONG);
      return false;
    } else if (contactNumber === "") {
      ToastAndroid.show(
        t("common:Please_enter_official_no"),
        ToastAndroid.LONG
      );
      return false;
    } else if (income === "") {
      ToastAndroid.show(
        t("common:Please_enter_the_Average_Monthly_Income"),
        ToastAndroid.LONG
      );
      return false;
    } else if (experience === "") {
      ToastAndroid.show(
        t("common:Please_Enter_your_current_Experience"),
        ToastAndroid.LONG
      );
      return false;
    }
    return true;
  };
  const nextStep = () => {
    if (!validate()) {
      return;
    }
    formData.source_income = source;
    formData.business_name = employName;
    formData.role = role;
    formData.contact_no = contactNumber.replace(/-/g, "");
    formData.monthly_income = income;
    formData.current_experience = experience;
    navigation.navigate("Documents", { formData });
  };
  useEffect(() => {
    if (!!employName && !!role && !!contactNumber && !!income) {
      setDisabled(false);
    } else {
      setDisabled(false);
    }
  }, [source, lyd, experience, employName, role, contactNumber, income]);
  return (
    <SideBarConatainer navigation={navigation}>
      <View style={styles.headerView}>
        <Icon
          name="arrow-left"
          size={30}
          style={styles.arrowIcon}
          onPress={() => navigation.goBack()}
        />
        <Text style={styles.kycText}>{t("common:kyc")}</Text>
      </View>
      <View style={styles.docContainer}>
        <ScrollView showsHorizontalScrollIndicator={false}>
          <View style={styles.mainContainer}>
            <StepContainer totalStep={3} currentStep={3} />
            <KeyboardAvoidingView
              style={{ flex: 1 }}
              behavior={Platform.OS === "ios" ? "padding" : "height"}
            >
              <View style={styles.innerContainer}>
                <Text style={styles.personalInfoText}>
                  {t("common:provide_financial_info")}
                </Text>
                <View style={styles.inputContainer}>
                  <View style={styles.inputView}>
                    <Text style={styles.nameText}>
                      {t("common:source_of_income_st")}
                      <Text style={{ color: "#FF4747" }}>*</Text>
                    </Text>
                    <View style={styles.PickerView}>
                      <Picker
                        mode="dropdown"
                        selectedValue={source}
                        onValueChange={(selectedValue) =>
                          setSource(selectedValue)
                        }
                        style={styles.pickerItem}
                      >
                        <Picker.Item
                          label="Business"
                          value="business"
                          style={styles.pickerItemText}
                        />
                        <Picker.Item
                          label="Job"
                          value="job"
                          style={styles.pickerItemText}
                        />
                        <Picker.Item
                          label="Other"
                          value="other"
                          style={styles.pickerItemText}
                        />
                      </Picker>
                    </View>
                  </View>
                  <View style={{ ...styles.inputView, marginLeft: 20 }}>
                    <Text style={styles.nameText}>
                      {t("common:business_Emp_name")}
                      <Text style={{ color: "#FF4747" }}>*</Text>
                    </Text>
                    <TextInput
                      textAlign={I18nManager.isRTL ? "right" : "left"}
                      value={employName}
                      returnKeyType="next"
                      onSubmitEditing={() => roleRef.current.focus()}
                      onChangeText={(text) => setEmployName(text)}
                      style={{ ...styles.input, height: 85 }}
                    ></TextInput>
                  </View>
                </View>

                <View style={styles.inputContainer}>
                  <View style={styles.inputView}>
                    <Text style={styles.nameText}>
                      {t("common:role_st")}
                      <Text style={{ color: "#FF4747" }}>*</Text>
                    </Text>
                    <TextInput
                      textAlign={I18nManager.isRTL ? "right" : "left"}
                      value={role}
                      returnKeyType="next"
                      onSubmitEditing={() => contactNumberRef.current.focus()}
                      ref={roleRef}
                      onChangeText={(text) => setRole(text)}
                      style={styles.input}
                    ></TextInput>
                  </View>
                  <View style={{ ...styles.inputView, marginLeft: 20 }}>
                    <Text style={styles.nameText}>
                      {t("common:contact_no_st")}
                      <Text style={{ color: "#FF4747" }}>*</Text>
                    </Text>
                    <TextInput
                      textAlign={I18nManager.isRTL ? "right" : "left"}
                      value={contactNumber}
                      returnKeyType="next"
                      onSubmitEditing={() => incomeRef.current.focus()}
                      ref={contactNumberRef}
                      onChangeText={(text) => {
                        var cleaned = ("" + text).replace(/\D/g, "");
                        let normValue = `${cleaned.substring(0, 3)}${cleaned.length > 3 ? "-" : ""
                          }${cleaned.substring(3, 6)}${cleaned.length > 6 ? "-" : ""
                          }${cleaned.substring(6, 10)}`;
                        setContactNumber(normValue);
                      }}
                      keyboardType="number-pad"
                      style={styles.input}
                    ></TextInput>
                  </View>
                </View>

                <View style={styles.inputContainer}>
                  <View style={styles.inputView}>
                    <Text style={styles.nameText}>
                      {t("common:average_monthly_income_st")}
                      <Text style={{ color: "#FF4747" }}>*</Text>
                    </Text>
                    <TextInput
                      textAlign={I18nManager.isRTL ? "right" : "left"}
                      value={income}
                      returnKeyType="next"
                      onSubmitEditing={() => experienceRef.current.focus()}
                      ref={incomeRef}
                      onChangeText={(text) => setIncome(text)}
                      keyboardType="number-pad"
                      style={styles.averageInput}
                    ></TextInput>
                  </View>
                  <View style={{ ...styles.inputView, marginLeft: 20 }}>
                    <View style={styles.lydView}>
                      <Picker
                        mode="dropdown"
                        selectedValue={lyd}
                        onValueChange={(selectedValue) => setLyd(selectedValue)}
                        style={styles.pickerLyd}
                      >
                        <Picker.Item
                          label="LYD"
                          value="LYD"
                          style={styles.pickerItemText}
                        />
                        <Picker.Item
                          label="USD"
                          value="USD"
                          style={styles.pickerItemText}
                        />
                      </Picker>
                    </View>
                  </View>
                </View>

                <View style={styles.inputContainer}>
                  <View style={styles.inputView}>
                    <Text style={styles.nameText}>
                      {t("common:years_of_experience_st")}
                      <Text style={{ color: "#FF4747" }}>*</Text>
                    </Text>
                    <TextInput
                      textAlign={I18nManager.isRTL ? "right" : "left"}
                      value={experience}
                      ref={experienceRef}
                      onChangeText={(text) => setExperience(text)}
                      keyboardType="number-pad"
                      returnKeyType="done"
                      style={styles.input}
                    ></TextInput>
                  </View>
                </View>

                <View style={styles.btnView}>
                  <TouchableOpacity onPress={nextStep} style={styles.button}>
                    <Text style={styles.buttonText}>
                      {t("common:save_and_continue")}
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>
            </KeyboardAvoidingView>
          </View>
        </ScrollView>
      </View>

    </SideBarConatainer>
  );
};

export default FinancialInfo;

const styles = StyleSheet.create({
  topContainer: {
    flex: 1,
    backgroundColor: "#FFFFFF",
  },
  headerView: {
    flexDirection: "row",
  },
  kycText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 26,
    color: "#000000",
    marginLeft: 10,
  },
  docContainer: {
    backgroundColor: "#FFF",
    borderRadius: 16,
    marginTop: 27,
    flex: 1,
  },
  mainContainer: {
    padding: 20,
  },
  innerContainer: {
    flex: 1,
    marginLeft: 24,
    marginRight: 24,
  },
  inputContainer: {
    flex: 1,
    flexDirection: "row",
  },
  inputView: {
    flex: 1,
    maxWidth: 327,
  },
  personalInfoText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 18,
    color: "#000000",
    marginTop: 20,
    alignSelf: "flex-start",
  },
  nameText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 14,
    color: "#A9ABAE",
    alignSelf: "flex-start",
    marginTop: 10,
  },
  input: {
    height: 56,
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 14,
    borderColor: "#E7E8E9",
    borderBottomWidth: 1,
    outlineStyle: "none",
    color: "#101820",
  },
  PickerView: {
    borderBottomWidth: 1,
    borderColor: "#E7E8E9",
    height: 47,
    justifyContent: "center",
  },
  pickerItem: {
    height: 45,
    borderWidth: 1,
    borderColor: "white",
    borderStyle: "solid",
  },
  pickerItemText: {
    color: "#101820",
    fontSize: 14,
    fontWeight: "500",
    fontFamily: "Inter",
  },
  incomeView: {
    flexDirection: "row",
  },
  averageInput: {
    borderBottomWidth: 1,
    borderColor: "#E7E8E9",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 14,
    color: "#101820",
    flex: 1,
    outlineStyle: "none",
  },
  lydView: {
    borderBottomWidth: 1,
    borderColor: "#E7E8E9",
    height: 47,
    justifyContent: "center",
    flex: 1,
    marginLeft: 20,
  },
  pickerLyd: {
    flex: 1,
    height: 45,
    borderWidth: 1,
    borderColor: "white",
    borderStyle: "solid",
  },
  btnView: {
    flex: 1,
    marginTop: 30,
    alignItems: "flex-end",
  },
  button: {
    padding: 16,
    backgroundColor: "#FF4747",
    borderRadius: 70,
    width: 404,
  },
  buttonText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 16,
    alignItems: "center",
    textAlign: "center",
    color: "#FFFFFF",
  },
});
