import { StyleSheet, Text, View ,Image} from "react-native";
import LogoImage from "../assets/image/logotizdan.png"

export default function AuthHeader() {
  return (
    <View style={styles.headerContainer}>
      <View style={{ flex: 1, maxWidth: 1300 }}>
      <Image source={LogoImage} style={{width:120,height:50,resizeMode:"contain"}}/>
      </View>
    </View>
  );
}
const styles = StyleSheet.create({
  headerContainer: {
    backgroundColor: "white",
    padding: 10,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    height: 55,
  },
  logoView: {
    color: "#FF4747",
    fontSize: 23,
    marginLeft: 25,
    fontWeight: "700",
  },
});
