import { StyleSheet, Text, SafeAreaView, View } from "react-native";
import React from "react";
import Icon from "react-native-vector-icons/Feather";
import { useTranslation } from "react-i18next";
import AppHeader from "../../Components/AppHeader";
import SideBar from "./Components/SideBar";
import { useSelector } from "react-redux";

const TransactionDetail = ({ navigation, route }) => {
  const { t } = useTranslation();
  const { val } = route.params;
  const userDetails = useSelector((state) => state.userDetails);

  return (
    <SafeAreaView style={styles.topContainer}>
      <View style={styles.insideContainer}></View>
      <View style={styles.mainContainer}>
        <View style={{ flex: 1 }}>
          <AppHeader navigation={navigation} />
          <View style={styles.headerView}>
            <Icon
              name="arrow-left"
              size={30}
              style={styles.arrowIcon}
              onPress={() => navigation.goBack()}
            />
            <Text style={styles.cardText}>
              {t("common:transaction_detail")}
            </Text>
          </View>
          <View style={styles.innerContainer}>
            <View style={styles.container}>
              <Text style={styles.nameText}>
                {t("common:transaction_amount")}
              </Text>
              <Text style={styles.summaryText}>
                {val.sending_currency + " " + val.amount}
              </Text>
            </View>

            <View style={styles.container}>
              <Text style={styles.nameText}>{t("common:transaction_id")}</Text>
              <Text style={styles.summaryText}>{val.Transactions__id}</Text>
            </View>

            <View style={styles.container}>
              <Text style={styles.nameText}>
                {t("common:transaction_from")}
              </Text>
              <Text style={styles.summaryText}> {val.sender}</Text>
            </View>

            <View style={styles.container}>
              <Text style={styles.nameText}>{t("common:transaction_to")}</Text>
              <Text style={styles.summaryText}>{val.receiver}</Text>
            </View>
            <View style={styles.container}>
              <Text style={styles.nameText}>
                {t("common:transaction_number")}
              </Text>
              <Text style={styles.summaryText}>{val.transaction_number}</Text>
            </View>
            <View style={styles.container}>
              <Text style={styles.nameText}>
                {t("common:transactions_status")}
              </Text>
              <Text style={styles.summaryText}>{val.Transactions__status}</Text>
            </View>
            <View style={styles.container}>
              <Text style={styles.nameText}>
                {t("common:transactions_created")}
              </Text>
              <Text style={styles.summaryText}>
                {val.Transactions__created}
              </Text>
            </View>
          </View>
        </View>
      </View>
    </SafeAreaView>
  );
};

export default TransactionDetail;

const styles = StyleSheet.create({
  topContainer: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "center",
    backgroundColor: "#f8f8f8",
  },
  insideContainer: {
    position: "absolute",
    backgroundColor: "white",
    height: 80,
    width: "100%",
  },
  mainContainer: {
    flex: 1,
    backgroundColor: "#F8F8F8",
    flexDirection: "row",
    maxWidth: 1400,
  },
  headerView: {
    flexDirection: "row",
    alignItems: "center",
    marginTop:25
  },
  arrowIcon: {
  },
  cardText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 26,
    color: "#000000", 
    marginLeft:15
  },
  innerContainer: {
    backgroundColor:"#FFF",
    borderRadius:16,
    marginTop:25,
    padding:20,
    gap:20
  },
  container: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  nameText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 14,
    color: "#101820",
  },
  summaryText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 14,
    textAlign: "right",
    color: "#FF4747",
  },
});
