import AsyncStorage from "@react-native-async-storage/async-storage";
import {
  POST_USER_LOGIN_REQUEST,
  POST_USER_LOGIN_REQUEST_SUCCESS,
  POST_USER_LOGIN_REQUEST_FAILURE,
  SET_LOGIN_USER,
  RESET_LOGIN_STATE
} from "./Actions";

const initialState = {
  message: null,
  data: {
    token: null,
    customer_id: null,
    otp_verified: null,
    otp_option: null,
    pass_code_verified: null,
    role_id: null,
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case POST_USER_LOGIN_REQUEST: {
      console.log(POST_USER_LOGIN_REQUEST);

      return {
        ...state,
        error: false,
        loading: true,
      };
    }
    case POST_USER_LOGIN_REQUEST_SUCCESS: {
      const { message, data } = action.payload;
      console.log(POST_USER_LOGIN_REQUEST_SUCCESS);
      AsyncStorage.setItem("user", JSON.stringify(data)).then((val=>console.log(val))).catch((err)=>console.log(err));
      return {
        ...state,
        loading: false,
        data,
        message,
      };
    }
    case POST_USER_LOGIN_REQUEST_FAILURE: {
      console.log(POST_USER_LOGIN_REQUEST_FAILURE);

      const { message } = action.payload;
      return {
        ...state,
        loading: false,
        error: true,
        message,
      };
    }
    case SET_LOGIN_USER: {
      const { user } = action;
      console.log(SET_LOGIN_USER);
      return {
        ...state,
        data:user        
      };
    }
    case RESET_LOGIN_STATE: {
      console.log(RESET_LOGIN_STATE);
      return {
        ...initialState        
      };
    }
    default:
      return state;
  }
};

export { reducer };
