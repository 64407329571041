export default {
  //--------common-----------//

  Continue: "استمر",
  next: "التالي",
  skip: "تخطي",
  SUBMIT: "ارسال",
  Confirm: "تأكيد",
  save_and_continue: "حفظ وستمرار",
  transfer_money: "تحويل نقود",
  save: "حفظ",
  scan_qr_code: "مسح رمز الاستجابة السريعة",
  select_source: "اختر مصدر",
  take_a_photo: "أخذ صورة",
  choose_a_photo_from_the_gallery: "اختيار صورة من معرض الصور",
  cancel: "إلغاء",
  Select_Printer: "حدد الطابعة",
  Sunmi_Printer: "طابعة سونمي",
  Android_Printer: "ذكري المظهر طابعة",
  //--------Dashboard-------//
  qr_pay: "الدفع باستخدام QR",
  transactions: "المعاملات",
  received_from: "تم الاستلام من",
  sended_to: "أرسل إلى",
  transaction_detail: "تفاصيل المعاملة",
  transaction_amount: "قيمة المعاملة",
  transaction_from: "معاملة من",
  transaction_to: "معاملة لـ",
  transaction_number: "رقم العملية:",
  transactions_status: "المعاملات_الحالة",
  transactions_created: "المعاملات_المنشأة",
  Home: "الصفحة الرئيسية",
  category: "فئة",
  document: "وثيقة",
  Activity: "نشاط",
  Profile: "البروفايل",
  my_account: "حسابي",
  //--------Services-------//
  services: "الخدمات",
  essentials: "الضروريات",
  service_vouchers: "قسائم الخدمة",
  Vouchers: "القسائم",
  //--------Account-------//
  account: "حسابات",
  documents_and_reports: "الوثائق والتقارير",
  manage_notifications: "إدارة الإخطارات",
  invite_friends: "ادعو أصدقاء",
  personal_info: "معلومات شخصية",
  security_and_privacy: "الأمن والخصوصية",
  subscription: "الاشتراك",
  contact_to_support: "اتصل للدعم",
  rate_us: "قيمنا",
  faqs: "أسئلة وأجوبة",
  Logout: "تسجيل خروج",
  are_you_sure_you_want_to_logout: "هل أنت متأكد من رغبتك في الخروج؟",
  tizdan_customer: "زبون تيزدان",
  transactions_history: "تاريخ المعاملات",

  //--------HomeScreen-------//

  GetStarted: "ابدأ",
  signin: "تسجيل الدخول",

  //--------PhoneNumber-------//
  EnterPhoneNumber: "أدخل رقم هاتفك النقال",
  text_you_code: "حتى نتمكن من إرسال رمز المصادقة إليك في رسالة نصية",
  enter_phone_number: "أدخل رقم هاتفك النقال",
  _9xx_xxx_xxxx_phone_number: "9XX-XXX-XXXX",

  //--------VerficationCode-------//

  ResendCode: "أعد إرسال الرمز",
  EnterCode: "ادخل الرمز",
  WeSentSMS: "لقد أرسلنا إليك رسالة نصية قصيرة تحتوي على رمز التحقق إلى",
  PleaseEnterCode: "الرجاء إدخال رمز التحقق الخاص بك",

  //--------Name-------//

  enter_name: "أدخل اسمك",
  FirstName: "الاسم الأول",
  LastName: "اللقب",

  //--------Email-------//

  EnterEmail: "أدخل عنوان البريد الالكتروني",
  EmailAddress: "عنوان البريد الالكترونى",
  please_enter_valid_emil: "الرجاء إدخال إيميل صالح",

  //--------Password-------//
  enter_password: "ادخل رقمك السري",
  password: "كلمة المرور",

  //--------Gender-------//

  ChooseGender: "اختر الجنس",
  Male: "ذكر",
  Female: "أنثى",
  Other: "أخرى",

  //--------Nationality-------//

  YourNationality: "ما هي جنسيتك",
  Nationality: "الجنسية",

  //--------Residence-------//
  YourResidence: "اختر بلد الإقامة",
  CountryOfResidence: "مكان الإقامة",

  //--------PinCode-------//
  verify_pinCode: "تحقق من دبوس - الرمز",
  PinCode: "إنشاء رمز دبوس",
  update_security_pin: "تحديث دبوس الأمان",
  new_security_pin_cap: "دبوس أمان جديد",
  new_security_pin: "دبوس أمان جديد",
  confirm_your_pin: "قم بتأكيد رقم التعريف الشخصي الخاص بك",
  OTP: "رقم سري مبدئي لمرة واحدة",
  New_Password: "كلمة مرور جديدة",
  Confirm_Password: "تأكيد كلمة المرور",
  CheckOTP:
    "تحقق من الرقم السري على رقم هاتفك النقال المسجل أو البريد الإلكتروني المسجل.",
  PinChanged: "تم تحديث دبوس الأمان بنجاح",
  Please_enter_otp: "الرجاء إدخال رقم سري مبدئي لمرة واحدة",
  authentication_failure: "فشل المصادقة",
  UseFingerPrint: "استخدام بصمة الأصبع",
  please_enter_four_digit: "الرجاء إدخال رمز مكون من أربعة أرقام",
  Please_enter_match_password: "الرجاء إدخال كلمة مرور مطابقة",

  //--------Signin-------//
  welcome_back: "مرحبًا بعودتك",
  forgot_password: "هل نسيت كلمة السر",
  //--------Forget Password-------//
  reset_password: "إعادة تعيين كلمة المرور",

  //--------Request-------//

  requests: "الطلبات",

  //--------AddCard-------//

  add_card: "أضف بطاقة",
  Card_number_st: "رقم البطاقة ",
  ExpireDate: " تاريخ انتهاء الصلاحية",
  card_holder_name: " إسم صاحب البطاقة",
  CVV: " قيمة التحقق من الكرت",
  add_into_wallet: " أضف إلى المحفظة",
  currency_: " العملة",
  USD: "دولار أمريكي",
  Amount: " القيمة",
  SaveMyCard: "حفظ بطاقتي للاستخدام في المستقبل",

  //--------Bank Top Up-------//
  bank_top_up: "رصيد البنك",
  step_1: "الخطوة 1",
  MakeDeposit: "قم بالإيداع في مصرفنا أدناه في أحد البنوك المفضلة لديك",
  Meezan_Bank_Limited: "بنك ميزان المحدود",
  account_name: "أسم الحساب",
  Meezan_Bank: "بنك ميزان",
  AccountNumber: "رقم حساب",
  Dubai_Islamic_Bank: "بنك دبي الإسلامي",
  Tizdan_Bank: "بنك تزدان",
  step_2: "2 الخطوة",
  Add_InTo_Wallet: "إسم صاحب البطاقة",
  amount: "الكمية",
  method: "الطريقة",
  deposite_date: "تاريخ الإيداع",
  upload: "تحميل",
  deposit_slip_no: "رقم القسيمة",
  //--------Top Up Money-------//
  top_up_money: "قم بتعبئة الأموال",
  Add_Via_Credit_Debit_Card: "أضف عن طريق بطاقة الائتمان / الخصم",
  wallet_to_wallet: "من المحفظة إلى المحفظة",

  //--------MDP Cards-------//
  mdp_cards: "م د ص البطاقات",
  mdp_issuance_text:
    "يتطلب منك البطاقات م د ص إكمال ملف تعريف اعرف زبونك الخاص بك أولاً بدلاً من طلب بطاقات م د ص",
  mdp_customer: "عميل م د ص",
  second_name: "الاسم الثاني",
  place_of_birth: "مكان الميلاد",
  title: "العنوان",
  preferred_language: "اللغة المفضلة",
  house: "منزل",
  credit_debit_card: "بطاقة الائتمان/ الخصم",
  account_id: "معرف الحساب",
  Please_enter_your_second_name: "الرجاء إدخال اسمك الثاني",
  Please_enter_place_of_birth: "الرجاء إدخال مكان الميلاد",
  Please_enter_your_house: "من فضلك ادخل منزلك",
  Please_enter_your_id_number: "الرجاء إدخال رقم الهوية الخاص بك",

  //--------Wallet To Wallet-------//
  available_wallets: "المحافظ المتاحة",
  you_send: "انت ارسل",
  you_receive: "انت تستقبل",

  //--------New-------//
  exchange_money: "الصرافة",
  kyc: "اعرف زبونك",
  cbl: "ج ب ل",
  letter_of_credit: "خطاب اعتماد",

  //--------Letter of credit Step1-------//
  company_details: "تفاصيل الشركة",
  c_name: " اسم الشركة",
  company_address: " عنوان الشركة",
  country_st: " بلد",
  city: " مدينة",
  bank_details: "",

  //--------Letter of credit Step2-------//
  financial_information: "معلومات مالية",
  currency_to: "عملة",
  lC_type: "نوع خطاب الاعتماد",
  confirmed: "مؤكد",
  performa_invoice_date: "تاريخ الفاتورة المبدئي",
  performa_invoice_number: "رقم الفاتورة المبدئي",

  //--------Letter of credit Step3-------//
  products_services_details: "تفاصيل المنتجات / ",
  fill_in_the_details: "املأ تفاصيل المنتجات / الخدمات التي تشتريها",

  //--------Letter of credit Step4-------//
  shipment_details: "تفاصيل الشحنة",
  kindly_provide_shipment_details: "يرجى تقديم تفاصيل الشحن والتسليم التالية",
  delivery_date: "تاريخ التسليم",
  ship_by: "السفينة عن طريق",
  partial_shipment: "شحنة جزئية",
  transshipment: "إعادة الشحن",
  date_of_shipment: "تاريخ الشحنة",
  latest_date_of_presentation: "آخر موعد للعرض",
  collect_your_documents: "اجمع مستنداتك",
  authorized_to_pick_or_deliver: "مخول بالاختيار أو التسليم",

  //--------Letter of credit Step5-------//
  supporting_documents: "الوثائق الداعمة",
  certificate_of_origin: "شهادة المنشأ",
  reference_number: "رقم المرجع ",
  date_of_issue_st: "تاريخ المسألة ",
  expiry_date_st: "تاريخ انتهاء الصلاحية ",

  perfoma_invoice: "الفاتورة الأولية",
  commercial_invoice: "فاتورة تجارية",
  packing_list: "قائمة التعبئة",
  authorized_person_ID: "هوية الشخص المرخص له",

  //--------KYC step1-------//
  provide_the_personal_information: "قدم المعلومات الشخصية",
  father_name_st: "اسم الوالد ",
  mother_name_st: "اسم الأم ",
  gender_st: "الجنس ",
  date_of_birth_st: "تاريخ الميلاد ",
  marital_status: "الحالة الزوجية",
  nationality: "جنسية",
  email: "البريد الالكتروني",
  secondary_contact_number: "رقم الاتصال الثاني",

  //--------KYC Step2-------//
  provide_address_detail: "تقديم تفاصيل العنوان",
  address_st: "العنوان ",
  street_st: "الشارع ",
  district_st: "المنطقة ",

  //--------KYC Step3-------//
  provide_financial_info: "قم بتوفير المعلومات المالية",
  business_Emp_name: "اسم العمل / صاحب العمل ",
  role_st: "الوظيفة ",
  contact_no_st: "رقم الاتصال ",
  average_monthly_income_st: "متوسط الدخل الشهري ",
  years_of_experience_st: " عدد سنوات الخبرة في عملك الحالي؟",

  //--------KYC Step4-------//
  attach_the_documents: "إرفاق المستندات التالية",
  national_id_passport: "الرقم الوطني/ جواز السفر",
  id_number_st: "رقم التعريف الشخصي ",
  place_of_issue: "مكان الإصدار",
  national_number: "الرقم الوطني ",
  proof_of_funds: "اثبات التمويل ",
  Would_apply_for_CBL_Number: "هل ترغب في التقدم بطلب للحصول على رقم ج ب ل؟",
  no_later: "لا لاحقا",
  no_later: "لا لاحقا",
  //--------CBL Request-------//
  documents: "المستندات",
  kindly_attach_supporting: "يرجى إرفاق أي مستندات داعمة (إن وجدت)",

  //--------My Request-------//
  completed: "مكتمل",
  cencelled: "ألغيت",
  pending: "في الانتظار",
  kyc_verification: "التحقق من اعرف زبونك",

  //--------CBL Request Details-------//
  request_category: "نوع الطلب",
  cbl_request: "طلب ج ب ل",
  request_id: "طلب رقم تعريف",
  date: "التاريخ",
  status: "الحالة",
  status_reason_col: "سبب الحالة:",
  documents_in_review: "المستندات تحت المراجعة",
  description: "الوصف",
  comments: "التعليقات",
  cbl_issuance_text:
    "الجهة المصدرة لـ CBL تتطلب إكمال ملفك الشخصي أولا ومن ثم يمكنك التقديم على CBL",
  would_complete_your_profile: "هل ترغب في اكمال ملفك الشخصي؟",
  Yes: "نعم",
  No: "لا",

  //--------KYC Request Details-------//
  personal_information: "المعلومات الشخصية",
  address_details: "تفاصيل العنوان",

  //--------KYC Personal Details-------//
  grand_father_name: "اسم الجد",
  secondary_number: "الرقم الثانوي",

  //--------KYC Address Details-------//
  address: "العنوان",
  street: "الشارع",

  //--------KYC Financial Details-------//
  Financial_information: "معلومات مالية",
  source_of_income_st: "مصدر الدخل ",
  buisness_employer_name: "اسم الشركة / صاحب العمل",
  official_contact_number: "رقم العمل",
  average_monthly_income: "متوسط الدخل الشهري",
  years_of_experience: "سنوات من الخبرة",
  role: "الوظيفة",

  //--------KYC Documents Details-------//
  id_number: "رقم التعريف الشخصي",
  date_of_issue: "تاريخ المسألة",
  expiry_date: "تاريخ انتهاء الصلاحية",
  detail: "التفاصيل",
  account_name: "اسم الحساب",
  account_number: "رقم الحساب",
  add_to_wallet: "الإضافة للمحفظة",
  //--------Transfer-------//
  transfer: "تحويل",
  find_your_recipient: "ابحث عن المتلقي الخاص بك",
  //--------User Account-------//
  account_number_st: "رقم حساب ",
  transfer_from_st: "تحويل من ",
  //--------Bank Transfer-------//
  bank_name_st: "اسم المصرف ",
  branch_st: "الفرع ",

  //--------Transaction Summary-------//
  transaction_summary: "ملخص العملية",
  sender_name: "اسم المرسل",
  receivers_name: "إسم المستلم",
  receivers_account_number: "رقم حساب المستلمين",
  TransactionID: "رقم العملية",
  fee: "الرسوم",
  Tax: "الضرائب",

  //--------Family Members-------//
  add_new: "اضف جديد",
  family_members: "أفراد العائلة",
  Family_Mem: "أفراد العائلة",
  total_balance: "إجمالي الرصيد",
  wallets: "محافظ",
  top_up: "شحن",
  topup: "شحن",
  with_draw: "سحب",
  family_member_details: "تفاصيل أفراد الأسرة",
  phone: "الهاتف",
  delete_member: "حذف العضو",
  national_id: "الرقم الوطني",
  delete_account: "حذف حساب",
  are_delete_this_account:
    "هل أنت متأكد من رغبتك في حذف هذا الحساب",
  //--------Edit Family Members-------//
  edit_family_member: "تحرير بيانات فرد من العائلة",
  provide_basic_information_family: "توفير المعلومات الأساسية لأفراد الأسرة",
  first_name_st: "الاسم الأول ",
  last_name_st: "اللقب ",
  national_id_st: "الرقم الوطني ",
  phone_number_st: "رقم الهاتف ",
  relation_st: "علاقة ",
  create_password_st: "انشاء كلمة مرور ",
  confirm_password_st: "تأكيد كلمة المرور ",

  //--------Add Family Members-------//
  add_family_member: "أضف فرد من العائلة",

  //--------Beneficiaries-------//
  beneficiaries: "المستفيدون",

  //--------Add Beneficiaries-------//
  add_beneficiaries: "إضافة مستفيد",
  account_holder_name_st: "اسم صاحب الحساب ",

  //--------Beneficiaries Detail-------//
  beneficiaries_details: "تفاصيل المستفيدين",
  phone_number: "رقم الهاتف",
  account_holder_name: "اسم صاحب الحساب",
  account_type: "نوع الحساب",
  enter_voucher_quantity: "أدخل كمية القسيمة هنا",
  //--------Gift Cards-------//
  gift_cards: "كروت هدايا",
  gift_card: "كوبونات",
  Purchased_Cards: "البطاقات المشتراة",
  gift_cards_details: "بطاقات الهدايا - التفاصيل",

  //--------Gift Detail-------//
  company_name: "اسم الشركة",
  transaction_id: "رقم المعاملة",
  serial_number: "رقم سري",
  voucher_code: "كود الايصال",
  instructions: "التعليمات",
  buy: "شراء",
  brand: "ماركة",
  //--------Purchased Card-------//
  day: "اليوم",
  week: "الأسبوع",
  month: "الشهر",
  year: "السنة",
  active: "نشط",
  finished: "تم الانتهاء من",
  buy_date: "تاريخ الشراء  ",
  //--------Service Voucher-------//
  service_voucher: "كروت اتصالات",
  voucher: "ايصال",
  Total_amount_of_selected: "المبلغ الإجمالي للإيصال المحدد هو",
  But_add_balance_in_your_wallet: "لكن ليس لديك رصيد كافٍ ، يرجى إضافة رصيد في محفظتك.",
  //--------Add Existing CBL-------//
  add_existing_cbl: "أضف CBL موجود حاليا",
  provide_existing_CBL_details: "قدم تفاصيل CBL الحالية",
  cbl_number: "رقم CBL",
  existing_CBL: "CBL الحالية",
  bank_name: "اسم البنك",
  branch: "الفرع",
  //--------My QR Code-------//
  my_qr_code: "رمز الاستجابة السريعة الخاص بي",
  this_is_your_QR_code:
    "هذا هو رمز الاستجابة السريعة الخاص بك. قدمه للآخرين للتواصل معك",
  add_phone_number: "أضف رقم الهاتف",
  enter_number_to_pay: "أدخل رقم الهاتف المحمول للدفع",
  //--------QR Pay-------//
  please_provide_the_detail_below: "من فضلك قم بتوفير البيانات المطلوبة أدناه",
  pay: "الدفع",
  account_no_st: " رقم الحساب",

  //--------Validation-------//
  You_can_only_wallets: "يمكنك فقط التبادل بين المحافظ الموجودة",
  Currency_is_same: "العملة هي نفسها",
  Please_enter_ID: "الرجاء إدخال الهوية",
  Please_select_date_of_issue: "الرجاء تحديد تاريخ الإصدار",
  Please_select_expiration_date: "الرجاء تحديد تاريخ انتهاء الصلاحية",
  Please_select_identification_document_type: "الرجاء تحديد نوع وثيقة الهوية",
  Please_enter_your_father_name: "الرجاء إدخال اسم والدك",
  Please_enter_your_mother_name: "الرجاء إدخال اسم والدتك",
  Please_enter_your_grand_father_name: "الرجاء إدخال اسم والدك الكبير",
  Please_select_DOB: "الرجاء تحديد تاريخ الميلاد",
  Please_select_Nationality: "الرجاء تحديد الجنسية",
  Please_enter_the_Secondary_Contact: "الرجاء إدخال جهة الاتصال الثانوية",
  Please_enter_address: "الرجاء إدخال العنوان",
  Please_Enter_the_Street_Number: "الرجاء إدخال رقم الشارع",
  Please_select_the_District: "الرجاء تحديد المنطقة",
  Please_Enter_Zip_Code: "الرجاء إدخال الرمز البريدي",
  Please_enter_Business_Employee_Name: "الرجاء إدخال اسم العمل / الموظف",
  Please_select_the_Role: "الرجاء تحديد الدور",
  Please_enter_official_no: "الرجاء إدخال الرقم الرسمي",
  Please_enter_the_Average_Monthly_Income: "الرجاء إدخال متوسط ​​الدخل الشهري",
  Please_Enter_your_current_Experience: "الرجاء إدخال تجربتك الحالية",
  Please_enter_amount: "الرجاء إدخال المبلغ",
  Please_enter_the_description: "الرجاء إدخال الوصف",
  Please_Enter_the_Phone_Number: "الرجاء إدخال رقم الهاتف",
  Please_attach_Deposit_Slip: "يرجى إرفاق قسيمة الإيداع",
  Type_comment: "اكتب تعليق",
  top_up_wallet: "محفظة",
  details: "تفاصيل",
  Find_your_family_member: "ابحث عن فرد من عائلتك",
  Please_enter_your_first_name: "الرجاء إدخال الاسم الأول",
  Please_enter_your_last_name: "الرجاء إدخال اسمك الأخير",
  Please_enter_id: "الرجاء إدخال الرقم القومي",
  Please_enter_the_phoneNumber: "الرجاء إدخال رقم الهاتف",
  Please_enter_password: "الرجاء إدخال كلمة المرور",
  Please_enter_confirm_password: "الرجاء إدخال تأكيد كلمة المرور",
  PhonNumber_does_not_match_any_account: "رقم الهاتف لا يتطابق مع أي حساب",
  Insufficient_balance_in_the_selected_wallet: "رصيد غير كاف في المحفظة المختارة",
  signup: "تسجيل",
  appname: "زبون تزدان",
  offer: "عرض",
  SELECTALANGUAGE: "اختيار اللغة",
  Earned: "تحصيل",
  Select: "اختيار",
  VIEWALLCARDS: "أعرض كل البطاقات",
  On: "تشغيل",
  Off: "ايقاف",
  currency: " د.ل ",
  DigitalPaymentSolution: "حلول دفع الكترونية",
  NEWUPDATE: "تحديث جديد",
  NOTNOW: "ليس الآن",
  string_first: "ارسال واستقبال مدفوعات",
  string_second: "بحث عن التجار والدفع لهم",
  string_third: "حجز تذاكر أحداث",
  string_forth: "شحن لحظي في أي وقت",
  content_first: "استخدم الدفع لارسال، واستقبال المدفوعات لعملياتك الالكترونية",
  content_second: 'الدفع باستخدام خاصية "المسح والدفع" خلال جزء من الثانية',
  content_third: "يمكنك حجز التذاكر للأحداث التي يعلن عنها التجار",
  content_forth: "يمكن إجراء عمليات آنية، ودفع فواتير في أي وقت",
  LOGIN: "تسجيل الدخول",
  REGISTER: "الاشتراك",
  MobileNumber: "رقم الهاتف المحمول",
  LoginDes: "قم بالدخول للاستمتاع بعروض رائعة وطرق دفع ميسرة",
  EnterValidNumber: "من فضلك أدخل رقم صحيح",

  SelectYourCountry: "اختر الدولة",
  ReferCode: "الرقم المرجعي",
  Password: "كلمة المرور",
  EnterFirstName: "من فضلك أدخل الاسم الأول",
  EnterLastName: "من فضلك أدخل اللقب",
  EnterValidEmail: "من فضلك أدخل بريد الكتروني فعال",
  EnterNumber: "من فضلك أدخل رقم",
  EnterCountry: "من فضلك اختر الدولة",
  EnterPassword: "من فضلك أدخل كلمة المرور",
  ResetPasswordText: "يجب أن تتكون كلمة المرور من 6 أحرف على الأقل.",
  UpdatePlayService: "من فضلك قم بتحديث خدمات متجر قوقل!!!",
  Ok: "تم",
  Cancel: "إلغاء",
  ForgotPassword: "نسيت كلمة المرور؟",
  EnterMobileNumber: "من فضلك أدخل رقم الهاتف المحمول",
  OTPsent: "تم ارسال كلمة السر المؤقتة على بريدك الإلكتروني ورقم هاتفك",
  LogoutForOtherDevice: "تسجيل الخروج من الجهاز الآخر",
  AlreadyYouLoggedIn:
    "قمت بتسجيل الدخول من جهاز آخر، لذلك قم بالضغط على موافق للاستمرار على هذا الجهاز",
  AreYouSureWantToExit: "هل أنت متأكد من رغبتك في الخروج",
  SCANQRCODE: "مسح رمز الاستجابة السريعة QR",
  FINDNEARBY: "البحث في الجوار",
  PAYNOW: "ادفع الآن",
  RECEIVE: "الاستلام",
  Yourbeneficiarylistisempty: "قائمة المستفيدين فارغة",
  SendMoney: "إرسال الأموال",
  PleaseEnterNewPin: "برجاء ادخال رقم تعريفي PIN جديد",
  PleaseEnterOTP: "برجاء ادخال الرقم السري المبدئي",
  PinChanged: "تم تغيير رمز الأمان بنجاح",
  CheckregesteredEmail:
    "تحقق من الرقم السري على رقم هاتفك النقال المسجل أو البريد الإلكتروني المسجل.",
  Youget: "أنت حصلت على",
  RewardPointsAfterPaymentSuccess: "نقاط المكافأة بعد الدفع بنجاح",
  ResetPassword: "إعادة تعيين كلمة المرور",
  ResetSecurityPin: "إعادة تعيين رمز الأمان",
  NewPassword: "كلمة مرور جديدة",
  NewSecurityPin: "رقم سري جديد",
  OldPassword: "كلمة المرور القديمة",
  OTP: "رقم سري مبدئي لمرة واحدة",
  CheckOTP:
    "تحقق من الرقم السري على رقم هاتفك النقال المسجل أو البريد الإلكتروني المسجل.",
  ENTERNEWPASSWORD: "من فضلك أدخل كلمة مرور جديدة",
  ENTEROTP: "برجاء ادخال الرقم السري المبدئي",
  ENTERVALIDOTP: "برجاء ادخال الرقم السري المبدئي الصحيح",
  TouchIDPasscode: "أدخل رقم التعريف الشخصي PIN",
  EnterPin: "يرجى ادخال الرقم السري",
  CreatePin: "انشاء رقم سري",
  SecureAccess: "سيؤمن هذا الرمز الوصول إلى",
  PersonalData: "بيانات شخصية",
  ForgotPin: "نسيت رمز الأمان؟",
  Reenter: "الرجاء إعادة إدخال رقم التعريف الشخصي الصحيح",
  ConfirmSecurityPin: "قم بتأكيد رقم رقمك السري",
  NewPin: "قم بإنشاء رقم رقم سري جديد",
  OldPin: "الرجاء إدخال الرقم السري القديم",
  OTPsentsuccessfully: "رقم الأمان الوحيد تم ارساله بنجاح",
  Delete: "حذف",
  OTPVerification: "التحقق من الرقم السري المبدئي لمرة واحدة",
  OTPBanner: "من فضلك الاسترخاء قليلا حتى نتحقق من رقم هاتفك",
  OtpToast:
    "أدخل الرقم السري أدناه في حال لم نستطع التواصل باستخدام الرسائل النصية",
  ResendCode: "اضغط لإعادة الارسال مرة أخرى",
  Home: "الصفحة الرئيسية",
  Nearby: "المحلات",
  Feeds: "التغذية",
  Events: "الأحداث",
  More: "أكثر",
  RPay: "تزدان",
  BalanceDetails: "نقاط R",
  ADDMONEY: "إضافة نقود",
  RecentTransaction: "العمليات الأخيرة",
  Send: "ارسال",
  Offers: "العروض",
  BILLPAYMENT: "مدفوعات الفواتير",
  TRANSACTIONS: "العمليات",
  REFERRAL: "الإحالات",
  REWARDS: "المكافئات",
  BillPayment: "مدفوعات الفواتير",
  ComingSoon: "قريبا....",
  Recharge: "إعادة الشحن",
  YouRecentRewardsWillAppearHere: "مكافآتك الأخيرة ستظهر هنا",
  RechargeBillPaymentsampmore: "إعادة الشحن، الفواتير، وغيرها",
  Earn200morepointstoredeemalenovaLaptop:
    "اربح 200 نقطة إضافية لربح جهاز لينوفو محمول",
  pleaseenteraamount: "من فضلك أدخل القيمة",
  pleaseenteravalidamount: "من فضلك أدخل قيمة صحيحة",
  Thisdeviceisnotsupported: "هذا الجهاز غير مدعم",
  Sessionexpiredpleaselogintocontinue:
    "صلاحية الجلسة انتهت، برجاء تسجيل الدخول مرة أخرى للمتابعة",
  DealsNearbyyou: "تعاملات في الجوار منك",
  Mywallet: "محفظتي",
  MyAccount: "ملفي الشخصي",
  MyAccountDes: "إدارة ملفك الشخصي هنا",
  MyWalletDes: "قم بشحن محفظتك باستخدام بطاقتك الائتمانية",
  Transaction: "تاريخ المعاملات",
  MyVoucherCode: "كود قسيمتي",
  TransactionDes: "معاملاتك القديمة السابقة في التطبيق ومع باقي التجار",
  MoneyRequest: "طلب نقود",
  MoneyRequestDes: "بامكانك طلب نقود من الزبائن الآخرين",
  MyVoucherCodeDes: "معلومات رمز ايصالك هنا",
  EventBooking: "سجل الحجز السابق",
  EventBookingDes: "سجل الحجز السابق على التطبيق وباقي التجار",
  Popcoins: "المكافآت",
  PopcoinsDes: "اربح عملات من التجار، واستفد منها",
  MyContacts: "المستفيدون",
  AddBeneficiary: "إضافة مستفيد",
  MyContactsDes: "أضف كل معلومات المستفيدين هنا",
  ReferAndEarn: "إحالة، وكسب",
  ReferAndEarnDes: "أضف أصدقائك وأربح",
  Bussinesaccount: "قم بالتسجيل كحساب عمل",
  BussinesaccountDes: "تعاملاتك القديمة في التطبيق ومع باقي التجار",
  Rfid: "بطاقة ذكية",
  RfidDes: "بطاقتك الذكية",
  Settings: "الإعدادات",
  SettingsDes: "إعداداتك",
  LogoutDes: "الخروج من التطبيق بأمان",
  Remove: "إزالة",
  Alert: "تنبيه!!",
  AreYouSureWantToDelete: "هل أنت متأكد من رغبتك في الحذف؟",
  BookingDetails: "تفاصيل الحجز",
  BookingSuccess: "تم الحجز بنجاح",
  YourSavedBankAccountsAppearHere: "حسابات المصرفية المحفوظة ستظهر هنا",
  SearchCountryName: "أبحث باسم الدولة",
  NoCountryFound: "لم يتم العثور على الدولة",
  YourSavedCardsAppearHere: "بطاقاتك المحفوظة ستظهر هنا",
  AccountHolderName: "اسم صاحب الحساب",
  BankName: "اسم المصرف",
  AccountNumber: "رقم الحساب",
  Add: "اضافة",
  UseFingerPrint: "استخدام بصمة الأصبع",
  Shops: "المحلات",
  Seller: "العملاء",
  Search: "البحث بواسطة اسم المخزن",
  SearchFeed: "البحث بواسطة اسم المحل أو باسم العرض",
  Navigate: "استعرض",
  Reward: "المكافآت",
  OFFER: "عرض",
  ShopOffer: "عرض المحل",
  Nostoresfound: "لم يتم العثور على محلات",
  Nosellerssfound: "لم يتم العضور على بائعين",
  Searchbyagentsname: "البحث باسم العملاء",
  Actions: "الإجراءات",
  LanguageSelection: "اللغة",
  NotificationBlock: "حظر الإخطار",
  ChangePin: "تغيير رمز الأمان",
  Changepassword: "تغيير كلمة السر",
  English: "الإنجليزية",
  Arabic: "العربية",
  Portuguese: "البرتغالية",
  Hindi: "الهندية",
  Profile: "البروفايل",
  MyProfile: "ملفي الشخصي",
  ShowQR: "أظهر رمز الاستجابة السريعة QR لقبول الدفعات",
  QRcodeAbove: "رمز الاستجابة السريعة QR أعلاه هو تعريفك الشخصي",
  EditProfile: "تحرير الملف الشخصي",
  UpdateKYC: "تحديث اعرف زبونك",
  SavedCards: "البطاقات المخزنة",
  BankAccounts: "الحسابات المصرفية",
  Update: "تحديث",
  ShareReferral: "مشاركة الإحالة",
  ViewReferral: "عرض الإحالة",
  YourReferralCode: "رقم الإحالة الخاص بك",
  ShareLink: "مشاركة باستخدام رابط انترنت",
  Copy: "نسخ",
  FirstText: "دعوة أصدقائك إلى، وستتحصل",
  SecondText: "بعد أن يقوم أصدقاؤك بالتسجيل معنا",
  ThirdText: "سيتحصل صديقك أيضا على",
  ReferFriend: "الإشارة لصديق",
  BeneficiaryEmpty: "قائمة المستفيدين منك فارغة",
  TransactionHistory: "تاريخ المعاملات",
  All: "الكل",
  Paid: "تم الدفع",
  Received: "تم الاستلام",
  Topup: "شحن المحفظة",
  Refund: "إعادة شحن",
  BonusCredited: "اعتماد المكافأة",
  Creditwallet: "الائتمان للمحفظة",
  AmountTransferredTo: "القيمة المحولة إلى",
  Failed: "فشل",
  Success: "نجاح",
  RecentTransactionAppearHere: "آخر سجل لعملياتك سيظهر هنا",
  WithdrawnTransactions: "عمليات السحب",
  WithdrawnFee: "عمولة السحب",
  CreditedToBank: "الإئتمان للمصرف",
  Transactedon: "تم التعامل مع",
  OrderId: "رقم Txn",
  MyTransactions: "معاملاتي",
  YouHaveEarned: "لقد ربحت",
  PointsReduced: "النقاط المخفضة",
  Offer: "عرض",
  EarnedPoints: "النقاط التي تم ربحها",
  Congratulation: "تهانينا",
  Need: "الاحتياج",
  MorePointsFor: "نقاط أكثر لغرض",
  off: "إيقاف",
  YouEligibleFor: "أنت غير مفوض لغرض",
  OffOnYouNextPurchase: "موقف لعملية الشراء التالية",
  Points: "النقاط",
  Entermobilenumbertopay: "أدخل رقم الهاتف للدفع",
  OR: "أو",
  Entermobilenumbertorequest: "أدخل رقم الهاتف للطلب",
  ConfirmDetails: "أكد البيانات لغرض الاستمرار",
  Requestnow: "أطلب الآن",
  ServiceFee: "رسوم الخدمة",
  PopPayFee: "التعريفة",
  TotalPayable: "القيمة الأجمالية واجبة الدفع",
  Tip: "ملاحظات",
  RewardOffer: "عرض المكافأة",
  Total: "الإجمالي",
  TransactionDetail: "تفاصيل الحركة",
  RefundAmount: "قيمة الرتجيع",
  RefundType: "نوع الترجيع",
  RefundDate: "تاريخ الترجيع",
  Name: "الاسم",
  PayNow: "ادفع الآن",
  Submit: "ارسال",
  Family: "الأسرة",
  Business: "العمل",
  Totalavailablecoins: "المكافآت الإجمالية المتاحة",
  PopCoinHistory: "المكافآت السابقة",
  SENDREWARDS: "ارسال مكافآت",
  add_via_credit_debit: "أضف باستخدام بطاقة ائتمانية/ بطاقة خصم",
  bank_top_up: "رصيد البنك",
  wallet_to_wallet: "محفظة إلى محفظة",
  PaidSuccess: "تم الدفع بنجاح",
  For: "لأجل",
  MyWallet: "محفظتي",
  Withdrawn: "سحب",
  Availablebalance: "الرصيد المتاح",
  AddMoney: "إضافة نقود",
  Withdraw: "سحب",
  EnterAmount: "أدخل القيمة",
  Creditdebit: "ائتمان/ خصم",
  SaveCards: "احفظ البطاقات",
  CardNumber: "رقم البطاقة",
  mmyy: "الشهر/ السنة",
  Savemycard: "احفظ بطاقتي لغرض استخدامها مستقبلا",
  EnteredAmount: "القيمة المدخلة",
  CreditedtoBank: "ائتمان للمصرف",
  Withdrawfee: "عمولة السحب",
  SelectBankAccount: "اختر رقم الحساب المصرفي",
  AddBankAccount: "إضافة رقم حساب مصرفي جديد",
  ProceedToPay: "الاستمرار للدفع",
  Tappay: "اضغط لغرض اختيار البطاقة المستخدمة في الدفع",
  Westoreyourcard: "تم حفظ بيانات بطاقتك في خادم نظام الدفع",
  Change: "تغيير",
  Notallowed: "غير مسموح",
  PleaseEnterAmount: "من فضلك أدخل القيمة",
  PleaseEnterValidAmount: "من فضلك أدخل قيمة صحيحة",
  PleaseAddBankAccount: "من فضلك أضف رقم حساب مصرفي جديد",
  PleaseEnterMinimumAmount: "من فضلك أضف القيمة الدنيا",

  PaidSuccessfully: "تم الدفع بنجاح",
  UnauthorizedAccess: "دخول غير مخول",
  PleaseEnterValidCardDetails: "من فضلك أدخل بيانات كرت صحيحة ونشطة",
  AboutEvent: "حول الحدث",
  Organizers: "المنظمون",
  TermsCond: "الشروط والأحكام",
  BOOK: "حجز",
  YourRecentEventsAppearHere: "آخر سجل لعملياتك سيظهر هنا",
  CurrentlyEventList: "حاليا لايوجد أحداث في القائمة",
  EventDescription: "وصف الحدث",
  BusinessName: "اسم الشركة",
  ReviewBooking: "مراجعة الحجز",
  TicketDetails: "تفاصيل التذكرة",
  NoTickets: "لايوجد تذاكر",
  DateTime: "التاريخ والوقت",
  Subtotal: "الإجمالي الفرعي",
  Servicetax: "ضريبة الخدمات",
  ConfirmationMessage: "رسالة التأكيد سترسل إلى",
  YourRecentTransactionWillAppearHere: "عملياتك الأخيرة ستظهر هنا",
  RequestReceivedFrom: "تم استلام الطلب من",
  RequestSentTo: "تم ارسال الطلب إلى",
  RejectRequest: "أرفض الطلب",
  SENDMONEY: "إرسال الأموال",
  FROM: "من",
  EventName: "اسم الحدث",
  TicketBookedSuccessfully: "تم حجز التذكرة بنجاح",
  Venu: "المكان",
  Date: "التاريخ",
  TicketType: "نوع التذكرة",
  Time: "التوقيت",
  Price: "السعر",
  BookingID: "رقم الحجز",
  VERIFIED: "مؤكد",
  SelectDate: "اختر التاريخ",
  SelectTime: "اختر التوقيت",
  EnterOldPassword: "من فضلك أدخل الرقم السري القديم",
  EnterNewPassword: "من فضلك أدخل الرقم السري الجديد",
  NoEventfound: "لم يتم العثور على أي حدث",
  ok: "تم",
  no_camera_permission:
    "لايمكن تشغيل هذا التطبيق لعدم الوصول إلى الكاميرا. سيتم الخروج من التطبيق الآن",
  low_storage_error: "لايمكن تحميل متعرف الوجه لعدم وجود مساحة تخزينية كافية",
  BookingHistory: "الحجوزات السابقة",
  UpComingEvents: "الأحداث القادمة",
  CompletedEvents: "الأحداث المكتملة",
  Onwards: "القادمة",
  PleaseEnterCardNumber: "من فضلك أدخل رقم الكرت",
  PleaseEnterexpiryMonth: "من فضلك أدخل شهر انتهاء الصلاحية",
  PleaseEnterCvvnumber: "من فضلك أدخل رقم CVV",
  PleaseEnterValidCvvnumber: "من فضلك أدخل رقم CVV صحيح",
  Appblock: "حالة التطبيق",
  Apply: "التنفيذ",
  Share: "مشاركة",
  SharePost: "شارك هذا المنشور في وسائل التواصل الإجتماعي",
  HidePost: "إخفاء هذا المنشور",
  IDontWantPost: "لا أريد أن أرى هذا المنشور في الصفحة",
  Unfollowtheshop: "إلغاء متابعة التاجر",
  StopSeeingFeedsShop: "التوقف عن مشاهدة تحديثات هذا السوق",
  History: "التاريخ",
  RECENTPAYMENT: "المدفوعات القريبة",
  NoInternetConnection: "لايوجد اتصال بالانترنت",
  Locationisdisabled: "الموقع مخفي",
  LocationisdisabledDEC: "خدمة الموقع غير مفعلة، برجاء تفعيلها",
  Permissionnecessary: "الإذن ضروري",
  ExternalStoragePermissionNecessary: "الإذن للوصول للتخزين الخارجي ضروري",
  CameraPermissionNecessary: "الإذن للوصول للكاميرا ضروري",
  Pleaseselectyouroperator: "من فضلك أختر المشغل الخاص بك",
  pleasechooseanothernumber: "من فضلك أختر رقما آخر",
  Prepaid: "دفع مسبق",
  Postpaid: "دفع لاحق",
  SelectOperator: "اختر المشغل",
  Insufficientwalletbalance: "رصيد المحفظة غير كافٍ",
  pleaseselectadate: "من فضلك اختر التاريخ",
  pleaseselectatime: "من فضلك اختر الوقت",
  InsufficientRewards: "مكافآت غير كافية",
  Noeventsfound: "لم يتم العثور على أحداث",
  MoneyloadtowalletSuccessfully: "تم تحميل القيمة المالية للمحفظة بنجاح",
  Version: "الإصدار",
  voucher_purchased: "كروت اتصالات تم شراؤها",

  buynow: "اشتري الآن",
  add_friend_via_google_facebook: "أضف صديقا عبر قوقل أو فيسبوك",
  EnterFatherName: "من فضلك أدخل اسم الأب",
  EnterGFatherName: "من فضلك أدخل اسم الجد",
  less_balance: "أنت لا تملك رصيد كافٍ!!!!",
  add_new_account: "أضف حساباً جديداً",
  type_of_relationship: "نوع العلاقة",
  enter_nationality: "من فضلك أدخل الجنسية",
  enter_national_number: "من فضلك أدخل الرقم الوطني",
  my_family: "عائلتي",
  nearby: "جار",
  my_cards: "بطاقاتي",
  request_money: "أطلب نقوداً",
  taxi: "تاكسي",
  shop: "تسوق",
  my_friends: "أصدقائي",
  offers: "العروض",
  location_is_disable: "تم حجب الموقع",
  Location_service_is_disabled_please_enable_location:
    "تم حجب الموقع، من فضلك اسمح بالوصول للموقع",
  sure_you_want_to_delete_account: "هل أنت متأكد من رغبتك في حذف البطاقة؟",
  recommended_items: "عناصر موصى بها",
  cs_cart: "عربة CS",
  add_to_wishlist: "أضف إلى قائمة الأمنيات",
  price_details: "تفاصيل الأسعار",
  continue_shopping: "استمر في التسوق",
  check_out: "الدفع",
  user_account: "حساب مستخدم",
  add_new_beneficiary: "أضف مستفيد جديد",
  or_via_other_services: "أو من خلال خدمات أخرى",
  recents_transtion: "المعاملة الأخيرة",
  see_all: "مشاهدة الكل",
  see_all_line: "مشاهدة الكل",
  no_recent_transtions: "لايوجد معاملات مؤخرا",
  family_users: "المستخدمون من الأسرة",
  saved_cards: "البطاقات المخزنة",
  linked_bank_accounts: "حسابات المصرف المرتبطة",
  change_mobile_number: "غير رقم الهاتف",
  change_pin_code: "تغيير الرقم الأمان",
  no_record_found: "لم يتم العثور على أي بيانات",
  rating_reviews: "المراجعات والتقييم",
  permission_msg:
    "من غير هذا السماح بالوصول لايمكن تشغيل التطبيق. من فضلك اذهب إلى صفحة الإعدادات وامنح هذا التطبيق السماح بالوصول",
  more: "أكثر",
  currencySymbol: "د.ل",
  purchase_online_vouchers: "شراء ايصالات عبر الانترنت",
  hidden_companies: "شركات الايصالات المخفية",
  hide_seller_message: "هل ترغب في إظهار بائع الايصالات هذا؟",
  hidesellermessage: "هل ترغب في اخفاء بائع الايصالات هذا؟",
  hidegiftsellermessage: "هل ترغب في اخفاء بائع الكوبونات هذا؟",
  unhide_seller_message: "هل ترغب في اظهار بائع الكوبونات هذا؟",
  unhide: "اظهار",
  cancel: "إلغاء",
  c_name: "اسم الشركة",
  transids: "رقم العملية:",
  search_here: "أبحث هنا",

  purchased_vouchers: "كروت اتصالات تم شراؤها",
  p_gift_cards_history: "كروت هدايا تم شراؤها",
  close: "مغلق",
  apply: "التنفيذ",
  service_providers: "مزودو الخدمات",
  start_date: "تاريخ البدء",
  end_date: "تاريخ الانتهاء",
  price: "السعر",
  topup_received: "تم استلام الشحن",
  topup_sent: "تم ارسال الشحن",
  hidden_gift_cards_companies: "شركات كوبونات مخفية",
  hidden_vouchers_companies: "شركات الايصالات المخفية",
  sur_name: "اللقب",
  father_middle_name: "اسم الأب",
  full_name: "الاسم بالكامل",
  sex: "الجنس",
  social_status: "الحالة الاجتماعية",
  number_of_family_members: "عدد أفراد العائلة",
  date_of_iisue: "تاريخ الإصدار",
  expiration_date: "تاريخ انتهاء الصلاحية",
  address_amp_contact_info: "معلومات الاتصال",
  save_amp_next: "حفظ، والتالي",

  an_identification_document: "وثيقة تعريف شخصي",
  id_card: "بطاقة تعريف",
  passport: "جواز السفر",
  registration_no: "رقم التسجيل",
  window: "أرملة",
  single: "مفرد",
  divorced: "مطلقة",
  married: "متزوج",
  region: "المنطقة",
  city: "المدينة",
  email: "الايميل",
  phone_no: "رقم الهاتف",
  current_employer_detail: "تفاصيل العمل الحالي",
  employer_name: "اسم الموظف",
  employer_phone_no: "رقم هاتف الموظف",
  job_title: "اسم العمل",
  employer_address: "عنوان الوظيفة",
  working_years: "عدد سنوات العمل",
  give_us_your_identification_document_detail:
    "برجاء اعطاء تفاصيل عن وثيقة التعريف الشخصي",
  review_comment: "مراجعة التعليق",
  place_order: "تقديم الطلب",
  linked_accounts: "الحسابات المرتبطة",
  add_new_link_account: "أضف حساب مرتبط جديد",
  clear: "مسح",
  account_balance: "رصيد الحساب",
  bank_account_number: "رقم الحساب المصرفي",
  reset: "إعادة تشغيل",
  share_code: "مشاركة الكود",
  whatsapp_not_installed: "لم يتم تحميل الواتساب",
  add_money: "إضافة نقود",
  saving_account: "حساب التوفير",
  proceed: "متابعة",
  enter_mobile_number_to_receive_payment: "أدخل رقم الهاتف لغرض استلام الدفعة",
  direct_transfer: "تحويل مباشر",
  current_balance: "الرصيد الحالي",
  see_all_transaction: "مشاهدة كل العمليات",
  withdraw_money: "سحب نقود",
  load_money: "تحميل نقود",
  payment: "الدفعة",
  enter_description: "أدخل الوصف",
  sent: "تم الارسال",
  make_new_request: "القيام بطلب آخر",
  approve: "تأكيد",
  decline: "تراجع",
  ask_date_of_return: "تاريخ الرجوع",
  your_recent_coupon_code_n_will_appear_here: "كود الكوبون الجديد سيظهر هنا",
  enter_account_number: "أدخل رقم الحساب",
  account: "الحساب",
  enter_nick_name: "أدخل اسم الشهرة",
  nick_name: "اسم الشهرة",
  create_account: "أنشيء حساب",
  add_linked_account: "أضف الحسابات المرتبطة",
  privacy_policies: "السياسات والخصوصية",
  account_settings: "إعدادات الحساب",
  contact_us: "الاتصال بنا",
  agree_terms_and_conditions: "الموافقة على الشروط والأحكام",
  father_s_name: "اسم الأب",
  mother_s_name: "اسم الأم",
  financial: "المالية",
  document: "وثيقة",

  job: "العمل",
  please_attach_following_documents: "من فضلك قم بارفاق المستندات التالية",
  edit: "تحرير",
  user_no: "رقم المستخدم",
  user_detail: "تفاصيل المستخدم",
  update_contact_number: "تحديث رقم الهاتف",
  change_pin: "تغيير الرقم الأمان",
  kyc_information: "معلومات اعرف زبونك",
  cbl_information: "معلومات CBL",
  my_documents: "مستنداتي",
  preferred_currency: "العملة المفضلة",
  omar: "OMAR",
  faq: "الأسئلة المتكررة",
  complete_profile: "استكمال الملف الشخصي",
  filter_by: "الفلترة باستخدام",
  received: "تم الاستلام",
  proceed_to_pay: "الاستمرار للدفع",
  pay_to: "الدفع لـ",
  selected_address: "العنوان المختار",
  mark_as_delivery_address: "تعيين كعنوان استلام",
  save_address: "حفظ العنوان",
  name: "الاسم",
  bank_account: "رقم الحساب المصرفي",
  credit_card: "البطاقة الأئتمانية",
  qr_code: "كود الاستجابة السريعة QR",
  nfc: "NFC",
  add_new_address: "إضافة عنوان جديد",
  sort_by: "الفرز باستخدام",
  type: "النوع",
  expenses: "المصروفات",
  by_day: "باليوم",
  last_3_month: "الأشهر الثلاثة الأخيرة",
  under_development_nchart: "مخطط تحت التطوير",
  total_expenses: "إجمالي المصروفات",
  food_amp_dinning: "الطعام",
  rent_household_bills: "الإيجار/ فواتير صاحب السكن",
  shopping: "التسوق",
  travel: "السفر",
  add_using_qr_code: "الإضافة باستخدام كود الاستجابة السريعة QR",
  add_using_information: "افضافة باستخدام المعلومات",
  select_address: "اختيار العنوان",
  enter_your_number_to_update: "أدخل رقمك لغرض التحديث",
  choose_payment_method: "اختيار طريقة الدفع",
  sub_total_col: "المجموع الفرعي",
  lyd_13: "13 دينار ليبي",
  taxes_col: "الضرائب",
  total: "الإجمالي",
  payment_through_wallet: "الدفع من خلال المحفظة",
  cash_on_delivery: "الدفع عند الاستلام",
  change_mode_to_online: "تغيير الوضع إلى متصل",
  change_mode_to_offline: "تغيير الوضع إلى غير متصل",
  events_by_tizdan_customer: "أحداث عن طريق زبائن تزدان",
  name_surname: "الاسم واللقب",
  block_report: "حظر/ تقرير",
  gift_card_type: "نوع الكوبون",
  amazon_pay_email_gift_card: "ايميل أمازون لدفع الكوبونات",
  message: "الرسالة",
  now: "الآن",
  price_of_product_lyd_10: "ثمن المنتج: 10 دينار ليبي",
  xs: "xs",
  xl: "xl",
  product_quantity: "كمية المنتج",
  product_description: "وصف المنتج",
  add_to_cart: "الإضافة للعربة",
  title_of_item: "اسم البضاعة",
  size: "الحجم",
  color: "اللون",
  varients: "الهدايا",
  product_detail: "تفاصيل المنتج",
  rate_product: "قيم المنتج",
  q: "Q",
  are_you_the_registered_head_of_family: "هل أنت رب العائلة؟",
  yes: "نعم",
  edit_answer: "تحرير الإجابة",
  would_you_like_to_your_family_grant_to_be_processed_through_tizdan:
    "هل ترغب في استلام منحة أرباب الأسر عن طريق تزدان؟",
  would_you_like_to_apply_for_a_individual_cbl:
    "هل ترغب في التقديم على CBL للأشخاص؟",
  expired: "منتهي الصلاحية",
  beam_data: "بيانات Beam",
  received_data: "البيانات المستلمة",
  current_balance_lyd_0: "الرصيد الحالي: 0 دينار ليبي",
  view_purchased_voucher_codes: "عرض أكواد الايصالات المشتراة",
  view_my_offline_vouchers: "استعراض ايصالاتي دون الاتصال",
  amount_st: "الكمية",
  request_to_st: "الطلب لغرض",
  date_of_return: "تاريخ العودة",
  member_s_of_android: "أعضاء الأندرويد",
  send_money: "إرسال الأموال",
  pay_bill: "دفع فاتورة",
  split_bill: "تجزئة فاتورة",
  sms: "رسالة نصية",
  total_payable: "القيمة الأجمالية واجبة الدفع",
  all_payment_options: "جميع خيارات الدفع",
  tizdan_wallet: "محفظة تزدان",
  debit_card: "بطاقة ائتمانية",
  net_banking: "صافي مصرفي",
  contiune: "الاستمرار",
  cascade_store: "متجر cascade",
  cart: "العربة",
  wishlist: "أمنيات",
  tizdan: "تزدان",
  ticket_name: "اسم التذكرة",
  science_olympiad_events_are_balanced_among_the_three_broad_areas_of_the_science_standards:
    "أحداث أولومبياد التعليمي تم تحديدها ضمن المعايير الثلاثة للعلوم",
  no_image: "لاتوجد صورة",
  place_name: "اسم المكان",
  back_to_home: "العودة للصفحة الرئيسية",
  enter_your_smart_card_number_to_activate:
    "أدخل رقم بطاقتك الذكية لغرض التفعيل",
  why_i_need_s_smart_card: "لماذا أحتاج لبطاقة ذكية؟",
  enter_card_number: "أدخل رقم البطاقة",
  activate_now: "التفعيل الآن",
  your_card_number: "رقم بطاقتك",
  activated_on: "مفعلة في",
  expires_on: "تنتهي صلاحيتها في",
  top_offers: "العروض الأولى",
  location: "الموقع",
  add_location: "اضافة موقع",
  shop_name: "اسم المتجر",
  events: "الأحداث",
  roadster_women_sneakers: "أحذية رياضية Roadster",
  it_s_important_that_a_:
    "من المهم أن يكون كاتب وصف المنتج صادقًا وشفافًا عند وصفه المنتج. القول بأن المنتج مجاني في حين أنه في الحقيقة ليس كذلك يعتبر أمرا خادعًا. إن القول بأن منتجك ذو جودة عالية في حين أنه لديك عدد لا يحصى من الشكاوى حول الجودة لن يخدع الناس لإعجابهم بمنتجك.",
  delivered: "تم الاستلام",
  order_id: "رقم الطلبية",
  invoice_no_dot: "رقم الفاتورة",
  order_date: "تاريخ الطلب",
  delivered_date: "تاريخ الاستلام",
  shipping_address: "عنوان الشحن",
  contact_to_our_support_dot: "الاتصال بالدعم في تزدان",
  need_help: "تحتاج لمساعدة؟",
  give_a_review: "القيام بمراجعة",
  delivery_fee: "رسوم الاستلام",
  tax_amp_charges: "الرسوم والضرائب",
  discount: "التخفيضات",
  sub_total: "الاجمالي الفرعي",
  view: "عرض",
  rate: "تقييم",
  save_rating: "حفظ التقييم",
  select_city: "اختيار المدينة",
  same_as_shipping_info: "نفس عنوان الشحن",
  add_shipping_info: "إضافة معلومات الشحن",
  add_notes: "إضافة ملاحظات",
  add_change_address: "إضافة/ تغيير العنوان",
  add_billing_info: "إضافة بيانات الفوترة",
  coupon_code: "كود الكوبون",
  print_voucher: "طباعة الايصال",
  thank_you_for_your_purchase: "شكرا لقيامك بالشراء",
  thank_you: "شكرا لك",
  categories: "التصنيفات",
  featured_products: "منتجات مميزة",
  deals_for_you: "صفقات لك",
  add_fund: "إضافة تمويل",
  balance: "الرصيد",
  receiver_number: "رقم المستقبل",
  number: "العدد",
  transaction_fee: "رسوم المعاملة",
  money_sent_successfully: "تم ارسال المال بنجاح",
  lyd: "د.ل",
  complete: "اكتملت",
  have_an_issue: "تواجه أي مشكلة؟",
  contact_to_support_on_phone_call_n_218915004222:
    "الاتصال بالدعم الفني على الرقم 218915004222+",
  tizdan_customer_users: "زبائن مستخدمو تزدان",
  no_tizdan_customer_user_found: "لم يتم ايجاد زبون لتزدان بهذا الاسم",
  no_family_user_found: "لم يتم ايجاد عائلة",
  new_trip: "رحلة جديدة",
  my_bookings: "حجوزاتي",
  flight: "طيران",
  bus: "حافلة",
  car: "سيارة",
  one_way: "في اتجاه واحد",
  round_trip: "ذهاب وعودة",
  from: "من",
  to: "إلى",
  when: "متى",
  passengers: "المسافرون",
  economy: "اقتصادية",
  _1_adult: "بالغ واحد",
  customer_s_personal_data: "بيانات شخصية للزبون",
  full_name_in_english_will_be_written_on_visa_card:
    "الاسم بالكامل باللغة الإنجليزية -سيكتب على بطاقة الفيزا",
  back: "العودة",
  current_employer: "الوظيفة الحالية",
  employer_info: "معلومات الوظيفة",
  for_your_purchase_n_nbelow_is_the_voucher_code_for_your_future_reference:
    "لغرض الشراء يوجد في الأسفل رقم الايصال لغرض الإشارة له مستقبلا",
  done: "تم",
  coming_soon: "قريبا",
  ttd: "TTD $",
  per_month_you_can_add_upto_n_ttd_20000_in_your_wallet_why:
    "يمكنك إضافة مبلغ حتى $20000 في الشهر في حساب محفظتك لماذا؟",
  visa: "فيزا",
  add_beneficiary_using_information: "إضافة بيانات المستفيد",
  eb: "EB",
  dth: "مباشرة إلى الصفحة الرئيسية",
  service: "الخدمة",
  service_number: "رقم الخدمة",
  oyopay_fee: "رسوم OYAPAY",
  ticket: "التذكرة",
  step_1_question_1: "المرحلة 1- السؤال 1",
  step_1_question_2: "المرحلة 1- السؤال 2",
  step_1_question_3: "المرحلة 1- السؤال 3",
  top: "الأعلى",
  full_sleeve_top: "قميص بكم كامل",
  merchant_name: "اسم المتجر:",
  selected_customer_didn_t_have_rpay_account:
    "المتجر الذي تم اختياره لايملك حساب RPAY",
  payment_details: "تفاصيل الدفعة",
  actual_cost: "التكلفة الحقيقية",
  choose_for_a_contact: "اختيار المتجر",
  drawing_competition: "منافسة الرسم",
  inter_city: "داخل المدينة #",
  _80_people_intrested: "شخص مهتم +80",
  complex: "معقد",
  seller_name: "اسم البائع",
  book_festival: "مهرجان الكتاب",
  are_you_looking_for_a_children_s_book_that_is_highly_entertaining:
    "هل تبحث كتاب للأطفال مسلي للغاية",
  paid_for_shop: "دفع للمحل",
  earned: "تحصيل",
  cancel_request: "الغاء الطلب",
  are_you_sure_to_cancel_the_request: "هل أنت متأكد من الغاء الطلبية؟",
  take_a_photo: "أخذ صورة",
  choose_a_photo_from_the_gallery: "اختيار صورة من معرض الصور",
  confirm_transfer: "تأكيد التحويل",
  please_confirm_transfer_details_and_amount:
    "من فضلك أكد قيمة وتفاصيل التحويل؟",
  receiver_phone_number: "رقم هاتف المستلم",
  new_update_available: "تحديثات جديدة متوفرة!!",
  update_now: "التحديث الآن!!",
  scratch_your_card: "شطب بطاقتك",
  are_you_sure_want_to_remove_sub_user:
    "هل أنت متأكد من رغبتك في حذف المستخدم الفرعي؟",
  paid_to_ola: "تم الدفع لــ OLA",
  user_name: "اسم المستخدم",
  store_name: "اسم المحل",
  it_s_important_that_a_product_description_:
    "من المهم أن يكون كاتب وصف المنتج صادقًا وشفافًا عند وصفه المنتج. القول بأن المنتج مجاني في حين أنه في الحقيقة ليس كذلك يعتبر أمرا خادعًا. إن القول بأن منتجك ذو جودة عالية في حين أنه لديك عدد لا يحصى من الشكاوى حول الجودة لن يخدع الناس لإعجابهم بمنتجك.",
  manikandan_mn: "Manikandan.MN",
  persad_food_stores_ltd: "محلات Persad الغذائية المحدودة",
  your_feed_list_is_empty: "قائمة التغذية فارغة",
  verifying_your_identity: "تأكيد هويتك",
  using_fingerprint: "استخدام بصمة الأصبع؟",
  redeem_now: "الاستبدال الآن",
  my_beneficiary: "المستفيدون",
  logout: "الخروج",
  customer_comments: "تعليقات المتجر",
  success: "بنجاح",
  refund_details: "تفاصيل إعادة التعبئة",
  refund_status: "حالة إعادة التعبئة",
  analyzer_report: "تقرير التحليل",
  orders: "الطلبيات",
  scan: "مسح",
  support: "الدعم",
  tizdan_customer_gift_and_user: "هدية عملاء تزدان والمستخدم",
  on_map: "على الخريطة",
  dob_col: "تاريخ الميلاد",
  phone_col: "رقم الهاتف",
  email_col: "الايميل:",
  history: "التاريخ",
  extra_fee: "رسوم إضافية",
  your_saved_card_will_appear_here: "بطاقتك المحفوظة ستظهر هنا",
  reedem_points: "استبدال النقاط",
  coupon: "الكوبون",
  foloosi_fee: "رسوم فلوسي",
  tip: "نصيحة",
  add_tips: "إضافة نصائح",
  you_get_50_reward_points_after_payment_success:
    "تحصل على 50 نقطة مكافأة بعد استكمال الدفع بنجاح",
  pay_for: "الدفع لــــ",
  aed: "AED",
  you_recent_transaction_n_will_appere_here: "آخر مدفوعاتك ستظهر هنا",
  category_name: "اسم الصنف",
  cart_total: "إجمالي العربة",
  cart_discount: "تخفيض العربة",
  order_total: "إجمالي الطلبية",
  delivery_charges: "رسوم الشحن",
  get_upto_200_cashback: "احصل على مسترجعات نقدية تصل إلى 200%",
  there_is_a_little_bit_of_twist_:
    "هناك بعض التغيير في هذا العرض. سوفر استرداد نقدي بنسبة 100 ٪ ولكن بالتقسيط",
  recharge: "إعادة الشحن",
  super_recharge: "إعادة شحن فائق",
  flash_sale_save_50_on_recharges_amp_bill_payments:
    "تخفيضات: وفر 50٪ على عمليات إعادة الشحن ودفع الفواتير",
  get_code_to_scratch_this: "الحصول على كود لإظهار هذا",
  code: "الكود",
  flash: "مفاجيء",
  promotion_details: "تفاصيل الإعلان",
  get_code: "الحصول على الكود",
  points_available: "النقاط المتوفرة",
  inter_city_startup: "داخل المدينة # الانطلاق",
  port_of_trinidad: "ميناء-ترينيداد",
  need_50_more_points_for_25_off: "تحتاج لــ 50 نقطة إضافية للحصول على حسم 25%",
  events_for_gather_for_marchants: "أحداث تجمع التجار",
  at_square_ground_california: "في ساحة كاليفورنيا",
  event_cinema: "حدث سينمائي",
  participants: "المشاركون",
  yesterday: "أمس",
  all_categories: "كل التصنيفات",
  change_amount: "تغيير القيمة",
  get_directions: "الحصول على الاتجاهات",
  class_: "الدرجة",
  recharge_processing: "عملية إعادة الشحن",
  card: "البطاقة",

  relation_to_account_holder: "العلاقة بصاحب الحساب",
  from_date: "من تاريخ",
  to_date: "إلى تاريخ",
  search: "البحث",
  type_phone_number: "طباعة رقم الهاتف",
  address_1: "العنوان 1",
  address_2: "العنوان 2",
  state: "الولاية",
  postal_code: "الرمز البريدي",
  country: "الدولة",
  enter_voucher_code: "ادخال رقم الايصال",
  enter_address: "ادخال العنوان",
  enter_your_first_name: "أدخل اسمك الأول",
  enter_father_middle_name: "أدخل اسم الوالد",
  enter_grandfather_name: "أدخل اسم الجد",
  enter_surname: "أدخل اللقب",
  enter_mother_name: "أدخل اسم الأم",
  enter_full_name_in_english: "أدخل الاسم كاملا باللغة الإنجليزية",
  current_employer_name: "اسم العمل الحالي",
  the_number_of_years_of_work_in_your_current_emplyoment:
    "عدد سنوات الخبرة في عملك الحالي",
  the_number_of_years_of_work_in_your_current_emplyoment_business:
    "عدد سنوات الخبرة في عملك الحالي",
  current_employer_address: "عنوان عملك الحالي",
  employer_phone_number: "رقم العمل",
  card_holder_name: "اسم حامل البطاقة",
  search_event_by_name_place: "البحث عن الحدث بالاسم، المكان",
  what_this_for: "ماهذا",
  search_by_name: "البحث بالاسم",
  business_employer_name: "اسم النشاط/ العمل",
  total_experience: "الخبرة الكلية",

  reason_for_cancel: "سبب الإلغاء",
  phone_no_dot: "رقم الهاتف",
  search_by_country_name_code: "البحث باسم الدولة، الكود",
  enter_4_digit_pin: "أدخل الرقم السري من 4 خانات",
  re_enter_4_digit_pin: "أعد ادخال الرقم السري من 4 خانات",
  please_enter_your_bank_account: "من فضلك أدخل اسم المصرف",
  please_enter_name: "من فضلك أدخل اسمك",
  please_enter_a_account_holder_name: "من فضلك أدخل اسم صاحب الحساب المصرفي",
  please_enter_a_account_number: "من فضلك أدخل رقم حسابك المصرفي",
  please_enter_first_name: "من فضلك أدخل اسمك الأول",
  password_should_be_at_least: "يجب أن تتكون كلمة المرور من 6 أحرف على الأقل",
  please_enter_valid_password: "من فضلك أدخل كلمة مرور فعالة",
  pin_code_mismatch: "الرقم السري غير مطابق",
  password_mismatch_reenter_password:
    "كلمة المرور غير مطابقة- من فضلك أعد ادخال كلمة المرور",
  please_enter_valid_referral_code: "من فضلك أدخل كود إحالة صحيح",
  please_enter_father_middle_name: "من فضلك أدخل اسم الأب",
  please_enter_grandfather_name: "من فضلك أدخل اسم الجد",
  please_enter_surname: "من فضلك أدخل اللقب",
  please_enter_mother_name: "من فضلك أدخل اسم الأم",
  please_enter_full_name: "من فضلك أدخل الاسم كاملا",
  please_select_gender: "من فضلك اختر الجنس",
  please_enter_id: "من فضلك أدخل رقم التعريف",
  please_enter_place_name_of_issuing_document:
    "الرجاء إدخال مكان وثيقة الإصدار",
  please_enter_issue_date: "من فضلك أدخل تاريخ الإصدار",
  please_expire_date: "من فضلك أدخل تاريخ انتهاء الصلاحية",
  please_select_martial_status: "الرجاء اختيار الحالة الإجتماعية",
  please_enter_family_members_count: "من فضلك أدخل عدد أفراد الأسرة",
  please_select_identificaion_document_type: "من فضلك اختر نوع وثيقة التعريف",
  please_select_dob: "من فضلك أختر تاريخ الميلاد",
  enter_relationship_type: "أدخل نوع العلاقة",
  please_enter_a_valid_number: "من فضلك أدخل رقم صحيح",
  please_select_date_of_return: "من فضلك اختر موعد العودة",
  please_enter_your_account_name: "من فضلك أدخل اسم حسابك",
  please_enter_mobile_number: "من فضلك أدخل رقم هاتفك المحمول",
  you_cannot_add_yourself: "لاتستطيع إضافة نفسك!",
  please_enter_beneficiary_name: "من فضلك أدخل اسم المستفيد",
  please_enter_your_relation_with_benificiary: "من فضلك أدخل علاقتك بالمستفيد",
  please_enter_valid_4_digit_pin: "من فضلك أدخل رقم سري من 4 خانات فعال",
  pin_mismatch: "عدم تطابق الرقم السري",
  please_enter_your_name: "من فضلك أدخل اسمك",
  you_cannot_add_from_yourself: "لاتستطيع إضافة نفسك!",
  old_password_is_wrong: "كلمة المرور القديمة غير صحيحة",
  please_make_any_changes: "من فضلك قم بأي تعديلات",
  permission_caneled_now_your_application:
    "تم إلغاء الإذن، والآن لا يمكن لتطبيقك الوصول إلى الكاميرا.",
  failed_to_read_picture_data: "فشل في قراءة بيانات الصورة!",
  profile_uploaded_successfully: "تم تحميل الملف الشخصي بنجاح",
  please_enter_a_valid_card_details: "من فضلك أدخل بيانات كرت صحيحة ونشطة",
  invalid_referral: "إحالة غير صالحة",
  please_enter_working_years: "من فضلك أدخل سنوات العمل",
  please_enter_job_title: "من فضلك أدخل اسم العمل",
  please_enter_address: "من فضلك أدخل العنوان",
  please_select_the_question_1: "من فضلك اختر السؤال 1",
  please_select_the_question_2: "من فضلك اختر السؤال 2",
  please_select_the_question_3: "من فضلك اختر السؤال 3",
  please_enter_the_first_name: "من فضلك اختر الاسم الأول",
  please_enter_the_father_middle_name: "من فضلك أدخل اسم الأب",
  please_enter_the_grandfather_name: "من فضلك أدخل اسم الجد",
  please_enter_the_surname: "من فضلك أدخل اللقب",
  please_enter_the_mother_name: "من فضلك أدخل اسم الأم",
  please_enter_the_full_name: "من فضلك أدخل الاسم كاملا",
  please_select_the_gender: "من فضلك اختر الجنس",
  please_select_the_social_status: "من فضلك اختر الحالة الإجتماعية",
  please_enter_the_family_members: "من فضلك أدخل عدد أفراد الأسرة",
  please_enter_the_identification_number: "من فضلك أدخل رقم التعريف",
  please_select_expiration_date: "من فضلك أدخل تاريخ انتهاء الصلاحية",
  please_enter_the_passport_number: "من فضلك أدخل رقم جواز السفر",
  please_enter_the_national_number: "من فضلك أدخل الرقم الوطني",
  please_enter_the_registration_number: "من فضلك أدخل رقم التسجيل",
  please_enter_the_place_of_issue: "من فضلك أدخل مكان الإصدار",
  please_select_date_of_issue: "من فضلك أدخل تاريخ الإصدار",
  please_enter_the_email_address: "من فضلك أدخل البريد الالكتروني",
  please_enter_the_valid_email_address: "من فضلك أدخل بريد الكتروني صحيح",
  please_enter_the_phone_number: "من فضلك أدخل رقم الهاتف",
  please_enter_valid_number: "من فضلك أدخل رقم هاتف صحيح",
  please_enter_the_address: "من فضلك أدخل العنوان",
  please_enter_the_employer_name: "من فضلك أدخل جهة العمل",
  please_enter_the_employer_phone_number: "من فضلك أدخل هاتف جهة العمل",
  please_enter_valid_phone_number: "من فضلك أدخل رقم هاتف صحيح",
  please_enter_the_employer_address: "من فضلك أدخل عنوان جهة العمل",
  please_enter_the_job_title: "من فضلك أدخل اسم الوظيفة",
  please_enter_the_employer_working_year: "من فضلك أدخل عدد سنوات الخبرة",
  please_select_the_date_of_issue: "من فضلك أختر تاريخ الإصدار",
  please_select_the_expiry_date: "من فضلك أدخل تاريخ انتهاء الصلاحية",
  expiry_date_must_be_after_the_issue:
    "تاريخ انتهاء الصلاحية يجب أن يكون بعد تاريخ الإصدار",
  expiry_date_must_be_after:
    "تاريخ انتهاء الصلاحية يجب أن يكون بعد تاريخ الإصدار",
  permission_canceled_now_your:
    "تم إلغاء الإذن، لا يمكن الآن لتطبيقك الوصول إلى الكاميرا.",
  item_added_to_wishlist: "تم إضافة عناصر إلى قائمة الأمنيات",
  added_to_wishlist: "تم إضافة لقائمة الأمنيات",
  item_removed_from_wishlist: "تم حذف عنصر من قائمة الأمنيات",
  remove_from_wishlist: "حذف من قائمة الأمنيات",
  would_you_like_to_apply_for_cbl_number: "هل ترغب في التقديم على رقم CBL",
  please_pick_the_date_of_birth: "من فضلك اختر تاريخ الميلاد",
  please_select_the_nationality: "من فضلك اخترت الجنسية",
  file_should_not_greater_than_5_mb: "لايجب أن يزيد حجم الملف عن 5 ميجابايت",
  please_enter_the_street_number: "من فضلك أدخل رقم الشارع",
  please_select_the_country: "من فضلك اختر الدولة",
  please_select_the_city: "من فضلك اختر المدينة",
  please_select_the_district: "من فضلك اختر المنطقة",
  please_enter_the_secondary_contact: "من فضلك أدخل شخص ثانٍ للاتصال",
  please_enter_the_region: "من فضلك أدخل المنطقة",
  please_select_the_source_of_icome: "من فضلك اختر مصدر الدخل",
  please_select_the_role: "من فضلك أدخل الوظيفة",
  please_enter_the_average_monthly_income: "من فضلك أدخل متوسط الدخل الشهري",
  please_select_the_currency: "من فضلك اختر العملة",
  please_enter_the_number_of_years_of_work:
    "من فضلك أدخل عدد سنوات الخبرة في عملك الحالي",
  file_added_successfully: "تم إضافة الملف بنجاح",

  please_enter_your_father_name: "من فضلك أدخل اسم الوالد",
  please_enter_your_grand_father_name: "من فضلك أدخل اسم الجد",
  please_enter_your_mother_name: "من فضلك أدخل اسم الأم",
  please_enter_business_name: "من فضلك أدخل اسم جهة العمل",
  please_enter_official_contact_no: "من فضلك أدخل رقم الاتصال الرسمي",
  please_enter_your_current_employee:
    "من فضلك أدخل عدد سنوات الخبرة في عملك الحالي",
  apply_cbl: "التقديم على CBL",
  go_to_cbl_requests: "إذهب إلى طلبات CBL",
  exiting_cbl: "الخروج من CBL",
  no_cbl_found: "لايوجد CBL",
  cbl_number_col: "رقم CBL:",
  bank_name_col: "اسم المصرف:",
  new_: "جديد",
  my_requests: "طلباتي",
  my_request: "طلباتي",
  request_new_cbl: "طلب CBL جديد",
  requests: "الطلبات",
  kindly_attach_any_supporting_documents_if_any:
    "من فضلك أرفق أي مستند داعمة (حال توفرها)",
  applied_on_col: "التقديم في:",
  cbl_request_no_col: "رقم طلب CBL:",
  n_a: "لايوجد",
  add_document: "إضافة وثيقة",
  insufficient_wallet_balance: "رصيد المحفظة غير كافي",
  please_enter_a_amount: "من فضلك أدخل القيمة",
  invalid_receiver_selected: "تم اختيار مستلم غير صحيح",
  please_select_social_status: "من فضلك اختر الحالة الإجتماعية",
  please_give_the_rate_to_product: "من فضلك قيم المنتج",
  no_linked_account_available: "لايوجد حساب مرتبط",
  please_enter_account_number: "من فضلك أدخل رقم الحساب/ رقم الهاتف",
  please_enter_nick_name: "من فضلك أدخل الاسم",
  please_enter_discription: "من فضلك أدخل الوصف",
  please_select_your_address: "من فضلك اختر عنوانك",
  please_allow_the_location_permissions_from_setting:
    "من فضلك اعطي الصلاحية للموقع من الإعدادات",
  please_enter_last_name: "من فضلك أدخل اللقب",
  please_enter_city: "من فضلك أدخل المدينة",
  please_enter_state: "من فضلك أدخل الولاية",
  please_enter_postal_code: "من فضلك أدخل الرمز البريدي",
  please_enter_country: "من فضلك أدخل الدولة",
  please_select_end_date_greater_then_start_date:
    "من فضلك أدخل تاريخ النهاية أعلى من تاريخ البداية",
  sub_user_does_not: "المستخدم الفرعي لايملك أي قيمة",
  enter_withdraw_amount_is_greater_then:
    "القيمة التي ترغب بسحبها أكبر من رصيد هذا الحساب الفرعي",
  please_select_amount: "من فضلك اختر القيمة",
  this_company_does_not_any_gift: "هذه الشركة لاتملك أي كوبونات",
  please_select_the_payment_method: "من فضلك اختر طريقة الدفع",
  requested_updated_successfully: "تم تحديث الطلب بنجاح",
  status_updated: "تم تحديث الحالة",
  under_development: "تحت التطوير",
  please_phone_number: "أدخل رقم الهاتف",
  entered_amount_is_greater_then_the: "القيمة المدخلة أعلى من رصيدك",
  here_are_no_fingure_prints: "لايوجد بصمة مسجلة على هذا الجهاز",
  please_enter_valid_opt: "من فضلك أدخل كلمة سر OTP فعالة",
  please_enter_a_correct_opt: "من فضلك أدخل كلمة سر OTP صحيحة",
  invalid_recipient_to_request: "المستلم غير صحيح",
  invalid_ticket_to_verify: "الكرت غير صحيح",
  select_customer_did_not_have_account: "المتجر الذي اخترته لايملك حساب RPay",
  you_can_not_send_money: "لا تستطيع ارسال/ استلام نقود من نفسك",
  please_check_country_code: "من فضلك التأكد من كود الدولة",
  please_choose_a_valid_number: "من فضلك اختر رقم صحيح",
  sorry_you_cannot_follow_the_rpay:
    "عذرا، لايمكنك الغاء متابعة تحديثات مسؤول RPay",
  no_feed_found: "لم يتم العثور على تحديثات",
  permission_canceled_now_your_application:
    "تم الغاء الصلاحيات، الآن لايمكن للتطبيق الوصول لجهات الاتصال",
  no_contact_found: "لم يتم العثور على جهات اتصال",
  please_choose_another_number: "من فضلك اختر رقما آخر",
  voucher_provider_set_hidden: "تم اخفاء مزود الايصالات بنجاح",
  card_deleted_successfully: "تم حذف الكرت بنجاح",
  please_enter_smart_card_number: "من فضلك أدخل رقم الكرت الذكي",
  please_enter_id_number: "من فضلك أدخل رقم التعريف",
  please_enter_place_of_issue: "من فضلك أدخل مكان الإصدار",
  please_add_your_review: "من فضلك أضف مراجعتك للمنتج",
  top_up_money: "شحن النقود",
  exchange_money: "تغيير العملة",
  letter_of_credit: "رسالة اعتماد",
  request_category_: "أطلب صنف:",
  request_id_: "أطلب رقم التعريف:",
  date_: "التاريخ:",
  admin_comments: "ملاحظات المسؤول:",
  withdraw: "سحب",
  my_wallet: "المحفظة",
  business_information: "معلومات العمل",
  father_name: "اسم الأب",
  document_completed: "اكتمال المستندات",
  lyd_wallet: "المحفظة بالدينار الليبي",
  available_balance_col: "الرصيد المتاح:",
  current_balance_col: "الرصيد الحالي:",
  wallet_to_wallet_exchange: "مبادلة من محفظة إلى محفظة",
  create_new_wallet: "انشاء محفظة جديدة",
  transaction: "عملية",
  martial_status: "الحالة الإجتماعية",
  provide_basic_information_about_your_business:
    "قم بتوفير المعلومات الأساسية حول نفسك",

  transaction_limits_info: "قم بتوفير المعلومات التالية لغرض تحديد حد العمليات",
  please_provide_address_detail: "من فضلك وفر تفاصيل العنوان",
  zip_code: "الرمز البريدي",
  under_review: "تحت المراجعة",
  available_wallet: "المحفظة المتاحة",
  usd_wallet: "محفظة الدولار الأمريكي",
  exchange_from: "تحويل من",
  exchange_to: "تحويل إلى",
  submit_request: "أرسل الطلب",
  your_request_successfully_submit: "تم ارسال طلبك بنجاح",
  view_detail: "عرض التفاصيل",
  deposite_date: "تاريخ الإيداع",
  slip_no: "رقم القسيمة",
  deposit_slip: "قسيمة الإيداع",
  step_3: "الخطوة 3",
  deposite_currency: "عملة الإيداع",
  step_2: "الخطوة 2",
  hide_detail: "اخفاء التفاصيل",
  cvv: "قيمة التحقق من الكرت",
  hello_blank_fragment: "مرحبا. جزء فارغ",
  add_new_bank: "أضف مصرفا جديدا",
  bank_transfer: "تحويل مصرفي",
  please_provide_the_user_bank_account_details:
    "من فضلك قم بتوفير تفاصيل الحساب المصرفي",
  account_no: "رقم الحساب",
  transfer_from: "تحويل من",
  transfer_to_beneficiaries: "تحويل إلى مستفيد",
  receiver_name: "اسم المستلم",
  receivers_account_no: "رقم الحساب المصرفي للمستلم",
  please_enter_your_4_digits_pin_code: "من فضلك أدخل رمز الأمان من 4 خانات",
  forgot_pin: "نسيت رمز الأمان PIN؟",
  delete_underline: "حذف",
  my_cbl: "رسالة CBL الخاصة بي",
  please_provide_the_existing_cbl_details:
    "برجاء توفير تفاصيل CBL الموجود حاليا",
  please_enter_the_cbl_number: "برجاء أدخل رقم CBL",
  select_language: "اختر اللغة",
  account_setting: "إعدادات الحساب",

  transation_history: "تاريخ المعاملات",
  analyzer_report_cap: "تقرير المحلل",
  orders_cap: "الطلبات",
  scan_cap: "مسح",
  please_enter_the_account_number: "من فضلك أدخل رقم الحساب",
  please_select_deposite_date: "من فضلك اختر تاريخ الإيداع",
  please_select_a_bank: "من فضلك اختر المصرف",
  please_upload_deposite_slip: "من فضلك قم بارفاق قسيمة الإيداع",
  wallet_is_not_available: "المحفظة غير متاحة",
  congratulation_you_have_made_transaction_successfully:
    "تهانينا!! قمت بإجراء العملية بنجاح",
  currency_is_the_same: "العملة نفسها",
  issue_date_should_be_smaller_than_expire_date:
    "تاريخ الإصدار يجب أن يكون أصغر من تاريخ انتهاء الصلاحية",
  expire_date_should_greater_than_issue_date:
    "تاريخ انتهاء الصلاحية يجب أن يكون بعد تاريخ الإصدار",
  please_select_the_issue_date_first: "من فضلك اختر تاريخ الإصدار أولا",
  please_enter_zip_code: "من فضلك أدخل الرمز البريدي",
  please_enter_your_current_experience: "من فضلك أدخل الخبرة الحالية",
  my_cbl_numbe: "رقم CBL الخاص بي",
  please_select_the_bank_that_you_have_deposited_the_top_up_amount:
    "من فضلك أدخل المصرف الذي قمت بايداع قيمة الشحن به",
  status: "الحالة",
  selected_custome_didnt_have_tizdan_account:
    "المتجر الذي تم اختياره لايملك حساب تزدان",
  you_cannot_send: "لاتستطيع ارسال/ استقبال نقود من نفسك",
  qr_pay_transaction: "عمليات دفع QR",
  fill_required_fields: "قم بملء جميع الحقول المطلوبة",
  family_members_detail: "تفاصيل فرد العائلة",
  family_members_list: "قائمة أفراد العائلة",
  relation: "العلاقة",
  view_transaction_limits: "أعرض حدود العملية",
  view_transaction_history: "أعرض العمليات السابقة",
  set_your_password: "انشاء كلمة مرور خاصة بك",
  please_enter_the_password: "من فضلك أدخل كلمة المرور",
  please_confirm_your_password: "من فضلك قم بتأكيد كلمة المرور",
  top_up_money_limit: "حدود شحن النقود",
  withdraw_limit: "حد السحب",
  customer_to_customer: "زبون إلى زبون",
  transfer_limit: "حد التحويل",
  customer_to_merchant: "زبون إلى تاجر",
  limit_definition: "يجب تعريف هذه الحدود شهريا",
  note: "ملاحظة:",
  set_transaction_limits: "ضع حدود العمليات",
  family_member_added_successfully: "تم إضافة فرد الأسرة بنجاح",
  alert: "تنبيه",
  are_you_sure_you_want_to_delete_this_member:
    "هل أنت متأكد من أنك تريد حذف هذا الفرد",

  monthly_limits: "الحدود الشهرية",
  customer: "زبون",
  transfer_to_family: "تحويل إلى أفراد العائلة",
  enabled: "السماح",
  disable: "إبطال",
  no_wallets_available: "لاتوجد محفظة!",
  family_member_wallet_view: "عرض محفظة فرد من العائلة",

  use_pay_to_send_receive_paymnets_for_your_online_transactions:
    "استخدم دفع لإرسال واستلام المدفوعات لمعاملاتك عبر الإنترنت",
  resend: "إعادة ارسال",
  create_pin: "انشاء رمز أمان PIN",
  to_increase_your_verification_level_complete_100_profile_now:
    "لغرض زيادة استكمال مستوى التعريف إلى 100% من فضلك أكمل الملف الشخصي الآن",
  no_beneficiaries_yet: "لايوجد مستفيدون حتى الآن",
  no_family_member_yet: "لايوجد أفراد أسرة حتى الآن!",
  reason: "السبب:",
  update: "تحديث",
  transaction_not_availble: "العملية غير متاحة",
  enable_fingerprint: "السماح بالبصمة",
  failed: "فشل",
  no_kyc: "من فضلك أدخل طلب اعرف زبونك أولا",
  please_confirm_your_security_pin: "قم بتأكيد رقمك السري",
  events_and_offer_screen: "شاشة الأحداث، والعروض",
  manager: "المدير",
  item_detail: "تفاصيل العنصر",
  view_code: "عرض الكود",
  redeemed: "مخلص",
  code_collin: "الكود:",
  analyzer: "المحلل",
  all_user: "كل المستخدمين",
  omlah_users: "مستخدمو عملة",
  all_transactions: "كل العمليات",
  paid_to: "دفعت إلى",
  select_a_Amount: "اختر القيمة",
  pay_amount: "قيمة الدفعة:",
  recharger_failed: "عملية إعادة الشحن فشلت",
  card_code_colln: "كود البطاقة",
  to_high: "إلى الأعلى",
  nfc_payment: "دفعة Nfc",
  to_low: "إلى الأدنى",
  refunded_amount: "المبلغ المسترد",
  refund_amount: "استرداد المبلغ",
  refund_type: "نوع الاسترداد",
  refund_date: "تاريخ الاسترداد",
  more_: "أكثر",
  verification_level: "مستوى التحقق",
  in_progress: "تحت الإجراء",
  select_file: "اختر الملف",
  no_recorde_found: "لم يتم العثور على السجل",
  enter_valid_amount: "أدخل قيمة صحيحة!",
  bank_name_cant_be_empty: "لايمكن ترك خانة اسم المصرف فارغة!",
  account_number_cant_be_empty: "لايمكن ترك خانة رقم الحساب فارغة!",
  today_s_exchange_rate: "معدل الصرف اليوم:",
  you_will_receive: "ستستلم:",
  please_provide_the_bank_transfer_details:
    "من فضلك قم بتوفير تفاصيل التحويل المصرفي",
  purchase_offline_voucher_codes: "شراء ايصالات دون اتصال",
  your_default_wallet_is: "محفظتك الإفتراضية هي",
  top_up_: "شحن",
  transfer_cap: "تحويل",
  relationship_col: "العلاقة",
  please_enter_national_id: "من فضلك رقمك الوطني",
  select_relation_from_given: "اختر العلاقة من الآتي",
  near_by: "متاجر في الجوار",
  load_more: "تحميل أكثر",
  are_you_sure_you_want_to_delete_cbl_num:
    "هل أنت متأكد من رغبتك في حذف رقم CBL الخاص بك؟",
  please_enter_your_comments: "من فضلك أدخل تعليقاتك",
  to_pay: "للدفع",
  accepted_here: "قبلت هنا",
  scan_this_qr_code: "قم بمسح (scan) كود الاستجابة السريعة QR هذا",
  removed: "تم الإزالة",
  mixed: "مختلط",
  higher_to_lower: "من الأعلى للأدنى",
  lower_to_high: "من الأدنى للأعلى",
  received_small: "تم الاستلام",
  balance_is_not_available: "الرصيد غير متاح",
  parent_info: "تفاصيل الوالد",
  forgot_pin_: "نسيت رمز الأمان دبوس",
  zero_lyd: " 0 د.ل ",
  please_apply_for_cbl_first: "يرجى التقدم بطلب للحصول على CBL أولاً",
  wallet_and_currency_should_be_same:
    "يجب أن تكون المحفظة والعملة المحددتان متماثلتين.",
  cant_transfer_more_than_wallet_balance:
    "لا يمكنك تحويل مبلغ يزيد عن رصيد حسابك.",
  cheque: "صك مصرفي",
  search_by_shop_name: "بحث بواسطة اسم المتجر",
};
