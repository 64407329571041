import {Platform} from "react-native";
import React, { useEffect } from "react";
import { createMaterialTopTabNavigator } from "@react-navigation/material-top-tabs";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import FamilyWallet from "./FamilyWallet";
import FamilyTransactions from "./FamilyTransactions";
import FamilyDetail from "./FamilyDetail";
import FamilyTopUp from "./FamilyTopUp";
import { useTranslation } from "react-i18next";
import EditFamilyMember from "./EditFamilyMember"
const Tab = createMaterialTopTabNavigator();
const Stack = createNativeStackNavigator();

const TopUpWallet = () => {
  return (
    <Stack.Navigator>
      <Stack.Screen
        options={{ headerShown: false }}
        name="FamilyWallet"
        component={FamilyWallet}
      />
      <Stack.Screen
        options={{ headerShown: false }}
        name="FamilyTopUp"
        component={FamilyTopUp}
      />
    </Stack.Navigator>
  );
};
const EditDetail = () => {
  return (
    <Stack.Navigator>
      <Stack.Screen
        options={{ headerShown: false }}
        name="FamilyDetail"
        component={FamilyDetail}
      />
      <Stack.Screen
        options={{ headerShown: false }}
        name="EditFamilyMember"
        component={EditFamilyMember}
      />
    </Stack.Navigator>
  );
};

const TopTabFamily = () => {
  const { t } = useTranslation();

  return (
    <Tab.Navigator
      screenOptions={{
        tabBarLabelStyle: {
          textTransform: "none",
          fontFamily: 'Inter',
          fontStyle: "normal",
          fontWeight: "600",
          fontSize:14,
        },
        tabBarActiveTintColor: 
        Platform.OS === "web" ?
            '#FF4747' : "#000000",
        tabBarInactiveTintColor:"#000000",
        tabBarStyle: 
        Platform.OS === "web" ? 
        {
          backgroundColor: "#F2F2F2",
          elevation: 0,
          shadowOpacity: 0, 
          width:457
        }: {
          backgroundColor: "#F2F2F2",
          elevation: 0,
          shadowOpacity: 0,
        },
        swipeEnabled: false
      }}
    >
      <Tab.Screen
        options={{
          tabBarIndicatorStyle: 
          Platform.OS === "web" ? {
            backgroundColor: "#FF4747",
            height: 2,
          } : {
            backgroundColor: "#FF4747",
            height: 2,
            width: "12%",
            left: "11%"
          },
        }}
        name={t("common:top_up_wallet")}
        component={TopUpWallet}
      />
      <Tab.Screen
        options={{
          tabBarIndicatorStyle: 
          Platform.OS === "web" ? {
            backgroundColor: "#FF4747",
            height: 2,
          } : {
            backgroundColor: "#FF4747",
            height: 2,
            width: "24%",
            left: "4.5%"
          },
        }}
        name={t("common:transactions")}
        component={FamilyTransactions}
      />
      <Tab.Screen
        options={{
          tabBarIndicatorStyle: 
          Platform.OS === "web" ? {
            backgroundColor: "#FF4747",
            height: 2,
          } : {
            backgroundColor: "#FF4747",
            height: 2,
            width: "14%",
            left: "10%"
          },
        }}
        name={t("common:details")}
        component={EditDetail}
      />
    </Tab.Navigator>
  );
};

export default TopTabFamily;

