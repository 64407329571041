import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  Pressable,
  Dimensions,
  SafeAreaView,
  TextInput,
} from "react-native";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  checkPhone,
  resetCheckPhoneState,
} from "../../Redux/Models/CheckPhoneNumber/Actions";
import Loading from "../Loading";
import { ScrollView } from "react-native-gesture-handler";
import DownIcon from "react-native-vector-icons/MaterialIcons"  
import CountryPicker from 'react-native-country-picker-modal'
import AuthHeader from '../../Components/AuthHeader'
const height = Dimensions.get("window").height;
const PhoneNumber = ({ navigation }) => {
  const [formattedValue, setFormattedValue] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [visible, setVisible] = useState(false);
  const [countryCode, setCountryCode] = useState("LY");
  const [callingCode, setWithCallingCode] = useState(218);
  const onSelect = (country) => {
      setCountryCode(country.cca2);
      setWithCallingCode(country.callingCode);
  };
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const checkPhoneNumber = useSelector((state) => state.checkPhoneNumber);

  const checkPhoneNumberApi = () => {
    dispatch(checkPhone(formattedValue));
  };

  useEffect(() => {
    console.log("in effect", checkPhoneNumber);
    if (checkPhoneNumber.data.user === true) {
      navigation.navigate("VerificationCode");
      dispatch(resetCheckPhoneState());
    }
    if (checkPhoneNumber.data.user === false) {
      navigation.navigate("SignIn", { phoneNumber: formattedValue });
      dispatch(resetCheckPhoneState());
    }
    if (checkPhoneNumber.error === true) {
      alert(checkPhoneNumber.message);
      dispatch(resetCheckPhoneState());
    }
  }, [checkPhoneNumber]);
  const onChangePhone =(text)=>{
    var cleaned = ("" + text).replace(/\D/g, "");
    let normValue = `${cleaned.substring(0, 3)}${
      cleaned.length > 3 ? "-" : ""
    }${cleaned.substring(3, 6)}${
      cleaned.length > 6 ? "-" : ""
    }${cleaned.substring(6, 10)}`;
    setPhoneNumber(normValue)
    let withCode ='00'+callingCode.toString() + text.replace(/-/g, "").replace("-", "");
    setFormattedValue(withCode)
  }

  useEffect(() => {
    if (!!formattedValue) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [formattedValue]);
  return (
    <SafeAreaView style={styles.mainContainer}>
      <ScrollView contentContainerStyle={{flexGrow:1}}>
      <AuthHeader/>
        <View style={styles.SubContainer}>
          <View style={styles.pageView}>
            <View style={styles.mainContectContainer}>
              <Text style={styles.numberText}>
                {t("common:EnterPhoneNumber")}
              </Text>
              <Text style={styles.authText}>{t("common:text_you_code")}</Text>
              <View style={styles.phoneView}>
              <Text style={styles.phoneText}>{t("common:phone_number")}</Text>             
            </View>
            <View style={{flexDirection:'row',gap:10,flexWrap:'wrap'}}>            
            <Pressable onPress={()=>setVisible(true)} style={styles.countryPickerView}>
              <CountryPicker
                countryCode={countryCode}
                withCallingCodeButton={true}
                withCallingCode={true}
                containerButtonStyle={styles.names} 
                withFlag={true}
                withEmoji={false}
                withFilter={true}
                onSelect={onSelect}
                visible={visible}
                onClose={()=>setVisible(false)}             
              />
              <DownIcon name="keyboard-arrow-down" style={{marginLeft:10}} size={24}></DownIcon>           
            </Pressable>
            <TextInput 
            placeholder="(91) 837 6323"
            value={phoneNumber}
            placeholderTextColor="rgba(0,0,0,0.4)"          
            keyboardType="number-pad"
            maxLength={12}
            style={styles.phoneTextInput}
            onChangeText={onChangePhone}></TextInput>
            </View>
            <View style={styles.btnView}>
              <TouchableOpacity
                onPress={checkPhoneNumberApi}
                style={disabled ? styles.buttonDisabled : styles.button}
                disabled={disabled}
              >
                <Text style={styles.buttonText}>{t("common:Continue")}</Text>
              </TouchableOpacity>
            </View>
            </View>
           
          </View>

        </View>
      </ScrollView>
      {checkPhoneNumber.loading && <Loading></Loading>}
    </SafeAreaView>
  );


};

export default PhoneNumber;

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    backgroundColor: "#FFFFFF",
  },


  SubContainer: {
    flex: 1,
    backgroundColor: "#F8F8F8",
    flexDirection:'row',
    justifyContent:'center'
  },
  pageView: {
    flex: 1,
    maxHeight:580,
    maxWidth:640,
    marginHorizontal: 15,
    backgroundColor:'white',
    marginTop: 30,
    borderRadius: 10,
    marginBottom:51,
  },
  mainContectContainer: {
    flex: 1,
    margin: 50,
  },
  countryPickerView: {
    backgroundColor: "#F8F8F8",
    width:183.5,
    height:55,
    marginTop: 4,
    flexDirection:'row',
    justifyContent: "center",
    alignItems:'center',
    borderRadius:10
},
phoneTextInput:
{width:346.5,height:55,backgroundColor:'#F8F8F8',marginTop: 4,borderRadius:10,outlineStyle:'none',paddingVertical:16,paddingHorizontal:30,fontWeight:'600',fontSize:18,lineHeight:22},
  numberText: {
    marginTop: 26,
    fontFamily: "Inter",
    fontStyle: "normal",
    fontSize: 26,
    color: "#101820",
    fontWeight: "700",
    alignSelf: "flex-start",
  },
  authText: {
    marginTop: 16,
    color: "#101820",
    fontSize: 16,
    fontWeight: "400",
    fontStyle: "normal",
    fontFamily: "Inter",
    alignSelf: "flex-start",
  },
  phoneView: {
    marginTop: 32,
  },
  phoneText: {
    fontSize: 14,
    fontWeight: "500",
    color: "#A9ABAE",
    alignSelf: "flex-start",
  },
  number: {
    marginTop: 7,
    backgroundColor: "#F8F8F8",
    borderBottomWidth: 1,
    width: "100%",
     flexWrap:'wrap',
    borderBottomColor: "#E9E9E9",
  },
  number1: {
    backgroundColor: "#F8F8F8",
    fontWeight: "600",
    flex:1,
    overflow:"hidden",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontSize: 16,
    marginTop: 1,
    color: "#101820",
    outlineStyle: "none",
  },
  numberContainer: {
    height: 70,
    backgroundColor: "#F8F8F8",
  },
  codeText: {
    fontWeight: "600",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontSize: 16,
    marginTop: 1,
    color: "#101820",
  },
  btnView: {
    flex: 1,
    // minWidth:540,
    justifyContent: "flex-end",
    // marginTop: 500,
    // marginBottom:51
  },
  button: {
    padding: 16,
    borderRadius: 70,
    backgroundColor: "#FF4747",
  },
  buttonDisabled: {
    padding: 16,
    borderRadius: 70,
    opacity: 0.5,
    backgroundColor: "#FF4747",
  },
  buttonText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 16,
    alignItems: "center",
    textAlign: "center",
    color: "#FFFFFF",
  },
});
