import { StyleSheet, Text, View, TouchableOpacity, Image, useWindowDimensions } from "react-native";
import React, { useEffect } from "react";
import homeImageRed from "../../../assets/image/HomeRed.png";
import categoryImage from "../../../assets/image/Category.png";
import categoryImageRed from "../../../assets/image/CategoryRed.png";
import documentImage from "../../../assets/image/Document.png";
import documentImageRed from "../../../assets/image/DocumentRed.png";
import ActivityImage from "../../../assets/image/Activity.png";
import profileImage from "../../../assets/image/ProfileBlack.png";
import profileImageRed from "../../../assets/image/ProfileRed.png";
import logOut from "../../../assets/image/cabinet-logout1.png";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { setUser } from "../../../Redux/Models/User/Login/Actions";
import { CHANGE_STATUS } from "../../../Redux/Models/User/Register/Action";
import Alert from "../../../Components/Alert";
import LogoImage from "../../../assets/image/logotizdan.png"
import { selectedRequests } from '../../../Redux/Models/MyRequests/Actions'
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import homeImage from "../../../assets/image/Home.png";

const SideBar = ({ navigation,route }) => {
  const { width } = useWindowDimensions();
  const selectedRequest = useSelector((state) => state.myRequests.tab);
  const dispatch = useDispatch()
  const { t } = useTranslation();

  useEffect(() => {
    if (!selectedRequest) {
      dispatch(selectedRequests("Dashboard"))
    }
  }, [])
  const switchTab = (tab) => {
    dispatch(selectedRequests(tab))
    navigation.navigate(tab)
  }
  const logout = () => {
    Alert(
      t("common:tizdan_customer"),
      t("common:are_you_sure_you_want_to_logout"),
      [
        {
          text: t("common:No"),
          style: "cancel",
        },
        {
          text: t("common:yes"),
          onPress: () => {
            AsyncStorage.removeItem("user").then((val) => console.log(val)).catch((err) => console.log(err));
            const user = { token: null };
            dispatch(setUser(user));
            dispatch({ type: CHANGE_STATUS });
          },
        },
      ],
      {
        cancelable: true,
      }
    );
  };
  return (
    <View style={[styles.headerContainer, width < 560 && { maxWidth: 50 }]}>
      <View style={styles.logoContainer}>
        {width > 560 && <Image source={LogoImage} style={{ width: 120, height: 50, resizeMode: "contain" }} />}
      </View>
      <View style={styles.sidebar}>
        <TouchableOpacity onPress={() => switchTab('Dashboard')} style={[styles.activeButton, route.name !== "Dashboard" && { backgroundColor: '#FFF' }]}>
          <View style={[styles.buttonInnerContent, width < 560 && { marginLeft: 10 }]}>
            <Image source={route.name === "Dashboard" ? homeImageRed : homeImage} style={styles.homeImage} />
            {width > 560 && <Text style={[styles.activeButttonText, route.name !== "Dashboard" && { color: '#000', fontWeight: "500" }]}>{t("common:Home")}</Text>}
          </View>
        </TouchableOpacity>
        <TouchableOpacity onPress={() => switchTab('Services')} style={[styles.activeButton, route.name !== "Services" && { backgroundColor: '#FFF' }]}>
          <View style={[styles.buttonInnerContent, width < 560 && { marginLeft: 10 }]}>
            <Image source={route.name === "Services" ? categoryImageRed : categoryImage} style={styles.homeImage} />
            {width > 560 && <Text style={[styles.activeButttonText, route.name !== "Services" && { color: '#000', fontWeight: "500" }]}>{t("common:category")}</Text>}
          </View>
        </TouchableOpacity>
        <TouchableOpacity onPress={() => {
          navigation.navigate('RequestTopUp')
          dispatch(selectedRequests(t("common:new_")))
        }} style={[styles.activeButton, selectedRequest !== "RequestTopUp" && { backgroundColor: '#FFF' }]}>
          <View style={[styles.buttonInnerContent, width < 560 && { marginLeft: 10 }]}>
            <Image source={selectedRequest === "RequestTopUp" ? documentImageRed : documentImage} style={styles.homeImage} />

            {width > 560 && <Text style={[styles.activeButttonText, selectedRequest !== "RequestTopUp" && { color: '#000', fontWeight: "500" }]}>{t("common:document")}</Text>}
          </View>
        </TouchableOpacity>
        <TouchableOpacity style={styles.button}>
          <View style={[styles.buttonInnerContent, width < 560 && { marginLeft: 10 }]}>
            <Image source={ActivityImage} style={styles.homeImage} />

            {width > 560 && <Text style={styles.butttonText}>{t("common:Activity")}</Text>}
          </View>
        </TouchableOpacity>
        <TouchableOpacity onPress={() => switchTab('Profile')} style={[styles.activeButton, route.name !== "Account" && { backgroundColor: '#FFF' }]}>
          <View style={[styles.buttonInnerContent, width < 560 && { marginLeft: 10 }]}>
            <Image source={route.name === "Account" ? profileImageRed : profileImage} style={styles.homeImage} />

            {width > 560 && <Text style={[styles.activeButttonText, route.name !== "Account" && { color: '#000', fontWeight: "500" }]}>{t("common:Profile")}</Text>}
          </View>
        </TouchableOpacity>
      </View>
      <View style={styles.endContainer}>
        <TouchableOpacity onPress={logout} style={styles.button}>
          <View style={[styles.buttonInnerContent, width < 560 && { marginLeft: 10 }]}>

            <Image source={logOut} style={styles.homeImage} />

            {width > 560 && <Text style={styles.butttonText}>{t("common:logout")}</Text>}
          </View>
        </TouchableOpacity>
      </View>
    </View>
  );
};

export default SideBar;

const styles = StyleSheet.create({
  headerContainer: {
    flex: 1,
    backgroundColor: "#ffffff",
    maxWidth: 250,
    maxHeight: 1024,
  },
  logoContainer: {
    marginTop: 10,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#ffffff",
    height: 51,
  },
  logoView: {
    color: "#FF4747",
    fontSize: 30,
    fontStyle: "normal",
    marginLeft: 25,
    fontWeight: "600",
  },
  sidebar: {
    flex: 1,
    marginTop: 19,
  },
  activeButton: {
    flex: 1,
    maxHeight: 50,
    backgroundColor: "rgba(255, 71, 71, 0.05)",
  },
  button: {
    flex: 1,
    maxHeight: 50,
  },
  buttonInnerContent: {
    flex: 1,
    alignItems: "center",
    flexDirection: "row",
    marginLeft: 33
  },
  homeImage: {
    height: 30,
    width: 30,
    resizeMode: "contain"
  },
  categoryImage: {
    height: 18,
    width: 18,
    resizeMode: "contain"
  },
  documentImage: {
    height: 20,
    width: 20,
    resizeMode: "contain"
  },
  profileImage: {
    height: 19,
    width: 16,
    resizeMode: "contain"
  },
  iconImage: {
    height: 20,
    width: 20,
    resizeMode: "contain"
  },
  activeButttonText: {
    marginLeft: 13,
    fontSize: 16,
    fontWeight: "700",
    color: "#FF4747",
  },
  butttonText: {
    marginLeft: 13,
    fontStyle: "normal",
    fontSize: 16,
    fontWeight: "500",
    color: "#000000",
  },
  endContainer: {
    flex: 1,
    justifyContent: 'flex-end',
    maxHeight: 50,
    marginBottom: 30
  }
});
