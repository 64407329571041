
const POST_SERVICE_VOUCHER_COMPANY_LIST_REQUEST = 'POST_SERVICE_VOUCHER_COMPANY_LIST_REQUEST';
const POST_SERVICE_VOUCHER_COMPANY_LIST_REQUEST_SUCCESS =  'POST_SERVICE_VOUCHER_COMPANY_LIST_REQUEST_SUCCESS';
const POST_SERVICE_VOUCHER_COMPANY_LIST_REQUEST_FAILURE =  'POST_SERVICE_VOUCHER_COMPANY_LIST_REQUEST_FAILURE';
const FILTER_SERVICE_VOUCHER_COMPANY_LIST = "FILTER_SERVICE_VOUCHER_COMPANY_LIST";

const serviceVoucherCompanyList = (formData)=>{

    return {
        type: POST_SERVICE_VOUCHER_COMPANY_LIST_REQUEST,
        formData
    }
}
const filterVoucherCompanyList = (search) => {
  return {
    type: FILTER_SERVICE_VOUCHER_COMPANY_LIST,
    search,
  };
};
export {
  serviceVoucherCompanyList,
  filterVoucherCompanyList,
  POST_SERVICE_VOUCHER_COMPANY_LIST_REQUEST,
  POST_SERVICE_VOUCHER_COMPANY_LIST_REQUEST_SUCCESS,
  POST_SERVICE_VOUCHER_COMPANY_LIST_REQUEST_FAILURE,
  FILTER_SERVICE_VOUCHER_COMPANY_LIST,
};