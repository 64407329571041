import {
  POST_USER_BENEFICIARIES_REQUEST,
  POST_USER_BENEFICIARIES_REQUEST_SUCCESS,
  POST_USER_BENEFICIARIES_REQUEST_FAILURE,
  POST_ADD_BENEFICIARIES_REQUEST,
  POST_ADD_BENEFICIARIES_REQUEST_SUCCESS,
  POST_ADD_BENEFICIARIES_REQUEST_FAILURE,
  CHANGE_BENEFICIARIES_STATUS,
  RESET_USER_BENEFICIARIES_STATE
} from "./Actions";

const initialState = {
  message: null,
  data: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case POST_USER_BENEFICIARIES_REQUEST: {
      console.log(POST_USER_BENEFICIARIES_REQUEST);

      return {
        ...state,
        error: false,
        success:false,
        loading: true,
      };
    }
    case POST_USER_BENEFICIARIES_REQUEST_SUCCESS: {
      const { message, data } = action.payload;
      console.log(POST_USER_BENEFICIARIES_REQUEST_SUCCESS);
      return {
        ...state,
        loading: false,
        success:true,
        data, 
        message,
      };
    }
    case POST_USER_BENEFICIARIES_REQUEST_FAILURE: {
      console.log(POST_USER_BENEFICIARIES_REQUEST_FAILURE);

      const { message } = action.payload;
      return {
        ...state,
        loading: false,
        success:false,
        error: true,
        message,
      };
    }
    case POST_ADD_BENEFICIARIES_REQUEST: {
      console.log(POST_ADD_BENEFICIARIES_REQUEST);

      return {
        ...state,
        error: false,
        success:false,
        loading: true,
      };
    }
    case POST_ADD_BENEFICIARIES_REQUEST_SUCCESS: {
      const { message, success } = action.payload;
      console.log(POST_ADD_BENEFICIARIES_REQUEST_SUCCESS);
      return {
        ...state,
        loading: false,
        beneficiarySuccess:true,
        addBeneficiary:success, 
        message,
      };
    }
    case POST_ADD_BENEFICIARIES_REQUEST_FAILURE: {
      console.log(POST_ADD_BENEFICIARIES_REQUEST_FAILURE);

      const { message } = action.payload;
      return {
        ...state,
        loading: false,
        success:false,
        error: true,
        message,
      };
    }
    case CHANGE_BENEFICIARIES_STATUS: {
      console.log(CHANGE_BENEFICIARIES_STATUS)
      return {
        ...state,
        beneficiarySuccess: false,
        addBeneficiary:false, 

      };
    }
    case RESET_USER_BENEFICIARIES_STATE: {
      console.log(RESET_USER_BENEFICIARIES_STATE);

      return {
        ...state,
        loading: false,
        success: false,
        error: false,
      };
    }
    default:
      return state;
  }
};

export { reducer };
