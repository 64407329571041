import { takeEvery, put, call } from "redux-saga/effects";
import BASEURL from "../../../URLs";
import { queryApi } from "../../../QueryApi";
import {
  POST_CREATE_FAMILY_MEMBER_PASSWORD_REQUEST,
  POST_CREATE_FAMILY_MEMBER_PASSWORD_REQUEST_SUCCESS,
  POST_CREATE_FAMILY_MEMBER_PASSWORD_REQUEST_FAILURE,
} from "./Actions";

function* handler() {
  yield takeEvery(POST_CREATE_FAMILY_MEMBER_PASSWORD_REQUEST, changeFamilyMemberPassword);
}

function* changeFamilyMemberPassword(action) {
  try {
    // API call
    const apiResponse = yield call(queryApi, {
      endpoint: BASEURL.familyMemberPasswordChange + action.parms,
      method: "POST",
      body: action.formData,
    });
    console.log("changeFamilyMemberPassword API", apiResponse);
    if (!("error" in apiResponse)) {
      yield put({
        type: POST_CREATE_FAMILY_MEMBER_PASSWORD_REQUEST_SUCCESS,
        payload: apiResponse,
      });
    } else {
      yield put({
        type: POST_CREATE_FAMILY_MEMBER_PASSWORD_REQUEST_FAILURE,
        payload: apiResponse.error,
      });
    }
  } catch (err) {
    console.log(err);
    // Handle error
    yield put({
      type: POST_CREATE_FAMILY_MEMBER_PASSWORD_REQUEST_FAILURE,
      payload: { message: err },
    });
  }
}

export { handler };
