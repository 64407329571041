import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  Image,
  TextInput,
  I18nManager,
  Dimensions,
  ScrollView,
  SafeAreaView
} from "react-native";
import ToastAndroid from "react-native-root-toast";
import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import Lyd from "../../assets/image/lyd.png";
import { Picker } from "@react-native-picker/picker";
import Money from "../../assets/image/money.png";
import { useDispatch, useSelector } from "react-redux";
import {
  requestTransfer,
  changeRequestTransferstatus,
} from "../../Redux/Models/RequestTransfer/Actions";
import Loading from "../Loading";
import { getUserWallet, resetUserWallet } from "../../Redux/Models/UserWallet/Actions";
import { getCurrencies } from "../../Redux/Models/Currencies/Actions";
import Alert from "../../Components/Alert";

const height = Dimensions.get("window").height;

const UserAccount = ({ navigation, route }) => {
  const { t } = useTranslation();
  const [currency, setCurrency] = useState("");
  const [accountNumber, setAccountNumber] = useState(
    route.params?.phoneNumber
  );
  const [amount, setAmount] = useState("");
  const [description, setDescription] = useState("");
  const dispatch = useDispatch();
  const requestTransfers = useSelector((state) => state.requestTransfer);
  const userDetails = useSelector((state) => state.userDetails);
  const [wallet, setWallet] = useState(userDetails.data.user?.accounts[0]);
  const userWallets = useSelector((state) => state.userWallet);
  const currencies = useSelector((state) => state.currencies);

  const amountRef = useRef();
  const descriptionRef = useRef();
  const validate = () => {
    if (amount === "") {
      ToastAndroid.show(t("common:Please_enter_amount"), ToastAndroid.LONG);
      return false;
    } else if (description === "") {
      ToastAndroid.show(t("common:PhonNumber_does_not_match_any_account"), ToastAndroid.LONG);
      return false;
    }
    return true;
  };
  const requestTransferApi = () => {
    if (!validate()) {
      return;
    }
    let params = new URLSearchParams({
      phone_number: accountNumber.replace(/-/g, ""),
    });
    dispatch(getUserWallet(params));


  };

  useEffect(() => {
    // let params = new URLSearchParams({
    //   phone_number: accountNumber,
    // });
    // dispatch(getUserWallet(params));
    dispatch(getCurrencies());
  }, []);
  const transferRequestApi = () => {
    let formData = new FormData();
    formData.append(
      "financial_request[receiver_account_id]",
      userWallets.data.wallets[0].id
    );
    formData.append("financial_request[amount]", amount);
    formData.append("financial_request[sender_account_id]", wallet.id);
    formData.append("request_date", Math.ceil(new Date().getTime() / 1000));
    formData.append("description", description);
    navigation.navigate("TransferVerifyPin", { formData });
  }
  useEffect(() => {
    if (requestTransfers.error === true) {
      return Alert("Error", requestTransfers.message, [
        {
          text: "OK",
          onPress: () => {
            dispatch(changeRequestTransferstatus());
          },
        },
      ]);
    }

    if (userWallets.success === true) {
      dispatch(resetUserWallet());
      if (userWallets.data.wallets.length > 0) {
        transferRequestApi();
      } else {
        return Alert("Error", "PhonNumber does not match any account");
      }
    }
    if (requestTransfers.success === true) {
      return Alert("Congratulation", requestTransfers.message, [
        {
          text: "OK",
          onPress: () => {
            dispatch(changeRequestTransferstatus());
          },
        },
      ]);
    }
  }, [requestTransfers, userWallets]);

  return (
    <SafeAreaView style={styles.topContainer}>
      <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
        <View style={styles.innerContainer}>
          <View style={styles.insideContainer}>
            <View style={styles.nameContainer}>
              <Text style={{ ...styles.nameText, marginTop: 20 }}>
                {t("common:account_number_st")}
                <Text style={{ color: "#FF4747" }}>*</Text>
              </Text>
              <TextInput
                textAlign={I18nManager.isRTL ? "right" : "left"}
                keyboardType="number-pad"
                returnKeyType="done"
                onSubmitEditing={() => amountRef.current.focus()}
                maxLength={12}
                value={accountNumber}
                onChangeText={(text) => {
                  var cleaned = ("" + text).replace(/\D/g, "");
                  let normValue = `${cleaned.substring(0, 3)}${cleaned.length > 3 ? "-" : ""
                    }${cleaned.substring(3, 6)}${cleaned.length > 6 ? "-" : ""
                    }${cleaned.substring(6, 10)}`;
                  setAccountNumber(normValue);
                }}
                style={styles.input}
              ></TextInput>
            </View>
            <View style={[styles.nameContainer, { marginLeft: 20 }]}>
              <Text style={styles.nameText}>
                {t("common:transfer_from_st")}
                <Text style={{ color: "#FF4747" }}>*</Text>
              </Text>
              <View style={styles.transferFromView}>
                <Image source={Lyd} style={styles.lydImage} />
                <Picker
                  mode="dropdown"
                  selectedValue={wallet}
                  onValueChange={(selectedValue) => setWallet(selectedValue)}
                  style={styles.pickerText}
                >
                  {userDetails !== undefined &&
                    userDetails.data.user.accounts.map((val, ind) => {
                      return (
                        <Picker.Item
                          key={ind}
                          label={val.name}
                          value={val}
                          style={styles.pickerItemText}
                        />
                      );
                    })}
                </Picker>
              </View>
            </View>
          </View>
          <Text style={styles.nameText}>
            {t("common:amount_st")}
            <Text style={{ color: "#FF4747" }}>*</Text>
          </Text>
          <View style={styles.sendView}>
            <TextInput
              textAlign={I18nManager.isRTL ? "right" : "left"}
              defaultValue={amount}
              onChangeText={(newText) => setAmount(newText)}
              keyboardType="number-pad"
              returnKeyType="done"
              ref={amountRef}
              onSubmitEditing={() => descriptionRef.current.focus()}
              style={styles.sendInput}
            ></TextInput>
            <View style={styles.pickerView}>
              <Image source={Money} style={styles.lydImage} />
              <Picker
                mode="dropdown"
                selectedValue={currency}
                onValueChange={(selectedValue) => setCurrency(selectedValue)}
                style={styles.pickerText}
              >
                {currencies.success === true &&
                  currencies.data.currencies?.map((val) => {
                    return (
                      <Picker.Item
                        key={val.id}
                        label={val.currency_iso_code}
                        value={val.id}
                        style={styles.pickerItemText}
                      />
                    );
                  })}
              </Picker>
            </View>
          </View>
          <TextInput
            textAlign={I18nManager.isRTL ? "right" : "left"}
            defaultValue={description}
            onChangeText={(newText) => setDescription(newText)}
            placeholder={t("common:description")}
            style={{ ...styles.input, marginTop: 30 }}
            returnKeyType="done"
            ref={descriptionRef}
          ></TextInput>
          <View style={styles.btnView}>
            <TouchableOpacity onPress={requestTransferApi} style={styles.button}>
              <Text style={styles.buttonText}>{t("common:transfer")}</Text>
            </TouchableOpacity>
          </View>
        </View>
      </ScrollView>

      {(requestTransfers.loading || userWallets.loading) && <Loading></Loading>}
    </SafeAreaView>
  );
};

export default UserAccount;

const styles = StyleSheet.create({
  topContainer: {
    backgroundColor: "#FFFFFF",
    borderRadius: 16,
    marginTop: 20,
  },
  innerContainer: {
    flex: 1,
    marginLeft: 24,
    marginRight: 24,
  },
  insideContainer: {
    flexDirection: "row",
    marginTop: 20,
  },
  nameContainer: {
    flex: 1,
  },
  nameText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 14,
    color: "#A9ABAE",
    alignSelf: "flex-start",
    marginTop: 15,
  },
  input: {
    height: 56,
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 14,
    borderColor: "#E7E8E9",
    borderBottomWidth: 1,
    outlineStyle: "none",
    color: "#101820"
  },
  transferFromView: {
    flexDirection: "row",
    borderColor: "#E7E8E9",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 49,
    marginTop: 10
  },
  lydImage: {
    width: 24,
    height: 24,
  },
  pickerItemText: {
    color: "#101820",
    fontSize: 14,
    fontWeight: "500",
    fontFamily: "Inter",
  },
  sendView: {
    flexDirection: "row",
    marginTop: 10
  },
  sendInput: {
    flex: 1,
    borderBottomWidth: 1,
    borderColor: "#F1F1F1",
    fontFamily: "Inter",
    fontSize: 14,
    fontWeight: "500",
    outlineStyle: "none",
    color: "#101820"
  },
  pickerView: {
    flex: 1,
    borderBottomWidth: 1,
    borderColor: "#F1F1F1",
    marginLeft: 20,
    flexDirection: "row",
    alignItems: "center",
  },
  pickerText: {
    flex: 1,
    height: 45,
    borderWidth: 1,
    borderColor: "white",
    borderStyle: "solid",
  },
  btnView: {
    flex: 1,
    marginTop: 30,
    marginBottom: 20,
    alignItems:"flex-end"
  },
  button: {
    padding: 16,
    backgroundColor: "#FF4747",
    borderRadius: 70,
    width:404
  },
  buttonText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 16,

    alignItems: "center",
    textAlign: "center",
    color: "#FFFFFF",
  },
});
