import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  Image,
  ScrollView,
  TextInput,
  Platform,
  I18nManager,
} from "react-native";
import React, { useState, useRef, useEffect } from "react";
import Icon from "react-native-vector-icons/Feather";
import Calendar from "../../../assets/image/calendar.png";
import { Picker } from "@react-native-picker/picker";
import { useTranslation } from "react-i18next";
import { SafeAreaView } from "react-native-safe-area-context";
import DateTimePickerModal from "react-native-modal-datetime-picker";
import { ApiDateFormate, DateFormate } from "../../../Utils/HelperFunctions";
import { useSelector, useDispatch } from "react-redux";
import { mdpCardsLookupLanguages } from "../../../Redux/Models/MdpCards/MdpCardsLookupLanguages/Actions";
import {
  mdpCardsKycUpdate,
  resetMdpCardsKycUpdate,
} from "../../../Redux/Models/MdpCards/MdpCardsKycUpdate/Actions";
import Alert from "../../../Components/Alert";
import SideBarConatainer from "../../../Components/SideBarConatainer";
import Loading from "../../Loading";
import ToastAndroid from "react-native-root-toast";
import WebDatePicker from "../../../Components/DatePickerTest";

const RTL = I18nManager.isRTL;
const MdpCustomer = ({ navigation }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const items = ["dr", "ms", "mrs", "mr", "sir", "other"];
  const mdpCardsLanguages = useSelector(
    (state) => state.mdpCardsLookupLanguages
  );
  const mdpKycUpdate = useSelector((state) => state.mdpCardsKycUpdate);
  const [secondName, setSecondName] = useState("");
  const [placeOfBirth, setPlaceOfBirth] = useState("");
  const [expiryDate, setExpiryDate] = useState();
  const [house, setHouse] = useState("");
  const [title, setTitle] = useState(items[0]);
  const [gender, setGender] = useState("MALE");
  const [preferredLanguage, setPreferredLanguage] = useState();
  const [idNumber, setIdNumber] = useState("");
  const [formateDate, setFormateDate] = useState();
  const [isDatePickerVisible, setDatePickerVisibility] = useState(false);
  const userDetails = useSelector((state) => state.userDetails);
  const [showImage, setShowImage] = useState(true);

  useEffect(() => {
    dispatch(mdpCardsLookupLanguages());
  }, []);

  useEffect(() => {
    if (mdpCardsLanguages.success === true) {
      setPreferredLanguage(mdpCardsLanguages.data[0].description);
    }
  }, [mdpCardsLanguages]);

  const placeOfBirthRef = useRef();
  const houseRef = useRef();
  const idNumberRef = useRef();
  const showDatePicker = () => {
    setDatePickerVisibility(true);
    setShowImage(false);
  };
  const hideDatePicker = () => {
    setDatePickerVisibility(false);
  };

  const dateExpiryRef = useRef();
  const OnDateChange = (selectedDate) => {
    const currentDate = selectedDate;
    setExpiryDate(currentDate);
    setDatePickerVisibility(false);
    setShowImage(true);
  };
  const handleClickOutside = (event) => {
    let date = dateExpiryRef.current.contains(event.target);
    if (date === false && Platform.OS === "web") {
      setDatePickerVisibility(false);
      setShowImage(true);
    }
  };
  React.useEffect(() => {
    if (Platform.OS === "web") {
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, []);

  const Validate = () => {
    if (secondName === "") {
      ToastAndroid.show(
        t("common:Please_enter_your_second_name"),
        ToastAndroid.LONG
      );
      return false;
    } else if (formateDate === undefined) {
      ToastAndroid.show(
        t("common:Please_select_expiration_date"),
        ToastAndroid.LONG
      );
      return false;
    } else if (placeOfBirth === "") {
      ToastAndroid.show(
        t("common:Please_enter_place_of_birth"),
        ToastAndroid.LONG
      );
      return false;
    } else if (house === "") {
      ToastAndroid.show(t("common:Please_enter_your_house"), ToastAndroid.LONG);
      return false;
    } else if (idNumber === "") {
      ToastAndroid.show(
        t("common:Please_enter_your_id_number"),
        ToastAndroid.LONG
      );
      return false;
    }
    return true;
  };
  const UpdateKyc = () => {
    if (!Validate()) {
      return;
    }
    const formData = new FormData();
    formData.append("second_name", secondName),
      formData.append("gender", gender),
      formData.append("place_of_birth", placeOfBirth),
      formData.append("title", title),
      formData.append("preferred_lang", preferredLanguage.description),
      formData.append("house", house),
      formData.append("id_number", idNumber),
      formData.append(
        "id_expire_date",
        new Date(formateDate).getFullYear() +
        "-0" +
        Math.ceil(new Date(formateDate).getMonth() + 1) +
        "-" +
        new Date(formateDate).getDate()
      );
    // console.log("Data", formData);
    dispatch(mdpCardsKycUpdate(formData));
  };
  useEffect(() => {
    if (mdpKycUpdate.error === true) {
      return Alert("Error", mdpKycUpdate.message, [
        {
          text: "OK",
          onPress: () => {
            dispatch(resetMdpCardsKycUpdate());
          },
        },
      ]);
    }
    if (mdpKycUpdate.success === true) {
      return Alert("Congratulation", mdpKycUpdate.message, [
        {
          text: "OK",
          onPress: () => {
            dispatch(resetMdpCardsKycUpdate());
            navigation.navigate("MdpCard");
          },
        },
      ]);
    }
  }, [mdpKycUpdate]);

  return (
    <SideBarConatainer navigation={navigation}>
      <View style={styles.headerView}>
        <Icon
          name="arrow-left"
          size={30}
          onPress={() => navigation.goBack()}
          style={styles.backIcon}
        />
        <Text style={styles.cardText}>{t("common:mdp_customer")}</Text>
      </View>
      <View style={styles.docContainer}>
        <ScrollView showsHorizontalScrollIndicator={false}>
          <View style={styles.topContainer}>
            <View style={[styles.inputContainer, { zIndex: 2000 }]}>
              <View style={styles.inputView}>
                <Text style={styles.inputText}>
                  {t("common:second_name")}
                  <Text style={{ color: "#FF4747" }}> *</Text>
                </Text>
                <TextInput
                  textAlign={I18nManager.isRTL ? "right" : "left"}
                  onSubmitEditing={() => placeOfBirthRef.current.focus()}
                  value={secondName}
                  onChangeText={(text) => setSecondName(text)}
                  returnKeyType="next"
                  style={styles.cardInput}
                ></TextInput>
              </View>
              <View style={[styles.inputView, { marginLeft: 10 }]}>
                <Text style={styles.inputText}>
                  {t("common:expiry_date")}
                  <Text style={{ color: "#FF4747" }}> *</Text>
                </Text>
                <View style={styles.dateOfBirthView}>
                  <Text style={styles.dateText1}>
                    {expiryDate && ApiDateFormate(expiryDate)}
                  </Text>
                  <TouchableOpacity
                    onPress={showDatePicker}
                    style={styles.dateButton}
                  >
                    {showImage && (
                      <Image source={Calendar} style={styles.calendarImage} />
                    )}
                  </TouchableOpacity>
                  <View
                    style={
                      isDatePickerVisible
                        ? { display: "flex" }
                        : { display: "none" }
                    }
                    ref={dateExpiryRef}
                  >
                    {Platform.OS === "web" && (
                      <WebDatePicker
                        isDatePickerVisible={isDatePickerVisible}
                        date={expiryDate}
                        OnDateChange={OnDateChange}
                        ref={dateExpiryRef}
                      />
                    )}
                  </View>
                </View>
              </View>
            </View>
            <View style={styles.inputContainer}>
              <View style={styles.inputView}>
                <Text style={styles.inputText}>
                  {t("common:place_of_birth")}
                  <Text style={{ color: "#FF4747" }}> *</Text>
                </Text>
                <TextInput
                  textAlign={I18nManager.isRTL ? "right" : "left"}
                  ref={placeOfBirthRef}
                  onSubmitEditing={() => houseRef.current.focus()}
                  returnKeyType="next"
                  value={placeOfBirth}
                  onChangeText={(text) => setPlaceOfBirth(text)}
                  style={styles.cardInput}
                ></TextInput>
              </View>
              <View style={[styles.inputView, { marginLeft: 10 }]}>
                <Text style={styles.inputText}>
                  {t("common:house")}
                  <Text style={{ color: "#FF4747" }}> *</Text>
                </Text>
                <TextInput
                  textAlign={I18nManager.isRTL ? "right" : "left"}
                  ref={houseRef}
                  onSubmitEditing={() => idNumberRef.current.focus()}
                  value={house}
                  onChangeText={(text) => setHouse(text)}
                  returnKeyType="next"
                  style={styles.cardInput}
                ></TextInput>
              </View>
            </View>
            <View style={styles.inputContainer}>
              <View style={styles.inputView}>
                <Text style={styles.inputText}>
                  {t("common:title")}
                  <Text style={{ color: "#FF4747" }}> *</Text>
                </Text>
                <View style={styles.pickerView}>
                  <Picker
                    mode="dropdown"
                    selectedValue={title}
                    onValueChange={(itemValue) => setTitle(itemValue)}
                    style={styles.pickerText}
                  >
                    {items.map((item, index) => (
                      <Picker.Item key={index} label={item} value={item} />
                    ))}
                  </Picker>
                </View>
              </View>
              <View style={[styles.inputView, { marginLeft: 10 }]}>
                <Text style={styles.inputText}>
                  {t("common:gender")}
                  <Text style={{ color: "#FF4747" }}> *</Text>
                </Text>
                <View style={styles.pickerView}>
                  <Picker
                    mode="dropdown"
                    selectedValue={gender}
                    onValueChange={(selectedValue) => setGender(selectedValue)}
                    style={styles.pickerText}
                  >
                    <Picker.Item
                      label="MALE"
                      value="MALE"
                      style={styles.pickerItemText}
                    />
                    <Picker.Item
                      label="FEMALE"
                      value="FEMALE"
                      style={styles.pickerItemText}
                    />
                  </Picker>
                </View>
              </View>
            </View>
            <View style={styles.inputContainer}>
              <View style={styles.inputView}>
                <Text style={styles.inputText}>
                  {t("common:preferred_language")}
                  <Text style={{ color: "#FF4747" }}> *</Text>
                </Text>
                <View style={styles.pickerView}>
                  <Picker
                    mode="dropdown"
                    selectedValue={preferredLanguage}
                    onValueChange={(selectedValue) =>
                      setPreferredLanguage(selectedValue)
                    }
                    style={styles.pickerText}
                  >
                    {mdpCardsLanguages !== undefined &&
                      mdpCardsLanguages.data.map((val, ind) => {
                        return (
                          <Picker.Item
                            key={ind}
                            label={val.description}
                            value={val}
                            style={styles.pickerItemText}
                          />
                        );
                      })}
                  </Picker>
                </View>
              </View>
              <View style={[styles.inputView, { marginLeft: 10 }]}>
                <Text style={styles.inputText}>
                  {t("common:id_number")}
                  <Text style={{ color: "#FF4747" }}>*</Text>
                </Text>
                <TextInput
                  textAlign={I18nManager.isRTL ? "right" : "left"}
                  ref={idNumberRef}
                  value={idNumber}
                  onChangeText={(text) => setIdNumber(text)}
                  keyboardType="number-pad"
                  returnKeyType="done"
                  style={styles.cardInput}
                ></TextInput>
              </View>
            </View>
            <View style={styles.btnView}>
              <TouchableOpacity onPress={UpdateKyc} style={styles.button}>
                <Text style={styles.buttonText}>{t("common:update")}</Text>
              </TouchableOpacity>
            </View>
          </View>
        </ScrollView>
      </View>
      {(mdpKycUpdate.loading || mdpCardsLanguages.loading) && (
        <Loading></Loading>
      )}
    </SideBarConatainer>
  );
};

export default MdpCustomer;

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 5,
  },
  mainContainer: {
    flex: 1,
    backgroundColor: "#FFFFFF",
  },
  docContainer: {
    backgroundColor: "#FFF",
    borderRadius: 16,
    marginTop: 27,
    flex: 1,
  },
  topContainer: {
    padding: 20,
  },
  inputContainer: {
    flex: 1,
    flexDirection: "row",
  },
  inputView: {
    flex: 1,
    maxWidth: 327,
  },
  headerView: {
    flexDirection: "row",
    alignItems: "center",
  },
  backIcon: {
    transform: [{ scaleX: RTL ? -1 : 1 }],
  },
  dateOfBirthView: {
    borderBottomWidth: 1,
    borderColor: "#F1F1F1",
    height: 48,
    flexDirection: "row",
    justifyContent: "space-between",
    zIndex: 2000,
  },
  dateText1: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 14,
    color: "#101820",
    marginTop: 20,
  },
  calendarImage: {
    height: 20,
    width: 20,
    marginTop: 20,
    resizeMode: "contain",
  },
  cardText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 26,
    color: "#000000",
    marginLeft: 10,
  },
  inputText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 14,
    color: "#A9ABAE",
    height: 25,
    marginTop: 10,
  },
  cardInput: {
    borderBottomWidth: 1,
    borderColor: "#E7E8E9",
    height: 56,
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 14,
    outlineStyle: "none",
    color: "#101820",
  },
  dateButton: {
    alignItems: "flex-end",
    marginRight: 10,
    marginTop: 5,
  },
  pickerView: {
    borderBottomWidth: 1,
    borderColor: "#E7E8E9",
    flexDirection: "row",
    alignItems: "center",
    height: 56,
  },
  pickerText: {
    flex: 1,
    height: 45,
    borderWidth: 1,
    borderColor: "white",
    borderStyle: "solid",
  },
  pickerItemText: {
    color: "#101820",
    fontSize: 14,
    fontWeight: "500",
    fontFamily: "Inter",
  },
  btnView: {
    flex: 1,
    marginTop: 30,
    alignItems: "flex-end",
  },
  button: {
    padding: 16,
    backgroundColor: "#FF4747",
    borderRadius: 70,
    width: 404,
  },
  buttonText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 16,
    alignItems: "center",
    textAlign: "center",
    color: "#FFFFFF",
  },
});
