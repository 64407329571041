import { StyleSheet, Text, View } from "react-native";
import React from "react";
import { createMaterialTopTabNavigator } from "@react-navigation/material-top-tabs";
import UserAccount from "./UserAccount";
import BankTransferList from "./BankTransferList";
import { useTranslation } from "react-i18next";
import { Platform } from "react-native";
const Tab = createMaterialTopTabNavigator();

const TopTabTransfer = ({ phoneNumber }) => {
  const { t } = useTranslation();

  return (
    <Tab.Navigator
      screenOptions={{
        tabBarLabelStyle: {
          textTransform: "none",
          fontFamily: 'Inter',
          fontStyle: "normal",
          fontWeight: "600",
          fontSize: 14,
        },
        tabBarActiveTintColor: 
        Platform.OS === "web" ?
            '#FF4747' : "#000000",
        tabBarInactiveTintColor:"#000000",
        tabBarStyle:
        Platform.OS === "web" ? 
        {
          backgroundColor: "#F2F2F2",
          elevation: 0,
          shadowOpacity: 0,
          width:350
        }: {
          backgroundColor: "#F2F2F2",
          elevation: 0,
          shadowOpacity: 0,
        },
        swipeEnabled: false,
      }}
    >
      <Tab.Screen
        initialParams={{ phoneNumber: phoneNumber }}
        options={{
          tabBarIndicatorStyle:
            Platform.OS === "web" ? {
              backgroundColor: "#FF4747",
              height: 2
            } : {
              backgroundColor: "#FF4747",
              height: 2,
              width: "26%",
              left: "12.5%"
            },
        }}
        name={t("common:user_account")}
        component={UserAccount}
      />
      <Tab.Screen
        options={{
          tabBarIndicatorStyle:
            Platform.OS === "web" ? {
              backgroundColor: "#FF4747",
              height: 2
            } : {
              backgroundColor: "#FF4747",
              height: 2,
              width: "26%",
              left: "12.5%"
            },
        }}
        name={t("common:bank_transfer")}
        component={BankTransferList}
      />
    </Tab.Navigator>
  );
};

export default TopTabTransfer;

const styles = StyleSheet.create({});
