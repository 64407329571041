import { StyleSheet, Text, View, SafeAreaView, TouchableOpacity } from "react-native";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import AppHeader from "./AppHeader.web"
import { useSelector, useDispatch } from "react-redux";
import { useRef } from "react";
import {selectedRequests} from '../Redux/Models/MyRequests/Actions' 

const SideBarConatainer = ({children,navigation}) => {
  const { t } = useTranslation();
  const [selected,setSelected] = useState(t("common:new_"))
  const selectedRef =useRef()
const dispatch = useDispatch()
  const userDetails = useSelector((state) => state.userDetails);
  const selectedRequest = useSelector((state) => state.myRequests.tab);

  // console.log("selected",selectedRequests);
useEffect(()=>{
  console.log("selectedRequest",selectedRequest);
  if(!selectedRequest){
    dispatch(selectedRequests(t("common:new_")))
  }
},[])
  const switchTab=(tab)=> {
     dispatch(selectedRequests(tab))
     navigation.navigate(tab) 
  }
  return (
    <SafeAreaView style={{flex:1}}>
      <AppHeader userDetails={userDetails} />
      <View style={styles.mainContainer}>
        <View style={styles.innerContainer}>
          <View style={styles.reqSideBar}>
          <Text style={styles.requestsText}>{t("common:requests")}</Text>
          <TouchableOpacity onPress={()=>switchTab(t("common:new_"))} style={[styles.sideBtn,selectedRequest !==t("common:new_") &&{border:'none'}]}><Text style={ [styles.sideBtnText,selectedRequest === t("common:new_") &&{color:'#FF4747'}]}>{t("common:new_")}</Text></TouchableOpacity>
          <TouchableOpacity onPress={()=>switchTab(t("common:my_requests"))} style={[styles.sideBtn,selectedRequest !==t("common:my_requests") &&{border:'none'}]}><Text style={[styles.sideBtnText,selectedRequest === t("common:my_requests") &&{color:'#FF4747'}]}>{t("common:my_requests")}</Text></TouchableOpacity>
          </View>

          <View style={styles.tabContainer}>
            {children}
          </View>

        </View>
      </View>
    </SafeAreaView>
  );
};

export default SideBarConatainer;

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    marginVertical:25,
    flexDirection: "row",
    justifyContent: "center",
  },
  innerContainer: {
    flex: 1,
    flexDirection:"row",
    maxWidth: 1400,
    gap:30
  },
  reqSideBar:{
    flex:1,
    backgroundColor:'white',
    borderRadius:16,
    paddingLeft:20,
    paddingVertical:30,
    maxWidth:279,
    minWidth:80
  },
  container: {
    marginTop: 32,
    marginHorizontal: 24,
  },
  requestsText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 22,
    color: "#101820",
    alignSelf: "flex-start",
    marginBottom:20
  },
  tabContainer: {
    flex: 1,
  },
  sideBtn:{
    paddingVertical:20,
    borderRightWidth:2,
    borderRightColor:'#FF4747'

  },
  sideBtnText:{
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: 18,
    lineHeight: 18,
    alignSelf:"flex-start"
  }
});
