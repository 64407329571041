import {
  StyleSheet,
  Text,
  TouchableOpacity,
  Image,
  ScrollView,
  TextInput,
  I18nManager,
  View,
  Dimensions,
  KeyboardAvoidingView,
  Platform,
  Modal,
  Pressable,
} from "react-native";
import React, { useState, useEffect, useRef } from "react";
import Icon from "react-native-vector-icons/Feather";
import Calendar from "../../assets/image/calendar.png";
import { RadioButton } from "react-native-paper";
import Money from "../../assets/image/money.png";
import { Picker } from "@react-native-picker/picker";
import { useTranslation } from "react-i18next";
import { SafeAreaView } from "react-native-safe-area-context";
import { launchCamera, launchImageLibrary } from "react-native-image-picker";
import { useSelector, useDispatch } from "react-redux";
import { getCurrencies } from "../../Redux/Models/Currencies/Actions";
import ToastAndroid from "react-native-root-toast";
import {
  uploadDepositSlip,
  resetUploadDepositSlip,
  ClearFilesOnDepositSlip,
} from "../../Redux/Models/DocumentUpload/BankDepositSlip/Actions";
import {
  requestBankTopUp,
  changeTopUpstatus,
} from "../../Redux/Models/RequestBankTopUp/Actions";
import { DateFormate } from "../../Utils/HelperFunctions";
import { BarCodeScanner } from "expo-barcode-scanner";
import Loading from "../Loading";
import DateTimePickerModal from "react-native-modal-datetime-picker";
import Alert from "../../Components/Alert";
import WebDatePicker from "../../Components/DatePickerTest";
import SideBarConatainer from "../../Components/SideBarConatainer.web";
import { v4 as uuidv4 } from 'uuid';
import StepContainer from "../../Components/StepContainer";



const BankDetail = ({ navigation, route }) => {
  const { t } = useTranslation();
  const [currency, setCurrency] = useState("");
  const [hasPermission, setHasPermission] = useState(null);
  const [method, setMethod] = useState("Cheque");
  const [amount, setAmount] = useState("");
  const [slipNumber, setSlipNumber] = useState("");
  const [date, setDate] = useState();
  const [visible, setVisible] = useState(false);
  const [uploadedFile, setUploadedFile] = useState("");
  const [isDatePickerVisible, setDatePickerVisibility] = useState(false);
  const [show, setShow] = useState(true);
  const showDatePicker = () => {
    setDatePickerVisibility(true);
    if (Platform.OS === "web") {
      setShow(false);
    }
  };
  const hideDatePicker = () => {
    setDatePickerVisibility(false);
  };
  const dispatch = useDispatch();

  const currencies = useSelector((state) => state.currencies);
  const userDetails = useSelector((state) => state.userDetails.data.user);
  // console.log("userDetails", userDetails.accounts);
  const [wallet, setWallet] = useState(userDetails.accounts[0]);

  const user = useSelector((state) => state.user);
  const bankDepositSlip = useSelector((state) => state.bankDepositSlip);
  const requestBankTopUpdata = useSelector((state) => state.requestBankTopUp);
  const { bankName } = route.params;

  console.log("Bank", bankName);

  const validate = () => {
    if (amount === 0) {
      ToastAndroid.show(t("common:Please_enter_amount"), ToastAndroid.LONG);
      return false;
    } else if (date === undefined) {
      ToastAndroid.show(
        t("common:please_select_deposite_date"),
        ToastAndroid.LONG
      );
      return false;
    } else if (uploadedFile === "") {
      ToastAndroid.show(
        t("common:Please_attach_Deposit_Slip"),
        ToastAndroid.LONG
      );
      return false;
    }
    return true;
  };
  const dateRef = useRef();
  const handleClickOutside = (event) => {
    let date = dateRef.current.contains(event.target);
    if (date === false && Platform.OS === "web") {
      setDatePickerVisibility(false);
      setShow(true);
    }
  };
  React.useEffect(() => {
    if (Platform.OS === "web") {
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, []);
  const OnDateChange = (selectedDate) => {
    const currentDate = selectedDate;
    setDate(currentDate);
    setDatePickerVisibility(false);
    if (Platform.OS === "web") {
      setShow(true);
    }
  };
  useEffect(() => {
    dispatch(getCurrencies());
    getBarCodeScannerPermissions();
    dispatch(ClearFilesOnDepositSlip());
  }, []);
  const getBarCodeScannerPermissions = async () => {
    const { status } = await BarCodeScanner.requestPermissionsAsync();
    setHasPermission(status === "granted");
  };

  const uploadDoc = () => {
    setVisible(true);
  };
  const takePhoto = () => {
    let formData = new FormData();
    setVisible(false);

    if (hasPermission === true) {
      launchCamera({
        cameraType: "back",
        mediaType: "photo",
      })
        .then((val) => {
          console.log(val);
          formData.append("file", {
            type: val.assets[0].type,
            uri: val.assets[0].uri,
            name: val.assets[0].fileName,
          });
          dispatch(uploadDepositSlip(formData));
        })
        .catch((err) => console.log(err));
    } else {
      getBarCodeScannerPermissions();
    }
  };
  const choosePhoto = () => {
    setVisible(false);
    let formData = new FormData();
    launchImageLibrary({ mediaType: "photo" })
      .then(async (val) => {
        console.log("val", val);
        let res = await fetch(val.assets[0].uri)
        let buffar = await res.arrayBuffer()
        let file = new File([buffar], uuidv4(), { type: val.assets[0].type })
        formData.append("file", file);
        dispatch(uploadDepositSlip(formData));
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    bankDepositSlip.error === true && alert(bankDepositSlip.message);
    console.log("bankDepositSlip", bankDepositSlip);
    if (bankDepositSlip.success === true) {
      setUploadedFile(bankDepositSlip.data);
    }
  }, [bankDepositSlip]);

  useEffect(() => {
    currencies.success && setCurrency(currencies.data.currencies[0].id);
  }, [currencies]);
  useEffect(() => {
    if (requestBankTopUpdata.error === true) {
      return Alert("Error", requestBankTopUpdata.message, [
        {
          text: "OK",
          onPress: () => {
            dispatch(changeTopUpstatus());
          },
        },
      ]);
    }
    if (requestBankTopUpdata.success === true) {
      return Alert("Congratulation", requestBankTopUpdata.message, [
        {
          text: "OK",
          onPress: () => {
            dispatch(changeTopUpstatus());
            dispatch(resetUploadDepositSlip());
            navigation.navigate("RequestTopUp");
          },
        },
      ]);
    }
  }, [requestBankTopUpdata]);

  const handleCancel = () => {
    setVisible(false);
  };
  const bankTopUp = () => {
    if (!validate()) {
      return;
    }
    let formData = new FormData();
    let d = new Date();
    let financial_request = {
      ...bankName,
      amount: amount,
      receiver_account_id: Number(wallet.id),
      is_exchange: 0,
      from_currency_id: Number(currency),
      wallet: wallet.name,
      top_up_method: method,
      amount_depos_currency: amount + wallet.currency.currency_iso_code,
      depos_date: Math.floor(date.getTime() / 1000),
      depos_slip_no: "",
    };
    for (let [key, value] of Object.entries(financial_request)) {
      formData.append("financial_request[" + key + "]", value);
    }

    for (let [key, value] of Object.entries(uploadedFile)) {
      formData.append("doc_files[0][" + key + "]", value);
    }
    formData.append("request_date", Math.ceil(d.getTime()));
    formData.append("description", "");
    dispatch(requestBankTopUp(formData));
  };
  return (
    <SideBarConatainer navigation={navigation}>
      <Modal transparent={true} visible={visible}>
        <Pressable onPress={handleCancel} style={styles.innerDialog}>
          <View style={styles.dialogContainer}>
            <View style={styles.textContainer}>
              <Text style={styles.source}>{t("common:select_source")}</Text>
              {/* <TouchableOpacity onPress={takePhoto}>
                <Text style={styles.dialogText}>
                  {t("common:take_a_photo")}
                </Text>
              </TouchableOpacity> */}
              <TouchableOpacity onPress={choosePhoto}>
                <Text style={styles.dialogText}>
                  {t("common:choose_a_photo_from_the_gallery")}
                </Text>
              </TouchableOpacity>
              <TouchableOpacity onPress={handleCancel}>
                <Text style={styles.cancelText}>{t("common:cancel")}</Text>
              </TouchableOpacity>
            </View>
          </View>
        </Pressable>
      </Modal>
      <View style={styles.headerView}>
        <Icon
          onPress={() => navigation.goBack()}
          name="arrow-left"
          size={30}
        />
        <Text style={styles.bankText}>{t("common:bank_top_up")}</Text>
      </View>
      <ScrollView showsHorizontalScrollIndicator={false}>
        <View style={styles.addBankContainer}>
          <StepContainer totalStep={1} currentStep={2} />
          <Text style={styles.descriptionText}>{t("common:MakeDeposit")}</Text>
          <View style={styles.usdView}>
            <Image source={Money} style={styles.moneyImage} />
            <Picker
              mode="dropdown"
              selectedValue={wallet}
              onValueChange={(selectedValue) => setWallet(selectedValue)}
              style={styles.pickerItem}
            >
              {userDetails.accounts?.map((val) => {
                return (
                  <Picker.Item
                    key={val.id}
                    label={val.name}
                    value={val}
                    style={styles.pickerItemText}
                  />
                );
              })}
            </Picker>
          </View>
          <Text style={styles.nameText}>{t("common:amount")}</Text>
          <View style={styles.sendView}>
            <TextInput
              textAlign={I18nManager.isRTL ? "right" : "left"}
              defaultValue={amount}
              onChangeText={(text) => setAmount(Number(text))}
              keyboardType="number-pad"
              returnKeyType="done"
              style={styles.sendInput}
            ></TextInput>
            <View style={styles.pickerView}>
              <Image source={Money} style={styles.lydImage} />
              <Picker
                mode="dropdown"
                selectedValue={currency}
                onValueChange={(selectedValue) => setCurrency(selectedValue)}
                style={styles.pickerText}
              >
                {currencies.success === true &&
                  currencies.data.currencies?.map((val) => {
                    return (
                      <Picker.Item
                        key={val.id}
                        label={val.currency_iso_code}
                        value={val.id}
                        style={styles.pickerItemText}
                      />
                    );
                  })}
              </Picker>
            </View>
          </View>
          <View style={{ flexDirection: "row", marginTop: 10, zIndex: 2000 }}>
            <View style={styles.methodView}>
              <Text style={styles.nameText}>{t("common:method")}</Text>

              <Picker
                mode="dropdown"
                selectedValue={method}
                onValueChange={(selectedValue) => setMethod(selectedValue)}
                style={styles.pickerText}
              >
                <Picker.Item
                  label="Cheque"
                  value="Cheque"
                  style={styles.pickerItemText}
                />
              </Picker>
            </View>
            <View style={{ flex: 1, marginLeft: 20, zIndex: 2000 }}>
              <Text style={styles.nameText}>{t("common:deposite_date")}</Text>
              <View style={styles.dateView}>
                <Text style={styles.dateText}>{date && DateFormate(date)}</Text>
                <TouchableOpacity
                  onPress={showDatePicker}
                  style={styles.dateButton}
                >
                  {show && (
                    <Image source={Calendar} style={styles.calendarImage} />
                  )}
                </TouchableOpacity>
                <View
                  style={
                    isDatePickerVisible
                      ? { display: "flex" }
                      : { display: "none" }
                  }
                  ref={dateRef}
                >
                  {Platform.OS === "web" && (
                    <WebDatePicker
                      isDatePickerVisible={isDatePickerVisible}
                      date={date}
                      OnDateChange={OnDateChange}
                    />
                  )}
                </View>
                <DateTimePickerModal
                  isVisible={isDatePickerVisible}
                  mode="date"
                  onConfirm={OnDateChange}
                  onCancel={hideDatePicker}
                />
              </View>
            </View>
          </View>
          <View style={styles.depositView}>
            <Text style={styles.depositText}>
              {t("common:deposit_slip")}
              <Text style={{ color: "#FF4747" }}> *</Text>{" "}
              {uploadedFile.origin_name?.length > 20
                ? uploadedFile.origin_name.substring(0, 20)
                : uploadedFile.origin_name}
            </Text>

            <TouchableOpacity onPress={uploadDoc} style={styles.uploadButton}>
              <Text style={styles.uploadText}>{t("common:upload")}</Text>
            </TouchableOpacity>
          </View>

          <Text style={styles.nameText}>
            {t("common:deposit_slip_no")}
            <Text style={{ color: "#FF4747" }}> *</Text>{" "}
          </Text>

          <TextInput
            textAlign={I18nManager.isRTL ? "right" : "left"}
            defaultValue={slipNumber}
            onChangeText={(text) => setSlipNumber(Number(text))}
            keyboardType="number-pad"
            returnKeyType="done"
            style={styles.slipInput}
          ></TextInput>
          <View style={styles.btnView}>
            <TouchableOpacity onPress={bankTopUp} style={styles.submitButton}>
              <Text style={styles.submitText}>
                {t("common:SUBMIT")}
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      </ScrollView>
      {(currencies.loading ||
        bankDepositSlip.loading ||
        requestBankTopUpdata.loading) && <Loading></Loading>}
    </SideBarConatainer>
  );
};

export default BankDetail;

const styles = StyleSheet.create({
  innerDialog: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#000000aa",
  },
  dialogContainer: {
    backgroundColor: "#FFFFFF",
    borderRadius: 10,
    marginHorizontal: 24,
    justifyContent: "center",
    alignSelf: "center",
  },
  textContainer: {
    marginHorizontal: 12,
    padding: 10,
    margin: 10,
  },
  source: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 18,
    color: "#101820",
    alignSelf: "flex-start",
  },
  dialogText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 16,
    color: "#101820",
    marginTop: 6,
    alignSelf: "flex-start",
  },
  cancelText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 16,
    color: "#101820",
    marginTop: 20,
    textAlign: "right",
    alignSelf: "flex-end",
  },
  headerView: {
    flexDirection: "row",
    alignItems: "center",
  },
  arrowIcon: {
    fontSize: 25,
    color: "#101820",
  },
  bankText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 26,
    color: "#000000",
    marginLeft: 10,
  },
  addBankContainer: {
    backgroundColor: "#ffffff",
    marginTop: 27,
    borderRadius: 16,
    padding: 20,
  },
  descriptionText: {
    marginTop: 16,
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 14,
    color: "#101820",
    alignSelf: "flex-start",
    marginTop: 30
  },
  usdView: {
    borderRadius: 10,
    borderWidth: 1,
    borderColor: "#E0E0E0",
    flexDirection: "row",
    alignItems: "center",
    height: 56,
    marginTop: 20,
  },
  moneyImage: {
    height: 24,
    width: 24,
    marginLeft: 16,
  },
  numberText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 14,
    color: "#101820",
    marginRight: 10,
    alignSelf: "flex-start",
  },
  pickerItem: {
    flex: 1,
    height: 45,
    borderWidth: 1,
    borderColor: "white",
    borderStyle: "solid",
  },
  downArrowImage: {
    marginRight: 16,
  },
  sendView: {
    flexDirection: "row",
  },
  sendInput: {
    flex: 1,
    borderBottomWidth: 1,
    borderColor: "#F1F1F1",
    fontFamily: "Inter",
    fontSize: 14,
    fontWeight: "500",
    outlineStyle: "none",
  },
  slipInput: {
    borderBottomWidth: 1,
    borderColor: "#F1F1F1",
    fontFamily: "Inter",
    fontSize: 14,
    fontWeight: "500",
    height: 56,
    outlineStyle: "none",
  },
  btnView: {
    alignItems: "flex-end",
    marginTop: 30,
    marginBottom: 15,
    flex: 1
  },
  submitButton: {
    backgroundColor: "#FF4747",
    borderRadius: 70,
    justifyContent: "center",
    alignItems: "center",
    padding: 16,
    alignSelf: "flex-end",
    width: 327,
  },
  submitText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 16,
    alignItems: "center",
    textAlign: "center",
    color: "#FFFFFF",
  },
  pickerView: {
    flex: 1,
    borderBottomWidth: 1,
    borderColor: "#F1F1F1",
    marginLeft: 20,
    flexDirection: "row",
    alignItems: "center",
  },
  lydImage: {
    height: 24,
    width: 24,
  },
  pickerText: {
    flex: 1,
    height: 56,
    borderWidth: 1,
    borderColor: "white",
    borderStyle: "solid",
  },
  pickerItemText: {
    color: "#101820",
    fontSize: 14,
    fontWeight: "500",
    fontFamily: "Inter",
  },
  nameText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 14,
    color: "#A9ABAE",
    marginTop: 10,
    alignSelf: "flex-start",
  },
  methodView: {
    borderBottomWidth: 1,
    borderColor: "#F1F1F1",
    justifyContent: "center",
    flex: 1,
  },
  dateView: {
    borderBottomWidth: 1,
    borderColor: "#F1F1F1",
    height: 56,
    flexDirection: "row",
    justifyContent: "space-between",
    zIndex: 2000,
  },
  dateButton: {
    marginTop: 20,
  },
  calendarImage: {
    height: 18,
    width: 16.5,
    marginRight: 10,
    resizeMode: "contain"
  },
  dateText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 14,
    color: "#101820",
    marginTop: 15,
  },
  depositView: {
    borderBottomWidth: 1,
    borderColor: "#F1F1F1",
    height: 56,
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 15,
  },
  depositText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 14,
    color: "#A9ABAE",
    marginTop: 15,
  },
  uploadText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 14,
    textAlign: "right",
    color: "#FF4747",
    marginTop: 15,
  },
});
