import {
  GET_MY_REQUESTS_REQUEST,
  GET_MY_REQUESTS_REQUEST_SUCCESS,
  GET_MY_REQUESTS_REQUEST_FAILURE,
  SET_REQUESTS_TAB
} from "./Actions";

const initialState = {
  loading: null,
  message: null,
  error: null,
  data: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_MY_REQUESTS_REQUEST: {
      console.log(GET_MY_REQUESTS_REQUEST);
      return {
        ...state,
        error: false,
        loading: true,
        phoneNumber: action.parm,
      };
    }
    case GET_MY_REQUESTS_REQUEST_SUCCESS: {
      const { message, data } = action.payload;
      console.log(GET_MY_REQUESTS_REQUEST_SUCCESS);
      return {
        ...state,
        loading: false,
        data,
        message,
      };
    }
    case GET_MY_REQUESTS_REQUEST_FAILURE: {
      console.log(GET_MY_REQUESTS_REQUEST_FAILURE);

      const { message } = action.payload;
      return {
        ...state,
        loading: false,
        error: true,
        message,
      };
    }
    case SET_REQUESTS_TAB:{
      const  tab  = action.tab;
      return {
        ...state,
        tab
        
      };
    }

    default:
      return state;
  }
};

export { reducer };
