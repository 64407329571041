import { all } from "redux-saga/effects";
import { handler as checkPhoneNumberSaga } from "./CheckPhoneNumber/Saga";
import { handler as userSaga } from "./User/Login/Saga";
import { handler as VerifyPassCode } from "./User/VerifyPassCode/Saga";
import { handler as userRegisterSaga } from "./User/Register/Saga";
import { handler as userDetailsSaga } from "./User/UserDetails/Saga";
import { handler as forgetPasswordSage } from "./User/ForgetPassword/Saga";
import { handler as newPasswordSage } from "./User/NewPassword/Saga";
import { handler as currenciesSaga } from "./Currencies/Saga";
import { handler as tizdanBankAccountsSaga } from "./TizdanBankAccounts/Saga";
import { handler as documentUploadSaga } from "./DocumentUpload/Saga";
import { handler as requestBankTopUpSaga } from "./RequestBankTopUp/Saga";
import { handler as myFamilyListSaga } from "./User/MyFamily/MyFamilyList/Saga";
import { handler as addFamilyMemberSaga } from "./User/MyFamily/AddFamilyMember/Saga";
import { handler as editFamilyMemberSaga } from "./User/MyFamily/EditFamilyMember/Saga";
import { handler as deleteFamilyMemberSaga } from "./User/MyFamily/DeleteMember/Saga";
import { handler as changeMyFamilyPasswordSaga } from "./User/MyFamily/ChangePassword/Saga";
import { handler as myRequestSaga } from "./MyRequests/Saga";
import { handler as beneficiariesSaga } from "./Beneficiaries/Saga";
import { handler as requestKycSaga } from "./RequestKyc/Saga";
import { handler as countryListSaga } from "./Countries/Saga";
import { handler as requestTransferSaga } from "./RequestTransfer/Saga";
import { handler as giftCardCompanyListSaga } from "./GiftCardCompanyList/Saga";
import { handler as giftCardBuyListSaga } from "./GiftCardBuyList/Saga";
import { handler as serviceVoucherCompanyListSaga } from "./ServiceVoucherCompanyList/Saga";
import { handler as serviceVoucherBuyListSaga } from "./ServiceVoucherBuyList/Saga";
import { handler as requestCblSaga } from "./RequestCbl/Saga";
import { handler as cityListSaga } from "./Cities/Saga";
import { handler as buyGiftCardSaga } from "./BuyGiftCard/Saga";
import { handler as buyServiceVoucherSaga } from "./BuyServiceVoucher/Saga";
import { handler as verifyDocumentSaga } from "./VerifyDocuments/Saga";
import { handler as familyMemberTopUpSaga } from "./User/MyFamily/FamilyMemberTopUp/Saga";
import { handler as cblVerifyDocumentSaga } from "./CblVerifyDocuments/Saga";
import { handler as userWalletSaga } from "./UserWallet/Saga";
import { handler as deleteFileSaga } from "./DeleteDocument/Saga";
import { handler as bankDepositSlipSaga } from "./DocumentUpload/BankDepositSlip/Saga";
import { handler as cblDocumentsSaga } from "./DocumentUpload/CBLDocuments/Saga";
import { handler as familyMemberTransactionsSaga } from "./User/MyFamily/FamilyMemberTransactions/Saga";
import { handler as requestDetailsSaga } from "./RequestDetails/Saga";
import { handler as createCommentsRequestSaga } from "./Comments/RequestAddComment/Saga";
import { handler as getRequestCommentListSaga } from "./Comments/RequestListComment/Saga";
import { handler as requestUpdateSaga } from "./RequestUpdate/Saga";
import { handler as topBankUpdateSaga } from "./TopBankUpdate/Saga";
import { handler as cblRequestUpdateSaga } from "./CblRequestUpdate/Saga";
import { handler as cblNumberSaga } from "./CblNumber/Saga";
import { handler as requestExchangeSaga } from "./ExchangeMoney/Saga";
import { handler as changePinCodeSaga } from "./User/ChangePinCode/Saga";
import { handler as mdpCreateCardSaga } from "./MdpCards/MdpCreateCardRequest/Saga";
import { handler as mdpCardsListSaga } from "./MdpCards/MdpCardsList/Saga";
import { handler as mdpCardsKycStatusSaga } from "./MdpCards/MdpCardsKycStatus/Saga";
import { handler as mdpCardsCustomerStatusSaga } from "./MdpCards/MdpCardsCustomerStatus/Saga";
import { handler as mdpCardsKycUpdateSaga } from "./MdpCards/MdpCardsKycUpdate/Saga";
import { handler as mdpCardsSetPinSaga } from "./MdpCards/MdpCardsSetPin/Saga";
import { handler as mdpCardsChangeBalanceSaga } from "./MdpCards/MdpCardChangeBalance/Saga";
import { handler as mdpCardsBalanceSaga } from "./MdpCards/MdpCardCurrentBalance/Saga";
import { handler as mdpCardsLookupProductsSaga } from "./MdpCards/MdpCardsLookupProducts/Saga";
import { handler as mdpCardsLookupIdTypesSaga } from "./MdpCards/MdpCardsLookupIdTypes/Saga";
import { handler as mdpCardsLookupLanguagesSaga } from "./MdpCards/MdpCardsLookupLanguages/Saga";
import { handler as mdpCardsLookupCountriesSaga } from "./MdpCards/MdpCardsLookupCountries/Saga";
import { handler as mdpCardsLookupNationalitiesSaga } from "./MdpCards/MdpCardsLookupNationalities/Saga";

export default function* rootSaga() {
  yield all([
    checkPhoneNumberSaga(),
    userSaga(),
    VerifyPassCode(),
    userRegisterSaga(),
    userDetailsSaga(),
    forgetPasswordSage(),
    newPasswordSage(),
    currenciesSaga(),
    tizdanBankAccountsSaga(),
    documentUploadSaga(),
    requestBankTopUpSaga(),
    myFamilyListSaga(),
    addFamilyMemberSaga(),
    editFamilyMemberSaga(),
    deleteFamilyMemberSaga(),
    myRequestSaga(),
    beneficiariesSaga(),
    requestKycSaga(),
    countryListSaga(),
    requestTransferSaga(),
    giftCardCompanyListSaga(),
    giftCardBuyListSaga(),
    serviceVoucherCompanyListSaga(),
    serviceVoucherBuyListSaga(),
    changeMyFamilyPasswordSaga(),
    requestCblSaga(),
    cityListSaga(),
    buyGiftCardSaga(),
    buyServiceVoucherSaga(),
    verifyDocumentSaga(),
    familyMemberTopUpSaga(),
    cblVerifyDocumentSaga(),
    userWalletSaga(),
    deleteFileSaga(),
    bankDepositSlipSaga(),
    cblDocumentsSaga(),
    familyMemberTransactionsSaga(),
    requestDetailsSaga(),
    createCommentsRequestSaga(),
    getRequestCommentListSaga(),
    requestUpdateSaga(),
    topBankUpdateSaga(),
    cblRequestUpdateSaga(),
    cblNumberSaga(),
    requestExchangeSaga(),
    changePinCodeSaga(),
    mdpCreateCardSaga(),
    mdpCardsListSaga(),
    mdpCardsKycStatusSaga(),
    mdpCardsCustomerStatusSaga(),
    mdpCardsKycUpdateSaga(),
    mdpCardsSetPinSaga(),
    mdpCardsChangeBalanceSaga(),
    mdpCardsBalanceSaga(),
    mdpCardsLookupProductsSaga(),
    mdpCardsLookupIdTypesSaga(),
    mdpCardsLookupLanguagesSaga(),
    mdpCardsLookupCountriesSaga(),
    mdpCardsLookupNationalitiesSaga(),
  ]);
}
