const GET_VERIFY_DOCUMENT_REQUEST = "GET_VERIFY_DOCUMENT_REQUEST";
const GET_VERIFY_DOCUMENT_REQUEST_SUCCESS = "GET_VERIFY_DOCUMENT_REQUEST_SUCCESS";
const GET_VERIFY_DOCUMENT_REQUEST_FAILURE = "GET_VERIFY_DOCUMENT_REQUEST_FAILURE";

const verifyDocument = () => {
  return {
    type: GET_VERIFY_DOCUMENT_REQUEST,
    
  };
};

export {
  verifyDocument,
  GET_VERIFY_DOCUMENT_REQUEST,
  GET_VERIFY_DOCUMENT_REQUEST_SUCCESS,
  GET_VERIFY_DOCUMENT_REQUEST_FAILURE,
};
