import {
  StyleSheet,
  Text,
  View,
  Dimensions,
  ScrollView,
  TouchableOpacity,
  SafeAreaView,
  Modal,
  Pressable,
} from "react-native";
import ToastAndroid from "react-native-root-toast";
import React, { useState, useEffect } from "react";
import Icon from "react-native-vector-icons/Feather";
import CrossIcon from "react-native-vector-icons/AntDesign";
import { useTranslation } from "react-i18next";
import DocumentForm from "./EditCblDocumentForm";
import Loading from "../../Loading";
import Dialog from "react-native-dialog";

import { useDispatch, useSelector } from "react-redux";
const height = Dimensions.get("window").height;
import { resetUploadCBLFilestate } from "../../../Redux/Models/DocumentUpload/CBLDocuments/Actions";
import { cblUpdateRequest, resetCblRequestUpdate} from "../../../Redux/Models/CblRequestUpdate/Actions";
import Alert from "../../../Components/Alert";
import SideBarConatainer from "../../../Components/SideBarConatainer"


const Documents = ({ navigation, route }) => {
  const [visible, setVisible] = useState(false);
  const [isCbl, setIsCbl] = useState(0);
  const verifyDocuments = route.params?.requestDocs.docs
  const requestDocs = route.params?.requestDocs

  const documentUpload = useSelector((state) => state.cblDocuments);

 // const verifyDocuments = useSelector((state) => state.verifyDocument);
  const deleteFile = useSelector((state) => state.deleteFile);
  const userDetails = useSelector((state) => state.userDetails.data.user);
  const cblRequestUpdate = useSelector((state) => state.cblRequestUpdate);

  const [allDocs, setAllDocs] = useState({});

  const { t } = useTranslation();

  const dispatch = useDispatch();
const validate = () => {
  for (let [ind, val] of verifyDocuments.entries()) {
    if (allDocs[ind].id_number === "") {
      ToastAndroid.show(t("common:Please_enter_ID"), ToastAndroid.LONG);
      console.log(allDocs[ind].doc_files)
      return false;
    }else if (allDocs[ind].place_issue === "") {
      ToastAndroid.show(t("common:please_enter_the_place_of_issue"), ToastAndroid.LONG);
      return false;
    }  else if (allDocs[ind].date_issue === undefined) {
      ToastAndroid.show(t("common:Please_select_date_of_issue"), ToastAndroid.LONG);
      return false;
    } else if (allDocs[ind].expiry_date === undefined) {
      ToastAndroid.show(t("common:Please_select_expiration_date"), ToastAndroid.LONG);
      return false;
      } else if (!allDocs[ind].doc_files?.length > 0) {
        ToastAndroid.show(t("common:Please_select_identification_document_type"), ToastAndroid.LONG);
      return false;
    }
  }
  return true;
};
  // useEffect(() => {
  //   dispatch(verifyDocument());
  // }, []);
  useEffect(() => {
    if (documentUpload.error === true) {
      dispatch(resetUploadCBLFilestate());
      alert(documentUpload.message);
    }
  }, [documentUpload]);
  useEffect(() => {
    //console.log(allDocs)
  }, [allDocs]);
  const KycRequestApi = () => {
    if (!validate()) {
      return;
    }
    let addToForm = new FormData();
  
    for (let [mainkey, values] of Object.entries(allDocs)) {
      for (let [key, value] of Object.entries(values)) {
        if (key !== "doc_files") {
          addToForm.append("docs[" + mainkey + "][" + key + "]", value);
        } else {
          value.map((val, ind) => {
            for (let [key, value] of Object.entries(val)) {
              key !== 'doc_id' && addToForm.append(
                "docs[" + mainkey + "][doc_files][" + ind + "][" + key + "]",
                value
              );
            }
          });
        }
      }
    }
    let tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    addToForm.append(
      "target_completion_date",
      Math.ceil(tomorrow.getTime() / 1000)
    );
    addToForm.append("request_date", Math.ceil(new Date().getTime() / 1000));
    addToForm.append("priority", "High");
    dispatch(cblUpdateRequest(requestDocs.id, addToForm));
  };
  useEffect(() => {
    if (cblRequestUpdate.error === true) {
      return Alert("Error", cblRequestUpdate.message, [
        {
          text: "OK",
          onPress: () => {
            dispatch(resetCblRequestUpdate());
          },
        }, 
      ]);
    }
    if (cblRequestUpdate.success === true) {
      return Alert("Congratulation", cblRequestUpdate.message, [
        {
          text: "OK",
          onPress: () => {
            dispatch(resetCblRequestUpdate());
            navigation.navigate("CblRequestDetail", { request: requestDocs });
          },
        },
      ]);
    }
  }, [cblRequestUpdate]);
  const handleCancel = () => {
    setVisible(false);
  };
 
  return (
    <SideBarConatainer navigation={navigation}>
      <Modal transparent={true} visible={visible}>
        <Pressable onPress={() => setVisible(false)} style={styles.innerDialog}>
          <View style={styles.dialogContainer}>
          <View style={styles.textContainer}>
          <TouchableOpacity style={styles.crossBtn} onPress={handleCancel}>
            <CrossIcon name="close" size={24} />
          </TouchableOpacity>
        </View>
        <Text style={styles.profileText}>
          {t("common:Would_apply_for_CBL_Number")}
        </Text>
        <View style={styles.btnContainer}>
          <TouchableOpacity onPress={() => IsCblDialog(0)} style={styles.btnNo}>
            <Text style={styles.btnNoText}>{t("common:no_later")}</Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => IsCblDialog(1)}
            style={styles.btnYes}
          >
            <Text style={styles.btnYesText}>{t("common:Yes")}</Text>
          </TouchableOpacity>
        </View>
          </View>
        </Pressable>
      </Modal>
      <View style={styles.headerView}>
        <Icon
          name="arrow-left"
          size={30}
          style={styles.arrowIcon}
          onPress={() => navigation.goBack()}
        />
        <Text style={styles.kycText}>{t("common:cbl_request")}</Text>
      </View>
      <View style={{ flex: 1 }}>
        <ScrollView contentContainerStyle={{ flexGrow: 1 }} showsHorizontalScrollIndicator={false}>
          <View style={styles.mainContainer}>
        <View style={styles.innerContainer}>
          <Text style={styles.personalInfoText}>
            {t("common:attach_the_documents")}
          </Text>
          {verifyDocuments.map((val, ind) => (
            <DocumentForm
              setAllDocs={setAllDocs}
              key={ind}
              doc={ind}
              docData={val}
            />
          ))}
          <View style={styles.btnView}>
            <TouchableOpacity onPress={KycRequestApi} style={styles.button}>
              <Text style={styles.buttonText}>
                    {t("common:update")}
              </Text>
            </TouchableOpacity>
          </View>
        </View>
          </View>
      </ScrollView>
      </View>

      {(
        documentUpload.loading ||
        deleteFile.loading ||
        cblRequestUpdate.loading) && <Loading></Loading>}
    </SideBarConatainer>
  );
};

export default Documents;

const styles = StyleSheet.create({
  innerDialog: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#000000aa",
  },
  dialogContainer: {
    backgroundColor: "#FFFFFF",
    borderRadius: 20,
    padding:20,
    marginHorizontal: 24,
    justifyContent: 'center',
    alignSelf: "center",
  },
  textContainer: {
    alignSelf: "flex-end",
  },
  profileText: {
    textAlign: "center",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 20,
    color: "#101820",
    marginTop: 10,
    alignSelf: "flex-start",
    
  },
  btnContainer: {
    marginTop: 20,
    flexDirection: "row",
    justifyContent: "space-around",
  },
  btnNo: {
    borderRadius: 70,
    borderColor: "#101820",
    borderWidth: 1,
    height: 56,
    width: 130,
    justifyContent: "center",
  },
  btnNoText: {
    fontFamily: "Inter",
    fontSize: 16,
    fontWeight: "700",
    color: "#101820",
    textAlign: "center",
    alignItems: "center",
  },
  btnYes: {
    borderRadius: 70,
    backgroundColor: "#FF4747",
    height: 56,
    width: 130,
    justifyContent: "center",
  },
  btnYesText: {
    fontFamily: "Inter",
    fontSize: 16,
    fontWeight: "700",
    color: "#FFFFFF",
    textAlign: "center",
    alignItems: "center",
  },
  headerView: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom:27
  },
  kycText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 26,
    alignSelf: "flex-start",
    color: "#000000",
    marginLeft: 10
  },
  mainContainer: {
    backgroundColor: "#ffffff",
    borderRadius: 16,
    padding: 20,
  },
  innerContainer: {
    flex: 1,
    marginLeft: 24,
    marginRight: 24,
  },
  personalInfoText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 18,
    alignSelf: "flex-start",
    color: "#000000",
    marginTop: 20,
  },
  btnView: {
    flex: 1,
    alignItems: "flex-end",
    justifyContent: "flex-end",
  },
  button: {
    padding: 16,
    backgroundColor: "#FF4747",
    borderRadius: 70,
    width: 327
  },
  buttonText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 16,
    alignItems: "center",
    textAlign: "center",
    color: "#FFFFFF",
  },
});
