import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  TextInput,
I18nManager,
  TouchableOpacity,
  Dimensions,
  SafeAreaView,
} from "react-native";
import React, { useState } from "react";
import Icon from "react-native-vector-icons/Feather";
import { useTranslation } from "react-i18next";
import { Picker } from "@react-native-picker/picker";
const height = Dimensions.get("window").height;

const LetterCreditStep1 = ({ navigation }) => {
  const { t } = useTranslation();
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [nameText, setNameText] = useState("");
  const [addressText, setAddressText] = useState("");
  const [bankText, setBankText] = useState("");
  const [branchText, setBranchText] = useState("");
  const [accountText, setAccountText] = useState("");
  const [codeText, setCodeText] = useState("");
  return (
    <SafeAreaView style={styles.mainContainer}>
      <View style={styles.headerView}>
        <Icon
          name="arrow-left"
          style={styles.arrowIcon}
          onPress={() => navigation.goBack()}
        />
        <Text style={styles.cardText}>{t("common:letter_of_credit")}</Text>
      </View>
      <View style={styles.stepView}>
        <View style={styles.step1}></View>
        <View style={styles.steps}></View>
        <View style={styles.steps}></View>
        <View style={styles.steps}></View>
        <View style={styles.steps}></View>
      </View>
      <ScrollView>
        <View style={styles.innerContainer}>
          <Text style={styles.companyText}>{t("common:company_details")}</Text>
          <Text style={styles.nameText}>
            {t("common:c_name")}
            <Text style={{ color: "#FF4747" }}>*</Text>
          </Text>
          <TextInput 
 textAlign={I18nManager.isRTL ? "right" :"left"} 
            defaultValue={nameText}
            onChangeText={(newText) => setNameText(newText)}
            style={styles.nameInput}
          ></TextInput>

          <Text style={styles.addressText}>
            {t("common:company_address")}
            <Text style={{ color: "#FF4747" }}>*</Text>
          </Text>
          <TextInput 
 textAlign={I18nManager.isRTL ? "right" :"left"} 
            defaultValue={addressText}
            onChangeText={(newText) => setAddressText(newText)}
            style={styles.addressInput}
          ></TextInput>

          <Text style={styles.countryText}>
            {t("common:country_st")}
            <Text style={{ color: "#FF4747" }}>*</Text>
          </Text>
          <View style={styles.pickerView}>
            <Picker
              mode="dropdown"
              selectedValue={country}
              onValueChange={(selectedValue) => setCountry(selectedValue)}
              style={styles.methodPickerText}
            >
              <Picker.Item
                label="North Africa"
                value="North Africa"
                style={styles.pickerItemText}
              />
            </Picker>
          </View>
          <Text style={[styles.countryText, styles.cityText]}>
            {t("common:city")}
            <Text style={{ color: "#FF4747" }}>*</Text>
          </Text>
          <View style={[styles.pickerView, styles.cityPicker]}>
            <Picker
              mode="dropdown"
              selectedValue={city}
              onValueChange={(selectedValue) => setCity(selectedValue)}
            >
              <Picker.Item
                label="Libyan"
                value="Libyan"
                style={styles.pickerItemText}
              />
            </Picker>
          </View>
          <Text style={styles.bankText}>{t("common:bank_details")}</Text>
          <TextInput 
 textAlign={I18nManager.isRTL ? "right" :"left"} 
            defaultValue={bankText}
            onChangeText={(newText) => setBankText(newText)}
            style={[styles.input, styles.bankInput]}
            placeholder="Bank"
          ></TextInput>

          <TextInput 
 textAlign={I18nManager.isRTL ? "right" :"left"} 
            defaultValue={branchText}
            onChangeText={(newText) => setBranchText(newText)}
            style={[styles.input, styles.branchInput]}
            placeholder="Branch" 
          ></TextInput>

          <TextInput 
 textAlign={I18nManager.isRTL ? "right" :"left"} 
            defaultValue={accountText}
            onChangeText={(newText) => setAccountText(newText)}
            style={[styles.input, styles.accountInput]}
            placeholder="Account number"
          ></TextInput>

          <TextInput 
 textAlign={I18nManager.isRTL ? "right" :"left"} 
            defaultValue={codeText}
            onChangeText={(newText) => setCodeText(newText)}
            style={[styles.input, styles.codeInput]}
            placeholder="Swift code"
          ></TextInput>
          <View style={styles.btnView}>
            <TouchableOpacity
              onPress={() => navigation.navigate("LetterCreditStep2")}
              style={styles.button}
            >
              <Text style={styles.buttonText}>
                {t("common:save_and_continue")}
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

export default LetterCreditStep1;

const styles = StyleSheet.create({
  mainContainer: {
    height: height,
    backgroundColor: "#FFFFFF",
  },
  headerView: {
    flexDirection: "row",
    marginTop: 44,
    alignItems: "center",
    backgroundColor: "#FFFFFF",
  },
  arrowIcon: {
    fontSize: 25,
    color: "#101820",
    marginLeft: 24,
  },
  cardText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 22,
    color: "#000000",
    left: 90,
  },
  stepView: {
    flexDirection: "row",
    height: 6,
    marginTop: 40,
    marginBottom: 25,
    marginLeft: 24,
    marginRight: 24,
    justifyContent: "space-between",
  },
  step1: {
    width: 59,
    height: 6,
    backgroundColor: "#FF4747",
    borderRadius: 17,
  },
  steps: {
    width: 59,
    height: 6,
    backgroundColor: "#000000",
    opacity: 0.1,
    borderRadius: 17,
  },
  innerContainer: {
    flex: 1,
    marginLeft: 24,
    marginRight: 24,
  },
  companyText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 18,
    color: "#000000",
    top: 20,
  },
  nameText: {
    height: 28,
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 14,
    color: "#A9ABAE",
    top: 40,
  },
  nameInput: {
    borderBottomWidth: 1,
    borderColor: "#E7E8E9",
    height: 50,
    top: 40,
    color: "#101820",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 14,
  },
  addressText: {
    height: 28,
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 14,
    color: "#A9ABAE",
    top: 60,
  },
  addressInput: {
    borderBottomWidth: 1,
    borderColor: "#E7E8E9",
    height: 50,
    top: 60,
    color: "#101820",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 14,
  },
  countryText: {
    height: 28,
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 14,
    color: "#A9ABAE",
    top: 80,
  },
  cityText: {
    top: 100,
  },
  pickerView: {
    top: 80,
    borderBottomWidth: 1,
    borderColor: "#E7E8E9",
  },
  cityPicker: {
    top: 100,
  },
  pickerItemText: {
    color: "#101820",
    fontSize: 14,
    fontWeight: "500",
    fontFamily: "Inter",
  },
  bankText: {
    top: 130,
    fontFamily: "Inter",
    fontStyle: "norma",
    fontWeight: "600",
    fontSize: 18,
    color: "#000000",
  },
  input: {
    borderBottomWidth: 1,
    borderColor: "#E7E8E9",
    height: 65,
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 14,
    color: "#101820",
  },
  bankInput: {
    top: 160,
  },
  branchInput: {
    top: 180,
  },
  accountInput: {
    top: 200,
  },
  codeInput: {
    top: 220,
  },
  btnView: {
    flex: 1,
    marginTop:280,
    marginBottom: 51,
    justifyContent: "flex-end",
  },
  button: {
    padding: 16,
    backgroundColor: "#FF4747",
    borderRadius: 70,
  },
  buttonText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 16,
    alignItems: "center",
    textAlign: "center",
    color: "#FFFFFF",
  },
});
