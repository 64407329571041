const POST_CHANGE_PIN_CODE_REQUEST = "POST_CHANGE_PIN_CODE_REQUEST";
const POST_CHANGE_PIN_CODE_REQUEST_SUCCESS = "POST_CHANGE_PIN_CODE_REQUEST_SUCCESS";
const POST_CHANGE_PIN_CODE_REQUEST_FAILURE = "POST_CHANGE_PIN_CODE_REQUEST_FAILURE";
const RESET_CHANGE_PIN_CODE_STATE="RESET_CHANGE_PIN_CODE_STATE";
const changePinCode = (formData) => {
  return {
    type: POST_CHANGE_PIN_CODE_REQUEST,
    formData,
  };
};

const resetState = () => {
  return {
    type: RESET_CHANGE_PIN_CODE_STATE,
    
  };
};

export {
  changePinCode,
  resetState,
  POST_CHANGE_PIN_CODE_REQUEST,
  POST_CHANGE_PIN_CODE_REQUEST_SUCCESS,
  POST_CHANGE_PIN_CODE_REQUEST_FAILURE,
  RESET_CHANGE_PIN_CODE_STATE,
};
