import {
  POST_REQUEST_CBL_REQUEST,
  POST_REQUEST_CBL_REQUEST_SUCCESS,
  POST_REQUEST_CBL_REQUEST_FAILURE,
  RESET_CBL_REQUEST_STATE
} from "./Actions";

const initialState = {
  loading: null,
  message: null,
  error: null,
  data: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case POST_REQUEST_CBL_REQUEST: {
      console.log(POST_REQUEST_CBL_REQUEST);
      return {
        ...state,
        error: false,
        success:false,
        loading: true,
      };
    }
    case POST_REQUEST_CBL_REQUEST_SUCCESS: {
      const { message, data } = action.payload;
      console.log(POST_REQUEST_CBL_REQUEST_SUCCESS);
      return {
        ...state,
        loading: false,
        success:true,
        data,
        message,
      };
    }
    case POST_REQUEST_CBL_REQUEST_FAILURE: {
      console.log(POST_REQUEST_CBL_REQUEST_FAILURE);

      const { message } = action.payload;
      return {
        ...state,
        loading: false,
        success:false,
        error: true,
        message,
      };
    }
    case RESET_CBL_REQUEST_STATE: {
      console.log(RESET_CBL_REQUEST_STATE);

      return {
        ...state,
        loading: false,
        success: false,
        error: false,
      };
    }
    default:
      return state;
  }
};

export { reducer };
