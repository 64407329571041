import { StyleSheet, Image, Platform, View } from "react-native";
import React from "react";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import Dashboard from "../Screens/Dashboard/Dashboard";
import Services from "../Screens/Dashboard/Services";
import RequestTopUp from "../Screens/TopUpMoney/RequestTopUp";
import Account from "../Screens/Dashboard/Account";
import Home from "../assets/image/Home.png";
import HomeRed from "../assets/image/HomeRed.png";
import Document from "../assets/image/Document.png";
import DocumentRed from "../assets/image/DocumentRed.png";
import Profile from "../assets/image/ProfileBlack.png";
import ProfileRed from "../assets/image/ProfileRed.png";
import Category from "../assets/image/Category.png";
import CategoryRed from "../assets/image/CategoryRed.png";
import TopUpMoney from "../Screens/TopUpMoney/TopUpMoney";
import AddCard from "../Screens/TopUpMoney/AddCard";
import WalletToWallet from "../Screens/TopUpMoney/WalletToWallet";
import BankTopUp from "../Screens/TopUpMoney/BankTopUp";
import EditBankTopUp from "../Screens/TopUpMoney/EditTopUpMoney/EditBankTopUp";
import LetterCreditStep1 from "../Screens/CreditRequest/LetterCreditStep1";
import LetterCreditStep2 from "../Screens/CreditRequest/LetterCreditStep2";
import LetterCreditStep3 from "../Screens/CreditRequest/LetterCreditStep3";
import LetterCreditStep4 from "../Screens/CreditRequest/LetterCreditStep4";
import LetterCreditStep5 from "../Screens/CreditRequest/LetterCreditStep5";
import PersonalInfo from "../Screens/KycRequest/PersonalInfo";
import EditPersonalInfo from "../Screens/KycRequest/EditKycRequest/EditPersonalInfo";
import EditCblDocuments from "../Screens/CblRequest/EditCblRequest/EditCblDocuments";
import EditCblDocumentForm from "../Screens/CblRequest/EditCblRequest/EditCblDocumentForm";
import AddressDetail from "../Screens/KycRequest/AddressDetail";
import FinancialInfo from "../Screens/KycRequest/FinancialInfo";
import Documents from "../Screens/KycRequest/Documents";
import CblRequest from "../Screens/CblRequest/CblRequest";
import CblRequestDetail from "../Screens/RequestAndDetails/CblRequestDetail";
import KycRequestDetail from "../Screens/RequestAndDetails/KycRequestDetail";
import TopUpRequestDetail from "../Screens/RequestAndDetails/TopUpRequestDetail";
import KycPersonalInfo from "../Screens/RequestAndDetails/KycPersonalInfo";
import KycAddressDetail from "../Screens/RequestAndDetails/KycAddressDetail";
import KycFinancialInfo from "../Screens/RequestAndDetails/KycFinancialInfo";
import KycDocuments from "../Screens/RequestAndDetails/KycDocument";
import CblDocument from "../Screens/RequestAndDetails/CblDocument";
import EditDocuments from "../Screens/KycRequest/EditKycRequest/EditDocuments";
import Transfer from "../Screens/TransferCustomer/Transfer";
import BankTransfer from "../Screens/TransferCustomer/BankTransfer";
import TransactionSummary from "../Screens/TransferCustomer/TransactionSummary";
import FamilyMember from "../Screens/FamilyMembers/FamilyMember";
import EditFamilyMember from "../Screens/FamilyMembers/EditFamilyMember";
import AddFamilyMember from "../Screens/FamilyMembers/AddFamilyMember";
import AddBeneficiaries from "../Screens/Beneficiaries/AddBeneficiaries";
import Beneficiaries from "../Screens/Beneficiaries/Beneficiaries";
import BeneficiariesDetail from "../Screens/Beneficiaries/BeneficiariesDetail";
import GiftCard from "../Screens/GiftCards/GiftCard";
import GiftCardDetail from "../Screens/GiftCards/GiftCardDetail";
import ServiceVoucher from "../Screens/ServiceVouchers/ServiceVoucher";
import VoucherDetail from "../Screens/ServiceVouchers/VoucherDetail";
import MyQrCode from "../Screens/QrPay/MyQrCode";
import ScanQrCode from "../Screens/QrPay/ScanQrCode";
import PhoneNumberScan from "../Screens/QrPay/PhoneNumberScan";
import QrPay from "../Screens/QrPay/QrPay";
import { useSelector } from "react-redux";
import ExchangeWalletToWallet from "../Screens/ExchangeMoneyRequest/ExchangeWalletToWallet";
import CompanyGiftCardDetail from "../Screens/GiftCards/CompanyGiftCardDetail";
import CompanyVoucherDetail from "../Screens/ServiceVouchers/CompanyVoucherDetail";
import BuyVoucherVerifyPin from "../Screens/ServiceVouchers/BuyVoucherVerifyPin";
import TransferVerifyPin from "../Screens/TransferCustomer/TransferVerifyPin";
import FamilyTopUpVerifyPin from "../Screens/FamilyMembers/FamilyTopUpVerifyPin";
import FamilyTopUp from "../Screens/FamilyMembers/FamilyTopUp";
import TransactionDetail from "../Screens/Dashboard/TransactionDetail";
import FamilyTransactionDetail from "../Screens/FamilyMembers/FamilyTransactionDetail";
import EditAddressDetail from "../Screens/KycRequest/EditKycRequest/EditAddressDetail";
import EditFinancialInfo from "../Screens/KycRequest/EditKycRequest/EditFinancialInfo";
import TopUpStep2Detail from "../Screens/RequestAndDetails/TopUpStep2Detail";
import TopUpStep1Detail from "../Screens/RequestAndDetails/TopUpStep1Detail";
import AccountSetting from "../Screens/AccountSettings/AccountSetting";
import SelectLanguage from "../Screens/AccountSettings/SelectLanguage";
import AsyncStorage from "@react-native-async-storage/async-storage";
import ChangePinCode from "../Screens/AccountSettings/ChangePinCode";
import ForgotPin from "../Screens/AccountSettings/ForgotPin";
import MdpCard from "../Screens/MdpCards/MdpUserCustomer/MdpCard"
import MdpCustomer from "../Screens/MdpCards/MdpUserCustomer/MdpCustomer";
import ChangeBalance from "../Screens/MdpCards/MdpUserCustomer/ChangeBalance";
const Stack = createNativeStackNavigator();

const DashBoardTabStack = () => {
  return (
    <Stack.Navigator>
      <Stack.Screen
        options={{ headerShown: false }}
        name="Dashboard"
        component={Dashboard}
      />
      <Stack.Screen
        options={{ headerShown: false }}
        name="TransactionDetail"
        component={TransactionDetail}
      />
      {/* //....Transfer Customer.....// */}
      <Stack.Screen
        options={{ headerShown: false }}
        name="Transfer"
        component={Transfer}
      />
      <Stack.Screen
        options={{ headerShown: false }}
        name="BankTransfer"
        component={BankTransfer}
      />
      <Stack.Screen
        options={{ headerShown: false }}
        name="TransactionSummary"
        component={TransactionSummary}
      />
      <Stack.Screen
        options={{ headerShown: false }}
        name="TransferVerifyPin"
        component={TransferVerifyPin}
      />
      {/* //....QR Pay.....// */}
      <Stack.Screen
        options={{ headerShown: false }}
        name="MyQrCode"
        component={MyQrCode}
      />
      <Stack.Screen
        options={{ headerShown: false }}
        name="ScanQrCode"
        component={ScanQrCode}
      />
      <Stack.Screen
        options={{ headerShown: false }}
        name="PhoneNumberScan"
        component={PhoneNumberScan}
      />
      <Stack.Screen
        options={{ headerShown: false }}
        name="QrPay"
        component={QrPay}
      />
    </Stack.Navigator>
  );
};
const ServiceTabStack = () => {
  return (
    <Stack.Navigator>
      <Stack.Screen
        options={{ headerShown: false }}
        name="Services"
        component={Services}
      />
      {/* //....Family Members.....// */}
      <Stack.Screen
        options={{ headerShown: false }}
        name="FamilyMember"
        component={FamilyMember}
      />
      <Stack.Screen
        options={{ headerShown: false }}
        name="FamilyTopUp"
        component={FamilyTopUp}
      />
      <Stack.Screen
        options={{ headerShown: false }}
        name="EditFamilyMember"
        component={EditFamilyMember}
      />
      <Stack.Screen
        options={{ headerShown: false }}
        name="AddFamilyMember"
        component={AddFamilyMember}
      />
      <Stack.Screen
        options={{ headerShown: false }}
        name="FamilyTopUpVerifyPin"
        component={FamilyTopUpVerifyPin}
      />
      <Stack.Screen
        options={{ headerShown: false }}
        name="FamilyTransactionDetail"
        component={FamilyTransactionDetail}
      />

      {/* //....Beneficiaries.....// */}
      <Stack.Screen
        options={{ headerShown: false }}
        name="Beneficiaries"
        component={Beneficiaries}
      />
      <Stack.Screen
        options={{ headerShown: false }}
        name="AddBeneficiaries"
        component={AddBeneficiaries}
      />
      <Stack.Screen
        options={{ headerShown: false }}
        name="BeneficiariesDetail"
        component={BeneficiariesDetail}
      />
      {/* //....Gift Cards.....// */}
      <Stack.Screen
        options={{ headerShown: false }}
        name="GiftCardDetail"
        component={GiftCardDetail}
      />
      <Stack.Screen
        options={{ headerShown: false }}
        name="CompanyGiftCardDetail"
        component={CompanyGiftCardDetail}
      />
      <Stack.Screen
        options={{ headerShown: false }}
        name="GiftCard"
        component={GiftCard}
      />
      {/* //....Service Vouchers.....// */}
      <Stack.Screen
        options={{ headerShown: false }}
        name="ServiceVoucher"
        component={ServiceVoucher}
      />
      <Stack.Screen
        options={{ headerShown: false }}
        name="VoucherDetail"
        component={VoucherDetail}
      />
      <Stack.Screen
        options={{ headerShown: false }}
        name="CompanyVoucherDetail"
        component={CompanyVoucherDetail}
      />
      <Stack.Screen
        options={{ headerShown: false }}
        name="BuyVoucherVerifyPin"
        component={BuyVoucherVerifyPin}
      />
    </Stack.Navigator>
  );
};
const RequestTabStack = () => {
  return (
    <Stack.Navigator>
      <Stack.Screen
        options={{ headerShown: false }}
        name="RequestTopUp1"
        component={RequestTopUp}
      />
      <Stack.Screen
        options={{ headerShown: false }}
        name="TopUpMoney"
        component={TopUpMoney}
      />
      <Stack.Screen
        options={{ headerShown: false }}
        name="AddCard"
        component={AddCard}
      />
      <Stack.Screen
        options={{ headerShown: false }}
        name="BankTopUp"
        component={BankTopUp}
      />
      <Stack.Screen
        options={{ headerShown: false }}
        name="WalletToWallet"
        component={WalletToWallet}
      />
      <Stack.Screen
        options={{ headerShown: false }}
        name="MdpCard"
        component={MdpCard}
      />
      {/* //....Edit Top up Money Screen...//  */}
      <Stack.Screen
        options={{ headerShown: false }}
        name="EditBankTopUp"
        component={EditBankTopUp}
      />
      {/* //....Letter of Credit Screen...//  */}
      <Stack.Screen
        options={{ headerShown: false }}
        name="LetterCreditStep1"
        component={LetterCreditStep1}
      />
      <Stack.Screen
        options={{ headerShown: false }}
        name="LetterCreditStep2"
        component={LetterCreditStep2}
      />
      <Stack.Screen
        options={{ headerShown: false }}
        name="LetterCreditStep3"
        component={LetterCreditStep3}
      />
      <Stack.Screen
        options={{ headerShown: false }}
        name="LetterCreditStep4"
        component={LetterCreditStep4}
      />
      <Stack.Screen
        options={{ headerShown: false }}
        name="LetterCreditStep5"
        component={LetterCreditStep5}
      />
      {/* //....KYC Screen...//  */}
      <Stack.Screen
        options={{ headerShown: false }}
        name="PersonalInfo"
        component={PersonalInfo}
      />
      <Stack.Screen
        options={{ headerShown: false }}
        name="AddressDetail"
        component={AddressDetail}
      />
      <Stack.Screen
        options={{ headerShown: false }}
        name="FinancialInfo"
        component={FinancialInfo}
      />

      <Stack.Screen
        options={{ headerShown: false }}
        name="Documents"
        component={Documents}
      />
      <Stack.Screen
        options={{ headerShown: false }}
        name="EditDocuments"
        component={EditDocuments}
      />
      {/* //....EDIT KYC Screen...//  */}

      <Stack.Screen
        options={{ headerShown: false }}
        name="EditPersonalInfo"
        component={EditPersonalInfo}
      />
      <Stack.Screen
        options={{ headerShown: false }}
        name="EditAddressDetail"
        component={EditAddressDetail}
      />
      <Stack.Screen
        options={{ headerShown: false }}
        name="EditFinancialInfo"
        component={EditFinancialInfo}
      />
      {/* //.......Exchange Money......// */}
      <Stack.Screen
        options={{ headerShown: false }}
        name="ExchangeWalletToWallet"
        component={ExchangeWalletToWallet}
      />
      {/* //.......CBL Request......// */}
      <Stack.Screen
        options={{ headerShown: false }}
        name="CblRequest"
        component={CblRequest}
      />
      {/* //.......CBL Edit Request......// */}
      <Stack.Screen
        options={{ headerShown: false }}
        name="EditCblDocuments"
        component={EditCblDocuments}
      />
      <Stack.Screen
        options={{ headerShown: false }}
        name="EditCblDocumentForm"
        component={EditCblDocumentForm}
      />

      {/* //.......MDP cards......// */}
      <Stack.Screen
        options={{ headerShown: false }}
        name="MdpCustomer"
        component={MdpCustomer}
      />
      <Stack.Screen
        options={{ headerShown: false }}
        name="ChangeBalance"
        component={ChangeBalance}
      />

      {/* //.......Request and Its Detail......// */}
      <Stack.Screen
        options={{ headerShown: false }}
        name="TopUpStep1Detail"
        component={TopUpStep1Detail}
      />
      <Stack.Screen
        options={{ headerShown: false }}
        name="TopUpStep2Detail"
        component={TopUpStep2Detail}
      />
      <Stack.Screen
        options={{ headerShown: false }}
        name="TopUpRequestDetail"
        component={TopUpRequestDetail}
      />
      <Stack.Screen
        options={{ headerShown: false }}
        name="CblRequestDetail"
        component={CblRequestDetail}
      />
      <Stack.Screen
        options={{ headerShown: false }}
        name="KycRequestDetail"
        component={KycRequestDetail}
      />
      <Stack.Screen
        options={{ headerShown: false }}
        name="KycPersonalInfo"
        component={KycPersonalInfo}
      />
      <Stack.Screen
        options={{ headerShown: false }}
        name="KycAddressDetail"
        component={KycAddressDetail}
      />
      <Stack.Screen
        options={{ headerShown: false }}
        name="KycFinancialInfo"
        component={KycFinancialInfo}
      />
      <Stack.Screen
        options={{ headerShown: false }}
        name="KycDocuments"
        component={KycDocuments}
      />
      <Stack.Screen
        options={{ headerShown: false }}
        name="CblDocument"
        component={CblDocument}
      />
    </Stack.Navigator>
  );
};
const ProfileTabStack = () => {
  return (
    <Stack.Navigator>
      <Stack.Screen
        options={{ headerShown: false }}
        name="Account"
        component={Account}
      />
      {/* //....Account Settings ...//  */}
      <Stack.Screen
        options={{ headerShown: false }}
        name="AccountSetting"
        component={AccountSetting}
      />
      <Stack.Screen
        options={{ headerShown: false }}
        name="SelectLanguage"
        component={SelectLanguage}
      />
      <Stack.Screen
        options={{ headerShown: false }}
        name="ChangePinCode"
        component={ChangePinCode}
      />
      <Stack.Screen
        options={{ headerShown: false }}
        name="ForgotPin"
        component={ForgotPin}
      />
    </Stack.Navigator>
  );
};

const Tab = createBottomTabNavigator();

const BottomTab = () => {
  return (
    <> <View style={{ position: "absolute", backgroundColor: "white", height: 50, bottom: 0, right: 0, left: 0 }} />
      <Tab.Navigator
        screenOptions={{
          headerShown: false,
          tabBarShowLabel: Platform.OS === 'web',
          tabBarLabelStyle: { ...styles.tarBarStyle },
          tabBarStyle: {
            borderTopWidth: 0,
            backgroundColor: 'white',
            overflow: 'hidden',
            elevation: 0,
            width: 1400,
            alignSelf: "center",
          },
          tabBarItemStyle: {
            justifyContent: "flex-start",
            marginLeft: 20,
          },
        }}
      >
        <Tab.Screen
          name="DashBoard"
          component={DashBoardTabStack}
          options={{
            tabBarLabel: "Home",
            tabBarIcon: ({ focused }) => {
              if (focused) {
                return (<Image source={HomeRed} style={styles.homeImage} />
                );
              } else {
                return (<Image source={Home} style={styles.homeImage} />
                );
              }
            },
          }}
        />
        <Tab.Screen
          name="Services"
          component={ServiceTabStack}
          options={{
            tabBarLabel: 'Category',
            tabBarIcon: ({ focused }) => {
              if (focused) {
                return <Image source={CategoryRed} style={styles.homeImage} />
              } else {
                return <Image source={Category} style={styles.homeImage} />
              }
            },
          }}
        />
        <Tab.Screen
          name="RequestTopUp"
          component={RequestTabStack}
          options={{
            tabBarLabel: 'Document',
            tabBarIcon: ({ focused }) => {
              if (focused) {
                return (
                  <Image source={DocumentRed} style={styles.homeImage} />
                );
              } else {
                return (
                  <Image source={Document} style={styles.homeImage} />
                );
              }
            },
          }}
        />
        <Tab.Screen
          name="Profile"
          component={ProfileTabStack}
          options={{
            tabBarLabel: 'Profile',
            tabBarIcon: ({ focused }) => {
              if (focused) {
                return <Image source={ProfileRed} style={styles.homeImage} />;
              } else {
                return <Image source={Profile} style={styles.homeImage} />
              }
            },
          }}
        />
      </Tab.Navigator>
    </>
  );
};

export default BottomTab;

const styles = StyleSheet.create({
  homeImage: {
    height: 30,
    width: 30,
    resizeMode:"contain"
  },
  tarBarStyle: {
    color: '#000000',
    fontSize: 16,
    fontFamily: 'Inter',
    fontWeight: '700',
  }
});
