import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  Image,
  Dimensions,
  SafeAreaView
} from "react-native";
import React, { useState } from "react";
import Icon from "react-native-vector-icons/Feather";
import { useTranslation } from "react-i18next";
import Edit from "../../assets/image/edit.png";
import { ApiDateFormate, DateFormate } from "../../Utils/HelperFunctions"
import { I18nManager } from "react-native";
import SideBarConatainer from "../../Components/SideBarConatainer"

const RTL = I18nManager.isRTL;

const TopUpStep2Detail = ({ navigation, route }) => {
  const request = route.params?.userRequestDetail.data.request
  const { t } = useTranslation();

  return (
    <SideBarConatainer navigation={navigation}>
      <View style={styles.headerView}>
        <Icon
          name="arrow-left"
          size={30}
          style={styles.arrowIcon}
          onPress={() => navigation.goBack()}
        />
        <Text style={styles.requestsText}>{t("common:requests")}</Text>
      </View>
      <View style={styles.innerContainer}>
        <View style={styles.addressView}>
          <Text style={styles.addressText}>{t("common:detail")}</Text>
          {
            request.verify_request_status.toLowerCase() === "pending" && (
              <TouchableOpacity
                onPress={() =>
                  navigation.navigate("EditBankDetail", { request })
                }
                style={styles.editBtn}
              >
                <Image source={Edit} style={styles.editImage} />
              </TouchableOpacity>
            )

          }

        </View>

        <View style={styles.container}>
          <Text style={styles.nameText}>{t("common:add_to_wallet")}</Text>
          <Text style={styles.cblRequestText}>{request.financial_request.receiver_account.name}</Text>
        </View>

        <View style={styles.container}>
          <Text style={styles.nameText}>{t("common:deposite_currency")}</Text>
          <Text style={styles.cblRequestText}>{request.financial_request.receiver_account.currency.currency_iso_code}</Text>
        </View>

        <View style={styles.container}>
          <Text style={styles.nameText}>{t("common:amount")}</Text>
          <Text style={styles.cblRequestText}>{request.financial_request.amount}</Text>
        </View>

        <View style={styles.container}>
          <Text style={styles.nameText}>{t("common:method")}</Text>
          <Text style={styles.cblRequestText}>{request.financial_request.top_up_method}</Text>
        </View>
        <View style={styles.container}>
          <Text style={styles.nameText}>{t("common:deposite_date")}</Text>
          <Text style={styles.cblRequestText}>{DateFormate(new Date(request.financial_request.depos_date))}</Text>
        </View>
        <View style={styles.container}>
          <Text style={styles.nameText}>{t("common:slip_no")}</Text>
          <Text style={styles.cblRequestText}>{request.financial_request.depos_slip_no}</Text>
        </View>
      </View>
    </SideBarConatainer>
  );
};

export default TopUpStep2Detail;

const styles = StyleSheet.create({
  headerView: {
    flexDirection: "row",
    alignItems: "center",
  },
  arrowIcon: {
    transform: [{ scaleX: RTL ? -1 : 1 }]
  },
  requestsText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 26,
    color: "#000000",
    marginLeft: 10,
  },
  innerContainer: {
    marginTop: 20,
    backgroundColor: "#ffffff",
    padding: 20,
    borderRadius: 16,
  },
  addressView: {
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 14,
  },
  addressText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 18,
    color: "#000000",
  },
  editImage: {
    height: 17,
    width: 51,
  },
  container: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 24,
    justifyContent: "space-between",
  },
  nameText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 14,
    color: "#BDBDBD",
  },
  cblRequestText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 14,
    textAlign: "right",
  },
});
