import {
  POST_DEPOSIT_SLIP_UPLOAD_REQUEST,
  POST_DEPOSIT_SLIP_UPLOAD_REQUEST_SUCCESS,
  POST_DEPOSIT_SLIP_UPLOAD_REQUEST_FAILURE,
  RESET_DEPOSIT_SLIP_UPLOAD_STATE,
  CLEAR_FILES_ON_DEPOSIT_SLIP,
} from "./Actions";

const initialState = {
  loading: null,
  message: null,
  error: null,
  data: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case POST_DEPOSIT_SLIP_UPLOAD_REQUEST: {
      console.log(POST_DEPOSIT_SLIP_UPLOAD_REQUEST);
      return {
        ...state,
        error: false,
        success: false,
        loading: true,
      };
    }
    case POST_DEPOSIT_SLIP_UPLOAD_REQUEST_SUCCESS: {
      const { message, data } = action.payload;
      console.log(POST_DEPOSIT_SLIP_UPLOAD_REQUEST_SUCCESS);
      return {
        ...state,
        loading: false,
        success: true,
        data,
        message,
      };
    }
    case POST_DEPOSIT_SLIP_UPLOAD_REQUEST_FAILURE: {
      console.log(POST_DEPOSIT_SLIP_UPLOAD_REQUEST_FAILURE);
      const { message } = action.payload;
      return {
        ...state,
        loading: false,
        success: false,
        error: true,
        message,
      };
    }
    case RESET_DEPOSIT_SLIP_UPLOAD_STATE: {
      console.log(RESET_DEPOSIT_SLIP_UPLOAD_STATE);
      return {
        ...initialState,
        loading: false,
        success: false,
        error: false,
      };
    }
    case CLEAR_FILES_ON_DEPOSIT_SLIP: {
      console.log(CLEAR_FILES_ON_DEPOSIT_SLIP);
      return {
        ...state,
        data: {},
      };
    }
    default:
      return state;
  }
};

export { reducer };
