
const POST_BUY_GIFT_CARD_REQUEST = 'POST_BUY_GIFT_CARD_REQUEST';
const POST_BUY_GIFT_CARD_REQUEST_SUCCESS =  'POST_BUY_GIFT_CARD_REQUEST_SUCCESS';
const POST_BUY_GIFT_CARD_REQUEST_FAILURE =  'POST_BUY_GIFT_CARD_REQUEST_FAILURE';
const CHANGE_BUY_GIFT_CARD_STATUS =  'CHANGE_BUY_GIFT_CARD_STATUS';

const buyGiftCard = (formData) => {
  return {
    type: POST_BUY_GIFT_CARD_REQUEST,
    formData,
  };
};
const changeBuyGiftCardStatus = () => {
  return {
    type: CHANGE_BUY_GIFT_CARD_STATUS,
  };
};
export {
  buyGiftCard,
  changeBuyGiftCardStatus,
  POST_BUY_GIFT_CARD_REQUEST,
  POST_BUY_GIFT_CARD_REQUEST_SUCCESS,
  POST_BUY_GIFT_CARD_REQUEST_FAILURE,
  CHANGE_BUY_GIFT_CARD_STATUS
};