
const POST_GIFT_CARD_BUY_LIST_REQUEST = 'POST_GIFT_CARD_BUY_LIST_REQUEST';
const POST_GIFT_CARD_BUY_LIST_REQUEST_SUCCESS =  'POST_GIFT_CARD_BUY_LIST_REQUEST_SUCCESS';
const POST_GIFT_CARD_BUY_LIST_REQUEST_FAILURE =  'POST_GIFT_CARD_BUY_LIST_REQUEST_FAILURE';

const giftCardBuyList = (formData)=>{

    return {
        type: POST_GIFT_CARD_BUY_LIST_REQUEST,
        formData
    }
}

export {
  giftCardBuyList,
  POST_GIFT_CARD_BUY_LIST_REQUEST,
  POST_GIFT_CARD_BUY_LIST_REQUEST_SUCCESS,
  POST_GIFT_CARD_BUY_LIST_REQUEST_FAILURE,
};