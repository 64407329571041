import { StyleSheet, Text, View, Platform, SafeAreaView, TextInput, I18nManager, ScrollView, TouchableOpacity, KeyboardAvoidingView } from 'react-native'
import Icon from "react-native-vector-icons/Feather";
import { useTranslation } from "react-i18next";
import { useState, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import ToastAndroid from "react-native-root-toast";

const ForgotPin = ({ navigation }) => {
    const { t } = useTranslation();
    const [newSecurityPin, setNewSecurityPin] = useState("");
    const [confirmSecurityPin, setConfirmSecurityPin] = useState("");
    const [otp, setOtp] = useState("");

    const confirmRef = useRef();
    const otpRef = useRef();
    const Validate = () => {
        if (newSecurityPin !== confirmSecurityPin) {
            ToastAndroid.show(
                t("common:Please_enter_match_password"),
                ToastAndroid.LONG
            );
            return false;
        } else if (otp !== "1234") {
            ToastAndroid.show(
                t("common:Please_enter_otp"),
                ToastAndroid.LONG
            );
            return false;
        }
        return true;
    };

    return (
        <SafeAreaView style={styles.mainContainer}>
            <KeyboardAvoidingView
                style={{ flex: 1 }}
                behavior={Platform.OS === "ios" ? "padding" : "height"}
            >
                <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
                    <View style={styles.headerView}>
                        <Icon name="arrow-left" style={styles.arrowIcon} size={24} onPress={() => navigation.goBack()} />
                        <Text style={styles.updateText}>{t("common:new_security_pin_cap")}</Text>
                        <View style={styles.inputContainer}>
                            <TextInput
                                placeholder={t("common:new_security_pin")}
                                textAlign={I18nManager.isRTL ? "right" : "left"}
                                style={styles.input}
                                onSubmitEditing={() => confirmRef.current.focus()}
                                keyboardType="number-pad"
                                returnKeyType="done"
                                maxLength={4}
                                value={newSecurityPin}
                                onChangeText={(value) => setNewSecurityPin(value)}
                            ></TextInput>
                            <TextInput
                                placeholder={t("common:confirm_your_pin")}
                                textAlign={I18nManager.isRTL ? "right" : "left"}
                                style={styles.input}
                                ref={confirmRef}
                                onSubmitEditing={() => otpRef.current.focus()}
                                keyboardType="number-pad"
                                returnKeyType="done"
                                maxLength={4}
                                value={confirmSecurityPin}
                                onChangeText={(value) => setConfirmSecurityPin(value)}
                            ></TextInput>
                            <TextInput
                                placeholder={t("common:OTP")}
                                textAlign={I18nManager.isRTL ? "right" : "left"}
                                style={styles.input}
                                keyboardType="number-pad"
                                returnKeyType="done"
                                value={otp}
                                ref={otpRef}
                                onChangeText={(value) => setOtp(value)}
                            ></TextInput>
                            <Text style={styles.checkText}>{t("common:CheckOTP")}</Text>
                        </View>
                        <View style={styles.btnView}>
                            <TouchableOpacity onPress={Validate} style={styles.btn}>
                                <Text style={styles.btnText}>{t("common:Continue")}</Text>
                            </TouchableOpacity>
                        </View>
                    </View>
                </ScrollView>
            </KeyboardAvoidingView>

        </SafeAreaView>
    )
}

export default ForgotPin

const styles = StyleSheet.create({
    mainContainer: {
        flex: 1,
        backgroundColor: "#FFFFFF"
    },
    headerView: {
        flex: 1,
        marginHorizontal: 24
    },
    arrowIcon: {
        marginTop: 40,
        alignSelf: "flex-start"
    },
    updateText: {
        marginTop: 32,
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: "700",
        fontSize: 14,
        color: "#101820",
        textAlign: "center",
    },
    inputContainer: {
        marginTop: 15
    },
    input: {
        height: 56,
        color: "#101820",
        fontWeight: "500",
        marginTop: 55,
        borderBottomWidth: 1,
        borderBottomWidth: 1,
        borderColor: "#E7E8E9",
        color: "#101820",
        outlineStyle: "none",

    },
    checkText: {
        marginTop: 5,
        fontFamily: "Inter",
        fontSize: 10,
        fontWeight: "500",
        fontStyle: "normal",
        color: "#555555",
        alignSelf: "flex-start"
    },
    btnView: {
        flex: 1,
        marginBottom: 51,
        justifyContent: "flex-end"
    },
    btn: {
        padding: 16,
        borderRadius: 50,
        backgroundColor: "#FF4747"
    },
    btnText: {
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: "700",
        fontSize: 16,
        alignItems: "center",
        textAlign: "center",
        color: "#FFFFFF",
    },
})