import {
  StyleSheet,
  Text,
  View,
  TextInput,
I18nManager,
  TouchableOpacity,
  Dimensions,
  SafeAreaView,
} from "react-native";
import React, { useState } from "react";
import Icon from "react-native-vector-icons/Feather";
import { useTranslation } from "react-i18next";
const height = Dimensions.get("window").height;

const LetterCreditStep3 = ({ navigation }) => {
  const { t } = useTranslation();
  const [text, setText] = useState("");
  return (
    <SafeAreaView style={styles.topContainer}>
      <View style={styles.headerView}>
        <Icon
          name="arrow-left"
          style={styles.arrowIcon}
          onPress={() => navigation.goBack()}
        />
        <Text style={styles.cardText}>{t("common:letter_of_credit")}</Text>
      </View>
      <View style={styles.stepView}>
        <View style={styles.step1}></View>
        <View style={styles.step2}></View>
        <View style={styles.step3}></View>
        <View style={styles.steps}></View>
        <View style={styles.steps}></View>
      </View>
      <View style={styles.container}>
        <Text style={styles.productText}>
          {t("common:products_services_details")}
        </Text>
        <Text style={styles.fillDetailsText}>
          {t("common:fill_in_the_details")}
        </Text>
        <TextInput 
 textAlign={I18nManager.isRTL ? "right" :"left"} 
          defaultValue={text}
          onChangeText={(newText) => setText(newText)}
          placeholder="Description of Product / Services"
          style={styles.descriptionInput}
        ></TextInput>
        <TextInput 
 textAlign={I18nManager.isRTL ? "right" :"left"} 
          defaultValue={text}
          onChangeText={(newText) => setText(newText)}
          placeholder="Any instructions or special request?"
          style={styles.instructionsInput}
        ></TextInput>
        <View style={styles.btnView}>
          <TouchableOpacity
            onPress={() => navigation.navigate("LetterCreditStep4")}
            style={styles.button}
          >
            <Text style={styles.buttonText}>
              {t("common:save_and_continue")}
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    </SafeAreaView>
  );
};

export default LetterCreditStep3;

const styles = StyleSheet.create({
  topContainer: {
    height: height,
    backgroundColor: "#FFFFFF",
  },
  headerView: {
    flexDirection: "row",
    marginTop: 44,
    alignItems: "center",
    backgroundColor: "#FFFFFF",
  },
  arrowIcon: {
    fontSize: 25,
    color: "#101820",
    marginLeft: 24,
  },
  cardText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 22,
    color: "#000000",
    left: 90,
  },
  stepView: {
    flexDirection: "row",
    height: 6,
    marginTop: 40,
    marginBottom: 25,
    marginLeft: 24,
    marginRight: 24,
    justifyContent: "space-between",
  },
  step1: {
    width: 59,
    height: 6,
    backgroundColor: "#FF4747",
    borderRadius: 17,
  },
  step2: {
    width: 59,
    height: 6,
    backgroundColor: "#FF4747",
    borderRadius: 17,
  },
  step3: {
    width: 59,
    height: 6,
    backgroundColor: "#FF4747",
    borderRadius: 17,
  },
  steps: {
    width: 59,
    height: 6,
    backgroundColor: "#000000",
    opacity: 0.1,
    borderRadius: 17,
  },
  container: {
    flex: 1,
    marginLeft: 24,
    marginRight: 24,
  },
  productText: {
    height: 25,
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 18,
    color: "#000000",
    marginTop: 20,
  },
  fillDetailsText: {
    marginTop: 16,
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 14,
    color: "#101820",
  },
  descriptionInput: {
    height: 65,
    marginTop: 42,
    borderBottomWidth: 1,
    borderColor: "#E7E8E9",
    color: "#101820",
  },
  instructionsInput: {
    height: 65,
    marginTop: 24,
    borderBottomWidth: 1,
    borderColor: "#E7E8E9",
    color: "#101820",
  },
  btnView: {
    flex: 1,
    marginBottom: 51,
    justifyContent: "flex-end",
  },
  button: {
    padding: 16,
    backgroundColor: "#FF4747",
    borderRadius: 70,
  },
  buttonText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 16,
    alignItems: "center",
    textAlign: "center",
    color: "#FFFFFF",
  },
});
