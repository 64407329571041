
const GET_USER_WALLET_REQUEST = 'GET_USER_WALLET_REQUEST';
const GET_USER_WALLET_REQUEST_SUCCESS =  'GET_USER_WALLET_REQUEST_SUCCESS';
const GET_USER_WALLET_REQUEST_FAILURE =  'GET_USER_WALLET_REQUEST_FAILURE';
const RESET_USER_WALLET = "RESET_USER_WALLET";

const getUserWallet = (params)=>{

    return {
        type: GET_USER_WALLET_REQUEST,
        params
    }
}
const resetUserWallet = () => {
  return {
    type: RESET_USER_WALLET,
  };
};
export {
  getUserWallet,
  resetUserWallet,
  GET_USER_WALLET_REQUEST,
  GET_USER_WALLET_REQUEST_SUCCESS,
  GET_USER_WALLET_REQUEST_FAILURE,
  RESET_USER_WALLET,
};