import {
  StyleSheet,
  Text,
  View,
  Dimensions,
  TextInput,
  I18nManager,
  Image,
  KeyboardAvoidingView,
  Platform,
  ScrollView,
  TouchableOpacity
} from "react-native";
import React from "react";
import { useTranslation } from "react-i18next";
import Icon from "react-native-vector-icons/Feather";
import TopTabTransfer from "./TopTabTransfer";
import { SafeAreaView } from "react-native-safe-area-context";
const height = Dimensions.get("window").height;
import Profile from "../../assets/image/profile.png";
import AppHeader from "../../Components/AppHeader";
import { useSelector } from "react-redux";

const Transfer = ({ navigation, route }) => {
  const { t } = useTranslation();
  const userDetails = useSelector((state) => state.userDetails);

  return (
    <SafeAreaView style={styles.mainContainer}>
      <AppHeader navigation={navigation} />
      <View style={styles.topHeader}>
        <View style={styles.topHeaderContainer}>
          <View style={styles.container}>
            <Icon
              name="arrow-left"
              size={30}
              onPress={() => navigation.goBack()}
            />
            <Text style={styles.transferText}>{t("common:transfer")}</Text>
          </View>
          <View style={styles.searchView}>
            <Icon name="search" style={styles.searchIcon} size={20} />
            <TextInput
              textAlign={I18nManager.isRTL ? "right" : "left"}
              style={styles.searchInput}
              placeholder={t("common:find_your_recipient")}
            ></TextInput>
          </View>
          <View style={styles.imageContainer}>
            <View style={styles.imageView}>
              <TouchableOpacity
                onPress={() => navigation.navigate("BankTransfer")}
                style={styles.button}
              >
                <Icon name="plus" color="#FFFFFF" size={26} />
              </TouchableOpacity>
              <Text style={styles.nameText}>{t("common:add_new")}</Text>
            </View>
            <View style={styles.imageView}>
              <Image source={Profile} style={styles.image} />
              <Text style={styles.nameText}>Kashif</Text>
            </View>
            <View style={styles.imageView}>
              <Image source={Profile} style={styles.image} />
              <Text style={styles.nameText}>Muhammad</Text>
            </View>
            <View style={styles.imageView}>
              <Image source={Profile} style={styles.image} />
              <Text style={styles.nameText}>Ali</Text>
            </View>
            <View style={styles.imageView}>
              <Image source={Profile} style={styles.image} />
              <Text style={styles.nameText}>Hassan</Text>
            </View>
            <View style={styles.imageView}>
              <Image source={Profile} style={styles.image} />
              <Text style={styles.nameText}>Javad</Text>
            </View>
          </View>
          <View style={styles.topTabView}>
            <TopTabTransfer phoneNumber={route.params?.phoneNumber} />
          </View>
        </View>
      </View>
    </SafeAreaView>
  );
};

export default Transfer;

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
  },
  topHeader: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "center",
  },
  topHeaderContainer: {
    flex: 1,
    maxWidth: 1400
  },
  container: {
    flexDirection: "row",
    marginTop: 25,
    alignItems: "center",
  },
  transferText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 26,
    color: "#000000",
    marginLeft: 15,
  },
  searchView: {
    bottom: 0,
    backgroundColor: "#FFFFFF",
    borderRadius: 60,
    flexDirection: "row",
    height: 40,
    alignItems: "center",
    marginTop: 25
  },
  searchIcon: {
    marginLeft: 15,
  },
  searchInput: {
    flex: 1,
    height: 24,
    marginHorizontal: 8,
    fontFamily: "Gilroy",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 14,
    color: "#272727",
    outlineStyle: "none",
  },
  imageContainer: {
    backgroundColor: "#FFF",
    marginTop: 32,
    flexDirection: "row",
    alignItems: "center",
    gap: 20,
    height: 180,
    padding: 20,
    borderRadius: 16,
    marginTop: 15
  },
  imageView: {
    borderRadius: 25,
    alignItems: "center",
  },
  button: {
    backgroundColor: "#FF4747",
    height: 94,
    width: 94,
    borderRadius: 100,
    alignItems: "center",
    justifyContent: "center"
  },
  image: {
    height: 94,
    width: 94,
    borderRadius: 2000,
  },
  nameText: {
    marginTop: 8,
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 20,
    color: "#10121F",
  },
  topTabView: {
    flex: 1,
  },
});
